import { Button, Modal, Result } from 'antd';
import { BaseButtonProps } from 'antd/lib/button/button';
import React from 'react';
import { WarningFilled } from '@ant-design/icons';

const WarningModal = ({
  icon,
  visible,
  toggleModal,
  onYes,
  onNo,
  noButtonText,
  yesButtonText,
  title,
  subTitle,
  preventToggleOnClickNo,
  noButtonProps,
  yesButtonProps
}: {
  visible: boolean;
  toggleModal: () => void;
  onYes?: () => void;
  onNo?: () => void;
  title: string;
  subTitle: string | React.ReactNode;
  preventToggleOnClickNo?: boolean;
  noButtonText?: string;
  yesButtonText?: string;
  yesButtonProps?: BaseButtonProps;
  noButtonProps?: BaseButtonProps;
  icon?: React.ReactNode;
}) => {
  return (
    <Modal visible={visible} footer={null} closeIcon={<></>}>
      <Result
        icon={icon || <WarningFilled />}
        status="warning"
        title={title}
        subTitle={subTitle}
        extra={[
          yesButtonText ? (
            <Button
              key='WarningModal__1'
              type="link"
              {...yesButtonProps}
              onClick={() => {
                onYes && onYes();
                toggleModal();
              }}
            >
              {yesButtonText}
            </Button>
          ) : null,
          noButtonText ? (
            <Button
              key='WarningModal__2'
              {...noButtonProps}
              onClick={() => {
                onNo && onNo();

                if (preventToggleOnClickNo) return;

                toggleModal();
              }}
            >
              {noButtonText}
            </Button>
          ) : null
        ]}
      />
    </Modal>
  );
};

export default WarningModal;
