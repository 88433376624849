import { PLAN_INTERVAL_TYPES } from '../../helper/subscriptionHelper';

export enum ADD_ON_KEY {
  GOOGLE_JOBS = 'GOOGLE_JOBS',
  CAREER_WIDGET = 'CAREER_WIDGET',
  SMS = 'SMS'
}

export interface AddOnPayload {
  key: string;
  value: string;
};

export interface AddOnPrice {
  unit: { [PLAN_INTERVAL_TYPES.YEARLY]: number; [PLAN_INTERVAL_TYPES.MONTHLY]: number; [PLAN_INTERVAL_TYPES.INVALID]?: number; };
  addOn: { [PLAN_INTERVAL_TYPES.YEARLY]: number; [PLAN_INTERVAL_TYPES.MONTHLY]: number; [PLAN_INTERVAL_TYPES.INVALID]?: number; };
}

export type AddOn = {
  id: number;
  key: ADD_ON_KEY;
  title: string;
  description: string;
  benefits: string[];
  image: string;
  detailedDescription: string;
  price: AddOnPrice;
  carouselLinks: string[];
  unit: {
    label: { singular: string; plural: string };
    included: number;
    step: number;
    subscriptionInfoMaxKey: string;
    subscriptionInfoRemainingKey: string;
  };
  permission?: RemoteAddOnPermission;
};

export interface AddOnCardProps {
  addOn: AddOn;
}

export interface RemoteAddOnPermission {
  valid: boolean;
  validUntil: Date;
}

export interface RemoteAddOn {
  id: number;
  key: ADD_ON_KEY;
  permission: RemoteAddOnPermission;
}

export interface MarketplaceState {
  addOns: RemoteAddOn[] | [];
  selectedAddOn: AddOn | null;
  loading: boolean;
}
