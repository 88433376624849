import React from 'react';

export const DOMAIN_STATUSES = {
  CONNECTED: 'CONNECTED',
  DNS_CONFIGURATION_DONE: 'DNS_CONFIGURATION_DONE',
  WAITING_FOR_DNS_CONFIGURATION: 'WAITING_FOR_DNS_CONFIGURATION',
  WRONG_DNS_CONFIGURATION: 'WRONG_DNS_CONFIGURATION',
  NOT_REACHABLE: 'NOT_REACHABLE'
};

const domainConnectionHelpCenterLink =
  'https://hilfe.meetovo.de/de/article/eigene-subdomain-domain-verknupfen-woykz1/#3-wie-verknupfe-ich-meine-subdomain-mit-meinem-funnel';

const cnameConnectionMessageByStatus = {
  DNS_CONFIGURATION_DONE: (_: string) => 'Der CNAME-Record ist korrekt konfiguriert.',
  WRONG_DNS_CONFIGURATION: (cname: string) => (
    <>
      Konfiguriere deine DNS-Einstellungen mit dieser{' '}
      <a href={domainConnectionHelpCenterLink} target="_blank">
        Anleitung
      </a>
      . <br />
      Aktueller CNAME: {cname}
    </>
  ),
  WAITING_FOR_DNS_CONFIGURATION: (cname: string) => (
    <>
      Konfiguriere deine DNS-Einstellungen mit dieser{' '}
      <a href={domainConnectionHelpCenterLink} target="_blank">
        Anleitung
      </a>
      . <br />
      Anschließend dauert es bis zu 72 Std. bis die Domain verbunden ist. <br />
      Aktueller CNAME: {cname}
    </>
  )
};

const domainReachableMessage = {
  WAITING_FOR_DNS_CONFIGURATION: 'Die Domain kann über den Browser abgerufen werden.',
  NOT_REACHABLE:
    'Die Domain ist ungültig, bitte überprüfe sie auf Schreibfehler. Stelle außerdem sicher, dass sie bereits von deinem Domain-Anbieter aktiviert wurde.'
};

export const domainSteps = (
  currentStatus: string,
  cname?: string
): {
  title: string;
  description: string | JSX.Element;
  status: 'error' | 'wait' | 'process' | 'finish';
}[] => {
  return [
    {
      title: 'Domain angelegt',
      description: 'Die Domain wurde in MEETOVO hinzugefügt.',
      status: 'finish'
    },
    {
      title: 'Domain erreichbar',
      description:
        domainReachableMessage[
          (getCurrentDomainStepperProgress(currentStatus) === 1
            ? currentStatus
            : DOMAIN_STATUSES.WAITING_FOR_DNS_CONFIGURATION) as keyof typeof domainReachableMessage
        ],
      status:
        currentStatus === DOMAIN_STATUSES.WAITING_FOR_DNS_CONFIGURATION
          ? 'finish'
          : currentStatus === DOMAIN_STATUSES.NOT_REACHABLE
          ? 'error'
          : 'wait'
    },
    {
      title: 'CNAME-Record konfiguriert',
      description: cnameConnectionMessageByStatus[
        (getCurrentDomainStepperProgress(currentStatus) === 2
          ? currentStatus
          : DOMAIN_STATUSES.WAITING_FOR_DNS_CONFIGURATION) as keyof typeof cnameConnectionMessageByStatus
      ](cname || 'Nicht definiert'),
      status:
        currentStatus === DOMAIN_STATUSES.WRONG_DNS_CONFIGURATION
          ? 'error'
          : currentStatus === DOMAIN_STATUSES.DNS_CONFIGURATION_DONE
          ? 'finish'
          : currentStatus === DOMAIN_STATUSES.WAITING_FOR_DNS_CONFIGURATION
          ? 'process'
          : 'wait'
    },
    {
      title: 'Domain verbunden',
      description: 'Du kannst deine verknüpften Funnels nun aufrufen.',
      status: currentStatus === DOMAIN_STATUSES.CONNECTED ? 'finish' : 'wait'
    }
  ];
};

export const getCurrentDomainStepperProgress = (status: string): number => {
  switch (status) {
    case DOMAIN_STATUSES.CONNECTED:
      return 3;
    case DOMAIN_STATUSES.WAITING_FOR_DNS_CONFIGURATION:
    case DOMAIN_STATUSES.DNS_CONFIGURATION_DONE:
    case DOMAIN_STATUSES.WRONG_DNS_CONFIGURATION:
      return 2;
    default:
      return 1;
  }
};
