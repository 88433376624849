import gql from 'graphql-tag';

const DUPLICATE_FUNNEL_PAGE = gql`
  mutation duplicateFunnelPage(
    $input: DuplicateFunnelPageInput!
  ) {
    duplicateFunnelPage(input: $input) {
      name
      craftState
      redirectURL
      id
      type
      pageOrder
      facebookPixelEvent
      tikTokPixelEvent
      linkedInEvent
      googleTagTrigger
      customJs
    }
  }
`;

export default function duplicateFunnelPageAPI(id: number) {
  return window.apolloClient.mutate({
    mutation: DUPLICATE_FUNNEL_PAGE,
    variables: { input: { id } }
  });
}
