import React from "react";
import { QuestionCircleOutlined, LoginOutlined, SmallDashOutlined } from '@ant-design/icons';

export const sourceIcons: { [key: string]: {} } = {
    "Google": <img className="source-logo" src={`${process.env.PUBLIC_URL}/analytics/logos/google.png`} />,
    "Facebook": <img className="source-logo" src={`${process.env.PUBLIC_URL}/analytics/logos/facebook.png`} />,
    "Instagram": <img className="source-logo" src={`${process.env.PUBLIC_URL}/analytics/logos/instagram.png`} />,
    "Youtube": <img className="source-logo" src={`${process.env.PUBLIC_URL}/analytics/logos/youtube.png`} />,
    "LinkedIn": <img className="source-logo" src={`${process.env.PUBLIC_URL}/analytics/logos/linkedin.png`} />,
    "Xing": <img className="source-logo" src={`${process.env.PUBLIC_URL}/analytics/logos/xing.png`} />,
    "TikTok": <img className="source-logo" src={`${process.env.PUBLIC_URL}/analytics/logos/tiktok.png`} />,
    "Pinterest": <img className="source-logo" src={`${process.env.PUBLIC_URL}/analytics/logos/pinterest.png`} />,
    "WhatsApp": <img className="source-logo" src={`${process.env.PUBLIC_URL}/analytics/logos/whatsapp.png`} />,
    "Quora": <img className="source-logo" src={`${process.env.PUBLIC_URL}/analytics/logos/quora.png`} />,
    "Meetup": <img className="source-logo" src={`${process.env.PUBLIC_URL}/analytics/logos/meetup.png`} />,
    "Andere": <SmallDashOutlined />,
    "Anonym": <QuestionCircleOutlined />,
    "Direkt": <LoginOutlined />
}