import React, { useState } from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { GrFormClose } from 'react-icons/gr';
import DefaultSmallLoader from '../../../SharedUI/components/DefaultSmallLoader';

interface ActionProps {
  deletable?: boolean;
  editable?: boolean;
  selectable?: boolean;
  children: React.ReactNode;
  title?: string | React.ReactNode;
  onDelete?: () => any;
  onSelect?: () => void;
  onEdit?: () => void;
  deleteLoading?: boolean;
}

const ActionProviderHOCControllable = ({
  children,
  onDelete,
  deletable,
  title,
  onEdit,
  onSelect,
  deleteLoading,
  editable,
  selectable
}: ActionProps) => {
  const [toBeDeleted, setToBeDeleted] = useState(false);
  return (
    <div className="template-action-provider">
      <div className="d-flex mb-1">
        {typeof title === 'string' ? <p className="ellipsis-title small-label">{title}</p> : title}
        <div className="settings-title__right px-1 pe-auto actions">
          {editable && <EditOutlined className="me-2" onClick={onEdit} />}
          {deletable && (
            <>
              {toBeDeleted ? (
                <div className="d-flex align-items-center">
                  <GrFormClose
                    size={'20'}
                    className="cursor-pointer me-1"
                    color="#212B6D"
                    onMouseDown={() => {
                      setToBeDeleted(false);
                    }}
                  />
                  <div className="d-flex align-items-center cursor-pointer">
                    <DefaultSmallLoader loading={deleteLoading || false} />
                  </div>
                  {!deleteLoading && (
                    <div
                      className="d-flex align-items-center cursor-pointer delete-confirmation-text"
                      onClick={() => {
                        onDelete && onDelete();
                        setToBeDeleted(false);
                      }}
                    >
                      <DeleteOutlined />
                      Bestätigen
                    </div>
                  )}
                </div>
              ) : (
                <DeleteOutlined
                  onClick={() => {
                    setToBeDeleted(true);
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
      <div className="template-block__inner" style={{ display: 'block' }}>
        {children}
        {selectable && (
          <div className="overlay">
            <PlusOutlined onClick={onSelect} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ActionProviderHOCControllable;
