import { Steps } from 'antd';
import React, { FC } from 'react';
import {
  getCurrentDomainStepperProgress,
  domainSteps,
  DOMAIN_STATUSES
} from '../../utils/domainConnectionStatuses';

interface DomainStatusProgressProps {
  currentStatus: string;
  cname?: string;
}

const DomainStatusProgress: FC<DomainStatusProgressProps> = ({ cname, currentStatus }) => {
  if (currentStatus === DOMAIN_STATUSES.CONNECTED) return <></>;
  const { Step } = Steps;

  return (
    <Steps
      current={getCurrentDomainStepperProgress(currentStatus)}
      className="mb-2"
      direction="vertical"
    >
      {domainSteps(currentStatus, cname)?.map(({ title, description, status }, index) => (
        <Step
          status={getCurrentDomainStepperProgress(currentStatus) > index ? 'finish' : status}
          title={title}
          description={description}
        />
      ))}
    </Steps>
  );
};

export default DomainStatusProgress;
