import React from 'react';
import { useAd } from '../redux/adBuilderSlice';
import { BuilderPreviewBase } from '../../components/ui/BuilderPreviewBase';

export default function AdBuilderPreview() {
  const userAd = useAd();

  return (
    <BuilderPreviewBase
      selectedPage={userAd}
      pagesExisting={Boolean(userAd.craftState)}
      className="ad-builder-preview"
    />
  );
}
