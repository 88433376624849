import React, { useState } from 'react';
import ImageWithoutCacheOnError from '../../Builder/components/sharedUI/ImageWithoutCacheOnError';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import { ImageType } from '../utils/LibraryModels';
import { useAppDispatch } from '../../redux/hooks';
import ActionProviderHOC from '../../Builder/components/sharedUI/ActionProviderHOC';
import UpdateImageDetailsModal from './UpdateImageDetailsModal';
import { getAllImagesTagsThunk, removeImageFromGalleryThunk } from '../redux/libraryThunk';

interface Props {
  previewImageURL: string;
  deletable: boolean;
  tags: string[];
  currentImage: ImageType;
  searchQuery?: string;
  onSelectImage?: (image: ImageType) => void;
}

const SelectableItem = ({
  tags,
  previewImageURL,
  deletable,
  currentImage,
  searchQuery,
  onSelectImage
}: Props) => {
  const dispatch = useAppDispatch();
  const [isEditVisible, setIsEditVisible] = useState(false);

  const toggleEditVisible = () => {
    setIsEditVisible(!isEditVisible);
  };

  return (
    <div className="template-block">
      <ActionProviderHOC
        previewImageURL={previewImageURL}
        deletable={deletable}
        onDelete={() => dispatch(removeImageFromGalleryThunk({ id: currentImage?.id }))}
        deleteCallback={() => dispatch(getAllImagesTagsThunk())}
        title={currentImage?.name}
        onSelect={() => {
          if (onSelectImage) {
            onSelectImage(currentImage);
          }
        }}
        onEdit={toggleEditVisible}
      >
        <img className="m-0" src={previewImageURL} />
      </ActionProviderHOC>
      <div className="mt-2 mx-2 builder__settings__image-select-modal__template-tags">
        {currentImage.tags.map(tag => (
          <CheckableTag key={tag} checked={searchQuery === tag}>
            {tag}
          </CheckableTag>
        ))}
      </div>
      <UpdateImageDetailsModal
        visible={isEditVisible}
        toggleVisible={toggleEditVisible}
        currentImage={currentImage}
        tags={tags}
      />
    </div>
  );
};

export default SelectableItem;
