import gql from 'graphql-tag';

export interface Input {
  id: number;
}

export const REMOVE_FUNNEL_TEMPLATE = gql`
  mutation removeFunnelTemplate($id: Int!) {
    removeFunnelTemplate(id: $id)
  }
`;

export default function removeFunnelTemplate(input: Input) {
  return window.apolloClient.mutate({
    mutation: REMOVE_FUNNEL_TEMPLATE,
    variables: { id: input.id }
  });
}
