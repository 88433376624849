import gql from 'graphql-tag';

export const CREATE_SCREENSHOT_OF_FIRST_FUNNEL_PAGE = gql`
  mutation createScreenshotOfFirstFunnelPage {
    createScreenshotOfFirstFunnelPage
  }
`;

export default function createScreenshotOfFirstFunnelPageAPI() {
  try {
    return window.apolloClient.mutate({
      mutation: CREATE_SCREENSHOT_OF_FIRST_FUNNEL_PAGE,
    });
  } catch (error) {

  }
}
