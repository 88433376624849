import { Checkbox } from 'antd';
import React from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import { setSelectedBlockNodeIds, useSelectedBlockNodeIds } from '../../redux/builderSlice';
import { useNode } from '@craftjs/core';

const SaveBlockCheckboxAction = () => {
  const dispatch = useAppDispatch();
  const { id } = useNode();
  const selectedBlockNodeIds = useSelectedBlockNodeIds();
  const isNodeSelected = selectedBlockNodeIds.indexOf(id) !== -1;

  return (
    <li className="mr-2">
      <Checkbox
        checked={isNodeSelected}
        onChange={() => {
          dispatch(setSelectedBlockNodeIds(id));
        }}
      />
    </li>
  );
};

export default SaveBlockCheckboxAction;
