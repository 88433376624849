import React, { useEffect } from 'react';
import { useEditor, useNode, UserComponent } from '@craftjs/core';
import { Row } from 'react-bootstrap';
import { CRAFT_ELEMENTS, CRAFT_ELEMENTS_LABEL } from '../../../config/craftElements';
import { ActionsController } from '../../sharedUI/ActionsController';
import DropElementArea, { Level } from '../common/DropElementArea';
import { CraftElementBaseProps } from '../../../helper/craftJs';

interface Props extends CraftElementBaseProps {
  children?: any;
  columnWidths?: {
    sm: number;
    md: number;
    lg: number;
  };
  showParentSettingsOnChildHover?: boolean;
  openParentSettingsOnChildSettings?: boolean;
  backgroundColor?: string;
}

const ColumnGrid: UserComponent<{}> = ({ 
  children, 
  columnWidths, 
  showParentSettingsOnChildHover,
  openParentSettingsOnChildSettings,
  backgroundColor,
  ...props 
}: Props) => {
  const { query } = useEditor();

  const {
    connectors: { connect, drag },
    isFirstChildOfItsParent,
    columnHasNoChild,
    currentNode
  } = useNode(node => {
    const SerializedNodes = query.getSerializedNodes();
    const parentID = node.data.parent;
    const isFirstChildOfItsParent = SerializedNodes[parentID].nodes[0] === node.id;
    const columnNodes = node.data.nodes;
    const columnHasNoChild = columnNodes.reduce((prev, curr) => {
      if (SerializedNodes[curr].nodes.length) {
        return false;
      }
      return prev;
    }, true);

    return { isFirstChildOfItsParent, currentNode: node, columnHasNoChild };
  });

  return (
    <>
      {isFirstChildOfItsParent && <DropElementArea level={Level.COLUMN_GRID} />}
      <ActionsController
        style={{ padding: '5px', minHeight: columnHasNoChild ? '260px' : 'inherit' }}
        label={CRAFT_ELEMENTS_LABEL[currentNode.data.displayName]}
      >
        <Row {...props} ref={(ref: any) => connect(drag(ref))} className="column-grid-container">
          {children}
        </Row>
      </ActionsController>
      <DropElementArea level={Level.COLUMN_GRID} />
    </>
  );
};

ColumnGrid.craft = {
  name: CRAFT_ELEMENTS.COLUMN_GRID,
  props: {
    openParentSettingsOnChildSettings: true,
    showParentSettingsOnChildHover: true,
  },
  rules: {
    canMoveIn: (selectedNode: any) => {
      return selectedNode[0].data.displayName === CRAFT_ELEMENTS.COLUMN;
    }
  }
};

export default ColumnGrid;
