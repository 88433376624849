import { Row, Col } from 'antd';
import React from 'react';
import { TEMPLATE_KEYS } from '../../Container/helper';
import { useEditor, useNode } from '@craftjs/core';
import { iconDeactivationAllowTemplates } from './Choice';
import { useElementsPresets } from '../../../../redux/builderSlice';
import { builderPresetElements } from '../../../../interfaces/builderSliceTypes';

const ChoiceTemplates = () => {
  const { query, actions } = useEditor();
  const { props, childNodes } = useNode(node => {
    const parentID = node.data.parent;
    const SerializedNodes = query.getSerializedNodes();
    const parentNode = SerializedNodes[parentID];
    const childNodes = parentNode.nodes;
    return {
      childNodes: childNodes,
      props: node.data.props
    };
  });
  const handleTemplate = (value: TEMPLATE_KEYS) => {
    actions.setProp(childNodes, (props: any) => {
      props.templateKey = value;
      if (!iconDeactivationAllowTemplates.includes(value)) {
        props.deactivateIconButton = true;
      }
    });
  };

  const presets = useElementsPresets(builderPresetElements.CHOICE);

  const settingDisabled = props.isPresetOn;

  const finalProps =  props.isPresetOn ? {...props, ...presets} : props;

  return (
    <Row
      gutter={16}
      className={`builder__settings-sidebar__row ${settingDisabled && 'disable-layer__disabled'}`}
    >
      <Col span={6}>
        <div
          className={`
          choice-template-setting
          choice-template-setting__right-icon
          ${
            finalProps?.templateKey === TEMPLATE_KEYS.ICON_ON_RIGHT
              ? 'choice-template-setting__active'
              : ''
          }`}
          onClick={() => {
            handleTemplate(TEMPLATE_KEYS.ICON_ON_RIGHT);
          }}
        >
          <img src="/dashboard/choice-template-icon-right.svg" />
        </div>
      </Col>
      <Col span={6}>
        <div
          className={`
          choice-template-setting
          choice-template-setting__center-icon
          ${
            finalProps?.templateKey === TEMPLATE_KEYS.ICON_ON_CENTER
              ? 'choice-template-setting__active'
              : ''
          }`}
          onClick={() => {
            handleTemplate(TEMPLATE_KEYS.ICON_ON_CENTER);
          }}
        >
          <img src="/dashboard/choice-template-icon-center.svg" />
        </div>
      </Col>
      <Col span={6}>
        <div
          className={`
          choice-template-setting
          choice-template-setting__left-icon
          ${
            finalProps?.templateKey === TEMPLATE_KEYS.ICON_ON_LEFT
              ? 'choice-template-setting__active'
              : ''
          }`}
          onClick={() => {
            handleTemplate(TEMPLATE_KEYS.ICON_ON_LEFT);
          }}
        >
          <img src="/dashboard/choice-template-icon-left.svg" />
        </div>
      </Col>
      <Col span={6}>
        <div
          className={`
          choice-template-setting
          choice-template-setting__top-icon
          ${
            finalProps?.templateKey === TEMPLATE_KEYS.ICON_ON_TOP
              ? 'choice-template-setting__active'
              : ''
          }`}
          onClick={() => {
            handleTemplate(TEMPLATE_KEYS.ICON_ON_TOP);
          }}
        >
          <img src="/dashboard/choice-template-icon-top.svg" />
        </div>
      </Col>
    </Row>
  );
};

export default ChoiceTemplates;
