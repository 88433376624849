import React from 'react';
import { Popover, Select, DatePicker, Button, Input, Row, Col, Tooltip } from 'antd';
import { FunnelInterface } from '../../Funnel/components/GeneralSettingsBuilder';
import { eventTypes } from '../utils/eventTypes';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import { debounce } from 'debounce';
import { BookingStatus } from '../container/Events';
import moment from 'moment';
import { predefinedDatePickerRanges } from '../utils/datePickerRanges';
import { FiFilter } from 'react-icons/fi';

const { RangePicker } = DatePicker;

const defaultRangePickerProps: RangePickerProps<moment.Moment> = {
  allowClear: false,
  format: 'DD.MM.YYYY',
  allowEmpty: [false, false]
};

interface FilterPopover {
  defaultFunnelIds?: number[];
  funnels: FunnelInterface[];
  handleFunnelSelectionChange(value: number[]): any;
  handleOnSearchQueryChange(value: string): void;
  rangePickerProps: RangePickerProps<moment.Moment>;
  type: string;
  handleTypeChange(value: string): any;
  availableStatus: BookingStatus[];
  handleChangeStatus: (value: number) => void;
  bookingStatus: number;
}

const FilterPopover = ({
  defaultFunnelIds,
  funnels,
  type,
  handleTypeChange,
  handleFunnelSelectionChange,
  handleOnSearchQueryChange,
  rangePickerProps,
  availableStatus,
  handleChangeStatus,
  bookingStatus
}: FilterPopover) => {
  const handleOnChange = debounce((value: string) => {
    handleOnSearchQueryChange(value);
  }, 1500);

  return (
    <div className="d-flex align-items-center">
      <Tooltip title='Filter'>
        <Popover
          className="default-popover"
          placement="top"
          content={
            <div className="default-popover__content" style={{ width: 400, maxWidth: 400 }}>
              <div className="default-popover__item">
                <label>Datumsbereich</label>
                <RangePicker
                  {...defaultRangePickerProps}
                  {...rangePickerProps}
                  //@ts-ignore
                  ranges={predefinedDatePickerRanges}
                />
              </div>

              <Row>
                <Col span={24}>
                  <div className="default-popover__item">
                    <label>Suche</label>
                    <Input
                      placeholder="z.B. Jane Doe"
                      className="w-100"
                      onChange={e => {
                        const value = e.target.value;
                        handleOnChange(value);
                      }}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="default-popover__item">
                    <label>Funnels</label>
                    <Select
                      defaultValue={defaultFunnelIds}
                      mode="multiple"
                      placeholder="Auswählen..."
                      onChange={handleFunnelSelectionChange}
                      optionFilterProp={'label'}
                      filterOption={(input, option) => {
                        return !!option?.label
                          ?.toString()
                          .toLocaleLowerCase()
                          .includes(input.toLowerCase());
                      }}
                    >
                      {funnels.map((funnel: any, idx: number) => {
                        return (
                          <Select.Option key={idx} label={funnel.title} value={funnel.id}>
                            {funnel.title}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </div>
                </Col>
              </Row>

              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <div className="default-popover__item">
                    <label>Typ</label>
                    <Select
                      defaultValue="all"
                      value={type}
                      onChange={(typeSelectionValue: string, _) =>
                        handleTypeChange(typeSelectionValue)
                      }
                    >
                      {eventTypes.map((o: any, idx: number) => (
                        <Select.Option key={idx} value={o.key}>
                          {o.text}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="default-popover__item">
                    <label>Status</label>
                    <Select
                      defaultValue={bookingStatus}
                      style={{ width: '100%' }}
                      onChange={handleChangeStatus}
                    >
                      <Select.Option key={0} value={0}>
                        Alle
                      </Select.Option>
                      {availableStatus?.map(single => {
                        return (
                          <Select.Option key={single.id} value={single.id}>
                            {single.status}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </div>
                </Col>
              </Row>
            </div>
          }
          trigger="click"
        >
          <Button
            className="ant-btn__without-background m-0"
            type="ghost"
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            icon={<FiFilter className='agency-dashboard-brand-color' size={22} />}
          >
          </Button>
        </Popover>
      </Tooltip>
    </div>
  );
};

export default FilterPopover;
