import React, { memo } from 'react';

import CarouselImageUpload from './CarouselImageUpload';
import { SortableContainer } from 'react-sortable-hoc';

const SortableListContainer = SortableContainer(({ children, ...rest }: any) => {
  return (
    <div className="sortable-container" {...rest}>
      {children}
    </div>
  );
});

export default memo(
  ({
    slideNodes,
    onSortEnd,
    handleDelete
  }: {
    slideNodes: string[];
    onSortEnd: ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => void;
    handleDelete: (key: string) => void;
  }) => {
    return (
      <>
        <SortableListContainer
          id="carousel-slides-listing"
          className="sortable-container"
          onSortEnd={onSortEnd}
          useDragHandle
          pressDelay={1}
        >
          {slideNodes?.map((key, i: number) => {
            return (
              <CarouselImageUpload
                handleDelete={() => {
                  handleDelete(key);
                }}
                deletable={slideNodes.length > 1}
                key={`item-${i}`}
                id={key}
                index={i}
              />
            );
          })}
        </SortableListContainer>
      </>
    );
  },
  (prev, next) => {
    return JSON.stringify(prev.slideNodes) === JSON.stringify(next.slideNodes);
  }
);
