import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppSelector } from '../../../redux/hooks';
import { getCompleteWidgetBuilder, linkFunnelToWidget, saveWidgetBuilderStateThunk } from './thunk';
import {
  CompanyDetail,
  FunnelDetails,
  JobInformation,
  TargetAudience,
  Widget,
  WidgetBuilderStateChecksums,
  WidgetBuilderStateType
} from '../interfaces/widgetSliceTypes';
import { message } from 'antd';
import { GeneralMessages } from '../../../config/messages';

export const INITIAL_STATE: WidgetBuilderStateType = {
  widget: {
    id: 0,
    craftState: '',
    title: '',
    uniqueIdentifier: ''
  },
  isWidgetSaved: true,
  shouldSaveWidget: true,
  checksums: {
    widget: null,
    WidgetTheme: null,
    connectedFont: null
  },
  loaders: {
    widgetBuilderStateFetch: true,
    widgetBuilderStateSave: false,
    linkFunnelToWidget: false
  },
  currentWidgetId: 0,
  linkedFunnelDetails: {},
  jobInformation: {},
  targetAudience: {},
  companyDetail: {}
};

//@ts-ignore
const widgetBuilderUiSlice = createSlice({
  name: 'widgetBuilderUI',
  initialState: INITIAL_STATE,
  reducers: {
    resetToInitialWidgetBuilderState: state => {
      return INITIAL_STATE;
    },
    setCraftState: (state, { payload }: PayloadAction<{ craftState: string }>) => {
      state.widget.craftState = payload.craftState;
    },
    setCurrentWidgetId: (state, { payload }: PayloadAction<{ id: number }>) => {
      state.currentWidgetId = payload.id;
    },
    editIsWidgetSaved: (state, { payload }: PayloadAction<boolean>) => {
      state.isWidgetSaved = payload;
    },
    setShouldSaveWidget: (state, { payload }: PayloadAction<boolean>) => {
      state.shouldSaveWidget = payload;
    },
    handleRemoveLinkedFunnel: (state, { payload }: PayloadAction<number>) => {
      state?.linkedFunnelDetails && delete state?.linkedFunnelDetails[payload as number];
    },
    setWidgetTitle: (state, { payload }: PayloadAction<string>) => {
      state.widget.title = payload;
      state.isWidgetSaved = false;
    },
    setWidgetUniqueIdentifier: (state, { payload }: PayloadAction<string>) => {
      state.widget.uniqueIdentifier = payload;
      state.isWidgetSaved = false;
    }
  },
  extraReducers: {
    //@ts-ignore
    [getCompleteWidgetBuilder.fulfilled]: (
      state: WidgetBuilderStateType,
      action: PayloadAction<{
        widget: Omit<Widget, 'funnels'> & { funnels: { [key: string]: any }[] };
        checksums: WidgetBuilderStateChecksums;
      }>
    ) => {
      const funnelsMap: { [key: string]: any } = {};
      const jobInformationMap: { [key: string]: any } = {};
      const companyDetailMap: { [key: string]: any } = {};
      const targetAudienceMap: { [key: string]: any } = {};

      action.payload.widget.funnels.forEach(funnel => {
        const { id, jobInformation, companyDetail, targetAudience, ...rest } = funnel;
        funnelsMap[id] = rest;
        jobInformationMap[id] = jobInformation;
        companyDetailMap[id] = companyDetail;
        targetAudienceMap[id] = targetAudience;
      });

      state.linkedFunnelDetails = { ...(state.linkedFunnelDetails || {}), ...funnelsMap };
      state.jobInformation = { ...(state.jobInformation || {}), ...jobInformationMap };
      state.companyDetail = { ...(state.companyDetail || {}), ...companyDetailMap };
      state.targetAudience = { ...(state.targetAudience || {}), ...targetAudienceMap };

      state.widget = {
        id: action.payload.widget.id,
        craftState: action.payload.widget.craftState,
        title: action.payload.widget.title,
        uniqueIdentifier: action.payload.widget.uniqueIdentifier
      };
      state.currentWidgetId = action.payload.widget.id;
      state.checksums = action.payload.checksums;
      state.loaders.widgetBuilderStateFetch = false;
    },
    //@ts-ignore
    [getCompleteWidgetBuilder.pending]: (state: WidgetBuilderStateType) => {
      state.loaders.widgetBuilderStateFetch = true;
    },
    // @ts-ignore
    [getCompleteWidgetBuilder.rejected]: (state: WidgetBuilderStateType) => {
      state.loaders.widgetBuilderStateFetch = false;
      message.error(GeneralMessages.error);
    },
    //@ts-ignore
    [linkFunnelToWidget.fulfilled]: (
      state: WidgetBuilderStateType,
      action: PayloadAction<{
        funnelData: FunnelDetails;
        jobInformation: JobInformation;
        targetAudience: TargetAudience;
        companyDetail: CompanyDetail;
      }>
    ) => {
      const { funnelData, jobInformation, targetAudience, companyDetail } = action.payload;

      state.linkedFunnelDetails = {
        ...(state.linkedFunnelDetails || {}),
        [funnelData.id as number]: funnelData
      };
      state.jobInformation = {
        ...(state.jobInformation || {}),
        [funnelData.id as number]: jobInformation
      };
      state.companyDetail = {
        ...(state.companyDetail || {}),
        [funnelData.id as number]: companyDetail
      };
      state.targetAudience = {
        ...(state.targetAudience || {}),
        [funnelData.id as number]: targetAudience
      };
      state.loaders.linkFunnelToWidget = false;
    },
    //@ts-ignore
    [linkFunnelToWidget.pending]: (state: WidgetBuilderStateType) => {
      state.loaders.linkFunnelToWidget = true;
    },
    // @ts-ignore
    [linkFunnelToWidget.rejected]: (state: WidgetBuilderStateType) => {
      state.loaders.linkFunnelToWidget = false;
      message.error(GeneralMessages.error);
    },
    // @ts-ignore
    [saveWidgetBuilderStateThunk.fulfilled]: (
      state: WidgetBuilderStateType,
      action: PayloadAction<{ checksums: WidgetBuilderStateChecksums; craftState: string }>
    ) => {
      if (!state.shouldSaveWidget) return;

      state.checksums = { ...state.checksums, ...action.payload.checksums };
      state.widget.craftState = action.payload.craftState;

      state.isWidgetSaved = true;
      state.loaders.widgetBuilderStateSave = false;
    },
    //@ts-ignore
    [saveWidgetBuilderStateThunk.pending]: (state: WidgetBuilderStateType) => {
      state.loaders.widgetBuilderStateSave = true;
    },
    // @ts-ignore
    [saveWidgetBuilderStateThunk.rejected]: (state: WidgetBuilderStateType) => {
      state.loaders.widgetBuilderStateSave = false;
    }
  }
});

export const {
  setCraftState,
  setCurrentWidgetId,
  editIsWidgetSaved,
  setShouldSaveWidget,
  resetToInitialWidgetBuilderState,
  setWidgetTitle,
  setWidgetUniqueIdentifier
} = widgetBuilderUiSlice.actions;

export const useWidget = (): Widget => {
  return useAppSelector(state => state.widgetBuilderUI.widget);
};

export const useCurrentWidgetId = (): any => {
  return useAppSelector((state: any) => state.widgetBuilderUI.currentWidgetId);
};

export const useWidgetBuilderLoading = (): boolean => {
  return useAppSelector(state => {
    return state.widgetBuilderUI.loaders.widgetBuilderStateFetch;
  });
};

export const useSaveWidgetBuilderLoading = (): boolean => {
  return useAppSelector(state => state.widgetBuilderUI.loaders.widgetBuilderStateSave);
};

export const useIsWidgetSaved = (): boolean => {
  return useAppSelector(state => state.widgetBuilderUI.isWidgetSaved);
};

export const useShouldSaveWidget = (): boolean => {
  return useAppSelector(state => state.widgetBuilderUI.shouldSaveWidget);
};

export const useLinkFunnelToWidgetLoading = (): boolean => {
  return useAppSelector(state => state.widgetBuilderUI.loaders.linkFunnelToWidget);
};

export const useLinkedFunnelDetails = (funnelId: number): FunnelDetails => {
  return useAppSelector(state => {
    return state.widgetBuilderUI?.linkedFunnelDetails
      ? state.widgetBuilderUI?.linkedFunnelDetails[funnelId]
      : {};
  });
};

export const useJobInformation = (funnelId: number): JobInformation => {
  return useAppSelector(state => {
    return state.widgetBuilderUI?.jobInformation
      ? state.widgetBuilderUI?.jobInformation[funnelId] 
      : {};
  });
};

export const useTargetAudience = (funnelId: number): TargetAudience => {
  return useAppSelector(state => {
    return state.widgetBuilderUI?.targetAudience
      ? state.widgetBuilderUI?.targetAudience[funnelId] 
      : {};
  });
};

export const useCompanyDetail = (funnelId: number): CompanyDetail => {
  return useAppSelector(state => {
    return state.widgetBuilderUI?.companyDetail
      ? state.widgetBuilderUI?.companyDetail[funnelId] 
      : {};
  });
};


export default widgetBuilderUiSlice.reducer;
