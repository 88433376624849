import gql from 'graphql-tag';

interface Input {
  id: number;
}

export const DELETE_FUNNEL_PAGE = gql`
  mutation deleteFunnelPage($pageId: Int!) {
    deleteFunnelPage(pageId: $pageId)
  }
`;

export default function deleteFunnelPage(input: Input) {
  return window.apolloClient.mutate({
    mutation: DELETE_FUNNEL_PAGE,
    variables: { pageId: +input.id }
  });
}
