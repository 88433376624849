import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
export const GET_LATEST_COMMIT_HASH = gql`
  {
    getLatestCommitHash
  }
`;
const useLatestCommitHash = () => {
  return useQuery(GET_LATEST_COMMIT_HASH, {
    fetchPolicy: 'no-cache',
    pollInterval: 300000
  });
};
export default useLatestCommitHash;
