const headlineTextData = {
  title: 'Überschrift',
  data: [
    {
      "category": "Gesundheitswesen",
      "content": [
        {
          "text": "✨ Traumjob in 2 Minuten sichern",
          "title": "[Vorteile] Überschrift #1",
          "id": 100019
        },
        {
          "text": "Bekomme endlich das, was du verdienst",
          "title": "[Vorteile] Überschrift #2",
          "id": 100020
        },
        {
          "text": "Bewerben, ohne Lebenslauf in 2 Minuten",
          "title": "[Vorteile] Überschrift #3",
          "id": 100021
        },
        {
          "text": "Werde Teil des Teams",
          "title": "[Vorteile] Überschrift #4",
          "id": 100022
        },
        {
          "text": "🔍 Gesucht: Pflegekraft (m/w/d) in [Stadt]",
          "title": "[Vorteile] Überschrift #5",
          "id": 100023
        },
        {
          "text": "[Vorteil] & [Vorteil] & [Vorteil]",
          "title": "[Vorteile] Überschrift #6",
          "id": 100024
        },
        {
          "text": "Pflegekräfte (m/w/d) ❤️ Muster GmbH",
          "title": "[Vorteile] Überschrift #7",
          "id": 100025
        },
        {
          "text": "Bewerbung abschließen & Top-Gehalt sichern",
          "title": "[Vorteile] Überschrift #8",
          "id": 100026
        },
        {
          "text": "Hier direkt bewerben",
          "title": "[Vorteile] Überschrift #9",
          "id": 100027
        },
        {
          "text": "[Berufsgruppe] sind zufrieden bei Muster GmbH:: ⌀ 4,8/5 Sterne. Wann bist du dabei?",
          "title": "[Vorteile] Überschrift #10",
          "id": 100028
        },
        {
          "text": "Du + [Emoji, z.B. 🏥] = ❤️",
          "title": "[Vorteile] Überschrift #11",
          "id": 100029
        },
        {
          "text": "Dein neuer Job wartet",
          "title": "[Vorteile] Überschrift #12",
          "id": 100030
        },
        {
          "text": "✨  In 53 Sekunden Traumkarriere entdecken",
          "title": "[Quiz] Überschrift #1",
          "id": 100173
        },
        {
          "text": "Finde heraus, ob du zu uns passt",
          "title": "[Quiz] Überschrift #2",
          "id": 100174
        },
        {
          "text": "🔍 Entdecke das Potenzial als Pflegekraft (m/w/d) in 53 Sekunden",
          "title": "[Quiz] Überschrift #3",
          "id": 100175
        },
        {
          "text": "Job-Zufriedenheit bei Pflegekräften (m/w/d): ⌀ 4,8/5 Sterne. Wann bist du dabei?",
          "title": "[Quiz] Überschrift #4",
          "id": 100176
        },
        {
          "text": "Du + [Emoji, z.B. 🏥] = Top-Karriere",
          "title": "[Quiz] Überschrift #5",
          "id": 100177
        },
        {
          "text": "Dein Ausbildungsplatz wartet. Nur 53 Sekunden.",
          "title": "[Quiz] Überschrift #6",
          "id": 100178
        },
        {
          "text": "✨ Traumjob in 2 Minuten sichern",
          "title": "[Story] Überschrift #1",
          "id": 100287
        },
        {
          "text": "Bekomme endlich das, was du verdienst",
          "title": "[Story] Überschrift #2",
          "id": 100288
        },
        {
          "text": "Bewerben, ohne Lebenslauf in 2 Minuten",
          "title": "[Story] Überschrift #3",
          "id": 100289
        },
        {
          "text": "Werde Teil des Teams",
          "title": "[Story] Überschrift #4",
          "id": 100290
        },
        {
          "text": "🔍 Gesucht: Pflegekraft (m/w/d) in [Stadt]",
          "title": "[Story] Überschrift #5",
          "id": 100291
        },
        {
          "text": "[Vorteil] & [Vorteil] & [Vorteil]",
          "title": "[Story] Überschrift #6",
          "id": 100292
        },
        {
          "text": "Pflegekräfte (m/w/d) ❤️ Muster GmbH",
          "title": "[Story] Überschrift #7",
          "id": 100293
        },
        {
          "text": "Bewerbung abschließen & Top-Gehalt sichern",
          "title": "[Story] Überschrift #8",
          "id": 100294
        },
        {
          "text": "Hier direkt bewerben",
          "title": "[Story] Überschrift #9",
          "id": 100295
        },
        {
          "text": "[Berufsgruppe] sind zufrieden bei Muster GmbH:: ⌀ 4,8/5 Sterne. Wann bist du dabei?",
          "title": "[Story] Überschrift #10",
          "id": 100296
        },
        {
          "text": "Du + [Emoji, z.B. 🏥] = ❤️",
          "title": "[Story] Überschrift #11",
          "id": 100297
        },
        {
          "text": "Dein neuer Job wartet",
          "title": "[Story] Überschrift #12",
          "id": 100298
        }
      ]
    },
    {
      "category": "Handwerk",
      "content": [
        {
          "text": "✨ Traumjob in 2 Minuten sichern",
          "title": "[Vorteile] Überschrift #1",
          "id": 100050
        },
        {
          "text": "Bekomme endlich das, was du verdienst",
          "title": "[Vorteile] Überschrift #2",
          "id": 100051
        },
        {
          "text": "Bewerben, ohne Lebenslauf in 2 Minuten",
          "title": "[Vorteile] Überschrift #3",
          "id": 100052
        },
        {
          "text": "Werde Teil des Teams",
          "title": "[Vorteile] Überschrift #4",
          "id": 100053
        },
        {
          "text": "🔍 Gesucht: Handwerker (m/w/d) in [Stadt]",
          "title": "[Vorteile] Überschrift #5",
          "id": 100054
        },
        {
          "text": "[Vorteil] & [Vorteil] & [Vorteil]",
          "title": "[Vorteile] Überschrift #6",
          "id": 100055
        },
        {
          "text": "Handwerker (m/w/d) ❤️ Muster GmbH",
          "title": "[Vorteile] Überschrift #7",
          "id": 100056
        },
        {
          "text": "Bewerbung abschließen & Top-Gehalt sichern",
          "title": "[Vorteile] Überschrift #8",
          "id": 100057
        },
        {
          "text": "Hier direkt bewerben",
          "title": "[Vorteile] Überschrift #9",
          "id": 100058
        },
        {
          "text": "[Berufsgruppe] sind zufrieden bei Muster GmbH:: ⌀ 4,8/5 Sterne. Wann bist du dabei?",
          "title": "[Vorteile] Überschrift #10",
          "id": 100059
        },
        {
          "text": "Du + [Emoji, z.B. ⚒️] = ❤️",
          "title": "[Vorteile] Überschrift #11",
          "id": 100060
        },
        {
          "text": "Dein neuer Job wartet",
          "title": "[Vorteile] Überschrift #12",
          "id": 100061
        },
        {
          "text": "✨  In 53 Sekunden Traumkarriere entdecken",
          "title": "[Quiz] Überschrift #1",
          "id": 100197
        },
        {
          "text": "Finde heraus, ob du zu uns passt",
          "title": "[Quiz] Überschrift #2",
          "id": 100198
        },
        {
          "text": "🔍 Entdecke das Potenzial als Pflegekraft (m/w/d) in 53 Sekunden",
          "title": "[Quiz] Überschrift #3",
          "id": 100199
        },
        {
          "text": "Job-Zufriedenheit bei Pflegekräften (m/w/d): ⌀ 4,8/5 Sterne. Wann bist du dabei?",
          "title": "[Quiz] Überschrift #4",
          "id": 100200
        },
        {
          "text": "Du + [Emoji, z.B. 🏥] = Top-Karriere",
          "title": "[Quiz] Überschrift #5",
          "id": 100201
        },
        {
          "text": "Dein Ausbildungsplatz wartet. Nur 53 Sekunden.",
          "title": "[Quiz] Überschrift #6",
          "id": 100202
        },
        {
          "text": "✨ Traumjob in 2 Minuten sichern",
          "title": "[Story] Überschrift #1",
          "id": 100311
        },
        {
          "text": "Bekomme endlich das, was du verdienst",
          "title": "[Story] Überschrift #2",
          "id": 100312
        },
        {
          "text": "Bewerben, ohne Lebenslauf in 2 Minuten",
          "title": "[Story] Überschrift #3",
          "id": 100313
        },
        {
          "text": "Werde Teil des Teams",
          "title": "[Story] Überschrift #4",
          "id": 100314
        },
        {
          "text": "🔍 Gesucht: Handwerker (m/w/d) in [Stadt]",
          "title": "[Story] Überschrift #5",
          "id": 100315
        },
        {
          "text": "[Vorteil] & [Vorteil] & [Vorteil]",
          "title": "[Story] Überschrift #6",
          "id": 100316
        },
        {
          "text": "Handwerker (m/w/d) ❤️ Muster GmbH",
          "title": "[Story] Überschrift #7",
          "id": 100317
        },
        {
          "text": "Bewerbung abschließen & Top-Gehalt sichern",
          "title": "[Story] Überschrift #8",
          "id": 100318
        },
        {
          "text": "Hier direkt bewerben",
          "title": "[Story] Überschrift #9",
          "id": 100319
        },
        {
          "text": "[Berufsgruppe] sind zufrieden bei Muster GmbH:: ⌀ 4,8/5 Sterne. Wann bist du dabei?",
          "title": "[Story] Überschrift #10",
          "id": 100320
        },
        {
          "text": "Du + [Emoji, z.B. ⚒️] = ❤️",
          "title": "[Story] Überschrift #11",
          "id": 100321
        },
        {
          "text": "Dein neuer Job wartet",
          "title": "[Story] Überschrift #12",
          "id": 100322
        }
      ]
    },
    {
      "category": "Handel & Vertrieb",
      "content": [
        {
          "text": "✨ Traumjob in 2 Minuten sichern",
          "title": "[Vorteile] Überschrift #1",
          "id": 100081
        },
        {
          "text": "Bekomme endlich das, was du verdienst",
          "title": "[Vorteile] Überschrift #2",
          "id": 100082
        },
        {
          "text": "Bewerben, ohne Lebenslauf in 2 Minuten",
          "title": "[Vorteile] Überschrift #3",
          "id": 100083
        },
        {
          "text": "Werde Teil des Teams",
          "title": "[Vorteile] Überschrift #4",
          "id": 100084
        },
        {
          "text": "🔍 Gesucht: Kaufmann/frau (m/w/d) in [Stadt]",
          "title": "[Vorteile] Überschrift #5",
          "id": 100085
        },
        {
          "text": "[Vorteil] & [Vorteil] & [Vorteil]",
          "title": "[Vorteile] Überschrift #6",
          "id": 100086
        },
        {
          "text": "Kaufleute (m/w/d) ❤️ Muster GmbH",
          "title": "[Vorteile] Überschrift #7",
          "id": 100087
        },
        {
          "text": "Bewerbung abschließen & Top-Gehalt sichern",
          "title": "[Vorteile] Überschrift #8",
          "id": 100088
        },
        {
          "text": "Hier direkt bewerben",
          "title": "[Vorteile] Überschrift #9",
          "id": 100089
        },
        {
          "text": "Kaufleute (m/w/d) sind zufrieden bei Muster GmbH:: ⌀ 4,8/5 Sterne. Wann bist du dabei?",
          "title": "[Vorteile] Überschrift #10",
          "id": 100090
        },
        {
          "text": "Du + [Emoji, z.B. 📞] = ❤️",
          "title": "[Vorteile] Überschrift #11",
          "id": 100091
        },
        {
          "text": "Dein neuer Job wartet",
          "title": "[Vorteile] Überschrift #12",
          "id": 100092
        },
        {
          "text": "✨  In 53 Sekunden Traumkarriere entdecken",
          "title": "[Quiz] Überschrift #1",
          "id": 100221
        },
        {
          "text": "Finde heraus, ob du zu uns passt",
          "title": "[Quiz] Überschrift #2",
          "id": 100222
        },
        {
          "text": "🔍 Entdecke das Potenzial als Kaufmann/frau (m/w/d) in 53 Sekunden",
          "title": "[Quiz] Überschrift #3",
          "id": 100223
        },
        {
          "text": "Job-Zufriedenheit bei Kaufleuten (m/w/d): ⌀ 4,8/5 Sterne. Wann bist du dabei?",
          "title": "[Quiz] Überschrift #4",
          "id": 100224
        },
        {
          "text": "Du + [Emoji, z.B. 📞] = Top-Karriere",
          "title": "[Quiz] Überschrift #5",
          "id": 100225
        },
        {
          "text": "Dein Ausbildungsplatz wartet. Nur 53 Sekunden.",
          "title": "[Quiz] Überschrift #6",
          "id": 100226
        },
        {
          "text": "✨ Traumjob in 2 Minuten sichern",
          "title": "[Story] Überschrift #1",
          "id": 100335
        },
        {
          "text": "Bekomme endlich das, was du verdienst",
          "title": "[Story] Überschrift #2",
          "id": 100336
        },
        {
          "text": "Bewerben, ohne Lebenslauf in 2 Minuten",
          "title": "[Story] Überschrift #3",
          "id": 100337
        },
        {
          "text": "Werde Teil des Teams",
          "title": "[Story] Überschrift #4",
          "id": 100338
        },
        {
          "text": "🔍 Gesucht: Kaufmann/frau (m/w/d) in [Stadt]",
          "title": "[Story] Überschrift #5",
          "id": 100339
        },
        {
          "text": "[Vorteil] & [Vorteil] & [Vorteil]",
          "title": "[Story] Überschrift #6",
          "id": 100340
        },
        {
          "text": "Kaufleute (m/w/d) ❤️ Muster GmbH",
          "title": "[Story] Überschrift #7",
          "id": 100341
        },
        {
          "text": "Bewerbung abschließen & Top-Gehalt sichern",
          "title": "[Story] Überschrift #8",
          "id": 100342
        },
        {
          "text": "Hier direkt bewerben",
          "title": "[Story] Überschrift #9",
          "id": 100343
        },
        {
          "text": "Kaufleute (m/w/d) sind zufrieden bei Muster GmbH:: ⌀ 4,8/5 Sterne. Wann bist du dabei?",
          "title": "[Story] Überschrift #10",
          "id": 100344
        },
        {
          "text": "Du + [Emoji, z.B. 📞] = ❤️",
          "title": "[Story] Überschrift #11",
          "id": 100345
        },
        {
          "text": "Dein neuer Job wartet",
          "title": "[Story] Überschrift #12",
          "id": 100346
        }
      ]
    },
    {
      "category": "Gastronomie",
      "content": [
        {
          "text": "✨ Traumjob in 2 Minuten sichern",
          "title": "[Vorteile] Überschrift #1",
          "id": 100112
        },
        {
          "text": "Bekomme endlich das, was du verdienst",
          "title": "[Vorteile] Überschrift #2",
          "id": 100113
        },
        {
          "text": "Bewerben, ohne Lebenslauf in 2 Minuten",
          "title": "[Vorteile] Überschrift #3",
          "id": 100114
        },
        {
          "text": "Werde Teil des Teams",
          "title": "[Vorteile] Überschrift #4",
          "id": 100115
        },
        {
          "text": "🔍 Gesucht: Köche (m/w/d) in [Stadt]",
          "title": "[Vorteile] Überschrift #5",
          "id": 100116
        },
        {
          "text": "[Vorteil] & [Vorteil] & [Vorteil]",
          "title": "[Vorteile] Überschrift #6",
          "id": 100117
        },
        {
          "text": "Köche (m/w/d) ❤️ Muster GmbH",
          "title": "[Vorteile] Überschrift #7",
          "id": 100118
        },
        {
          "text": "Bewerbung abschließen & Top-Gehalt sichern",
          "title": "[Vorteile] Überschrift #8",
          "id": 100119
        },
        {
          "text": "Hier direkt bewerben",
          "title": "[Vorteile] Überschrift #9",
          "id": 100120
        },
        {
          "text": "Köche (m/w/d) sind zufrieden bei Muster GmbH:: ⌀ 4,8/5 Sterne. Wann bist du dabei?",
          "title": "[Vorteile] Überschrift #10",
          "id": 100121
        },
        {
          "text": "Du + [Emoji, z.B. 🍳] = ❤️",
          "title": "[Vorteile] Überschrift #11",
          "id": 100122
        },
        {
          "text": "Dein neuer Job wartet",
          "title": "[Vorteile] Überschrift #12",
          "id": 100123
        },
        {
          "text": "✨  In 53 Sekunden Traumkarriere entdecken",
          "title": "[Quiz] Überschrift #1",
          "id": 100245
        },
        {
          "text": "Finde heraus, ob du zu uns passt",
          "title": "[Quiz] Überschrift #2",
          "id": 100246
        },
        {
          "text": "🔍 Entdecke das Potenzial als Koch (m/w/d) in 53 Sekunden",
          "title": "[Quiz] Überschrift #3",
          "id": 100247
        },
        {
          "text": "Job-Zufriedenheit bei Köchen (m/w/d): ⌀ 4,8/5 Sterne. Wann bist du dabei?",
          "title": "[Quiz] Überschrift #4",
          "id": 100248
        },
        {
          "text": "Du + [Emoji, z.B. 🍳] = Top-Karriere",
          "title": "[Quiz] Überschrift #5",
          "id": 100249
        },
        {
          "text": "Dein Ausbildungsplatz wartet. Nur 53 Sekunden.",
          "title": "[Quiz] Überschrift #6",
          "id": 100250
        },
        {
          "text": "✨ Traumjob in 2 Minuten sichern",
          "title": "[Story] Überschrift #1",
          "id": 100359
        },
        {
          "text": "Bekomme endlich das, was du verdienst",
          "title": "[Story] Überschrift #2",
          "id": 100360
        },
        {
          "text": "Bewerben, ohne Lebenslauf in 2 Minuten",
          "title": "[Story] Überschrift #3",
          "id": 100361
        },
        {
          "text": "Werde Teil des Teams",
          "title": "[Story] Überschrift #4",
          "id": 100362
        },
        {
          "text": "🔍 Gesucht: Köche (m/w/d) in [Stadt]",
          "title": "[Story] Überschrift #5",
          "id": 100363
        },
        {
          "text": "[Vorteil] & [Vorteil] & [Vorteil]",
          "title": "[Story] Überschrift #6",
          "id": 100364
        },
        {
          "text": "Köche (m/w/d) ❤️ Muster GmbH",
          "title": "[Story] Überschrift #7",
          "id": 100365
        },
        {
          "text": "Bewerbung abschließen & Top-Gehalt sichern",
          "title": "[Story] Überschrift #8",
          "id": 100366
        },
        {
          "text": "Hier direkt bewerben",
          "title": "[Story] Überschrift #9",
          "id": 100367
        },
        {
          "text": "Köche (m/w/d) sind zufrieden bei Muster GmbH:: ⌀ 4,8/5 Sterne. Wann bist du dabei?",
          "title": "[Story] Überschrift #10",
          "id": 100368
        },
        {
          "text": "Du + [Emoji, z.B. 🍳] = ❤️",
          "title": "[Story] Überschrift #11",
          "id": 100369
        },
        {
          "text": "Dein neuer Job wartet",
          "title": "[Story] Überschrift #12",
          "id": 100370
        }
      ]
    },
    {
      "category": "Industrie",
      "content": [
        {
          "text": "✨ Traumjob in 2 Minuten sichern",
          "title": "[Vorteile] Überschrift #1",
          "id": 100143
        },
        {
          "text": "Bekomme endlich das, was du verdienst",
          "title": "[Vorteile] Überschrift #2",
          "id": 100144
        },
        {
          "text": "Bewerben, ohne Lebenslauf in 2 Minuten",
          "title": "[Vorteile] Überschrift #3",
          "id": 100145
        },
        {
          "text": "Werde Teil des Teams",
          "title": "[Vorteile] Überschrift #4",
          "id": 100146
        },
        {
          "text": "🔍 Gesucht: Staplerfahrer (m/w/d) in [Stadt]",
          "title": "[Vorteile] Überschrift #5",
          "id": 100147
        },
        {
          "text": "[Vorteil] & [Vorteil] & [Vorteil]",
          "title": "[Vorteile] Überschrift #6",
          "id": 100148
        },
        {
          "text": "Staplerfahrer (m/w/d) ❤️ Muster GmbH",
          "title": "[Vorteile] Überschrift #7",
          "id": 100149
        },
        {
          "text": "Bewerbung abschließen & Top-Gehalt sichern",
          "title": "[Vorteile] Überschrift #8",
          "id": 100150
        },
        {
          "text": "Hier direkt bewerben",
          "title": "[Vorteile] Überschrift #9",
          "id": 100151
        },
        {
          "text": "Staplerfahrer (m/w/d) sind zufrieden bei Muster GmbH:: ⌀ 4,8/5 Sterne. Wann bist du dabei?",
          "title": "[Vorteile] Überschrift #10",
          "id": 100152
        },
        {
          "text": "Du + [Emoji, z.B. 🔧] = ❤️",
          "title": "[Vorteile] Überschrift #11",
          "id": 100153
        },
        {
          "text": "Dein neuer Job wartet",
          "title": "[Vorteile] Überschrift #12",
          "id": 100154
        },
        {
          "text": "✨  In 53 Sekunden Traumkarriere entdecken",
          "title": "[Quiz] Überschrift #1",
          "id": 100269
        },
        {
          "text": "Finde heraus, ob du zu uns passt",
          "title": "[Quiz] Überschrift #2",
          "id": 100270
        },
        {
          "text": "🔍 Entdecke das Potenzial als Staplerfahrer (m/w/d) in 53 Sekunden",
          "title": "[Quiz] Überschrift #3",
          "id": 100271
        },
        {
          "text": "Job-Zufriedenheit bei Staplerfahrern (m/w/d): ⌀ 4,8/5 Sterne. Wann bist du dabei?",
          "title": "[Quiz] Überschrift #4",
          "id": 100272
        },
        {
          "text": "Du + [Emoji, z.B. 🔧] = Top-Karriere",
          "title": "[Quiz] Überschrift #5",
          "id": 100273
        },
        {
          "text": "Dein Ausbildungsplatz wartet. Nur 53 Sekunden.",
          "title": "[Quiz] Überschrift #6",
          "id": 100274
        },
        {
          "text": "✨ Traumjob in 2 Minuten sichern",
          "title": "[Story] Überschrift #1",
          "id": 100383
        },
        {
          "text": "Bekomme endlich das, was du verdienst",
          "title": "[Story] Überschrift #2",
          "id": 100384
        },
        {
          "text": "Bewerben, ohne Lebenslauf in 2 Minuten",
          "title": "[Story] Überschrift #3",
          "id": 100385
        },
        {
          "text": "Werde Teil des Teams",
          "title": "[Story] Überschrift #4",
          "id": 100386
        },
        {
          "text": "🔍 Gesucht: Staplerfahrer (m/w/d) in [Stadt]",
          "title": "[Story] Überschrift #5",
          "id": 100387
        },
        {
          "text": "[Vorteil] & [Vorteil] & [Vorteil]",
          "title": "[Story] Überschrift #6",
          "id": 100388
        },
        {
          "text": "Staplerfahrer (m/w/d) ❤️ Muster GmbH",
          "title": "[Story] Überschrift #7",
          "id": 100389
        },
        {
          "text": "Bewerbung abschließen & Top-Gehalt sichern",
          "title": "[Story] Überschrift #8",
          "id": 100390
        },
        {
          "text": "Hier direkt bewerben",
          "title": "[Story] Überschrift #9",
          "id": 100391
        },
        {
          "text": "Staplerfahrer (m/w/d) sind zufrieden bei Muster GmbH:: ⌀ 4,8/5 Sterne. Wann bist du dabei?",
          "title": "[Story] Überschrift #10",
          "id": 100392
        },
        {
          "text": "Du + [Emoji, z.B. 🔧] = ❤️",
          "title": "[Story] Überschrift #11",
          "id": 100393
        },
        {
          "text": "Dein neuer Job wartet",
          "title": "[Story] Überschrift #12",
          "id": 100394
        }
      ]
    }
  ]
};

export default headlineTextData;
