const ctaTextData = {
  title: 'Call to action',
  data: [
    {
      "category": "Gesundheitswesen",
      "content": [
        {
          "text": "… dann bewirb dich jetzt bei uns als Pflegekraft (m/w/d). Klicke dafür unten einfach nur auf Button.\r\n\r\nDauert nicht länger als zwei Minuten. Kein Lebenslauf nötig. Versprochen! 🤝",
          "title": "[Vorteile] Kein Lebenslauf nötig",
          "id": 100014
        },
        {
          "text": "Klingt gut? Klicke jetzt auf \"Mehr dazu\" und schau, ob wir zueinander passen.\r\nBewirb dich in unter 2 Minuten bei uns - ganz ohne Lebenslauf.",
          "title": "[Vorteile] Passen wir zueinander",
          "id": 100015
        },
        {
          "text": "Interesse? Deine Bewerbung kannst du ohne Anschreiben und Lebenslauf in unter 2 Minuten zukommen lassen. Jetzt auf den Button klicken.\r\n\r\nLass uns gemeinsam…  [Unternehmensvision in 1-2 Sätzen. Höherer Sinn. Die Bedeutung, die die Arbeit hat]",
          "title": "[Vorteile] Vision",
          "id": 100016
        },
        {
          "text": "Wir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig.\r\n\r\nKlicke jetzt auf den Button und beantworte ein paar kurze Fragen (Dauer: maximal 2 Minuten).\r\n\r\nDer Ablauf:\r\n1. Klicke auf den Button und beantworte die kurzen Fragen\r\n2. Du erhältst Termine für ein Kennenlerngespräch\r\n3. Wenn wir zueinander passen, machen wir Dir ein attraktives Jobangebot",
          "title": "[Vorteile] Nächste Schritte",
          "id": 100017
        },
        {
          "text": "Unglücklich im jetzigen Job? Wenn du keine Lust mehr hast, jeden Tag bedeutungslose Arbeit zu machen, dann melde dich bei uns. \r\n\r\nWir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig. Jetzt auf den Button klicken und in 2 Minuten bewerben.",
          "title": "[Vorteile] Unglücklich im jetzigen Job",
          "id": 100018
        },
        {
          "text": "Hast du das Zeug zur Pflegekraft (m(w/d)? Finde es in nur 53 Sekunden heraus.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!",
          "title": "[Quiz] Hast du das Zeug?",
          "id": 100168
        },
        {
          "text": "🚀 Jetzt klicken und finde in nur 53 Sekunden heraus, ob dieser Job zu dir passt.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!",
          "title": "[Quiz] Passt dieser Job?",
          "id": 100169
        },
        {
          "text": "Klingt gut?  \r\n\r\nDann finde jetzt in 53 Sekunden heraus, ob du Karriere in der Pflege machen kannst.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!",
          "title": "[Quiz] Klingt gut?",
          "id": 100170
        },
        {
          "text": "🔝 Hast du auch Lust auf eine Karriere in der Pflege?\r\n\r\nKlicke jetzt auf den Button und finde in 53 Sekunden heraus, ob du als Pflegekraft (m/w/d) glücklich wirst.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\r\n\r\nWir freuen uns auf dich! ❤️",
          "title": "[Quiz] Lust auf Karriere?",
          "id": 100171
        },
        {
          "text": "Ready für den Ausbildungsstart [z.B. 2023]? Schau es dir jetzt unser #Abiprogramm an und finde in 53 Sekunden heraus, ob du Karriere in der [Branche] machen kannst.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!",
          "title": "[Quiz] Ausbildungsstart",
          "id": 100172
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\n… dann bewirb dich jetzt bei uns als [Berufsgruppe]. Klicke dafür unten einfach nur auf Button.\r\n\r\nDauert nicht länger als zwei Minuten. Kein Lebenslauf nötig. Versprochen! 🤝\r\n",
          "title": "[Story] Kein Lebenslauf nötig",
          "id": 100282
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\nKlingt gut? Klicke jetzt auf \"Mehr dazu\" und schau, ob wir zueinander passen.\r\nBewirb dich in unter 2 Minuten bei uns - ganz ohne Lebenslauf.\r\n",
          "title": "[Story] Passen wir zueinander",
          "id": 100283
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\nInteresse? Deine Bewerbung kannst du ohne Anschreiben und Lebenslauf in unter 2 Minuten zukommen lassen. Jetzt auf den Button klicken.\r\n\r\nLass uns gemeinsam…  [Unternehmensvision in 1-2 Sätzen. Höherer Sinn. Die Bedeutung, die die Arbeit hat]\r\n",
          "title": "[Story] Vision",
          "id": 100284
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\nWir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig.\r\n\r\nKlicke jetzt auf den Button und beantworte ein paar kurze Fragen (Dauer: maximal 2 Minuten).\r\n\r\nDer Ablauf:\r\n1. Klicke auf den Button und beantworte die kurzen Fragen\r\n2. Du erhältst Termine für ein Kennenlerngespräch\r\n3. Wenn wir zueinander passen, machen wir Dir ein attraktives Jobangebot\r\n",
          "title": "[Story] Nächste Schritte",
          "id": 100285
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\nUnglücklich im jetzigen Job? Wenn du keine Lust mehr hast, jeden Tag bedeutungslose Arbeit zu machen, dann melde dich bei uns. \r\n\r\nWir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig. Jetzt auf den Button klicken und in 2 Minuten bewerben.\r\n",
          "title": "[Story] Unglücklich im jetzigen Job",
          "id": 100286
        }
      ]
    },
    {
      "category": "Handwerk",
      "content": [
        {
          "text": "… dann bewirb dich jetzt bei uns als Handwerker (m/w/d). Klicke dafür unten einfach nur auf Button.\r\n\r\nDauert nicht länger als zwei Minuten. Kein Lebenslauf nötig. Versprochen! 🤝",
          "title": "[Vorteile] Kein Lebenslauf nötig",
          "id": 100045
        },
        {
          "text": "Klingt gut? Klicke jetzt auf \"Mehr dazu\" und schau, ob wir zueinander passen.\r\nBewirb dich in unter 2 Minuten bei uns - ganz ohne Lebenslauf.",
          "title": "[Vorteile] Passen wir zueinander",
          "id": 100046
        },
        {
          "text": "Interesse? Deine Bewerbung kannst du ohne Anschreiben und Lebenslauf in unter 2 Minuten zukommen lassen. Jetzt auf den Button klicken.\r\n\r\nLass uns gemeinsam…  [Unternehmensvision in 1-2 Sätzen. Höherer Sinn. Die Bedeutung, die die Arbeit hat]",
          "title": "[Vorteile] Vision",
          "id": 100047
        },
        {
          "text": "Wir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig.\r\n\r\nKlicke jetzt auf den Button und beantworte ein paar kurze Fragen (Dauer: maximal 2 Minuten).\r\n\r\nDer Ablauf:\r\n1. Klicke auf den Button und beantworte die kurzen Fragen\r\n2. Du erhältst Termine für ein Kennenlerngespräch\r\n3. Wenn wir zueinander passen, machen wir Dir ein attraktives Jobangebot",
          "title": "[Vorteile] Nächste Schritte",
          "id": 100048
        },
        {
          "text": "Unglücklich im jetzigen Job? Wenn du keine Lust mehr hast, jeden Tag bedeutungslose Arbeit zu machen, dann melde dich bei uns. \r\n\r\nWir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig. Jetzt auf den Button klicken und in 2 Minuten bewerben.",
          "title": "[Vorteile] Unglücklich im jetzigen Job",
          "id": 100049
        },
        {
          "text": "Hast du das Zeug zum Handwerker (m/w/d)? Finde es in nur 53 Sekunden heraus.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!",
          "title": "[Quiz] Hast du das Zeug?",
          "id": 100192
        },
        {
          "text": "🚀 Jetzt klicken und finde in nur 53 Sekunden heraus, ob dieser Job zu dir passt.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!",
          "title": "[Quiz] Passt dieser Job?",
          "id": 100193
        },
        {
          "text": "Klingt gut?  \r\n\r\nDann finde jetzt in 53 Sekunden heraus, ob du Karriere im Handwerk machen kannst.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!",
          "title": "[Quiz] Klingt gut?",
          "id": 100194
        },
        {
          "text": "🔝 Hast du auch Lust auf eine Karriere im Handwerk?\r\n\r\nKlicke jetzt auf den Button und finde in 53 Sekunden heraus, ob du als [Berufsgruppe] glücklich wirst.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\r\n\r\nWir freuen uns auf dich! ❤️",
          "title": "[Quiz] Lust auf Karriere?",
          "id": 100195
        },
        {
          "text": "Ready für den Ausbildungsstart [z.B. 2023]? Schau es dir jetzt unser #Abiprogramm an und finde in 53 Sekunden heraus, ob du Karriere in der [Branche] machen kannst.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!",
          "title": "[Quiz] Ausbildungsstart",
          "id": 100196
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\n… dann bewirb dich jetzt bei uns als [Berufsgruppe]. Klicke dafür unten einfach nur auf Button.\r\n\r\nDauert nicht länger als zwei Minuten. Kein Lebenslauf nötig. Versprochen! 🤝\r\n",
          "title": "[Story] Kein Lebenslauf nötig",
          "id": 100306
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\nKlingt gut? Klicke jetzt auf \"Mehr dazu\" und schau, ob wir zueinander passen.\r\nBewirb dich in unter 2 Minuten bei uns - ganz ohne Lebenslauf.\r\n",
          "title": "[Story] Passen wir zueinander",
          "id": 100307
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\nInteresse? Deine Bewerbung kannst du ohne Anschreiben und Lebenslauf in unter 2 Minuten zukommen lassen. Jetzt auf den Button klicken.\r\n\r\nLass uns gemeinsam…  [Unternehmensvision in 1-2 Sätzen. Höherer Sinn. Die Bedeutung, die die Arbeit hat]\r\n",
          "title": "[Story] Vision",
          "id": 100308
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\nWir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig.\r\n\r\nKlicke jetzt auf den Button und beantworte ein paar kurze Fragen (Dauer: maximal 2 Minuten).\r\n\r\nDer Ablauf:\r\n1. Klicke auf den Button und beantworte die kurzen Fragen\r\n2. Du erhältst Termine für ein Kennenlerngespräch\r\n3. Wenn wir zueinander passen, machen wir Dir ein attraktives Jobangebot\r\n",
          "title": "[Story] Nächste Schritte",
          "id": 100309
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\nUnglücklich im jetzigen Job? Wenn du keine Lust mehr hast, jeden Tag bedeutungslose Arbeit zu machen, dann melde dich bei uns. \r\n\r\nWir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig. Jetzt auf den Button klicken und in 2 Minuten bewerben.\r\n",
          "title": "[Story] Unglücklich im jetzigen Job",
          "id": 100310
        }
      ]
    },
    {
      "category": "Handel & Vertrieb",
      "content": [
        {
          "text": "… dann bewirb dich jetzt bei uns als Kaufmann/frau (m/w/d). Klicke dafür unten einfach nur auf Button.\r\n\r\nDauert nicht länger als zwei Minuten. Kein Lebenslauf nötig. Versprochen! 🤝",
          "title": "[Vorteile] Kein Lebenslauf nötig",
          "id": 100076
        },
        {
          "text": "Klingt gut? Klicke jetzt auf \"Mehr dazu\" und schau, ob wir zueinander passen.\r\nBewirb dich in unter 2 Minuten bei uns - ganz ohne Lebenslauf.",
          "title": "[Vorteile] Passen wir zueinander",
          "id": 100077
        },
        {
          "text": "Interesse? Deine Bewerbung kannst du ohne Anschreiben und Lebenslauf in unter 2 Minuten zukommen lassen. Jetzt auf den Button klicken.\r\n\r\nLass uns gemeinsam…  [Unternehmensvision in 1-2 Sätzen. Höherer Sinn. Die Bedeutung, die die Arbeit hat]",
          "title": "[Vorteile] Vision",
          "id": 100078
        },
        {
          "text": "Wir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig.\r\n\r\nKlicke jetzt auf den Button und beantworte ein paar kurze Fragen (Dauer: maximal 2 Minuten).\r\n\r\nDer Ablauf:\r\n1. Klicke auf den Button und beantworte die kurzen Fragen\r\n2. Du erhältst Termine für ein Kennenlerngespräch\r\n3. Wenn wir zueinander passen, machen wir Dir ein attraktives Jobangebot",
          "title": "[Vorteile] Nächste Schritte",
          "id": 100079
        },
        {
          "text": "Unglücklich im jetzigen Job? Wenn du keine Lust mehr hast, jeden Tag bedeutungslose Arbeit zu machen, dann melde dich bei uns. \r\n\r\nWir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig. Jetzt auf den Button klicken und in 2 Minuten bewerben.",
          "title": "[Vorteile] Unglücklich im jetzigen Job",
          "id": 100080
        },
        {
          "text": "Hast du das Zeug zum Kaufmann/frau (m/w/d)? Finde es in nur 53 Sekunden heraus.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!",
          "title": "[Quiz] Hast du das Zeug?",
          "id": 100216
        },
        {
          "text": "🚀 Jetzt klicken und finde in nur 53 Sekunden heraus, ob dieser Job zu dir passt.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!",
          "title": "[Quiz] Passt dieser Job?",
          "id": 100217
        },
        {
          "text": "Klingt gut?  \r\n\r\nDann finde jetzt in 53 Sekunden heraus, ob du Karriere im Handel machen kannst.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!",
          "title": "[Quiz] Klingt gut?",
          "id": 100218
        },
        {
          "text": "🔝 Hast du auch Lust auf eine Karriere im Handel?\r\n\r\nKlicke jetzt auf den Button und finde in 53 Sekunden heraus, ob du als [Berufsgruppe] glücklich wirst.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\r\n\r\nWir freuen uns auf dich! ❤️",
          "title": "[Quiz] Lust auf Karriere?",
          "id": 100219
        },
        {
          "text": "Ready für den Ausbildungsstart [z.B. 2023]? Schau es dir jetzt unser #Abiprogramm an und finde in 53 Sekunden heraus, ob du Karriere in der [Branche] machen kannst.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!",
          "title": "[Quiz] Ausbildungsstart",
          "id": 100220
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\n… dann bewirb dich jetzt bei uns als [Berufsgruppe]. Klicke dafür unten einfach nur auf Button.\r\n\r\nDauert nicht länger als zwei Minuten. Kein Lebenslauf nötig. Versprochen! 🤝\r\n",
          "title": "[Story] Kein Lebenslauf nötig",
          "id": 100330
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\nKlingt gut? Klicke jetzt auf \"Mehr dazu\" und schau, ob wir zueinander passen.\r\nBewirb dich in unter 2 Minuten bei uns - ganz ohne Lebenslauf.\r\n",
          "title": "[Story] Passen wir zueinander",
          "id": 100331
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\nInteresse? Deine Bewerbung kannst du ohne Anschreiben und Lebenslauf in unter 2 Minuten zukommen lassen. Jetzt auf den Button klicken.\r\n\r\nLass uns gemeinsam…  [Unternehmensvision in 1-2 Sätzen. Höherer Sinn. Die Bedeutung, die die Arbeit hat]\r\n",
          "title": "[Story] Vision",
          "id": 100332
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\nWir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig.\r\n\r\nKlicke jetzt auf den Button und beantworte ein paar kurze Fragen (Dauer: maximal 2 Minuten).\r\n\r\nDer Ablauf:\r\n1. Klicke auf den Button und beantworte die kurzen Fragen\r\n2. Du erhältst Termine für ein Kennenlerngespräch\r\n3. Wenn wir zueinander passen, machen wir Dir ein attraktives Jobangebot\r\n",
          "title": "[Story] Nächste Schritte",
          "id": 100333
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\nUnglücklich im jetzigen Job? Wenn du keine Lust mehr hast, jeden Tag bedeutungslose Arbeit zu machen, dann melde dich bei uns. \r\n\r\nWir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig. Jetzt auf den Button klicken und in 2 Minuten bewerben.\r\n",
          "title": "[Story] Unglücklich im jetzigen Job",
          "id": 100334
        }
      ]
    },
    {
      "category": "Gastronomie",
      "content": [
        {
          "text": "… dann bewirb dich jetzt bei uns als Kaufmann/frau (m/w/d). Klicke dafür unten einfach nur auf Button.\r\n\r\nDauert nicht länger als zwei Minuten. Kein Lebenslauf nötig. Versprochen! 🤝",
          "title": "[Vorteile] Kein Lebenslauf nötig",
          "id": 100107
        },
        {
          "text": "Klingt gut? Klicke jetzt auf \"Mehr dazu\" und schau, ob wir zueinander passen.\r\nBewirb dich in unter 2 Minuten bei uns - ganz ohne Lebenslauf.",
          "title": "[Vorteile] Passen wir zueinander",
          "id": 100108
        },
        {
          "text": "Interesse? Deine Bewerbung kannst du ohne Anschreiben und Lebenslauf in unter 2 Minuten zukommen lassen. Jetzt auf den Button klicken.\r\n\r\nLass uns gemeinsam…  [Unternehmensvision in 1-2 Sätzen. Höherer Sinn. Die Bedeutung, die die Arbeit hat]",
          "title": "[Vorteile] Vision",
          "id": 100109
        },
        {
          "text": "Wir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig.\r\n\r\nKlicke jetzt auf den Button und beantworte ein paar kurze Fragen (Dauer: maximal 2 Minuten).\r\n\r\nDer Ablauf:\r\n1. Klicke auf den Button und beantworte die kurzen Fragen\r\n2. Du erhältst Termine für ein Kennenlerngespräch\r\n3. Wenn wir zueinander passen, machen wir Dir ein attraktives Jobangebot",
          "title": "[Vorteile] Nächste Schritte",
          "id": 100110
        },
        {
          "text": "Unglücklich im jetzigen Job? Wenn du keine Lust mehr hast, jeden Tag bedeutungslose Arbeit zu machen, dann melde dich bei uns. \r\n\r\nWir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig. Jetzt auf den Button klicken und in 2 Minuten bewerben.",
          "title": "[Vorteile] Unglücklich im jetzigen Job",
          "id": 100111
        },
        {
          "text": "Hast du das Zeug zum Koch (m/w/d)? Finde es in nur 53 Sekunden heraus.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!",
          "title": "[Quiz] Hast du das Zeug?",
          "id": 100240
        },
        {
          "text": "🚀 Jetzt klicken und finde in nur 53 Sekunden heraus, ob dieser Job zu dir passt.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!",
          "title": "[Quiz] Passt dieser Job?",
          "id": 100241
        },
        {
          "text": "Klingt gut?  \r\n\r\nDann finde jetzt in 53 Sekunden heraus, ob du Karriere in der Gastronomie machen kannst.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!",
          "title": "[Quiz] Klingt gut?",
          "id": 100242
        },
        {
          "text": "🔝 Hast du auch Lust auf eine Karriere in der Gastronomie?\r\n\r\nKlicke jetzt auf den Button und finde in 53 Sekunden heraus, ob du als [Berufsgruppe] glücklich wirst.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\r\n\r\nWir freuen uns auf dich! ❤️",
          "title": "[Quiz] Lust auf Karriere?",
          "id": 100243
        },
        {
          "text": "Ready für den Ausbildungsstart [z.B. 2023]? Schau es dir jetzt unser #Abiprogramm an und finde in 53 Sekunden heraus, ob du Karriere in der [Branche] machen kannst.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!",
          "title": "[Quiz] Ausbildungsstart",
          "id": 100244
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\n… dann bewirb dich jetzt bei uns als [Berufsgruppe]. Klicke dafür unten einfach nur auf Button.\r\n\r\nDauert nicht länger als zwei Minuten. Kein Lebenslauf nötig. Versprochen! 🤝\r\n",
          "title": "[Story] Kein Lebenslauf nötig",
          "id": 100354
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\nKlingt gut? Klicke jetzt auf \"Mehr dazu\" und schau, ob wir zueinander passen.\r\nBewirb dich in unter 2 Minuten bei uns - ganz ohne Lebenslauf.\r\n",
          "title": "[Story] Passen wir zueinander",
          "id": 100355
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\nInteresse? Deine Bewerbung kannst du ohne Anschreiben und Lebenslauf in unter 2 Minuten zukommen lassen. Jetzt auf den Button klicken.\r\n\r\nLass uns gemeinsam…  [Unternehmensvision in 1-2 Sätzen. Höherer Sinn. Die Bedeutung, die die Arbeit hat]\r\n",
          "title": "[Story] Vision",
          "id": 100356
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\nWir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig.\r\n\r\nKlicke jetzt auf den Button und beantworte ein paar kurze Fragen (Dauer: maximal 2 Minuten).\r\n\r\nDer Ablauf:\r\n1. Klicke auf den Button und beantworte die kurzen Fragen\r\n2. Du erhältst Termine für ein Kennenlerngespräch\r\n3. Wenn wir zueinander passen, machen wir Dir ein attraktives Jobangebot\r\n",
          "title": "[Story] Nächste Schritte",
          "id": 100357
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\nUnglücklich im jetzigen Job? Wenn du keine Lust mehr hast, jeden Tag bedeutungslose Arbeit zu machen, dann melde dich bei uns. \r\n\r\nWir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig. Jetzt auf den Button klicken und in 2 Minuten bewerben.\r\n",
          "title": "[Story] Unglücklich im jetzigen Job",
          "id": 100358
        }
      ]
    },
    {
      "category": "Industrie",
      "content": [
        {
          "text": "… dann bewirb dich jetzt bei uns als Kaufmann/frau (m/w/d). Klicke dafür unten einfach nur auf Button.\r\n\r\nDauert nicht länger als zwei Minuten. Kein Lebenslauf nötig. Versprochen! 🤝",
          "title": "[Vorteile] Kein Lebenslauf nötig",
          "id": 100138
        },
        {
          "text": "Klingt gut? Klicke jetzt auf \"Mehr dazu\" und schau, ob wir zueinander passen.\r\nBewirb dich in unter 2 Minuten bei uns - ganz ohne Lebenslauf.",
          "title": "[Vorteile] Passen wir zueinander",
          "id": 100139
        },
        {
          "text": "Interesse? Deine Bewerbung kannst du ohne Anschreiben und Lebenslauf in unter 2 Minuten zukommen lassen. Jetzt auf den Button klicken.\r\n\r\nLass uns gemeinsam…  [Unternehmensvision in 1-2 Sätzen. Höherer Sinn. Die Bedeutung, die die Arbeit hat]",
          "title": "[Vorteile] Vision",
          "id": 100140
        },
        {
          "text": "Wir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig.\r\n\r\nKlicke jetzt auf den Button und beantworte ein paar kurze Fragen (Dauer: maximal 2 Minuten).\r\n\r\nDer Ablauf:\r\n1. Klicke auf den Button und beantworte die kurzen Fragen\r\n2. Du erhältst Termine für ein Kennenlerngespräch\r\n3. Wenn wir zueinander passen, machen wir Dir ein attraktives Jobangebot",
          "title": "[Vorteile] Nächste Schritte",
          "id": 100141
        },
        {
          "text": "Unglücklich im jetzigen Job? Wenn du keine Lust mehr hast, jeden Tag bedeutungslose Arbeit zu machen, dann melde dich bei uns. \r\n\r\nWir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig. Jetzt auf den Button klicken und in 2 Minuten bewerben.",
          "title": "[Vorteile] Unglücklich im jetzigen Job",
          "id": 100142
        },
        {
          "text": "Hast du das Zeug zum Staplerfahrer (m/w/d)? Finde es in nur 53 Sekunden heraus.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!",
          "title": "[Quiz] Hast du das Zeug?",
          "id": 100264
        },
        {
          "text": "🚀 Jetzt klicken und finde in nur 53 Sekunden heraus, ob dieser Job zu dir passt.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!",
          "title": "[Quiz] Passt dieser Job?",
          "id": 100265
        },
        {
          "text": "Klingt gut?  \r\n\r\nDann finde jetzt in 53 Sekunden heraus, ob du Karriere in der Industrie machen kannst.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!",
          "title": "[Quiz] Klingt gut?",
          "id": 100266
        },
        {
          "text": "🔝 Hast du auch Lust auf eine Karriere in der Industrie?\r\n\r\nKlicke jetzt auf den Button und finde in 53 Sekunden heraus, ob du als [Berufsgruppe] glücklich wirst.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\r\n\r\nWir freuen uns auf dich! ❤️",
          "title": "[Quiz] Lust auf Karriere?",
          "id": 100267
        },
        {
          "text": "Ready für den Ausbildungsstart [z.B. 2023]? Schau es dir jetzt unser #Abiprogramm an und finde in 53 Sekunden heraus, ob du Karriere in der [Branche] machen kannst.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!",
          "title": "[Quiz] Ausbildungsstart",
          "id": 100268
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\n… dann bewirb dich jetzt bei uns als [Berufsgruppe]. Klicke dafür unten einfach nur auf Button.\r\n\r\nDauert nicht länger als zwei Minuten. Kein Lebenslauf nötig. Versprochen! 🤝\r\n",
          "title": "[Story] Kein Lebenslauf nötig",
          "id": 100378
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\nKlingt gut? Klicke jetzt auf \"Mehr dazu\" und schau, ob wir zueinander passen.\r\nBewirb dich in unter 2 Minuten bei uns - ganz ohne Lebenslauf.\r\n",
          "title": "[Story] Passen wir zueinander",
          "id": 100379
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\nInteresse? Deine Bewerbung kannst du ohne Anschreiben und Lebenslauf in unter 2 Minuten zukommen lassen. Jetzt auf den Button klicken.\r\n\r\nLass uns gemeinsam…  [Unternehmensvision in 1-2 Sätzen. Höherer Sinn. Die Bedeutung, die die Arbeit hat]\r\n",
          "title": "[Story] Vision",
          "id": 100380
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\nWir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig.\r\n\r\nKlicke jetzt auf den Button und beantworte ein paar kurze Fragen (Dauer: maximal 2 Minuten).\r\n\r\nDer Ablauf:\r\n1. Klicke auf den Button und beantworte die kurzen Fragen\r\n2. Du erhältst Termine für ein Kennenlerngespräch\r\n3. Wenn wir zueinander passen, machen wir Dir ein attraktives Jobangebot\r\n",
          "title": "[Story] Nächste Schritte",
          "id": 100381
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\nUnglücklich im jetzigen Job? Wenn du keine Lust mehr hast, jeden Tag bedeutungslose Arbeit zu machen, dann melde dich bei uns. \r\n\r\nWir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig. Jetzt auf den Button klicken und in 2 Minuten bewerben.\r\n",
          "title": "[Story] Unglücklich im jetzigen Job",
          "id": 100382
        }
      ]
    }
  ]
};

export default ctaTextData;
