import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const GET_ALL_FUNNELS_SKILLS = gql`
  query {
    getAllFunnelsSkills
  }
`;
const useGetAllFunnelsSkills = () => {
  return useQuery(GET_ALL_FUNNELS_SKILLS);
};

export default useGetAllFunnelsSkills;
