import moment from "moment";

export default function formatEventTitle(eventStart: string, eventEnd: string) {
    if(!eventStart) return "Anfrage ohne Termin"

    const eventIsInThisWeek = +moment(eventStart).startOf("isoWeek") === +moment().startOf("isoWeek")
    const eventIsToday = +moment(eventStart).startOf("day") === +moment().startOf("day")
    const eventIsTomorrow = +moment(eventStart).startOf("day") === +moment().add(1, "day").startOf("day")
    let title: string = "";

    if (eventIsToday)
        title = `Heute ${moment(eventStart).format("HH:mm")} - ${moment(eventEnd).format("HH:mm")} Uhr`

    else if (eventIsTomorrow)
        title = `Morgen ${moment(eventStart).format("HH:mm")} - ${moment(eventEnd).format("HH:mm")}Uhr`

    else if (eventIsInThisWeek)
        title = `${moment(eventStart).format("dddd HH:mm")} - ${moment(eventEnd).format("HH:mm")} Uhr`

    else
        title = `${moment(eventStart).format("DD.MM.YYYY HH:mm")} - ${moment(eventEnd).format("HH:mm")} Uhr`

    return title
}