import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment';
import { selectReload } from '../../ProductFruitsWrapper/redux/productFruitsSlice';
import { useNavigate } from 'react-router';
import { useSubscriptionInfo, useSubscriptionInfoLoading } from '../../UI/redux/userSlice';
import { useAppDispatch } from '../../redux/hooks';
import { getCompleteSubscriptionInfo } from '../../UI/redux/userThunk';

const TrailBanner = () => {

  const subscription = useSubscriptionInfo();
  const loading = useSubscriptionInfoLoading();
  const dispatch = useAppDispatch();

  const total: number = useSelector(selectReload);
  const navigate = useNavigate();
  const { status, trialEndDate } = subscription;
  const subscriptionRemaingHours = moment(trialEndDate).diff(moment(), 'hours');
  const subscriptionRemaingDays = Math.ceil(
    (subscriptionRemaingHours >= 0 ? subscriptionRemaingHours : 0) / 24
  );

  useEffect(() => {
    if (!loading) {
      const timeout = setTimeout(() => {
        dispatch(getCompleteSubscriptionInfo());
      }, 6000);

      return () => {
        clearTimeout(timeout);
      };
    }

    return () => {};
  }, [total]);

  if (status === 'TRIALING')
    document
      .querySelector('.ant-layout-header.ui__sub-header-background > h1')
      ?.classList.add('hidden-on__max-sm');

  return (
    <>
      {status === 'TRIALING' && (
        <div className="trail-banner-container">
          <div className="banner-date">
            <div className="date-number">
              <strong>{subscriptionRemaingDays}</strong>
            </div>
            <div className="date-text">Tage</div>
          </div>
          <div className="banner-text">
            Um alle Features zu erleben. <br />
            <span onClick={() => navigate('/checkout')}> Jetzt upgraden </span>
            und online bleiben.
          </div>
        </div>
      )}
    </>
  );
};

export default TrailBanner;
