import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { FunnelThemeType } from '../interfaces/builderSliceTypes';

const CREATE_USER_COLOR_THEME = gql`
  mutation createColorTheme($input: CreateFunnelColorThemeInput!) {
    createFunnelColorTheme(createFunnelColorThemeInput: $input) {
      id
      headlineColor
      accentColorContrast
      textColor
      accentColor
      backgroundColor
      cardBackgroundColor
      cardIconColor
      cardTextColor
      fontFamily
      fontLink
      isCustom
      name
      defaultGradient
    }
  }
`;

export default function createUserColorTheme() {
  return useMutation(CREATE_USER_COLOR_THEME);
}

export function createUserColorThemeAPI(input: FunnelThemeType) {
  return window.apolloClient.mutate({
    mutation: CREATE_USER_COLOR_THEME,
    variables: {
      input: { ...input }
    }
  });
}
