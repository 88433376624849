import React from 'react';
import { SettingsElement, SettingsComponents } from './settingsGbTypes';
import TextAlignmentButtons from '../../../SharedUI/components/TextAlignmentButtons';
import { Slider } from 'antd';
import { getElementColor } from '../../helper/craftJs';
import FontStyleButtons from '../../../SharedUI/components/FontStyleButtons';
import BuilderColorPickerButton from '../sharedUI/BuilderColorPickerButton';
import { useCurrentlyEditingBuilderTheme } from '../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';
import BuilderSettingsTitleWithSwitch from '../sharedUI/BuilderSettingsTitleWithSwitch';

export type SingleSettingRendererProps = {
  element: SettingsElement;
  onChange: (key: string, value: any) => void;
  values: { [key: string]: any };
};

const SingleSettingRenderer = ({ element, onChange, values }: SingleSettingRendererProps) => {
  const value = values[element.key];
  const handleChange = (value: any) => {
    onChange(element.key, value);
  };

  const theme = useCurrentlyEditingBuilderTheme();

  let elementTemp = null;

  const disabled = values?.isPresetOn && !element?.shouldNotDisableWhenPresetIsOn;

  switch (element.component) {
    case SettingsComponents.CUSTOM_COMPONENT:
      const ComponentTag = element?.customComponent;
      elementTemp = <ComponentTag element={element} onChange={onChange} values={values} />;
      break;
    case SettingsComponents.ALIGNMENT_OPTIONS:
      elementTemp = (
        <TextAlignmentButtons
          align={value}
          onChange={handleChange}
          disabled={element.disabled}
          {...element.otherProps}
        />
      );
      break;
    case SettingsComponents.FONT_STYLE_BUTTONS:
      elementTemp = (
        <FontStyleButtons
          hasStyle={() => !!value}
          onChange={handleChange}
          {...element.otherProps}
        />
      );
      break;
    case SettingsComponents.COLOR_PICKER:
      elementTemp = (
        <BuilderColorPickerButton
          color={getElementColor(value, theme)}
          onChange={(color, colorKey) => {
            handleChange(colorKey || color);
          }}
          {...element.otherProps}
        />
      );
      break;
    case SettingsComponents.SWITCH:
      elementTemp = (
        <BuilderSettingsTitleWithSwitch
          title={element?.otherProps?.title}
          checked={value}
          onChange={handleChange}
          {...element.otherProps}
        />
      );
      break;
    case SettingsComponents.SLIDER:
      elementTemp = (
        <Slider
          className="builder-slider-style"
          value={value}
          onChange={handleChange}
          {...element.otherProps}
        />
      );
      break;
  }

  return <div className={`${disabled && 'disable-layer__disabled'}`}>{elementTemp}</div>;
};

export default SingleSettingRenderer;
