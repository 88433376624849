import React, { useState, useEffect } from 'react';
import { parse } from 'papaparse';
import IconIndex from '../utils/icon-index.json';
// @ts-ignore
import InfiniteScroll from 'react-infinite-scroll-component';
import { List, Input, message } from 'antd';
import { debounce } from 'debounce';
import { deepCopy } from '../../../helper/array';
import { SearchOutlined } from '@ant-design/icons';
import { UltimateImagePickerMessages } from '../../../config/messages';
import DefaultSmallLoader from '../../../SharedUI/components/DefaultSmallLoader';
import { setFrequentlyUsedIcons } from '../utils/iconsHelper';
import BuilderSettingsTitle from '../../../Builder/components/sharedUI/BuilderSettingsTitle';
import { getEnvironment } from '../../../helper/environment';

declare global {
  interface Window {
    resetIconPickerValue(): any;
  }
}

interface IconDataInterface {
  category: string;
  keyword: string;
  icon: string;
}

export const IconCard = ({
  icon,
  onClick,
  currentIcon
}: {
  currentIcon?: string;
  icon: string;
  onClick?(icon: string): any;
}) => {
  const { domain } = getEnvironment();
  const iconPath = domain + '/funnel/icon-library/' + icon + '.svg';

  return (
    <div
      className={`icon-picker-grid__card ${currentIcon === icon ? 'active' : ''}`}
      onClick={() => onClick && onClick(icon)}
    >
      <div
        style={{ WebkitMask: 'url(' + iconPath + ') center / contain no-repeat' }}
        className="icon-picker-grid__card__icon"
      ></div>
    </div>
  );
};

const IconDataToSelectData = (iconData: IconDataInterface[]): IconDataInterface[] => {
  const allIcons = IconIndex.map(icon => ({
    icon,
    category: 'Alle',
    keyword: ''
  }));

  return [...iconData, ...allIcons].map(({ icon, category, keyword }: IconDataInterface) => ({
    icon,
    category,
    keyword
  }));
};

const isSearchCanditate = (candidate: IconDataInterface, searchTerm: string) => {
  let { icon, category, keyword } = candidate;

  searchTerm = searchTerm.toLowerCase();

  keyword = keyword.toLowerCase();
  category = category.toLowerCase();
  icon = icon.toLowerCase();

  const condition1 = keyword.includes(searchTerm);
  const condition2 = icon.includes(searchTerm);
  const condition3 = category.includes(searchTerm);

  return condition1 || condition2 || condition3;
};

let currentPage = 1;
let allIconData: IconDataInterface[] = [];
let allSearchedData: IconDataInterface[] = [];
let currentSearchTerm = '';
const defaultInfiniteScrollElementHeight = 440;

function IconPicker({ value, onChange }: { value?: string; onChange(value: string): any }) {
  let [iconData, setIconData] = useState<IconDataInterface[]>([]);

  const [currentIcon, setCurrentIcon] = useState(value);
  const [infiniteScrollElementHeight, setInfiniteScrollElementHeight] = useState(
    defaultInfiniteScrollElementHeight
  );
  const pageSize = 24;
  const currentAllIconData = () => (currentSearchTerm ? allSearchedData : allIconData);

  useEffect(() => {
    const element = document.querySelector('.infinite-scroll-component__outerdiv');
    const height = element ? element.clientHeight - 10 : defaultInfiniteScrollElementHeight;
    setInfiniteScrollElementHeight(height);
  }, []);

  useEffect(() => {
    parse(process.env.PUBLIC_URL + '/icon-picker/icon-picker-data.csv', {
      download: true,
      header: true,
      complete: ({ data }: { data: IconDataInterface[] }) => {
        allIconData = IconDataToSelectData(data);
        setIconData(getIconsByPage());
      },
      error: () => {
        message.error(UltimateImagePickerMessages.couldntLoadIcons);
      }
    });

    return () => {
      allSearchedData = [];
      currentSearchTerm = '';
    };
  }, []);

  const handleSerach = debounce((searchTerm: string) => {
    currentPage = 1;
    iconData = [];
    currentSearchTerm = searchTerm;

    if (searchTerm) allSearchedData = allIconData.filter(id => isSearchCanditate(id, searchTerm));

    if (!searchTerm) allSearchedData = [];

    setIconData(getIconsByPage());
  }, 200);

  const getIconsByPage = () => {
    const from = (currentPage - 1) * pageSize;
    const to = currentPage * pageSize - 1;

    return deepCopy(currentAllIconData()).splice(from, to);
  };

  const loadMore = () => {
    currentPage++;
    setIconData([...iconData, ...getIconsByPage()]);
  };

  const hanldeChangeIcon = (icon: string) => {
    onChange(icon);
    setCurrentIcon(icon);
    setFrequentlyUsedIcons(icon);
  };

  window.resetIconPickerValue = () => setCurrentIcon('');

  return (
    <div className="icon-picker-grid">
      <BuilderSettingsTitle title="Durchsuchen" />
      <Input
        onChange={(e: any) => handleSerach(e.target.value)}
        size="large"
        placeholder="Suche..."
        prefix={<SearchOutlined />}
      />
      <p>Tipp: Benutze auch englische Suchbegriffe</p>
      <InfiniteScroll
        dataLength={iconData.length}
        next={loadMore}
        height={infiniteScrollElementHeight}
        hasMore={iconData.length < currentAllIconData().length - 1}
        loader={null}
      >
        <List
          grid={{ gutter: 24, column: 3 }}
          dataSource={iconData}
          renderItem={({ icon }: any) => {
            return (
              <List.Item>
                <IconCard icon={icon} currentIcon={currentIcon} onClick={hanldeChangeIcon} />
              </List.Item>
            );
          }}
        />
      </InfiniteScroll>
    </div>
  );
}

export default IconPicker;
