import gql from 'graphql-tag';

export const GET_DASHBOARD_INFO = gql`
  query {
    getUser {
      id
      firstName
      lastName
      email
      imageURL
      agencyDashboardId
      role
      employeeGmails
      allowedTemplates
      provider
      createdAt
      partner {
        status
        partnerId
      }
      trialAchievements {
        productFruitId
        hoursAdded
      }
      questionsAnswered {
        question
        answer
      }
      createdAt
    }
    getPublicContract {
      id
      updatedAt
      title
      url
      reasonForContractChange
      newVersionOf
      deprecated
      required
    }
    getContract {
      id
      updatedAt
      agreed
      publicContract {
        id
      }
    }
    getSubscriptionInfo {
      status
      paddle_user_id
    }
    getGlobalSettingsFlags {
      key
      value
    }
  }
`;