import React, { useState } from 'react';
import { DeleteOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';

interface InlineConfirmDeletePropTypes {
  onConfirm?: Function;
  loading?: boolean;
  onCancel?: Function;
  disabled?: boolean;
}

const InlineConfirmDelete: React.FC<InlineConfirmDeletePropTypes> = ({
  onConfirm,
  loading = false,
  onCancel,
  disabled = false
}: any) => {
  const [flag, setFlag] = useState(false);
  return (
    <>
      {flag ? (
        loading ? (
          <LoadingOutlined />
        ) : (
          <>
            <Button
              danger
              type="link"
              onClick={() => {
                setFlag(false);
                onConfirm();
              }}
            >
              <DeleteOutlined className="del-icon" /> Löschen
            </Button>
            <CloseOutlined onClick={() => setFlag(false)} />
          </>
        )
      ) : (
        <Button disabled={disabled} danger type="link" onClick={() => setFlag(true)}>
          <DeleteOutlined className="del-icon" />
        </Button>
      )}
    </>
  );
};

InlineConfirmDelete.defaultProps = {
  loading: false,
  onConfirm: () => {},
  onCancel: () => {}
};
export default InlineConfirmDelete;
