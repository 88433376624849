import { Button, Col, Form, Input, Row } from 'antd';
import React from 'react';
import { urlFormValidator } from '../../../helper/url';
import { forceLowerCase } from '../../utils/domainInput';
import FunnelSelectList from '../../../UI/components/FunnelSelectList';
import { FormInstance } from 'antd/lib/form';

const WebhookForm = ({
  initialValues,
  form,
  handleSubmit,
  loading,
  handleCancel
}: {
  initialValues?: {
    url: string;
    funnelId: number;
  };
  form: FormInstance;
  handleSubmit: any;
  loading: boolean;
  handleCancel: any;
}) => {
  return (
    <Form
      name="add-domain-form"
      form={form}
      initialValues={initialValues}
      layout="vertical"
      scrollToFirstError={true}
      onFinish={handleSubmit}
    >
      <Row gutter={20}>
        <Col sm={12}>
          <Form.Item
            rules={[
              _ => urlFormValidator(_, true, true),
              {
                required: true,
                message: 'Die URL ist erforderlich'
              }
            ]}
            label="Webhook URL"
            name="url"
          >
            <Input placeholder="z.B. deine-domain.de/webhook" data-testid="WebHookFormUrlInput"/>
          </Form.Item>
        </Col>
        <Col sm={12}>
          <Form.Item
            required
            rules={[
              {
                required: true,
                type: 'number',
                message: 'Der Funnel ist erforderlich'
              }
            ]}
            label="Funnel"
            name="funnelId"
          >
            <FunnelSelectList  showActiveFunelsOnly={true} />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end" gutter={10}>
        <Col>
          <Button color="gray" type="link" disabled={loading} onClick={handleCancel} >
            Abbrechen
          </Button>
        </Col>
        <Col>
          <Button type="primary"  data-testid="webHookFormSubmitButton" onClick={() => form.submit()} loading={loading}>
            Speichern
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default WebhookForm;
