import React from 'react';
import { CRAFT_ELEMENTS } from '../../config/craftElements';
import { CraftElementBaseProps } from '../../helper/craftJs';


interface Props extends CraftElementBaseProps {
  craftState: string;
}

const CraftStateRenderer = (props: Props) => {
  return null;
};

CraftStateRenderer.craft = {
  name: CRAFT_ELEMENTS.CRAFT_STATE_RENDERER,
};

export default CraftStateRenderer;
