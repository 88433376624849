import React from 'react';
import { Empty } from 'antd';

export default function DefaultEmpty({
  icon,
  title,
  description,
  children
}: {
  icon: React.ReactNode;
  title: string;
  description?: string;
  children?: React.ReactNode;
}) {
  return (
    <Empty className="default-empty" image={icon} description={null}>
      <React.Fragment>
        <h2>{title}</h2>
        {description && <p>{description}</p>}
        {children}
      </React.Fragment>
    </Empty>
  );
}
