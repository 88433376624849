import { Col, Row } from 'antd';
import React from 'react';
import { CropperSettings, CropperShape } from '../ImageUploadV3';

const ImageSizeSelectorV3 = ({
  settings,
  setSettings,
  circleEnabled,
  rectangleEnabled,
  freeSelectionEnabled,
  possibleAspectRatios
}: {
  settings: CropperSettings;
  setSettings: (value: CropperSettings) => void;
  circleEnabled: boolean;
  rectangleEnabled: boolean;
  freeSelectionEnabled: boolean;
  possibleAspectRatios: { height: number; width: number }[];
}) => {
  const updateSettings = (values: Partial<CropperSettings>) => {
    setSettings({
      ...settings,
      ...values
    });
  };

  return (
    <Row className="image-cropper-v3__settings-wrapper" justify="space-between">
      <Col span={8} className="shape-selector">
        <label>Form</label>
        <div>
          {circleEnabled && (
            <div
              className={`image-cropper-v3__settings-button circle
                ${settings.stencilType === CropperShape.ROUND && 'active'}`}
              onClick={() => updateSettings({ stencilType: CropperShape.ROUND })}
            >
              Kreis
            </div>
          )}
          {rectangleEnabled && (
            <div
              className={`image-cropper-v3__settings-button rect
                ${settings.stencilType === CropperShape.RECTANGLE && 'active'}`}
              onClick={() => updateSettings({ stencilType: CropperShape.RECTANGLE })}
            >
              Rechteck
            </div>
          )}
        </div>
      </Col>
      <Col span={14} className="size-selector">
        <label>Format</label>
        <div>
          {settings.stencilType === CropperShape.RECTANGLE && rectangleEnabled ? (
            <>
              {freeSelectionEnabled && (
                <RatioIcon
                  className={`image-cropper-v3__settings-button image-cropper-v3__settings-button-free-scale ${!settings.aspectRatio ? 'active' : ''}`}
                  onClick={() => updateSettings({ aspectRatio: undefined })}
                >
                  Frei
                </RatioIcon>
              )}
              {possibleAspectRatios.map(({ height, width }, i) => {
                const isActive = width / height == settings.aspectRatio;
                return (
                  <RatioIcon
                    key={i}
                    className={`image-cropper-v3__settings-button   ${isActive ? 'active' : ''}`}
                    height={height}
                    width={width}
                    style={{ aspectRatio: `${width} / ${height}` }}
                    onClick={() => updateSettings({ aspectRatio: width / height })}
                  >
                    {`${width} / ${height} `}
                  </RatioIcon>
                );
              })}
            </>
          ) : (
            <RatioIcon className={`image-cropper-v3__settings-button  active`} width={1} height={1}>
              1 / 1
            </RatioIcon>
          )}
        </div>

      </Col>
    </Row>
  );
};

export default ImageSizeSelectorV3;

const RatioIcon = ({ className, onClick, height, width, children }: any) => {
  return (
    <button className={className} style={{ aspectRatio: `${width} / ${height}` }} onClick={onClick}>
      {children}
    </button>
  );
};
