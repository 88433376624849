import React from 'react';
import { RedoOutlined } from '@ant-design/icons';
import { Tooltip, Button, DatePicker, PageHeader } from 'antd';
import moment from 'moment';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import { predefinedDatePickerRanges } from '../../Events/utils/datePickerRanges';

const { RangePicker } = DatePicker;

export interface PageHeaderWithRangePickerProps {
  refetch: Function;
  loading: boolean;
  title?: string | JSX.Element;
  subTitle?: string | JSX.Element;
  rangePickerProps: RangePickerProps<moment.Moment>;
}

const defaultRangePickerProps: RangePickerProps<moment.Moment> = {
  allowClear: false,
  format: 'DD.MM.YYYY',
  allowEmpty: [false, false]
};

export default function PageHeaderWithRangePicker(props: PageHeaderWithRangePickerProps) {
  const { refetch, loading, title, rangePickerProps, subTitle } = props;

  //@ts-ignore
  const PageHeaderExtra = (
    <React.Fragment>
      <RangePicker
        {...defaultRangePickerProps}
        {...rangePickerProps}
        //@ts-ignore
        ranges={predefinedDatePickerRanges}
      />
      <Tooltip title="Aktualisieren">
        <Button
          loading={loading}
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            refetch();
          }}
          shape="circle"
          icon={<RedoOutlined />}
        />
      </Tooltip>
    </React.Fragment>
  );

  return <PageHeader className="site-page-header" title={title || ''} extra={PageHeaderExtra} />;
}
