import { useEditor, useNode } from '@craftjs/core';
import { Card, Col, Form, Input, Popover, Row, Switch } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FooterComponentMessages } from '../../../config/messages';
import { urlFormValidator } from '../../../helper/url';
import { CRAFT_ELEMENTS, CRAFT_ELEMENTS_LABEL } from '../../config/craftElements';
import { editGeneralSettings, useBuilderGeneralSettings } from '../../redux/builderSlice';
import { ActionsController } from '../sharedUI/ActionsController';
import BuilderColorPickerButton from '../sharedUI/BuilderColorPickerButton';
import BuilderSettingsTitle from '../sharedUI/BuilderSettingsTitle';
import BuilderSettingsTogglePro from '../sharedUI/BuilderSettingsTogglePro';
import { CraftElementBaseProps } from '../../helper/craftJs';
import { getElementColor } from '../../helper/craftJs';
import { FUNNEL_THEME_KEYS } from '../../interfaces/builderSliceTypes';
import { useCurrentlyEditingBuilderTheme } from '../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';
import CustomBranding from './ProUserBranding/CustomBranding';
import BrandingComponent from './ProUserBranding/BrandingSwitchComponent';
import { useSubscriptionInfo, useSubscriptionInfoLoading } from '../../../UI/redux/userSlice';

interface PropTypes extends CraftElementBaseProps {
  imprintURL?: string;
  privacyPolicyURL?: string;
  backgroundColor?: string;
  isCustomColor?: boolean;
  isFirstRender?: boolean;
  customBrandingEnabled?: boolean;
  customBranding?: {
    label?: string;
    link?: string;
    src?: string;
  };
}

const FooterComponent = ({ backgroundColor, customBrandingEnabled, customBranding }: PropTypes) => {
  const theme = useCurrentlyEditingBuilderTheme();
  const dispatch = useDispatch();
  const { language, brandingEnabled } = useBuilderGeneralSettings();
  const imprintLabel = language === 'DE' ? 'Impressum' : 'Imprint';
  const privacyPolicyLabel = language === 'DE' ? 'Datenschutzerklärung' : 'Privacy policy';
  const generalSettings = useBuilderGeneralSettings();

  const subscriptionInfo = useSubscriptionInfo();
  const loading = useSubscriptionInfoLoading();
  const { nodeId, props, currentNode } = useNode(node => ({
    nodeId: node.id,
    props: node.data.props,
    currentNode: node
  }));

  const { link, label, src } = customBranding || {};
  const { query, actions } = useEditor();

  useEffect(() => {
    if (loading) return;

    if (!subscriptionInfo.customBrandingEnabled && props.customBrandingEnabled) {
      actions.history.ignore().setProp(nodeId, (state: any) => {
        state.customBrandingEnabled = false;
      });
    }

    if (subscriptionInfo.brandingEnabled && !brandingEnabled) {
      actions.history.ignore().setProp(nodeId, (state: any) => {
        state.brandingEnabled = true;
      });
      dispatch(editGeneralSettings({ brandingEnabled: true }));
    }
  }, [subscriptionInfo, loading]);

  return (
    <ActionsController
      style={{ background: getElementColor(backgroundColor, theme) }}
      label={CRAFT_ELEMENTS_LABEL[currentNode.data.displayName]}
    >
      <div className="footer-wrapper p-4 pb-5 pt-4 w-100 d-flex flex-column align-items-center justify-content-center">
        <div className="d-flex mb-2">
          <a className="footer-wrapper__link">{imprintLabel}</a>

          <span className="mx-2 fw-lighter">|</span>
          <a className="footer-wrapper__link">{privacyPolicyLabel}</a>
        </div>

        {customBrandingEnabled ? (
          <div className="footer-wrapper__branding m-0">
            <a href={link} target="_blank">
              {label}
              <img className="custom-branding-img" src={src} alt="" />
            </a>
          </div>
        ) : (
          brandingEnabled && (
            <p className="footer-wrapper__branding m-0">
              ⚡️ by{' '}
              <a href="https://meetovo.de/?source=cf" target="_blank">
                MEETOVO
              </a>
            </p>
          )
        )}
      </div>
    </ActionsController>
  );
};

const FooterComponentSettings = () => {
  const {
    actions: { setProp },
    props
  } = useNode(node => ({
    props: node.data.props
  }));
  const [imprintForm] = Form.useForm();
  const [policyForm] = Form.useForm();

  const generalSettings = useBuilderGeneralSettings();
  const dispatch = useDispatch();
  const subscriptionInfo = useSubscriptionInfo();
  const { brandingEnabled, customBrandingEnabled } = subscriptionInfo;
  const theme = useCurrentlyEditingBuilderTheme();

  const brandingToggleDisabled =
    brandingEnabled || (props.customBrandingEnabled && customBrandingEnabled);

  const handleMEETOVOBrandingChange = (checked: boolean) => {
    dispatch(editGeneralSettings({ brandingEnabled: checked }));
  };

  const handleCustomBrandingChange = (checked: boolean) => {
    setProp((props: any) => {
      props.customBrandingEnabled = checked;

      if (checked && generalSettings.brandingEnabled)
        dispatch(editGeneralSettings({ brandingEnabled: false }));
    });
  };

  return (
    <div className="builder__settings-sidebar__container">
      <Card className="settings-card p-0 footer-setting-card" title="Footer" bordered={false}>
        <Row className="builder__settings-sidebar__row px-4 m-0">
          <Col span={24}>
            <BuilderSettingsTitle title="Impressum" />
            <Form
              form={imprintForm}
              initialValues={{ url: props.imprintURL || '' }}
              onFinish={values => {
                setProp((props: any) => (props.imprintURL = values.url));
              }}
              autoComplete="off"
            >
              <Form.Item
                name="url"
                rules={[
                  {
                    required: true,
                    message: 'URL ist erforderlich'
                  },
                  urlFormValidator
                ]}
              >
                <Input
                  className="mt-2"
                  placeholder="https://deine-seite.de/impressum"
                  onChange={imprintForm.submit}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Row className="builder__settings-sidebar__row px-4  m-0">
          <Col span={24}>
            <BuilderSettingsTitle title="Datenschutzerklärung" />
            <Form
              form={policyForm}
              initialValues={{ url: props.privacyPolicyURL || '' }}
              onFinish={values => {
                setProp((props: any) => (props.privacyPolicyURL = values.url));
              }}
              autoComplete="off"
            >
              <Form.Item
                name="url"
                rules={[
                  {
                    required: true,
                    message: 'URL ist erforderlich'
                  },
                  urlFormValidator
                ]}
              >
                <Input
                  className="mt-2"
                  placeholder="https://deine-seite.de/datenschutz"
                  onChange={policyForm.submit}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Row className="builder__settings-sidebar__row px-4 m-0 mb-3">
          <Col span={11}>
            <BuilderSettingsTitle title="Hintergrundfarbe" />
            <BuilderColorPickerButton
              showColorTypeBtns
              color={getElementColor(props.backgroundColor, theme)}
              onChange={(backgroundColor, colorKey) => {
                setProp((state: any) => {
                  state.isCustomColor = true;
                  state.backgroundColor = colorKey || backgroundColor;
                });
              }}
            />
          </Col>
        </Row>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BuilderSettingsTogglePro
              title="MEETOVO-Branding"
              rightContent={
                <BrandingComponent
                  message={FooterComponentMessages.brandingEnabled}
                  disabled={brandingToggleDisabled}
                  checked={generalSettings.brandingEnabled}
                  onChange={handleMEETOVOBrandingChange}
                />
              }
            />
            <p className="px-4 my-3">
              Das Branding ist ein kleiner Link, der zur MEETOVO Website führt. Ab dem Expert-Paket
              ist dieser deaktivierbar.
              <br />
              ⚡️ by MEETOVO
            </p>
          </Col>
        </Row>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BuilderSettingsTogglePro
              title="Mein Branding"
              classNames="py-3"
              rightContent={
                <BrandingComponent
                  message={FooterComponentMessages.customBrandingEnabled}
                  disabled={!customBrandingEnabled}
                  checked={props.customBrandingEnabled}
                  onChange={handleCustomBrandingChange}
                />
              }
            />
            {!props.customBrandingEnabled && (
              <>
                <p className="px-4 my-3">
                  Erhalte Traffic über die Funnels deiner Kunden. Mit "Mein Branding" kannst du dein
                  Logo inkl. Verlinkung im Footer auf jeder Seite platzieren.
                </p>
                <div className="px-4 mb-4 mt-2">
                  <img
                    src="/dashboard/funnel-builder/footer-custom-branding-mockup.jpg"
                    className="decent-border w-100"
                  />
                </div>
              </>
            )}
          </Col>
        </Row>

        {props.customBrandingEnabled && <CustomBranding />}
      </Card>
    </div>
  );
};

const FooterComponentDefaultProps = {
  backgroundColor: FUNNEL_THEME_KEYS.BACKGROUND_COLOR,
  isFirstRender: true,
  customBranding: {
    link: '',
    label: '⚡️ by ',
    src:
      'https://meetovo-file-bucket.s3.eu-central-1.amazonaws.com/bilder/coach-image-cid-12-1665765556212-v3wd0f.png'
  }
};

FooterComponent.craft = {
  name: CRAFT_ELEMENTS.FOOTER,
  props: FooterComponentDefaultProps,
  related: {
    settings: FooterComponentSettings,
    isDeletable: false
  },
  rules: {
    isDeletable: false
  },
  isDeletable: false
};

export default FooterComponent;
