import { resetToInitialCheckoutState } from '../Checkout/redux/checkoutSlice';
import { resetToInitialFunnelsState } from '../Funnel/redux/funnelSlice';
import { resetToInitialLibraryState } from '../Library/redux/librarySlice';
import { resetToInitialProductFruitsState } from '../ProductFruitsWrapper/redux/productFruitsSlice';
import { resetToInitialUIState } from '../UI/redux/uiSlice';
import { resetToInitialUserState } from '../UI/redux/userSlice';

export const clearReduxStore = (dispatch: any) => {
  dispatch(resetToInitialCheckoutState());
  dispatch(resetToInitialUserState());
  dispatch(resetToInitialProductFruitsState());
  dispatch(resetToInitialUIState());
  dispatch(resetToInitialLibraryState());
  dispatch(resetToInitialFunnelsState());
};
