import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';
import { getEnvironment } from '../../helper/environment';
import { getAuthData, startTokenExpirationCheck } from '../container/Login';

export const getAppoloClient = () => {
  startTokenExpirationCheck(window.handleLogout);

  let graphQlLink = createUploadLink({
    uri: getEnvironment().graphqlEndpoint,
    headers: {
      'keep-alive': 'true'
    }
  }) as any;

  const authLink = setContext((_, { headers }) => {
    const token = getAuthData('token');
    
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    };
  });

  graphQlLink = authLink.concat(graphQlLink);

  return new ApolloClient({
    link: graphQlLink,
    cache: new InMemoryCache({
      addTypename: false
    })
  });
};
