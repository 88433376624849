import React, { useState, Suspense, useEffect, CSSProperties } from 'react';
import { Spinner } from 'react-bootstrap';
// import { StandardSpinner } from '../../SharedUI/components/StandardSpinner';
const AudioPlayer = React.lazy(() => import('react-h5-audio-player'));

export interface MediaDisplayVoiceMessagePropsInterface {
  voiceMessageSignedUrl: string;
  className?: string;
  render({
    canPlay,
    audioPlayTimeInPercent,
    isPlaying,
    play,
    pause
  }: {
    canPlay: boolean;
    audioPlayTimeInPercent: number;
    isPlaying: boolean;
    play: Function;
    pause: Function;
  }): any;
  style: CSSProperties;
}

const MediaDisplayVoiceMessage = ({
  voiceMessageSignedUrl,
  render,
  className,
  style
}: MediaDisplayVoiceMessagePropsInterface) => {
  const [audioInfo, setAudioInfo] = useState({
    isPlaying: false,
    canPlay: false,
    duration: 0,
    currentTime: 0
  });

  useEffect(() => {
    setAudioInfo({ ...audioInfo, canPlay: !!voiceMessageSignedUrl });
    return () => {
      setAudioInfo({ isPlaying: false, canPlay: false, duration: 0, currentTime: 0 });
    }
  }, [voiceMessageSignedUrl]);

  const { duration, currentTime, canPlay, isPlaying } = audioInfo;
  const audioPlayTimeInPercent = (currentTime * 100) / duration;
  const [audioElement, setAudioElement] = useState<HTMLAudioElement | undefined>();

  return (
    <div
      style={style}
      className={className + ' voice-container ' + (isPlaying ? ' is-playing ' : '')}
    >
      <Suspense fallback={<div />}>
        <AudioPlayer
          autoPlayAfterSrcChange={false}
          style={{ display: 'none' }}
          listenInterval={1000}
          src={voiceMessageSignedUrl}
          onListen={e => {
            //@ts-ignore
            const currentTime = e?.target?.currentTime;
            const noNextInterval = duration - currentTime < 1;
            setAudioInfo({ ...audioInfo, currentTime: noNextInterval ? duration : currentTime });
          }}
          onPlay={() => {
            //@ts-ignore
            // window.trackVoiceMessagePlayback();
            setAudioInfo({ ...audioInfo, isPlaying: true });
          }}
          onAbort={() =>
            //@ts-ignore
            setAudioInfo({ ...audioInfo, isPlaying: false })
          }
          onEnded={() =>
            //@ts-ignore
            setAudioInfo({ ...audioInfo, isPlaying: false })
          }
          onPause={() =>
            //@ts-ignore
            setAudioInfo({ ...audioInfo, isPlaying: false })
          }
          ref={(ref: any) => {
            const element = ref?.audio?.current;
            if (element && !audioElement) {
              element.addEventListener('loadedmetadata', (e: any) => {
                setAudioInfo({ ...audioInfo, duration: e?.target?.duration || 0, canPlay: true });
              });
              setAudioElement(element);
            }
          }}
        />
      </Suspense>

      {render({
        canPlay,
        audioPlayTimeInPercent,
        isPlaying,
        play: () => audioElement?.play(),
        pause: () => audioElement?.pause()
      })}
    </div>
  );
};

export default MediaDisplayVoiceMessage;
