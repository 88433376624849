import { AGENCY_OS_THEME_KEYS } from '../interfaces/agencyOSBuilderSliceTypes';

export const agencyOSColorPickerConfig = [
  {
    label: 'Headline',
    description: 'Überschriften und Fragen',
    colorKey: AGENCY_OS_THEME_KEYS.HEADLINE_COLOR,
    errorMessage:
      'Überschriften werden nicht lesbar sein. Du solltest entweder die Hintergrund- oder die Überschriftenfarbe ändern.'
  },
  {
    label: 'Text',
    description: 'Haupttexte und Hinweise',
    colorKey: AGENCY_OS_THEME_KEYS.TEXT_COLOR,
    errorMessage:
      'Der Text wird nicht lesbar sein. Du solltest entweder die Hintergrund- oder die Textfarbe ändern.'
  },
  {
    label: 'Accent Color',
    description: 'Buttons, Icons und Fortschrittsanzeige',
    colorKey: AGENCY_OS_THEME_KEYS.ACCENT_COLOR,
    errorMessage:
      'Buttons, Icons und Fortschrittsanzeige wird nicht lesbar sein. Du solltest entweder die Hintergrund- oder die Akzentfarbe ändern.'
  },
  {
    label: 'Primary Color',
    description: 'Auswahlkarten und Testimonials',
    colorKey: AGENCY_OS_THEME_KEYS.PRIMARY_COLOR
  },
  {
    label: 'Primary Contrast Color',
    description: 'Wird auf der Akzentfarbe verwendet',
    colorKey: AGENCY_OS_THEME_KEYS.PRIMARY_CONTRAST_COLOR
  }
];
