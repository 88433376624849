import React from 'react';
import { Table, Tag, Row } from 'antd';

const tagRenderFunc = (tags: string[]) => (
  <>
    {tags.map(tag => {
      const color = 'green';

      return (
        <Tag color={color} key={tag}>
          <b
            style={{
              color: '#389e0d'
            }}
          >
            {tag}
          </b>
        </Tag>
      );
    })}
  </>
);

const columns = [
  {
    title: 'Typ',
    dataIndex: 'type',
    key: 'type',
    render: tagRenderFunc
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: tagRenderFunc
  },
  {
    title: 'Wert',
    key: 'value',
    dataIndex: 'value',
    render: tagRenderFunc
  }
];

const data = [
  {
    key: '1',
    type: ['CNAME'],
    name: ['@'],
    value: ['cname.meetovo-dns.de.']
  }
];

const DomainConnectInstructions: React.FC = () => {
  return (
    <>
      <div>
        <Row>
          <p>
            Diesen CNMAE-Record musst du in den DNS-Einstellungen deines Hosting-Anbieters für deine
            angegebene Domain vornehmen. Weitere Informationen findest du im
            <a
              target="_blank"
              href="https://hilfe.meetovo.de/de/article/eigene-domainsubdomain-verknupfen-woykz1/"
            >
              &nbsp;Hilfecenter
            </a>
            .
          </p>
        </Row>

        <Table pagination={false} columns={columns} dataSource={data} />

        <Row
          style={{
            marginTop: '1em'
          }}
        >
          <p>Dieser Prozess kann zwischen 24 und 72 Stunden dauern.</p>
        </Row>
      </div>
    </>
  );
};

export default DomainConnectInstructions;
