import React from 'react';
import { Button, Space } from 'antd';
import ColorPicker from '../../../Funnel/components/subcomponents/ColorPicker';
import { agencyOSColorPickerConfig } from '../config/agencyOsSettingColor';
import { useDispatch } from 'react-redux';
import { StepBackwardOutlined } from '@ant-design/icons';
import {
  setAgencyOSCurrentlyEditingTheme,
  useAgencyOSCurrentlyEditingTheme,
  useAgencyOSTheme
} from '../redux/agencyOSBuilderSlice';

const AgencyOSBuilderColorPicker = () => {
  const dispatch = useDispatch();
  const funnelTheme = useAgencyOSTheme();
  const currentlyEditingTheme = useAgencyOSCurrentlyEditingTheme();

  const handleSetColor = (colorKey: string, color: string) => {
    dispatch(setAgencyOSCurrentlyEditingTheme({ ...currentlyEditingTheme, [colorKey]: color }));
  };

  const handleCancel = async () => {
    dispatch(setAgencyOSCurrentlyEditingTheme(funnelTheme));
  };

  return (
    <div className="builder__settings-sidebar__container colors">
      <section className="color-pickers">
        {agencyOSColorPickerConfig.map(({ label, description, colorKey }, i) => (
          <ColorPicker
            key={i}
            label={label}
            description={description}
            // @ts-ignore
            color={currentlyEditingTheme[colorKey]}
            onChange={color => handleSetColor(colorKey, color)}
          />
        ))}
        <Space className="actions">
          <Button type="link" icon={<StepBackwardOutlined />} onClick={handleCancel}>
            Zurücksetzen
          </Button>
        </Space>
      </section>
    </div>
  );
};

export default AgencyOSBuilderColorPicker;
