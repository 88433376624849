import React from 'react';
import { Button, Tooltip } from 'antd';
import {
  ALIGNMENT_OPTIONS,
  CONTAINER_ALIGNMENT_OPTIONS,
  alignmentOptions
} from './alignmentHelpers';

interface Props {
  align: string;
  onChange(align: string): void;
  disabled?: boolean;
  customAlignmentOptions?: {
    align: ALIGNMENT_OPTIONS | CONTAINER_ALIGNMENT_OPTIONS;
    icon: JSX.Element;
    text: string;
  }[];
}

const ColumnAlignmentButtons = ({ align, onChange, disabled, customAlignmentOptions }: Props) => {
  const currentAlignmentOptions = customAlignmentOptions || alignmentOptions;
  return (
    <div className="text-formatting-buttons text-formatting-buttons__alignment">
      {currentAlignmentOptions.map(item => {
        return (
          <Tooltip placement="topLeft" title={item.text} key={item.text}>
            <Button
              disabled={disabled}
              className="m-2 mt-1 mb-1"
              size="large"
              type={align === item.align ? 'primary' : undefined}
              icon={item.icon}
              onClick={(e: any) => {
                onChange(item.align);
              }}
              shape="circle"
              data-testid="text-alignment-left-alignment-button"
            />
          </Tooltip>
        );
      })}
    </div>
  );
};

export default ColumnAlignmentButtons;
