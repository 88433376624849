import gql from 'graphql-tag';

export interface Input {
  name: string;
}

const CREATE_FUNNEL_FOLDER = gql`
  mutation createFunnelFolder($input: CreateFunnelFolderInput!) {
    createFunnelFolder(input: $input) {
      id
      name
    }
  }
`;

export default function createFunnelFolder(input: Input) {
  return window.apolloClient.mutate({
    mutation: CREATE_FUNNEL_FOLDER,
    variables: { input }
  });
}
