function setCookie(cname: string, cvalue?: string, exdays = 1000) {
  if (!cname) return false;

  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = 'expires=' + d.toUTCString();
  document.cookie =
    cname +
    '=' +
    (cvalue || '') +
    ';' +
    expires +
    (location.host.includes('meetovo') ? ';domain=.meetovo.de' : '') +
    ';path=/';
}

function getCookie(cname: string) {
  var name = cname + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      const cookieValue = c.substring(name.length, c.length);
      return cookieValue && cookieValue != 'undefined' ? cookieValue : '';
    }
  }
  return '';
}

export { getCookie, setCookie };
