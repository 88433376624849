import { ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

export const customHtmlToDraft = (html: string): ContentState => {
  const blocksFromHtml = htmlToDraft(html);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

  return contentState;
};

export const customDraftToHtml = (
  contentState: ContentState,
  customEntityTransform?: any
): string => {
  const rawContentState = convertToRaw(contentState);

  let html = draftToHtml(
    rawContentState,
    {
      trigger: '#',
      separator: ' '
    },
    false,
    customEntityTransform
  ) || ""

  html = html.replace("auto;width", "auto;max-width")
  html = html.replace("auto; width", "auto; max-width")
  
  return html;
};
