import React, { useState } from 'react';
import { Card, Col, Row } from 'antd';
import DomainListModal from '../components/CustomDomains/DomainListModal';
import { connectCardTitlesAndCategories } from '../utils/types';
import { EDIT_CONTENT_TYPE } from '../context/ResourceEditContext';

const CustomDomains = () => {
  return (
    <>
      <DomainListModal
        trigger={
          <div className="card-with-hover">
            <Card
              hoverable
              className="card-container"
              cover={<img src={process.env.PUBLIC_URL + '/connect/connect__domain-icon.png'} />}
            >
              <Card.Meta className ="card-title" title={connectCardTitlesAndCategories[EDIT_CONTENT_TYPE.MY_DOMAINS].title}/>
              <div>{connectCardTitlesAndCategories[EDIT_CONTENT_TYPE.MY_DOMAINS].category}</div>
            </Card>
          </div>
        }
      />
    </>
  );
};

export default CustomDomains;
