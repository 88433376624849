import React from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { Button } from 'antd';
import { NextStepInterface } from '../../Funnel/components/ThankyouPageSettingsBuilder';
import { DeleteOutlined } from '@ant-design/icons';
import { getNewIdAndWeight } from '../../Funnel/helper/dynamicFormHelper';
import {
  PlusOutlined,
  MoreOutlined,
  UpCircleOutlined,
  DownCircleOutlined
} from '@ant-design/icons';
import { deepCopy } from '../../helper/array';

const DragHandle = SortableHandle(({ customStyles, className }: any) => (
  <span
    className={`sortable-list__item-handle ${className ? className : ''} ${
      !customStyles ? 'ui__white-round-wrapper__handle' : ''
    }`}
  >
    <MoreOutlined />
  </span>
));

const SortableItem = SortableElement(
  ({
    item,
    itemCount,
    passedIndex,
    renderItem,
    onDelete,
    customStyles,
    className,
    handleSort,
    manuellSortEnabled
  }: {
    manuellSortEnabled?: boolean;
    itemCount: number;
    passedIndex: number;
    item: any;
    onDelete?: any;
    handleSort(value: any): any;
    renderItem(item: {}): JSX.Element;
    customStyles?: boolean;
    className?: string;
  }) => {
    let ns: NextStepInterface = item;

    return (
      <div
        className={`sortable-list__item ${
          !customStyles ? 'ui__white-round-wrapper with-handle with-action-bar' : ''
        } ${className ? className : ''}`}
      >
        <DragHandle className={className} customStyles={customStyles} />
        {manuellSortEnabled && (
          <div className="sortable-list__manuel-sort">
            {passedIndex > 0 && (
              <UpCircleOutlined
                onClick={() => handleSort({ oldIndex: passedIndex, newIndex: passedIndex - 1 })}
              />
            )}
            {passedIndex + 1 < itemCount && (
              <DownCircleOutlined
                onClick={() => handleSort({ oldIndex: passedIndex, newIndex: passedIndex + 1 })}
              />
            )}
          </div>
        )}

        {renderItem(item)}

        <div
          className={`sortable-list__item__action-bar ${
            !customStyles ? 'ui__white-round-wrapper__action-bar' : ''
          } ${className ? className : ''}`}
        >
          <DeleteOutlined
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              onDelete(ns.id);
            }}
          />
        </div>
      </div>
    );
  }
);

const SortableListContainer = SortableContainer(
  ({
    items,
    onDelete,
    handleSort,
    renderItem,
    customStyles,
    className,
    shouldCancelStart,
    manuellSortEnabled
  }: {
    items: any[];
    manuellSortEnabled?: boolean;
    handleSort(value: any): any;
    onDelete?: any;
    renderItem(item: {}): JSX.Element;
    customStyles?: boolean;
    className?: string;
    shouldCancelStart?(): boolean;
  }) => {
    return (
      <div className="sortable-list__container">
        {items.map((item: any, index: number) => (
          <SortableItem
            //@ts-ignore
            shouldCancelStart={shouldCancelStart}
            key={`item-${item.id}`}
            index={index}
            item={item}
            onDelete={onDelete}
            renderItem={renderItem}
            customStyles={customStyles}
            className={className}
            handleSort={handleSort}
            passedIndex={index}
            itemCount={items.length}
            manuellSortEnabled={manuellSortEnabled}
          />
        ))}
      </div>
    );
  }
);

const SortableList = ({
  items,
  onChangeEnd,
  renderItem,
  emptyObject,
  showAddButton,
  customStyles,
  className,
  afterAdd,
  shouldCancelStart,
  addButtonText,
  manuellSortEnabled
}: {
  items: any[];
  onChangeEnd: any;
  renderItem(item: {}): JSX.Element;
  emptyObject?: object;
  showAddButton?: boolean;
  customStyles?: boolean;
  className?: string;
  afterAdd?(item: any): any;
  shouldCancelStart?(): boolean;
  addButtonText?: string;
  manuellSortEnabled?: boolean;
}) => {
  if (showAddButton === undefined) showAddButton = true;

  const itemsCopy: any[] = deepCopy(items, ['voiceMessageFile']);
  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const visibleItems = itemsCopy.filter((a: any) => !a.toDelete);
    const deletedItems = itemsCopy.filter((a: any) => a.toDelete);

    let itemsCopySorted: any[] = arrayMove(visibleItems, oldIndex, newIndex);
    itemsCopySorted.forEach((item: any, idx: number) => (item.weight = idx)); // normalize weights

    onChangeEnd([...itemsCopySorted, ...deletedItems]);
  };

  const handleDelete = (id: number) => {
    let idx: number | undefined = itemsCopy.findIndex((item: any) => item.id === id);

    if (idx > -1) {
      let beforeWeightFound = false;
      let newElementIdOnDeletedPosition = itemsCopy[idx + 1] && itemsCopy[idx + 1].id;

      if (idx > 0) {
        newElementIdOnDeletedPosition = itemsCopy
          .filter(item => !item.toDelete)
          .reverse()
          .reduce((acc, item, i) => {
            if (item.weight < itemsCopy[idx!].weight && !beforeWeightFound) {
              beforeWeightFound = true;
              return item.id;
            }

            return acc;
          }, false);
      }

      itemsCopy[idx].toDelete = true;
      itemsCopy[idx].weight = 999999;

      onChangeEnd(itemsCopy, newElementIdOnDeletedPosition, id);
    }
  };

  const onAdd = () => {
    const { newId, newWeight } = getNewIdAndWeight(itemsCopy);

    const newItem = JSON.parse(JSON.stringify(emptyObject));

    newItem.id = newId;
    newItem.tempId = true;

    if (newWeight > 0) newItem.weight = newWeight;

    itemsCopy.push(newItem);

    onChangeEnd(itemsCopy);

    afterAdd && afterAdd(newItem);
  };

  if (!Array.isArray(itemsCopy)) {
    return null;
  }

  return (
    <div className="sortable-list">
      <SortableListContainer
        shouldCancelStart={shouldCancelStart}
        useDragHandle
        items={itemsCopy
          .sort((a: any, b: any) => a.weight - b.weight)
          .filter((a: any) => !a.toDelete)}
        onSortEnd={onSortEnd}
        onDelete={handleDelete}
        renderItem={renderItem}
        customStyles={customStyles}
        className={className}
        handleSort={onSortEnd}
        manuellSortEnabled={manuellSortEnabled}
      />

      {emptyObject && showAddButton && (
        <Button
          type="ghost"
          className={
            'sortable-list__add' +
            (addButtonText ? ' icon-circle-button-with-label ' : ' ') +
            (className ? className : '')
          }
          onClick={onAdd}
        >
          <div className={'ui__icon-circle'}>
            <PlusOutlined />
          </div>
          {addButtonText ? addButtonText : ''}
        </Button>
      )}
    </div>
  );
};

export { SortableList };
