import React, { useEffect } from 'react';
import { Empty, Button } from 'antd';
import { UpCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';

interface Props {
  imageURL?: string;
  imageStyle?: React.CSSProperties;
  title: string;
  description: string;
  buttonText?: string;
  pushToUpgradeOnButtonClick?: boolean;
  onButtonClick?(): void;
}

const BlockedFeatureOverlay = ({
  imageStyle,
  imageURL,
  title,
  description,
  buttonText,
  pushToUpgradeOnButtonClick,
  onButtonClick
}: Props) => {
  const navigate = useNavigate();

  const handleCheckoutPush = () => navigate('/checkout', { state: { upgradeMode: true } });

  useEffect(() => {
    const topBarHeight = document.querySelector('.ui__sub-header-background')?.clientHeight || 0;

    document
      .querySelector('.blocked-feature-overlay__overlay-visible')
      ?.setAttribute('style', `height: ${window.innerHeight - topBarHeight}px; overflow: hidden;`);

    return () => {
      document
        .querySelector('.blocked-feature-overlay__overlay-visible')
        ?.setAttribute('style', ``);
    };
  }, []);

  return (
    <div className="blocked-feature-overlay__overlay">
      <img
        style={imageStyle}
        src={imageURL || `${process.env.PUBLIC_URL}/blocked-red-gradient-500.png`}
      />
      <Empty
        image={null}
        imageStyle={{ height: 0 }}
        description={
          <React.Fragment>
            <h1>{title}</h1>
            <p>{description}</p>
          </React.Fragment>
        }
      >
        {buttonText && (
          <Button
            size="large"
            type="primary"
            icon={<UpCircleOutlined />}
            onClick={
              pushToUpgradeOnButtonClick ? handleCheckoutPush : onButtonClick && onButtonClick
            }
          >
            {buttonText}
          </Button>
        )}
      </Empty>
    </div>
  );
};

export default BlockedFeatureOverlay;
