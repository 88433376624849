import React from 'react';
import { useNode, useEditor } from '@craftjs/core';
import { Form, Input } from 'antd';
import BuilderSettingsTitle from '../../../sharedUI/BuilderSettingsTitle';
import { getAllContainersSectionIds } from '../../../../helper/craftJs';

import { debounce } from 'debounce';
import { sectionIdsValidator } from '../sectionIdsValidation';

export const ContainerSettingsSectionId = () => {
  const { query } = useEditor();

  const {
    actions: { setProp },
    currentNode,
    props
  } = useNode(node => ({
    props: node.data.props,
    nodeId: node.id,
    currentNode: node
  }));
  const [form] = Form.useForm();
  const sectionIds = getAllContainersSectionIds(query, currentNode.id);

  return (
    <Form
      form={form}
      initialValues={{ id: props.sectionId }}
      onFinish={values => {
        setProp((props: any) => (props.sectionId = values.id));
      }}
      autoComplete="off"
      style={{ width: '100%' }}
    >
      <BuilderSettingsTitle classNames="mx-1" title="Abschnittstitel" />
      <Form.Item
        name="id"
        className="mb-0"
        rules={[
          {
            required: false,
            ...sectionIdsValidator(sectionIds)
          }
        ]}
      >
        <Input
          placeholder="Erscheint in Button-Aktionen"
          onChange={e => {
            debounce(() => form.submit(), 1000)();
          }}
        />
      </Form.Item>
      <a
        target="_blank"
        href="https://hilfe.meetovo.de/de/article/button-aktion-weiterleitungen-abschnitt-verlinken-anker-scroll-qo559i/#3-abschnitt-verlinken"
        className="link mt-1 d-block"
      >
        Wie verlinke ich Abschnitte?
      </a>
    </Form>
  );
};

export default ContainerSettingsSectionId;
