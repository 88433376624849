import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const VALIDATE_PIN = gql`
  mutation($input: String!) {
    validatePin(input: $input)
  }
`;

const mutateValidatePin = () => {
  const mutation = useMutation(VALIDATE_PIN);
  return mutation;
};

export default mutateValidatePin;
