import React, { useEffect } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';

import { CRAFT_ELEMENTS, CRAFT_ELEMENTS_LABEL } from '../../../../config/craftElements';
import { childSelectionOnParentClick, getUniqueId } from '../../../../helper/craftJs';
import BuilderCarousel from './BuilderCarousel';
import { ActionsController } from '../../../sharedUI/ActionsController';
import OverlayWhileDragging from '../Components/OverlayWhileDragging';
import { ContainerComponentProps as CarouselComponentPropsInterface} from '../../Container/interfaces';

const CarouselContainer = ({ children, ...props }: CarouselComponentPropsInterface) => {
  const { currentNode, nodeId } = useNode(node => ({
    currentNode: node,
    nodeId: node.id
  }));

  const {
    actions: { selectNode }
  } = useEditor();

  const { actions, query } = useEditor();

  useEffect(() => {
    if (!children && currentNode.data.nodes.length === 0) {
      const actionCreator = actions.history.ignore();
      const node = query
        .parseReactElement(<Element id={getUniqueId()} is={BuilderCarousel} canvas />)
        .toNodeTree();
      actionCreator.addNodeTree(node, currentNode.id);
    }
  }, []);

  return (
    <ActionsController
      {...props}
      style={{
        position: 'relative'
      }}
      label={CRAFT_ELEMENTS_LABEL[currentNode.data.displayName]}
    >
      <OverlayWhileDragging />
      <div
        onClick={e => {
          childSelectionOnParentClick(nodeId, query, selectNode);
        }}
      >
        {children}
      </div>
    </ActionsController>
  );
};

export default CarouselContainer;

CarouselContainer.craft = {
  name: CRAFT_ELEMENTS.CAROUSEL_CONTAINER,
  props: {
    openChildSettingsOnParentSettings: true
  },
  rules: {
    canMoveIn: (selectedNode: any) => {
      return false;
    },
    canMoveOut: () => false
  }
};
