import { sanitizerConfig } from '../../Funnel/utils/sanitizerConfig';
import { customDraftToHtml } from '../../helper/draftHelper';
import { EmailTemplateType } from '../interfaces/builderSliceTypes';
import sanitizeHtml from 'sanitize-html';

export const getEmailTemplateSanitizedHtml = ({
  tabContent
}: {
  tabContent: EmailTemplateType;
}) => {
  const html = customDraftToHtml(tabContent.html.getCurrentContent());
  const sanitizedHtml = sanitizeHtml(html, sanitizerConfig);
  return sanitizedHtml;
};
