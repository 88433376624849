import gql from 'graphql-tag';

interface Input {
  id: number;
}

export const DELETE_AD_MEDIA_TEMPLATE = gql`
  mutation deleteAdMediaTemplate($id: Int!) {
    deleteAdMediaTemplate(id: $id)
  }
`;

export default function deleteAdMediaTemplate(input: Input) {
  return window.apolloClient.mutate({
    mutation: DELETE_AD_MEDIA_TEMPLATE,
    variables: { id: input.id }
  });
}
