export const hexToRGBA = (hexOrRGBA: string, alpha: number, brightness: number = 1): string => {
  const isHex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hexOrRGBA);

  const adjustBrightness = (value: number): number => {
    return Math.max(0, Math.min(255, value * brightness));
  };

  if (isHex) {
    const hexValue = hexOrRGBA.replace(/^#/, '');

    if (hexValue.length === 3) {
      const r = adjustBrightness(parseInt(hexValue[0] + hexValue[0], 16));
      const g = adjustBrightness(parseInt(hexValue[1] + hexValue[1], 16));
      const b = adjustBrightness(parseInt(hexValue[2] + hexValue[2], 16));
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    if (hexValue.length === 6) {
      const r = adjustBrightness(parseInt(hexValue.slice(0, 2), 16));
      const g = adjustBrightness(parseInt(hexValue.slice(2, 4), 16));
      const b = adjustBrightness(parseInt(hexValue.slice(4, 6), 16));
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
  } else {
    const rgbaParts = hexOrRGBA.match(/(\d+(\.\d+)*)/g);
    if (rgbaParts && rgbaParts.length === 4) {
      const r = adjustBrightness(parseInt(rgbaParts[0]));
      const g = adjustBrightness(parseInt(rgbaParts[1]));
      const b = adjustBrightness(parseInt(rgbaParts[2]));
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
  }

  return `rgba(255, 255, 255, ${alpha})`; // Fallback color
};
