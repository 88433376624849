import { getAuthData } from '../Login/container/Login';
import { Role } from '../UI/utils/roleTypes';

interface DecodeJWTToken {
  coachId: number;
  exp: number;
  funnelId: number;
  iat: number;
  role: Role;
}

export function decodeJWTToken(): DecodeJWTToken {
  const token = getAuthData('token');
  return JSON.parse(atob(token.split('.')[1]));
}
