import { useEffect } from 'react';
import {
  getCurrentlyOpenedFunnelIds,
  removeFunnelFromCurrentlyOpenedFunnels,
  setCurrentlyOpenedFunnelIds
} from '../../helper/sharedFunctions';
import { getFunnelIdFromToken } from '../../../UI/utils/mixpanel';
import { LocalStorageKeys } from '../../../Share/constants/localstorageKeys';

const ManageCurrentlyOpenedFunnels = ({
  onPageOpenedInAnotherTab
}: {
  onPageOpenedInAnotherTab: Function;
}) => {
  const funnelId = getFunnelIdFromToken();
  // It will check for the currently opened funnels in localStorage and add the current funnel id to the list if the ID is removed by some other Tab
  const saveFunnelsOnLocalStorageEvent = function(e: StorageEvent) {
    const funnelId = getFunnelIdFromToken();
    if (e.key == LocalStorageKeys.CURRENTLY_OPENED_FUNNELS) {
      let existingFunnelIds = getCurrentlyOpenedFunnelIds();

      if (!existingFunnelIds.includes(funnelId)) {
        existingFunnelIds.push(funnelId);
        setCurrentlyOpenedFunnelIds(existingFunnelIds);
      }
    }
  };
  useEffect(() => {
    let existingFunnelIds = getCurrentlyOpenedFunnelIds();
    if (existingFunnelIds.includes(funnelId)) {
      onPageOpenedInAnotherTab();
    } else {
      existingFunnelIds.push(funnelId);
      setCurrentlyOpenedFunnelIds(existingFunnelIds);
    }
    window.addEventListener('storage', saveFunnelsOnLocalStorageEvent);
    window.addEventListener('unload', removeFunnelFromCurrentlyOpenedFunnels);
    return () => {
      removeFunnelFromCurrentlyOpenedFunnels();
      window.removeEventListener('storage', saveFunnelsOnLocalStorageEvent);
      window.removeEventListener('unload', removeFunnelFromCurrentlyOpenedFunnels);
    };
  }, []);
  return null;
};

export default ManageCurrentlyOpenedFunnels;
