import React, { useEffect, useState } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import {
  Alert,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  InputNumber,
  Popover,
  Row,
  Slider,
  Switch
} from 'antd';
import BuilderSettingsTitle from '../../sharedUI/BuilderSettingsTitle';
import { InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import { CalendarSelector } from '../../../../Funnel/components/subcomponents/CalendarSelector';
import BusinessHours, {
  BusinessHoursInterface
} from '../../../../Funnel/components/subcomponents/BusinessHours';
import SpecialClosure, {
  SpecialClosureInterface
} from '../../../../Funnel/components/subcomponents/SpecialClosures';
import ScarcitySettings from './ScarcitySettings';
import { useBuilderGeneralSettings } from '../../../redux/builderSlice';
import { CRAFT_ELEMENTS, CRAFT_ELEMENTS_LABEL } from '../../../config/craftElements';
import { ActionsController } from '../../sharedUI/ActionsController';
import useCalendars from '../../../hooks/useCalendars';
import { CalendarSettingsMessages } from '../../../../config/messages';
import { getAllDaysOfWeekFormatted } from '../../../helper/calendar';
import BuilderSettingsTogglePro from '../../sharedUI/BuilderSettingsTogglePro';
import { CraftElementBaseProps } from '../../../helper/craftJs';

const endOfHour = moment()
  .endOf('hour')
  .add(1, 'minute');

interface PropsInterface extends CraftElementBaseProps {
  calendarId?: string;
  shifts: BusinessHoursInterface[];
  specialClosures: SpecialClosureInterface[];
  bookingRangeInHours: number[];
  maxBookingsPerDay: number;
  slotBufferInMinutes: number;
  durationInMinutes: number;
  busyStatus: boolean;
}

const CalenderComponent = (props: PropsInterface) => {
  const { currentNode } = useNode(node => ({
    currentNode: node
  }));
  const { language } = useBuilderGeneralSettings();
  const monthLong = moment()
    .locale((language || 'DE').toLowerCase())
    .format('MMMM');

  return (
    <ActionsController
      className="calendar__dummy max-width__700px-centered"
      label={CRAFT_ELEMENTS_LABEL[currentNode.data.displayName]}
    >
      <div className="calendar">
        <div className="day-selector">
          <div className="day-selector__navigation-mobile">
            <div className="day-selector__left">
              <i className="icon-provider FiChevronLeft ">
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <polyline points="15 18 9 12 15 6"></polyline>
                </svg>
              </i>
            </div>
            <span className="month">{monthLong}</span>
            <div className="day-selector__right">
              <i className="icon-provider FiChevronRight ">
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <polyline points="9 18 15 12 9 6"></polyline>
                </svg>
              </i>
            </div>
          </div>
          <ul className="day-selector__days">
            {getAllDaysOfWeekFormatted(language || 'DE').map(
              ({ isToday, dayOfMonth, dayOfWeek, month }, index) => (
                <li key={index} className={isToday ? 'active' : ' past'}>
                  <span className="dow">{dayOfMonth}</span>
                  <span className="dom">{dayOfWeek}</span>
                  <span className="month">{month}</span>
                </li>
              )
            )}
          </ul>
        </div>
        <div className="timeslots">
          <div className="timeslot-wrapper">
            <div className="time-divider">
              <div className="line"></div>
              <span>15:00 - 17:00</span>
            </div>
            <div className="timeslot-wrapper__inner">
              <span className="timeslot">15:00</span>
              <span className="timeslot">15:30</span>
              <span className="timeslot">16:00</span>
              <span className="timeslot">16:30</span>
            </div>
          </div>
          <div className="timeslot-wrapper">
            <div className="time-divider">
              <div className="line"></div>
              <span>17:00 - 18:00</span>
            </div>
            <div className="timeslot-wrapper__inner">
              <span className="timeslot">17:00</span>
              <span className="timeslot">17:30</span>
            </div>
          </div>
        </div>
      </div>

      <Row>
        <Col span={24}>
          <Alert
            type="warning"
            showIcon
            className="mb-0"
            message={CalendarSettingsMessages.noLivePreveiwWarning}
          />
        </Col>
      </Row>
    </ActionsController>
  );
};

const CalenderComponentSettings = () => {
  const {
    actions: { setProp },
    props,
    nodeId
  } = useNode(node => ({
    props: node.data.props,
    nodeId: node.id
  }));
  const { query } = useEditor();
  const { loading, error, data } = useCalendars();
  const { getCalendars: calendars } = data || { getCalendars: [] };
  const handleChangeShifts = (shifts: BusinessHoursInterface[]) =>
    setProp((state: any) => (state.shifts = shifts));

  const handleChangeSpecialClosures = (specialClosures: SpecialClosureInterface[]) =>
    setProp((state: any) => (state.specialClosures = specialClosures));

  useEffect(() => {
    if (!query.node(nodeId).get()) return;

    if (!props.calendarId) {
      setProp((state: any) => {
        state.calendarId = calendars.getCalendars?.[0]?.id;
      });
    }
  }, [calendars]);

  return (
    <Col span={24} className="builder__settings-sidebar__container">
      <Card className="settings-card" title="Kalender" bordered={false}>
        <Row className="builder__settings-sidebar__row">
          <Col span={calendars.length ? 11 : 24}>
            {/* Linked with Funnel */}
            <CalendarSelector
              calendarId={props.calendarId}
              handleChangeCalendarId={(calendarId: string) => {
                setProp((state: any) => {
                  state.calendarId = calendarId;
                });
              }}
              classes="w-100"
              calendars={calendars}
            />
          </Col>
          {!!calendars.length && (
            <>
              <Col span={1} />
              <Col span={12} className="ml-1">
                <BuilderSettingsTitle title="Dauer" suffix="(in Minuten)" />
                <InputNumber
                  className="pr-4"
                  min={5}
                  value={props.durationInMinutes}
                  onChange={value => {
                    setProp((state: any) => (state.durationInMinutes = value));
                  }}
                />
              </Col>
            </>
          )}
        </Row>

        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BusinessHours businessHours={props.shifts} onChange={handleChangeShifts} />
          </Col>
        </Row>

        <Row className="builder__settings-sidebar__row"></Row>

        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BuilderSettingsTogglePro
              classNames="py-2 without-border"
              title='"Beschäftigt" blocken'
              infoPopoverText='Im Google-Calendar ist es möglich Termine mit dem Status "Beschäftigt"
              und "Verfügbar" zu versehen. Wenn du diese Funktion nutzt, dann
              aktiviere diese Checkbox. Anschließend blocken nur Termine mit dem
              Status "Beschäftigt" deinen Kalender.'
              rightContent={
                <Switch
                  size="small"
                  checked={props.busyStatus}
                  onChange={checked => {
                    setProp((props: any) => (props.busyStatus = checked));
                  }}
                />
              }
            />
          </Col>
        </Row>

        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <SpecialClosure
              specialClosures={props.specialClosures}
              onChange={handleChangeSpecialClosures}
            />
          </Col>
        </Row>

        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <Form.Item
              className="mb-0"
              extra={`Buchung min. ${props.bookingRangeInHours[0]} und max. ${props.bookingRangeInHours[1]} Std. im Voraus`}
            >
              <label>Buchungsspanne</label>
              <Slider
                range
                step={1}
                max={props.bookingRangeInHours[1] + 100}
                value={props.bookingRangeInHours}
                defaultValue={[1, 360]}
                onChange={(value: any) => {
                  setProp((state: any) => (state.bookingRangeInHours = value));
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <ScarcitySettings
          onChange={values => {
            Object.keys(values).forEach(key => {
              setProp((state: any) => (state[key] = values[key]));
            });
          }}
          maxBookingsPerDay={props.maxBookingsPerDay}
          slotBufferInMinutes={props.slotBufferInMinutes}
        />
      </Card>
    </Col>
  );
};

const CalenderComponentDefaultProps: PropsInterface = {
  specialClosures: [],
  shifts: [{ dow: [1, 2, 3, 4, 5], start: '08:00', end: '16:00' }],
  bookingRangeInHours: [1, 360],
  maxBookingsPerDay: 5,
  slotBufferInMinutes: 15,
  busyStatus: true,
  durationInMinutes: 15,
  onlySettingsReq: true
};

CalenderComponent.craft = {
  name: CRAFT_ELEMENTS.CALENDAR,
  props: CalenderComponentDefaultProps,
  related: {
    settings: CalenderComponentSettings
  }
};

export default CalenderComponent;
