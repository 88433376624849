import { Card, Col, Input, Row, Typography, Form } from 'antd';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import {
  getFunnelIdFromToken,
  getMixPanelEventExistingTrackingData,
  mixpanelEvents,
  saveMixpanelEventOccurrence
} from '../../../UI/utils/mixpanel';
import BuilderSettingsTitle from '../sharedUI/BuilderSettingsTitle';

const SenderDetail = ({ value, onChange }: { value: string; onChange: (e: any) => void }) => {
  return (
    <Row className="builder__settings-sidebar__row px-4">
      <Col span={24}>
        <BuilderSettingsTitle title="Absender" />
        <Form.Item
          className="mb-0"
          extra="Wird in allen E-Mails als Absender genutzt. Standard ist dein Name."
        >
          <Input
            placeholder="z.B. dein Name oder Firma"
            value={value}
            onChange={e => {
              onChange(e.target.value.replace(/[\/\\@_^|()$~%.'":*?<>{}]/g, ''));
              if (
                e.target.value &&
                !getMixPanelEventExistingTrackingData(
                  mixpanelEvents.ADDED_SENDER_INSIDE_EMAIL_SETTINGS
                )
              ) {
                mixpanel.track(mixpanelEvents.ADDED_SENDER_INSIDE_EMAIL_SETTINGS, {
                  funnelId: getFunnelIdFromToken()
                });
                saveMixpanelEventOccurrence(mixpanelEvents.ADDED_SENDER_INSIDE_EMAIL_SETTINGS);
              }
            }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default SenderDetail;
