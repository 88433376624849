import { Row, Col } from 'antd';
import React from 'react';
import { TEMPLATE_KEYS } from '../../Container/helper';
import { useElementsPresets } from '../../../../redux/builderSlice';
import { builderPresetElements } from '../../../../interfaces/builderSliceTypes';
import { choicePresetsInitialValues } from '../../../../interfaces/presetsInititalValues';

type ChoiceTemplatesForPresetsProps = {
  onChange: (key: string, value: any) => void;
};

const ChoiceTemplatesForPresets = ({ onChange }: ChoiceTemplatesForPresetsProps) => {
  const values = useElementsPresets(builderPresetElements.CHOICE) || choicePresetsInitialValues;

  const handleChange = (value: string) => {
    onChange('templateKey', value);
  };

  return (
    <Row gutter={16} className="builder__settings-sidebar__row">
      <Col span={6}>
        <div
          className={`
          choice-template-setting 
          choice-template-setting__right-icon
          ${
            values?.templateKey === TEMPLATE_KEYS.ICON_ON_RIGHT
              ? 'choice-template-setting__active'
              : ''
          }`}
          onClick={() => {
            handleChange(TEMPLATE_KEYS.ICON_ON_RIGHT);
          }}
        >
          <img src="/dashboard/choice-template-icon-right.svg" />
        </div>
      </Col>
      <Col span={6}>
        <div
          className={`
          choice-template-setting 
          choice-template-setting__center-icon
          ${
            values?.templateKey === TEMPLATE_KEYS.ICON_ON_CENTER
              ? 'choice-template-setting__active'
              : ''
          }`}
          onClick={() => {
            handleChange(TEMPLATE_KEYS.ICON_ON_CENTER);
          }}
        >
          <img src="/dashboard/choice-template-icon-center.svg" />
        </div>
      </Col>
      <Col span={6}>
        <div
          className={`
          choice-template-setting 
          choice-template-setting__left-icon
          ${
            values?.templateKey === TEMPLATE_KEYS.ICON_ON_LEFT
              ? 'choice-template-setting__active'
              : ''
          }`}
          onClick={() => {
            handleChange(TEMPLATE_KEYS.ICON_ON_LEFT);
          }}
        >
          <img src="/dashboard/choice-template-icon-left.svg" />
        </div>
      </Col>
      <Col span={6}>
        <div
          className={`
          choice-template-setting 
          choice-template-setting__top-icon
          ${
            values?.templateKey === TEMPLATE_KEYS.ICON_ON_TOP
              ? 'choice-template-setting__active'
              : ''
          }`}
          onClick={() => {
            handleChange(TEMPLATE_KEYS.ICON_ON_TOP);
          }}
        >
          <img src="/dashboard/choice-template-icon-top.svg" />
        </div>
      </Col>
    </Row>
  );
};

export default ChoiceTemplatesForPresets;
