import { ActionEvents } from '../../Builder/hooks/useBookings';
import { FaPhone, FaPhoneSlash } from 'react-icons/fa';
import { Tag } from 'antd';
import React from 'react';
import moment from 'moment';
import dateFormats from '../../config/dateFormats';

export interface EventTypesInterface {
  key: string;
  text: string;
}

export const eventTypes: EventTypesInterface[] = [
  { key: 'ALL', text: 'Alle' },
  { key: 'WITH_APPOINTMENT', text: 'Bewerbungen mit Termin' },
  { key: 'WITHOUT_APPOINTMENT', text: 'Bewerbungen ohne Termin' },
  { key: 'TRACKINGS', text: 'Unvollständige Bewerbungen' }
];

export const actionEventType = {
  LAST_NOT_REACHED_TIME: 'Nicht erreicht',
  REACHED_TIME: 'Erreicht'
};

export const NotReachedTableColumns = [
  {
    title: 'Status',
    dataIndex: 'actionEventType',
    render: (text: any, record: any) => {
      // @ts-ignore
      const actionEvent = actionEventType[record?.actionEventType];

      if (record?.actionEventType === ActionEvents.REACHED_TIME) {
        return (
          <Tag icon={<FaPhone />} color="success">
            {actionEvent}
          </Tag>
        );
      } else if (record?.actionEventType === ActionEvents.LAST_NOT_REACHED_TIME) {
        return (
          <Tag icon={<FaPhoneSlash />} color="error">
            {actionEvent}
          </Tag>
        );
      }

      return actionEvent;
    }
  },
  {
    title: 'Zeit',
    dataIndex: 'eventTime',
    render: (text: any, record: any) => {
      return moment(record.eventTime).format(dateFormats.dateTime)
    }
  }
];
