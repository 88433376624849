import { Event, EventMap } from '../components/EventKanban/types';
import { DraggableLocation } from 'react-beautiful-dnd';
import { BookingInterface } from '../container/Events';
// a little function to help us with reordering the result
export const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

type ReorderQuoteMapArgs = {
  quoteMap: EventMap;
  source: DraggableLocation;
  destination: DraggableLocation;
};

export type ReorderQuoteMapResult = {
  quoteMap: any;
};

export const reorderQuoteMap = ({
  quoteMap,
  source,
  destination
}: ReorderQuoteMapArgs): ReorderQuoteMapResult => {
  const current: BookingInterface[] = [...quoteMap[source.droppableId]];
  const next: BookingInterface[] = [...quoteMap[destination.droppableId]];
  const target: BookingInterface = current[source.index];

  // moving to same list
  if (source.droppableId === destination.droppableId) {
    const reordered: Event[] = reorder(current, source.index, destination.index);
    const result: any = {
      ...quoteMap,
      [source.droppableId]: reordered
    };
    return {
      quoteMap: result
    };
  }

  // moving to different list

  // remove from original
  current.splice(source.index, 1);
  // insert into next
  next.splice(destination.index, 0, target);

  const result: EventMap = {
    ...quoteMap,
    [source.droppableId]: current,
    [destination.droppableId]: next
  };

  return {
    quoteMap: result
  };
};

export const convertToMachineReadable = (label: string): string => {
  return label
    .normalize('NFD')
    .replace(/[^A-Za-z0-9]/g, '')
    .trim()
    .toUpperCase()
    .replace(' ', '_');
};
