import { List } from 'antd';
import React from 'react';
import { IconCard } from '../../IconPickerGrid/container/IconPickerGrid';
import { getFrequentlyUsedIcons } from '../../IconPickerGrid/utils/iconsHelper';

const FrequentlyUsedIconPicker = ({ value, onChange }: any) => {
  const icons = getFrequentlyUsedIcons();

  if (!icons.length) {
    return <p>Keine Icons vorhanden</p>;
  }

  return (
    <div className="frequently-used-icons__container" style={{ maxHeight: 200 }}>
      <List
        grid={{ gutter: 24, column: 3 }}
        dataSource={icons}
        renderItem={(icon: any) => {
          return (
            <List.Item>
              <IconCard
                icon={icon}
                currentIcon={value}
                onClick={() => {
                  onChange(icon);
                }}
              />
            </List.Item>
          );
        }}
      />
    </div>
  );
};

export default FrequentlyUsedIconPicker;
