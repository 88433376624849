import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';
import { cancelAddOnThunk, getAllAddOnsThunk, subscribeAddOnThunk } from './thunk';
import { message } from 'antd';
import { MarketplaceMessages } from '../../config/messages';
import { addOns } from '../config/helper';
import {
  ADD_ON_KEY,
  AddOn,
  MarketplaceState,
  RemoteAddOn,
  RemoteAddOnPermission
} from '../config/types';
import { useAppSelector } from '../../redux/hooks';

const INITIAL_STATE: MarketplaceState = {
  addOns: [],
  selectedAddOn: null,
  loading: false
};

export const marketplaceSlice = createSlice({
  name: 'marketplace',
  initialState: INITIAL_STATE,
  reducers: {
    resetToInitialMarketplaceState: () => {
      return INITIAL_STATE;
    },
    setAddOns: (state, action: PayloadAction<RemoteAddOn[] | []>) => {
      state.addOns = action.payload;
    },
    setSelectedAddOn: (state, action: PayloadAction<AddOn | null>) => {
      state.selectedAddOn = action.payload;
    }
  },
  extraReducers: {
    //@ts-ignore
    [getAllAddOnsThunk.pending]: (state: MarketplaceState, action: PayloadAction) => {
      state.loading = true;
    },
    //@ts-ignore
    [getAllAddOnsThunk.rejected]: (state: MarketplaceState, action: PayloadAction) => {
      state.loading = false;
      message.success(MarketplaceMessages.getAllAddOnsError, 5);
    },
    //@ts-ignore
    [getAllAddOnsThunk.fulfilled]: (
      state: MarketplaceState,
      action: PayloadAction<{ addOns: RemoteAddOn[] }>
    ) => {
      state.addOns = action.payload.addOns;
      state.loading = false;
    },
    //@ts-ignore
    [subscribeAddOnThunk.pending]: (state: MarketplaceState, action: PayloadAction) => {
      state.loading = true;
    },
    //@ts-ignore
    [subscribeAddOnThunk.rejected]: (state: MarketplaceState, action: PayloadAction) => {
      state.loading = false;
      message.error(MarketplaceMessages.subscribeAddOnError, 5);
    },
    //@ts-ignore
    [subscribeAddOnThunk.fulfilled]: (
      state: MarketplaceState,
      action: PayloadAction<{ addOnId: number; permission: RemoteAddOnPermission }>
    ) => {
      const currentAddOnIndex = state.addOns.findIndex(({ id }) => id === action.payload.addOnId);
      state.addOns[currentAddOnIndex].permission = action.payload.permission;
      if (state.selectedAddOn) state.selectedAddOn.permission = action.payload.permission;

      message.success(MarketplaceMessages.subscribeAddOnSuccess, 5);

      state.loading = false;
    },
    //@ts-ignore
    [cancelAddOnThunk.pending]: (state: MarketplaceState, action: PayloadAction) => {
      state.loading = true;
    },
    //@ts-ignore
    [cancelAddOnThunk.rejected]: (state: MarketplaceState, action: PayloadAction) => {
      state.loading = false;
      message.error(MarketplaceMessages.cancelAddOnError, 5);
    },
    //@ts-ignore
    [cancelAddOnThunk.fulfilled]: (
      state: MarketplaceState,
      action: PayloadAction<{ addOnId: number; permission: RemoteAddOnPermission }>
    ) => {
      const currentAddOnIndex = state.addOns.findIndex(({ id }) => id === action.payload.addOnId);
      state.addOns[currentAddOnIndex].permission = action.payload.permission;
      if (state.selectedAddOn) state.selectedAddOn.permission = action.payload.permission;

      message.success(MarketplaceMessages.cancelAddOnSuccess, 5);

      state.loading = false;
    }
  }
});

export const { resetToInitialMarketplaceState, setSelectedAddOn } = marketplaceSlice.actions;

export const selectAddOnPermission = (state: RootState, key: ADD_ON_KEY) =>
  !!state.marketplace.addOns.find(({ key: addOnKey }) => addOnKey === key)?.permission?.valid;

export const selectSelectedAddOn = (state: RootState) => state.marketplace.selectedAddOn;

export const selectAddOns = (state: RootState) => {
  return addOns.map(addOn => ({
    ...addOn,
    ...state.marketplace.addOns?.find(({ id }) => id === addOn.id)
  }));
};

export const useMarketplaceLoading = () => useAppSelector(state => state.marketplace.loading);  

export default marketplaceSlice.reducer;
