import React, { useEffect } from 'react';
import { Form, Input, Row, Col } from 'antd';
import BuilderSettingsTitle from '../../../components/sharedUI/BuilderSettingsTitle';
import {
  removeSpecialCharsFromString,
  uniqueIdentifierFormValidator
} from '../../../../Funnel/helper/url';
import BuilderValidator from '../../../container/BuilderValidator';
import { useAppDispatch } from '../../../../redux/hooks';
import {
  setWidgetUniqueIdentifier,
  setWidgetTitle,
  useWidget
} from '../../redux/widgetBuilderSlice';
import { useWidgetById } from '../../../../Funnel/redux/funnelSlice';
import UniqueIdentifierNoLongerAvailable from '../../../components/sharedUI/UniqueIdentifierNoLongerAvailable';

export const GeneralSettingsFormRules = {
  uniqueIdentifier: [{ required: true, message: 'Bitte definiere eine URL für deinen Widget.' }],
  title: [
    { required: true, message: 'Gib deinem Widget einen Namen.' },
    { max: 60, message: 'Ein Wert darf nicht länger als 60 Zeichen sein.' }
  ]
};

function WidgetGeneralSettings() {
  const [form] = Form.useForm();
  const generalSettings = useWidget();
  const widget = useWidgetById(generalSettings.id);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      form.getFieldValue('title') !== generalSettings.title ||
      form.getFieldValue('uniqueIdentifier') !== generalSettings.uniqueIdentifier
    ) {
      form.setFieldsValue({
        title: generalSettings.title,
        uniqueIdentifier: generalSettings.uniqueIdentifier
      });
    }
  }, [generalSettings.title, generalSettings.uniqueIdentifier]);

  return (
    <>
      <Col span={24} className="builder__settings-sidebar__container">
        <label className="builder__settings-sidebar__container__simple-title">
          Grundeinstellungen
        </label>

        <Row className="builder__settings-sidebar__row pt-3">
          <Col span={24} className="mb-3 px-4">
            <BuilderValidator form={form}>
              <Form
                name="general_settings_form"
                layout="vertical"
                form={form}
                scrollToFirstError={true}
                initialValues={{
                  title: generalSettings.title,
                  uniqueIdentifier: generalSettings.uniqueIdentifier
                }}
              >
                <div className="d-flex align-items-center">
                  <BuilderSettingsTitle title="Titel" suffix="(nur für dich)" />
                </div>
                <Form.Item name="title" rules={GeneralSettingsFormRules.title}>
                  <Input
                    data-testid="funnelTitleInput"
                    placeholder="Mein Recruiting Widget"
                    value={generalSettings.title}
                    maxLength={60}
                    onChange={e => {
                      dispatch(setWidgetTitle(e.target.value));
                    }}
                  />
                </Form.Item>

                <div className="d-flex align-items-center">
                  <BuilderSettingsTitle title="URL" suffix="(app.meetovo.de/widget/xyz)" />
                </div>
                <UniqueIdentifierNoLongerAvailable
                  oldUniqueIdentifier={widget?.uniqueIdentifier}
                  newUniqueIdentifier={generalSettings?.uniqueIdentifier}
                />
                <Form.Item
                  name="uniqueIdentifier"
                  rules={[
                    ...GeneralSettingsFormRules.uniqueIdentifier,
                    uniqueIdentifierFormValidator
                  ]}
                  extra="Um dein Widget auch als Link abzurufen."
                >
                  <Input
                    data-testid="funnleUniqueIdentifier"
                    placeholder="mein-recruiting-widget"
                    value={generalSettings.uniqueIdentifier}
                    onChange={e => {
                      const cleanUniqueIdentifier = removeSpecialCharsFromString(e.target.value);
                      dispatch(setWidgetUniqueIdentifier(cleanUniqueIdentifier));
                    }}
                  />
                </Form.Item>
              </Form>
            </BuilderValidator>
          </Col>
        </Row>
      </Col>
    </>
  );
}

export default WidgetGeneralSettings;
