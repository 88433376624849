import React from 'react';
import { Modal, Button, Row, Col, Typography, Result } from 'antd';
import ApplicantNotificationModal from './ApplicantNotificationModal';
import { applicantNotificationTypeEnum } from '../../../Builder/components/emailTemplate/utils';

function DeclineApplicantModal({
  visible,
  setVisibility,
  eventId
}: {
  visible: boolean;
  setVisibility: (visible: boolean) => void;
  eventId: number;
}) {
  const handleCancel: () => void = () => {
    setVisibility(false);
  };

  return (
    <React.Fragment>
      <Modal
        width={500}
        visible={visible}
        destroyOnClose
        onCancel={handleCancel}
        className="mb-6 decline-applicant"
        footer={null}
      >
        <Result
          status="info"
          title="Möchtest du eine Absagenachricht versenden?"
          subTitle={
            <p>
              Anschließend öffnet sich eine Vorschau dort kannst du die im Funnel hinterlege Vorlage
              ansehen und absenden. Im Falle einer E-Mail lässt sich diese zusätzlich anpassen.
            </p>
          }
          extra={[
            <>
              <ApplicantNotificationModal
                eventId={eventId}
                trigger={<Button type="primary">Ja, Nachricht öffnen</Button>}
                setConfirmModalVisibility={setVisibility}
                applicantNotificationType={applicantNotificationTypeEnum.CANCELATION}
              />
              <Button type="link" className="d-block w-100" onClick={() => handleCancel()}>
                Nein
              </Button>
            </>
          ]}
        />
      </Modal>
    </React.Fragment>
  );
}

export default DeclineApplicantModal;
