/* eslint-disable import/prefer-default-export */
import { message } from 'antd';
import { EditorState, Modifier } from 'draft-js';
import { EmailTemplateType, EmailType } from '../interfaces/builderSliceTypes';
import { AgencyOSBuilderMessages, FunnelMessages, UIMessages } from '../../config/messages';
import { useEffect, useState } from 'react';
import { getFunnelIdFromToken } from '../../UI/utils/mixpanel';
import { LocalStorageKeys } from '../../Share/constants/localstorageKeys';
import { BuilderBase, builderConfig } from '../config/builderUi';
import { useLocation } from 'react-router-dom';
import { useIsWidgetsBuilderPath } from '../hooks/useIsWidgetsBuilderPath';
import { isBuilderInvalid } from '../container/BuilderValidator';
import { AGENCY_OS_BUILDER_PATH } from '../../UI/paths';
import { useInternalEditorReturnType } from '@craftjs/core/lib/editor/useInternalEditor';
import { childSelectionOnParentClick, parentSelectionOnChildClick } from './craftJs';
import { Delete } from 'craftjs-utils-meetovo';
import { defautlWidgetThemeKeys } from '../WidgetsBuilder/helper/helper';

export enum BUILDER_TYPE {
  FUNNEL = 'FUNNEL',
  AGENCY_OS_BUILDER = 'AGENCY_OS_BUILDER'
}

export const shouldSaveOrShowMessage = (
  shouldSave: boolean,
  type: BUILDER_TYPE = BUILDER_TYPE.FUNNEL
) => {
  if (!shouldSave) {
    if (!localStorage.getItem('shouldSaveOrShowMessage')) {
      localStorage.setItem('shouldSaveOrShowMessage', 'block');
      message.error(
        type === BUILDER_TYPE.FUNNEL
          ? FunnelMessages.twoTabsOpen
          : AgencyOSBuilderMessages.twoTabsOpen,
        8,
        () => {
          localStorage.removeItem('shouldSaveOrShowMessage');
        }
      );
    }
    return false;
  }
  localStorage.removeItem('shouldSaveOrShowMessage');
  return true;
};

export const getFontLink = (family: string) =>
  'https://fonts.googleapis.com/css?family=' +
  family +
  '%3Aregular%2Citalic%2C300%2C700&subset=latin&font-display=swap';

export const getEmailTemplateTabName = (type: EmailType) => {
  const names = {
    CONFIRMATION: 'Bestätigung',
    REMINDER: 'Terminerinnerung',
    CANCELATION: 'Absage',
    INTERNAL_CONFIRMATION: 'Benachrichtigung',
    SCHEDULED: 'Zeitgesteuert',
    NOT_REACHED: 'Nicht erreicht'
  };
  return names[type];
};

export function insertCharacterToEditor(
  characterToInsert: string | number,
  editorState: EditorState
) {
  const currentContent = editorState.getCurrentContent(),
    currentSelection = editorState.getSelection(),
    currentInlineStyles = editorState.getCurrentInlineStyle();

  const newContent = Modifier.replaceText(
    currentContent,
    currentSelection,
    `${characterToInsert}`,
    currentInlineStyles
  );

  const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');

  return newEditorState;
}

export const getDateTimeFromImageUrl = (url: string | undefined) => {
  return new Date(+(url?.split('-')[8] || ''));
};

export const getTextFromHtml = (e: any) =>
  new DOMParser().parseFromString(e, 'text/html').documentElement.textContent?.trim();

const toolbarText = {
  link_title: 'Titel des Links',
  link: 'Link-Ziel',
  open_in_new_tab: 'Link in neuem Fenster öffnen',
  confirm: 'Hinzufügen',
  cancel: 'Abbrechen'
};

export const translateEmailToolbarText = () => {
  document.querySelector('.email-link-btn')?.addEventListener('click', () => {
    setTimeout(() => {
      if (document.querySelector('.email-link-popup > label:nth-child(1)')) {
        // @ts-ignore
        document.querySelector('.email-link-popup > label:nth-child(1)').innerHTML =
          toolbarText.link_title;
      }
      if (document.querySelector('.email-link-popup > label:nth-child(3)')) {
        // @ts-ignore
        document.querySelector('.email-link-popup > label:nth-child(3)').innerHTML =
          toolbarText.link;
      }
      // @ts-ignore
      document.querySelector(
        '.email-link-popup > label.rdw-link-modal-target-option > span'
      ).innerHTML = toolbarText.open_in_new_tab;
      // @ts-ignore
      document.querySelector('.email-link-popup > span > button:nth-child(1)').innerHTML =
        toolbarText.confirm;
      // @ts-ignore
      document.querySelector('.email-link-popup > span > button:nth-child(2)').innerHTML =
        toolbarText.cancel;
    }, 0);
  });
};

export function reduceColorOpacity(color: string, opacity: number): string {
  if (color.includes('#')) {
    color = color.split('#')[1];
  }
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

export async function getHashFromObject(obj: Object | undefined | null): Promise<string | null> {
  try {
    if (typeof obj !== 'object') return null;

    const encoder = new TextEncoder();
    const data = encoder.encode(JSON.stringify(obj));
    const hash = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hash));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  } catch (error) {
    return null;
  }
}

export function useDebounce(value: any, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

export function convertColorToRGBA(inputColor: string = '', opacity: any): string {
  // Check if the input color is in hex format

  if (inputColor.startsWith('rgba')) {
    let colorComponents = inputColor.split(',');
    colorComponents[3] = opacity;
    return `${colorComponents.join(',')})`;
  }

  if (/^#([0-9A-F]{3}){1,2}$/i.test(inputColor)) {
    let hexColor = inputColor.replace('#', '');

    // Expand shorthand hex (e.g., "03F") to full hex (e.g., "0033FF")
    if (hexColor.length === 3) {
      hexColor = hexColor
        .split('')
        .map(char => char + char)
        .join('');
    }

    // Parse the hex values for red, green, and blue
    const red = parseInt(hexColor.slice(0, 2), 16);
    const green = parseInt(hexColor.slice(2, 4), 16);
    const blue = parseInt(hexColor.slice(4, 6), 16);

    // Return the RGBA color with the specified opacity
    return `rgba(${red},${green},${blue},${opacity})`;
  } else {
    // If not a hex color, assume it's already in RGBA format
    return inputColor;
  }
}

export const addOpacityToLinearGradient = (input: string, opacity: number): string => {
  // Regular expression to match rgba codes
  const rgbaRegex = /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*\.?\d+))?\)/gi;

  // Replace RGBA codes with modified opacity
  const modifiedGradient = input.replace(rgbaRegex, (_, r, g, b, a) => {
    const newOpacity = a ? (parseFloat(a) * opacity).toString() : opacity.toString();
    return `rgba(${r}, ${g}, ${b}, ${newOpacity})`;
  });

  return modifiedGradient;
};

export function getUrlQueryParamValue(param: string) {
  const url = new URL(window.location.href);
  return url.searchParams.get(param);
}

export const setCurrentlyOpenedFunnelIds = (idsArray: number[]) =>
  localStorage.setItem(LocalStorageKeys.CURRENTLY_OPENED_FUNNELS, JSON.stringify(idsArray));

export const getCurrentlyOpenedFunnelIds = (): number[] =>
  JSON.parse(localStorage.getItem(LocalStorageKeys.CURRENTLY_OPENED_FUNNELS) || '[]');

export const removeFunnelFromCurrentlyOpenedFunnels = () => {
  const funnelId = getFunnelIdFromToken();
  let existingFunnelIds = getCurrentlyOpenedFunnelIds();
  let tmp = existingFunnelIds.filter(_ => _ != funnelId);
  setCurrentlyOpenedFunnelIds(tmp);
};

export enum AvailablePlans {
  Pro = 'Pro',
  Expert = 'Expert',
  Starter = 'Starter',
  Jährlich = 'Jährlich',
  Monatlich = 'Monatlich'
}

const planIds = {
  [AvailablePlans.Pro]: ['754247', '754248'],
  [AvailablePlans.Expert]: ['754249', '754250'],
  [AvailablePlans.Starter]: ['754251', '754252'],
  [AvailablePlans.Jährlich]: ['602947'],
  [AvailablePlans.Monatlich]: ['597746']
};

export function checkCurrentPlan(userPlan: AvailablePlans, subscriptionPlanId: string): boolean {
  return planIds[userPlan].includes(subscriptionPlanId);
}

export function getCurrentRoute() {
  return useLocation().pathname;
}

export const getBuilderConfigForRoute = (baseRoute = '/builder/bearbeiten'): BuilderBase => {
  return builderConfig.find((base: BuilderBase) => baseRoute.includes(base.onClickRoute))!;
};

export const getOtherEmailAddressesToSendForInternalConfirmation = ({
  emailTemplates
}: {
  emailTemplates: EmailTemplateType[];
}) => {
  return emailTemplates.find(({ type }) => type === EmailType.INTERNAL_CONFIRMATION)
    ?.otherEmailAddressesToSend;
};

export function convertMinutesToWeeksDaysHoursMinutes(minutes: number) {
  const minutesInHour = 60;
  const hoursInDay = 24;
  const daysInWeek = 7;
  const weeks = Math.floor(minutes / (minutesInHour * hoursInDay * daysInWeek));
  const remainingMinutes = minutes % (minutesInHour * hoursInDay * daysInWeek);
  const days = Math.floor(remainingMinutes / (minutesInHour * hoursInDay));
  const remainingMinutes2 = remainingMinutes % (minutesInHour * hoursInDay);
  const hours = Math.floor(remainingMinutes2 / minutesInHour);
  const remainingMinutes3 = remainingMinutes2 % minutesInHour;

  return {
    weeks: weeks,
    days: days,
    hours: hours,
    minutes: remainingMinutes3
  };
}

export const checkIsWidgetTheme = (colorKey: string) =>
  useIsWidgetsBuilderPath() ? defautlWidgetThemeKeys.includes(colorKey) : true;
export const addNewTemplateHandler = async (
  e: any,
  selectedElementTab: string,
  handleGeneralSettingsDataSave: (payload: string) => Promise<void>
) => {
  if (isBuilderInvalid()) return;
  e.preventDefault();
  e.stopPropagation();

  if (
    selectedElementTab === '1' &&
    (location.href.includes('/builder/bearbeiten/elemente') ||
      location.href.includes(AGENCY_OS_BUILDER_PATH.hubSettings))
  ) {
    message.info(UIMessages.elementsAlreadyOpen);
    return;
  }
  await handleGeneralSettingsDataSave('1');
};

export const handleSelectCraftElement = ({
  openParentSettingsOnChildSettings,
  openChildSettingsOnParentSettings,
  nodeId,
  query,
  actions
}: {
  openParentSettingsOnChildSettings?: boolean;
  openChildSettingsOnParentSettings?: boolean;
  nodeId: string;
  query: Delete<useInternalEditorReturnType<any>['query'], 'deserialize'>;
  actions: any;
}) => {
  if (openParentSettingsOnChildSettings) {
    parentSelectionOnChildClick(nodeId, query, actions.selectNode);
  } else if (openChildSettingsOnParentSettings) {
    childSelectionOnParentClick(nodeId, query, actions.selectNode);
  } else {
    actions.selectNode(nodeId);
  }
};

export const parseJSONFieldsWithinObject: any = (obj: any) => {
  if (typeof obj !== 'object' || obj === null) {
    try {
      let cleanedObj = obj;
      if (typeof obj === 'string') {
        if (obj.startsWith('"') && obj.endsWith('"')) {
          cleanedObj = obj.slice(1, -1);
        }
        cleanedObj = cleanedObj.replace(/\\"/g, '"');
      }
      
      const parsedValue = JSON.parse(cleanedObj);
      return parsedValue;
    } catch (error) {
      return obj;
    }
  }

  if (Array.isArray(obj)) {
    return obj.map(parseJSONFieldsWithinObject);
  }

  const parsedObject = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      // @ts-ignore
      parsedObject[key] = parseJSONFieldsWithinObject(obj[key]);
    }
  }

  return parsedObject;
};
