import { CRAFT_ELEMENTS } from '../config/craftElements';
import { EmailTemplateType, EmailType } from '../interfaces/builderSliceTypes';
import { checkIfProvidedElementsExistsInBuilder, isProvidedElementExistInFunnel } from './craftJs';

export const userInteractingComponents: string[] = [
  CRAFT_ELEMENTS.LONG_ANSWER,
  CRAFT_ELEMENTS.RANGE_SLIDER,
  CRAFT_ELEMENTS.SINGLE_CHOICE,
  CRAFT_ELEMENTS.MULTIPLE_CHOICE,
  CRAFT_ELEMENTS.CALENDAR,
  CRAFT_ELEMENTS.CONTACT_FORM,
  CRAFT_ELEMENTS.ELEMENT_APPENDER,
  CRAFT_ELEMENTS.UPLOAD_FILE
];

export const elementsOnceInFunnel: string[] = [
  CRAFT_ELEMENTS.CONTACT_FORM,
  CRAFT_ELEMENTS.CALENDAR
];

export const trackUserInteractingComponents = (query: any) =>
  Object.keys(query.getState()?.nodes).find(key =>
    (userInteractingComponents as string[]).includes(
      query.getState().nodes?.[key]?.data?.displayName
    )
  );

export const isElementExistInFunnel = (query: any, elementKey: string) => {
  if (!userInteractingComponents.includes(elementKey)) return false;

  const currentBuilderKeys = checkIfProvidedElementsExistsInBuilder(
    query,
    userInteractingComponents
  );

  const keys = isProvidedElementExistInFunnel(elementsOnceInFunnel, true);
  if (elementsOnceInFunnel.includes(elementKey) && keys[elementKey]) {
    return true;
  } else return Object.values(currentBuilderKeys).reduce((prev, curr) => prev || curr, false);
};

export const canAddTemplateInsideCurrentPage = (
  state: any,
  onceExistingElements: any,
  currentPageUserInteractingElements: any
) => {
  const userInteractingElementsByName = Object.keys(state)
    .map(key => state[key]?.displayName)
    .filter(elementName => userInteractingComponents.includes(elementName));

  const flag = userInteractingElementsByName.find(ele => {
    if (
      onceExistingElements[ele] ||
      Object.values(currentPageUserInteractingElements).reduce((prev, curr) => prev || curr, false)
    )
      return true;
  });

  if (
    userInteractingElementsByName.length === 0 &&
    Object.keys(currentPageUserInteractingElements).length === 0
  ) {
    return false;
  }

  if (userInteractingElementsByName.length > 0 && !!flag) {
    return false;
  }
  return true;
};

export function getNodeIdsForTree(
  serializeNodes: any,
  selectedNodeId: string,
  allNodes: string[] = []
) {
  allNodes.push(selectedNodeId);
  if (serializeNodes[selectedNodeId].nodes.length > 0) {
    serializeNodes[selectedNodeId].nodes.forEach((element: string) => {
      getNodeIdsForTree(serializeNodes, element, allNodes);
    });
  }
  return allNodes;
}

export function removeUnnecessaryNodes(json: any, requiredNodes: string[]) {
  Object.keys(json).map(key => {
    if (!requiredNodes.includes(key)) {
      delete json[key];
    }
  });
  return json;
}

export function orderedEmailTemplates(emailTemplates: EmailTemplateType[]) {
  const data = Object.keys(EmailType).reduce<EmailTemplateType[]>(
    (acc: EmailTemplateType[], type: string): EmailTemplateType[] => {
      const thisTemplate = emailTemplates.find((et: EmailTemplateType) => et.type === type);

      if (!thisTemplate) return acc;
      return [...acc, thisTemplate];
    },
    []
  );
  return data;
}
