import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input } from 'antd';
import FunnelSelector from '../../../SharedUI/components/FunnelSelector';
import { Store } from 'antd/lib/form/interface';
import { FunnelSelectionValidator, SlugValidatior } from '../../utils/domainAndSlugValidation';
import { forceLowerCase } from '../../utils/domainInput';
import { useAllFunnels } from '../../../Funnel/redux/funnelSlice';

interface AddSlugProps {
  domainName: string;
  onAdd(values: Store): any;
  open: boolean;
  setOpen(open: boolean): any;
}

const AddSlug = ({ domainName, onAdd, open, setOpen }: AddSlugProps) => {
  const [forceRerenderCount, setForceRerenderCount] = useState(0);
  const funnels = useAllFunnels();
  const [form] = Form.useForm();

  const handleScrollToBottom = () => {
    const inner = document.querySelector('#slug-list-modal');
    inner?.scrollTo(0, inner?.scrollHeight || 0);
  };

  const handleOpen = () => {
    setOpen(true);
    handleScrollToBottom();
  };

  const handleAdd = (values: Store) => {
    setOpen(false);
    onAdd(values);
    form.resetFields();
    handleScrollToBottom();
  };

  const handleForceUpdate = () => {
    setForceRerenderCount(forceRerenderCount + 1);
  };

  const currentSlug = form?.getFieldValue(`slug`) || ''
  const extra = 'Link-Vorschau: ' + domainName + (currentSlug !== '/' && currentSlug.includes('/') ? currentSlug : '');

  if (!open)
    return (
      <div className="connect-settings-changer__add-button-wrapper" style={{ border: 'none' }}>
        <Button
          onClick={handleOpen}
          type="ghost"
          className="lead-qualifier__add icon-circle-button-with-label"
        >
          <span className="ui__icon-circle">
            <PlusOutlined />
          </span>
          Hinzufügen
        </Button>
      </div>
    );

  if (open)
    return (
      <Card className="ui__white-round-wrapper" style={{ marginBottom: '1em' }}>
        <Form
          name="add-slug-form"
          form={form}
          layout="vertical"
          scrollToFirstError={true}
          onFinish={handleAdd}
          onValuesChange={handleForceUpdate}
          initialValues={{ slug: '/' }}
        >
          <Form.Item
            required
            name="slug"
            label="Slug"
            rules={SlugValidatior}
            extra={extra}
          >
            <Input onInput={forceLowerCase} />
          </Form.Item>
          <Form.Item required rules={FunnelSelectionValidator} name="funnelId" label={'Funnel'}>
            <FunnelSelector
              placeholder="Wähle einen Funnel"
              defaultValue={[form.getFieldValue('funnel')]}
              customFunnels={[
                {
                  title: 'Ohne Funnel',
                  id: -1
                }
              ]}
              onChange={selectFunnelIds => {
                form.setFieldsValue({
                  [`funnelId`]: selectFunnelIds
                });
              }}
              initialData={funnels}
              allSelectdable={false}
              showActiveFunelsOnly={true}
            />
          </Form.Item>
          <Button type="primary" onClick={() => form.submit()}>
            Hinzufügen
          </Button>
          <Button color="gray" type="link" onClick={() => setOpen(false)}>
            Abbrechen
          </Button>
        </Form>
      </Card>
    );

  return null;
};

export default AddSlug;
