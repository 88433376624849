import React, { useEffect, useState } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { Card, Col, Row, Slider } from 'antd';
import BuilderSettingsTitle from '../sharedUI/BuilderSettingsTitle';
import { ActionsController } from '../sharedUI/ActionsController';
import { CRAFT_ELEMENTS } from '../../config/craftElements';
import TextAlignmentButtons from '../../../SharedUI/components/TextAlignmentButtons';
import {
  CraftElementBaseProps,
  getElementColor,
  getKeyByValue,
  getRandomId,
  getValueByKey
} from '../../helper/craftJs';
import ImageUploadV3 from '../../../GeneralComponents/ImageUploadV3';
import {
  ImageAvailableOpacity,
  defaultImageOpacity
} from '../../AdBuilder/interfaces/ImageSizeInterface';
import RichTextComponent from './RichTextComponent/container/RichTextComponent';
import ImageWithoutCacheOnError from '../sharedUI/ImageWithoutCacheOnError';
import { useAgencyOSCurrentlyEditingTheme } from '../../AgencyOSBuilder/redux/agencyOSBuilderSlice';
import { AGENCY_OS_THEME_KEYS } from '../../AgencyOSBuilder/interfaces/agencyOSBuilderSliceTypes';
import { convertColorToRGBA } from '../../helper/sharedFunctions';
export const defaultImage = `${process.env.PUBLIC_URL}/image-placeholder.png`;

interface Props extends CraftElementBaseProps {
  src?: string;
  opacity?: number;
  children?: any;
  align?: string;
}
const initialStateForRichText =
  '{"blocks":[{"key":"aa9lq","text":"Welcome to Your Hub","type":"center","depth":0,"inlineStyleRanges":[{"offset":0,"length":19,"style":"CUSTOM_FONT_SIZE_2.5em"},{"offset":0,"length":19,"style":"CUSTOM_COLOR_#f1e8df"},{"offset":0,"length":19,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"ce2hc","text":"","type":"center","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"fc4nv","text":"Here you will find all the information that you need to work successfully with the software and with us, so that you can soon welcome new employees to the team.","type":"center","depth":0,"inlineStyleRanges":[{"offset":0,"length":160,"style":"CUSTOM_COLOR_#f1e8df"},{"offset":0,"length":160,"style":"CUSTOM_FONT_SIZE_1.5em"}],"entityRanges":[],"data":{}}],"entityMap":{}}';

export const BannerComponent = (props: Props) => {
  const { children } = props;
  const { currentNode, id } = useNode(node => ({
    currentNode: node
  }));
  const { actions, query } = useEditor();
  const theme = useAgencyOSCurrentlyEditingTheme();

  useEffect(() => {
    if (currentNode.data.nodes.length === 0) {
      const node = query.createNode(
        <Element
          id={getRandomId(10)}
          is={RichTextComponent}
          rawState={JSON.parse(initialStateForRichText)}
          onlySettingsReq={true}
          canDrag={false}
          showPlusIcon={false}
        ></Element>
      );
      actions.add(node, currentNode.id);
    }
  }, []);

  return (
    <ActionsController className="action-container">
      <div
        data-testid="banner-component-testing"
        className="img-container"
        style={{ overflow: 'hidden' }}
      >
        <div
          className="img-overlay"
          style={{
            background: `linear-gradient(to right, ${convertColorToRGBA(
              getElementColor(AGENCY_OS_THEME_KEYS.PRIMARY_COLOR, theme),
              props.opacity
            )}, ${convertColorToRGBA(
              getElementColor(AGENCY_OS_THEME_KEYS.PRIMARY_CONTRAST_COLOR, theme),
              props.opacity
            )})`
          }}
        />
        <ImageWithoutCacheOnError width={'100%'} src={props.src || defaultImage} />
      </div>

      <div
        className="text-container"
        data-testid="text-container-wrapper"
        style={{ justifyContent: props.align }}
      >
        <div className="text-children">{children}</div>
      </div>
    </ActionsController>
  );
};

export const BannerComponentDefaultProps = {
  opacity: 0.6,
  align: 'center'
};
const marks = {
  1: '10%',
  2: '20%',
  3: '30%',
  4: '40%',
  5: '50%',
  6: '60%',
  7: '70%',
  8: '80%'
};

export const BannerComponentSettings = () => {
  const {
    actions: { setProp },
    props
  } = useNode(node => ({
    props: node.data.props
  }));

  const setImageSrc = (src: string) => {
    setProp((props: any) => {
      props.src = src;
    });
  };

  const [pexelsImage, setPexelsImage] = useState();
  const src = props.src;

  return (
    <div className="builder__settings-sidebar__container">
      <Card className="settings-card" title="Bild" bordered={false}>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BuilderSettingsTitle title="Overlay-Transparenz" />
          </Col>
          <Col span={24} data-testid="image-opacity-slider">
            <Slider
              className="builder-slider-style"
              marks={marks}
              min={1}
              max={8}
              tooltipVisible={false}
              defaultValue={defaultImageOpacity.key}
              value={getKeyByValue(props.opacity, ImageAvailableOpacity).key}
              onChange={value => {
                setProp(
                  (props: any) =>
                    (props.opacity = getValueByKey(value as number, ImageAvailableOpacity).value)
                );
              }}
            />
          </Col>
        </Row>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BuilderSettingsTitle title="Ausrichtung" />
            <TextAlignmentButtons
              align={props.align}
              onChange={align => {
                setProp((props: any) => (props.align = align));
              }}
            />
          </Col>
        </Row>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BuilderSettingsTitle title="Hochladen" />
            <ImageUploadV3
              previewImage={src}
              onChange={(url: string) => {
                setImageSrc(url);
                setPexelsImage(undefined);
              }}
              minWidth={3.84}
              minHeight={1}
              maxWidth={1365}
              minZoom={0}
              uploadedImage
              grid
              restrictPosition={false}
              existingImageURL={pexelsImage}
              onModalCancel={() => {
                setPexelsImage(undefined);
              }}
              possibleAspectRatios={[{ height: 1, width: 3.84 }]}
              showRatioSelector={false}
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
};

BannerComponent.craft = {
  name: CRAFT_ELEMENTS.BANNER,
  props: BannerComponentDefaultProps,
  related: {
    settings: BannerComponentSettings
  }
};
