interface FormatPercentProps {
    value: number, 
    basevalue: number, 
    roundToInt?: boolean,
    returnRawValue?: boolean
}

export const formatPercent = ({value, basevalue, roundToInt, returnRawValue}: FormatPercentProps) => {
    let percent = (value / basevalue) * 100;
    if (roundToInt)
        percent = Math.round(percent)
    else
        percent = Math.round(percent * 100) / 100

    return returnRawValue ? percent : percent + "%"
}