
import gql from 'graphql-tag';


interface SetMessageTemplatesInput {
  id: number
  timeInMinutesTillEvent?: number
  companyName?: String
  contactNumber?: String
  contactPersonCallNumber?: String
  enabled?: Boolean
  scheduledTime?: number
  }

export const SET_FUNNEL_MESSAGE_TEMPLATES = gql`
  mutation setMessageTemplates($input:[SetMessageTemplatesInput!]!) {
    setMessageTemplates(input: $input)
  }
`;

export default function setFunnelMessagingTemplates(input: SetMessageTemplatesInput[]) {
  return window.apolloClient.mutate({
    mutation:SET_FUNNEL_MESSAGE_TEMPLATES,
    variables: { input }
  });
}