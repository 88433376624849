import { SizeInterface } from '../config/builderUi';

export const FontAvailableSizes: SizeInterface[] = [
  {
    key: 1,
    value: '1'
  },
  {
    key: 2,
    finalHtmlElement: "h3",
    value: '1.5'
  },
  {
    key: 3,
    finalHtmlElement: "h2",
    value: '2.5'
  },
  {
    key: 4,
    finalHtmlElement: "h1",
    value: '3.5'
  }
];

export const defaultFontSize = FontAvailableSizes[0];
