import { gql } from '@apollo/client';
import { deepCopy } from '../../../helper/array';

export const SEND_FEEDBACK = gql`
  mutation SendDebugTrackingFeedback($pathname: String, $selectedPageId: Int, $sessionId: String!, $fingerprint: String!, $additionalData: JSON!) {
    sendDebugTrackingFeedback(pathname: $pathname, selectedPageId: $selectedPageId, sessionId: $sessionId, fingerprint: $fingerprint, additionalData: $additionalData) {
      id
    }
  }
`;

export const START_SESSION = gql`
  mutation StartDebugTrackingSession($pathname: String, $selectedPageId: Int, $authToken: String!, $previousSessionId: String, $sessionId: String!, $fingerprint: String!, $device: JSON!, $startedAt: Date!) {
    startDebugTrackingSession(pathname: $pathname, selectedPageId: $selectedPageId, authToken: $authToken, previousSessionId: $previousSessionId, sessionId: $sessionId, fingerprint: $fingerprint, device: $device, startedAt: $startedAt) {
      id
    }
  }
`;

export const END_SESSION = gql`
  mutation EndDebugTrackingSession($pathname: String, $selectedPageId: Int, $sessionId: String!, $endedAt: Date!) {
    endDebugTrackingSession(pathname: $pathname, selectedPageId: $selectedPageId, sessionId: $sessionId, endedAt: $endedAt) {
      id
    }
  }
`;

export const TRACK_INTERACTION = gql`
  mutation trackInteractionDebugTracking($pathname: String, $selectedPageId: Int, $type: String!, $sessionId: String!, $className: String, $elementId: String, $customEventName: String, $craftState: String, $additionalData: JSON, $firedAt: Date!) {
    trackInteractionDebugTracking(pathname: $pathname, selectedPageId: $selectedPageId, type: $type, sessionId: $sessionId, className: $className, elementId: $elementId, customEventName: $customEventName, craftState: $craftState, additionalData: $additionalData, firedAt: $firedAt) {
      id
    }
  }
`;

interface StartSessionMutationParams {
  pathname: string,
  selectedPageId: number,
  authToken: string;
  sessionId: string;
  previousSessionId?: string;
  fingerprint: string;
  device: {
    userAgent: string;
    language: string;
    screenWidth: number;
    screenHeight: number;
    colorDepth: number;
    timezoneOffset: number;
    cookieEnabled: boolean;
    platform: string;
    vendor: string;
    brand: string;
    model: string;
  };
  startedAt: string;
}

export function startSessionMutation(params: StartSessionMutationParams): Promise<any> {
  if (localStorage.getItem("DebugTracking__debug-mode")) {
    console.log("startSessionMutation", params);
    return Promise.resolve();
  }

  return window.apolloClient.mutate({
    mutation: START_SESSION,
    variables: params,
  });
}

interface EndSessionMutationParams {
  selectedPageId: number,
  pathname: string,
  sessionId: string;
  endedAt: string;
}

export function endSessionMutation(params: EndSessionMutationParams): Promise<any> {
  if (localStorage.getItem("DebugTracking__debug-mode")) {
    console.log("endSessionMutation", params);
    return Promise.resolve();
  }

  return window.apolloClient.mutate({
    mutation: END_SESSION,
    variables: params,
  });
}

interface TrackInteractionsMutationParams {
  selectedPageId: number,
  pathname: string,
  type: string;
  sessionId: string;
  className?: string | null;
  elementId?: string;
  customEventName?: string;
  craftState?: string;
  additionalData?: any;
  firedAt: string;
}

export function trackInteractionMutation(params: TrackInteractionsMutationParams): Promise<any> {
  if (localStorage.getItem("DebugTracking__debug-mode")) {
    console.log("trackInteractionMutation", params);
    return Promise.resolve();
  }

  params.additionalData = params.additionalData ? deepCopy(params.additionalData) : undefined;

  return window.apolloClient.mutate({
    mutation: TRACK_INTERACTION,
    variables: params,
  });
}

interface SendFeedbackMutationParams {
  additionalData: object,
  selectedPageId: number,
  pathname: string,
  sessionId: string
  fingerprint: string
}

export function sendFeedbackMutation(params: SendFeedbackMutationParams): Promise<any> {
  params.additionalData = params.additionalData ? deepCopy(params.additionalData) : undefined;

  return window.apolloClient.mutate({
    mutation: SEND_FEEDBACK,
    variables: params,
  });
}