import gql from 'graphql-tag';

export const CANCEL_ADD_ON = gql`
  mutation cancelAddOn($addOnId: Int!) {
    cancelAddOn(addOnId: $addOnId) {
      valid
      validUntil
    }
  }
`;

export default function cancelAddOnAPI(addOnId: number) {
  return window.apolloClient.mutate({
    mutation: CANCEL_ADD_ON,
    variables: { addOnId }
  });
}
