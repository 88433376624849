import React, { useEffect } from 'react';
import { Col as RBCol, Row as RBRow } from 'react-bootstrap';
import { useEditor, useNode, Element } from '@craftjs/core';
import { CRAFT_ELEMENTS } from '../../../../../config/craftElements';
import { ElementAppenderProps } from '../config';
import ElementAppenderSettings from './ElementAppenderSettings';
import ContainerComponentHOC from '../../ContainerHOC';
import { getUniqueId } from '../../../../../helper/craftJs';

const ElementAppender = ({ elementTitle, childNodes, ...props }: any) => {
  const { children } = props;
  const { currentNode } = useNode(node => ({
    currentNode: node
  }));

  const { actions, query } = useEditor();

  useEffect(() => {
    if (currentNode.data.nodes.length === 0) {
      const actionsCreator = actions.history.throttle();
      for (let c1 = 0; c1 < childNodes.length; c1++) {
        const nodeTree = query.parseReactElement(
          <Element
            id={getUniqueId()}
            is={childNodes[c1].node}
            {...childNodes[c1].props}
            onlySettingsReq={true}
          />
        ).toNodeTree();
        actionsCreator.addNodeTree(nodeTree, currentNode.id);
      }
    }
  }, []);

  return (
    <ContainerComponentHOC {...props} elementTitle={elementTitle} copyNotReq elementName={CRAFT_ELEMENTS.ELEMENT_APPENDER}>
      <div
        className="position-relative element-appender__inner"
        style={{
          ...(currentNode.data.nodes.length !== 0 && {
            minHeight: 'inherit'
          })
        }}
      >
        <RBRow className="container-row container-md">
          <RBCol sm={12}>{children}</RBCol>
        </RBRow>
      </div>
    </ContainerComponentHOC>
  );
};

ElementAppender.craft = {
  name: CRAFT_ELEMENTS.ELEMENT_APPENDER,
  rules: {
    canMoveIn: (selectedNode: any) => {
      return selectedNode[0].data.displayName === CRAFT_ELEMENTS.SINGLE_CHOICE;
    },
    canMoveOut: (selectedNode: any) => {
      return false;
    }
  },
  props: ElementAppenderProps,
  related: {
    settings: ElementAppenderSettings
  }
};

export default ElementAppender;
