import React, { useEffect } from 'react';
import { Button, Form, Input, Space, message } from 'antd';
import { FaChevronLeft } from 'react-icons/fa';
import Text from 'antd/lib/typography/Text';
import { useForm } from 'antd/lib/form/util';
import {
  setCurrentlyEditingTheme,
  setIsFunnelSaved,
  useFunnelTheme
} from '../../../redux/builderSlice';
import { defaultStringFieldValidation } from '../../../../UI/utils/formValidation';
import { colorPickerConfig } from '../../../config/builderSettingsColor';
import ColorPicker from '../../../../Funnel/components/subcomponents/ColorPicker';
import { isAnyColorSameAsBackgroundColor } from '../../../../helper/colorTheme';
import { useDispatch } from 'react-redux';
import { DeleteFilled, StepBackwardOutlined } from '@ant-design/icons';
import { deleteUserFunnelThemeThunk, saveCompleteBuilderDataThunk } from '../../../redux/thunk';
import { checkIsWidgetTheme } from '../../../helper/sharedFunctions';
import { useCurrentlyEditingBuilderTheme } from '../../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';
import { FunnelThemeType } from '../../../interfaces/builderSliceTypes';
import { isWidgetBuilderPath } from '../../../WidgetsBuilder/helper/helper';
import { editIsWidgetSaved } from '../../../WidgetsBuilder/redux/widgetBuilderSlice';
import { saveWidgetBuilderStateThunk } from '../../../WidgetsBuilder/redux/thunk';
import { widgetColorPickerConfig } from '../../../WidgetsBuilder/config/builderSettingsColor';

const EditFunnelTheme = ({ handleToggle }: { handleToggle: () => void }) => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const currentlyEditingTheme = useCurrentlyEditingBuilderTheme() as FunnelThemeType;
  const funnelTheme = useFunnelTheme();
  const colorPicker = isWidgetBuilderPath() ? widgetColorPickerConfig : colorPickerConfig

  const handleSetColor = (colorKey: string, color: string) => {
    if (currentlyEditingTheme) {
      const updatedColorTheme = { ...currentlyEditingTheme, [colorKey]: color };
      const validationResult = isAnyColorSameAsBackgroundColor(updatedColorTheme);
      if (isWidgetBuilderPath()) {
        dispatch(editIsWidgetSaved(false));
      } else {
        dispatch(setIsFunnelSaved(false));
      }

      if (validationResult.error) {
        const erroredColorKey = validationResult.key;
        const configData = colorPickerConfig.find(config => config.colorKey === erroredColorKey);
        message.error(configData?.errorMessage);
      } else {
        dispatch(setCurrentlyEditingTheme(updatedColorTheme as FunnelThemeType));
      }
    }
  };

  useEffect(() => {
    form.setFieldsValue({ name: currentlyEditingTheme.name });
  }, [currentlyEditingTheme.name]);

  const handleCancel = async () => {
    dispatch(setCurrentlyEditingTheme(funnelTheme));
    handleToggle();
  };

  const handleDelete = async () => {
    if (currentlyEditingTheme?.id) {
      dispatch(deleteUserFunnelThemeThunk());
    }
  };

  const handleSubmit = async () => {
    if (isWidgetBuilderPath()) {
      await dispatch(saveWidgetBuilderStateThunk());
    } else {
      await dispatch(saveCompleteBuilderDataThunk());
    }
    handleToggle();
  };

  return (
    <>
      <Space>
        <div className="default-opacity-hover" onClick={form.submit}>
          <button className="icon-btn">
            <FaChevronLeft />
          </button>
          <Text className="brand-color-text__primary"> Speichern</Text>
        </div>
      </Space>

      <div>
        <Form
          className="mt-4"
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
          initialValues={{ name: currentlyEditingTheme.name }}
        >
          {(!funnelTheme.id || funnelTheme.id !== currentlyEditingTheme.id) && (
            <Form.Item label="Titel" rules={defaultStringFieldValidation} name={'name'}>
              <Input
                value={currentlyEditingTheme.name}
                onChange={e => {
                  dispatch(
                    setCurrentlyEditingTheme({ ...currentlyEditingTheme, name: e.target.value })
                  );
                }}
              />
            </Form.Item>
          )}
        </Form>
      </div>

      <section className="color-pickers">
        {colorPicker.map(({ label, description, colorKey }, i) => {
          const isWidgetTheme = checkIsWidgetTheme(colorKey);
          return (
            isWidgetTheme && (
              <ColorPicker
                key={i}
                label={label}
                description={description}
                // @ts-ignore
                color={currentlyEditingTheme[colorKey]}
                onChange={color => handleSetColor(colorKey, color)}
              >
                <>
                  <div
                    className={'color-circle__circle'}
                    style={{
                      background: currentlyEditingTheme[colorKey as keyof FunnelThemeType] as string
                    }}
                  ></div>
                  <span>
                    <b>{label}</b>
                    {description && (
                      <React.Fragment>
                        <br />
                        {description}
                      </React.Fragment>
                    )}
                  </span>
                </>
              </ColorPicker>
            )
          );
        })}
        <Space className="actions">
          <Button type="link" icon={<StepBackwardOutlined />} onClick={handleCancel}>
            Zurücksetzen
          </Button>

          {currentlyEditingTheme?.id && currentlyEditingTheme?.id !== funnelTheme?.id && (
            <Button danger type="link" onClick={handleDelete} icon={<DeleteFilled />}>
              Löschen
            </Button>
          )}
        </Space>
      </section>
    </>
  );
};

export default EditFunnelTheme;
