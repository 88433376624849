import gql from 'graphql-tag';

export enum JobNotificationType {
  URL_UPDATED = 'URL_UPDATED',
  URL_DELETED = 'URL_DELETED'
}

export interface SetFunnelJobPostForIndexing {
  type: JobNotificationType;
  jobPostId: number;
}

export const SET_FUNNEL_JOB_POST_FOR_INDEXING = gql`
  mutation SetFunnelJobPostForIndexing($input: SetFunnelJobPostForIndexing!) {
    setFunnelJobPostForIndexing(input: $input)
  }
`;

export default function setFunnelJobPostForIndexing(input: SetFunnelJobPostForIndexing) {
  return window.apolloClient.mutate({
    mutation: SET_FUNNEL_JOB_POST_FOR_INDEXING,
    variables: { input }
  });
}
