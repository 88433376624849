import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export const GET_PRE_DEFINED_COLOR_THEMES = gql`
  query getColorThemes {
    preDefinedColorThemes {
      id
      textColor
      accentColor
      backgroundColor
      cardBackgroundColor
      cardIconColor
      cardTextColor
      headlineColor
      accentColorContrast
      isCustom
      name
    }
  }
`;

export default function usePreDefinedColorThemes() {
  return useQuery(GET_PRE_DEFINED_COLOR_THEMES);
}
