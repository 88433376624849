import { createAsyncThunk } from '@reduxjs/toolkit';
import subscribeAddOnAPI from '../graphql/subscribeAddOn';
import cancelAddOnAPI from '../graphql/cancelAddOn';
import getAllAddOnsAPI from '../graphql/getAllAddOns';
import { AddOnPayload } from '../config/types';

export const getAllAddOnsThunk = createAsyncThunk('get-all-add-ons', async () => {
  try {
    const response = await getAllAddOnsAPI();

    return { addOns: response.data.getAllAddOns };
  } catch (error) {
    console.error(error);
    throw error;
  }
});

export const subscribeAddOnThunk = createAsyncThunk(
  'subscribe-add-on',
  async (input: { addOnId: number; payload: AddOnPayload[] }) => {
    try {
      const { addOnId, payload } = input;
      const response = await subscribeAddOnAPI(addOnId, payload);
      return { addOnId, permission: response.data.subscribeAddOn };
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const cancelAddOnThunk = createAsyncThunk('cancel-add-on', async (addOnId: number) => {
  try {
    const response = await cancelAddOnAPI(addOnId);
    return { addOnId, permission: response.data.cancelAddOn };
  } catch (error) {
    console.error(error);
    throw error;
  }
});
