import React from 'react';
import { Progress, Table, Card, Tag, Empty } from 'antd';
import { formatPercent } from '../../helper/numberFormat';
import { ChartData } from './PageHitComponent';
import { LeadQualifierInterface } from '../../Events/container/Events';
import { getReadableStepNameByPhd } from '../utils/readableStepNames';
import { AnalyticsMessages } from '../../config/messages';
import { checkIfStepIsCraftPage } from '../utils/setpNameHelpers';

interface ModiefiedData {
  name: string;
  value: number;
  percent: number;
  readableStepName?: string;
}

interface ChoiceCountInterface {
  choiceId: number;
  count: number;
}

interface WelcomePageDataInterfaceSimple {
  id: number;
  serviceName: string;
}

export interface PageHitDataInterface {
  artificial?: boolean;
  stepIndex: number;
  stepName: string;
  leadQualifierId: number;
  hits: number;
  averageTimeInSeconds: number;
  dropOfCount: number;
  choiceArray: ChoiceCountInterface[];
}

interface PageHitComponentProps {
  funnelPages: { id: number; name: string }[];
  pageHitData: PageHitDataInterface[];
  leadQualifierData: LeadQualifierInterface[];
  welcomePageData: WelcomePageDataInterfaceSimple[];
  leads?: number;
}

const getChoiceTitleByChoiceId = (lqd: LeadQualifierInterface, choiceId: number) => {
  const choiceTitle = lqd?.choices.find(({ id }: any) => id === choiceId)?.title || 'Gelöscht';
  return choiceTitle;
};

function PageHitComponentV2({
  leads,
  pageHitData,
  leadQualifierData,
  welcomePageData,
  funnelPages
}: PageHitComponentProps) {
  let mainData: any = [];
  pageHitData?.forEach((phd: PageHitDataInterface, i) => {
    const dropOfCountLabel = `${phd.dropOfCount} (${parseFloat(
      `${(phd.dropOfCount * 100) / phd.hits}`
    ).toFixed(2)}%)`;

    mainData = [
      ...mainData,
      {
        key: i,
        question: getReadableStepNameByPhd({
          phd,
          maxLength: 75,
          funnelPages,
          welcomePageData,
          leadQualifierData
        }),
        hits: phd.hits,
        dropOfCount: phd.dropOfCount,
        expandableData: [],
        dropOfCountLabel
      }
    ];

    if (
      phd.stepName.includes('leadQualifier') ||
      (checkIfStepIsCraftPage(phd.stepName) && phd.leadQualifierId)
    ) {
      const lqd = leadQualifierData.find(
        (lqd: LeadQualifierInterface) =>
          `${lqd.id}` === `${phd.leadQualifierId || phd.stepName.split('_')[1]}`
      );

      if (lqd && lqd?.type != 'radio' && lqd?.type != 'multiple') {
        mainData[i].notExpandable = true;
        mainData[i].expandableData.push({ key: i, title: 'notExpandable' });
        return;
      }

      const totalChoiceHitCount =
        phd.choiceArray?.reduce((acc: number, cc: ChoiceCountInterface) => acc + cc.count, 0) || 0;

      mainData[i].expandableData = phd.choiceArray.map(ca => {
        const countLabel = `${ca.count} (${parseFloat(
          `${(ca.count / totalChoiceHitCount) * 100}`
        ).toFixed(2)}%)`;

        return {
          key: i,
          ...ca,
          countLabel,
          //@ts-ignore
          title: getChoiceTitleByChoiceId(lqd, ca.choiceId)
        };
      });
    } else {
      mainData[i].notExpandable = true;
      mainData[i].expandableData.push({ key: i, title: 'notExpandable' });
    }
  });

  const columns = [
    { title: 'Seite', dataIndex: 'question', key: 'question' },
    {
      title: 'Aufrufe',
      dataIndex: 'hits',
      key: 'hits',
      sorter: {
        //@ts-ignore
        compare: (a, b) => a.hits - b.hits,
        multiple: 2
      }
    },
    {
      title: 'Absprünge',
      dataIndex: 'dropOfCount',
      key: 'dropOfCount',
      //@ts-ignore
      render: (a, { question, dropOfCount, dropOfCountLabel }: any) => {
        if (dropOfCount > 0 && question != 'Dankesseite') return <Tag>-{dropOfCountLabel}</Tag>;
        else return 0;
      },
      sorter: {
        //@ts-ignore
        compare: (a, b) => a.dropOfCount - b.dropOfCount,
        multiple: 2
      }
    }
  ];

  //@ts-ignore
  const expandedRowRender = ({ expandableData }, b, c, d) => {
    const columns = [
      { title: 'Antwort', dataIndex: 'title', key: 'title' },
      {
        title: 'Antwortanzahl',
        dataIndex: 'count',
        key: 'count',
        //@ts-ignore
        render: (a, { count, countLabel }: any) => {
          if (count > 0) return countLabel;
          else return 0;
        }
      }
    ];

    return <Table size="small" columns={columns} dataSource={expandableData} pagination={false} />;
  };

  return (
    <div className="page-hits-mobile">
      <Card title="Besucherverhalten (Tabelle)">
        {mainData.length ? (
          <Table
            size="large"
            className="components-table-demo-nested"
            columns={columns}
            //@ts-ignore
            expandable={{ expandedRowRender, rowExpandable: ({ notExpandable }) => !notExpandable }}
            dataSource={mainData}
            pagination={false}
          />
        ) : (
          <Empty description={AnalyticsMessages.noPageHitData} />
        )}
      </Card>
    </div>
  );
}

export default PageHitComponentV2;
