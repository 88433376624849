import React, { ReactNode, useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { useAppDispatch } from '../../redux/hooks';
import { getAllFunnelsThunk, getAllWidgetsThunk, getFunnelsBookingCountThunk } from '../../Funnel/redux/thunk';
import {
  useAllFunnels,
  useAllFunnelsLoading,
  useFunnelsError
} from '../../Funnel/redux/funnelSlice';
import { Alert } from 'antd';
import { useLocation } from 'react-router';

type Props = {
  children: ReactNode;
};

export const fetchFunnelTimeInLocalStorageKey = 'meetovo-fetch-funnel-time';

export const FetchFunnelsBeforeComponentLoadHOC = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const funnels = useAllFunnels();
  const funnelsError = useFunnelsError();
  const getAllFunnelsLoading = useAllFunnelsLoading();
  const location = useLocation();

  useEffect(() => {
    const lastTimeFunnelsFetched = localStorage.getItem(fetchFunnelTimeInLocalStorageKey);
    const isLastTimeFunnelsFetchedLessThen10Mins =
      lastTimeFunnelsFetched &&
      (new Date().getTime() - new Date(parseInt(lastTimeFunnelsFetched)).getTime()) / 1000 / 60 >
        10;
    if (location.pathname.includes('/builder') && !funnels?.length) {
      dispatch(getAllFunnelsThunk());
    } else if (
      !location.pathname.includes('/builder') &&
      (!funnels?.length || isLastTimeFunnelsFetchedLessThen10Mins)
    ) {
      dispatch(getAllWidgetsThunk());
      dispatch(getAllFunnelsThunk());
    }
  }, [location.pathname]);

  useEffect(() => {
    if (funnels.length) {
      dispatch(getFunnelsBookingCountThunk(funnels.map(({ id }) => id)));
    }
  }, [funnels.length]);

  if (funnelsError) return <Alert type="error" message={funnelsError} banner />;
  return (
    <>
      {getAllFunnelsLoading && (
        <div className="loading-overlay">
          <LoadingOutlined className="centered" spin />
        </div>
      )}
      {[children]}
    </>
  );
};
