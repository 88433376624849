import { Input, Form, Typography } from 'antd';
import React, { useEffect } from 'react';
import BuilderValidator from '../../container/BuilderValidator';
import { semicolonSeperatedEmailsValidator } from '../../../helper/email';

const NewApplicationNotification = ({
  otherEmailAddressesToSend,
  onChange
}: {
  otherEmailAddressesToSend: string;
  onChange(otherEmailAddressesToSend: string): void;
}) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (form.getFieldValue('otherEmailAddressesToSend') !== otherEmailAddressesToSend)
      form.setFieldsValue({
        otherEmailAddressesToSend
      });
  }, [otherEmailAddressesToSend]);

  return (
    <>
      <BuilderValidator form={form}>
        <Form name="general_settings_form" layout="vertical" form={form} scrollToFirstError={true}>
          <Typography.Text strong>Empfänger</Typography.Text>
          <Form.Item
            name="otherEmailAddressesToSend"
            rules={[semicolonSeperatedEmailsValidator]}
            extra="Mehrere E-Mail-Adressen müssen durch Semikolon getrennt werden."
            className="my-2"
          >
            <Input
              placeholder="z.B. kunde@kundenfirma.de"
              onChange={({ target }) => onChange(target.value)}
              value={otherEmailAddressesToSend}
            />
          </Form.Item>
        </Form>
      </BuilderValidator>
    </>
  );
};

export default NewApplicationNotification;
