import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from '@sentry/browser';
import mixpanel from 'mixpanel-browser';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter } from 'react-router-dom';
import Immutable from 'immutable';
import { getEnvironment, isCustomDomain } from './helper/environment';

// https://github.com/facebook/draft-js/issues/1647
// @ts-ignore
Immutable.Iterable.noLengthWarning = true;
const { isDev, mixpanelProjectToken } = getEnvironment();
Sentry.init({
  ignoreErrors: ['ResizeObserver loop limit exceeded', 'ResizeObserver loop completed with undelivered notifications'],
  dsn: 'https://3698ec16cc664db2bbbd95cce5b22cc1@o386661.ingest.sentry.io/5221201',
  enabled: !isDev
});
mixpanel.init(mixpanelProjectToken, { debug: isDev, ignore_dnt: true });

const AgencyDashboardRedirect = () => {
  const customDomain = isCustomDomain() ? window.location.host : '';
  const isAlreadyRedirected = window.location.href.includes('/dashboard/login');

  if (customDomain && !isAlreadyRedirected) window.location.replace('/dashboard/login');

  return null;
};

ReactDOM.render(
  <Provider store={store}>
    <DndProvider backend={HTML5Backend}>
      <AgencyDashboardRedirect />
      <BrowserRouter basename="/dashboard">
        <App />
      </BrowserRouter>
    </DndProvider>
  </Provider>,
  document.getElementById('root')
);
