import React from 'react';
import SettingsPanelBase from '../../../components/sharedUI/SettingsPanelBase';
import DefaultEmptyViewForSettingsPanel from '../../../components/sharedUI/DefaultEmptyViewForSettingsPanel';

export const AdJobSettings = () => {
  return (
    <SettingsPanelBase
      emptyViewComponent={
        <DefaultEmptyViewForSettingsPanel />
      }
    />
  );
};

export default AdJobSettings;
