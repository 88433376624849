import gql from 'graphql-tag';

export const REMOVE_IMAGE_FROM_LIBRARY = gql`
  mutation removeImageFromLibrary($input: Int!) {
    removeImageFromLibrary(id: $input)
  }
`;

export default function removeImageFromLibrary(input: number) {
  return window.apolloClient.mutate({
    mutation: REMOVE_IMAGE_FROM_LIBRARY,
    variables: { input }
  });
}
