import React, { useEffect } from 'react';
import { Alert, Form, Input, Modal } from 'antd';
import { FacebookOutlined, GoogleOutlined } from '@ant-design/icons';
import { useAppDispatch } from '../../../redux/hooks';
import { setPageTrackingThunk } from '../../redux/thunk';
import { BuilderPageDataType } from '../../interfaces/builderSliceTypes';
import { useMainFunnelLoader } from '../../redux/builderSlice';
import { IoLogoLinkedin } from 'react-icons/io';
import { SiTiktok } from 'react-icons/si';

const validateRedirect = ({ getFieldValue }: any) => ({
  validator(rule: any, value: any) {
    if (!value) {
      return Promise.reject('Wenn die Weiterletung aktiviert ist, muss eine URL angegeben werden.');
    }
    return Promise.resolve();
  }
});

interface TrackingProps {
  visible: boolean;
  toggleVisible: (data: boolean) => void;
  pageData: BuilderPageDataType | undefined;
}
const Tracking = ({ visible, toggleVisible, pageData }: TrackingProps) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const loading = useMainFunnelLoader();

  useEffect(() => {
    if (pageData) {
      if (
        form.getFieldValue('facebookPixelEvent') !== pageData.facebookPixelEvent ||
        form.getFieldValue('googleTagTrigger') !== pageData.googleTagTrigger ||
        form.getFieldValue('tikTokPixelEvent') !== pageData.tikTokPixelEvent ||
        form.getFieldValue('linkedInEvent') !== pageData.linkedInEvent
      ) {
        form.setFieldsValue({
          facebookPixelEvent: pageData.facebookPixelEvent,
          googleTagTrigger: pageData.googleTagTrigger,
          tikTokPixelEvent: pageData.tikTokPixelEvent,
          linkedInEvent: pageData.linkedInEvent
        });
      }
    }
  }, [pageData?.facebookPixelEvent, pageData?.googleTagTrigger]);

  const handleOk = (e: any) => {
    if (pageData) {
      dispatch(
        setPageTrackingThunk({
          ...e,
          id: pageData.id
        })
      );
      toggleVisible(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    toggleVisible(false);
  };

  return (
    <Modal
      title="Tracking"
      visible={visible}
      onOk={form.submit}
      okText="Speichern"
      onCancel={handleCancel}
      confirmLoading={loading}
    >
      <Alert
        message={
          <div>
            Tipp: Verbinde deinen Anbieter unter "Connect".{' '}
            <a
              target="_blank"
              href="https://hilfe.meetovo.de/de/article/facebook-pixel-conversions-api-einrichten-1fz3cwb/"
            >
              Video ansehen
            </a>
          </div>
        }
        type="info"
        showIcon
        className="remove-margin-top"
      />
      <div className="py-3 px-4">
        <Form
          form={form}
          name="meta_data_form"
          layout="vertical"
          initialValues={{
            facebookPixelEvent: pageData?.facebookPixelEvent,
            googleTagTrigger: pageData?.googleTagTrigger,
            tikTokPixelEvent: pageData?.tikTokPixelEvent,
            linkedInEvent: pageData?.linkedInEvent
          }}
          onFinish={handleOk}
          scrollToFirstError={true}
        >
          <Form.Item label="Facebook Pixel Event" name={`facebookPixelEvent`}>
            <Input placeholder="z.B. Lead" prefix={<FacebookOutlined />} disabled={false} />
          </Form.Item>
          <Form.Item label="Google Tag Trigger" name={`googleTagTrigger`}>
            <Input placeholder="z.B. Mein Trigger" prefix={<GoogleOutlined />} disabled={false} />
          </Form.Item>
          <Form.Item label="TikTok Pixel Event" name={`tikTokPixelEvent`}>
            <Input placeholder="z.B. Submit Form" prefix={<SiTiktok />} disabled={false} />
          </Form.Item>
          <Form.Item label="LinkedIn Insight-Tag Conversion-ID" name={`linkedInEvent`}>
            <Input placeholder="z.B. 12345678" prefix={<IoLogoLinkedin />} disabled={false} />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
export default Tracking;
