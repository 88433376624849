const jobAdvantagesTextData = {
  title: 'Vorteile',
  data: [
    {
      category: 'Gesundheitswesen',
      content: [
        {
          text: `Wir bieten dir:\n- [Brennendes Bedürfnis: z.B. Finanziell sorglos: Übertarifliche Vergütung. startend bei [GEHALT] . Betriebliche Krankenversicherung. Zusatzleistungen und 13. Gehalt.]\n- [Brennendes Bedürfnis: z.B. Zusammenhalt wie in einer Familie: freundliche, greifbare und strukturierte Vorgesetzte. Wir halten unser Wort!]\n- [Brennendes Bedürfnis: z.B. Wertschätzung, die du verdienst: Du bist unersetzlich, das wissen wir mit regelmäßigen Teamevents zu belohnen!]`,
          title: '3 brennende Bedürfnisse',
          id: 100009
        },
        {
          text: `Mit diesen Vorteilen kannst du rechnen:\n\n[4-6 Benefits, nach der Formel: “Was” -> “Vorteil” -> “Bedeutung”]\n- Übertarifliche Bezahlung, für das dich andere beneiden werden. Startend bei [GEHALT].\n- Wertschätzung, die du verdienst. Deine Bemühungen werden belohnt.\n- Bezahlte Weiterbildung, damit du deine Expertise in 1 Monat so schnell wächst wie bei anderen in 1 Jahr.\n- Beste Absicherung. Für deine gesamte Familie, sodass du nie wieder lange im Wartezimmer sitzen musst.\n- Endlich ein Team, mit du regelmäßig legendäre Teamevents feierst als wäre es dein engster Freundeskreis.\n- Maximale Energie. Flexible Arbeitszeiten, Massagen, Fitness und beste Verpflegung, damit du dein volles Potenzial entfalten kannst.\n\n... und noch viel mehr!`,
          title: 'Vision + Bedeutung',
          id: 100011
        },
        {
          text: `Deine Vorteile:\n\n[Benefits, die Bewerber fordern und nicht üblich sind. 6-10 nacheinander]\n\n- Übertarifliche Verdienstmöglichkeiten, inkl. Prämienmodell & Zusatzleistungen. Startend bei [GEHALT]\n- Flexible Zeiteinteilung\n- Unbefristeter Arbeitsvertrag\n- Kostenlose Massagen\n- Bestens versichert. Gilt für deine gesamte Familie\n- Bezahlte Weiterbildungen: Bücher, Kurse, Veranstaltungen. Alles.\n- Neueste Dienstwagen. Auch zur privaten Nutzung.\n- Arbeiten, wo andere Urlaub machen\n- Spannende und abwechslungsreiche Projekte\n- Legendäre Teamevents. Monatlich veranstaltet\n- … & noch viel mehr!`,
          title: 'Viele Vorteile',
          id: 100012
        }
      ]
    },
    {
      category: 'Handwerk',
      content: [
        {
          text: `Deine Vorteile bei [UNTERNEHMENSNAME]\n\n1. Fitness- und Wellness-Zuschüsse\n2. Flexible Arbeitszeiten und Remote-Optionen\n3. Kinderbetreuungszuschüsse\n4. Firmenwagen oder Zuschuss für den öffentlichen Nahverkehr\n5. Berufliche Entwicklung und Aufstiegsmöglichkeiten\n6. Stabile und langfristige Beschäftigungsperspektive\n7. Ein Gehalt von [GEHALT]`,
          title: 'Auflistung',
          id: 100045
        },
        {
          text: `Damit du dich bei uns wohlfühlst, bieten wir folgende Vorteile:\n\n- Das Wohlbefinden deiner Familie und dir steht bei uns an erster Stelle. Daher bieten wir die besten Versicherungen.\n- Wir funktionieren nur als Team, daher sind unsere monatlichen Teamevents immer ein Highlight.\n- Wir schätzen deinen Einsatz, deshalb kannst du bei uns überdurchschnittlich verdienen, inklusive Prämienmodell & Zusatzleistungen.\n- Flexible Zeiteinteilung. Arbeite, wenn du am produktivsten bist.\n- Wir bieten dir einen unbefristeten Arbeitsvertrag bei einem Gehalt von [GEHALT].\n- Viele weitere Vorteile erwarten dich!`,
          title: 'Umschreibung',
          id: 100046
        },
        {
          text: `Profitiere von unseren zahlreichen Vorteilen:\n\n- Du arbeitest gerne nach deinem eigenen Rhythmus? Unser flexibles Arbeitszeitensystem ist dann genau das Richtige für dich!\n- Hast du Spaß daran, an spannenden Projekten teilzuhaben und dich immer weiter zu entfalten? Wir freuen uns auf Teammitglieder, die gerne Neues lernen.\n- Erhalte ein Gehalt von [GEHALT].\n- Ist dir eine ideale Work-Life-Balance wichtig? Uns auch! Wir achten auf effektives Zeitmanagement und eine gesunde Work-Life-Balance.\n- Arbeitest du gerne in einem kreativen Team?\n- Wenn dir das alles zusagt, dann freuen wir uns auf deine Bewerbung.`,
          title: 'Du perspektive?',
          id: 100047
        },
        {
          text: `Wir freuen uns dir in [BESCHAEFTIGUNGSART] folgende Vorteile anzubieten:\n\n- [Brennendes Bedürfnis: Unterstützung bei der Vereinbarkeit von Beruf und Familie: Kinderbetreuungszuschüsse helfen dir dabei, Familie und Beruf in Einklang zu bringen und deine Kinder gut versorgt zu wissen.]\n\n- [Brennendes Bedürfnis: Sicherheit und Gesundheitsvorsorge: Eine umfassende Krankenversicherung gewährleistet dir und deiner Familie eine finanzielle Absicherung im Falle von Krankheit oder Verletzung.]\n\n- [Brennendes Bedürfnis: Finanzielle Absicherung und langfristige Planung: Altersvorsorge und betriebliche Altersvorsorge helfen dir dabei, für deine Zukunft vorzusorgen und finanzielle Sicherheit im Alter zu gewährleisten. Starte mit [GEHALT] direkt durch]`,
          title: 'Brennendes Bedürfnis',
          id: 100048
        }
      ]
    },
    {
      category: 'Handel & Vertrieb',
      content: [
        {
          text: `Deine Vorteile bei [UNTERNEHMENSNAME]:\n\n[Brennendes Bedürfnis: Kreativität und Innovation: Kreativitätstage ermöglichen es dir, deine kreative Seite zu entfalten und an innovativen Projekten zu arbeiten, die dich inspirieren.]\n\n[Brennendes Bedürfnis: Finanziell abgesichert: Bestens versichert, inkl. deiner gesamten Familie. Starte direkt mit [GEHALT] durch]\n\n[Brennendes Bedürfnis: Eigeninitiative und Ideenentwicklung: Mit einem Innovationsbudget hast du die Möglichkeit, deine eigenen Ideen zu verfolgen und innovative Projekte voranzutreiben.]\n\n[Brennendes Bedürfnis: Unterstützung bei der Vereinbarkeit von Beruf und Familie: Kinderbetreuungszuschüsse helfen dir dabei, Familie und Beruf in Einklang zu bringen und deine Kinder gut versorgt zu wissen.]\n\n[Brennendes Bedürfnis: Umweltbewusstsein und ethische Orientierung: Nachhaltigkeitsinitiativen zeigen unser Engagement für die Umwelt und ermöglichen es dir, einen positiven Beitrag zur Nachhaltigkeit zu leisten.]`,
          title: 'Brennendes Bedürfnis',
          id: 100076
        },
        {
          text: `Unsere Vorteile machen den Unterschied. Hier ist, was du erwarten kannst:\n\n- Moderne Arbeitsausstattung: Wir bieten hochmoderne Arbeitsmittel und -technologien, um effizientes Arbeiten zu ermöglichen und deine Produktivität zu steigern.\n- Mitarbeitervergünstigungen: Profitiere von attraktiven Mitarbeitervergünstigungen für Produkte oder Dienstleistungen unseres Unternehmens und starte direkt mit [GEHALT].\n- Dynamisches Team: Werde Teil eines dynamischen Teams, das zusammenarbeitet, um Großes zu erreichen und dich dabei unterstützt, deine Ziele zu verwirklichen.\n- Firmenevents und Teambuilding: Regelmäßige Firmenevents und Teambuilding-Aktivitäten fördern den Zusammenhalt im Team und sorgen für Spaß und Motivation.\n- Flexibles Arbeitsmodell: Wir unterstützen flexible Arbeitszeiten und Remote-Arbeit, damit du Beruf und persönliche Verpflichtungen optimal vereinbaren kannst.`,
          title: 'Ausführliche Vorteile',
          id: 100077
        },
        {
          text: `Als Vertriebsprofi, erwarten dich bei uns folgende Vorteile:\n\n- Fachliche Weiterentwicklung: Willkommen im Trainings-Camp für Vertriebs-Profis! \n\n- Provisionssystem: Vergiss die üblichen Boni - mit unserem Provisionssystem kannst du ungedeckelt verdienen. Startend mit einem Fixum von [GEHALT].\n\n- Gesundheitsförderung: Unsere Gesundheitsprogramme sind so effektiv, dass du dich fragen wirst, warum du überhaupt jemals krank warst!\n\n- Dynamisches Team: Hier ist Teamwork mehr als nur ein Wort - es ist wie eine Folge von "Friends" mit weniger Drama und mehr Erfolg!\n\n- Flexibles Arbeitsmodell: Vergiss den 9-to-5-Alltag! Mit unseren flexiblen Arbeitszeiten kannst du arbeiten, wann und wo du willst. Du bist aber in [STANDORT] willkommen.`,
          title: 'Humorvoll Vorteile',
          id: 100078
        },
        {
          text: `Wenn folgende Fragen auf dich zutreffen,, dann wirst du dich über diese Vorteile freuen:\n\nFlexibilität ist dir wichtig? Arbeite remote und flexibel, wo immer du willst. Aber du bis auch in [STANDORT] im Office willkommen.\nDir ist ein hohes Fixum wichtig? Starte direkt mit [GEHALT].\nDu möchtest neues lernen? Wir bieten kontinuierlich Weiterbildungsmöglichkeiten für deine Karriere.\nHigh Tech Ausstattung begeistert dich? Wir verwenden nur hochmoderne Technologie für effizientes Arbeiten [ARBEITSPLATZMODELL].\nDu entfaltest dich gerne kreativ? Kreative Ideen sind bei uns willkommen und werden stark gefördert.\nZu Fuß bist du nicht gerne? Bei uns bekommst du ein Firmen E-bike das dich von A nach B bringt.\nUmweltschutz ist bei dir ein Thema? Unsere Nachhaltigkeitsinitiativen werden dich begeistern.`,
          title: 'Du persketive - Fragen',
          id: 100079
        },
        {
          text: `Damit du dich bei uns wohlfühlst, bieten wir folgende Vorteile:\n\n- Das Wohlbefinden deiner Familie und dir steht bei uns an erster Stelle. Daher bieten wir die besten Versicherungen.\n- Wir funktionieren nur als Team, daher sind unsere monatlichen Teamevents immer ein Highlight.\n- Wir schätzen deinen Einsatz, deshalb kannst du bei uns überdurchschnittlich verdienen, inklusive Prämienmodell & Zusatzleistungen. Starte mit [GEHALT].\n- Flexible Zeiteinteilung. Arbeite, wenn du am produktivsten bist. Aber du bist in [STANDORT] willkommen.\n- Wir bieten dir einen unbefristeten Arbeitsvertrag.\n- Viele weitere Vorteile erwarten dich!`,
          title: 'Einfache Vorteile',
          id: 100080
        }
      ]
    },
    {
      category: 'Gastronomie',
      content: [
        {
          text: `Deine Vorteile als Teammitglied bei [UNTERNEHMENSNAME]\n\n- Spitzenköche bereiten Mitarbeiter Mahlzeiten: Tägliche, hochwertige Mahlzeiten, zubereitet von Spitzenköchen, um Ihre Energie und Inspiration zu fördern.\n- Interkulturelles Team: Arbeit in einer vielfältigen, interkulturellen Umgebung, um von verschiedenen Traditionen zu lernen.\n- Kreative Menügestaltung: Freiheit, neue Gerichte zu entwickeln und die Speisekarte mit einzigartigen Ideen zu bereichern.\n- Familienfreundliche Flexibilität: Arbeitsumgebung, die Familienbedürfnisse berücksichtigt und Arbeit und Privatleben in Einklang bringt.\n- Mentorenprogramm mit Branchenführern: Sie erhalten Zugang zu einem exklusiven Mentorenprogramm, das es Ihnen ermöglicht, von den Besten in Ihrer Branche zu lernen und sich weiterzuentwickeln.\n- Finanzielle Absicherung mit einem Startgehalt von [GEHALT] und langfristige Planung: Altersvorsorge für eine sichere Zukunft.\n- Sicherheit und Gesundheitsvorsorge: Umfassende Krankenversicherung für dich und deine Familie.`,
          title: 'Viele Vorteile, lang & umschrieben',
          id: 100107
        },
        {
          text: `Schaue dir deine Vorteile bei [UNTERNEHMENSNAME] an\n\n- Dynamisches Team: Zusammenarbeit für gemeinsamen Erfolg.\n- Firmenevents und Teambuilding: Gemeinsame Aktivitäten für Teamspirit.\n- Moderne Arbeitsausstattung: Hochmoderne Technologie.\n- Mitarbeitervergünstigungen: Exklusive Rabatte.\n- Gesundheitsförderung: Programme für ein gesundes Leben.\n- Flache Hierarchien: Deine Ideen werden gehört und geschätzt.\n- Kreative Freiräume: Entwickle innovative Lösungen.\n- Übertarifliche Verdienstmöglichkeiten, inkl. Prämienmodell & Zusatzleistungen. Startend bei [GEHALT].`,
          title: 'Vorteile, kurze Sätze',
          id: 100108
        },
        {
          text: `Im Bereich [KATEGORIE] erwarten dich bei uns folgende Vorteile:\n\n Kulinarisches Gehirnfutter: Knackige Kochseminare, die dein kulinarisches Game auf die nächste Stufe heben.\n\nChef's Special für dich: Täglich servieren unsere Spitzenköche Essen, das so gut ist, dass du vergisst, dass du eigentlich arbeitest.\n\nWeltumspannendes Koch-Team: Wir haben ein Team, das so vielfältig ist wie unsere Speisekarte. Deine Küchencrew wird zum internationalen Gipfeltreffen!\n\nKreative Freiheit in der Küche: Vergiss "nach Rezept kochen". Hier kannst du kreativ sein und unsere Speisekarte rocken!\n\nFamilien-Balance à la Carte: Weil wir glauben, dass dein Leben mehr ist als nur Arbeit, bieten wir Flexibilität, damit du auch außerhalb der Küche glänzen kannst.\n\nFettes Gehalt, Happy Wallet: Bei uns gibt's nicht nur Geld, sondern genug, um dein Portemonnaie vor Glück platzen zu lassen mit einem Gehalt von [GEHALT].\n\nVersicherungszauber für Helden: Keine Angst vor Missgeschicken – wir haben alles abgesichert, damit Du Dich wie ein Superheld fühlen kannst.`,
          title: 'Vorteile, Lustig und Humor',
          id: 100109
        },
        {
          text: `Damit du dich bei uns wohlfühlst, bieten wir folgende Vorteile:\n\n- Das Wohlbefinden deiner Familie und dir steht bei uns an erster Stelle. Daher bieten wir die besten Versicherungen.\n- Wir funktionieren nur als Team, daher sind unsere monatlichen Teamevents immer ein Highlight.\n- Wir schätzen deinen Einsatz, deshalb kannst du bei uns überdurchschnittlich verdienen, inklusive Prämienmodell & Zusatzleistungen mi einem Starteghalt von [GEHALT].\n- Flexible Zeiteinteilung. Arbeite, wenn du am produktivsten bist.\n- Wir bieten dir einen unbefristeten Arbeitsvertrag.\n- Viele weitere Vorteile erwarten dich!`,
          title: 'Einfache Vorteile',
          id: 100110
        },
        {
          text: `Wenn dich folgende Fragen ansprechen, dann bewerbe dich bei [UNTERNEHMENSNAME]\n\n1. Du möchtest Deine Fähigkeiten verbessern? Wir bieten Schulungen und Seminare für deine berufliche Entwicklung.\n2. Kreative Entfaltung ist dir wichtig? Bei uns kannst du neue Gerichte entwickeln und Ideen für die Speisekarte einbringen.\n3. Du lebst für den Show Effekt? Du hast die Möglichkeit, an Veranstaltungen und Festivals teilzunehmen, um deine Fähigkeiten zu präsentieren.\n4. Neue Kulturen begeistern dich? Arbeite in einem Team mit verschiedenen kulturellen Hintergründen und Erfahrungen.\n5. Du willst gut verdienen, mit einem Startgehalt von [GEHALT].`,
          title: 'Du Perspektive - Fragen',
          id: 100111
        },
        {
          text:
            'Hast du das Zeug zum Koch (m/w/d)? Finde es in nur 53 Sekunden heraus.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!',
          title: '[Quiz] Hast du das Zeug?',
          id: 100240
        },
        {
          text:
            '🚀 Jetzt klicken und finde in nur 53 Sekunden heraus, ob dieser Job zu dir passt.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!',
          title: '[Quiz] Passt dieser Job?',
          id: 100241
        },
        {
          text:
            'Klingt gut?  \r\n\r\nDann finde jetzt in 53 Sekunden heraus, ob du Karriere in der Gastronomie machen kannst.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!',
          title: '[Quiz] Klingt gut?',
          id: 100242
        },
        {
          text:
            '🔝 Hast du auch Lust auf eine Karriere in der Gastronomie?\r\n\r\nKlicke jetzt auf den Button und finde in 53 Sekunden heraus, ob du als [Berufsgruppe] glücklich wirst.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\r\n\r\nWir freuen uns auf dich! ❤️',
          title: '[Quiz] Lust auf Karriere?',
          id: 100243
        },
        {
          text:
            'Ready für den Ausbildungsstart [z.B. 2023]? Schau es dir jetzt unser #Abiprogramm an und finde in 53 Sekunden heraus, ob du Karriere in der [Branche] machen kannst.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!',
          title: '[Quiz] Ausbildungsstart',
          id: 100244
        },
        {
          text:
            'Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\n… dann bewirb dich jetzt bei uns als [Berufsgruppe]. Klicke dafür unten einfach nur auf Button.\r\n\r\nDauert nicht länger als zwei Minuten. Kein Lebenslauf nötig. Versprochen! 🤝\r\n',
          title: '[Story] Kein Lebenslauf nötig',
          id: 100354
        },
        {
          text:
            'Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\nKlingt gut? Klicke jetzt auf "Mehr dazu" und schau, ob wir zueinander passen.\r\nBewirb dich in unter 2 Minuten bei uns - ganz ohne Lebenslauf.\r\n',
          title: '[Story] Passen wir zueinander',
          id: 100355
        },
        {
          text:
            'Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\nInteresse? Deine Bewerbung kannst du ohne Anschreiben und Lebenslauf in unter 2 Minuten zukommen lassen. Jetzt auf den Button klicken.\r\n\r\nLass uns gemeinsam…  [Unternehmensvision in 1-2 Sätzen. Höherer Sinn. Die Bedeutung, die die Arbeit hat]\r\n',
          title: '[Story] Vision',
          id: 100356
        },
        {
          text:
            'Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\nWir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig.\r\n\r\nKlicke jetzt auf den Button und beantworte ein paar kurze Fragen (Dauer: maximal 2 Minuten).\r\n\r\nDer Ablauf:\r\n1. Klicke auf den Button und beantworte die kurzen Fragen\r\n2. Du erhältst Termine für ein Kennenlerngespräch\r\n3. Wenn wir zueinander passen, machen wir Dir ein attraktives Jobangebot\r\n',
          title: '[Story] Nächste Schritte',
          id: 100357
        },
        {
          text:
            'Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\nUnglücklich im jetzigen Job? Wenn du keine Lust mehr hast, jeden Tag bedeutungslose Arbeit zu machen, dann melde dich bei uns. \r\n\r\nWir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig. Jetzt auf den Button klicken und in 2 Minuten bewerben.\r\n',
          title: '[Story] Unglücklich im jetzigen Job',
          id: 100358
        }
      ]
    },
    {
      category: 'Industrie',
      content: [
        {
          text: `Deine Vorteile:\n\n[Benefits, die Bewerber fordern und nicht üblich sind. 6-10 nacheinander].\n\n- Übertarifliche Verdienstmöglichkeiten, inkl. Prämienmodell & Zusatzleistungen. Startend bei [GEHALT].\n- Flexible Zeiteinteilung.\n- Unbefristeter Arbeitsvertrag.\n- Kostenlose Mahlzeiten.\n- Bestens versichert. Gilt für deine gesamte Familie.\n- Bezahlte Weiterbildungen: Bücher, Kurse, Veranstaltungen. Alles.\n- Neueste Dienstwagen. Auch zur privaten Nutzung.\n- Arbeiten, wo andere Urlaub machen.\n- Spannende und abwechslungsreiche Projekte.\n- Legendäre Teamevents. Monatlich veranstaltet.\n- … & noch viel mehr!`,
          title: 'Viele Vorteile',
          id: 100138
        },
        {
          text: `Wir freuen uns dir als [BESCHAEFTIGUNGSART] folgende Vorteile anzubieten:\n\n- [Brennendes Bedürfnis: Flexibilität und Autonomie: Flexible Arbeitszeiten und Remote-Optionen geben dir die Freiheit, deinen Arbeitstag nach deinen eigenen Bedürfnissen zu gestalten und von überall aus zu arbeiten.]\n\n- [Brennendes Bedürfnis: Unterstützung bei der Vereinbarkeit von Beruf und Familie: Kinderbetreuungszuschüsse helfen dir dabei, Familie und Beruf in Einklang zu bringen und deine Kinder gut versorgt zu wissen.]\n\n- [Brennendes Bedürfnis: Sicherheit und Gesundheitsvorsorge: Eine umfassende Krankenversicherung gewährleistet dir und deiner Familie eine finanzielle Absicherung im Falle von Krankheit oder Verletzung.]\n\n- [Brennendes Bedürfnis: Finanzielle Absicherung mit gutem Gehalt von [GEHALT] und langfristige Planung: Altersvorsorge und betriebliche Altersvorsorge helfen dir dabei, für deine Zukunft vorzusorgen und finanzielle Sicherheit im Alter zu gewährleisten.]`,
          title: 'Brennendes Bedürfnis',
          id: 100139
        },
        {
          text: `Folgende Vorteile erwarten dich bei [UNTERNEHMENSNAME]\nAttraktive Vergütungspakete: Bei uns bekommst du wettbewerbsfähige Gehälter mit Startgehalt von [GEHALT] und Zusatzleistungen wie Bonusprogramme, betriebliche Altersvorsorge und Gesundheitsleistungen.\n\nKarriereentwicklungsmöglichkeiten: Bei uns hast du klare Aufstiegsmöglichkeiten, Weiterbildungsprogramme und interne Schulungen, um deine Fähigkeiten zu verbessern und deine Karriereziele zu erreichen.\n\nStabilität und Sicherheit: Bei uns findest du langfristige Beschäftigungsmöglichkeiten, die weniger von Konjunkturzyklen abhängig sind.\n\nTechnologische Innovation: Bei uns arbeitest du mit modernsten Technologien und nimmst an innovativen Projekten teil, die die Industrie vorantreiben.\n\nArbeitsplatzvielfalt und Herausforderung: Bei uns erwarten dich abwechslungsreiche Aufgaben und Projekte, die dich intellektuell herausfordern.\n\nPositive Unternehmenskultur: Bei uns herrscht eine unterstützende und engagierte Arbeitsumgebung, die Teamarbeit, Zusammenarbeit und Wertschätzung der Mitarbeiter fördert.`,
          title: 'Lang Umschrieben',
          id: 100140
        },
        {
          text: `Über folgende Vorteile wirst du dich freuen bei [UNTERNEHMENSNAME] :\n\n\nDu möchtest flexibel arbeiten? Bei uns bieten wir flexible Arbeitszeitmodelle an, die es den Mitarbeitern ermöglichen, Arbeit und Privatleben besser zu vereinbaren.\n\nDu möchtest überdurchschnittlich verdienen? Mit einem Startgehalt von [GEHALT].\n\nDu möchtest von betrieblichen Sozialleistungen profitieren? Bei uns gibt es attraktive Mitarbeitervergünstigungen, Freizeitangebote und Unterstützung bei der Kinderbetreuung.\n\nDu möchtest in einem internationalen Umfeld arbeiten? Bei uns hast Du die Möglichkeit, mit Kollegen aus verschiedenen Ländern und Kulturen zusammenzuarbeiten.\n\nDu möchtest Teil eines Unternehmens sein, das sich für Nachhaltigkeit einsetzt? Bei uns kannst Du an Projekten teilnehmen, die dazu beitragen, die Umweltbelastung zu reduzieren und nachhaltige Lösungen zu entwickeln.`,
          title: 'Du perspektive',
          id: 100141
        }
      ]
    }
  ]
};

export default jobAdvantagesTextData;
