import React from 'react';
import { Button } from 'antd';
import { FaRedoAlt, FaUndoAlt } from 'react-icons/fa';
import useFunnelUndoRedo from '../../helper/useFunnelUndoRedo';
import { trackInteraction } from '../../DebugTracking/utils/helper';
import { TRACKINGS } from '../../DebugTracking/container/DebugTracker';
import useKeyboardShortcut, { Shortcut } from '../../../helper/hooks';

export const shortcutsConfig: Shortcut[] = [
  { ctrlKey: true, key: 'z', handler: 'handleUndo' },
  { metaKey: true, key: 'z', handler: 'handleUndo' },
  { ctrlKey: true, key: 'y', handler: 'handleRedo' },
  { metaKey: true, shiftKey: true, key: 'z', handler: 'handleRedo' }
];

const UndoRedoButtons = () => {
  const { canUndo, canRedo, actions, canUndoAndRedo } = useFunnelUndoRedo();

  const handleUndo = () => {
    if (canUndo) {
      trackInteraction({
        type: 'FUNCTION_CALL',
        customEventName: TRACKINGS.HANDLE_UNDO_IN_UNDO_REDO_BUTTONS
      });

      actions.history.undo();
    }
  };
  const handleRedo = () => {
    if (canRedo) {
      trackInteraction({
        type: 'FUNCTION_CALL',
        customEventName: TRACKINGS.HANDLE_REDO_IN_UNDO_REDO_BUTTONS
      });

      actions.history.redo();
    }
  };

  const handlers: Record<string, () => void> = {
    handleUndo,
    handleRedo,
  };

  useKeyboardShortcut(shortcutsConfig, (handler: string) => {
    if (handlers[handler]) {
      handlers[handler]();
    } else {
      console.warn(`Handler for shortcut "${handler}" not found`);
    }
  });

  return (
    <>
      {canUndoAndRedo && (
        <div className="undo-redo-buttons pe-2">
          <Button
            onClick={handleUndo}
            shape="circle"
            type="link"
            disabled={!canUndo}
            icon={<FaUndoAlt />}
          />
          <Button
            shape="circle"
            type="link"
            disabled={!canRedo}
            icon={<FaRedoAlt />}
            onClick={handleRedo}
          />
        </div>
      )}
    </>
  );
};

export default UndoRedoButtons;
