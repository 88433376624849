import gql from 'graphql-tag';

export interface Input {
  id: number;
  pageOrder: number;
}

export const CHANGE_FUNNEL_PAGE_ORDER = gql`
mutation changeFunnelPageOrder($input: [PageOrderInput]!) {
    changeFunnelPageOrder(input: $input)
  }
`;

export default function changeFunnelPageOrder(input: Input[]) {
  return window.apolloClient.mutate({
    mutation: CHANGE_FUNNEL_PAGE_ORDER,
    variables: { input }
  });
}
