import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import React from 'react';

interface ConditionalTooltipProps {
  disable: boolean;
  children: React.ReactNode;
  title: string;
  placement?: TooltipPlacement;
}

const ConditionalTooltip = (props: ConditionalTooltipProps) => {
  const { children, disable, title } = props;
  return disable ? (
    <>{children}</>
  ) : (
    <Tooltip title={title} placement={props.placement}>
      {children as JSX.Element}
    </Tooltip>
  );
};

export default ConditionalTooltip;
