export const resetStylesDuringTemplateScreenshot = {
    id: 'reset-styles-during-template-screenshot',
    style: `
    div[current-route*=builder] .builder__content .add-button-green circular-plus-button {
        bottom: -9999px;
    }

    div[current-route*=builder] .builder__content .active-actions-controller__wrapper .column-wrapper {
        border: none !important;
    }

    div[current-route*=builder] .builder__content .active-actions-controller__wrapper {
        box-shadow: none !important;
    }
`}

export const resetStylesDuringFunnelScreenshot = {
    id: 'reset-styles-during-template-screenshot',
    style: `
    ${resetStylesDuringTemplateScreenshot.style}

    .action-position-top {
        top: -9999px !important;
    }
`}