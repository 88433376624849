import React from 'react';
import { Row, Col, Tabs, message } from 'antd';
import { cardsWithCategory } from '../config/builderUi';
import { SettingsPanel } from '../../components/sharedUI/SettingsPanel';
import { ElementTemplateMessages } from '../../../config/messages';
import { DraggingFrom } from '../../interfaces/builderSliceTypes';
import { useSelectedElementTab } from '../../hooks/useSelectedElementTab';
import { useSetSelectedElementTab } from '../../reduxCommonMutations/setSelectedElementTab';
import BuilderCardThumb from '../../components/sharedUI/BuilderCardThumb';
import { useSetDraggingElement } from '../../reduxCommonMutations/setDraggingElement';


const { TabPane } = Tabs;

const ElementTemplate = () => {
  const setDraggingElement = useSetDraggingElement();
  const selectedElementTab = useSelectedElementTab();
  const setSelectedElementTabMutation = useSetSelectedElementTab();

  const callback = (key: string) => {
    if (key === '0') return;

    setSelectedElementTabMutation({
      payload: key
    })
  };

  return (
    <Tabs
      className="tab-container-style builder__settings-sidebar__container"
      activeKey={selectedElementTab}
      onChange={callback}
    >

      <TabPane tab="Elemente" className="single-tab-container" key="1">
        {cardsWithCategory.map(({ category, elements }, index) => (
          <div className="element-card__wrapper" key={index}>
            <label className="small-label">{category}</label>
            <Row>
              {elements.map((cardInfo, index) => {
                return (
                  <Col
                    key={index}
                    span={12}
                    onClick={() => message.info(ElementTemplateMessages.youNeedToDrag)}
                    onDragStart={e => {
                        setDraggingElement({
                          name: cardInfo.key || null,
                          draggingFrom: DraggingFrom.ACTION_CONTROLLER,
                          displayName: ''
                        })
                    }}
                    onDragEnd={e => {
                      setDraggingElement({ name: null });
                    }}
                  >
                    <div>
                      <BuilderCardThumb cardInfo={cardInfo} />
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        ))}
      </TabPane>
      <TabPane tab="Einstellungen" className="single-tab-container" key="2">
        <SettingsPanel />
      </TabPane>
    </Tabs>
  );
};

export default ElementTemplate;
