import gql from 'graphql-tag';

interface Input {
  emailSenderName: string;
  brandingEnabled: boolean;
}

export const SET_EMAIL_TEMPLATES_GENERAL_SETTING = gql`
  mutation setEmailTemplatesGeneralSetting($input: EmailTemplatesGeneralSetting!) {
    setEmailTemplatesGeneralSetting(input: $input)
  }
`;

export default function setEmailTemplatesGeneralSetting(input: Input) {
  return window.apolloClient.mutate({
    mutation: SET_EMAIL_TEMPLATES_GENERAL_SETTING,
    variables: { input }
  });
}
