import React from 'react';
import { Button, Form } from 'antd';
import { Store } from 'antd/lib/form/interface';
import TextArea from 'antd/lib/input/TextArea';

const NoteEditor = ({
  onSubmit,
  submitting
}: {
  onSubmit: (e: Store) => void;
  submitting: boolean;
}) => {
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    onSubmit(values);
    form.resetFields();
  };
  return (
    <Form onFinish={onFinish} form={form} initialValues={{ note: '' }}>
      <Form.Item
        name={'note'}
        rules={[
          { required: true, message: 'Bitte geben Sie Ihre Notiz ein.' },
          {
            min: 3,
            max: 500,
            whitespace: true,
            message: 'Ihre Notiz muss mindestens 3 und maximal 500 Zeichen lang sein..'
          }
        ]}
      >
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" loading={submitting} type="primary">
          Notiz hinzufügen
        </Button>
      </Form.Item>
    </Form>
  );
};

export default NoteEditor;
