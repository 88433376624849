import UIMocks from "./UI/mocks/UIMocks"
import { getBookingInfoMock } from "./Events/mocks/eventsMocks";
import { getPlotDataMock } from "./Analytics/mocks/analyticsMock";
import { connectMock } from "./Connect/mocks/connectMocks";
import { getFunnelDataMock } from './Funnel/mocks/funnelMocks'
//
export default [
    UIMocks,
    connectMock,
    getBookingInfoMock,
    getPlotDataMock,
    getFunnelDataMock
]