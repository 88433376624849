import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Alert } from 'antd';
import { AWSKeyVoiceMessagePlayerMessages } from '../../config/messages';
import { LoadingOutlined } from '@ant-design/icons';
import ReactAudioPlayer from 'react-audio-player';

interface AWSKeyVoiceMessagePlayerInterface {
  voiceMessageAWSKey: boolean;
}

export const GET_SIGNED_URL = gql`
  query getBookingInfo($key: String!) {
    getSignedUrlByAWSKey(key: $key) {
      AWSBucketSignedUrl
    }
  }
`;

function AWSKeyVoiceMessagePlayer({ voiceMessageAWSKey }: AWSKeyVoiceMessagePlayerInterface) {
  let { loading, error, data } = useQuery(GET_SIGNED_URL, {
    variables: { key: voiceMessageAWSKey }
  });
  const { AWSBucketSignedUrl } = data?.getSignedUrlByAWSKey || {};

  if (error)
    return (
      <Alert message={AWSKeyVoiceMessagePlayerMessages.couldNotGetUrl} type="error" showIcon />
    );

  if (loading || !AWSBucketSignedUrl) return <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return <ReactAudioPlayer src={AWSBucketSignedUrl} controls />;
}

export default AWSKeyVoiceMessagePlayer;
