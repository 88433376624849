import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';

interface CheckoutState {
  paddleCheckoutData: any;
  isLoading: boolean
}

const INITIAL_STATE: CheckoutState = {
  paddleCheckoutData: {},
  isLoading: false
};

export const checkoutSlice = createSlice({
  name: 'checkout',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: INITIAL_STATE,
  reducers: {
    resetToInitialCheckoutState: () => {
      return INITIAL_STATE;
    },
    setPaddleCheckoutData: (state, action: PayloadAction<{ payload: any }>) => {
      state.paddleCheckoutData = action.payload;
    },
    setPaddleCheckoutLoadingState: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    }
  }
});

export const { resetToInitialCheckoutState, setPaddleCheckoutLoadingState, setPaddleCheckoutData } = checkoutSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectPaddleCheckoutData = (state: RootState) => state.checkout.paddleCheckoutData;
export const selectPaddleCheckoutLoadingState = (state: RootState) => state.checkout.isLoading;

export default checkoutSlice.reducer;
