import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAgencyCustomerSubscriptionInfo,
  getSubscriptionInfo
} from '../../hooks/useSubscriptionInfo';
import { message } from 'antd';
import { GeneralMessages } from '../../config/messages';
import { Role } from '../utils/roleTypes';
import { decodeJWTToken } from '../../helper/decodeJWTToken';

export const getCompleteSubscriptionInfo = createAsyncThunk('get-subscription-info', async () => {
  try {
    const decodedToken = decodeJWTToken();
    let subscriptionInfo;
    if (decodedToken?.role === Role.AGENCY_CUSTOMER) {
      const { data } = await getAgencyCustomerSubscriptionInfo();
      subscriptionInfo = data.getSubscriptionInfo;
    } else {
      const { data } = await getSubscriptionInfo({ disableCache: true });
      subscriptionInfo = data.getSubscriptionInfo;
    }
    return { subscriptionInfo };
  } catch (error) {
    message.error(GeneralMessages.error)
    throw error;
  }
});
