import React from 'react';
import { setShouldSaveFunnel, useIsFunnelSaved } from '../../redux/builderSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import SaveIndicatorBase from './SaveIndicatorBase';
import { useDebounce } from '../../helper/sharedFunctions';
import { saveCompleteBuilderDataThunk } from '../../redux/thunk';

const SaveIndicator = () => {
  const isFunnelSaved = useIsFunnelSaved();
  const debouncedIsFunnelSaved = useDebounce(isFunnelSaved, 1000);
  const role = useAppSelector(state => state.user?.data?.role);
  const dispatch = useAppDispatch();
  const showDownloadTemplate =
    role === 'ADMIN' ||
    window.location.host.includes('localhost') ||
    !!localStorage.getItem('meetovoAllowFunnelDownload');

  const handleSave = () => {
    const tabsAllowedToSaveAll = [
      '/builder/bearbeiten',
      '/builder/design-einstellungen',
      '/builder/grundeinstellungen'
    ];
    const shouldSaveCompleteBuilderState = tabsAllowedToSaveAll.find(path =>
      location.pathname.includes(path)
    );

    if (shouldSaveCompleteBuilderState) {
      dispatch(saveCompleteBuilderDataThunk());
    } else if (location.pathname.includes('emails')) {
      dispatch(saveCompleteBuilderDataThunk({ shouldOnlySaveEmailTemplates: true }));
    }
  };

  return (
    <SaveIndicatorBase
      onClickSave={() => handleSave()}
      isSaved={debouncedIsFunnelSaved}
      showDownloadTemplate={showDownloadTemplate}
      onPageOpenedInAnotherTab={() => dispatch(setShouldSaveFunnel(false))}
    />
  );
};

export default SaveIndicator;
