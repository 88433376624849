import React from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { useNavigate } from 'react-router';

interface ChallengeBreadcrumbPropsInterface {
  moduleName: string
}

const ChallengeBreadcrumb = ({ moduleName }: ChallengeBreadcrumbPropsInterface) => {
  const navigate = useNavigate();

  const navigateToChallenge = () => {
    navigate('/academy')
  }

  return (
    <div className='challenge-breadcrumb'>
      <div className='challenge-breadcrumb__challenge' onClick={navigateToChallenge}>Recruiting Academy</div>
      <FaChevronRight />
      <div className='challenge-breadcrumb__module'>{moduleName}</div>
    </div>
  )
}

export default ChallengeBreadcrumb;