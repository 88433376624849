import React from 'react';
import { Button, Col, Form, Row } from 'antd';
import AdminUserSelector from '../../../CustomerManagement/components/AdminUserSelector';
import { getAuthData } from '../../container/Login';

function LoginSelector({ setAuthDataByAdmin }: { setAuthDataByAdmin: Function }) {
  const [form] = Form.useForm();
  return (
    <Form
      form={form}
      onFinish={({ userId }) => {
        setAuthDataByAdmin(userId);
      }}
      initialValues={{ userId: undefined }}
    >
      <Row>
        <Col sm={20}>
          <Form.Item
            name={'userId'}
            rules={[{ required: true, message: 'Please Select User to login!' }]}
          >
            <AdminUserSelector />
          </Form.Item>
        </Col>
        <Col sm={4}>
          <Button htmlType="submit" type="primary">
            Auswählen
          </Button>
        </Col>
      </Row>
      <Button
        onClick={() => {
          const token = getAuthData('token');
          const payload = JSON.parse(atob(token.split('.')[1]));
          setAuthDataByAdmin(payload.coachId);
        }}
        type="link"
      >
        Proceed to Admin Account
      </Button>
    </Form>
  );
}

export default LoginSelector;
