import React from 'react';
import { Spinner } from 'react-bootstrap';

export const ImageLoadingSpinner = (): React.ReactElement => {
  return (
    <div className="spinner__wrapper">
      <Spinner animation="border" role="status" size='sm'>
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};
