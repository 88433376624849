export default {
  name: [
    { required: true, message: 'Bitte gib deinen Namen an.' },
    {
      min: 3,
      max: 60,
      whitespace: true,
      message: 'Dein Name muss min. 3 und max. 8 Zeichen lang sein..'
    }
  ],
  email: [
    {
      pattern: new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ),
      required: true,
      message: 'Bitte gib eine valide E-Mail-Adresse ein und verwende keine Leertasten.'
    }
  ],
  password: [
    { required: true, message: 'Bitte gib ein Passwort ein.' },
    { min: 5, message: 'Dein Passwort muss mindestens 5 Zeichen lang sein.' }
  ],
  pin: [{ min: 4, required: true, message: 'Bitte geben Sie Ihre 4-stellige PIN ein.' }]
};
