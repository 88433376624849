import { Col, Form, Input, Row } from 'antd';
import React from 'react';
import BuilderSettingsTitle from '../../sharedUI/BuilderSettingsTitle';
import { defaultStringFieldValidation } from '../../../../UI/utils/formValidation';
import { urlFormValidator } from '../../../../helper/url';
import ImageUploadV3 from '../../../../GeneralComponents/ImageUploadV3';
import { commonAspectRatios } from '../../../../GeneralComponents/helper/cropImage';
import { useNode } from '@craftjs/core';

const CustomBranding = () => {
  const [linkForm] = Form.useForm();
  const [lebalForm] = Form.useForm();
  const {
    actions: { setProp },
    props
  } = useNode(node => ({
    props: node.data.props
  }));
  const { link, label, src } = props.customBranding;
  return (
    <>
      <Row className="builder__settings-sidebar__row px-4 m-0">
        <Col span={24}>
          <BuilderSettingsTitle title="Link" />
          <Form
            form={linkForm}
            onFinish={values => {
              setProp((props: any) => (props.customBranding.link = values.link));
            }}
            autoComplete="off"
          >
            <Form.Item
              name="link"
              rules={[
                {
                  required: true,
                  message: 'URL ist erforderlich'
                },
                urlFormValidator
              ]}
            >
              <Input
                defaultValue={link}
                className="mt-2"
                placeholder="https://deine-seite.de"
                onChange={linkForm.submit}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row className="builder__settings-sidebar__row px-4 m-0">
        <Col span={24}>
          <BuilderSettingsTitle title="Label" />
          <Form
            form={lebalForm}
            onFinish={values => {
              setProp((props: any) => (props.customBranding.label = values.label));
            }}
            autoComplete="off"
          >
            <Form.Item name="label" rules={[...defaultStringFieldValidation]}>
              <Input
                defaultValue={label}
                className="mt-2"
                placeholder="Label"
                onChange={lebalForm.submit}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row className="builder__settings-sidebar__row px-4 m-0">
        <Col span={24}>
          <BuilderSettingsTitle title="Logo" />
          <ImageUploadV3
            previewImage={src}
            onChange={(url: string, shape: any) => {
              setProp((props: any) => {
                props.customBranding.src = url;
              });
            }}
            minWidth={20}
            minHeight={20}
            maxHeight={90}
            maxWidth={1365}
            minZoom={0}
            uploadedImage
            grid
            restrictPosition={false}
            existingImageURL={''}
            circleEnabled={false}
            possibleAspectRatios={commonAspectRatios}
          />
        </Col>
      </Row>
    </>
  );
};

export default CustomBranding;
