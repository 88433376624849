import React from 'react';
import SettingsPanelBase from '../../../components/sharedUI/SettingsPanelBase';
import DefaultEmptyViewForSettingsPanel from '../../../components/sharedUI/DefaultEmptyViewForSettingsPanel';

export const SettingsPanel = () => {
  return (
    <SettingsPanelBase
      emptyViewComponent={
        <DefaultEmptyViewForSettingsPanel
          title="Kein Element gewählt"
          description="Klicke rechts auf das Plus, um einen Job hinzuzufügen oder klicke auf einen Job, um hier weitere Informationen zu sehen."
        />
      }
    />
  );
};
