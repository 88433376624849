// @ts-nocheck
import React, { useState } from 'react';
import { useOldLeadQualifier } from '../../redux/builderSlice';
import { groupArrayByKey } from '../../../helper/array';
import { Button, Modal } from 'antd';

const SelectValidLeadQualifier = () => {
  const [open, setOpen] = useState(true);
  const [ids, setIds] = useState([]);
  const [exclude, setExclude] = useState([]);
  const { leadQualifier } = useOldLeadQualifier();
  const groupedLq = groupArrayByKey(leadQualifier, 'craftJsNodeId');
  return (
    <Modal visible={open} onCancel={() => setOpen(false)} width="1000px">
      <div>
        <label>Auswahl:</label>
        {ids.join(',')}

        {Object.keys(groupedLq).map(key => {
          if (exclude.includes(key)) return null;
          return (
            <div style={{ background: 'grey', padding: '20px 10px', marginBottom: 10 }}>
              <Button
                danger
                onClick={() => {
                  setExclude([...exclude, key]);
                }}
              >
                Löschen
              </Button>
              <h2>{key}</h2>
              {groupedLq[key].map(({ id, question, choices }) => (
                <div style={{ background: 'white', padding: '10px' }}>
                  <h3>{question}</h3>
                  <ul style={{ marginLeft: '20px' }}>
                    {choices.map(({ title }) => (
                      <li>{title}</li>
                    ))}
                  </ul>
                  <Button
                    onClick={() => {
                      setIds([...ids, id]);
                      setExclude([...exclude, key]);
                    }}
                  >
                    Auswählen
                  </Button>
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

SelectValidLeadQualifier.propTypes = {};

export default SelectValidLeadQualifier;
