import React from 'react';
import { Row, Statistic, Col, Card, Popover } from 'antd';
import {
  ClockCircleOutlined,
  FlagOutlined,
  TeamOutlined,
  UserAddOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import { AnalyticsInfoTexts } from '../utils/infoTexts';
import { useLocation, useNavigate } from 'react-router';
import { EVENT_VIEWS } from '../../Events/utils/eventViewsTypes';

export interface SummaryDataInterface {
  pageView: number;
  leads: number;
  conversionRate: number;
  averageTimeInSeconds: number;
}

interface SummaryComponentProps {
  summaryData: SummaryDataInterface;
  customCount?: number;
  customConversionRate?: string;
}

interface StatisticElementProps {
  title: string;
  text: string;
  icon: JSX.Element;
  value: number | string;
  suffix?: string;
  isContactLink?: boolean;
}

export function StatisticElement({
  title,
  text,
  icon,
  value,
  suffix,
  isContactLink
}: StatisticElementProps) {
  const location = useLocation() as any;
  const navigate = useNavigate();

  return (
    <Statistic
      title={
        <React.Fragment>
          {isContactLink ? (
            <a
              className="link"
              onClick={() =>
                navigate('/kontakte', {
                  state: {
                    initialView: EVENT_VIEWS.LIST,
                    start: location?.state?.start,
                    end: location?.state?.end,
                    funnelIds: location?.state?.funnelIds
                  }
                })
              }
            >
              {title}
            </a>
          ) : (
            title
          )}
          <Popover
            className="default-popover"
            content={
              <div className="default-popover__content" style={{ maxWidth: 400 }}>
                {text}
              </div>
            }
            trigger="hover"
          >
            <InfoCircleOutlined />
          </Popover>
        </React.Fragment>
      }
      suffix={suffix || false}
      value={value}
      prefix={icon}
    />
  );
};

export function SummaryComponent({
  customCount,
  summaryData,
  customConversionRate
}: SummaryComponentProps) {
  summaryData = summaryData || {
    averageTimeInSeconds: 0,
    pageView: 0,
    leads: 0,
    conversionRate: 0
  };

  let averageTimeString: string;
  if (summaryData.averageTimeInSeconds < 60) {
    averageTimeString = `${summaryData.averageTimeInSeconds}s`;
  } else {
    let minutes = Math.floor(summaryData.averageTimeInSeconds / 60);
    averageTimeString = `${minutes}min ${summaryData.averageTimeInSeconds % 60}s`;
  }

  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={6}>
        <Card>
          <StatisticElement
            title="Klicks"
            text={AnalyticsInfoTexts.besuche}
            icon={<TeamOutlined />}
            value={summaryData.pageView}
          />
        </Card>
      </Col>
      <Col xs={24} sm={24} md={6}>
        <Card>
          <StatisticElement
            title="Bewerbungen"
            isContactLink={true}
            text={AnalyticsInfoTexts.leads}
            icon={<UserAddOutlined />}
            value={customCount || summaryData.leads}
          />
        </Card>
      </Col>
      <Col xs={24} sm={24} md={6}>
        <Card>
          <StatisticElement
            title="Konvertierungsrate"
            text={AnalyticsInfoTexts.konvertierungsrate}
            icon={<FlagOutlined />}
            suffix="/ 100"
            value={
              customConversionRate || parseFloat(`${summaryData.conversionRate * 100}`).toFixed(2)
            }
          />
        </Card>
      </Col>
      <Col xs={24} sm={24} md={6}>
        <Card>
          <StatisticElement
            title="Durchschnittliche Zeit"
            text={AnalyticsInfoTexts.durschnittlicheZeit}
            icon={<ClockCircleOutlined />}
            value={averageTimeString}
          />
        </Card>
      </Col>
    </Row>
  );
}
