import React, { useEffect } from 'react';
import { Select, Tag } from 'antd';

export default function FunnelSelector({
  defaultValue,
  mode,
  allSelectdable = false,
  onChange,
  initialData,
  idsToExclude,
  disableMoreSelectable,
  placeholder,
  value,
  customFunnels,
  disabled,
  showActiveFunelsOnly
}: {
  defaultValue: number[];
  customFunnels?: { title: string; id: number }[];
  mode?: string;
  allSelectdable: boolean;
  initialData?: any[];
  onChange(value: number | number[]): any;
  idsToExclude?: number[];
  disableMoreSelectable?: boolean;
  placeholder?: string;
  value?: number[] | number;
  disabled?: boolean;
  showActiveFunelsOnly?: boolean;
}) {
  let data = initialData;

  if (showActiveFunelsOnly) {
    data = initialData?.filter(e => e.enabled || e.id == value);
  }

  const allOptionsDisabaled = Array.isArray(defaultValue) && defaultValue?.some(id => id === 0);
  data = allSelectdable && data ? [{ title: 'Alle', id: 0, value: 0 }, ...data] : data || [];

  if (customFunnels) data = [...data, ...customFunnels];

  const disabledFunnels: number[] = [];
  const enabledFunnels: number[] = [];
  initialData?.forEach(item => {
    if (defaultValue.includes(item.id) && !item.enabled && item.id !== 0) {
      disabledFunnels.push(item.id);
    } else {
      enabledFunnels.push(item.id);
    }
  });

  if (Array.isArray(defaultValue) && showActiveFunelsOnly) {
    defaultValue = defaultValue.filter(item => !disabledFunnels.includes(item));
  }

  if (Array.isArray(value) && showActiveFunelsOnly) {
    value = value.filter(item => !disabledFunnels.includes(item));
  }

  return (
    <Select
    {...(process.env.NODE_ENV === 'test' && { open: true })}
    data-testid="status-selection"
      showSearch
      defaultValue={defaultValue}
      value={value}
      mode={mode === 'multiple' ? 'multiple' : undefined}
      style={{ width: '100%' }}
      placeholder={placeholder || 'Funnel auswählen...'}
      onChange={(value: any) => {
        if (mode === 'multiple' && showActiveFunelsOnly) {
          value = [...value, ...disabledFunnels];
        }
        onChange(value);
      }}
      optionFilterProp={'label'}
      disabled={disabled}
    >
      {data.map(({ title, id }: any) => {
        const isInIdsToExclude = idsToExclude?.some(ite => ite === id);
        const shouldBeExcluded =
          isInIdsToExclude && Array.isArray(defaultValue)
            ? !defaultValue.some(dv => dv === id)
            : false;

        return !shouldBeExcluded && (!disableMoreSelectable || defaultValue?.includes(id)) ? (
          <Select.Option
            key={id}
            value={id}
            label={title}
            disabled={
              (allOptionsDisabaled && id > 0) ||
              (defaultValue.length > 0 && id === 0 && defaultValue[0] != 0)
            }
          >
            {title}
          </Select.Option>
        ) : null;
      })}
    </Select>
  );
}
