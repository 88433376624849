import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { useSelectedElementTab } from '../../hooks/useSelectedElementTab';
import useGeneralSettingsDataSave from '../../hooks/useGeneralSettingsDataSave';
import { addNewTemplateHandler } from '../../helper/sharedFunctions';

export const AddContainerComponentV2 = () => {
  const selectedElementTab = useSelectedElementTab();
  const { handleGeneralSettingsDataSave } = useGeneralSettingsDataSave();

  return (
    <div className="d-flex justify-content-center align-items-center mt-1 py-4">
      <div
        className="add-button-green circular-plus-button position-static container__add-wrapper-icon"
        onClick={e => addNewTemplateHandler(e, selectedElementTab, handleGeneralSettingsDataSave)}
      >
        <FaPlus className="container-plus-icon" />
      </div>
    </div>
  );
};
