import Fingerprint from '@fingerprintjs/fingerprintjs';
import { getCookie, setCookie } from '../../../helper/cookies';
import { trackInteractionMutation, endSessionMutation, startSessionMutation } from './graphql';
import lz from 'lzutf8';
import { getAuthData } from '../../../Login/container/Login';
import { getObjectId } from '../../../helper/id';
import { TRACKINGS } from '../container/DebugTracker';
import { AGENCY_OS_BUILDER_PATH_NAME } from '../../../UI/paths';

declare global {
    interface Window {
        __CRAFT_STATE__?: string;
    }
}

interface GenerateSessionHash { sessionId: string, fingerprint: string }

export async function generateSessionHash(): Promise<GenerateSessionHash> {
    return Fingerprint.load()
        .then(fp => fp.get())
        .then(result => result.visitorId)
        .catch(error => {
            console.error('Error getting fingerprint:', error);
            return null;
        })
        .then((visitorId: string | null): GenerateSessionHash => {
            let fingerprint = setCookie('meetovo_builder_fingerprint') || '';
            let sessionId = getObjectId();

            if (!fingerprint) fingerprint = visitorId || '';

            setCookie('meetovo_builder_session_id', sessionId);
            setCookie('meetovo_builder_fingerprint', fingerprint);

            return { sessionId, fingerprint };
        });
}

export const getPageInformation = () => {
    return {
        selectedPageId: window.selectedPageId || 0,
        pathname: window?.location?.pathname || ""
    }
}

export function getCraftState() {
    const state = window.__CRAFT_STATE__;
    if (!state) return "";

    const encodedState = lz.encodeBase64(lz.compress(JSON.stringify(state)));

    return encodedState;
}

export function endSession() {
    try {
        if (!window.debugTrackingEnabled) return;

        const sessionId = getCookie('meetovo_builder_session_id');
        const { pathname, selectedPageId } = getPageInformation();

        setCookie("meetovo_builder_session_id")
        setCookie("meetovo_builder_previous_session_id", sessionId)

        endSessionMutation({ pathname, selectedPageId, sessionId, endedAt: new Date().toISOString() });
    } catch (error) {
        console.error("endSession", error)
    }
}

export async function startSession() {
    try {
        if (!window.debugTrackingEnabled) return;

        const currentSessionId = getCookie('meetovo_builder_session_id');
        if (currentSessionId) endSession();

        const { sessionId, fingerprint } = await generateSessionHash();
        const { pathname, selectedPageId } = getPageInformation();
        const previousSessionId = getCookie('meetovo_builder_previous_session_id');
        const authToken = getAuthData("token");

        const device = {
            userAgent: window.navigator.userAgent,
            language: window.navigator.language,
            screenWidth: window.screen.width,
            screenHeight: window.screen.height,
            colorDepth: window.screen.colorDepth,
            timezoneOffset: new Date().getTimezoneOffset(),
            cookieEnabled: window.navigator.cookieEnabled,
            platform: (window.navigator as any).userAgentData?.platform || window.navigator.platform || "",
            vendor: window.navigator.vendor || "",
            brand: (window.navigator as any).userAgentData?.brands?.[0]?.brand || "",
            model: (window.navigator as any).userAgentData?.brands?.[0]?.model || "",
        };

        startSessionMutation({
            pathname,
            selectedPageId,
            authToken,
            previousSessionId,
            sessionId,
            fingerprint,
            device,
            startedAt: new Date().toISOString()
        });
    } catch (error) {
        console.error("startSession", error)
    }
}

export function autoTrackInteraction(event: MouseEvent, type: TRACKINGS) {
    try {
        if (!window.debugTrackingEnabled || !window.debugTrackingAutoTrackEnabled) return;

        if (!event?.target) return;

        const path = event?.composedPath();

        let element = event?.target as HTMLElement

        if (path?.length) {
            for (let i = 0; i < path.length; i++) {
                element = path[i] as HTMLElement;
                if (element.classList.length > 0) {
                    break;
                }
            }
        }


        let className = (element?.tagName || "").toLocaleLowerCase() === 'svg' ? element?.getAttribute('class') : element?.className;
        className = typeof className !== 'string' ? '' : className;

        const elementId = element?.id;
        const textContent = element?.textContent;
        const additionalData = {
            textContent
        }

        trackInteraction({
            type, className, elementId, additionalData
        })
    } catch (error) {
        console.error("autoTrackInteraction", error)
    }
}

interface TrackInteraction {
    type: string,
    className?: string | null,
    elementId?: string,
    customEventName?: string,
    additionalData?: object
    onSuccess?(): void
}

export function trackInteraction(params: TrackInteraction) {
    try {
        if (location.pathname.includes(AGENCY_OS_BUILDER_PATH_NAME.root)) return;
        if (!window.debugTrackingEnabled) return;

        const { type, className, elementId, customEventName, additionalData } = params
        const { pathname, selectedPageId } = getPageInformation();

        const sessionId = getCookie('meetovo_builder_session_id');

        trackInteractionMutation({
            pathname,
            selectedPageId,
            type,
            sessionId,
            className,
            elementId,
            customEventName,
            craftState: getCraftState(),
            additionalData,
            firedAt: new Date().toISOString()
        }).then(() => {
            if (params.onSuccess) params.onSuccess()
        })
    } catch (error) {
        console.error("trackInteraction", error)
    }
}