import { SingleRouteType } from './routeTypes';

export function getAllRoutes(routes: SingleRouteType[]) {
  let routePath: SingleRouteType[] = [];
  routes.forEach(({ subRoutes, ...rest }) => {
    if (subRoutes) {
      const subRoutesData = getAllRoutes(subRoutes);
      routePath = [...routePath, ...subRoutesData];
    }
    routePath.push({ ...rest });
  });
  return routePath;
}

export function getARoute(
  routes: SingleRouteType[],
  pathname: string
): SingleRouteType | undefined {
  return routes.find(single => {
    if (single.path === pathname) return true;

    if (single.subRoutes) {
      return getARoute(single.subRoutes, pathname);
    }

    return single.path === pathname;
  });
}
