import moment, { Moment } from 'moment'

const getDaysBetweenDates = (startDate: Moment, endDate: Moment, language: string) => {
    var now = startDate.clone(), dates = [];

    while (now.isSameOrBefore(endDate)) {
        dates.push(now.clone().locale(language.toLowerCase()));
        now.add(1, 'days');
    }
    return dates;
};

export const getAllDaysOfWeekFormatted = (language: string) => {
    const startOfWeek = moment().startOf("week")
    const endOfWeek = moment().endOf("week")

    const days = getDaysBetweenDates(startOfWeek, endOfWeek, language)

    return days.map((thisMoment: Moment) => {
        return {
            isToday: thisMoment.startOf("day").isSame(moment().startOf("day")),
            dayOfMonth: thisMoment.format('ddd'),
            dayOfWeek: thisMoment.format('DD'),
            month: thisMoment.format('MMM'),
        }
    })
};