import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Form, Input, List, Tabs } from 'antd';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import { ImageType, TagType } from '../utils/LibraryModels';
import SelectableItem from './SelectableItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import DefaultSmallLoader from '../../SharedUI/components/DefaultSmallLoader';
import { useAppDispatch } from '../../redux/hooks';
import { getAllImagesTagsThunk, getImagesWithPaginationThunk } from '../redux/libraryThunk';
import {
  setImageSearchValue,
  useAllLibraryImages,
  useAllLibraryImagesLoading,
  useAllTags,
  useImageSearchValue
} from '../redux/librarySlice';
import { debounce } from 'debounce';
import DefaultEmpty from '../../SharedUI/components/DefaultEmpty';
import { FileImageOutlined } from '@ant-design/icons';

const { Search } = Input;

function LibraryImagePicker({ onSelectImage }: { onSelectImage?: (image: ImageType) => void }) {
  const searchQuery = useImageSearchValue();
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const { images, total, page } = useAllLibraryImages();
  const allLibraryImagesLoading = useAllLibraryImagesLoading();

  const availableTags = useAllTags();

  const handleSearch = (e: any) => {
    dispatch(getImagesWithPaginationThunk({ searchQuery: e }));
  };

  const fetchMore = () => {
    dispatch(
      getImagesWithPaginationThunk({
        page: page + 1,
        limit: 30,
        searchQuery: form.getFieldValue('searchValue')
      })
    );
  };

  useEffect(() => {
    fetchMore();
    dispatch(getAllImagesTagsThunk());
  }, []);

  const debouncedSearch = useRef(
    debounce((value: string) => {
      handleSearch(value);
    }, 1000)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.clear();
    };
  }, []);

  const handleSearchQuery = (value: string) => {
    debouncedSearch(value);
  };

  useEffect(() => {
    form.setFieldsValue({ searchValue: searchQuery });
  }, [searchQuery]);

  return (
    <>
      <Form form={form} name="basic" onFinish={d => handleSearch(d?.searchValue)}>
        <Row>
          <Col span={24}>
            <Form.Item name="searchValue" className="mb-0">
              <Search
                allowClear
                onSearch={value => handleSearch(value)}
                onChange={e => {
                  const value = e.target.value;
                  dispatch(setImageSearchValue(value));
                  handleSearchQuery(value);
                }}
                placeholder="Archiv durchsuchen..."
              />
            </Form.Item>
          </Col>
        </Row>

        <Row className="tags-section">
          <div className="mb-2 builder__settings__image-select-modal__template-tags">
            {!!availableTags.length &&
              availableTags.map((tag: TagType) => (
                <CheckableTag
                  key={tag.id}
                  checked={searchQuery === tag.name}
                  onChange={() => {
                    dispatch(setImageSearchValue(tag.name));
                    handleSearch(tag.name);
                  }}
                >
                  {tag.name}
                </CheckableTag>
              ))}
          </div>
        </Row>

        {allLibraryImagesLoading && searchQuery && (
          <div className="d-flex justify-content-center m-2">
            <DefaultSmallLoader loading />
          </div>
        )}
        <InfiniteScroll
          next={fetchMore}
          hasMore={images?.length < total}
          loader={
            <div className="d-flex justify-content-center">
              <DefaultSmallLoader loading={allLibraryImagesLoading} />
            </div>
          }
          dataLength={images?.length}
          height={'50vh'}
        >
          <List
            locale={{
              emptyText: (
                <DefaultEmpty
                  icon={<FileImageOutlined />}
                  title="Keine Bilder gefunden"
                  description="Hier findest du deine Bilder nachdem du sie im Archiv gespeichert hast."
                />
              )
            }}
            grid={{ gutter: 16, column: 3 }}
            dataSource={images}
            renderItem={(image: ImageType) => {
              return (
                <List.Item>
                  <SelectableItem
                    key={image?.id}
                    tags={image.tags}
                    previewImageURL={image.imageLink}
                    deletable={true}
                    currentImage={image}
                    searchQuery={searchQuery}
                    onSelectImage={onSelectImage}
                  />
                </List.Item>
              );
            }}
          />
        </InfiniteScroll>
      </Form>
    </>
  );
}

export default LibraryImagePicker;
