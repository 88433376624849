import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, Select } from 'antd';
import DefaultLoader from '../../SharedUI/components/DefaultLoader';
import { ImageType } from '../utils/LibraryModels';
import { useAppDispatch } from '../../redux/hooks';
import { useAllTags } from '../redux/librarySlice';
import { getAllImagesTagsThunk, updateImageFromGalleryThunk } from '../redux/libraryThunk';

const defaultStringFieldValidation = [
  { required: true, message: 'Bitte gib einen Wert an.' },
  {
    min: 3,
    max: 60,
    whitespace: true,
    message: 'Der Wert muss min. 3 und max. 60 Zeichen lang sein.'
  }
];

const UpdateImageDetailsModal = ({
  visible = false,
  toggleVisible = (visible: boolean) => {},
  currentImage,
  tags
}: {
  visible: boolean;
  toggleVisible: (visible: boolean) => void;
  currentImage: ImageType;
  tags: string[];
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const allTags = useAllTags();

  useEffect(() => {
    if (currentImage)
      form.setFieldsValue({
        title: currentImage.name,
        tags: currentImage.tags
      });
  }, [currentImage]);

  const onSuccess = () => {
    toggleVisible(false);
  };
  const handleOk = async (e: any) => {
    setLoading(true);
    dispatch(
      updateImageFromGalleryThunk({
        id: currentImage.id,
        name: e?.title,
        tags: e?.tags
      })
    )
      .then(() => {
        setLoading(false);
        dispatch(getAllImagesTagsThunk());
        onSuccess();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    toggleVisible(false);
  };

  return (
    <>
      <DefaultLoader loading={false} />
      <Modal
        title="Als Vorlage speichern"
        okText="Speichern"
        className="funnel-block-template-wrapper"
        visible={visible}
        onOk={form.submit}
        onCancel={handleCancel}
        confirmLoading={loading}
        width={600}
      >
        <Form
          form={form}
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={handleOk}
          autoComplete="off"
        >
          <Form.Item
            label="Titel"
            name="title"
            className="mb-1"
            rules={defaultStringFieldValidation}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Tags" name="tags" className="mb-1">
            <Select
              mode="tags"
              placeholder="z.B. Vorteile, Kunde XYZ ..."
              options={allTags.map((tag: any) => ({ label: tag.name, value: tag.name }))}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default UpdateImageDetailsModal;
