import React, { useState } from 'react';
import { CloudUploadOutlined } from '@ant-design/icons';
import ImageUpload from '../../GeneralComponents/ImageUpload';
import { Avatar } from 'antd';

interface CircularImageUpload {
  imageURL?: string;
  onChange(imageURL: string): any;
  size?: number;
  headline: string;
  placeholderImage?: string | Promise<string>;
  avatarText?: string;
}

export default function CircularImageUpload({
  imageURL,
  onChange,
  size = 120,
  headline,
  placeholderImage,
  avatarText
}: CircularImageUpload) {
  const [thisPlaceholderImage, setPlaceholderImage] = useState(
    typeof placeholderImage === 'string' && imageURL ? placeholderImage : ''
  );

  if (placeholderImage instanceof Promise && !imageURL)
    placeholderImage.then(url => {
      if (!url) return;
      setPlaceholderImage(url);
      onChange(url);
    });

  return (
    <div className="circular-image-upload">
      <ImageUpload
        cropShape="round"
        zoomEnabled
        value={[
          {
            id: 'imageURL',
            src: imageURL || '',
            height: size,
            width: size,
            minWidth: size
          }
        ]}
        saveImageAndReturnUrl
        modalTitle="Bild hochladen"
        headline={headline}
        bigPreview
        onSubmit={(value: any) => {
          onChange(value[0].src);
        }}
        trigger={
          <div className="icon-overlay-on-hover circular-wrapper circular-wrapper-80">
            <CloudUploadOutlined className="icon-overlay-on-hover__icon" />
            {imageURL || thisPlaceholderImage ? (
              <img
                className="circular-wrapper__preview-image"
                src={imageURL || thisPlaceholderImage}
              />
            ) : (
              <Avatar shape={'circle'} size={80}>
                {avatarText || 'Bild'}
              </Avatar>
            )}
          </div>
        }
      />
    </div>
  );
}
