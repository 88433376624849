import { Button, Modal, Result } from 'antd';
import React from 'react';
import { useSubscriptionInfo } from '../redux/userSlice';

const PaymentMethodUpdateModal = ({
  visible,
  toggleModal
}: {
  visible: boolean;
  toggleModal: () => void;
}) => {
  const subscriptionInfo = useSubscriptionInfo();

  return (
    <Modal visible={visible} onCancel={toggleModal} footer={null} closeIcon={<></>}>
      <Result
        status="warning"
        title="Dein Account wurde deaktiviert"
        subTitle="Es bestehen offene Forderungen, die wir nicht abbuchen konnten. Bitte aktualisiere deine Zahlungsmethode."
        extra={[
          <Button>
            <a href={subscriptionInfo?.paddle_update_url} target="__blank">
              Zahlungsmethode aktualisieren
            </a>
          </Button>
        ]}
      />
    </Modal>
  );
};

export default PaymentMethodUpdateModal;
