import { analyticsMockDate, GET_PLOT_DATA } from "../container/Analytics";

export const getPlotDataMock = {
    request: {
        query: GET_PLOT_DATA,
        variables: {
            filter: {
                start: analyticsMockDate.start.toISOString(),
                end: analyticsMockDate.end.toISOString()
            }
        }
    },
    result: {
        data: {
            getSummaryPlot: {
                pageView: 100,
                leads: 77,
                conversionRate: 0.77,
                averageTimeInSeconds: 32
            },
            getPageHitPlot: [
                {
                    stepIndex: 0,
                    stepName: "pre-page_1",
                    hits: 100,
                    averageTimeInSeconds: 5,
                    choiceArray: []
                },
                {
                    stepIndex: 1,
                    stepName: "leadQualifier_1",
                    hits: 90,
                    averageTimeInSeconds: 7,
                    choiceArray: [
                        {
                            choiceId: 1,
                            count: 40
                        },
                        {
                            choiceId: 2,
                            count: 50
                        }
                    ]
                },
                {
                    stepIndex: 2,
                    stepName: "calendar",
                    hits: 88,
                    averageTimeInSeconds: 10,
                    choiceArray: []
                },
                {
                    stepIndex: 3,
                    stepName: "submit-form",
                    hits: 77,
                    averageTimeInSeconds: 10,
                    choiceArray: []
                },
            ],
            getLocationPlot: {
                topCities: [
                    {
                        cityName: "Hamburg",
                        countryFlag: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Flag_of_Germany.svg/2000px-Flag_of_Germany.svg.png",
                        count: 80
                    },
                    {
                        cityName: "Berlin",
                        countryFlag: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Flag_of_Germany.svg/2000px-Flag_of_Germany.svg.png",
                        count: 20
                    }
                ],
                topCountries: [
                    {
                        countryName: "Germany",
                        countryFlag: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Flag_of_Germany.svg/2000px-Flag_of_Germany.svg.png",
                        count: 100
                    }
                ]
            },
            getSourcePlot: [
                {
                    sourceTypeClean: "Facebook",
                    sourceType: "https://www.facebook.de/hans.peter",
                    count: 50
                },
                {
                    sourceTypeClean: "Andere",
                    sourceType: "Direkt",
                    count: 50
                }
            ],
            getLeadQualifier: [
                {
                    id: 1,
                    question: "Bist du männlich oder weiblich?",
                    type: "radio",
                    choices: [
                        {
                            id: 1,
                            title: "männlich"
                        },
                        {
                            id: 2,
                            title: "weiblich"
                        }
                    ]
                }
            ]
        }
    },
}

export default [getPlotDataMock]