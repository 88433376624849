export const predefinedBenefitOptions = [
  { label: 'Weiterbildungsangebot', value: 'training-and-development' },
  { label: 'Aufstiegschancen', value: 'career-advancement-opportunities' },
  { label: 'Workations', value: 'workations' },
  { label: 'Team events', value: 'team-events' },
  { label: 'Flexible Arbeitszeiten', value: 'flexible-working-hours' },
  { label: 'Home-Office-Möglichkeiten', value: 'remote-work-options' },
  { label: 'Betriebliche Altersvorsorge', value: 'company-pension-plan' },
  { label: 'Gesundheitsprogramme', value: 'health-programs' },
  { label: 'Mitarbeiter-Rabatte', value: 'employee-discounts' },
  { label: 'Weiterbildungsmöglichkeiten', value: 'training-opportunities' },
  { label: 'Kostenlose Verpflegung', value: 'free-meals' },
  { label: 'Gleitzeit', value: 'flexitime' },
  { label: 'Sabbatical-Optionen', value: 'sabbatical-options' },
  { label: 'Kinderbetreuung', value: 'childcare' },
  { label: 'Gesundheitsvorsorge', value: 'healthcare' },
  { label: 'Mitarbeiterboni', value: 'employee-bonuses' },
  { label: 'Betriebsausflüge', value: 'company-outings' },
  { label: 'Kostenlose Parkplätze', value: 'free-parking' },
  { label: 'Jobticket', value: 'job-ticket' },
  { label: 'Diensthandy', value: 'company-phone' },
  { label: 'Dienstwagen', value: 'company-car' },
  { label: 'Zusätzliche Urlaubstage', value: 'additional-vacation-days' },
  { label: 'Urlaubs- und Weihnachtsgeld', value: 'holiday-and-christmas-bonus' },
  { label: 'Work-Life-Balance-Initiativen', value: 'work-life-balance-initiatives' },
  { label: 'Ergonomische Arbeitsplätze', value: 'ergonomic-workstations' },
  { label: 'Mentoring-Programme', value: 'mentoring-programs' },
  { label: 'Karrieremöglichkeiten', value: 'career-opportunities' },
  { label: 'Innovative Arbeitsumgebung', value: 'innovative-work-environment' },
  { label: 'Betriebliches Gesundheitsmanagement', value: 'occupational-health-management' },
  { label: 'Kreativräume', value: 'creative-spaces' },
  { label: 'Kostenlose Getränke', value: 'free-drinks' },
  { label: 'Betriebliche Unfallversicherung', value: 'company-accident-insurance' },
  { label: 'Unterstützung bei Umzug', value: 'relocation-assistance' },
  { label: 'Firmenevents', value: 'company-events' },
  { label: 'Vermögenswirksame Leistungen', value: 'capital-forming-benefits' },
  { label: 'Mitarbeiteraktien', value: 'employee-shares' },
  { label: 'Freizeitangebote', value: 'leisure-activities' },
  { label: 'Coaching', value: 'coaching' },
  { label: 'Soziale Verantwortung', value: 'social-responsibility' },
  { label: 'Moderne IT-Ausstattung', value: 'modern-it-equipment' },
  {
    label: 'Betriebliches Eingliederungsmanagement',
    value: 'occupational-reintegration-management'
  },
  { label: 'Flexible Arbeitsorte', value: 'flexible-work-locations' },
  { label: 'Gesundheitsfördernde Maßnahmen', value: 'health-promoting-measures' },
  { label: 'Umfassende Einarbeitung', value: 'comprehensive-onboarding' },
  { label: 'Kostenübernahme für Weiterbildung', value: 'cost-coverage-for-training' },
  { label: 'Familienfreundliche Arbeitszeiten', value: 'family-friendly-working-hours' },
  { label: 'Attraktive Standortlage', value: 'attractive-location' },
  { label: 'Betriebsarzt', value: 'company-doctor' },
  { label: 'Corporate Benefits', value: 'corporate-benefits' },
  { label: 'Feiern und Jubiläen', value: 'celebrations-and-anniversaries' },
  { label: 'Jährliche Gehaltsüberprüfungen', value: 'annual-salary-reviews' },
  { label: 'Langfristige Beschäftigungssicherheit', value: 'long-term-job-security' },
  { label: 'Offene Unternehmenskultur', value: 'open-company-culture' }
];

export const predefinedUspOptions = [
  { label: 'Einzigartige Unternehmensphilosophie', value: 'unique-company-philosophy' },
  { label: 'Pionierarbeit in der Branche', value: 'pioneering-in-the-industry' },
  { label: 'Exklusive Partnerschaften', value: 'exclusive-partnerships' },
  { label: 'Bahnbrechende Innovationen', value: 'groundbreaking-innovations' },
  { label: 'Maßgeschneiderte Dienstleistungen', value: 'tailor-made-services' },
  { label: 'Unübertroffene Expertise', value: 'unmatched-expertise' },
  { label: 'Nischenmarktführer', value: 'niche-market-leader' },
  { label: 'Kundenzentrierte Kultur', value: 'customer-centric-culture' },
  { label: 'Hohe soziale Verantwortung', value: 'high-social-responsibility' },
  { label: 'Unvergleichliche Anpassungsfähigkeit', value: 'unparalleled-adaptability' },
  { label: 'Langfristige Partnerschaften', value: 'long-term-partnerships' },
  { label: 'Branchenführende Technologie', value: 'industry-leading-technology' },
  { label: 'Revolutionäre Geschäftsmodelle', value: 'revolutionary-business-models' },
  { label: 'Kreative Problemlösungen', value: 'creative-problem-solving' },
  { label: 'Erstklassige Benutzererfahrung', value: 'top-tier-user-experience' },
  { label: 'Visionäre Unternehmensführung', value: 'visionary-leadership' },
  { label: 'Herausragende Mitarbeiterentwicklung', value: 'outstanding-employee-development' },
  { label: 'Exklusive Technologielösungen', value: 'exclusive-technology-solutions' },
  { label: 'Nachgewiesene Erfolgsbilanz', value: 'proven-track-record' },
  { label: 'Exzellente Arbeitskultur', value: 'excellent-work-culture' },
  { label: 'Umfangreiche Marktkenntnisse', value: 'extensive-market-knowledge' },
  { label: 'Innovative Produktentwicklungen', value: 'innovative-product-developments' },
  { label: 'Hochspezialisierte Dienstleistungen', value: 'highly-specialized-services' },
  { label: 'Maximale Effizienz', value: 'maximum-efficiency' },
  { label: 'Starke Kundenloyalität', value: 'strong-customer-loyalty' },
  { label: 'Weltklasse-Support', value: 'world-class-support' },
  { label: 'High-End-Lösungen', value: 'high-end-solutions' },
  { label: 'Extrem schnelle Reaktionszeiten', value: 'extremely-fast-response-times' },
  { label: 'Führende Edge-Technologie', value: 'leading-edge-technology' },
  { label: 'Außergewöhnliches Netzwerk', value: 'exceptional-network' },
  { label: 'Präzise Datenanalyse', value: 'precise-data-analysis' },
  { label: 'Grenzenlose Kreativität', value: 'boundless-creativity' },
  { label: 'Unvergleichliche Qualitätssicherung', value: 'unparalleled-quality-assurance' },
  { label: 'Innovative Technologien', value: 'innovative-technologies' },
  { label: 'Hervorragender Kundenservice', value: 'excellent-customer-service' },
  { label: 'Hohe Qualitätsstandards', value: 'high-quality-standards' },
  {
    label: 'Nachhaltigkeit und Umweltbewusstsein',
    value: 'sustainability-and-environmental-awareness'
  },
  { label: 'Schnelle Markteinführung', value: 'quick-time-to-market' },
  { label: 'Kundenspezifische Lösungen', value: 'customized-solutions' },
  { label: 'Wettbewerbsfähige Preise', value: 'competitive-pricing' },
  { label: 'Globale Präsenz', value: 'global-presence' },
  { label: 'Langjährige Erfahrung', value: 'years-of-experience' },
  { label: 'Starke Markenidentität', value: 'strong-brand-identity' },
  { label: 'Innovationsführerschaft', value: 'innovation-leadership' },
  { label: 'Agile Arbeitsmethoden', value: 'agile-working-methods' },
  { label: 'Exklusivität', value: 'exclusivity' },
  { label: 'Hochqualifiziertes Team', value: 'highly-skilled-team' },
  { label: 'Modernste Infrastruktur', value: 'state-of-the-art-infrastructure' },
  { label: 'Hohe Kundenzufriedenheit', value: 'high-customer-satisfaction' },
  { label: 'Engagiertes Projektmanagement', value: 'dedicated-project-management' },
  { label: 'Zertifizierte Prozesse', value: 'certified-processes' },
  { label: 'Transparente Kommunikation', value: 'transparent-communication' },
  { label: 'Starke Innovationskultur', value: 'strong-innovation-culture' },
  { label: 'Hohe Produktivität', value: 'high-productivity' }
];

export const predefinedGoalOptions = [
  { label: 'Karriereaufstieg', value: 'career-promotion' },
  { label: 'Gehaltserhöhung', value: 'salary-increase' },
  { label: 'Work-Life-Balance', value: 'work-life-balance' },
  { label: 'Weiterbildung', value: 'further-education' },
  { label: 'Verantwortung', value: 'responsibility' },
  { label: 'Anerkennung', value: 'recognition' },
  { label: 'Jobsicherheit', value: 'job-security' },
  { label: 'Teamarbeit', value: 'teamwork' },
  { label: 'Produktivität', value: 'productivity' },
  { label: 'Netzwerkaufbau', value: 'networking' },
  { label: 'Bessere Zusammenarbeit', value: 'better-collaboration' },
  { label: 'Fortbildung', value: 'advanced-training' },
  { label: 'Kreativität', value: 'creativity' },
  { label: 'Entscheidungsbeteiligung', value: 'decision-participation' },
  { label: 'Technische Fähigkeiten', value: 'technical-skills' },
  { label: 'Arbeitszeitflexibilität', value: 'working-time-flexibility' },
  { label: 'Herausforderungen', value: 'challenges' },
  { label: 'Autonomie', value: 'autonomy' },
  { label: 'Finanzielle Sicherheit', value: 'financial-security' },
  { label: 'Meilenstein-Erreichung', value: 'milestone-achievement' },
  { label: 'Mentoring', value: 'mentoring' },
  { label: 'Führungsfähigkeiten', value: 'leadership-skills' },
  { label: 'Arbeitszufriedenheit', value: 'job-satisfaction' },
  { label: 'Expertise', value: 'expertise' },
  { label: 'Karriereentwicklung', value: 'career-development' },
  { label: 'Angenehmes Arbeitsklima', value: 'pleasant-working-atmosphere' },
  { label: 'Entscheidungsfreiheit', value: 'decision-freedom' },
  { label: 'Internationale Projekte', value: 'international-projects' },
  { label: 'Teamharmonie', value: 'team-harmony' },
  { label: 'Mehr Urlaub', value: 'more-vacation' },
  { label: 'Arbeitsbedingungen', value: 'working-conditions' },
  { label: 'Technologiezugang', value: 'technology-access' },
  { label: 'Leistungsanerkennung', value: 'performance-recognition' },
  { label: 'Unternehmensstrategie', value: 'corporate-strategy' },
  { label: 'Angenehme Unternehmenskultur', value: 'pleasant-company-culture' },
  { label: 'Diversität', value: 'diversity' },
  { label: 'Innovationsumfeld', value: 'innovation-environment' },
  { label: 'Feedback', value: 'feedback' },
  { label: 'Produktentwicklung', value: 'product-development' },
  { label: 'Gesundheit', value: 'health' },
  { label: 'Soziale Verantwortung', value: 'social-responsibility' },
  { label: 'Firmenwagen', value: 'company-car' },
  { label: 'Sabbatical', value: 'sabbatical' },
  { label: 'Leistungsboni', value: 'performance-bonuses' },
  { label: 'Berufserfahrung', value: 'work-experience' },
  { label: 'Eigenmotivation', value: 'self-motivation' },
  { label: 'Effizienz', value: 'efficiency' },
  { label: 'Zeitmanagement', value: 'time-management' },
  { label: 'Entscheidungsbefugnis', value: 'decision-authority' }
];

const predefinedSalesTasksOpions = [
  { label: 'Kundenbetreuung', value: 'customer-support' },
  { label: 'Verkaufstechniken', value: 'sales-techniques' },
  { label: 'Kommunikation', value: 'communication' },
  { label: 'Verhandlungsgeschick', value: 'negotiation-skills' },
  { label: 'Produktkenntnisse', value: 'product-knowledge' },
  { label: 'Zeitmanagement', value: 'time-management' },
  { label: 'Salesforce', value: 'salesforce' },
  { label: 'Microsoft Dynamics', value: 'microsoft-dynamics' },
  { label: 'SAP CRM', value: 'sap-crm' },
  { label: 'HubSpot', value: 'hubspot' },
  { label: 'Oracle CRM', value: 'oracle-crm' },
  { label: 'Pipedrive', value: 'pipedrive' },
  { label: 'Produktpräsentation', value: 'product-presentation' },
  { label: 'Verkaufsstrategien', value: 'sales-strategies' },
  { label: 'Kundenservice', value: 'customer-service' },
  { label: 'Warenkenntnisse', value: 'product-knowledge' },
  { label: 'Kassensysteme', value: 'pos-systems' },
  { label: 'SAP Retail', value: 'sap-retail' },
  { label: 'Microsoft Office', value: 'microsoft-office' },
  { label: 'Warenwirtschaftssysteme', value: 'inventory-management-systems' },
  { label: 'CRM-Software', value: 'crm-software' },
  { label: 'Google Workspace', value: 'google-workspace' },
  { label: 'Vertriebstechnik', value: 'sales-technique' },
  { label: 'Verhandlung', value: 'negotiation' },
  { label: 'Kundenkommunikation', value: 'customer-communication' },
  { label: 'Angebotsmanagement', value: 'proposal-management' },
  { label: 'Zoho CRM', value: 'zoho-crm' },
  { label: 'Vertrieb', value: 'sales' },
  { label: 'Kundenakquise', value: 'customer-acquisition' },
  { label: 'Marktanalyse', value: 'market-analysis' },
  { label: 'Marktforschungstools', value: 'market-research-tools' },
  { label: 'Eventmanagement', value: 'event-management' },
  { label: 'Produktplatzierung', value: 'product-placement' },
  { label: 'Marketing', value: 'marketing' },
  { label: 'Social Media Tools', value: 'social-media-tools' },
  { label: 'Eventmanagement-Software', value: 'event-management-software' },
  { label: 'Problemmanagement', value: 'problem-management' },
  { label: 'Telefonie', value: 'telephony' },
  { label: 'Zendesk', value: 'zendesk' },
  { label: 'Freshdesk', value: 'freshdesk' },
  { label: 'Vertriebsplanung', value: 'sales-planning' },
  { label: 'Organisation', value: 'organization' },
  { label: 'Kundenmanagement', value: 'customer-management' },
  { label: 'Reporting', value: 'reporting' },
  { label: 'Trello', value: 'trello' },
  { label: 'Finanzanalyse', value: 'financial-analysis' },
  { label: 'Vertriebskontrolle', value: 'sales-control' },
  { label: 'Datenanalyse', value: 'data-analysis' },
  { label: 'Microsoft Excel', value: 'microsoft-excel' },
  { label: 'SAP ERP', value: 'sap-erp' },
  { label: 'Tableau', value: 'tableau' },
  { label: 'Power BI', value: 'power-bi' },
  { label: 'Gebietsmanagement', value: 'territory-management' },
  { label: 'Außendienst', value: 'field-sales' },
  { label: 'Technischer Vertrieb', value: 'technical-sales' },
  { label: 'Projektmanagement', value: 'project-management' },
  { label: 'Projektmanagementsoftware', value: 'project-management-software' },
  { label: 'Vertriebsstrategien', value: 'sales-strategies' },
  { label: 'Mitarbeiterführung', value: 'leadership' },
  { label: 'Produktentwicklung', value: 'product-development' },
  { label: 'Jira', value: 'jira' },
  { label: 'Google Analytics', value: 'google-analytics' },
  { label: 'Filialmanagement', value: 'branch-management' },
  { label: 'Kundenberatung', value: 'customer-consulting' },
  { label: 'Warenpräsentation', value: 'merchandising' },
  { label: 'Warenmanagement', value: 'inventory-management' },
  { label: 'Produktmanagement', value: 'product-management' },
  { label: 'Unternehmensstrategie', value: 'corporate-strategy' },
  { label: 'Angenehme Unternehmenskultur', value: 'pleasant-company-culture' },
  { label: 'Diversität', value: 'diversity' },
  { label: 'Innovationsumfeld', value: 'innovation-environment' },
  { label: 'Feedback', value: 'feedback' },
  { label: 'Produktentwicklung', value: 'product-development' },
  { label: 'Gesundheit', value: 'health' },
  { label: 'Soziale Verantwortung', value: 'social-responsibility' },
  { label: 'Firmenwagen', value: 'company-car' },
  { label: 'Leistungsboni', value: 'performance-bonuses' },
  { label: 'Berufserfahrung', value: 'work-experience' },
  { label: 'Eigenmotivation', value: 'self-motivation' },
  { label: 'Effizienz', value: 'efficiency' },
  { label: 'Zeitmanagement', value: 'time-management' },
];

const predefinedMedicalTasksOpions = [
  { label: 'Pflegeplanung', value: 'nursing-planning' },
  { label: 'Medikamentenmanagement', value: 'medication-management' },
  { label: 'Patientenbeobachtung', value: 'patient-observation' },
  { label: 'Kommunikation', value: 'communication' },
  { label: 'Dokumentation', value: 'documentation' },
  { label: 'Teamarbeit', value: 'teamwork' },
  { label: 'Pflegesoftware', value: 'nursing-software' },
  { label: 'Medikamentenmanagementsoftware', value: 'medication-management-software' },
  { label: 'Krankenhausinformationssysteme', value: 'hospital-information-systems' },
  { label: 'Microsoft Office', value: 'microsoft-office' },
  { label: 'EPIC', value: 'epic' },
  { label: 'CareVue', value: 'carevue' },
  { label: 'Grundpflege', value: 'basic-care' },
  { label: 'Hauswirtschaft', value: 'housekeeping' },
  { label: 'Mobilisierung', value: 'mobilization' },
  { label: 'Erste Hilfe', value: 'first-aid' },
  { label: 'Hygiene', value: 'hygiene' },
  { label: 'Hauswirtschaftssoftware', value: 'housekeeping-software' },
  { label: 'Erste-Hilfe-Programme', value: 'first-aid-programs' },
  { label: 'Dokumentationssysteme', value: 'documentation-systems' },
  { label: 'Vitalzeichenmonitore', value: 'vital-sign-monitors' },
  { label: 'Palliativpflege', value: 'palliative-care' },
  { label: 'Schmerzmanagement', value: 'pain-management' },
  { label: 'Emotionale Unterstützung', value: 'emotional-support' },
  { label: 'Schmerzmanagementsysteme', value: 'pain-management-systems' },
  { label: 'Demenzpflege', value: 'dementia-care' },
  { label: 'Gedächtnistraining', value: 'memory-training' },
  { label: 'Gedächtnistrainingssoftware', value: 'memory-training-software' },
  { label: 'Rehabilitationspflege', value: 'rehabilitation-care' },
  { label: 'Patientenmobilisierung', value: 'patient-mobilization' },
  { label: 'Physikalische Therapie', value: 'physical-therapy' },
  { label: 'Rehabilitationssoftware', value: 'rehabilitation-software' },
  { label: 'Hospizpflege', value: 'hospice-care' },
  { label: 'Palliativpflege-Software', value: 'palliative-care-software' },
  { label: 'Pflegemanagement', value: 'nursing-management' },
  { label: 'Personalführung', value: 'staff-management' },
  { label: 'Budgetierung', value: 'budgeting' },
  { label: 'Qualitätssicherung', value: 'quality-assurance' },
  { label: 'Pflegemanagement-Software', value: 'nursing-management-software' },
  { label: 'Personalmanagement-Software', value: 'personnel-management-software' },
  { label: 'Budgetierungssoftware', value: 'budgeting-software' },
  { label: 'Aktivitätenplanung', value: 'activity-planning' },
  { label: 'Seniorenbetreuung', value: 'elderly-care' },
  { label: 'Kreativität', value: 'creativity' },
  { label: 'Aktivitätsplanungssoftware', value: 'activity-planning-software' },
  { label: 'Gerontologie', value: 'gerontology' }
];

const predefinedTechTasksOpions = [
  { label: 'Programmierung', value: 'programming' },
  { label: 'Softwaredesign', value: 'software-design' },
  { label: 'Debugging', value: 'debugging' },
  { label: 'Datenstrukturen', value: 'data-structures' },
  { label: 'Algorithmen', value: 'algorithms' },
  { label: 'Testen', value: 'testing' },
  { label: 'Java', value: 'java' },
  { label: 'Python', value: 'python' },
  { label: 'Git', value: 'git' },
  { label: 'Visual Studio', value: 'visual-studio' },
  { label: 'Eclipse', value: 'eclipse' },
  { label: 'IntelliJ', value: 'intellij' },
  { label: 'Datenanalyse', value: 'data-analysis' },
  { label: 'Machine Learning', value: 'machine-learning' },
  { label: 'Statistik', value: 'statistics' },
  { label: 'Datenvisualisierung', value: 'data-visualization' },
  { label: 'Big Data', value: 'big-data' },
  { label: 'R', value: 'r' },
  { label: 'Tableau', value: 'tableau' },
  { label: 'TensorFlow', value: 'tensorflow' },
  { label: 'Hadoop', value: 'hadoop' },
  { label: 'Apache Spark', value: 'apache-spark' },
  { label: 'Webdesign', value: 'web-design' },
  { label: 'Frontend-Entwicklung', value: 'frontend-development' },
  { label: 'Backend-Entwicklung', value: 'backend-development' },
  { label: 'HTML', value: 'html' },
  { label: 'CSS', value: 'css' },
  { label: 'JavaScript', value: 'javascript' },
  { label: 'React', value: 'react' },
  { label: 'Angular', value: 'angular' },
  { label: 'Node.js', value: 'nodejs' },
  { label: 'Projektmanagement', value: 'project-management' },
  { label: 'Zeitplanung', value: 'time-management' },
  { label: 'Budgetierung', value: 'budgeting' },
  { label: 'Kommunikation', value: 'communication' },
  { label: 'Risikomanagement', value: 'risk-management' },
  { label: 'Agile Methoden', value: 'agile-methods' },
  { label: 'JIRA', value: 'jira' },
  { label: 'Microsoft Project', value: 'microsoft-project' },
  { label: 'Trello', value: 'trello' },
  { label: 'Asana', value: 'asana' },
  { label: 'MS Office', value: 'ms-office' },
  { label: 'Confluence', value: 'confluence' },
  { label: 'Netzwerksicherheit', value: 'network-security' },
  { label: 'Risikoanalyse', value: 'risk-analysis' },
  { label: 'Cybersecurity', value: 'cybersecurity' },
  { label: 'Firewalls', value: 'firewalls' },
  { label: 'Verschlüsselung', value: 'encryption' },
  { label: 'Penetrationstests', value: 'penetration-testing' },
  { label: 'Wireshark', value: 'wireshark' },
  { label: 'Nessus', value: 'nessus' },
  { label: 'Metasploit', value: 'metasploit' },
  { label: 'Snort', value: 'snort' },
  { label: 'Splunk', value: 'splunk' },
  { label: 'Palo Alto', value: 'palo-alto' },
  { label: 'User Experience', value: 'user-experience' },
  { label: 'Interface Design', value: 'interface-design' },
  { label: 'Prototyping', value: 'prototyping' },
  { label: 'Usability Testing', value: 'usability-testing' },
  { label: 'Design Thinking', value: 'design-thinking' },
  { label: 'Wireframing', value: 'wireframing' },
  { label: 'Adobe XD', value: 'adobe-xd' },
  { label: 'Sketch', value: 'sketch' },
  { label: 'Figma', value: 'figma' },
  { label: 'InVision', value: 'invision' },
  { label: 'Axure', value: 'axure' },
  { label: 'Balsamiq', value: 'balsamiq' },
  { label: 'Grafikdesign', value: 'graphic-design' },
  { label: 'Layout', value: 'layout' },
  { label: 'Typografie', value: 'typography' },
  { label: 'Bildbearbeitung', value: 'image-editing' },
  { label: 'Branding', value: 'branding' },
  { label: 'Adobe Creative Suite', value: 'adobe-creative-suite' },
  { label: 'Adobe Photoshop', value: 'adobe-photoshop' },
  { label: 'Adobe Illustrator', value: 'adobe-illustrator' },
  { label: 'InDesign', value: 'indesign' },
  { label: 'CorelDRAW', value: 'coreldraw' },
  { label: 'Canva', value: 'canva' },
  { label: 'Digitales Marketing', value: 'digital-marketing' },
  { label: 'Content Management', value: 'content-management' },
  { label: 'Social Media Marketing', value: 'social-media-marketing' },
  { label: 'Google Analytics', value: 'google-analytics' },
  { label: 'SEO', value: 'seo' },
  { label: 'SEM', value: 'sem' },
  { label: 'Hootsuite', value: 'hootsuite' },
  { label: 'HubSpot', value: 'hubspot' },
  { label: 'Mailchimp', value: 'mailchimp' },
  { label: 'WordPress', value: 'wordpress' },
  { label: 'Facebook Ads Manager', value: 'facebook-ads-manager' },
  { label: 'Content Marketing', value: 'content-marketing' },
  { label: 'Keyword-Analyse', value: 'keyword-analysis' },
  { label: 'Backlink-Strategie', value: 'backlink-strategy' },
  { label: 'Google Search Console', value: 'google-search-console' },
  { label: 'SEMrush', value: 'semrush' },
  { label: 'Moz', value: 'moz' },
  { label: 'Ahrefs', value: 'ahrefs' },
  { label: 'Screaming Frog', value: 'screaming-frog' },
  { label: 'Yoast SEO', value: 'yoast-seo' },
  { label: 'Systemadministration', value: 'system-administration' },
  { label: 'Automatisierung', value: 'automation' },
  { label: 'CI/CD', value: 'ci-cd' },
  { label: 'Cloud Computing', value: 'cloud-computing' },
  { label: 'Containerisierung', value: 'containerization' },
  { label: 'Scripting', value: 'scripting' },
  { label: 'Docker', value: 'docker' },
  { label: 'Jenkins', value: 'jenkins' },
  { label: 'Kubernetes', value: 'kubernetes' },
  { label: 'AWS', value: 'aws' },
  { label: 'Ansible', value: 'ansible' },
  { label: 'Terraform', value: 'terraform' },
  { label: 'Architekturdesign', value: 'architecture-design' },
  { label: 'Skalierbarkeit', value: 'scalability' },
  { label: 'DevOps', value: 'devops' },
  { label: 'Azure', value: 'azure' },
  { label: 'Google Cloud', value: 'google-cloud' },
  { label: 'Mobile-Entwicklung', value: 'mobile-development' },
  { label: 'iOS', value: 'ios' },
  { label: 'Android', value: 'android' },
  { label: 'UI/UX', value: 'ui-ux' },
  { label: 'App-Testing', value: 'app-testing' },
  { label: 'Veröffentlichung', value: 'deployment' },
  { label: 'Swift', value: 'swift' },
  { label: 'Kotlin', value: 'kotlin' },
  { label: 'React Native', value: 'react-native' },
  { label: 'Flutter', value: 'flutter' },
  { label: 'Xcode', value: 'xcode' },
  { label: 'Android Studio', value: 'android-studio' },
  { label: 'Produktentwicklung', value: 'product-development' },
  { label: 'Marktforschung', value: 'market-research' },
  { label: 'Produktlebenszyklus', value: 'product-lifecycle' },
  { label: 'Strategie', value: 'strategy' },
  { label: 'Aha!', value: 'aha' },
  { label: 'Geschäftsprozesse', value: 'business-processes' },
  { label: 'Problemlösung', value: 'problem-solving' },
  { label: 'SQL', value: 'sql' },
  { label: 'Stakeholder-Management', value: 'stakeholder-management' },
  { label: 'Power BI', value: 'power-bi' },
  { label: 'MS Excel', value: 'ms-excel' },
  { label: 'SAP', value: 'sap' },
  { label: 'Deep Learning', value: 'deep-learning' },
  { label: 'Datenmodellierung', value: 'data-modeling' },
  { label: 'KI-Integration', value: 'ai-integration' },
  { label: 'PyTorch', value: 'pytorch' },
  { label: 'Scikit-learn', value: 'scikit-learn' },
  { label: 'Keras', value: 'keras' },
  { label: 'Jupyter', value: 'jupyter' },
  { label: 'IT-Support', value: 'it-support' },
  { label: 'Fehlerbehebung', value: 'troubleshooting' },
  { label: 'Hardware', value: 'hardware' },
  { label: 'Software', value: 'software' },
  { label: 'Netzwerkadministration', value: 'network-administration' },
  { label: 'Windows Server', value: 'windows-server' },
  { label: 'Linux', value: 'linux' },
  { label: 'Active Directory', value: 'active-directory' },
  { label: 'Cisco', value: 'cisco' },
  { label: 'ServiceNow', value: 'servicenow' },
  { label: 'IT-Infrastruktur', value: 'it-infrastructure' },
  { label: 'Router', value: 'routers' },
  { label: 'Switches', value: 'switches' },
  { label: 'VPN', value: 'vpn' },
  { label: 'Firewall', value: 'firewall' },
  { label: 'Juniper', value: 'juniper' },
  { label: 'VMware', value: 'vmware' },
  { label: 'Blockchain', value: 'blockchain' },
  { label: 'Smart Contracts', value: 'smart-contracts' },
  { label: 'Kryptographie', value: 'cryptography' },
  { label: 'Ethereum', value: 'ethereum' },
  { label: 'Solidity', value: 'solidity' },
  { label: 'Dezentralisierte Apps (DApps)', value: 'decentralized-apps' },
  { label: 'Hyperledger', value: 'hyperledger' },
  { label: 'Truffle', value: 'truffle' },
  { label: 'Ganache', value: 'ganache' },
  { label: 'Metamask', value: 'metamask' },
  { label: 'Datenbanken', value: 'databases' },
  { label: 'API-Entwicklung', value: 'api-development' },
  { label: 'MongoDB', value: 'mongodb' },
  { label: 'MySQL', value: 'mysql' },
  { label: 'Spieleentwicklung', value: 'game-development' },
  { label: 'C++', value: 'c++' },
  { label: 'Unity', value: 'unity' },
  { label: 'Unreal Engine', value: 'unreal-engine' },
  { label: '3D-Modellierung', value: '3d-modeling' },
  { label: 'Physik-Engine', value: 'physics-engine' },
  { label: 'C#', value: 'csharp' },
  { label: 'Maya', value: 'maya' },
  { label: 'Photoshop', value: 'photoshop' }
];

const predefinedCraftTasksOpions = [
  { label: 'Holzbearbeitung', value: 'woodworking' },
  { label: 'Bauplanung', value: 'construction-planning' },
  { label: 'Zeichnungen lesen', value: 'blueprint-reading' },
  { label: 'Maßarbeit', value: 'precision-work' },
  { label: 'Holzverbindungen', value: 'wood-joints' },
  { label: 'Sicherheit am Arbeitsplatz', value: 'workplace-safety' },
  { label: 'CAD-Software', value: 'cad-software' },
  { label: 'Holzbearbeitungsmaschinen', value: 'woodworking-machines' },
  { label: 'Sägen', value: 'saws' },
  { label: 'Hobel', value: 'planes' },
  { label: 'Bohrmaschinen', value: 'drills' },
  { label: 'Sicherheitsausrüstung', value: 'safety-equipment' },
  { label: 'Elektroinstallation', value: 'electrical-installation' },
  { label: 'Verkabelung', value: 'wiring' },
  { label: 'Fehlerdiagnose', value: 'troubleshooting' },
  { label: 'Sicherheitsvorschriften', value: 'safety-regulations' },
  { label: 'Schaltpläne lesen', value: 'circuit-diagram-reading' },
  { label: 'Wartung', value: 'maintenance' },
  { label: 'Elektro-CAD', value: 'electro-cad' },
  { label: 'Multimeter', value: 'multimeter' },
  { label: 'Kabelschneider', value: 'wire-cutters' },
  { label: 'Lötkolben', value: 'soldering-iron' },
  { label: 'Prüfgeräte', value: 'testing-equipment' },
  { label: 'Sanitärtechnik', value: 'plumbing' },
  { label: 'Rohrinstallation', value: 'pipe-installation' },
  { label: 'Wasserleitungssysteme', value: 'water-piping-systems' },
  { label: 'Schweißtechniken', value: 'welding-techniques' },
  { label: 'Schweißgeräte', value: 'welding-machines' },
  { label: 'Rohrbieger', value: 'pipe-benders' },
  { label: 'Rohrschneider', value: 'pipe-cutters' },
  { label: 'Wasserpumpenzangen', value: 'water-pump-pliers' },
  { label: 'Mauerwerk', value: 'masonry' },
  { label: 'Betonarbeiten', value: 'concrete-work' },
  { label: 'Materialkenntnis', value: 'material-knowledge' },
  { label: 'Mauerwerkzeuge', value: 'masonry-tools' },
  { label: 'Betonmischer', value: 'concrete-mixer' },
  { label: 'Kellen', value: 'trowels' },
  { label: 'Nivelliergeräte', value: 'leveling-tools' },
  { label: 'Anstricharbeiten', value: 'painting' },
  { label: 'Oberflächenbehandlung', value: 'surface-treatment' },
  { label: 'Farbtheorie', value: 'color-theory' },
  { label: 'Maltechniken', value: 'painting-techniques' },
  { label: 'Farbspritzpistolen', value: 'paint-spray-guns' },
  { label: 'Pinsel', value: 'brushes' },
  { label: 'Farbroller', value: 'paint-rollers' },
  { label: 'Abdeckmaterialien', value: 'covering-materials' },
  { label: 'Farbtonmischer', value: 'paint-mixer' },
  { label: 'Dachdeckung', value: 'roofing' },
  { label: 'Abdichtung', value: 'sealing' },
  { label: 'Dachreparatur', value: 'roof-repair' },
  { label: 'Dachdeckerwerkzeuge', value: 'roofing-tools' },
  { label: 'Teerkocher', value: 'tar-kettles' },
  { label: 'Ziegelzuschnitt', value: 'brick-cutting' },
  { label: 'Dachdeckerhämmer', value: 'roofing-hammers' },
  { label: 'Fliesenverlegung', value: 'tile-laying' },
  { label: 'Musterplanung', value: 'pattern-planning' },
  { label: 'Zuschnitt', value: 'cutting' },
  { label: 'Verfugen', value: 'grouting' },
  { label: 'Fliesenschneider', value: 'tile-cutters' },
  { label: 'Zahnkellen', value: 'notched-trowels' },
  { label: 'Fugenkellen', value: 'joint-trowels' },
  { label: 'Kältetechnik', value: 'refrigeration' },
  { label: 'Klimaanlagenwartung', value: 'air-conditioning-maintenance' },
  { label: 'Installation', value: 'installation' },
  { label: 'Regelungstechnik', value: 'control-engineering' },
  { label: 'Manometer', value: 'manometers' },
  { label: 'Vakuumpumpen', value: 'vacuum-pumps' },
  { label: 'Klimaanlagenprüfgeräte', value: 'air-conditioning-testing-devices' },
  { label: 'Thermometer', value: 'thermometers' },
  { label: 'Schlosserarbeiten', value: 'locksmithing' },
  { label: 'Metallbearbeitung', value: 'metalworking' },
  { label: 'Schlossmontage', value: 'lock-assembly' },
  { label: 'Reparaturtechniken', value: 'repair-techniques' },
  { label: 'Schlosserwerkzeuge', value: 'locksmith-tools' },
  { label: 'Schlossöffner', value: 'lock-pickers' },
  { label: 'Möbelbau', value: 'furniture-making' },
  { label: 'Glasbearbeitung', value: 'glass-processing' },
  { label: 'Montage', value: 'assembly' },
  { label: 'Fensterbau', value: 'window-construction' },
  { label: 'Glasreparatur', value: 'glass-repair' },
  { label: 'Glasbearbeitungsmaschinen', value: 'glass-processing-machines' },
  { label: 'Montagetools', value: 'assembly-tools' },
  { label: 'Glasschneider', value: 'glass-cutters' },
  { label: 'Stuckarbeiten', value: 'plastering' },
  { label: 'Putztechnik', value: 'plastering-techniques' },
  { label: 'Oberflächengestaltung', value: 'surface-design' },
  { label: 'Putzmaschinen', value: 'plastering-machines' },
  { label: 'Glättkellen', value: 'smoothing-trowels' },
  { label: 'Stuckwerkzeuge', value: 'plastering-tools' },
  { label: 'Zeichnungssoftware', value: 'drawing-software' },
  { label: 'Schweißgerätebedienung', value: 'welding-machine-operation' },
  { label: 'Prüfgeräte', value: 'testing-tools' },
  { label: 'Metallbearbeitungsmaschinen', value: 'metalworking-machines' },
  { label: 'Schweißhelme', value: 'welding-helmets' },
  { label: 'Betonmischung', value: 'concrete-mixing' },
  { label: 'Schalungsbau', value: 'formwork-construction' },
  { label: 'Betonoberflächenbearbeitung', value: 'concrete-surface-treatment' },
  { label: 'Schalungswerkzeuge', value: 'formwork-tools' },
  { label: 'Betonoberflächenbearbeitungstools', value: 'concrete-surface-treatment-tools' },
  { label: 'Bodenverlegung', value: 'floor-laying' },
  { label: 'Bodenverlegungsmaschinen', value: 'floor-laying-machines' },
  { label: 'Zahnspachtel', value: 'notched-trowels' },
  { label: 'Zuschnittmaschinen', value: 'cutting-machines' },
  { label: 'Lackieren', value: 'varnishing' },
  { label: 'Blechbearbeitung', value: 'sheet-metal-processing' },
  { label: 'Blechbearbeitungsmaschinen', value: 'sheet-metal-machines' },
  { label: 'Isolierarbeiten', value: 'insulation-work' },
  { label: 'Dämmung', value: 'insulation' },
  { label: 'Isoliermaschinen', value: 'insulation-machines' },
  { label: 'Dämmmaterialien', value: 'insulation-materials' },
  { label: 'Straßenbau', value: 'road-construction' },
  { label: 'Pflastertechnik', value: 'paving-techniques' },
  { label: 'Asphaltverarbeitung', value: 'asphalt-processing' },
  { label: 'Pflasterwerkzeuge', value: 'paving-tools' },
  { label: 'Asphaltmaschinen', value: 'asphalt-machines' },
  { label: 'Entwurf', value: 'design' },
  { label: 'Projektmanagement', value: 'project-management' },
  { label: 'Bauvorschriften', value: 'building-regulations' },
  { label: 'Kommunikation', value: 'communication' },
  { label: 'AutoCAD', value: 'autocad' },
  { label: 'Revit', value: 'revit' },
  { label: 'SketchUp', value: 'sketchup' },
  { label: 'ArchiCAD', value: 'archicad' },
  { label: 'BIM-Software', value: 'bim-software' },
  { label: 'Microsoft Project', value: 'microsoft-project' },
  { label: 'Bauingenieurwesen', value: 'civil-engineering' },
  { label: 'Statik', value: 'statics' },
  { label: 'Baustoffe', value: 'building-materials' },
  { label: 'SAP2000', value: 'sap2000' },
  { label: 'STAAD.Pro', value: 'staad-pro' },
  { label: 'Bauleitung', value: 'construction-management' },
  { label: 'Zeitplanung', value: 'scheduling' },
  { label: 'Budgetierung', value: 'budgeting' },
  { label: 'Sicherheitsmanagement', value: 'safety-management' },
  { label: 'Primavera', value: 'primavera' },
  { label: 'Excel', value: 'excel' },
  { label: 'Stadtplanung', value: 'urban-planning' },
  { label: 'GIS', value: 'gis' },
  { label: 'Umweltplanung', value: 'environmental-planning' },
  { label: 'Verkehrsplanung', value: 'transportation-planning' },
  { label: 'ArcGIS', value: 'arcgis' },
  { label: 'Google Earth', value: 'google-earth' },
  { label: 'Vermessungstechnik', value: 'surveying' },
  { label: 'Geodäsie', value: 'geodesy' },
  { label: 'Datenanalyse', value: 'data-analysis' },
  { label: 'Kartenlesen', value: 'map-reading' },
  { label: 'Trimble', value: 'trimble' },
  { label: 'Leica Geo Office', value: 'leica-geo-office' },
  { label: 'Tragwerksplanung', value: 'structural-engineering' },
  { label: 'Baustoffkunde', value: 'building-materials-science' },
  { label: 'Bauinspektion', value: 'construction-inspection' },
  { label: 'Sicherheitsstandards', value: 'safety-standards' },
  { label: 'Qualitätskontrolle', value: 'quality-control' },
  { label: 'Baustellenmanagementsoftware', value: 'construction-site-management-software' },
  { label: 'Qualitätsmanagementsoftware', value: 'quality-management-software' },
  { label: 'Kalkulation', value: 'calculation' },
  { label: 'Kostenanalyse', value: 'cost-analysis' },
  { label: 'Baustellenmanagement', value: 'construction-site-management' },
  { label: 'Landschaftsarchitektur', value: 'landscape-architecture' },
  { label: 'Pflanzplanung', value: 'plant-planning' },
  { label: 'Umweltgestaltung', value: 'environmental-design' },
  { label: 'Geotechnik', value: 'geotechnics' },
  { label: 'Bodenmechanik', value: 'soil-mechanics' },
  { label: 'Baugrunduntersuchung', value: 'ground-investigation' },
  { label: 'PLAXIS', value: 'plaxis' },
  { label: 'GeoStudio', value: 'geostudio' }
];

export const predefinedTaskOptions = [
  ...predefinedSalesTasksOpions,
  ...predefinedMedicalTasksOpions,
  ...predefinedTechTasksOpions,
  ...predefinedCraftTasksOpions
];
