import { getEnvironment } from '../../../helper/environment';

export const getWidgetBaseUrl = () => {
  return getEnvironment().domain + '/widget/';
};

export const getWidgetUrlWithoutHttp = () => {
  return getWidgetBaseUrl().replace('https://', '') + '/widget/';
};

export const widgetBuilderBasePath = 'widget-builder';

export const isWidgetBuilderPath = () => {
  return !!location.pathname.includes(`/${widgetBuilderBasePath}`);
};

export const defautlWidgetThemeKeys = [
  'headlineColor',
  'accentColor',
  'backgroundColor',
  'cardBackgroundColor',
  'textColor',
  'cardTextColor'
];
