import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const GET_IMAGE_TAGS = gql`
  query getImageTags {
    getImageTags {
      id
      name
    }
  }
`;

export const useImagesTags = () => {
  return useQuery(GET_IMAGE_TAGS);
};

export const getImagesTags = () => {
  return window.apolloClient.query({
    query: GET_IMAGE_TAGS,
    fetchPolicy: 'no-cache'
  });
};
