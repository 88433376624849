import React from 'react';
import { Card, Col, Row } from 'antd';
import { Collapse } from 'antd';
import { FaCheckSquare, FaHandPointer, FaImage } from 'react-icons/fa';
import { BiCarousel } from 'react-icons/bi';
import SettingsGB from '../Settings/SettingsGB';
import {
  ButtonPresetsSettingsItems,
  ChoiceComponentPresetsSettingsItems,
  ImageCarousalPresetsSettingsItems,
  ImagePresetsSettingsItems
} from './PresetsSettingsTemplates';
import { setElementsPresets, useElementsPresets } from '../../redux/builderSlice';
import { builderPresetElements } from '../../interfaces/builderSliceTypes';
import { useAppDispatch } from '../../../redux/hooks';
import { getKeyByValue, getValueByKey } from '../../helper/craftJs';
import { ButtonAvailableBorderRadius } from '../../interfaces/ButtonSizeInterface';
import {
  ImageAvailableBorderRadius,
  ImageAvailableSizes
} from '../../interfaces/ImageSizeInterface';
import {
  buttonPresetsInitialValues,
  choicePresetsInitialValues,
  imageCarousalPresetsInitialValues,
  imagePresetsInitialValues
} from '../../interfaces/presetsInititalValues';
import { ChoiceAvailableBorderRadius } from '../../interfaces/ChoiceSizeInterface';
const { Panel } = Collapse;

const ElementsPresets = () => {
  const dispatch = useAppDispatch();

  const handleChange = (
    elementKey: builderPresetElements,
    keyToUpdate: string,
    updatedValue: any
  ) => {
    const computeValue = (value: any, availableValues: any) => {
      if (keyToUpdate === 'borderRadius') {
        return getValueByKey(value, availableValues).pixelValue;
      } else if (keyToUpdate === 'boldText') {
        return !buttonValues.boldText;
      } else if (keyToUpdate === 'maxWidth') {
        return getValueByKey(value, availableValues).value;
      }
      return value;
    };

    const updatedObject = {
      [keyToUpdate]: computeValue(
        updatedValue,
        {
          [builderPresetElements.BUTTON]: ButtonAvailableBorderRadius,
          [builderPresetElements.IMAGE]: ImageAvailableBorderRadius,
          [builderPresetElements.CAROUSEL]: ImageAvailableBorderRadius,
          [builderPresetElements.CHOICE]: ChoiceAvailableBorderRadius
        }[elementKey]
      )
    };

    dispatch(setElementsPresets({ elementKey, updatedValue: updatedObject }));
  };

  const buttonValues =
    useElementsPresets(builderPresetElements.BUTTON) || buttonPresetsInitialValues;
  const imageValues = useElementsPresets(builderPresetElements.IMAGE) || imagePresetsInitialValues;
  const imageCarousalValues =
    useElementsPresets(builderPresetElements.CAROUSEL) || imageCarousalPresetsInitialValues;
  const choiceCarousalValues =
    useElementsPresets(builderPresetElements.CHOICE) || choicePresetsInitialValues;

  return (
    <Col className="builder__settings-sidebar__container h-100">
      <Card className="settings-card h-100" title="Element-Voreinstellungen" bordered={false}>
        <Row className="builder__settings-sidebar__row">
          <Collapse
            accordion
            style={{ width: '100%' }}
            expandIconPosition="right"
            bordered={false}
            className="presets-collapse"
          >
            <Panel
              header={
                <>
                  <FaHandPointer className="mr-2" />
                  {`   `}Button
                </>
              }
              key={builderPresetElements.BUTTON}
            >
              <SettingsGB
                onChange={(key, val) => handleChange(builderPresetElements.BUTTON, key, val)}
                elements={ButtonPresetsSettingsItems}
                values={{
                  ...buttonValues,
                  borderRadius: getKeyByValue(
                    // @ts-ignore
                    buttonValues?.borderRadius,
                    ButtonAvailableBorderRadius,
                    'pixelValue'
                  ).key
                }}
              />
            </Panel>
            <Panel
              header={
                <>
                  <FaImage />
                  {`   `}Bild
                </>
              }
              key={builderPresetElements.IMAGE}
            >
              <SettingsGB
                onChange={(key, val) => handleChange(builderPresetElements.IMAGE, key, val)}
                elements={ImagePresetsSettingsItems}
                values={{
                  ...imageValues,
                  borderRadius: getKeyByValue(
                    imageValues.borderRadius,
                    ImageAvailableBorderRadius,
                    'pixelValue'
                  ).key
                }}
              />
            </Panel>
            <Panel
              header={
                <>
                  <BiCarousel />
                  {`   `}Bild-Slider
                </>
              }
              key={builderPresetElements.CAROUSEL}
            >
              <SettingsGB
                onChange={(key, val) => handleChange(builderPresetElements.CAROUSEL, key, val)}
                elements={ImageCarousalPresetsSettingsItems}
                values={{
                  borderRadius: getKeyByValue(
                    imageCarousalValues.borderRadius,
                    ImageAvailableBorderRadius,
                    'pixelValue'
                  ).key
                }}
              />
            </Panel>
            <Panel
              header={
                <>
                  <FaCheckSquare />
                  {`   `}Auswahl
                </>
              }
              key={builderPresetElements.CHOICE}
            >
              <SettingsGB
                onChange={(key, val) => handleChange(builderPresetElements.CHOICE, key, val)}
                elements={ChoiceComponentPresetsSettingsItems}
                values={{
                  ...choiceCarousalValues,
                  borderRadius: getKeyByValue(
                    choiceCarousalValues.borderRadius,
                    ChoiceAvailableBorderRadius,
                    'pixelValue'
                  ).key
                }}
              />
            </Panel>
          </Collapse>
        </Row>
      </Card>
    </Col>
  );
};

export default ElementsPresets;
