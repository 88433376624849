import gql from 'graphql-tag';

export const GET_ALL_AVAILABLE_FONTS = gql`
query GetAllAvailableFonts {
  getAllAvailableFonts {
    id
    fontFamily
    fontLink
    type
    createdAt
  }
}
`;

export default function getAllAvailableFontsApi() {
  return window.apolloClient.query({
    query: GET_ALL_AVAILABLE_FONTS,
    fetchPolicy: 'no-cache'
  });
}
