import gql from 'graphql-tag';

export interface Input {
  id: number;
  name: string;
  craftState?: string;
}

export const UPDATE_FUNNEL_PAGE = gql`
  mutation updateFunnelPage($input: UpdateFunnelPageInput!) {
    updateFunnelPage(input: $input) {
      id
      name
      craftState
      redirectURL
      customJs
    }
  }
`;

export default function updateFunnelPage(input: Input) {
  return window.apolloClient.mutate({
    mutation: UPDATE_FUNNEL_PAGE,
    variables: { input: { ...input, id: +input.id } }
  });
}
