import React, { useEffect, useRef } from 'react';
import { throttle } from '../../helper/throttle';
import useWindowDimensions from '../../hooks/useWindowDimensions';

export const Viewport: React.FC = ({ children }) => {
  const step = 1;
  const scrollRef = useRef<Element | null>(null);
  const isScrollRef = useRef<boolean>(false);
  const { height } = useWindowDimensions();

  const setMove = (state: boolean) => {
    isScrollRef.current = state;
  };

  const moveToTop = () => {
    if (scrollRef.current?.scrollTop && isScrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollTop - step;
      requestAnimationFrame(moveToTop);
    }
  };

  const moveToBottom = () => {
    if (
      (scrollRef.current?.scrollTop === 0 || scrollRef.current?.scrollTop) &&
      isScrollRef.current
    ) {
      scrollRef.current.scrollTop = scrollRef.current.scrollTop + step;
      requestAnimationFrame(moveToBottom);
    }
  };

  const throttledMoveToTop = useRef(throttle(moveToTop, 100)).current;
  const throttledMoveToBottom = useRef(throttle(moveToBottom, 100)).current;

  useEffect(() => {
    scrollRef.current = document.getElementsByClassName('builder__content')[0];

    document.addEventListener(
      'drag',
      function(e) {
        e = e || window.event;
        const dragY = e.pageY;

        //Distance from bottom less then 50px
        if (dragY > height - 50) {
          setMove(true);
          throttledMoveToBottom();
        } else if (dragY < 100) {
          setMove(true);
          throttledMoveToTop();
        } else {
          setMove(false);
        }
      },
      false
    );

    document.addEventListener(
      'dragend',
      function(e) {
        setMove(false);
      },
      false
    );
  }, []);

  return (
    <div className="page-container">
      <div className={'craftjs-renderer'}>{children}</div>
    </div>
  );
};
