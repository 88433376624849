import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Form, message } from 'antd';
import { ConnectSettingsMessages, FunnelMessagesApiErrors } from '../../../config/messages';

import { WEBHOOK_TYPE } from '../../../hooks/useUserWebHooks';
import mutateCreateWebhook from '../../../hooks/mutateDeleteWebhook';
import mixpanel from 'mixpanel-browser';
import { mixpanelEvents } from '../../../UI/utils/mixpanel';
import WebhookForm from './WebhookForm';
interface AddWebhookDomainProps {
  addable: boolean;
}

const AddWebhookDomain = ({ addable }: AddWebhookDomainProps) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [createWebhook, { loading }] = mutateCreateWebhook();
  const domainListModalSelector = document.querySelector('#domain-list-modal');

  const handleSubmit = (values: any) => {
    values.type = WEBHOOK_TYPE.NEW_CONTACT;

    createWebhook({ variables: { data: values } })
      .then(() => {
        setOpen(false);
        form.resetFields();
        mixpanel.track(mixpanelEvents.WEBHOOK_CONNECTED);
        domainListModalSelector?.scrollTo(0, 0);
      })
      .catch(e => {
        message.error(FunnelMessagesApiErrors['Identifier is not unique.']);
      });
  };

  const handleOpen = () => {
    if (!addable) {
      message.error(ConnectSettingsMessages.notEnaugthDomains);
      return;
    }

    setOpen(true);
    domainListModalSelector?.scrollTo(0, domainListModalSelector?.scrollHeight || 0);
  };
  
  if (!open)
    return (
      <div className="connect-settings-changer__add-button-wrapper" style={{ border: 'none' }}>
        <Button
          onClick={handleOpen}
          type="ghost"
          className="lead-qualifier__add icon-circle-button-with-label"
        >
          <span className="ui__icon-circle"  data-testid="addNewWebHook">
            <PlusOutlined />
          </span>
          Hinzufügen
        </Button>
      </div>
    );

  if (open)
    return (
      <Card className="ui__white-round-wrapper" style={{ marginBottom: '1em' }}>
        <WebhookForm
          form={form}
          loading={loading}
          handleSubmit={handleSubmit}
          handleCancel={() => {
            setOpen(false);
            form.resetFields();
          }}
        />
      </Card>
    );

  return null;
};

export default AddWebhookDomain;
