export enum textTypesEnum {
  TEASER = 'TEASER',
  MAIN_TEXT = 'MAIN_TEXT',
  CTA = 'CTA',
  HEADLINE = 'HEADLINE',
  JOB_MIDDLE_PART = 'JOB_MIDDLE_PART',
  JOB_TITLE = 'JOB_TITLE',
  JOB_CTA = 'JOB_CTA',
  JOB_TEASER = 'JOB_TEASER',
  QUALIFICATION = 'Qualifications',
  SKILLS = 'Skills',
  RESPONSIBILITIES = 'Responsibilities',
  ADVANTAGES = 'ADVANTAGES',
  TASKS = 'TASKS',
  REQUIREMENTS = 'REQUIREMENTS',
}

export enum mediaTypeEnum {
  MEDIA = 'MEDIA',
  BANNER_TEXT = 'BANNER_TEXT',
  MEDIA_TEXT = 'MEDIA_TEXT'
}

export enum settingTypeEnum {
  SUGGESTION = 'SUGGESTION',
  EDITOR = 'EDITOR'
}

export interface SizeInterface {
  key: number;
  value: string | number;
}
