const mainTextData = {
  title: 'Hauptinhalt',
  data: [
    {
      "category": "Gesundheitswesen",
      "content": [
        {
          "text": "Mehr als nur einen Job als Pflegekraft (m/w/d) gewünscht? Werde Teil einer zweiten Familie: [der Muster GmbH]\r\n\r\nWir bieten dir:\r\n➡️ [Brennendes Bedürfnis: z.B. Finanziell sorglos: Übertarifliche Vergütung. Betriebliche Krankenversicherung. Zusatzleistungen und 13. Gehalt.\r\n➡️ [Brennendes Bedürfnis: z.B. Zusammenhalt wie in einer Familie: freundliche, greifbare und strukturierte Vorgesetzte. Wir halten unser Wort!]\r\n➡️ [Brennendes Bedürfnis: z.B. Wertschätzung, die du verdienst: Du bist unersetzlich, das wissen wir mit regelmäßigen Teamevents zu belohnen!]\r\n\r\n... und noch so viele weitere Gründe, ein Teil unserer Familie zu werden. In [Voll- und Teilzeit].\r\n",
          "title": "[Vorteile] 3 brennende Bedürfnisse",
          "id": 100009
        },
        {
          "text": "[Visionäre Aussage: z.B. Lass uns gemeinsam die Welt verändern. Und [Mehrwert für die Kunden] für jeden zugänglich machen! 🌍]\r\n\r\nWeitere [Berufsgruppe] sind auf dieser Mission erwünscht. Klicke unten auf den Link und erfahre, was wir dir alles ermöglichen werden.\r\n",
          "title": "[Vorteile] Vision",
          "id": 100010
        },
        {
          "text": "Lass uns gemeinsam den Menschen die Fürsorge geben, die sie verdienen. Und damit Hamburg zu einem gesünderen und harmonischen Ort machen, sodass sich deine Bemühung herumspricht 🌍 [Visionäre Aussage. Höherer Sinn. Die Bedeutung, die die Arbeit hat]\r\n\r\nWeitere Pflegekräfte (m/w/d) sind immer erwünscht. Wir brauchen dich für unsere Mission!\r\n\r\nMit diesen Vorteilen kannst du rechnen:\r\n\r\n[4-6 Benefits, nach der Formel: “Was” -> “Vorteil” -> “Bedeutung”]\r\n💳 Übertarifliche Bezahlung, für das dich andere beneiden werden.\r\n🙏🏻 Wertschätzung, die du verdienst. Deine Bemühungen werden belohnt\r\n📚 Bezahlte Weiterbildung, damit du deine Expertise in 1 Monat so schnell wächst wie bei anderen in 1 Jahr\r\n🏥 Beste Absicherung. Für deine gesamte Familie, sodass du nie wieder lange im Wartezimmer sitzen musst\r\n🎉 Endlich ein Team, mit du regelmäßig legendäre Teamevents feierst als wäre es dein engster Freundeskreis\r\n🔋 Maximale Energie. Flexible Arbeitszeiten, Massagen, Fitness und beste Verpflegung, damit du dein volles Potenzial entfalten kannst.\r\n\r\n... und noch viel mehr!\r\n",
          "title": "[Vorteile] Vision + Benefits",
          "id": 100011
        },
        {
          "text": "✅Deine Vorteile:\r\n\r\n[Benefits, die Bewerber fordern und nicht üblich sind. 6-10 nacheinander]\r\n\r\n- Übertarifliche Verdienstmöglichkeiten, inkl. Prämienmodell & Zusatzleistungen\r\n- Flexible Zeiteinteilung\r\n- Unbefristeter Arbeitsvertrag\r\n- Kostenlose Massagen\r\n- Bestens versichert. Gilt für deine gesamte Familie\r\n- Bezahlte Weiterbildungen: Bücher, Kurse, Veranstaltungen. Alles.\r\n- Neueste Dienstwagen. Auch zur privaten Nutzung.\r\n- Arbeiten, wo andere Urlaub machen\r\n- Spannende und abwechslungsreiche Projekte\r\n- Legendäre Teamevents. Monatlich veranstaltet\r\n- … & noch viel mehr!\r\n\r\n🏢Zum Unternehmen:\r\n[Berufsgruppe] freuen sich nicht nur auf diese Vorteile, sondern auch auf ein Unternehmen, das mit modernster Technik im [Branche] [Ergebnis erreicht]. Mit [Anzahl, z.B. 30] Mitarbeitern arbeiten wir gemeinsam an [Vision], um Menschen das [Ergebnis] zu erleichtern.\r\n",
          "title": "[Vorteile] Viele Vorteile",
          "id": 100012
        },
        {
          "text": "Hast du dir die Arbeit als Pflegekraft (m/w/d) anders vorgestellt? Bevor Mitarbeiter zu uns gewechselt, fragten sie sich das auch:\r\n\r\n💭 50-Stunden-Woche - “Mein Rücken leidet doch jetzt schon?” \r\n💭 Fehlende Wertschätzung - “Wofür die Nachtschichten, wenn nicht mal ein ‘Danke’ kommt?” \r\n💭 Stundenlohn - “Ich liebe meinen Beruf. Da geht echt nicht mehr?”\r\n\r\nEs wird Zeit für Veränderung und dass du deinen Job noch mehr lieben lernst. Wir von der [Muster GmbH] suchen nach neuen Kollegen, die ihre Expertise bei uns einbringen wollen.\r\n\r\nDafür bieten wir:\r\n\r\n[4-6 Benefits, nach der Formel: “Was” -> “Vorteil” -> “Bedeutung”]\r\n💳 Übertarifliche Bezahlung, für das dich andere beneiden werden.\r\n🙏🏻 Wertschätzung, die du verdienst. Deine Bemühungen werden belohnt\r\n📚 Bezahlte Weiterbildung, damit du deine Expertise in 1 Monat so schnell wächst wie bei anderen in 1 Jahr\r\n🏥 Beste Absicherung. Für deine gesamte Familie, sodass du nie wieder lange im Wartezimmer sitzen musst\r\n🎉 Endlich ein Team, mit du regelmäßig legendäre Teamevents feierst als wäre es dein engster Freundeskreis\r\n🔋 Maximale Energie. Flexible Arbeitszeiten, Massagen, Fitness und beste Verpflegung, damit du dein volles Potenzial entfalten kannst.\r\n\r\n... und noch viel mehr!\r\n",
          "title": "[Vorteile] Status-Quo brechen",
          "id": 100013
        },
        {
          "text": "Startend als Pflegekraft (m/w/d) bis hin zur Stationsleitung – die Vielfalt der Pflege und die Aufstiegschancen sind riesig. Ganz nach oben schaffst du es schon in wenigen Jahren! 👨‍💻💊💉\r\n\r\nIn der Pflege warten spannende Aufgaben und Themen, 30 Tage Urlaub, starkes Gehalt und ein eingeschweißtes Netzwerk, in dem du viele Gleichgesinnte finden wirst.\r\n",
          "title": "[Quiz] Von... bis...",
          "id": 100163
        },
        {
          "text": "[Visionäre Einleitung + Bedeutung für dich]\r\n\r\nPflegekräfte sind der Grund, warum unsere Welt noch nicht zusammenspricht. Sie helfen den Menschen, die sie am dringendsten brauchen. Und erhalten dafür unvorstellbare Dankbarkeit.\r\n\r\n[Vision von euch]\r\n\r\nUnsere Vision bei Muster GmbH:\r\n\r\nLass uns gemeinsam den Menschen die Fürsorge geben, die sie verdienen. Und damit Hamburg zu einem gesünderen und harmonischen Ort machen, sodass sich deine Bemühung herumspricht 🌍\r\n",
          "title": "[Quiz] Vision + Bedeutung der Tätigkeit",
          "id": 100164
        },
        {
          "text": "Welche Vorteile hat man als Azubi im Gesundheitswesen?\r\n\r\nViele! Wir bieten unseren Auszubildenden:\r\n[4-6 Benefits, nach der Formel: “Was” -> “Vorteil” -> “Bedeutung”]\r\n💳 Übertarifliche Bezahlung, für das dich andere beneiden werden.\r\n🙏🏻 Wertschätzung, die du verdienst. Deine Bemühungen werden belohnt\r\n📚 Bezahlte Weiterbildung, damit du deine Expertise in 1 Monat so schnell wächst wie bei anderen in 1 Jahr\r\n🏥 Beste Absicherung. Für deine gesamte Familie, sodass du nie wieder lange im Wartezimmer sitzen musst\r\n🎉 Endlich ein Team, mit du regelmäßig legendäre Teamevents feierst als wäre es dein engster Freundeskreis\r\n🔋 Maximale Energie. Flexible Arbeitszeiten, Massagen, Fitness und beste Verpflegung, damit du dein volles Potenzial entfalten kannst.\r\n... und noch viel mehr!\r\n",
          "title": "[Quiz] Vorteile",
          "id": 100165
        },
        {
          "text": "Wie sieht ein typischer Arbeitstag als Pflegekraft (m/w/d) aus?\r\n\r\n…\r\n…\r\n…\r\n…\r\n…\r\n\r\nDann ist der Tag zuende und du kannst mit guten Gewissen und [Vorteil] die Arbeitsstätte verlassen!\r\n",
          "title": "[Quiz] Typischer Arbeitstag",
          "id": 100166
        },
        {
          "text": "Was Max Mustermann über ihre Ausbildung zur Pflegekraft (m/w/d) sagt? „Es ist eine sehr schöne Arbeit, die viele Wege öffnet!“ \r\n\r\nDenn nach ihrer Ausbildung warten verschiedene Aufstiegsmöglichkeiten auf sie, neben einem guten Gehalt. \r\n",
          "title": "[Quiz] Mitarbeiter berichtet",
          "id": 100167
        },
        {
          "text": "[Vergangenheit von Mitarbeiter bis zur Gegenwart in unter 400 Zeichen]\r\n\r\nMaxi startete als Quereinsteiger. Mit 13 hatte er schon Interesse an… Ursprünglich hatte er … So fand er den Weg zur Muster GmbH.\r\n\r\n[Entwicklung beim Unternehmen, das man nachahmen möchte in unter 300 Zeichen]\r\n\r\nInnerhalb von nur 1,5 Jahren hat er sich hochgearbeitet und … Er verwaltet ein… und übernimmt Verantwortung für zahlreiche Kunden.\r\n\r\n[2-4 zeiliges Zitat des Mitarbeiters]\r\n\r\n“Ich hätte nie gedacht, dass mir die Muster GmbH von Anfang an so viel Verantwortung überträgt und sie mir die Möglichkeiten bieten, mich entfalten zu können. Hier angefangen zu haben, werde ich nie bereuen!”\r\n",
          "title": "[Story] Hero-Story",
          "id": 100279
        },
        {
          "text": "[Fragen, die interessante Geschichten hervorbringen]\r\n\r\nMuster GmbH: Wie sieht ein Arbeitstag bei Muster GmbH aus?\r\nMuster GmbH: Was war dein schönster Moment bei der Arbeit?\r\nMuster GmbH: Was würdest du [Berufsgruppe] empfehlen, die noch nicht bei [Muster GmbH] arbeiten?\r\n\r\n[Echte Zitate von einem Mitarbeiter. Mehr Mitarbeiter ergeben mehrere Anzeigen. Möglichst unter 600 Zeichen halten]\r\n\r\nMax Mustermann, [Berufsgruppe] bei [Muster GmbH]: “XYZ…”\r\n",
          "title": "[Story] Fragen-Antworten",
          "id": 100280
        },
        {
          "text": "[Firmenevent. Passend zum Bild. Unter 600 Zeichen]\r\n\r\n[Grund für das Event]\r\n\r\nEin Hoch auf ein 10 jähriges Bestehen von Muster GmbH. Eine ganze Bowlinghalle nur für uns, denn…\r\n\r\n[Erlebnis auf Firmenevent schildern]\r\nUnser Chef entpuppte sich als wahrer Bowlingchampion. Ein Strike nach dem anderen.\r\n\r\nAlle waren glücklich. Wie eine große Freundesgruppe, die an diesem Abend noch mehr zusammenschweißte… \r\n\r\n[1-Zeiler Zitate von Mitarbeitern]\r\n\r\n“Das beste Event dieses Jahres” - John Doe\r\n“Fühlt sich an wie eine Familie” - Maxime Musterfrau\r\n“Ich habe schon so viele schöne Erinnerungen machen dürfen” - Sabine Kraft",
          "title": "[Story] Erlebnis",
          "id": 100281
        }
      ]
    },
    {
      "category": "Handwerk",
      "content": [
        {
          "text": "Mehr als nur einen Job als Handwerker (m/w/d) gewünscht? Werde Teil einer zweiten Familie: [der Muster GmbH]\r\n\r\nWir bieten dir:\r\n➡️ [Brennendes Bedürfnis: z.B. Finanziell sorglos: Übertarifliche Vergütung. Betriebliche Krankenversicherung. Zusatzleistungen und 13. Gehalt.\r\n➡️ [Brennendes Bedürfnis: z.B. Zusammenhalt wie in einer Familie: freundliche, greifbare und strukturierte Vorgesetzte. Wir halten unser Wort!]\r\n➡️ [Brennendes Bedürfnis: z.B. Arbeit, die leicht fällt: Transparente Arbeitsorganisation, klare Regelungen, Zuständigkeiten und Verantwortlichkeiten!]\r\n\r\n... und noch so viele weitere Gründe, ein Teil unserer Familie zu werden. In [Voll- und Teilzeit]\r\n",
          "title": "[Vorteile] 3 brennende Bedürfnisse",
          "id": 100040
        },
        {
          "text": "[Visionäre Aussage: z.B. Lass uns gemeinsam die Welt verändern. Und [Mehrwert für die Kunden] für jeden zugänglich machen! 🌍] Weitere [Berufsgruppe] sind auf dieser Mission erwünscht. Klicke unten auf den Link und erfahre, was wir dir alles ermöglichen werden.",
          "title": "[Vorteile] Vision",
          "id": 100041
        },
        {
          "text": "Lass uns gemeinsam hochwertige Holzkunstwerte erschaffen, die in Erinnerung bleiben. Hausfassaden, an denen du deine Unterschrift hinterlässt und der gesamten Umgebung zeigst, was du mit deinen Händen getan hast ✋ [Unternehmensvision. Höherer Sinn. Die Bedeutung, die die Arbeit hat]\r\n\r\nWeitere Handwerker (m/w/d) sind immer erwünscht. Wir brauchen dich für unsere Mission!\r\n\r\nMit diesen Vorteilen kannst du rechnen:\r\n\r\n[4-6 Benefits, nach der Formel: “Was” -> “Vorteil” -> “Bedeutung”]\r\n💳 Übertarifliche Bezahlung, für das dich andere beneiden werden.\r\n📱 Reibungslose Abläufe durch App, sodass du immer weißt, was zutun ist\r\n📚 Bezahlte Weiterbildung, damit du deine Expertise in 1 Monat so schnell wächst wie bei anderen in 1 Jahr\r\n🏥 Beste Absicherung. Für deine gesamte Familie, sodass du nie wieder lange im Wartezimmer sitzen musst\r\n🎉 Endlich ein Team, mit du regelmäßig legendäre Teamevents feierst als wäre es dein engster Freundeskreis\r\n🔋 Maximale Energie. Flexible Arbeitszeiten, Massagen, Fitness und beste Verpflegung, damit du dein volles Potenzial entfalten kannst.\r\n\r\n… und noch viel mehr!\r\n",
          "title": "[Vorteile] Vision + Benefits",
          "id": 100042
        },
        {
          "text": "✅Deine Vorteile:\r\n\r\n[Benefits, die Bewerber fordern und nicht üblich sind. 6-10 nacheinander]\r\n\r\n- Übertarifliche Verdienstmöglichkeiten, inkl. Prämienmodell & Zusatzleistungen\r\n- Flexible Zeiteinteilung\r\n- Unbefristeter Arbeitsvertrag\r\n- Kostenlose Massagen\r\n- Bestens versichert. Gilt für deine gesamte Familie\r\n- Bezahlte Weiterbildungen: Bücher, Kurse, Veranstaltungen. Alles.\r\n- Neueste Dienstwagen. Auch zur privaten Nutzung.\r\n- Arbeiten, wo andere Urlaub machen\r\n- Spannende und abwechslungsreiche Projekte\r\n- Legendäre Teamevents. Monatlich veranstaltet\r\n- … & noch viel mehr!\r\n\r\n🏢Zum Unternehmen:\r\n[Berufsgruppe] freuen sich nicht nur auf diese Vorteile, sondern auch auf ein Unternehmen, das mit modernster Technik im [Branche] [Ergebnis erreicht]. Mit [Anzahl, z.B. 30] Mitarbeitern arbeiten wir gemeinsam an [Vision], um Menschen das [Ergebnis] zu erleichtern.\r\n",
          "title": "[Vorteile] Viele Vorteile",
          "id": 100043
        },
        {
          "text": "Hast du dir die Arbeit als Handwerker anders vorgestellt? Bevor Mitarbeiter zu uns gewechselt, fragten sie sich das auch:\r\n\r\n💭 Organisation - “Hat hier eigentlich irgendwer einen Plan” \r\n💭 Wertschätzung - “Vielleicht 1x im Monat. Geht doch nur ums Arbeiten?” \r\n💭 Stundenlohn - “Da geht echt nicht mehr?”\r\n\r\nEs wird Zeit für Veränderung und dass du deinen Job noch mehr lieben lernst. Wir von der [Muster GmbH] suchen nach neuen Kollegen, die ihre Expertise bei uns einbringen wollen.\r\n\r\nDafür bieten wir:\r\n\r\n[4-6 Benefits, nach der Formel: “Was” -> “Vorteil” -> “Bedeutung”]\r\n💳 Übertarifliche Bezahlung, für das dich andere beneiden werden.\r\n📱\r\n📚 Bezahlte Weiterbildung, damit du deine Expertise in 1 Monat so schnell wächst wie bei anderen in 1 Jahr\r\n🏥 Beste Absicherung. Für deine gesamte Familie, sodass du nie wieder lange im Wartezimmer sitzen musst\r\n🎉 Endlich ein Team, mit du regelmäßig legendäre Teamevents feierst als wäre es dein engster Freundeskreis\r\n🔋 Maximale Energie. Flexible Arbeitszeiten, Massagen, Fitness und beste Verpflegung, damit du dein volles Potenzial entfalten kannst.\r\n\r\n... und noch viel mehr!",
          "title": "[Vorteile] Status-Quo brechen",
          "id": 100044
        },
        {
          "text": "Startend als Handwerker (m/w/d) bis hin zum selbständigen Handwerksmeister – die Vielfalt im Handwerk und die Aufstiegschancen sind riesig. Ganz nach oben schaffst du es schon in wenigen Jahren! 👨‍💻🔨🔧\r\n\r\nIn der Handwerksbranche warten spannende Aufgaben und Themen, Sicherheit, 30 Tage Urlaub, starkes Gehalt und ein eingeschweißtes Netzwerk, in dem du viele Gleichgesinnte finden wirst.\r\n",
          "title": "[Quiz] Von... bis...",
          "id": 100187
        },
        {
          "text": "[Visionäre Einleitung + Bedeutung für dich]\r\n\r\nHandwerker sind der Grund, warum unsere Welt so ist, wie sie jetzt ist. Sie bauen, veredeln und reparieren Gebäude, Straßen und Dinge, die wir im Alltag benutzen. Und sind direkt am Fortschritt der Welt beteiligt.\r\n\r\n[Vision von euch]\r\nUnsere Version bei Muster GmbH:\r\n\r\n\r\nLass uns gemeinsam hochwertige Holzkunstwerte erschaffen, die in Erinnerung bleiben. Hausfassaden, an denen du deine Unterschrift hinterlässt und der gesamten Umgebung zeigst, was du mit deinen Händen getan hast ✋\r\n",
          "title": "[Quiz] Vision + Bedeutung der Tätigkeit",
          "id": 100188
        },
        {
          "text": "Welche Vorteile hat man als Azubi im Handwerk?\r\n\r\nViele! Wir bieten unseren Auszubildenden:\r\n[4-6 Benefits, nach der Formel: “Was” -> “Vorteil” -> “Bedeutung”]\r\n💳 Übertarifliche Bezahlung, für das dich andere beneiden werden.\r\n📱\r\n📚 Bezahlte Weiterbildung, damit du deine Expertise in 1 Monat so schnell wächst wie bei anderen in 1 Jahr\r\n🏥 Beste Absicherung. Für deine gesamte Familie, sodass du nie wieder lange im Wartezimmer sitzen musst\r\n🎉 Endlich ein Team, mit du regelmäßig legendäre Teamevents feierst als wäre es dein engster Freundeskreis\r\n🔋 Maximale Energie. Flexible Arbeitszeiten, Massagen, Fitness und beste Verpflegung, damit du dein volles Potenzial entfalten kannst.\r\n... und noch viel mehr!\r\n",
          "title": "[Quiz] Vorteile",
          "id": 100189
        },
        {
          "text": "Wie sieht ein typischer Arbeitstag als Handwerker (m/w/d) aus?\r\n\r\n…\r\n…\r\n…\r\n…\r\n…\r\n\r\nDann ist der Tag zuende und du kannst mit guten Gewissen und [Vorteil] die Arbeitsstätte verlassen!\r\n",
          "title": "[Quiz] Typischer Arbeitstag",
          "id": 100190
        },
        {
          "text": "Was Max Mustermann über ihre Ausbildung zum Handwerker sagt? „Es ist eine sehr schöne Arbeit, die viele Wege öffnet!“ \r\n\r\nDenn nach ihrer Ausbildung warten verschiedene Aufstiegsmöglichkeiten auf sie, neben einem guten Gehalt. \r\n",
          "title": "[Quiz] Mitarbeiter berichtet",
          "id": 100191
        },
        {
          "text": "[Vergangenheit von Mitarbeiter bis zur Gegenwart in unter 400 Zeichen]\r\n\r\nMaxi startete als Quereinsteiger. Mit 13 hatte er schon Interesse an… Ursprünglich hatte er … So fand er den Weg zur Muster GmbH.\r\n\r\n[Entwicklung beim Unternehmen, das man nachahmen möchte in unter 300 Zeichen]\r\n\r\nInnerhalb von nur 1,5 Jahren hat er sich hochgearbeitet und … Er verwaltet ein… und übernimmt Verantwortung für zahlreiche Kunden.\r\n\r\n[2-4 zeiliges Zitat des Mitarbeiters]\r\n\r\n“Ich hätte nie gedacht, dass mir die Muster GmbH von Anfang an so viel Verantwortung überträgt und sie mir die Möglichkeiten bieten, mich entfalten zu können. Hier angefangen zu haben, werde ich nie bereuen!”\r\n",
          "title": "[Story] Hero-Story",
          "id": 100303
        },
        {
          "text": "[Fragen, die interessante Geschichten hervorbringen]\r\n\r\nMuster GmbH: Wie sieht ein Arbeitstag bei Muster GmbH aus?\r\nMuster GmbH: Was war dein schönster Moment bei der Arbeit?\r\nMuster GmbH: Was würdest du [Berufsgruppe] empfehlen, die noch nicht bei [Muster GmbH] arbeiten?\r\n\r\n[Echte Zitate von einem Mitarbeiter. Mehr Mitarbeiter ergeben mehrere Anzeigen. Möglichst unter 600 Zeichen halten]\r\n\r\nMax Mustermann, [Berufsgruppe] bei [Muster GmbH]: “XYZ…”\r\n",
          "title": "[Story] Fragen-Antworten",
          "id": 100304
        },
        {
          "text": "[Firmenevent. Passend zum Bild. Unter 600 Zeichen]\r\n\r\n[Grund für das Event]\r\n\r\nEin Hoch auf ein 10 jähriges Bestehen von Muster GmbH. Eine ganze Bowlinghalle nur für uns, denn…\r\n\r\n[Erlebnis auf Firmenevent schildern]\r\nUnser Chef entpuppte sich als wahrer Bowlingchampion. Ein Strike nach dem anderen.\r\n\r\nAlle waren glücklich. Wie eine große Freundesgruppe, die an diesem Abend noch mehr zusammenschweißte… \r\n\r\n[1-Zeiler Zitate von Mitarbeitern]\r\n\r\n“Das beste Event dieses Jahres” - John Doe\r\n“Fühlt sich an wie eine Familie” - Maxime Musterfrau\r\n“Ich habe schon so viele schöne Erinnerungen machen dürfen” - Sabine Kraft",
          "title": "[Story] Erlebnis",
          "id": 100305
        }
      ]
    },
    {
      "category": "Handel & Vertrieb",
      "content": [
        {
          "text": "Mehr als nur einen Job als Kaufmann/frau (m/w/d) gewünscht? Werde Teil einer zweiten Familie: [der Muster GmbH]\r\n\r\nWir bieten dir:\r\n➡️ [Brennendes Bedürfnis: z.B. Finanziell sorglos: Übertarifliche Vergütung. Betriebliche Krankenversicherung. Zusatzleistungen und 13. Gehalt.\r\n➡️ [Brennendes Bedürfnis: z.B. Zusammenhalt wie in einer Familie: freundliche, greifbare und strukturierte Vorgesetzte. Wir halten unser Wort!]\r\n➡️ [Brennendes Bedürfnis: z.B. Guter Ruf bei Kunden: Menschen begegnen dich wie ein Freund und ringen darum, die Produkte zu kaufen]\r\n\r\n... und noch so viele weitere Gründe, ein Teil unserer Familie zu werden. In [Voll- und Teilzeit].",
          "title": "[Vorteile] 3 brennende Bedürfnisse",
          "id": 100071
        },
        {
          "text": "[Visionäre Aussage: z.B. Lass uns gemeinsam die Welt verändern. Und [Mehrwert für die Kunden] für jeden zugänglich machen! 🌍] Weitere [Berufsgruppe] sind auf dieser Mission erwünscht. Klicke unten auf den Link und erfahre, was wir dir alles ermöglichen werden.",
          "title": "[Vorteile] Vision",
          "id": 100072
        },
        {
          "text": "Lass uns gemeinsam die Parkplatzsituation in unserer Stadt verbessern. Eine Softwarelösung, die endlich den Parkhaus Widerstand bietet. Und du bist derjenige, der sie an den Mann oder die Frau bringen kann ✋ [Unternehmensvision. Höherer Sinn. Die Bedeutung, die die Arbeit hat]\r\n\r\nWeitere Kaufmänner/frauen (m/w/d) sind immer erwünscht. Wir brauchen dich für unsere Mission!\r\n\r\nMit diesen Vorteilen kannst du rechnen:\r\n\r\n[4-6 Benefits, nach der Formel: “Was” -> “Vorteil” -> “Bedeutung”]\r\n💳 Übertarifliche Bezahlung, für das dich andere beneiden werden.\r\n🤝 Kunden, die dich wie ein Freund ansehen\r\n📚 Bezahlte Weiterbildung, damit du deine Expertise in 1 Monat so schnell wächst wie bei anderen in 1 Jahr\r\n🏥 Beste Absicherung. Für deine gesamte Familie, sodass du nie wieder lange im Wartezimmer sitzen musst\r\n🎉 Endlich ein Team, mit du regelmäßig legendäre Teamevents feierst als wäre es dein engster Freundeskreis\r\n🔋 Maximale Energie. Flexible Arbeitszeiten, Massagen, Fitness und beste Verpflegung, damit du dein volles Potenzial entfalten kannst.\r\n\r\n... und noch viel mehr!\r\n",
          "title": "[Vorteile] Vision + Benefits",
          "id": 100073
        },
        {
          "text": "✅Deine Vorteile:\r\n\r\n[Benefits, die Bewerber fordern und nicht üblich sind. 6-10 nacheinander]\r\n\r\n- Übertarifliche Verdienstmöglichkeiten, inkl. Prämienmodell & Zusatzleistungen\r\n- Flexible Zeiteinteilung\r\n- Unbefristeter Arbeitsvertrag\r\n- Kostenlose Massagen\r\n- Bestens versichert. Gilt für deine gesamte Familie\r\n- Bezahlte Weiterbildungen: Bücher, Kurse, Veranstaltungen. Alles.\r\n- Neueste Dienstwagen. Auch zur privaten Nutzung.\r\n- Arbeiten, wo andere Urlaub machen\r\n- Spannende und abwechslungsreiche Projekte\r\n- Legendäre Teamevents. Monatlich veranstaltet\r\n- … & noch viel mehr!\r\n\r\n🏢Zum Unternehmen:\r\n[Berufsgruppe] freuen sich nicht nur auf diese Vorteile, sondern auch auf ein Unternehmen, das mit modernster Technik im [Branche] [Ergebnis erreicht]. Mit [Anzahl, z.B. 30] Mitarbeitern arbeiten wir gemeinsam an [Vision], um Menschen das [Ergebnis] zu erleichtern.\r\n",
          "title": "[Vorteile] Viele Vorteile",
          "id": 100074
        },
        {
          "text": "Hast du dir die Arbeit als Kaufmann/frau (m/w/d) anders vorgestellt? Bevor Mitarbeiter zu uns gewechselt, fragten sie sich das auch:\r\n\r\n💭 Schlechter Ruf - “Menschen wollen doch nichts verkauft bekommen?” \r\n💭 Wertschätzung - “Vielleicht 1x im Monat. Geht doch nur ums Arbeiten?” \r\n💭 Stundenlohn - “Da geht echt nicht mehr?”\r\n\r\nEs wird Zeit für Veränderung und dass du deinen Job noch mehr lieben lernst. Wir von der [Muster GmbH] suchen nach neuen Kollegen, die ihre Expertise bei uns einbringen wollen.\r\n\r\nDafür bieten wir:\r\n\r\n[4-6 Benefits, nach der Formel: “Was” -> “Vorteil” -> “Bedeutung”]\r\n💳 Übertarifliche Bezahlung, für das dich andere beneiden werden.\r\n🤝 Kunden, die dich wie ein Freund ansehen\r\n📚 Bezahlte Weiterbildung, damit du deine Expertise in 1 Monat so schnell wächst wie bei anderen in 1 Jahr\r\n🏥 Beste Absicherung. Für deine gesamte Familie, sodass du nie wieder lange im Wartezimmer sitzen musst\r\n🎉 Endlich ein Team, mit du regelmäßig legendäre Teamevents feierst als wäre es dein engster Freundeskreis\r\n🔋 Maximale Energie. Flexible Arbeitszeiten, Massagen, Fitness und beste Verpflegung, damit du dein volles Potenzial entfalten kannst.\r\n\r\n... und noch viel mehr!\r\n",
          "title": "[Vorteile] Status-Quo brechen",
          "id": 100075
        },
        {
          "text": "Startend als Kaufmann/frau (m/w/d) bis hin zum geschäftsführenden Filialleiter – die Vielfalt im kaufmännischen Bereich und die Aufstiegschancen sind riesig. Ganz nach oben schaffst du es schon in wenigen Jahren! 👨‍💻🔨🔧\r\n\r\nEs warten spannende Aufgaben und Themen, Sicherheit, 30 Tage Urlaub, starkes Gehalt und ein eingeschweißtes Netzwerk, in dem du viele Gleichgesinnte finden wirst.\r\n",
          "title": "[Quiz] Von... bis...",
          "id": 100211
        },
        {
          "text": "[Visionäre Einleitung + Bedeutung für dich]\r\n\r\nKaufmänner/frauen sind der Grund für unseren Wohlstand in Deutschland. Sie sind die Verbindung zwischen Mensch und Produkt. Dein analytisches Denken kann dafür sorgen, dass Unternehmen überleben.\r\n\r\n[Vision von euch]\r\nUnsere Version bei Muster GmbH:\r\n\r\n\r\nLass uns gemeinsam die Parkplatzsituation in unserer Stadt verbessern. Eine Softwarelösung, die endlich den Parkhaus Widerstand bietet. Und du bist derjenige, der sie an den Mann oder die Frau bringen kann ✋\r\n",
          "title": "[Quiz] Vision + Bedeutung der Tätigkeit",
          "id": 100212
        },
        {
          "text": "Welche Vorteile hat man als Azubi im kaufmännischen Bereich?\r\n\r\nViele! Wir bieten unseren Auszubildenden:\r\n[4-6 Benefits, nach der Formel: “Was” -> “Vorteil” -> “Bedeutung”]\r\n💳 Übertarifliche Bezahlung, für das dich andere beneiden werden.\r\n🤝 Kunden, die dich wie ein Freund ansehen\r\n📚 Bezahlte Weiterbildung, damit du deine Expertise in 1 Monat so schnell wächst wie bei anderen in 1 Jahr\r\n🏥 Beste Absicherung. Für deine gesamte Familie, sodass du nie wieder lange im Wartezimmer sitzen musst\r\n🎉 Endlich ein Team, mit du regelmäßig legendäre Teamevents feierst als wäre es dein engster Freundeskreis\r\n🔋 Maximale Energie. Flexible Arbeitszeiten, Massagen, Fitness und beste Verpflegung, damit du dein volles Potenzial entfalten kannst.\r\n... und noch viel mehr!\r\n",
          "title": "[Quiz] Vorteile",
          "id": 100213
        },
        {
          "text": "Wie sieht ein typischer Arbeitstag als Handwerker (m/w/d) aus?\r\n\r\n…\r\n…\r\n…\r\n…\r\n…\r\n\r\nDann ist der Tag zuende und du kannst mit guten Gewissen und [Vorteil] die Arbeitsstätte verlassen!\r\n",
          "title": "[Quiz] Typischer Arbeitstag",
          "id": 100214
        },
        {
          "text": "Was Max Mustermann über ihre Ausbildung zum Kaufmann sagt? „Es ist eine sehr schöne Arbeit, die viele Wege öffnet!“ \r\n\r\nDenn nach ihrer Ausbildung warten verschiedene Aufstiegsmöglichkeiten auf sie, neben einem guten Gehalt. \r\n",
          "title": "[Quiz] Mitarbeiter berichtet",
          "id": 100215
        },
        {
          "text": "[Vergangenheit von Mitarbeiter bis zur Gegenwart in unter 400 Zeichen]\r\n\r\nMaxi startete als Quereinsteiger. Mit 13 hatte er schon Interesse an… Ursprünglich hatte er … So fand er den Weg zur Muster GmbH.\r\n\r\n[Entwicklung beim Unternehmen, das man nachahmen möchte in unter 300 Zeichen]\r\n\r\nInnerhalb von nur 1,5 Jahren hat er sich hochgearbeitet und … Er verwaltet ein… und übernimmt Verantwortung für zahlreiche Kunden.\r\n\r\n[2-4 zeiliges Zitat des Mitarbeiters]\r\n\r\n“Ich hätte nie gedacht, dass mir die Muster GmbH von Anfang an so viel Verantwortung überträgt und sie mir die Möglichkeiten bieten, mich entfalten zu können. Hier angefangen zu haben, werde ich nie bereuen!”\r\n",
          "title": "[Story] Hero-Story",
          "id": 100327
        },
        {
          "text": "[Fragen, die interessante Geschichten hervorbringen]\r\n\r\nMuster GmbH: Wie sieht ein Arbeitstag bei Muster GmbH aus?\r\nMuster GmbH: Was war dein schönster Moment bei der Arbeit?\r\nMuster GmbH: Was würdest du [Berufsgruppe] empfehlen, die noch nicht bei [Muster GmbH] arbeiten?\r\n\r\n[Echte Zitate von einem Mitarbeiter. Mehr Mitarbeiter ergeben mehrere Anzeigen. Möglichst unter 600 Zeichen halten]\r\n\r\nMax Mustermann, [Berufsgruppe] bei [Muster GmbH]: “XYZ…”\r\n",
          "title": "[Story] Fragen-Antworten",
          "id": 100328
        },
        {
          "text": "[Firmenevent. Passend zum Bild. Unter 600 Zeichen]\r\n\r\n[Grund für das Event]\r\n\r\nEin Hoch auf ein 10 jähriges Bestehen von Muster GmbH. Eine ganze Bowlinghalle nur für uns, denn…\r\n\r\n[Erlebnis auf Firmenevent schildern]\r\nUnser Chef entpuppte sich als wahrer Bowlingchampion. Ein Strike nach dem anderen.\r\n\r\nAlle waren glücklich. Wie eine große Freundesgruppe, die an diesem Abend noch mehr zusammenschweißte… \r\n\r\n[1-Zeiler Zitate von Mitarbeitern]\r\n\r\n“Das beste Event dieses Jahres” - John Doe\r\n“Fühlt sich an wie eine Familie” - Maxime Musterfrau\r\n“Ich habe schon so viele schöne Erinnerungen machen dürfen” - Sabine Kraft",
          "title": "[Story] Erlebnis",
          "id": 100329
        }
      ]
    },
    {
      "category": "Gastronomie",
      "content": [
        {
          "text": "Mehr als nur einen Job als Koch (m/w/d) gewünscht? Werde Teil einer zweiten Familie: [der Muster GmbH]\r\n\r\nWir bieten dir:\r\n➡️ [Brennendes Bedürfnis: z.B. Finanziell sorglos: Übertarifliche Vergütung. Betriebliche Krankenversicherung. Zusatzleistungen und 13. Gehalt.\r\n➡️ [Brennendes Bedürfnis: z.B. Zusammenhalt wie in einer Familie: freundliche, greifbare und strukturierte Vorgesetzte. Wir halten unser Wort!]\r\n➡️ [Brennendes Bedürfnis: z.B. Dein Geschmack, deine Unterschrift: Nur mit den besten Zutaten die Stadt ein Lächeln ins Gesicht zaubern]\r\n\r\n... und noch so viele weitere Gründe, ein Teil unserer Familie zu werden. In [Voll- und Teilzeit].\r\n",
          "title": "[Vorteile] 3 brennende Bedürfnisse",
          "id": 100102
        },
        {
          "text": "[Visionäre Aussage: z.B. Lass uns gemeinsam die Welt verändern. Und [Mehrwert für die Kunden] für jeden zugänglich machen! 🌍] Weitere [Berufsgruppe] sind auf dieser Mission erwünscht. Klicke unten auf den Link und erfahre, was wir dir alles ermöglichen werden.",
          "title": "[Vorteile] Vision",
          "id": 100103
        },
        {
          "text": "Lass uns die Stadt das beste Essen aus der gesamten Umgebung bieten. Gerichte, die in Erinnerung bleiben - und deine Unterschrift tragen 👨‍🍳 [Unternehmensvision. Höherer Sinn. Die Bedeutung, die die Arbeit hat]\r\n\r\nWeitere Köche (m/w/d) sind immer erwünscht. Wir brauchen dich für unsere Mission!\r\n\r\nMit diesen Vorteilen kannst du rechnen:\r\n\r\n[4-6 Benefits, nach der Formel: “Was” -> “Vorteil” -> “Bedeutung”]\r\n💳 Übertarifliche Bezahlung, für das dich andere beneiden werden.\r\n🍳 Qualitative Lebensmittel, für die wir gerne mehr zahlen\r\n📚 Bezahlte Weiterbildung, damit du deine Expertise in 1 Monat so schnell wächst wie bei anderen in 1 Jahr\r\n🏥 Beste Absicherung. Für deine gesamte Familie, sodass du nie wieder lange im Wartezimmer sitzen musst\r\n🎉 Endlich ein Team, mit du regelmäßig legendäre Teamevents feierst als wäre es dein engster Freundeskreis\r\n🔋 Maximale Energie. Flexible Arbeitszeiten, Massagen, Fitness und beste Verpflegung, damit du dein volles Potenzial entfalten kannst.\r\n\r\n... und noch viel mehr!\r\n",
          "title": "[Vorteile] Vision + Benefits",
          "id": 100104
        },
        {
          "text": "✅Deine Vorteile:\r\n\r\n[Benefits, die Bewerber fordern und nicht üblich sind. 6-10 nacheinander]\r\n\r\n- Übertarifliche Verdienstmöglichkeiten, inkl. Prämienmodell & Zusatzleistungen\r\n- Flexible Zeiteinteilung\r\n- Unbefristeter Arbeitsvertrag\r\n- Kostenlose Massagen\r\n- Bestens versichert. Gilt für deine gesamte Familie\r\n- Bezahlte Weiterbildungen: Bücher, Kurse, Veranstaltungen. Alles.\r\n- Neueste Dienstwagen. Auch zur privaten Nutzung.\r\n- Arbeiten, wo andere Urlaub machen\r\n- Spannende und abwechslungsreiche Projekte\r\n- Legendäre Teamevents. Monatlich veranstaltet\r\n- … & noch viel mehr!\r\n\r\n🏢Zum Unternehmen:\r\n[Berufsgruppe] freuen sich nicht nur auf diese Vorteile, sondern auch auf ein Unternehmen, das mit modernster Technik im [Branche] [Ergebnis erreicht]. Mit [Anzahl, z.B. 30] Mitarbeitern arbeiten wir gemeinsam an [Vision], um Menschen das [Ergebnis] zu erleichtern.\r\n",
          "title": "[Vorteile] Viele Vorteile",
          "id": 100105
        },
        {
          "text": "Hast du dir die Arbeit als Koch (m/w/d) anders vorgestellt? Bevor Mitarbeiter zu uns gewechselt, fragten sie sich das auch:\r\n\r\n💭 50-Stunden-Woche - “Muss ich mich kaputt arbeiten?” \r\n💭 Billige Lebensmittel - “Und das soll ich dann servieren?” \r\n💭 Stundenlohn - “Da geht echt nicht mehr?”\r\n\r\nEs wird Zeit für Veränderung und dass du deinen Job noch mehr lieben lernst. Wir von der [Muster GmbH] suchen nach neuen Kollegen, die ihre Expertise bei uns einbringen wollen.\r\n\r\nDafür bieten wir:\r\n\r\n[4-6 Benefits, nach der Formel: “Was” -> “Vorteil” -> “Bedeutung”]\r\n💳 Übertarifliche Bezahlung, für das dich andere beneiden werden.\r\n🍳 Qualitative Lebensmittel, für die wir gerne mehr zahlen\r\n📚 Bezahlte Weiterbildung, damit du deine Expertise in 1 Monat so schnell wächst wie bei anderen in 1 Jahr\r\n🏥 Beste Absicherung. Für deine gesamte Familie, sodass du nie wieder lange im Wartezimmer sitzen musst\r\n🎉 Endlich ein Team, mit du regelmäßig legendäre Teamevents feierst als wäre es dein engster Freundeskreis\r\n🔋 Maximale Energie. Flexible Arbeitszeiten, Massagen, Fitness und beste Verpflegung, damit du dein volles Potenzial entfalten kannst.\r\n\r\n... und noch viel mehr!\r\n",
          "title": "[Vorteile] Status-Quo brechen",
          "id": 100106
        },
        {
          "text": "Startend als Koch (m/w/d) bis hin zum ausgezeichneten Sternekoch – die Vielfalt in der Gastronomie und die Aufstiegschancen sind riesig. Ganz nach oben schaffst du es schon in wenigen Jahren! 👨‍💻🔨🔧\r\n\r\nEs warten spannende Aufgaben und Themen, Sicherheit, 30 Tage Urlaub, starkes Gehalt und ein eingeschweißtes Netzwerk, in dem du viele Gleichgesinnte finden wirst.\r\n",
          "title": "[Quiz] Von... bis...",
          "id": 100235
        },
        {
          "text": "[Visionäre Einleitung + Bedeutung für dich]\r\n\r\nKöche zaubern uns. Sie machen schlechte Tage zu Guten. Ja, dein Geschmackssinn kann sogar Liebe entfachen, denn Liebe geht durch den Magen. Und diese Liebe kannst du verbreiten.\r\n\r\n[Vision von euch]\r\nUnsere Version bei Muster GmbH:\r\n\r\n\r\nLass uns die Stadt das beste Essen aus der gesamten Umgebung bieten. Gerichte, die in Erinnerung bleiben - und deine Unterschrift tragen 👨‍🍳\r\n",
          "title": "[Quiz] Vision + Bedeutung der Tätigkeit",
          "id": 100236
        },
        {
          "text": "Welche Vorteile hat man als Azubi in der Gastronomie?\r\n\r\nViele! Wir bieten unseren Auszubildenden:\r\n[4-6 Benefits, nach der Formel: “Was” -> “Vorteil” -> “Bedeutung”]\r\n💳 Übertarifliche Bezahlung, für das dich andere beneiden werden.\r\n🍳 Qualitative Lebensmittel, für die wir gerne mehr zahlen\r\n📚 Bezahlte Weiterbildung, damit du deine Expertise in 1 Monat so schnell wächst wie bei anderen in 1 Jahr\r\n🏥 Beste Absicherung. Für deine gesamte Familie, sodass du nie wieder lange im Wartezimmer sitzen musst\r\n🎉 Endlich ein Team, mit du regelmäßig legendäre Teamevents feierst als wäre es dein engster Freundeskreis\r\n🔋 Maximale Energie. Flexible Arbeitszeiten, Massagen, Fitness und beste Verpflegung, damit du dein volles Potenzial entfalten kannst.\r\n\r\n... und noch viel mehr!\r\n",
          "title": "[Quiz] Vorteile",
          "id": 100237
        },
        {
          "text": "Wie sieht ein typischer Arbeitstag als Handwerker (m/w/d) aus?\r\n\r\n…\r\n…\r\n…\r\n…\r\n…\r\n\r\nDann ist der Tag zuende und du kannst mit guten Gewissen und [Vorteil] die Arbeitsstätte verlassen!\r\n",
          "title": "[Quiz] Typischer Arbeitstag",
          "id": 100238
        },
        {
          "text": "Was Max Mustermann über ihre Ausbildung zum Koch sagt? „Es ist eine sehr schöne Arbeit, die viele Wege öffnet!“ \r\n\r\nDenn nach ihrer Ausbildung warten verschiedene Aufstiegsmöglichkeiten auf sie, neben einem guten Gehalt. \r\n",
          "title": "[Quiz] Mitarbeiter berichtet",
          "id": 100239
        },
        {
          "text": "[Vergangenheit von Mitarbeiter bis zur Gegenwart in unter 400 Zeichen]\r\n\r\nMaxi startete als Quereinsteiger. Mit 13 hatte er schon Interesse an… Ursprünglich hatte er … So fand er den Weg zur Muster GmbH.\r\n\r\n[Entwicklung beim Unternehmen, das man nachahmen möchte in unter 300 Zeichen]\r\n\r\nInnerhalb von nur 1,5 Jahren hat er sich hochgearbeitet und … Er verwaltet ein… und übernimmt Verantwortung für zahlreiche Kunden.\r\n\r\n[2-4 zeiliges Zitat des Mitarbeiters]\r\n\r\n“Ich hätte nie gedacht, dass mir die Muster GmbH von Anfang an so viel Verantwortung überträgt und sie mir die Möglichkeiten bieten, mich entfalten zu können. Hier angefangen zu haben, werde ich nie bereuen!”\r\n",
          "title": "[Story] Hero-Story",
          "id": 100351
        },
        {
          "text": "[Fragen, die interessante Geschichten hervorbringen]\r\n\r\nMuster GmbH: Wie sieht ein Arbeitstag bei Muster GmbH aus?\r\nMuster GmbH: Was war dein schönster Moment bei der Arbeit?\r\nMuster GmbH: Was würdest du [Berufsgruppe] empfehlen, die noch nicht bei [Muster GmbH] arbeiten?\r\n\r\n[Echte Zitate von einem Mitarbeiter. Mehr Mitarbeiter ergeben mehrere Anzeigen. Möglichst unter 600 Zeichen halten]\r\n\r\nMax Mustermann, [Berufsgruppe] bei [Muster GmbH]: “XYZ…”\r\n",
          "title": "[Story] Fragen-Antworten",
          "id": 100352
        },
        {
          "text": "[Firmenevent. Passend zum Bild. Unter 600 Zeichen]\r\n\r\n[Grund für das Event]\r\n\r\nEin Hoch auf ein 10 jähriges Bestehen von Muster GmbH. Eine ganze Bowlinghalle nur für uns, denn…\r\n\r\n[Erlebnis auf Firmenevent schildern]\r\nUnser Chef entpuppte sich als wahrer Bowlingchampion. Ein Strike nach dem anderen.\r\n\r\nAlle waren glücklich. Wie eine große Freundesgruppe, die an diesem Abend noch mehr zusammenschweißte… \r\n\r\n[1-Zeiler Zitate von Mitarbeitern]\r\n\r\n“Das beste Event dieses Jahres” - John Doe\r\n“Fühlt sich an wie eine Familie” - Maxime Musterfrau\r\n“Ich habe schon so viele schöne Erinnerungen machen dürfen” - Sabine Kraft",
          "title": "[Story] Erlebnis",
          "id": 100353
        }
      ]
    },
    {
      "category": "Industrie",
      "content": [
        {
          "text": "Wieso Staplerfahrer (m/w/d) zu uns wechseln? 🤔",
          "title": "[Vorteile] Warum wechseln sie zu uns?",
          "id": 100132
        },
        {
          "text": "Mehr als nur einen Job als Staplerfahrer (m/w/d) gewünscht? Werde Teil einer zweiten Familie: [der Muster GmbH]\r\n\r\nWir bieten dir:\r\n➡️ [Brennendes Bedürfnis: z.B. Finanziell sorglos: Übertarifliche Vergütung. Betriebliche Krankenversicherung. Zusatzleistungen und 13. Gehalt.\r\n➡️ [Brennendes Bedürfnis: z.B. Zusammenhalt wie in einer Familie: freundliche, greifbare und strukturierte Vorgesetzte. Wir halten unser Wort!]\r\n➡️ [Brennendes Bedürfnis: z.B. Wertschätzung, die du verdienst: Das wissen wir mit regelmäßigen Teamevents zu belohnen!]\r\n\r\n... und noch so viele weitere Gründe, ein Teil unserer Familie zu werden. In [Voll- und Teilzeit].\r\n",
          "title": "[Vorteile] 3 brennende Bedürfnisse",
          "id": 100133
        },
        {
          "text": "[Visionäre Aussage: z.B. Lass uns gemeinsam die Welt verändern. Und [Mehrwert für die Kunden] für jeden zugänglich machen! 🌍] Weitere [Berufsgruppe] sind auf dieser Mission erwünscht. Klicke unten auf den Link und erfahre, was wir dir alles ermöglichen werden.",
          "title": "[Vorteile] Vision",
          "id": 100134
        },
        {
          "text": "Staplerfahrer sind der Motor des Online-Handels. Ohne sie läuft kein Lager. Und auch deine Freunde hätten keine Pakete vor der Haustür. Wir wollen mit dir die Auslieferung noch schneller machen 📦 [Unternehmensvision. Höherer Sinn. Die Bedeutung, die die Arbeit hat]\r\n\r\nWeitere Staplerfahrer (m/w/d) sind immer erwünscht. Wir brauchen dich für unsere Mission!\r\n\r\nMit diesen Vorteilen kannst du rechnen:\r\n\r\n[5 Benefits, nach der Formel: “Was” -> “Vorteil” -> “Bedeutung”]\r\n💳 Übertarifliche Bezahlung, für das dich andere beneiden werden.\r\n📚 Bezahlte Weiterbildung, damit du deine Expertise in 1 Monat so schnell wächst wie bei anderen in 1 Jahr\r\n🏥 Beste Absicherung. Für deine gesamte Familie, sodass du nie wieder lange im Wartezimmer sitzen musst\r\n🎉 Endlich ein Team, mit du regelmäßig legendäre Teamevents feierst als wäre es dein engster Freundeskreis\r\n🔋 Maximale Energie. Flexible Arbeitszeiten, Massagen, Fitness und beste Verpflegung, damit du dein volles Potenzial entfalten kannst.\r\n",
          "title": "[Vorteile] Vision + Benefits",
          "id": 100135
        },
        {
          "text": "✅Deine Vorteile:\r\n\r\n[Benefits, die Bewerber fordern und nicht üblich sind. 6-10 nacheinander]\r\n\r\n- Übertarifliche Verdienstmöglichkeiten, inkl. Prämienmodell & Zusatzleistungen\r\n- Flexible Zeiteinteilung\r\n- Unbefristeter Arbeitsvertrag\r\n- Kostenlose Massagen\r\n- Bestens versichert. Gilt für deine gesamte Familie\r\n- Bezahlte Weiterbildungen: Bücher, Kurse, Veranstaltungen. Alles.\r\n- Neueste Dienstwagen. Auch zur privaten Nutzung.\r\n- Arbeiten, wo andere Urlaub machen\r\n- Spannende und abwechslungsreiche Projekte\r\n- Legendäre Teamevents. Monatlich veranstaltet\r\n- … & noch viel mehr!\r\n\r\n🏢Zum Unternehmen:\r\n[Berufsgruppe] freuen sich nicht nur auf diese Vorteile, sondern auch auf ein Unternehmen, das mit modernster Technik im [Branche] [Ergebnis erreicht]. Mit [Anzahl, z.B. 30] Mitarbeitern arbeiten wir gemeinsam an [Vision], um Menschen das [Ergebnis] zu erleichtern.\r\n",
          "title": "[Vorteile] Viele Vorteile",
          "id": 100136
        },
        {
          "text": "Hast du dir die Arbeit als [Berufsgruppe] anders vorgestellt? Bevor Mitarbeiter zu uns gewechselt, fragten sie sich das auch:\r\n\r\n💭 Kaputte Geräte - “Und damit soll ich arbeiten?” \r\n💭 Wertschätzung - “Vielleicht 1x im Monat. Geht doch nur ums Arbeiten?” \r\n💭 Stundenlohn - “Da geht echt nicht mehr?”\r\n\r\nEs wird Zeit für Veränderung und dass du deinen Job noch mehr lieben lernst. Wir von der [Muster GmbH] suchen nach neuen Kollegen, die ihre Expertise bei uns einbringen wollen.\r\n\r\nDafür bieten wir:\r\n\r\n[5 Benefits, nach der Formel: “Was” -> “Vorteil” -> “Bedeutung”]\r\n💳 Übertarifliche Bezahlung, für das dich andere beneiden werden.\r\n🔨 Werkzeuge und Fahrzeuge, die gefühlt noch nie benutzt wurden\r\n📚 Bezahlte Weiterbildung, damit du deine Expertise in 1 Monat so schnell wächst wie bei anderen in 1 Jahr\r\n🏥 Beste Absicherung. Für deine gesamte Familie, sodass du nie wieder lange im Wartezimmer sitzen musst\r\n🎉 Endlich ein Team, mit du regelmäßig legendäre Teamevents feierst als wäre es dein engster Freundeskreis\r\n🔋 Maximale Energie. Flexible Arbeitszeiten, Massagen, Fitness und beste Verpflegung, damit du dein volles Potenzial entfalten kannst.\r\n\r\n... und noch viel mehr!\r\n",
          "title": "[Vorteile] Status-Quo brechen",
          "id": 100137
        },
        {
          "text": "Startend als Staplerfahrer (m/w/d) bis hin zur Lagerleitung – die Vielfalt in der Industrie als Staplerfahrer (m/w/d) und die Aufstiegschancen sind riesig. Ganz nach oben schaffst du es schon in wenigen Jahren! 👨‍💻🔨🔧\r\n\r\nEs warten spannende Aufgaben und Themen, Sicherheit, 30 Tage Urlaub, starkes Gehalt und ein eingeschweißtes Netzwerk, in dem du viele Gleichgesinnte finden wirst.\r\n",
          "title": "[Quiz] Von... bis...",
          "id": 100259
        },
        {
          "text": "[Visionäre Einleitung + Bedeutung für dich]\r\n\r\nStaplerfahrer halten der Welt den Rücken frei. Sie verwalten, organisieren und transportieren Güter, die für den Fortschritt der Welt erforderlich sind. Eine große Verantwortung, denn ohne sie würde hier gar nichts mehr laufen.\r\n\r\n[Vision von euch]\r\nUnsere Version bei Muster GmbH:\r\n\r\nStaplerfahrer sind der Motor des Online-Handels. Und auch deine Freunde hätten keine Pakete vor der Haustür. Wir wollen mit dir die Auslieferung noch schneller machen 📦\r\n",
          "title": "[Quiz] Vision + Bedeutung der Tätigkeit",
          "id": 100260
        },
        {
          "text": "Welche Vorteile hat man als Azubi in der Industrie?\r\n\r\nViele! Wir bieten unseren Auszubildenden:\r\n[5 Benefits, nach der Formel: “Was” -> “Vorteil” -> “Bedeutung”]\r\n💳 Übertarifliche Bezahlung, für das dich andere beneiden werden.\r\n🔨 Werkzeuge und Fahrzeuge, die gefühlt noch nie benutzt wurden\r\n📚 Bezahlte Weiterbildung, damit du deine Expertise in 1 Monat so schnell wächst wie bei anderen in 1 Jahr\r\n🏥 Beste Absicherung. Für deine gesamte Familie, sodass du nie wieder lange im Wartezimmer sitzen musst\r\n🎉 Endlich ein Team, mit du regelmäßig legendäre Teamevents feierst als wäre es dein engster Freundeskreis\r\n🔋 Maximale Energie. Flexible Arbeitszeiten, Massagen, Fitness und beste Verpflegung, damit du dein volles Potenzial entfalten kannst.\r\n\r\n... und noch viel mehr!\r\n",
          "title": "[Quiz] Vorteile",
          "id": 100261
        },
        {
          "text": "Wie sieht ein typischer Arbeitstag als Handwerker (m/w/d) aus?\r\n\r\n…\r\n…\r\n…\r\n…\r\n…\r\n\r\nDann ist der Tag zuende und du kannst mit guten Gewissen und [Vorteil] die Arbeitsstätte verlassen!\r\n",
          "title": "[Quiz] Typischer Arbeitstag",
          "id": 100262
        },
        {
          "text": "Was Max Mustermann über ihre Ausbildung zum Staplerfahrer sagt? „Es ist eine sehr schöne Arbeit, die viele Wege öffnet!“ \r\n\r\nDenn nach ihrer Ausbildung warten verschiedene Aufstiegsmöglichkeiten auf sie, neben einem guten Gehalt. \r\n",
          "title": "[Quiz] Mitarbeiter berichtet",
          "id": 100263
        },
        {
          "text": "[Vergangenheit von Mitarbeiter bis zur Gegenwart in unter 400 Zeichen]\r\n\r\nMaxi startete als Quereinsteiger. Mit 13 hatte er schon Interesse an… Ursprünglich hatte er … So fand er den Weg zur Muster GmbH.\r\n\r\n[Entwicklung beim Unternehmen, das man nachahmen möchte in unter 300 Zeichen]\r\n\r\nInnerhalb von nur 1,5 Jahren hat er sich hochgearbeitet und … Er verwaltet ein… und übernimmt Verantwortung für zahlreiche Kunden.\r\n\r\n[2-4 zeiliges Zitat des Mitarbeiters]\r\n\r\n“Ich hätte nie gedacht, dass mir die Muster GmbH von Anfang an so viel Verantwortung überträgt und sie mir die Möglichkeiten bieten, mich entfalten zu können. Hier angefangen zu haben, werde ich nie bereuen!”\r\n",
          "title": "[Story] Hero-Story",
          "id": 100375
        },
        {
          "text": "[Fragen, die interessante Geschichten hervorbringen]\r\n\r\nMuster GmbH: Wie sieht ein Arbeitstag bei Muster GmbH aus?\r\nMuster GmbH: Was war dein schönster Moment bei der Arbeit?\r\nMuster GmbH: Was würdest du [Berufsgruppe] empfehlen, die noch nicht bei [Muster GmbH] arbeiten?\r\n\r\n[Echte Zitate von einem Mitarbeiter. Mehr Mitarbeiter ergeben mehrere Anzeigen. Möglichst unter 600 Zeichen halten]\r\n\r\nMax Mustermann, [Berufsgruppe] bei [Muster GmbH]: “XYZ…”\r\n",
          "title": "[Story] Fragen-Antworten",
          "id": 100376
        },
        {
          "text": "[Firmenevent. Passend zum Bild. Unter 600 Zeichen]\r\n\r\n[Grund für das Event]\r\n\r\nEin Hoch auf ein 10 jähriges Bestehen von Muster GmbH. Eine ganze Bowlinghalle nur für uns, denn…\r\n\r\n[Erlebnis auf Firmenevent schildern]\r\nUnser Chef entpuppte sich als wahrer Bowlingchampion. Ein Strike nach dem anderen.\r\n\r\nAlle waren glücklich. Wie eine große Freundesgruppe, die an diesem Abend noch mehr zusammenschweißte… \r\n\r\n[1-Zeiler Zitate von Mitarbeitern]\r\n\r\n“Das beste Event dieses Jahres” - John Doe\r\n“Fühlt sich an wie eine Familie” - Maxime Musterfrau\r\n“Ich habe schon so viele schöne Erinnerungen machen dürfen” - Sabine Kraft",
          "title": "[Story] Erlebnis",
          "id": 100377
        }
      ]
    }
  ]
};

export default mainTextData;
