import gql from 'graphql-tag';

export interface SetPageNameAPIInput {
  id: number;
  name: string;
}

export const SET_PAGE_NAME = gql`
  mutation setPageName($input: SetPageName!) {
    setPageName(input: $input) {
      id
      name
    }
  }
`;

export default function setPageNameAPI(input: SetPageNameAPIInput) {
  return window.apolloClient.mutate({
    mutation: SET_PAGE_NAME,
    variables: { input }
  });
}
