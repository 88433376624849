import { useQuery } from '@apollo/react-hooks';
import React, { useEffect } from 'react';
import { GET_DASHBOARD_INFO } from '../utils/graphqlQueries';

export default function InitializeUpvoty() {
  const { data } = useQuery(GET_DASHBOARD_INFO);
  const { getUser: userData } = data || {};
  useEffect(() => {
    if ((window as any)?.upvoty && userData) {
      const { id, email, firstName, lastName } = userData;
      let upvotyUserAuthScript: any;
      upvotyUserAuthScript = document.createElement('script');
      upvotyUserAuthScript.setAttribute('type', 'text/javascript');
      upvotyUserAuthScript.innerHTML = `(function(){upvoty.init('identify', {
        user: {
            id: '${String(id)}',
            name: '${firstName + ' ' + lastName}',
            email: '${email}',
        },
        baseUrl: 'roadmap.meetovo.de',
        publicKey: 'd0fae535787b608b83a16580e6c60b87',
    });})();`;
      document.head.appendChild(upvotyUserAuthScript);
      return () => {
        if (upvotyUserAuthScript) {
          document.head.removeChild(upvotyUserAuthScript);
        }
      };
    }
  }, [userData]);
  return null;
}
