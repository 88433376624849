import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const REMOVE_USER_COLOR_THEME = gql`
  mutation removeUserColorTheme($id: Int!) {
    removeUserColorTheme(id: $id)
  }
`;

export default function deleteUserFunnelTheme() {
  return useMutation(REMOVE_USER_COLOR_THEME);
}

export function deleteUserFunnelThemeApi(id: number) {
  return window.apolloClient.mutate({
    mutation: REMOVE_USER_COLOR_THEME,
    variables: { id }
  });
}
