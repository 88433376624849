import { FUNNEL_THEME_KEYS } from '../../../interfaces/builderSliceTypes';
import { ContainerPaddingClasses } from '../Container/interfaces';

export const DividerComponentProps = {
  dividerColor: FUNNEL_THEME_KEYS.ACCENT_COLOR,
  showLine: true,
  paddingTopClass: ContainerPaddingClasses.CONTAINER_PADDING_M_TOP,
  paddingBottomClass: ContainerPaddingClasses.CONTAINER_PADDING_M_BOTTOM,
  backgroundColor: FUNNEL_THEME_KEYS.BACKGROUND_COLOR
};
