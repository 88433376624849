import React from 'react';
import { useDispatch } from 'react-redux';
import {
  setCurrentlyEditingTheme,
  setIsEditingColorTheme,
  useIsEditingColorTheme,
  useFunnelTheme
} from '../../redux/builderSlice';
import { FunnelThemeType } from '../../interfaces/builderSliceTypes';
import EditFunnelTheme from '../elements/ColorTheme/EditFunnelTheme';
import { saveCompleteBuilderDataThunk } from '../../redux/thunk';
import PreDefinedColorThemesListing from '../elements/ColorTheme/PreDefinedColorThemesListing';
import UserColorThemeListing from '../elements/ColorTheme/UserColorThemeListing';
import AppliedColorThemeActions from '../elements/ColorTheme/AppliedColorThemeActions';
import { saveWidgetBuilderStateThunk } from '../../WidgetsBuilder/redux/thunk';
import { isWidgetBuilderPath } from '../../WidgetsBuilder/helper/helper';

const BuilderSettingsColor = () => {
  const dispatch = useDispatch();

  const funnelTheme = useFunnelTheme();
  const isEditingColorTheme = useIsEditingColorTheme();

  const setCurrentlyEditingThemeRedux = (theme: FunnelThemeType) => {
    dispatch(setCurrentlyEditingTheme(theme));
  };

  const applyColorTheme = async (colorTheme: FunnelThemeType) => {
    const newColorTheme = {
      ...colorTheme,
      fontFamily: funnelTheme.fontFamily,
      fontLink: funnelTheme.fontLink,
      id: funnelTheme.id,
      funnelId: funnelTheme.funnelId
    };
    setCurrentlyEditingThemeRedux(newColorTheme);
    if (isWidgetBuilderPath()) {
      await dispatch(saveWidgetBuilderStateThunk());
    } else {
      await dispatch(saveCompleteBuilderDataThunk());
    }
  };

  return (
    <div className="builder__settings-sidebar__container colors">
      {isEditingColorTheme ? (
        <EditFunnelTheme
          handleToggle={() => dispatch(setIsEditingColorTheme(!isEditingColorTheme))}
        />
      ) : (
        <>
          <AppliedColorThemeActions />
          <UserColorThemeListing applyColorTheme={applyColorTheme} />
          <PreDefinedColorThemesListing applyColorTheme={applyColorTheme} />
        </>
      )}
    </div>
  );
};

export default BuilderSettingsColor;
