import React from 'react';
import {
  IconProvider
  // @ts-ignore
} from 'meetovo-frontend-booking';

interface PlayCircleInterface {
  className: string;
  onClick(): any;
  isPlaying: boolean;
}

export default function PlayCircle({ className, onClick, isPlaying }: PlayCircleInterface) {
  return (
    <div
      className={`${className} play-circle ${isPlaying ? 'is-playing' : ''}`}
      onClick={() => onClick && onClick()}
    >
      {!isPlaying ? <IconProvider name="FaPlay" /> : <IconProvider name="FaPause" />}
    </div>
  );
}
