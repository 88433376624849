const jobTeaserTextData = {
  title: 'Teaser',
  data: [
    {
      category: 'Gesundheitswesen',
      content: [
        {
          text:'Gesucht: examinierte als [z.B. Pflegefachkräfte (m/w/d)] im Bereich [KATEGORIE] mit Herz',
          title: 'Berufsgruppe + Emoji',
          id: 100000
        },
        {
          text:'Du kannst gut anpacken und mit Verantwortung umgehen? Dann haben wir etwas für dich im Bereich [KATEGORIE]!',
          title: 'Kannst du gut...?',
          id: 100001
        },
        {
          text:'Als [z.B. Pflegefachkraft (m/w/d)] liebst du es, mit Menschen zu arbeiten und hast das Herz am rechten Fleck? Super, genau DICH suchen wir im Bereich [KATEGORIE]!',
          title: 'Liebst du...?',
          id: 100002
        },
        {
          text: `“Das Team der [UNTERNEHMENSNAME] ist inzwischen meine zweite Familie, inzwischen freue ich mich auf Montagmorgen!" - Maxime Musterfrau, [z.B. Pflegekraft]\n\nDu willst das auch sagen können? Jetzt als [z.B. Pflegekraft (m/w/d)] im Bereich [KATEGORIE] in [BESCHAEFTIGUNGSART] in [STANDORT] bewerben!`,
          title: 'Mitarbeiterzitat',
          id: 100003
        },
        {
          text: `[z.B. Pflegekräfte (m/w/d)] und Kunden lieben [UNTERNEHMENSNAME]\nDavon willst du dich selbst überzeugen? Dann lies jetzt weiter...`,
          title: '... dann lies jetzt weiter!',
          id: 100004
        },
        {
          text:'[z.B. Pflegekräfte (m/w/d)] im Bereich [KATEGORIE] in [STANDORT] und Umgebung aufgepasst!',
          title: 'Aufgepasst!',
          id: 100005
        },
        {
          text:'Harte Arbeit als [z.B. Pflegekraft (m/w/d)] im Bereich [KATEGORIE]? Kostenlose Massagen warten auf dich nach der Arbeit! [oder anderes unübliches Benefit]',
          title: 'Dein Benefit wartet',
          id: 100006
        },
        {
          text:'Jetzt Wechselprämie von xxxx€ sichern als [z.B. Pflegekraft] im Bereich [KATEGORIE]!',
          title: 'Wechselprämie',
          id: 100007
        },
        {
          text: 'Wieso [z.B. Pflegekräfte (m/w/d)] im Bereich [KATEGORIE] zu uns wechseln?',
          title: 'Warum wechseln sie zu uns?',
          id: 100008
        },
        {
          text:'Du suchst die perfekte Ausbildung als [z.B. Pflegekraft (m/w/d)] im Bereich [KATEGORIE] in deiner Nähe in [STANDORT]?',
          title: 'Ausbildung in der Nähe?',
          id: 100155
        },
        {
          text:'Du kannst gut anpacken und mit Verantwortung umgehen? Dann starte jetzt als [z.B. Azubi oder Quereinsteiger] / [BESCHAEFTIGUNGSART] im Bereich [KATEGORIE]',
          title: 'Kannst du gut...?',
          id: 100156
        },
        {
          text: `Du liebst es, mit Menschen zu arbeiten und hast das Herz am rechten Fleck? Super, dann hast du die perfekten Voraussetzungen als [z.B. Pflegekraft (m/w/d)] im Bereich [KATEGORIE]`,
          title: 'Liebst du...?',
          id: 100157
        },
        {
          text: `[Emotionaler 2-Zeiler eines Azubis: z.B. Das Team der Muster Gmbh ist inzwischen meine zweite Familie, inzwischen freue ich mich auf Montagmorgen. Die beste Entscheidung, hier meine Ausbildung gestartet zu haben!"]`,
          title: 'Mitarbeiterzitat',
          id: 100158
        },
        {
          text:'Warum [z.B. Pflegekräfte (m/w/d)] den besten Start in das Arbeitsleben haben. Lies jetzt weiter...',
          title: 'Bester Start ins Arbeitsleben',
          id: 100159
        },
        {
          text: 'Finde heraus, ob du Karriere als [z.B. Pflegekraft (m/w/d)] machen kannst!',
          title: 'Finde heraus...',
          id: 100160
        },
        {
          text: '[z.B. 5] Gründe, warum [z.B. Pflegekräfte (m/w/d)] den besten Job haben!',
          title: 'Gründe, warum...',
          id: 100161
        },
        {
          text:'Du willst als [z.B. Pflegekraft (m/w/d)] arbeiten, aber dir fehlt die Vorstellung, wie das abläuft? Du bist Quereinsteiger, Anfänger oder Wiedereinsteiger? Kein Problem!',
          title: 'Fehlende Vorstellung',
          id: 100162
        }
      ]
    },
    {
      category: 'Handwerk',
      content: [
        {
          text: 'Gesucht: [z.B. Handwerker (m/w/d)] im Bereich [KATEGORIE] mit Power',
          title: 'Berufsgruppe + Emoji',
          id: 100031
        },
        {
          text:'Du kannst gut anpacken und Staub macht dir nichts aus? Dann haben wir etwas für dich im Bereich [KATEGORIE]!',
          title: 'Kannst du gut...?',
          id: 100032
        },
        {
          text:'Als Handwerker (m/w/d) liebst du es, dich auszupowern und bist gerne dabei, wenn etwas Großartiges gebaut wird? Top, genau DICH suchen wir!',
          title: 'Liebst du...?',
          id: 100033
        },
        {
          text: `“Das Team der [UNTERNEHMENSNAME] ist inzwischen meine zweite Familie, inzwischen freue ich mich auf Montagmorgen!" - Max Mustermann, [z.B. Handwerker]\n\nDu willst das auch sagen können? Jetzt als [z.B. Handwerker] (m/w/d) in [BESCHAEFTIGUNGSART] in [STANDORT] im Bereich [KATEGORIE] bewerben!`,
          title: '... dann lies jetzt weiter!',
          id: 100034
        },
        {
          text: '[z.B. Handwerker (m/w/d)] in [STANDORT] und Umgebung aufgepasst!',
          title: 'Aufgepasst!',
          id: 100035
        },
        {
          text: `Den Tag über körperlich als [z.B. Handwerker (m/w/d)] im Bereich [KATEGORIE] zu arbeiten ist angestrengt? Kostenlose Massagen warten auf dich nach der Arbeit! [oder anderes unübliches Benefit]`,
          title: 'Dein Benefit wartet',
          id: 100036
        },
        {
          text:'Jetzt Wechselprämie von xxxx€ sichern als [z.B. Handwerker] im Bereich [KATEGORIE]!',
          title: 'Wechselprämie',
          id: 100037
        },
        {
          text: 'Wieso [z.B. Handwerker] im Bereich [KATEGORIE] zu uns wechseln?',
          title: 'Warum wechseln sie zu uns?',
          id: 100038
        },
        {
          text:'Du suchst die perfekte Ausbildung als [z.B. Handwerker (m/w/d)] im Bereich [KATEGORIE] in deiner Nähe?',
          title: 'Ausbildung in der Nähe?',
          id: 100039
        },
        {
          text:'Du kannst gut anpacken und Staub macht dir nichts aus? Dann starte jetzt als [z.B. Azubi oder Quereinsteiger] im [KATEGORIE]',
          title: 'Kannst du gut...?',
          id: 100179
        },
        {
          text:'Du liebst es, dich auszupowern und bist gerne dabei, wenn etwas Großartiges gebaut wird? Super, dann hast du die perfekten Voraussetzungen als Handwerker (m/w/d) im Bereich [KATEGORIE]',
          title: 'Liebst du...?',
          id: 100180
        },
        {
          text:'[Emotionaler 2-Zeiler eines Azubis: z.B. Das Team der Muster Gmbh ist inzwischen meine zweite Familie, inzwischen freue ich mich auf Montagmorgen. Die beste Entscheidung, hier meine Ausbildung gestartet zu haben!"]',
          title: 'Mitarbeiterzitat',
          id: 100181
        },
        {
          text:'Warum [z.B. Handwerker (m/w/d)] im Bereich [KATEGORIE] den besten Start in das Arbeitsleben haben. Lies jetzt weiter...',
          title: 'Bester Start ins Arbeitsleben',
          id: 100182
        },
        {
          text:'Finde heraus, ob du Karriere als [z.B. Handwerker (m/w/d)] im Bereich [KATEGORIE] machen kannst!',
          title: 'Finde heraus...',
          id: 100183
        },
        {
          text:'[z.B. 5] Gründe, warum Handwerker (m/w/d) im Bereich [KATEGORIE] den besten Job haben!',
          title: 'Gründe, warum...',
          id: 100184
        },
        {
          text:'Du willst als Handwerker (m/w/d) im Bereich [KATEGORIE] arbeiten, aber dir fehlt die Vorstellung, wie das abläuft? Du bist Quereinsteiger, Anfänger oder Wiedereinsteiger? Kein Problem!',
          title: 'Fehlende Vorstellung',
          id: 100185
        }
      ]
    },
    {
      "category": "Handel & Vertrieb",
      "content": [
        {
          "text": "Gesucht: [z.B. Kaufmänner/frauen] im Bereich [KATEGORIE] mit Gespür für Zahlen!",
          "title": "Berufsgruppe + Emoji",
          "id": 100076
        },
        {
          "text": "Du kannst gut mit Zahlen jonglieren und dir Dinge merken? Dann haben wir etwas für dich im Bereich [KATEGORIE]!",
          "title": "Kannst du gut...?",
          "id": 100077
        },
        {
          "text": "Als [z.B. Kaufmann/frau] liebst du es, Preise zu kalkulieren und analytisches Denken fällt dir leicht? Top, genau DICH suchen wir im Bereich [KATEGORIE]!",
          "title": "Liebst du...?",
          "id": 100078
        },
        {
          "text": `“Das Team der [UNTERNEHMENSNAME] ist inzwischen meine zweite Familie, inzwischen freue ich mich auf Montagmorgen!" - Max Mustermann, [z.B. Kaufmann]\n\nDu willst das auch sagen können? Jetzt als [z.B. Kaufmann/frau] in [BESCHAEFTIGUNGSART] in [STANDORT] im Bereich [KATEGORIE] bewerben!`,
          "title": "Mitarbeiterzitat",
          "id": 100079
        },
        {
          "text": `[z.B. Kaufleute] im Bereich [KATEGORIE] und Kunden lieben [UNTERNEHMENSNAME]\nDavon willst du dich selbst überzeugen? Dann lies jetzt weiter...`,
          "title": "... dann lies jetzt weiter!",
          "id": 100080
        },
        {
          "text": "[z.B. Kaufmann/frau] im Bereich [KATEGORIE] in [STANDORT] und Umgebung aufgepasst!",
          "title": "Aufgepasst!",
          "id": 100216
        },
        {
          "text": "Den ganzen Tag den Kopf im Bereich [KATEGORIE] angestrengt? Kostenlose Massagen warten auf dich nach der Arbeit! [oder anderes unübliches Benefit]",
          "title": "Dein Benefit wartet",
          "id": 100217
        },
        {
          "text": "Jetzt Wechselprämie von xxxx€ als [z.B. Kaufmann/frau] im Bereich [KATEGORIE] sichern!",
          "title": "Wechselprämie",
          "id": 100218
        },
        {
          "text": "Wieso [z.B. Kaufleute] im Bereich [KATEGORIE] zu uns wechseln?",
          "title": "Warum wechseln sie zu uns?",
          "id": 100219
        },
        {
          "text": "Du suchst die perfekte Ausbildung als [z.B. Kaufmann/frau (m/w/d)] im Bereich [KATEGORIE] in deiner Nähe?",
          "title": "Ausbildung in der Nähe?",
          "id": 100220
        },
        {
          "text": "Du kannst gut mit Zahlen jonglieren und dir Dinge merken? Dann starte jetzt als [z.B. Azubi oder Quereinsteiger] im Bereich [KATEGORIE]",
          "title": "Kannst du gut...?",
          "id": 100330
        },
        {
          "text": "Du liebst es, Preise zu kalkulieren und analytisches Denken fällt dir leicht? Super, dann hast du die perfekten Voraussetzungen als [z.B. Kaufmann/frau (m/w/d)] im Bereich [KATEGORIE]",
          "title": "Liebst du...?",
          "id": 100331
        },
        {
          "text": "[Emotionaler 2-Zeiler eines Azubis: z.B. Das Team der Muster Gmbh ist inzwischen meine zweite Familie, inzwischen freue ich mich auf Montagmorgen. Die beste Entscheidung, hier meine Ausbildung gestartet zu haben!\"]",
          "title": "Mitarbeiterzitat",
          "id": 100332
        },
        {
          "text": "Warum Kaufleute (m/w/d) im Bereich [KATEGORIE] den besten Start in das Arbeitsleben haben. Lies jetzt weiter...",
          "title": "Bester Start ins Arbeitsleben",
          "id": 100333
        },
        {
          "text": "Finde heraus, ob du Karriere als [z.B. Kaufmann/frau (m/w/d)] im Bereich [KATEGORIE] machen kannst!",
          "title": "Finde heraus...",
          "id": 100334
        },
        {
          "text": "[z.B. 5] Gründe, warum Kaufleute (m/w/d) im Bereich [KATEGORIE] den besten Job haben!",
          "title": "Gründe, warum...",
          "id": 100334
        },
        {
          "text": "Du willst als [z.B. Kaufmann/frau] im Bereich [KATEGORIE] arbeiten, aber dir fehlt die Vorstellung, wie das abläuft? Du bist Quereinsteiger, Anfänger oder Wiedereinsteiger? Kein Problem!",
          "title": "Fehlende Vorstellung",
          "id": 100334
        }
      ]
    },
    {
      "category": "Gastronomie",
      "content": [
        {
          "text": "Gesucht: [z.B. Köche (m/w/d)] im Bereich [KATEGORIE] mit Geschmack",
          "title": "Berufsgruppe + Emoji",
          "id": 100107
        },
        {
          "text": "Guter Geschmack ist dein siebter Sinn und deine Suppen sind nie versalzen? Dann haben wir etwas für dich im Bereich [KATEGORIE]!",
          "title": "Kannst du gut...?",
          "id": 100108
        },
        {
          "text": "Als [z.B. Koch (m/w/d)] liebst du es, neue Gerichte zu kreieren und kannst mit exzellenten Zutaten gut umgehen? Top, genau DICH suchen wir im Bereich [KATEGORIE]!",
          "title": "Liebst du...?",
          "id": 100109
        },
        {
          "text": `“Das Team der [UNTERNEHMENSNAME] ist inzwischen meine zweite Familie, inzwischen freue ich mich auf Montagmorgen!" - Max Mustermann, [z.B. Koch]\n\nDu willst das auch sagen können? Jetzt als [z.B. Koch (m/w/d)] in [BESCHAEFTIGUNGSART] in [STANDORT] im Bereich [KATEGORIE] bewerben!`,
          "title": "Mitarbeiterzitat",
          "id": 100110
        },
        {
          "text": `[z.B. Köche (m/w/d)] im Bereich [KATEGORIE] und Kunden lieben [UNTERNEHMENSNAME]\nDavon willst du dich selbst überzeugen? Dann lies jetzt weiter...`,
          "title": "... dann lies jetzt weiter!",
          "id": 100111
        },
        {
          "text": "[z.B. Köche (m/w/d)] im Bereich [KATEGORIE] in [STANDORT] und Umgebung aufgepasst!",
          "title": "Aufgepasst!",
          "id": 100240
        },
        {
          "text": "Den ganzen Tag gekocht als [z.B. Koch] im Bereich [KATEGORIE]? Kostenlose Massagen warten auf dich nach der Arbeit! [oder anderes unübliches Benefit]",
          "title": "Dein Benefit wartet",
          "id": 100241
        },
        {
          "text": "Jetzt Wechselprämie von xxxx€ [z.B. Koch] im Bereich [KATEGORIE] sichern!",
          "title": "Wechselprämie",
          "id": 100242
        },
        {
          "text": "Wieso [z.B. Köche (m/w/d)] im Bereich [KATEGORIE] zu uns wechseln?",
          "title": "Warum wechseln sie zu uns?",
          "id": 100243
        },
        {
          "text": "Du suchst die perfekte Ausbildung als [z.B. Koch (m/w/d)] in deiner Nähe in [STANDORT]?",
          "title": "Ausbildung in der Nähe?",
          "id": 100244
        },
        {
          "text": "Guter Geschmack ist dein siebter Sinn und deine Suppen sind nie versalzen? Dann starte jetzt als [z.B. Azubi oder Quereinsteiger] im Bereich [KATEGORIE]",
          "title": "Kannst du gut...?",
          "id": 100354
        },
        {
          "text": "Du liebst es, neue Gerichte zu kreieren und kannst mit exzellenten Zutaten gut umgehen? Super, dann hast du die perfekten Voraussetzungen als [z.B. Koch (m/w/d)] im Bereich [KATEGORIE]",
          "title": "Liebst du...?",
          "id": 100355
        },
        {
          "text": "[Emotionaler 2-Zeiler eines Azubis: z.B. Das Team der [UNTERNEHMENSNAME] ist inzwischen meine zweite Familie, inzwischen freue ich mich auf Montagmorgen. Die beste Entscheidung, hier meine Ausbildung im Bereich [KATEGORIE] gestartet zu haben!\"]",
          "title": "Mitarbeiterzitat",
          "id": 100356
        },
        {
          "text": "Warum [z.B. Köche (m/w/d)] im Bereich [KATEGORIE] den besten Start in das Arbeitsleben haben. Lies jetzt weiter...",
          "title": "Bester Start ins Arbeitsleben",
          "id": 100357
        },
        {
          "text": "Finde heraus, ob du Karriere als Koch (m/w/d) im Bereich [KATEGORIE] machen kannst!",
          "title": "Finde heraus...",
          "id": 100358
        },
        {
          "text": "[z.B. 5] Gründe, warum [z.B. Köche (m/w/d)] im Bereich [KATEGORIE] den besten Job haben!",
          "title": "Gründe, warum...",
          "id": 100358
        },
        {
          "text": "Du willst als [z.B. Koch (m/w/d)] im Bereich [KATEGORIE] arbeiten, aber dir fehlt die Vorstellung, wie das abläuft? Du bist Quereinsteiger, Anfänger oder Wiedereinsteiger? Kein Problem!",
          "title": "Fehlende Vorstellung",
          "id": 100358
        },
      ]
    },
    {
      "category": "Industrie",
      "content": [
        {
          "text": "Gesucht: [z.B. Staplerfahrer (m/w/d)] im Bereich [KATEGORIE] mit Durchblick",
          "title": "Berufsgruppe + Emoji",
          "id": 100138
        },
        {
          "text": "Als [z.B. Staplerfahrer (m/w/d)] im Bereich [KATEGORIE] liebst du es, mit dem Stapler zu fahren und behältst immer Durchblick? Dann haben wir etwas für dich!",
          "title": "Kannst du gut...?",
          "id": 100139
        },
        {
          "text": "Als [Staplerfahrer (m/w/d)] liebst du es, schnell durch die Lager zu düsen und hast Feingefühl? Top, genau DICH suchen wir!",
          "title": "Liebst du...?",
          "id": 100140
        },
        {
          "text": `“Das Team der [UNTERNEHMENSNAME] ist inzwischen meine zweite Familie, inzwischen freue ich mich auf Montagmorgen!" - Max Mustermann, [z.B. Staplerfahrer]\n\nDu willst das auch sagen können? Jetzt als Koch (m/w/d) in [Anstellungsart] in [STANDORT] im Bereich [KATEGORIE] bewerben!`,
          "title": "Mitarbeiterzitat",
          "id": 100141
        },
        {
          "text": `[z.B. Staplerfahrer (m/w/d)] im Bereich [KATEGORIE] und Kunden lieben [UNTERNEHMENSNAME]\nDavon willst du dich selbst überzeugen? Dann lies jetzt weiter...`,
          "title": "... dann lies jetzt weiter!",
          "id": 100142
        },
        {
          "text": "[z.B. Staplerfahrer (m/w/d)] im Bereich [KATEGORIE] in [STANDORT] und Umgebung aufgepasst!",
          "title": "Aufgepasst!",
          "id": 100264
        },
        {
          "text": "Den ganzen Tag im Stapler als [z.B. Staplerfahrer (m/w/d)] im Bereich [KATEGORIE]? Kostenlose Massagen warten auf dich nach der Arbeit! [oder anderes unübliches Benefit]",
          "title": "Dein Benefit wartet",
          "id": 100265
        },
        {
          "text": "Jetzt Wechselprämie von xxxx€ als [z.B. Stapelfahrer (m/w/d)] im Bereich [KATEGORIE] sichern!",
          "title": "Wechselprämie",
          "id": 100266
        },
        {
          "text": "Wieso [z.B. Staplerfahrer (m/w/d)] im Bereich [KATEGORIE] zu uns wechseln?",
          "title": "Warum wechseln sie zu uns?",
          "id": 100267
        },
        {
          "text": "Du suchst die perfekte Ausbildung als [z.B. Staplerfahrer (m/w/d)] in deiner Nähe in [STANDORT]?",
          "title": "Ausbildung in der Nähe?",
          "id": 100268
        },
        {
          "text": "Du würdest gerne mit dem Stapler fahren und behältst generell immer Durchblick? Dann starte jetzt als [z.B. Azubi oder Quereinsteiger] im Bereich [KATEGORIE]",
          "title": "Kannst du gut...?",
          "id": 100378
        },
        {
          "text": "Du würdest es lieben, schnell durch die Lager zu düsen und hast Feingefühl? Super, dann hast du die perfekten Voraussetzungen als [z.B. Staplerfahrer (m/w/d)] im Bereich [KATEGORIE]",
          "title": "Liebst du...?",
          "id": 100379
        },
        {
          "text": "[Emotionaler 2-Zeiler eines Azubis]: z.B. Das Team der [UNTERNEHMENSNAME] ist inzwischen meine zweite Familie, inzwischen freue ich mich auf Montagmorgen. Die beste Entscheidung, hier meine Ausbildung im Bereich [KATEGORIE] gestartet zu haben!\"]",
          "title": "Mitarbeiterzitat",
          "id": 100380
        },
        {
          "text": "Warum [z.B. Staplerfahrer (m/w/d)] im Bereich [KATEGORIE] den besten Start in das Arbeitsleben haben. Lies jetzt weiter...",
          "title": "Bester Start ins Arbeitsleben",
          "id": 100381
        },
        {
          "text": "Finde heraus, ob du Karriere als [BESCHAEFTIGUNGSART] in [STANDORT] im Bereich [KATEGORIE] machen kannst!",
          "title": "Finde heraus...",
          "id": 100382
        },
        {
          "text": "[z.B. 5] Gründe, warum [z.B. Staplerfahrer (m/w/d)] den besten Job haben!",
          "title": "Gründe, warum...",
          "id": 100382
        },
        {
          "text": "Du willst als [z.B. Staplerfahrer (m/w/d)] arbeiten, aber dir fehlt die Vorstellung, wie das abläuft? Du bist Quereinsteiger, Anfänger oder Wiedereinsteiger? Kein Problem!",
          "title": "Fehlende Vorstellung",
          "id": 100382
        },
      ]
    }
  ]
};

export default jobTeaserTextData;
