import React, { useState } from 'react';
import { getRandomId } from '../../helper/craftJs';
import { enhanceStockImage } from '../../helper/images';

// This component needs to be used in builder to prevent cors issues which sometimes happening
// It forces the browser to reload an image using an arbitrary url param to relove a previous cached cors issue

const ImageWithoutCacheOnError = (props: any) => {
  const [state, setState] = useState({ arbitraryUrlParameter: '', retriesLeft: 10 });
  const { arbitraryUrlParameter } = state;

  let src = enhanceStockImage(props.src)
  src = src + (!!src ? arbitraryUrlParameter : '');

  const handleSetArbitraryUrlParameter = () => {
    if (props.onError) {
      props.onError();
    }
    if (state.retriesLeft < 1) return;

    setState({
      arbitraryUrlParameter: `?arbitraryUrlParameter=${getRandomId()}`,
      retriesLeft: state.retriesLeft - 1
    });
  };

  return (
    <img
      {...props}
      src={src}
      onError={handleSetArbitraryUrlParameter}
      onErrorCapture={handleSetArbitraryUrlParameter}
      crossOrigin="anonymous"
    />
  );
};

export default ImageWithoutCacheOnError;
