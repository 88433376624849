import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Button, Form } from 'antd';
import { customHtmlToDraft } from '../../../helper/draftHelper';
import { EditorState } from 'draft-js';
import useGetEmailTemplate from '../../../hooks/useGetEmailTemplate';
import DefaultSmallLoader from '../../../SharedUI/components/DefaultSmallLoader';
import MessagingTemplateBlock from '../../../Builder/container/messageSender/MessagingTemplateBlock';
import EmailTemplateBlock from '../../../Builder/components/emailTemplate/EmailTemplateBlock';
import { ActionEvents } from '../../../Builder/hooks/useBookings';
import { applicantNotificationTypeEnum } from '../../../Builder/components/emailTemplate/utils';

export enum NotificationChannelType {
  EMAIL = 'EMAIL',
  MESSAGE = 'MESSAGE'
}

function ApplicantNotificationModal({
  trigger,
  eventId,
  setConfirmModalVisibility,
  applicantNotificationType,
  handleNotReachedTime,
  refetch
}: {
  trigger: JSX.Element;
  eventId: number;
  setConfirmModalVisibility?: (newState: boolean) => void;
  applicantNotificationType: applicantNotificationTypeEnum;
  handleNotReachedTime?: (actionEvent: ActionEvents) => Promise<void>;
  refetch?: () => void;
}) {
  const [visible, setVisibility] = useState(false);
  const [isEditorEmpty, setIsEditorEmpty] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [form] = Form.useForm();

  const { data: templateData, loading: templateLoading } = useGetEmailTemplate(
    eventId,
    applicantNotificationType
  );

  const { message, email, channelType } = templateData?.getApplicantNotificationTemplate || {};
  const handleCancel: () => void = () => {
    setVisibility(false);
    setConfirmModalVisibility && setConfirmModalVisibility(false);
  };

  useEffect(() => {
    if (templateData?.getApplicantNotificationTemplate) {
      form.setFieldsValue({
        subject: email?.subject || '',
        body: EditorState.createWithContent(
          customHtmlToDraft(
            channelType === NotificationChannelType.EMAIL ? email.html : message?.content
          )
        )
      });
      setIsEditorEmpty(false);
    }
  }, [templateData]);

  const handleSend = async () => {
    form.submit();
    handleNotReachedTime && (await handleNotReachedTime(ActionEvents.LAST_NOT_REACHED_TIME));
    handleCancel();
  };

  const title =
    applicantNotificationType === 'NOT_REACHED' ? '"Nicht erreicht"-Nachricht' : 'Absagenachricht';

  return (
    <React.Fragment>
      <div onClick={() => setVisibility(true)}>{trigger}</div>
      <Modal
        title={title}
        width={800}
        visible={visible}
        destroyOnClose
        onCancel={handleCancel}
        className="mb-6 decline-applicant"
        footer={
          <div className="decline-applicant__email-footer">
            <Button onClick={() => handleCancel()} type="link">
              Verwerfen
            </Button>
            <Button onClick={handleSend} loading={isSending} type="primary">
              Absenden
            </Button>
          </div>
        }
      >
        {templateLoading || !form.getFieldValue('body') ? (
          <div className="d-flex w-100 justify-content-center">
            <DefaultSmallLoader loading />
          </div>
        ) : (
          <Row>
            <Col span={24}>
              {channelType === NotificationChannelType.EMAIL ? (
                <EmailTemplateBlock
                  applicantNotificationType={applicantNotificationType}
                  eventId={eventId}
                  setIsSending={setIsSending}
                  setVisibility={setVisibility}
                  form={form}
                />
              ) : (
                <MessagingTemplateBlock
                  enabled
                  senderName="Firmenname"
                  setIsSending={setIsSending}
                  setVisibility={setVisibility}
                  eventId={eventId}
                  applicantNotificationType={applicantNotificationType}
                  form={form}
                  template={message}
                  showHelperMessage={false}
                />
              )}
            </Col>
          </Row>
        )}
      </Modal>
    </React.Fragment>
  );
}

export default ApplicantNotificationModal;
