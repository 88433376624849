import React from 'react';
import { readableStepNames } from '../utils/readableStepNames';
import { Progress } from 'antd';
import { formatPercent } from '../../helper/numberFormat';
import { ChartData } from './PageHitComponent';
import { LeadQualifierInterface } from '../../Events/container/Events';

interface ModiefiedData {
  name: string;
  value: number;
  percent: number;
  readableStepName?: string;
}

function PageHitComponentMobile({ data }: { data: ChartData[] }) {
  const totalHits = data.reduce((acc: number, elem: ChartData) => elem.value + acc, 0);

  const modifiedData: ModiefiedData[] = data.map(
    ({
      readableStepName,
      value,
      name
    }: {
      name: string;
      readableStepName?: string;
      value: number;
    }) => {
      const percent = +formatPercent({
        value,
        basevalue: totalHits,
        roundToInt: true,
        returnRawValue: true
      });

      return {
        readableStepName,
        value,
        percent,
        name
      };
    }
  );

  return (
    <div className="page-hits-mobile">
      {modifiedData.map(({ readableStepName, percent }: ModiefiedData, i) => (
        <div key={i} className="page-hits-mobile__row">
          <label>{readableStepName}</label>
          <Progress
            size="small"
            percent={percent}
            strokeColor={{
              '0%': '#1e266d',
              '100%': '#23d3d3'
            }}
          />
        </div>
      ))}
    </div>
  );
}

export default PageHitComponentMobile;
