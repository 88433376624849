import { SizeInterface } from '../config/builderUi';

export const VoiceMessageSizes: SizeInterface[] = [
  {
    key: 1,
    value: '100px'
  },
  {
    key: 2,
    value: '160px'
  },
  {
    key: 3,
    value: '200px'
  },
];

export const defaultVoiceMessageSize = VoiceMessageSizes[0];
