import { GET_CONNECT_SETTINGS } from "../container/Connect"

const getRatingProviderSettings = [
    {
        provider: "TrustPilot",
        profileURL: "https://www.trustpilot.de/review/shore.com/de",
        reviewCount: 330,
        ratingValue: 4.4
    },
    {
        provider: "ProvenExpert",
        profileURL: "https://www.provenexpert.com/de-de/michaela-merk/",
        reviewCount: 35,
        ratingValue: 4.79
    }
]


const connectData = {
    id: 1,
    pixelId: "XXXXXXXXXXXXXX",
    enabled: true,
}

const connectMock = {
    request: {
        query: GET_CONNECT_SETTINGS,
        variables: {}
    },
    result: {
        data: {
            getThirdPartyTrackingSettings: connectData,
            getRatingProviderSettings
        }
    },

}

export {
    connectData,
    connectMock
}