import React from 'react';
import { FunnelThemeType } from '../../interfaces/builderSliceTypes';
import { checkIsWidgetTheme } from '../../helper/sharedFunctions';

const ColorList = ({ theme, qty }: { theme: FunnelThemeType; qty: number }) => {
  return (
    <>
      {Object.keys(theme).map((key: string, index: number) => {
        const isColor = key.includes('Color') && checkIsWidgetTheme(key);
        if (!isColor || index + 1 > qty) return;

        return (
          <div
            key={index}
            className="color__circle"
            style={{
              // @ts-ignore
              background: `${theme[key]}`
            }}
          ></div>
        );
      })}
    </>
  );
};

export default ColorList;
