import React, { useEffect } from 'react';
import { Form, Input, Row, Col, Typography, Switch } from 'antd';
import {
  removeSpecialCharsFromString,
  uniqueIdentifierFormValidator
} from '../../../Funnel/helper/url';
import BuilderSettingsTitle from '../sharedUI/BuilderSettingsTitle';
import BuilderSettingsTogglePro from '../sharedUI/BuilderSettingsTogglePro';
import {
  editGeneralSettings,
  setFunnelSuggestionModalVisibility,
  useBuilderGeneralSettings,
  useFunnel,
  useFunnelSuggestion,
  useFunnelSuggestionModalVisibility
} from '../../redux/builderSlice';
import { useAppDispatch } from '../../../redux/hooks';
import BuilderValidator from '../../container/BuilderValidator';
import FunnelSuggestionModal from '../../../Funnel/components/FunnelSuggestionModal';
import { getFunnelDomain } from '../../../Funnel/helper/getFunnelDomain';
import UniqueIdentifierNoLongerAvailable from '../sharedUI/UniqueIdentifierNoLongerAvailable';
export const GeneralSettingsFormRules = {
  uniqueIdentifier: [{ required: true, message: 'Bitte definiere eine URL für deinen Funnel.' }],
  title: [
    { required: true, message: 'Gib deinem Funnel einen Namen.' },
    { max: 60, message: 'Ein Wert darf nicht länger als 60 Zeichen sein.' }
  ]
};

function GeneralSettingsBuilder() {
  const [form] = Form.useForm();
  const generalSettings = useBuilderGeneralSettings();
  const funnel = useFunnel();
  const dispatch = useAppDispatch();
  const funnelSuggestionModalVisibility = useFunnelSuggestionModalVisibility();
  const funnelSuggestion = useFunnelSuggestion();

  useEffect(() => {
    if (
      form.getFieldValue('title') !== generalSettings.title ||
      form.getFieldValue('uniqueIdentifier') !== generalSettings.uniqueIdentifier
    ) {
      form.setFieldsValue({
        title: generalSettings.title,
        uniqueIdentifier: generalSettings.uniqueIdentifier
      });
    }
  }, [generalSettings.title, generalSettings.uniqueIdentifier]);

  return (
    <>
      <Col span={24} className="builder__settings-sidebar__container">
        <Row className="builder__settings-sidebar__row pt-3">
          <Col span={24} className="mb-3 px-4">

              <Form
                name="general_settings_form"
                layout="vertical"
                form={form}
                scrollToFirstError={true}
                initialValues={{
                  title: generalSettings.title,
                  uniqueIdentifier: generalSettings.uniqueIdentifier
                }}
              >
                <div className="d-flex align-items-center">
                  <BuilderSettingsTitle title="Titel" suffix="(nur für dich)" />
                </div>
                <Form.Item name="title" rules={GeneralSettingsFormRules.title}>
                  <Input
                    data-testid="funnelTitleInput"
                    placeholder="Mein Recruiting Funnel"
                    value={generalSettings.title}
                    maxLength={60}
                    onChange={e => {
                      dispatch(editGeneralSettings({ title: e.target.value }));
                    }}
                  />
                </Form.Item>

                <div className="d-flex align-items-center">
                  <BuilderSettingsTitle title="URL" suffix="(app.meetovo.de/funnel/xyz)" />
                </div>
                <UniqueIdentifierNoLongerAvailable
                  oldUniqueIdentifier={funnel.uniqueIdentifier}
                  newUniqueIdentifier={generalSettings.uniqueIdentifier}
                />
                <Form.Item
                  name="uniqueIdentifier"
                  rules={[
                    ...GeneralSettingsFormRules.uniqueIdentifier,
                    uniqueIdentifierFormValidator
                  ]}
                  extra='Unter "Connect" kannst du eigene Domains verbinden'
                >
                  <Input
                    data-testid="funnleUniqueIdentifier"
                    placeholder="mein-recruiting-funnel"
                    value={generalSettings.uniqueIdentifier}
                    onChange={e => {
                      const cleanUniqueIdentifier = removeSpecialCharsFromString(e.target.value);
                      dispatch(editGeneralSettings({ uniqueIdentifier: cleanUniqueIdentifier }));
                    }}
                  />
                </Form.Item>
              </Form>
          </Col>

          <Col span={24}>
            <BuilderSettingsTogglePro
              title="Sprache"
              secondaryText="&nbsp;(im Funnel)"
              classNames="py-2"
              rightContent={
                <div className="pr-4 d-flex align-items-center">
                  {[
                    {
                      flagURL: `${process.env.PUBLIC_URL}/funnel-builder/flag-germany-96.png`,
                      value: 'DE'
                    },
                    {
                      flagURL: `${process.env.PUBLIC_URL}/funnel-builder/flag-uk-96.png`,
                      value: 'EN'
                    }
                  ].map((lang, index) => (
                    <img
                      key={index}
                      className="language-change-flag ps-2 m-0 cursor-pointer"
                      src={lang.flagURL}
                      style={{
                        height: 25,
                        ...(generalSettings.language !== lang.value && {
                          opacity: 0.35
                        })
                      }}
                      onClick={() => dispatch(editGeneralSettings({ language: lang.value }))}
                    />
                  ))}
                </div>
              }
            />
            <p className="px-4 my-3 mb-4">
              Wenn du die Sprache hier anpasst, dann werden alle bereiche des Funnels umgestellt,
              die du nicht selbst ändern kannst.
            </p>
          </Col>

          <Col span={24}>
            <BuilderSettingsTogglePro
              title="Cookie-Banner"
              classNames="py-3"
              rightContent={
                <Switch
                  size="small"
                  defaultChecked={generalSettings.cookieBanner}
                  onChange={checked => {
                    dispatch(editGeneralSettings({ cookieBanner: checked }));
                  }}
                />
              }
            />
            <p className="px-4 my-3">
              Der Cookie-Hinweis sollte nach DSGVO aktiviert sein. Er bietet außerdem die
              Datenschutzeinstellungen in einem Popup an. Wenn er deaktiviert ist, dan ist keinerlei
              Tracking möglich, auch Facebook-Pixel-Events und Google-Tags werden nicht gefeuert.
            </p>
            <div className="px-4 mt-2 mb-4">
              <img
                src="/dashboard/funnel-builder/cookie-banner.jpg"
                className="decent-border w-100"
              />
            </div>
          </Col>
        </Row>
      </Col>
      <FunnelSuggestionModal
        openSuggestionModal={funnelSuggestionModalVisibility}
        uniqueIdentifierSuggestion={funnelSuggestion}
        handleSuggestionModalClose={() => dispatch(setFunnelSuggestionModalVisibility(false))}
        domain={getFunnelDomain()}
        form={form}
        isGeneralSettings
      />
    </>
  );
}

export default GeneralSettingsBuilder;
