import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, message } from 'antd';
import { ConnectSettingsMessages } from '../../../config/messages';
import { useMutation } from '@apollo/react-hooks';
import { GET_ALL_DOMAINS, SET_FUNNEL_DOMAIN } from '../../utils/domainQueries';
import {
  DomainCountInterface,
  DomainValidatiorV2,
  FunnelSelectionValidator,
  SlugValidatior
} from '../../utils/domainAndSlugValidation';
import mixpanel from 'mixpanel-browser';
import { mixpanelEvents } from '../../../UI/utils/mixpanel';
import { forceLowerCase } from '../../utils/domainInput';
import FunnelSelector from '../../../SharedUI/components/FunnelSelector';
import { useAllFunnels } from '../../../Funnel/redux/funnelSlice';
import { factorsAiTrackEvent } from '../../../helper/factors-ai';

interface AddDomainProps {
  addable: boolean;
  domainCount: DomainCountInterface;
  maxDomains?: number;
  subdomainCount: number;
  refetchSubscriptionInfo: () => void;
  setNewlyCreatedDomainId?: (id: number) => void;
}

const AddDomain = ({
  addable,
  domainCount,
  maxDomains,
  subdomainCount,
  refetchSubscriptionInfo,
  setNewlyCreatedDomainId
}: AddDomainProps) => {
  const [forceRerenderCount, setForceRerenderCount] = useState(0);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [addDomain, { loading }] = useMutation(SET_FUNNEL_DOMAIN, {
    refetchQueries: [{ query: GET_ALL_DOMAINS }]
  });
  const domainListModalSelector = document.querySelector('#domain-list-modal');
  const funnels = useAllFunnels();

  const handleForceUpdate = () => {
    setForceRerenderCount(forceRerenderCount + 1);
  };

  const handleSubmit = (values: any) => {
    factorsAiTrackEvent('DOMAIN_ADDED');

    const newDomain = {
      name: values.name,
      slugs: [
        {
          slug: values?.slug || '/',
          funnelId: values.funnelId
        }
      ]
    };
    addDomain({ variables: { domain: newDomain } })
      .then(res => {
        setNewlyCreatedDomainId && setNewlyCreatedDomainId(res.data?.setFunnelDomain?.id);
        refetchSubscriptionInfo();
        setOpen(false);
        form.resetFields();

        mixpanel.track(mixpanelEvents.FUNNEL_DOMAIN_CONNECTED);

        domainListModalSelector?.scrollTo(0, 0);
      })
      .catch(err => {
        if ((err?.message as string)?.toLowerCase()?.includes('already existing')) {
          message.error(ConnectSettingsMessages.domainAlreadyConnected);
          return;
        }
        message.error(ConnectSettingsMessages.domainAlreadyExists);
      });
  };

  const handleOpen = () => {
    if (!addable) {
      message.error(ConnectSettingsMessages.notEnaugthDomains);
      return;
    }

    setOpen(true);
    domainListModalSelector?.scrollTo(0, domainListModalSelector?.scrollHeight || 0);
  };

  const currentSlug = form.getFieldValue(`slug`) || '';
  const extra =
    'Link-Vorschau: ' +
    (form.getFieldValue('name') || 'deine-domain.de') +
    (currentSlug !== '/' && currentSlug.includes('/') ? currentSlug : '');

  if (!open)
    return (
      <div className="connect-settings-changer__add-button-wrapper" style={{ border: 'none' }}>
        <Button
          data-testid="addNewDomainButton"
          onClick={handleOpen}
          type="ghost"
          className="lead-qualifier__add icon-circle-button-with-label"
        >
          <span className="ui__icon-circle">
            <PlusOutlined />
          </span>
          Hinzufügen
        </Button>
      </div>
    );

  if (open)
    return (
      <Card className="ui__white-round-wrapper" style={{ marginBottom: '1em' }}>
        <Form
          name="add-domain-form"
          form={form}
          layout="vertical"
          scrollToFirstError={true}
          onFinish={handleSubmit}
          onValuesChange={handleForceUpdate}
          initialValues={{ slug: '/' }}
        >
          <div className="fields-inline">
            <Form.Item
              required
              rules={[...DomainValidatiorV2(domainCount, subdomainCount, maxDomains as number)]}
              label="Domain"
              name="name"
              extra={extra}
            >
              <Input
                data-testid="DomainInput"
                placeholder="z.B. deine-domain.de"
                onInput={forceLowerCase}
              />
            </Form.Item>
            <Form.Item required name="slug" label="Slug" rules={[...SlugValidatior]}>
              <Input
                data-testid="SlugInput"
                placeholder={`z.B. "/mechaniker" oder "/stelle-1"`}
                onInput={forceLowerCase}
              />
            </Form.Item>
          </div>
          <Form.Item
            required
            rules={FunnelSelectionValidator}
            initialValue={-1}
            name="funnelId"
            label={'Funnel'}
          >
            <FunnelSelector
              placeholder="Wähle einen Funnel"
              defaultValue={[-1]}
              customFunnels={[
                {
                  title: 'Ohne Funnel',
                  id: -1
                }
              ]}
              onChange={selectFunnelIds => {
                form.setFieldsValue({
                  [`funnelId`]: selectFunnelIds
                });
              }}
              initialData={funnels}
              allSelectdable={false}
            />
          </Form.Item>
          <Button
            data-testid="domainSubmitButton"
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
          >
            Hinzufügen
          </Button>
          {!loading && (
            <Button color="gray" type="link" onClick={() => setOpen(false)}>
              Abbrechen
            </Button>
          )}
        </Form>
      </Card>
    );

  return null;
};

export default AddDomain;
