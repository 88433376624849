import { Typography } from 'antd';
import React from 'react';

const GoogleJobsBanner = () => {
  return (
    <div className="google-job-banner">
      <img src="/dashboard/google-jobs/google-logo.png" className='google-job-banner__logo' />
      <img src="/dashboard/google-jobs/google-search-bar-mockup.jpg" className='google-job-banner__search-bar' />
    </div>
  );
};

export default GoogleJobsBanner;
