import React, { useState } from 'react';
import { Card, Col, Modal } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { ZapierText, IntegromatText } from '../utils/texts';
import { getUrlParam } from '../../helper/url';
import { CONNECT_CONTENT_TYPE_NOT_EDITABLE, connectCardTitlesAndCategories } from '../utils/types';
import { EDIT_CONTENT_TYPE } from '../context/ResourceEditContext';

export const GET_USER = gql`
  query {
    getUser {
      zapierAccessToken
      integromatAccessToken
    }
  }
`;

function Connect() {
  const [zapierTokenVisible, setZapierTokenVisibility] = useState(false);
  const [integromatTokenVisible, setIntegromatTokenVisibility] = useState(false);
  const [isZapierModalVisible, setZapierModalVisible] = useState(false);
  const [isIntegromatModalVisible, setIntegromatModalVisible] = useState(false);
  const { data } = useQuery(GET_USER);

  const { getUser: user } = data || {
    getUser: { zapierAccessToken: 'Nicht verfügbar', integromatAccessToken: 'Nicht verfügbar' }
  };

  const showZapierModal = () => setZapierModalVisible(true);
  const showIntegromatModal = () => setIntegromatModalVisible(true);

  const closeZapierModal = () => setZapierModalVisible(false);
  const closeIntegromatModal = () => setIntegromatModalVisible(false);

  return (
    <>
      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
        <div className="card-with-hover card-with-hover__logo-not-rounded" onClick={showZapierModal}>
          <Card
            hoverable
            className="card-container"
            cover={
              <img src={process.env.PUBLIC_URL + '/connect/zapier-z-logo.png'} />
            }
          >
            <Card.Meta title={connectCardTitlesAndCategories[CONNECT_CONTENT_TYPE_NOT_EDITABLE.ZAPIER].title} className="card-title" />
            <div>{connectCardTitlesAndCategories[CONNECT_CONTENT_TYPE_NOT_EDITABLE.ZAPIER].category}</div>
          </Card>
        </div>
        <Modal
          title="Zapier Integration"
          visible={isZapierModalVisible}
          onCancel={closeZapierModal}
          footer={null}
        >
          <div className="zapier-modal">
            <strong>Access token: </strong>

            {zapierTokenVisible && user.zapierAccessToken}

            {zapierTokenVisible ? (
              <EyeInvisibleOutlined onClick={() => setZapierTokenVisibility(false)} />
            ) : (
              <span className="cursor-pointer" onClick={() => setZapierTokenVisibility(true)}>
                Anzeigen <EyeOutlined />
              </span>
            )}
          </div>
          {ZapierText}
        </Modal>
      </Col>

      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
        <div className="card-with-hover card-with-hover__logo-not-rounded" onClick={showIntegromatModal}>
          <Card className="card-container" hoverable cover={<img src={"/dashboard/connect/make-icon.svg"} />}>
            <Card.Meta title={connectCardTitlesAndCategories[CONNECT_CONTENT_TYPE_NOT_EDITABLE.MAKE].title} className="card-title" />
            <div>{connectCardTitlesAndCategories[CONNECT_CONTENT_TYPE_NOT_EDITABLE.MAKE].category}</div>
          </Card>
        </div>
        <Modal
          title="Make Integration"
          visible={isIntegromatModalVisible}
          onCancel={closeIntegromatModal}
          footer={null}
        >
          <div className="zapier-modal">
            <strong>Access token: </strong>

            {integromatTokenVisible && user.integromatAccessToken}

            {integromatTokenVisible ? (
              <EyeInvisibleOutlined onClick={() => setIntegromatTokenVisibility(false)} />
            ) : (
              <span className="cursor-pointer" onClick={() => setIntegromatTokenVisibility(true)}>
                Anzeigen <EyeOutlined />
              </span>
            )}
          </div>
          {IntegromatText}
        </Modal>
      </Col>
    </>
  );
}

export default Connect;
