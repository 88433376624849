export const removeSpecialCharsFromString = (string: string = "") => (
    string.replace(" ", "-").replace(/[^a-zA-Z0-9-_\.]/g, '').toLowerCase()
)

export const isValidUniqueIdentifier = (str: string) => {
    return !str.match(new RegExp(/[^a-z0-9-_\.]/))
}

export const uniqueIdentifierFormValidator = ({ getFieldValue }: { getFieldValue: Function }) => ({
    validator(rule: any, value: string) {
      if (!isValidUniqueIdentifier(value)) {
        return Promise.reject(
          'Deine URL darf nur folgende Zeichen enthalten: a-z, 0-9, -'
        );
      }
  
      return Promise.resolve();
    }
  });
  