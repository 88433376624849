import React from 'react';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import BuilderSettingsTitle from '../../../../sharedUI/BuilderSettingsTitle';
import BuilderColorPickerButton from '../../../../sharedUI/BuilderColorPickerButton';
import { Card, Col, Row, Slider, Switch } from 'antd';
import { distanceAboveMarks, distanceBelowMarks } from '../../../Container/config';
import {
  getPaddingClassBySliderValue,
  getSliderValueByPaddingClass
} from '../../../Container/helper';
import { useNode } from '@craftjs/core';
import BuilderSettingsTogglePro from '../../../../sharedUI/BuilderSettingsTogglePro';
import { ELEMENTS_WITH_ELEMENT_APPENDER } from '../../../../../config/builderUi';
import ContainerSettingsSectionId from '../../../Container/container/ContainerSettingsSectionId';
import { getElementColor } from '../../../../../helper/craftJs';
import { useCurrentlyEditingBuilderTheme } from '../../../../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';

export const ElementAppenderSettings = () => {
  const {
    actions: { setProp },
    props
  } = useNode(node => ({
    props: node.data.props,
    nodeId: node.id,
    currentNode: node
  }));
  const theme = useCurrentlyEditingBuilderTheme();

  return (
    <Col span={24} className="builder__settings-sidebar__container">
      <Card className="settings-card" title={props.elementTitle} bordered={false}>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BuilderSettingsTitle title="Abstand oben" icon={<FaArrowDown />} />
            <Slider
              className="builder-slider-style"
              marks={distanceAboveMarks}
              step={50}
              tooltipVisible={false}
              defaultValue={50}
              value={getSliderValueByPaddingClass(props.paddingTopClass, 'top')}
              onChange={value => {
                setProp(
                  (props: any) =>
                    (props.paddingTopClass = getPaddingClassBySliderValue(value, 'top'))
                );
              }}
            />
          </Col>
        </Row>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BuilderSettingsTitle title="Abstand unten" icon={<FaArrowUp />} />
            <Slider
              className="builder-slider-style"
              marks={distanceBelowMarks}
              step={50}
              tooltipVisible={false}
              defaultValue={50}
              value={getSliderValueByPaddingClass(props.paddingBottomClass, 'bottom')}
              onChange={value => {
                setProp(
                  (props: any) =>
                    (props.paddingBottomClass = getPaddingClassBySliderValue(value, 'bottom'))
                );
              }}
            />
          </Col>
        </Row>
        <Row className="builder__settings-sidebar__row">
          <Col span={11}>
            <BuilderSettingsTitle title="Hintergrundfarbe" />
            <BuilderColorPickerButton
              showColorTypeBtns
              color={getElementColor(props.backgroundColor, theme)}
              onChange={(backgroundColor, colorKey) => {
                setProp((props: any) => {
                  props.backgroundColor = colorKey || backgroundColor;
                });
              }}
            />
          </Col>
          {[ELEMENTS_WITH_ELEMENT_APPENDER.MULTIPLE_SELECTION].includes(props.elementTitle) && (
            <Col span={24} className="mt-3">
              <BuilderSettingsTogglePro
                title="Erforderlich"
                classNames="without-border pt-0 pb-3"
                rightContent={
                  <Switch
                    size="small"
                    checked={props.required}
                    onChange={checked => {
                      setProp((props: any) => (props.required = checked));
                    }}
                  />
                }
              />
            </Col>
          )}
        </Row>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <ContainerSettingsSectionId />
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

export default ElementAppenderSettings;
