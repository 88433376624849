import React, { ReactNode, useEffect } from 'react';
import { AgencyOSBuilderState } from '../../AgencyOSBuilder/interfaces/agencyOSBuilderSliceTypes';
import { BuilderState } from '../../interfaces/builderSliceTypes';
import { useSetSelectedElementTab } from '../../reduxCommonMutations/setSelectedElementTab';
import { useIsMobileView } from '../../hooks/useIsMobileView';
import { Widget } from '../../WidgetsBuilder/interfaces/widgetSliceTypes';

interface BuilderPreviewContainerBasePropsType {
  builderState: AgencyOSBuilderState | BuilderState | Widget;
  children: ReactNode;
  prohibitTabSwitch?: boolean;
}
export const BuilderPreviewContainerBase = ({
  builderState,
  children,
  prohibitTabSwitch
}: BuilderPreviewContainerBasePropsType) => {
  const setSelectedElementTabMutation = useSetSelectedElementTab();
  const isMobileView = useIsMobileView();

  const handleSwitchTab = (e: any) => {
    if (prohibitTabSwitch) return;
    e.stopPropagation();
    setSelectedElementTabMutation({
      payload: '1'
    });
  };

  useEffect(() => {
    const isDevelopment = process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development';
    function beforeUnloadHandler(e: any) {
      if (!isDevelopment) {
        var confirmationMessage = 'o/';
        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
      }
      return undefined;
    }
    window.addEventListener('beforeunload', beforeUnloadHandler);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [builderState]);

  // This "builder-view" class has been used in generating funnel preview image. Avoid updating it.
  return (
    <div
      className={`preview-section builder-view  ${isMobileView ? 'mobile-view' : ''}`}
      onClick={handleSwitchTab}
    >
      {children}
    </div>
  );
};
