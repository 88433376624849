export function getValueByPropString(_obj: any, propString?: string) {
  let obj = _obj;
  if (!propString) return obj;
  if (!propString || !_obj) return;

  let prop,
    props = propString.split('.');
  let arrayAccessor = NaN;
  for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
    let candidate;
    prop = props[i];

    arrayAccessor = parseInt(prop);
    if (!isNaN(parseInt(prop))) {
      candidate = obj[arrayAccessor];
    } else {
      candidate = obj[prop];
    }

    if (candidate !== undefined) {
      obj = candidate;
    } else {
      break;
    }
  }

  const finalAccessor = !isNaN(parseInt(props[i])) ? arrayAccessor : props[i];
  return obj[finalAccessor];
}

export function getLocalStorage(propString: string) {
  const firstProp = propString.includes('.') && propString.split('.')[0];

  if (!firstProp) {
    return JSON.parse(localStorage[propString] || '{}');
  }

  return getValueByPropString(
    JSON.parse(localStorage[firstProp] || '{}'),
    propString.replace(firstProp + '.', '')
  );
}

export function setLocalStorage(propString: string, value: string | object | []) {
  if (typeof value === 'string') {
    const firstProp = propString.split('.')[0];
    const secondProp = propString.split('.')[1];
    const storageObject = JSON.parse(localStorage[firstProp] || '{}');
    storageObject[secondProp] = value;
    localStorage.setItem(firstProp, JSON.stringify(storageObject));
  }

  if (typeof value === 'object') {
    localStorage.setItem(propString, JSON.stringify(value));
  }
}
