import { useQuery } from '@apollo/react-hooks';
import { DomainInterface } from '../../Connect/components/CustomDomains/Domain';
import { GET_ALL_DOMAINS } from '../../Connect/utils/domainQueries';

export const useAllDomains = () =>
  useQuery<{
    getAllDomains: DomainInterface[];
  }>(GET_ALL_DOMAINS, {
    fetchPolicy: 'cache-and-network'
  });
