import { useAppDispatch } from '../../redux/hooks';
import { editDraggingElement as editDraggingElementFromFunnel } from '../redux/builderSlice';
import { editDraggingElement as editDraggingElementFromAgencyOsBuilder } from '../AgencyOSBuilder/redux/agencyOSBuilderSlice';
import { DraggingElement } from '../interfaces/builderSliceTypes';
import { useIsAgencyOSBuilderPath } from '../hooks/useIsAgencyOSBuilderPath';

export const useSetDraggingElement = () => {
  const dispatch = useAppDispatch();
  const isAgencyOSBuilderPath = useIsAgencyOSBuilderPath();

  return (payload: DraggingElement) => {
    if (isAgencyOSBuilderPath) {
      dispatch(editDraggingElementFromAgencyOsBuilder(payload));
    } else {
      dispatch(editDraggingElementFromFunnel(payload));
    }
  };
};
