import gql from 'graphql-tag';

export interface Input {
  id: number;
  facebookPixelEvent: string | null;
  googleTagTrigger: string | null;
  tikTokPixelEvent: string | null;
  linkedInEvent: string | null;
}

export const SET_PAGE_TRACKING = gql`
  mutation setPageTracking($input: SetPageTrackingInput!) {
    setPageTracking(input: $input) {
      id
      facebookPixelEvent
      tikTokPixelEvent
      linkedInEvent
      googleTagTrigger
    }
  }
`;

export default function setPageTracking(input: Input) {
  return window.apolloClient.mutate({
    mutation: SET_PAGE_TRACKING,
    variables: { input }
  });
}
