import React from 'react';

const LoginQuote = ({}) => {
  return (
    <div className="quote-with-image-wrapper">
      <div className="quote-with-image bp-min-769-flex">
        <div className="image-circle">
          <img src={process.env.PUBLIC_URL + '/login/lukas-christof-200.jpg'} />
        </div>
        <div className="quote-content">
          <q>
            Wir haben MEETOVO extra für Agenturen & Recruiter konzipiert und entwickeln es stetig
            weiter, damit dein Funnel immer erfolgreicher wird!
          </q>
          <p>
            <b>Lukas & Christof</b> | Gründer von MEETOVO
          </p>
        </div>
      </div>

      <div className="quote-with-image version-2 bp-max-768">
        <div className="bp-max-768-flex">
          <div className="image-circle">
            <img src={process.env.PUBLIC_URL + '/login/lukas-christof-200.jpg'} />
          </div>
          <p>
            <b>Lukas & Christof</b> | Gründer von MEETOVO
          </p>
        </div>
        <div className="quote-content">
          <q>
            Wir haben MEETOVO extra für Agenturen & Recruiter konzipiert und entwickeln es stetig
            weiter, damit dein Funnel immer erfolgreicher wird!
          </q>
        </div>
      </div>
    </div>
  );
};

export default LoginQuote;
