import gql from 'graphql-tag';

export interface Input {
  title: string;
  text: string;
  type: string;
}

const CREATE_AD_TEXT_TEMPLATE = gql`
  mutation createAdTextTemplate($input: CreateAdTextTemplateInput!) {
    createAdTextTemplate(input: $input) {
      id
      title
      text
      type
    }
  }
`;

export default function createAdTextTemplate(input: Input) {
  return window.apolloClient.mutate({
    mutation: CREATE_AD_TEXT_TEMPLATE,
    variables: { input }
  });
}
