import { editAgencyOSMetaData } from '../../AgencyOSBuilder/redux/agencyOSBuilderSlice';
import { editMetaData } from '../../redux/builderSlice';
import { useIsAgencyOSBuilderPath } from '../useIsAgencyOSBuilderPath';

export const editMetaDataSettings = () => {
  const isAgencyOSBuilderPath = useIsAgencyOSBuilderPath();
  if (isAgencyOSBuilderPath) {
    return editAgencyOSMetaData;
  } else {
    return editMetaData;
  }
};
