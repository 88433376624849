import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { EDIT_CONTENT_TYPE } from '../Connect/context/ResourceEditContext';

export interface ContentEditSessionType {
  isEditing: boolean;
  resource: EDIT_CONTENT_TYPE;
}
export const GET_ALL_CONTENT_EDIT_STATUS = gql`
  query {
    getAllContentEditSession {
      isEditing
      resource
    }
  }
`;
const useContentEditStatus = () => {
  return useQuery(GET_ALL_CONTENT_EDIT_STATUS);
};

export default useContentEditStatus;
