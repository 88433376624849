import { useSocket, useSocketEvent } from 'socket.io-react-hook';
import { getEnvironment } from '../../helper/environment';
import { getAuthData } from '../../Login/container/Login';

export const useFunnelEditingSocket = ({ enabled }: { enabled: boolean }) => {
  const { token } = getAuthData();
  const domain = getEnvironment().domain;

  return useSocket(domain, {
    enabled: enabled && !!token,
    path: '/funnel-editing-socket',
    forceNew: false,
    transports: ['websocket', 'polling'],
    query: {
      EIO: 3,
      token: token ?? ''
    }
  });
};

export default function useThirdPartyConnectSocket() {
  const { token } = getAuthData();
  const domain = getEnvironment().domain;
  return useSocket(domain, {
    enabled: !!token,
    path: '/third-party-connect-socket',
    forceNew: false,
    transports: ['websocket', 'polling'],
    query: {
      EIO: 3,
      token: token ?? ''
    }
  });
}

export const useAgencyDashboardEditingSocket = ({ enabled }: { enabled: boolean }) => {
  const { token } = getAuthData();
  const domain = getEnvironment().domain;

  return useSocket(domain, {
    enabled: enabled && !!token,
    path: '/agency-dashboard-socket',
    forceNew: false,
    transports: ['websocket', 'polling'],
    query: {
      EIO: 3,
      token: token ?? ''
    }
  });
};
