export const uploadFileOptions = [
  { label: '.pdf', value: 'application/pdf' },
  { label: '.ppt', value: 'application/vnd.ms-powerpoint' },
  {
    label: '.pptx',
    value: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  },
  { label: '.xls', value: 'application/vnd.ms-excel' },
  { label: '.xlsx', value: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
  { label: '.doc', value: 'application/msword' },
  { label: '.zip', value: 'application/zip' },
  {
    label: '.docx',
    value: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  },
  { label: '.ods', value: 'application/vnd.oasis.opendocument.spreadsheet' },
  { label: '.odt', value: 'application/vnd.oasis.opendocument.text' },
  { label: '.png', value: 'image/png' },
  { label: '.jpg', value: 'image/jpeg' },
  { label: '.gif', value: 'image/gif' },
  { label: '.mov', value: 'video/quicktime' },
  { label: '.mp4', value: 'video/mp4' },
  { label: '.mp3', value: 'audio/mp3' },
  { label: '.ttf', value: 'font/ttf' },
  { label: '.woff', value: 'font/woff' },
  { label: '.woff2', value: 'font/woff2' }
];

const mimeTypesThatHaveNoDeterminedFileExtension: { [key: string]: string } = {
  'image/jpeg': 'jpg',
  'text/javascript': 'js',
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'application/msword': 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'audio/mpeg': 'mp3'
};

export const acceptedFormats = [
  'image/png',
  'image/jpeg',
  'application/pdf',
  'application/msword',
  'application/vnd.oasis.opendocument.text'
];

export const acceptedFontFormats = ['font/ttf', 'font/woff', 'font/woff2'];

export const getFileExtensionFromMimeType = (mimeType: string): string | null => {
  if (mimeTypesThatHaveNoDeterminedFileExtension[mimeType]) {
    return mimeTypesThatHaveNoDeterminedFileExtension[mimeType];
  }

  const mimeParts = mimeType.split('/');

  if (mimeParts.length === 2) {
    return mimeParts[1];
  }

  return null;
};

export const getFileExtensionFromFileName = (fileName: string | null): string | null => {
  if (!fileName) return null;
  
  const lastDotIndex = fileName.lastIndexOf('.');

  if (lastDotIndex === -1 || lastDotIndex === 0) {
    return null;
  }

  return fileName.substring(lastDotIndex + 1).toLowerCase();
};

