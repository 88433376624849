export type SelectedScheduledTime = {
  days: number;
  weeks: number;
  hours: number;
  minutes: number;
};

export const convertTimeToMinutes = (selectScheduledTime: SelectedScheduledTime) => {
  let scheduledTime = 0;
  const daysInWeek = 7;
  const hoursInDay = 24;
  const minutesInHour = 60;

  if (selectScheduledTime?.weeks) {
    scheduledTime += selectScheduledTime.weeks * daysInWeek * hoursInDay * minutesInHour;
  }
  if (selectScheduledTime?.days) {
    scheduledTime += selectScheduledTime.days * hoursInDay * minutesInHour;
  }
  if (selectScheduledTime?.hours) {
    scheduledTime += selectScheduledTime.hours * minutesInHour;
  }
  if (selectScheduledTime?.minutes) {
    scheduledTime += selectScheduledTime.minutes;
  }
  
  return scheduledTime;
};
