import React from 'react';
import {
  FaBriefcase,
  FaCalendar,
  FaGraduationCap,
  FaMapMarkerAlt,
  FaMoneyCheckAlt
} from 'react-icons/fa';
import {
  CompanyDetail,
  JobInformation,
  TargetAudience
} from '../../../interfaces/builderSliceTypes';
import {
  JobAttributeOption,
  JOB_ATTRIBUTES_IDENTIFIER,
  getJobOptions,
  LEGACY_JOB_ATTRIBUTES_IDENTIFIER,
  experienceDurationPlaceHolders,
  salaryPlanPlaceHolders
} from '../../helper/AdJobHelper';
import { formatNumber } from '../../helper/validateFunnelJobPresets';
import { useCurrentlyEditingTheme } from '../../../redux/builderSlice';
import { TARGET_GROUP_ELEMENTS } from '../../../components/generalSettings/TargetGroup/components/TargetGroupHelper';
import { COMPANY_INFORMATION_ELEMENTS } from '../../../components/generalSettings/AiDataTab/components/AiDataHelper';

type RenderContentProps = {
  jobInformation: JobInformation;
  targetAudience: TargetAudience;
  companyDetail: CompanyDetail;
  identifier:
    | JOB_ATTRIBUTES_IDENTIFIER
    | TARGET_GROUP_ELEMENTS
    | LEGACY_JOB_ATTRIBUTES_IDENTIFIER
    | COMPANY_INFORMATION_ELEMENTS;
};

const RenderContent = ({
  jobInformation,
  targetAudience,
  companyDetail,
  identifier
}: RenderContentProps) => {
  const { icon, options } = getJobOptions(identifier as JobAttributeOption);
  const { address, salary, title, workplaceModel, category, salaryPlan, employmentType } =
    jobInformation || {};
  const { experienceDuration, experience, education } = targetAudience || {};
  const { name } = companyDetail || {};
  const noValueExists = 'Keine Angabe';
  const { cardTextColor, accentColor } = useCurrentlyEditingTheme();

  let valueToRender;
  switch (identifier) {
    case JOB_ATTRIBUTES_IDENTIFIER.EMPLOYMENT_TYPE:
      valueToRender =
        (salary && title) || name ? (
          <div className="job-attribute">
            <FaBriefcase style={{ color: accentColor }} />{' '}
            {employmentType
              .map(singleEdu => {
                return getJobOptions(JOB_ATTRIBUTES_IDENTIFIER.EMPLOYMENT_TYPE).options.find(
                  single => single.value === singleEdu
                )?.label;
              })
              .join(', ')}
          </div>
        ) : (
          noValueExists
        );
      break;
    case TARGET_GROUP_ELEMENTS.EDUCATION:
      valueToRender =
        (salary && title) || name ? (
          <div className="job-attribute">
            <FaGraduationCap style={{ color: accentColor }} />{' '}
            {education
              .map(singleEdu => {
                return getJobOptions(TARGET_GROUP_ELEMENTS.EDUCATION).options.find(
                  single => single.value === singleEdu
                )?.label;
              })
              .join(', ')}
          </div>
        ) : (
          noValueExists
        );
      break;
    case JOB_ATTRIBUTES_IDENTIFIER.SALARY:
    case LEGACY_JOB_ATTRIBUTES_IDENTIFIER.SALARY:
      valueToRender =
        (salary && title) || name ? (
          <div className="job-attribute">
            <FaMoneyCheckAlt style={{ color: accentColor }} />{' '}
            {Array.isArray(salary)
              ? `${formatNumber(salary?.[0])}€ - ${formatNumber(salary?.[1])}€`
              : ''}{' '}
            {salaryPlanPlaceHolders[salaryPlan]}
          </div>
        ) : (
          noValueExists
        );
      break;
    case TARGET_GROUP_ELEMENTS.EXPERIENCE:
      valueToRender = (
        <div className="job-attribute">
          <FaCalendar style={{ color: accentColor }} /> {experience} {
          experienceDurationPlaceHolders[experienceDuration]}
        </div>
      );
      break;
    case JOB_ATTRIBUTES_IDENTIFIER.JOB_TITLE:
    case LEGACY_JOB_ATTRIBUTES_IDENTIFIER.JOB_TITLE:
      valueToRender = <h5 style={{ color: cardTextColor }}>{title || noValueExists}</h5>;
      break;
    case COMPANY_INFORMATION_ELEMENTS.NAME:
    case LEGACY_JOB_ATTRIBUTES_IDENTIFIER.COMPANY_NAME:
      valueToRender = <h5 style={{ color: cardTextColor }}>{name || noValueExists}</h5>;
      break;
    case JOB_ATTRIBUTES_IDENTIFIER.JOB_ADDRESS:
    case LEGACY_JOB_ATTRIBUTES_IDENTIFIER.JOB_ADDRESS:
      valueToRender = address && address.place && (
        <div className="job-attribute">
          <FaMapMarkerAlt style={{ color: accentColor }} />
          {address?.place}
        </div>
      );
      break;
    case JOB_ATTRIBUTES_IDENTIFIER.WORKPLACE_MODEL:
      const iconOption: any = options.find((option: any) => option.value === workplaceModel);
      valueToRender = iconOption && (
        <div className="job-attribute">
          <span style={{ color: accentColor }}>{iconOption.icon || icon}</span>
          {iconOption.label}
        </div>
      );
      break;
    case JOB_ATTRIBUTES_IDENTIFIER.CATEGORY:
    default:
      valueToRender =
        Array.isArray(category) &&
        category.map((item: any, index: number) => {
          const iconOption: any = options.find((option: any) => option.value === item);
          return (
            iconOption && (
              <div
                className="job-attribute"
                style={index !== category.length - 1 ? { marginRight: '0px' } : {}}
                key={index}
              >
                <span style={{ color: accentColor }}>
                  {index === 0 ? iconOption?.icon || icon : <></>}
                </span>
                {category.length - 1 === index ? iconOption?.label : `${iconOption?.label},`}
              </div>
            )
          );
        });
  }

  return valueToRender;
};

export default RenderContent;
