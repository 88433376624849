import React from 'react';
import { Popover, Switch } from 'antd';

const BrandingComponent = ({
  message,
  disabled,
  checked,
  onChange
}: {
  message?: string;
  disabled?: boolean;
  checked?: boolean;
  onChange?: any;
}) =>
  disabled ? (
    <Popover
      className="default-popover"
      content={<div className="default-popover__content">{message}</div>}
    >
      <div>
        <Switch disabled={disabled} size="small" checked={checked} onChange={onChange} />
      </div>
    </Popover>
  ) : (
    <Switch size="small" checked={checked} onChange={onChange} />
  );

export default BrandingComponent;
