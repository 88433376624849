import { Col, Collapse, Form, Row } from 'antd';
import React from 'react';
import { useAppDispatch } from '../../../../redux/hooks';
import { Communication } from '../../../interfaces/builderSliceTypes';
import {
  setCommunication,
  useCommunication,
  useIsAiSettingDuplicated
} from '../../../redux/builderSlice';
import { COMMUNICATION_ELEMENTS } from '../AiDataTab/components/AiDataHelper';
import DuplicateAiSettingsAlert from '../utils/DuplicateAiSettingsAlert';
import {
  hashFormItemsCommunication,
  hashTitlesCommunication
} from '../../../AdJobBuilder/components/sharedUI/FormItemsCustom';

const { Panel } = Collapse;

export default function CommunicationTab() {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { writingStyle, speech } = useCommunication();

  const handleChange = (value: any, identifier: keyof Communication) => {
    const values = form.getFieldsValue();
    form.setFieldsValue({ ...values, [identifier]: value });
    dispatch(setCommunication({ identifier, value }));
  };

  const isFunnelDuplicated = useIsAiSettingDuplicated();

  return (
    <Col span={24} className="builder__settings-sidebar__container">
      <Row className="builder__settings-sidebar__row">
        <Col span={24} className="px-4">
          <Form
            name="general_settings_form"
            layout="vertical"
            form={form}
            initialValues={{ writingStyle, speech }}
            scrollToFirstError={true}
          >
            <Collapse className="w-100" accordion expandIconPosition="right" bordered={false}>
              <Panel
                header={hashTitlesCommunication[COMMUNICATION_ELEMENTS.SPEECH](speech)}
                key={4}
              >
                {hashFormItemsCommunication[COMMUNICATION_ELEMENTS.SPEECH](speech, handleChange)}
              </Panel>
              <Panel
                header={hashTitlesCommunication[COMMUNICATION_ELEMENTS.WRITING_STYLE](writingStyle)}
                key={5}
              >
                {hashFormItemsCommunication[COMMUNICATION_ELEMENTS.WRITING_STYLE](
                  writingStyle,
                  handleChange
                )}
              </Panel>
            </Collapse>
          </Form>

          {isFunnelDuplicated && <DuplicateAiSettingsAlert />}
        </Col>
      </Row>
    </Col>
  );
}
