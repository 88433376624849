import React from 'react';
import { Empty, Card } from 'antd';
import { AnalyticsMessages } from '../../config/messages';
import { getReadableStepNameByPhd } from '../utils/readableStepNames';
import PageHitComponentMobile from './PageHitComponentMobile';
import { LeadQualifierInterface } from '../../Events/container/Events';
import { checkIfStepIsCraftPage } from '../utils/setpNameHelpers';

interface ChoiceCountInterface {
  choiceId: number;
  count: number;
}

interface WelcomePageDataInterfaceSimple {
  id: number;
  serviceName: string;
}

export interface PageHitDataInterface {
  artificial?: boolean;
  stepIndex: number;
  stepName: string;
  leadQualifierId: number;
  hits: number;
  averageTimeInSeconds: number;
  choiceArray: ChoiceCountInterface[];
}

interface PageHitComponentProps {
  funnelPages: { id: number; name: string }[];
  pageHitData: PageHitDataInterface[];
  leadQualifierData: LeadQualifierInterface[];
  welcomePageData: WelcomePageDataInterfaceSimple[];
  leads?: number;
}

export interface ChartData {
  name: string;
  value: number;
  phd: PageHitDataInterface;
  lqd: LeadQualifierInterface | undefined;
  readableStepName?: string;
}

export function PageHitComponentMobileV2({
  leads,
  pageHitData,
  leadQualifierData,
  welcomePageData,
  funnelPages
}: PageHitComponentProps) {
  pageHitData = pageHitData || [];
  leadQualifierData = leadQualifierData || [];
  welcomePageData = welcomePageData || [];

  // filter deleted lq trackings
  pageHitData = pageHitData.reduce((acc: PageHitDataInterface[], phd: PageHitDataInterface) => {
    const isLeadQualifier = phd.stepName.includes('leadQualifier_');

    if (!isLeadQualifier) return [...acc, phd];

    const lqId = parseInt(phd.stepName.replace('leadQualifier_', ''));
    const isAvailable = leadQualifierData.some(lqd => lqd.id === lqId);

    if (isAvailable) return [...acc, phd];
    return acc;
  }, []);

  // thank-you-page filtern da selbe info wie konversion
  pageHitData = pageHitData.filter(phd => phd.stepName !== 'thank-you-page');

  const conversionBarExists = pageHitData.some(
    ({ artificial, stepName }) => artificial && stepName == 'Konversion'
  );
  if (leads && !conversionBarExists)
    pageHitData.push({
      leadQualifierId: 0,
      artificial: true,
      stepIndex: pageHitData.length,
      stepName: 'Konversion',
      hits: leads,
      averageTimeInSeconds: 0,
      choiceArray: []
    });

  const data: ChartData[] = pageHitData.map((phd: PageHitDataInterface) => {
    return {
      name: phd.stepName,
      readableStepName: getReadableStepNameByPhd({
        phd,
        maxLength: 25,
        funnelPages,
        welcomePageData,
        leadQualifierData
      }),
      value: phd.hits,
      phd,
      lqd:
        phd.stepName.includes('leadQualifier') ||
        (checkIfStepIsCraftPage(phd.stepName) && phd.leadQualifierId)
          ? leadQualifierData.find(
              (lqd: LeadQualifierInterface) =>
                `${lqd.id}` === `${phd.leadQualifierId || phd.stepName.split('_')[1]}`
            )
          : undefined
    };
  });

  return (
    <Card title="Besucherverhalten">
      {data.length ? (
        <PageHitComponentMobile data={data} />
      ) : (
        <Empty description={AnalyticsMessages.noPageHitData} />
      )}
    </Card>
  );
}
