import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import { saveCompleteBuilderDataThunk } from '../../redux/thunk';

const SaveBuilderStateOnRouteChange = () => {
  const dispatch = useAppDispatch();
  const [previousPathname, setPreviousPathname] = useState('');

  useEffect(() => {
    try {
      // we only need to save on switch pages because the flow will be easier without loader all the time
      if (
        (previousPathname === '/dashboard/builder/bearbeiten' &&
          location.pathname.includes('/builder/bearbeiten/elemente')) ||
        (previousPathname === '/dashboard/builder/bearbeiten/elemente' &&
          location.pathname.includes('/builder/bearbeiten'))
      ) {
        setPreviousPathname(location.pathname);
        return;
      }

      const tabsAllowedToSaveAll = [
        '/builder/bearbeiten',
        '/builder/design-einstellungen',
      ];

      const shouldSaveCompleteBuilderState =
        (tabsAllowedToSaveAll.find(path => location.pathname.includes(path)) &&
          previousPathname.includes('/builder/bearbeiten')) ||
        previousPathname.includes('/builder/design-einstellungen');

      if (shouldSaveCompleteBuilderState) dispatch(saveCompleteBuilderDataThunk());

      if (previousPathname.includes('/builder/emails'))
        dispatch(saveCompleteBuilderDataThunk({ shouldOnlySaveEmailTemplates: true }));
    } catch (error) {}

    setPreviousPathname(location.pathname);
  }, [location.pathname]);

  return null;
};

export default SaveBuilderStateOnRouteChange;
