import { createUserColorThemeAPI } from "../../Builder/graphql/createUserColorTheme";
import { mutateUserColorThemeAPI } from "../../Builder/graphql/mutateUserColorTheme";
import updateFunnelTheme from "../../Builder/graphql/updateFunnelTheme";
import { FunnelThemeType } from "../../Builder/interfaces/builderSliceTypes";
import { addNewUserColorTheme, updateUserColorTheme } from "../../Builder/redux/builderSlice";


export const handleSaveTheme = async ({
    oldTheme,
    newTheme,
    dispatch
  }: {
    oldTheme: FunnelThemeType;
    newTheme: FunnelThemeType;
    dispatch: any;
  }) => {
    
    if (newTheme.id === oldTheme.id) {
      // Theme For Whole Funnel Should Be Updated
      await updateFunnelTheme(newTheme);
    } else if (!newTheme.id) {
      // Create New Theme for Funnel
      const theme = await createUserColorThemeAPI(newTheme);
      dispatch(addNewUserColorTheme(theme.data.createFunnelColorTheme));
    } else {
      // Update Existing User Theme
      const data = await mutateUserColorThemeAPI(newTheme);
      dispatch(updateUserColorTheme(data.data.updateUserColorTheme));
    }
  };