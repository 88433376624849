import React from 'react';
import { Row, Col, Card, Empty, Table, Popover } from 'antd';
import { AnalyticsMessages } from '../../config/messages';
import { formatPercent } from '../../helper/numberFormat';
import { groupArrayByKey, deepCopy } from '../../helper/array';
import { sourceIcons } from '../utils/sourceIcons';
import { InfoCircleOutlined } from '@ant-design/icons';
import { AnalyticsInfoTexts } from '../utils/infoTexts';

export interface SourceDataInterface {
  sourceType: string;
  sourceTypeClean: string;
  count: number;
  key?: string;
}

interface SourceComponentProps {
  sourceData: SourceDataInterface[];
}

export function SourceComponent({ sourceData }: SourceComponentProps) {
  sourceData = sourceData || [];
  sourceData.forEach(
    (tc: SourceDataInterface, idx: number) => (tc.key = `unique_source_key_${idx}`)
  );

  const totalCount = sourceData.reduce(
    (acc: number, elem: SourceDataInterface) => elem.count + acc,
    0
  );

  const sourceColumns = [
    {
      title: 'Quelle',
      dataIndex: 'sourceTypeClean',
      key: 'sourceTypeClean',
      render: (value: string) => (
        <span>
          {sourceIcons[value]} {value}
          {value === 'Anonym' ? (
            <Popover
              className="default-popover"
              content={
                <div className="default-popover__content" style={{ maxWidth: 400 }}>
                  {AnalyticsInfoTexts.anonym}
                </div>
              }
              trigger="hover"
            >
              <InfoCircleOutlined />
            </Popover>
          ) : null}
        </span>
      )
    },
    {
      title: 'Website',
      dataIndex: 'sourceType',
      key: 'sourceType'
    },
    {
      title: 'Anteil in %',
      dataIndex: 'count',
      key: 'count',
      render: (value: number) => formatPercent({ value, basevalue: totalCount })
    },
    {
      title: 'Anzahl',
      dataIndex: 'count',
      key: 'count',
      sorter: {
        compare: (a: SourceDataInterface, b: SourceDataInterface) => a.count - b.count,
        multiple: 1
      }
    }
  ];

  let modifiedSourceData = groupArrayByKey(sourceData, 'sourceTypeClean');

  modifiedSourceData = Object.keys(modifiedSourceData).reduce(
    (acc: SourceDataInterface[], key: string, idx: number) => {
      let currentSourceData = modifiedSourceData[key];

        currentSourceData = deepCopy(currentSourceData).map((msd: SourceDataInterface) => {
            msd.sourceTypeClean = "";
            return msd
        })

      // Filter Direkt umd diesen als einzelnen Punkt anzuzeigen
      let mutatedDirectSourceData;
      const directSourceData = currentSourceData.find(
        (csd: SourceDataInterface) => csd.sourceType === 'Direkt'
      );
      if (directSourceData) {
        currentSourceData = currentSourceData.filter(
          (csd: SourceDataInterface) => csd.sourceType !== 'Direkt'
        );
        mutatedDirectSourceData = {
          sourceTypeClean: 'Direkt',
          sourceType: '',
          count: directSourceData.count,
          key: `unique_sourceGroup_key_direkt`
        };
      }

      const anonymSourceData = currentSourceData.find(
        (csd: SourceDataInterface) => csd.sourceType === 'Anonym'
      );
      if (anonymSourceData) {
        currentSourceData = currentSourceData.filter(
          (csd: SourceDataInterface) => csd.sourceType !== 'Anonym'
        );
        mutatedDirectSourceData = {
          sourceTypeClean: 'Anonym',
          sourceType: '',
          count: anonymSourceData.count,
          key: `unique_sourceGroup_key_anonym`
        };
      }

      const totalCount = currentSourceData.reduce(
        (acc: number, elem: SourceDataInterface) => elem.count + acc,
        0
      );

      const groupedSourceData = {
        sourceTypeClean: key,
        sourceType: '',
        count: totalCount,
        children: currentSourceData,
        key: `unique_sourceGroup_key_${idx}`
      };

      const shouldAddGroupedSourceData = !!currentSourceData.length;

      if (mutatedDirectSourceData) {
        if (shouldAddGroupedSourceData) return [...acc, mutatedDirectSourceData, groupedSourceData];
        else return [...acc, mutatedDirectSourceData];
      } 
      
      if (shouldAddGroupedSourceData) {
        return [...acc, groupedSourceData];
      }

      return acc;
    },
    []
  );

  return (
    <Card
      title={
        <React.Fragment>
          Quelle
          <Popover
            content={<div style={{ maxWidth: 400 }}>{AnalyticsInfoTexts.quellen}</div>}
            trigger="hover"
          >
            <InfoCircleOutlined />
          </Popover>
        </React.Fragment>
      }
    >
      {modifiedSourceData.length ? (
        <Table
          pagination={modifiedSourceData.length > 6 ? { pageSize: 6 } : false}
          columns={sourceColumns}
          dataSource={modifiedSourceData}
        />
      ) : (
        <Empty description={AnalyticsMessages.noSourceData} />
      )}
    </Card>
  );
}
