import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { GET_USER_WEBHOOKS } from './useUserWebHooks';

export const REMOVE_WEBHOOK_DOMAIN = gql`
  mutation removeWebhook($id: Int!) {
    removeWebhook(id: $id)
  }
`;

export default function useRemoveWebhook() {
  return useMutation(REMOVE_WEBHOOK_DOMAIN, {
    refetchQueries: [{ query: GET_USER_WEBHOOKS }]
  });
}
