import { useEditor } from '@craftjs/core';

export const useCurrentNodeSettings = () =>
  useEditor((state, query) => {
    //@ts-ignore
    const [currentNodeId] = state.events.selected;

    let selected = {};
 
    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings: state.nodes[currentNodeId].related && state.nodes[currentNodeId].related.settings,
        isDeletable: query.node(currentNodeId).isDeletable(),
        isHidden: state.nodes[currentNodeId]?.data?.props?.canHide?.hideSwich
      };
    }

    return {
      selected,
      isEnabled: state.options.enabled
    };
  });
