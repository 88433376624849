import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import { FunnelMessages, GeneralMessages } from '../../../config/messages';
import {
  BUILDER_TYPE,
  getHashFromObject,
  shouldSaveOrShowMessage
} from '../../helper/sharedFunctions';
import {
  AgencyOSBuilderState,
  AgencyOSBuilderStateChecksums,
  SaveCompleteAgencyOSBuilderThunkPayloadType
} from '../interfaces/agencyOSBuilderSliceTypes';
import { fetchCompleteAgencyOSBuilder } from '../hooks/useGetCompleteAgencyOSBuilder';
import setAgencyDashboardPages from '../graphql/setAgencyDashboardPages';
import setAgencyDashboard from '../graphql/setAgencyDashboard';
import setAgencyOSBuilderTheme from '../graphql/setAgencyOSBuilderTheme';

export const getCompleteAgencyOSBuilder = createAsyncThunk(
  'get-complete-agency-os-builder',
  async () => {
    try {
      const { data }: any = await fetchCompleteAgencyOSBuilder();

      const {
        title,
        description,
        customMetaDataEnabled,
        faviconURL,
        previewImageURL,
        uniqueIdentifier,
        id,
        primaryColor,
        secondaryColor,
        isEditing
      } = data.getAgencyDashboard;

      const metaData = {
        metaTitle: title,
        description,
        customMetaDataEnabled,
        iconURL: faviconURL,
        previewImageURL
      };

      const agencyDashboard = {
        id,
        uniqueIdentifier,
        primaryColor,
        secondaryColor,
        isEditing
      };

      const checksums: AgencyOSBuilderStateChecksums = {
        agencyOSBuilderPages: await getHashFromObject(data.agencyOSBuilderPages),
        agencyDashboard: await getHashFromObject(agencyDashboard),
        metaData: await getHashFromObject(metaData),
        agencyOSTheme: await getHashFromObject(data.agencyOSBuilderTheme)
      };
      return {
        pages: data.agencyOSBuilderPages,
        agencyOSTheme: data.agencyOSBuilderTheme,
        allDomains: data.getAllDomains,
        agencyDashboard,
        metaData,
        checksums
      };
    } catch (err) {
      message.error(GeneralMessages.error);
      location.replace('/dashboard');
      throw new Error(GeneralMessages.error);
    }
  }
);

export const saveCompleteAgencyOSBuilderDataThunk = createAsyncThunk(
  'save-complete-agency-os-builder',
  async (
    data: undefined | SaveCompleteAgencyOSBuilderThunkPayloadType,
    { getState, rejectWithValue }
  ) => {
    try {
      const { callbackOnSuccess } = data || {};
      const { agencyOSBuilderUI: state } = getState() as {
        agencyOSBuilderUI: AgencyOSBuilderState;
      };
      const shouldSaveAgencyOSBuilder = shouldSaveOrShowMessage(
        state.shouldSaveAgencyOSBuilder,
        BUILDER_TYPE.AGENCY_OS_BUILDER
      );
      if (!shouldSaveAgencyOSBuilder) return;
      const { builderPages } = {
        builderPages: state.agencyOSBuilderPages
      };
      const currentAgencyOsBuilderPagesChecksum = await getHashFromObject(builderPages);
      const currentAgencyOSBuilderChecksum = await getHashFromObject(state.agencyDashboard);
      const currentAgencyOSBuilderMetaDataChecksum = await getHashFromObject(state.metaData);
      const currentAgencyOSBuilderThemeChecksum = await getHashFromObject(
        state.currentlyEditingTheme
      );

      const shouldUpdateAgencyOSBuilderTheme = !!(
        state.checksums.agencyOSTheme !== currentAgencyOSBuilderThemeChecksum ||
        !currentAgencyOSBuilderThemeChecksum
      );

      if (shouldUpdateAgencyOSBuilderTheme) {
        await setAgencyOSBuilderTheme(state.currentlyEditingTheme);
      }

      const shouldUpdateAgencyOSBuilderPages = !!(
        state.checksums.agencyOSBuilderPages !== currentAgencyOsBuilderPagesChecksum ||
        !currentAgencyOsBuilderPagesChecksum
      );
      const shouldUpdateAgencyOSBuilder = !!(
        state.checksums.agencyDashboard !== currentAgencyOSBuilderChecksum ||
        !currentAgencyOSBuilderChecksum
      );
      const shouldSaveAgencyOSBuilderMetaData = !!(
        state.checksums.metaData !== currentAgencyOSBuilderMetaDataChecksum ||
        !currentAgencyOSBuilderMetaDataChecksum
      );
      if (shouldSaveAgencyOSBuilderMetaData) {
        const { iconURL: faviconURL, metaTitle: title, ...rest } = state.metaData;
        await setAgencyDashboard({ ...rest, faviconURL, title });
      }

      const shouldUpdateFunnelAndPages =
        shouldUpdateAgencyOSBuilderPages || shouldUpdateAgencyOSBuilder;
      if (shouldUpdateAgencyOSBuilder) {
        await setAgencyDashboard(state.agencyDashboard);
      }

      if (shouldUpdateFunnelAndPages) {
        await setAgencyDashboardPages({
          pages: builderPages.map(page => ({ id: page.id, craftState: page.craftState }))
        });
      }

      const newChecksums: AgencyOSBuilderStateChecksums = {
        agencyOSBuilderPages: currentAgencyOsBuilderPagesChecksum,
        agencyDashboard: currentAgencyOSBuilderChecksum,
        metaData: currentAgencyOSBuilderMetaDataChecksum,
        agencyOSTheme: currentAgencyOSBuilderThemeChecksum
      };

      if (callbackOnSuccess) callbackOnSuccess();
      return {
        checksums: newChecksums,
        agencyOSTheme: state.currentlyEditingTheme
      };
    } catch (error) {
      console.log(error);
      const errorMessage: string = (error as any)?.message?.replace('GraphQL error: ', '');
      const thisError = FunnelMessages.apiErrors[errorMessage];
      if (thisError) message.error(thisError, 8);
      else message.error(GeneralMessages.error);
      return rejectWithValue(error);
    }
  }
);
