import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export const GET_COMPLETE_AGENCY_OS_BUILDER = gql`
  query getCompleteAgencyOSBuilder {
    agencyOSBuilderPages {
      id
      craftState
      type
    }
    getAgencyDashboard {
      id
      title
      logoURL
      faviconURL
      primaryColor
      secondaryColor
      createdById
      uniqueIdentifier
      isEditing
      description
      previewImageURL
      customMetaDataEnabled
    }
    agencyOSBuilderTheme {
      primaryColor
      primaryContrastColor
      accentColor
      headlineColor
      textColor
      fontFamily
      fontLink
    }
    getAllDomains {
      name
      agencyDashboardId
    }
  }
`;

export default function useGetCompleteAgencyOSBuilder() {
  return useQuery(GET_COMPLETE_AGENCY_OS_BUILDER, {
    fetchPolicy: 'cache-and-network'
  });
}

export function fetchCompleteAgencyOSBuilder() {
  return window.apolloClient.query({
    query: GET_COMPLETE_AGENCY_OS_BUILDER,
    fetchPolicy: 'no-cache'
  });
}
