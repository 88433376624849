import React, { useState } from 'react';
import moment from 'moment';
import { TimePicker, message, Divider } from 'antd';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { BusinessHoursMessages } from '../../../config/messages';
import BuilderSettingsTitle from '../../../Builder/components/sharedUI/BuilderSettingsTitle';

export interface BusinessHoursInterface {
  [type: string]: any;
  id?: number;
  dow: any;
  start: string;
  end: string;
}

interface BusinessHoursInterfaceProps {
  businessHours: BusinessHoursInterface[];
  onChange(businessHours: BusinessHoursInterface[]): any;
}

interface StateInterface {
  activeDay: number | null;
  newStart: string;
  newEnd: string;
  addActive: boolean;
}

function BusinessHours({ businessHours: _businessHours, onChange }: BusinessHoursInterfaceProps) {
  _businessHours = mutate(_businessHours);

  const [state, setState] = useState<StateInterface>({
    activeDay: null,
    newStart: '00:00',
    newEnd: '00:00',
    addActive: false
  });

  function sortBusinessHoursByTimeAndDay(unsortedBusinessHours: BusinessHoursInterface[]) {
    // TODO: Typescript befriedigen
    const businessHoursSortedByDow = unsortedBusinessHours.sort((a, b) => a.dow - b.dow);
    const stackedBusinessHours = businessHoursSortedByDow.reduce((acc: any, bh: any) => {
      if (acc[bh.dow]) acc[bh.dow].push(bh);
      else acc[bh.dow] = [bh];

      return acc;
    }, {});

    return Object.keys(stackedBusinessHours).reduce(
      (acc: BusinessHoursInterface[], dow: string) => {
        stackedBusinessHours[dow] = stackedBusinessHours[dow].sort(
          (a: BusinessHoursInterface, b: BusinessHoursInterface) =>
            stringToMinutes(a.start) - stringToMinutes(b.end)
        );
        return [...acc, ...stackedBusinessHours[dow]];
      },
      []
    );
  }

  function stringToMinutes(string: string) {
    const ss = string.split(':');
    return +ss[0] * 60 + +ss[1];
  }

  function minutesToString(_minutes: number) {
    const hours = Math.floor(_minutes / 60);
    const minutes = _minutes % 60;
    const hoursInFormat = hours < 10 ? '0' + hours : hours;
    const minutesInFormat = minutes < 10 ? '0' + minutes : minutes;

    return hoursInFormat + ':' + minutesInFormat;
  }

  function addPeriod(day: number | null = state.activeDay) {
    const businessHoursActiveDay = _businessHours.filter(b => b.dow === day);
    const lastBusinessHourActiveDay = businessHoursActiveDay[businessHoursActiveDay.length - 1];
    let newStart, newEnd;
    if (!lastBusinessHourActiveDay) {
      newStart = '09:00';
      newEnd = '19:00';
    } else {
      if (lastBusinessHourActiveDay.end >= '22:00') {
        return;
      }
      const newStartMinutes = stringToMinutes(lastBusinessHourActiveDay.end) + 60;
      const lastShifMinutesDuration =
        stringToMinutes(lastBusinessHourActiveDay.end) -
        stringToMinutes(lastBusinessHourActiveDay.start);
      newEnd =
        lastShifMinutesDuration + newStartMinutes > stringToMinutes('23:55')
          ? '23:55'
          : minutesToString(lastShifMinutesDuration + newStartMinutes);
      newStart = minutesToString(newStartMinutes);
    }

    _businessHours = sortBusinessHoursByTimeAndDay(_businessHours);

    handleChange([..._businessHours, { dow: day, end: newEnd, start: newStart }]);
  }

  function changeTime(value: any, key: number, type: string) {
    const startDateIsBeforeEndDate = value < _businessHours[key].end;
    const endDateIsAfterStartDate = value > _businessHours[key].start;
    const selectedTimeSlotBefore = _businessHours[key - 1];
    const selectedTimeSlotBeforeOnSameDay = selectedTimeSlotBefore?.dow === _businessHours[key].dow;
    const aEnd = type === 'end' ? value : _businessHours[key].end;
    const aStart = type === 'start' ? value : _businessHours[key].start;
    const bStart = selectedTimeSlotBefore?.start;
    const bEnd = selectedTimeSlotBefore?.end;
    const overlappingShift =
      _businessHours.length > 2 &&
      selectedTimeSlotBeforeOnSameDay &&
      aEnd >= bStart &&
      aStart <= bEnd;

    if (
      ((type === 'start' && startDateIsBeforeEndDate) ||
        (type === 'end' && endDateIsAfterStartDate)) &&
      !overlappingShift
    ) {
      _businessHours[key][type] = value;
      handleChange(_businessHours);
      return;
    }

    if (overlappingShift) {
      message.error(BusinessHoursMessages.overlappingShifts);
      return;
    }

    if (type === 'start' && !startDateIsBeforeEndDate)
      message.error(BusinessHoursMessages.startBeforeEnd);

    if (type === 'end' && !endDateIsAfterStartDate)
      message.error(BusinessHoursMessages.endBeforeEnd);
  }

  function deletePeriod(key: number) {
    handleChange(_businessHours.filter((h: BusinessHoursInterface, _key: number) => _key !== key));
  }

  function mutate(__businessHours: BusinessHoursInterface[]) {
    const muatetetBusinessHours: BusinessHoursInterface[] = [];

    _businessHours.forEach(({ id, start, end, dow }: BusinessHoursInterface) => {
      dow.forEach((thisDow: number) => {
        const newTimeSlot: BusinessHoursInterface = { start, end, dow: thisDow };

        if (id) newTimeSlot['id'] = id;

        muatetetBusinessHours.push(newTimeSlot);
      });
    });

    return sortBusinessHoursByTimeAndDay(muatetetBusinessHours);
  }

  function revertMutation(__businessHours: BusinessHoursInterface[]) {
    let muatetetBusinessHours: BusinessHoursInterface[] = [];

    __businessHours.forEach(({ id, start, end, dow }: BusinessHoursInterface) => {
      const selectedTimeSlotExistingIndex = muatetetBusinessHours.findIndex(
        mbh => mbh.start === start && mbh.end === end
      );
      if (selectedTimeSlotExistingIndex > -1)
        muatetetBusinessHours[selectedTimeSlotExistingIndex].dow.push(dow);
      else muatetetBusinessHours.push({ ...(id ? { id } : {}), start, end, dow: [dow] });
    });

    // muatetetBusinessHours.forEach((mbh) => {
    //     const idExists = muatetetBusinessHours.some((_mbh: BusinessHoursInterface) => _mbh.id === mbh.id)
    //     if(idExists)
    //         delete mbh.id
    // })

    return muatetetBusinessHours;
  }

  function handleChange(__businessHours: BusinessHoursInterface[]) {
    const muatetetBusinessHours = revertMutation(__businessHours);
    onChange(muatetetBusinessHours);
  }

  const { activeDay } = state;
  let week = [1, 2, 3, 4, 5, 6, 7];
  let renderPeriods: any;

  return (
    <div className="business-hours">
      <BuilderSettingsTitle title="Verfügbarkeiten" />

      {week.map(day => {
        const __businessHours = _businessHours.map((businessHour, index) => ({
          ...businessHour,
          index
        }));
        const businessDay = __businessHours.filter(businessHour => businessHour.dow === day);

        switch (true) {
          case businessDay.length && activeDay !== day:
            renderPeriods = businessDay.map((period, key) => (
              <div key={key} className="time-unit">
                <span className="time start">{period.start}</span>
                <span className="divider"> - </span>
                <span className="time end">{period.end}</span>

                <DeleteOutlined
                  onClick={(e: any) => {
                    e.stopPropagation();
                    deletePeriod(period.index);
                  }}
                />
                {key == 0 ? (
                  <PlusCircleOutlined
                    onClick={(e: any) => {
                      e.stopPropagation();
                      addPeriod(day);
                    }}
                  />
                ) : null}
              </div>
            ));
            break;

          case !businessDay.length:
            renderPeriods = (
              <div className="time-unit">
                <span className="time closed">Geschlossen</span>
                <PlusCircleOutlined
                  onClick={(e: any) => {
                    e.stopPropagation();
                    addPeriod(day);
                  }}
                />
              </div>
            );
            break;

          case businessDay.length && activeDay === day:
            renderPeriods = businessDay.map((period, key) => (
              <div key={key} className="time-unit edit">
                <TimePicker
                  format="HH:mm"
                  minuteStep={15}
                  allowClear={false}
                  onChange={date => {
                    if (date) changeTime(date.format('HH:mm'), period.index, 'start');
                  }}
                  //@ts-ignore
                  defaultValue={moment(period.start, 'HH:mm:ss')}
                />

                <span className="divider"> - </span>

                <TimePicker
                  format="HH:mm"
                  minuteStep={15}
                  allowClear={false}
                  onChange={date => {
                    if (date) changeTime(date.format('HH:mm'), period.index, 'end');
                  }}
                  //@ts-ignore
                  defaultValue={moment(period.end, 'HH:mm:ss')}
                />

                <DeleteOutlined
                  onClick={(e: any) => {
                    e.stopPropagation();
                    deletePeriod(period.index);
                  }}
                />

                {key == 0 ? (
                  <PlusCircleOutlined
                    onClick={(e: any) => {
                      e.stopPropagation();
                      addPeriod(day);
                    }}
                  />
                ) : null}
              </div>
            ));
            break;
        }

        return (
          <div
            className="day"
            key={day}
            onClick={(e: any) => {
              e.stopPropagation();
              setState({ ...state, activeDay: day, addActive: false });
            }}
          >
            <label>
              {moment()
                .isoWeekday(day)
                .format('dddd') + 's'}
            </label>
            <div className="time-unit-wrapper">{renderPeriods}</div>
          </div>
        );
      })}
    </div>
  );
}

export default BusinessHours;
