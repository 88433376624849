import { useNode, useEditor, Element, Node, NodeTree, Nodes } from '@craftjs/core';
import React, { useEffect, useState } from 'react';
import {
  craftToJSON,
  getClonedTree,
  getMainContainerNode,
  getUniqueId
} from '../../helper/craftJs';
import { CRAFT_ELEMENTS, containerDependingComponent } from '../../config/craftElements';
import WarningModalWithHideForeverCheckbox from './common/WarningModalWithHideForeverCheckbox';
import Column from './grid/Column';
import ColumnGrid from './grid/ColumnGrid';
import ContainerComponent from './Container/container/ContainerComponent';
import SelectValidLeadQualifier from '../sharedUI/SelectValidLeadQualifier';
import { AddContainerComponentV2 } from './AddContainerComponentV2';
import { CraftJsUserComponents } from '../../container/BuilderScreen';
import { SelectedTemplate } from '../TemplatesPanel/interfaces';

interface Props {
  padding?: string;
  children?: any;
  backgroundColor?: string;
}

export interface ComingNode {
  element: Node | undefined;
  index: number;
}

const MainContainerComponent = ({ padding, children, backgroundColor, ...props }: Props) => {
  const {
    connectors: { connect, drag },
    currentNode
  } = useNode(state => ({
    nodeId: state.id,
    currentNode: state
  }));
  const { actions, query } = useEditor();
  const [comingNode, setComingNode] = useState<ComingNode>({
    element: undefined,
    index: 0
  });
  const [actionCreator, setActionCreator] = useState(actions.history.throttle());
  const [visible, setVisible] = useState(false);
  const { id: RootId } = getMainContainerNode(query);
  const currentShowCount = parseInt(
    localStorage.getItem('meetovoContainerCreateWarningShowCount') || '0'
  );
  const { id } = getMainContainerNode(query);

  const handleAddNewContainer = (thisComingNode?: ComingNode) => {
    thisComingNode = thisComingNode || comingNode;
    if (thisComingNode.element) {
      localStorage.setItem('meetovoContainerCreateWarningShowCount', currentShowCount + 1 + '');

      const containerNodeTree: NodeTree = query
        .parseReactElement(
          <Element id={getUniqueId()} is={ContainerComponent} canvas>
            <Element id={getUniqueId()} is={ColumnGrid} canvas>
              <Element
                id={getUniqueId()}
                is={Column}
                columnWidths={{
                  sm: 12,
                  md: 12,
                  lg: 12
                }}
                canvas
              >
                <Element
                  id={getUniqueId()}
                  is={
                    CraftJsUserComponents[
                    thisComingNode.element.data.displayName as keyof typeof CraftJsUserComponents
                    ]
                  }
                  {...thisComingNode.element.data.props}
                />
              </Element>
            </Element>
          </Element>
        )
        .toNodeTree();

      actionCreator.addNodeTree(containerNodeTree, currentNode.id, thisComingNode.index);
      return;
    }
  };
  useEffect(() => {
    currentNode.data?.nodes.map(nodeId => {
      const nodeDetail = query.node(nodeId).get();
      const isListIcon = nodeDetail?.data.displayName === 'CraftStateRenderer';

      if (isListIcon && nodeDetail?.data.props.craftState) {
        const indexToPlaceElement = currentNode.data.nodes.findIndex(i => i == nodeId);
        handleTemplateSelectionSubmit(
          craftToJSON(nodeDetail?.data.props.craftState),
          indexToPlaceElement
        );
        actionCreator.delete(nodeId);
        return;
      }
      if (!nodeDetail) return;

      // @ts-ignore
      if (containerDependingComponent.includes(nodeDetail.data.name)) {
        const shouldHideWarning = !!localStorage.getItem('meetovoShouldHideContainerCreateWarning');
        const thisComingNode = {
          // @ts-ignore
          element: query.parseFreshNode(nodeDetail).toNode((n: any) => {
            n.id = getUniqueId();
            n.data.nodes = [];
            return n;
          }) as Node,
          index: query
            .node(RootId)
            .descendants(false)
            .indexOf(nodeId)
        };
        const actionCreator = actions.history.throttle();
        setActionCreator(actionCreator);
        actionCreator.delete(nodeId);
        if (!shouldHideWarning) {
          setComingNode(thisComingNode);
          setVisible(true);
        } else handleAddNewContainer(thisComingNode);
      }
    });
  }, [currentNode.data?.nodes]);

  const getAddContainerNodeId = () => {
    return currentNode.data?.nodes.find(element => {
      if (query.node(element).get()?.data.name === CRAFT_ELEMENTS.ADD_CONTAINER) {
        return element;
      }
    });
  };

  useEffect(() => {
    let toBeDeleted = getAddContainerNodeId();
    if (toBeDeleted) {
      actions.history.ignore().delete(getAddContainerNodeId() as string);
    }
  }, []);

  const handleTemplateSelectionSubmit = (
    selectedTemplate: SelectedTemplate,
    indexToPlaceAt: number
  ) => {
    const newNodes: Nodes = {};
    Object.keys(selectedTemplate.nodes).forEach(key => {
      if (selectedTemplate.nodes[key].displayName === CRAFT_ELEMENTS.CONTAINER) {
        selectedTemplate.nodes[key].props.showSaveableModal = false;
      }
      newNodes[key] = query.parseSerializedNode(selectedTemplate.nodes[key]).toNode();
    });
    // @ts-ignore
    selectedTemplate?.ids.forEach((nodeId, index) => {
      const clonedTree = getClonedTree(
        {
          rootNodeId: nodeId,
          nodes: newNodes
        },
        query
      );

      let actionCreator = actions.history.throttle(500);
      actionCreator.addNodeTree(clonedTree, id, indexToPlaceAt);
      if (index === 0) {
        actions.selectNode(clonedTree.rootNodeId);
      }
    });
  };
  return (
    <>
      <div {...props} ref={(ref: any) => connect(drag(ref))}>
        {!currentNode.data?.nodes.length && <AddContainerComponentV2 />}
        {children}
      </div>
      {localStorage.getItem('meetovoDebugOldLeadQualifier') === 'true' && (
        <SelectValidLeadQualifier />
      )}
      <WarningModalWithHideForeverCheckbox
        visible={visible}
        setVisible={setVisible}
        onSubmit={handleAddNewContainer}
        onCancel={() => {
          actions.history.undo();
        }}
        title='Hier ist noch kein Abschnitt. Soll einer erstellt werden?'
        content={
          <>
            <p>
              Darstellungselemente wie Text, Bild, Icon usw. müssen in übergeordneten Abschnitten
              plaziert werden. Erfahre in <a href="#">diesem Video</a>, wie du mit Abschnitten das
              perfekte Design erstellst.
            </p>
          </>
        }
        localStorageKey={'meetovoShouldHideContainerCreateWarning'}
      />
    </>
  );
};

export default MainContainerComponent;

MainContainerComponent.craft = {
  name: CRAFT_ELEMENTS.MAIN_CONTAINER,
  rules: {
    canMoveIn: (selectedNode: any) => {
      return (
        selectedNode[0].data.displayName === CRAFT_ELEMENTS.CONTAINER ||
        selectedNode[0].data.displayName === CRAFT_ELEMENTS.ADD_CONTAINER ||
        selectedNode[0].data.displayName === CRAFT_ELEMENTS.DIVIDER ||
        selectedNode[0].data.displayName === CRAFT_ELEMENTS.ELEMENT_APPENDER ||
        selectedNode[0].data.displayName === CRAFT_ELEMENTS.CRAFT_STATE_RENDERER ||
        containerDependingComponent.includes(selectedNode[0].data.displayName)
      );
    }
  }
};
