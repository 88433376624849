import React from 'react';
import { useNode } from '@craftjs/core';
import { FaPlus } from 'react-icons/fa';
import { CRAFT_ELEMENTS } from '../../config/craftElements';
import { useSelectedElementTab } from '../../hooks/useSelectedElementTab';
import { addNewTemplateHandler } from '../../helper/sharedFunctions';
import useGeneralSettingsDataSave from '../../hooks/useGeneralSettingsDataSave';

interface Props {}

export const AddContainerComponent = ({ ...props }: Props) => {
  const {
    connectors: { connect, drag }
  } = useNode();
  const selectedElementTab = useSelectedElementTab();
  const { handleGeneralSettingsDataSave } = useGeneralSettingsDataSave();

  return (
    <div
      {...props}
      ref={(ref: any) => {
        connect(drag(ref));
      }}
      className="d-flex justify-content-center align-items-center mt-1 py-4"
    >
      <div
        className="add-button-green circular-plus-button position-static container__add-wrapper-icon"
        onClick={e => addNewTemplateHandler(e, selectedElementTab, handleGeneralSettingsDataSave)}
      >
        <FaPlus className="container-plus-icon" />
      </div>
    </div>
  );
};

AddContainerComponent.craft = {
  name: CRAFT_ELEMENTS.ADD_CONTAINER
};
