export const WidgetMessages = {
  getAllWidgetsError:
    'Es gab einen Fehler beim Laden der WidgetF-Informationen. Bitte versuche es erneut.',
  noMoreWidgetsAvailable: (widgetCount: number, maxWidgetsCount: number) =>
    `Du hast bereits ${widgetCount}/${maxWidgetsCount} aktive Widgets`,
  deleteWidgetSuccess: 'Das Widget wurde erfolgreich gelöscht.',
  deleteWidgetError: 'Es gab einen Fehler beim Löschen des Widgets. Bitte versuche es erneut.',
  moveWidgetToFolderSuccessfully: 'Das Widget wurde verschoben',
  widgetNotMove: 'Das Widget konnte nicht verschoben werden. Bitte versuche es erneut.',
  moveWidgetFromFolderMessageSuccessfully:'Das Widget wurde verschoben',
  createWidgetSuccessfully:"Widget Erfolgreich erstellt",
  createWidgetError:"UniqueIdentifier ist bereits vorhanden",
  widgetDeactivate:"Widget wurde deaktiviert",
  widgetActivate:"Widget wurde aktiviert",
  uniqueIdentifierUpdateSuccessfully:"UniqueIdentifier wurde erfolgreich aktualisiert",
  uniqueIdentifierUpdateError:"UniqueIdentifier nicht aktualisieren",
  titleUpdatedSusccessfully: "Der Titel wurde erfolgreich aktualisiert",
  titleUpdateError: "Titel nicht aktualisiert"
};

export const WidgetFormRules = {
  uniqueIdentifier: [{ required: true, message: 'Bitte definiere eine URL für deinen Widget.' }],
  title: [{ required: true, message: 'Gib deinem Widget einen Namen.' }],
};
