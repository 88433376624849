import React, { useEffect, useState } from 'react';
import { convertMinutesToWeeksDaysHoursMinutes } from '../../helper/sharedFunctions';
import ScheduledSelectList from './ScheduledSelectList';
import { scheduledNotificationSelectListData } from './utils';
import { SelectedScheduledTime, convertTimeToMinutes } from '../../helper/convertTimeToMinutes';
import { EmailTemplateType, MessagingTemplateTabType } from '../../interfaces/builderSliceTypes';
import { useAppDispatch } from '../../../redux/hooks';
import { editFunnelSavedIndicator, useIsFunnelSaved } from '../../redux/builderSlice';

export default function ScheduledNotificationTemplateSelectList({
  scheduledTime,
  onChange,
  data
}: {
  scheduledTime: number | undefined;
  onChange: (e: EmailTemplateType | MessagingTemplateTabType) => void;
  data: EmailTemplateType | MessagingTemplateTabType;
}) {
  const dispatch = useAppDispatch();
  const isSaved = useIsFunnelSaved();
  const [selectedScheduledTime, setSelectedScheduledTime] = useState<SelectedScheduledTime>({
    days: 0,
    weeks: 0,
    hours: 0,
    minutes: 0
  });

  useEffect(() => {
    if (scheduledTime) {
      const convertedTime = convertMinutesToWeeksDaysHoursMinutes(scheduledTime);
      setSelectedScheduledTime(convertedTime);
    }
  }, [scheduledTime]);

  return (
    <ScheduledSelectList
      selectListData={scheduledNotificationSelectListData}
      handleChange={(value: number, key: string) => {
        const scheduledTime = convertTimeToMinutes({ ...selectedScheduledTime, [key]: value });
        onChange({
          ...data,
          scheduledTime
        });
        setSelectedScheduledTime(prevSelectedTime => ({
          ...prevSelectedTime,
          [key]: value
        }));
        if (isSaved) {
          dispatch(editFunnelSavedIndicator(false));
        }
      }}
      selectedTime={selectedScheduledTime}
    />
  );
}
