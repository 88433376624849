import gql from 'graphql-tag';

export interface setWidgetHub {
  id: number;
  craftState?: string;
  folderId?: number | null;
  uniqueIdentifier?: string;
  enabled?: boolean;
}

export const UPDATE_WIDGET = gql`
  mutation setWidgetHub($input: SetWidgetHubInput!, $funnelIds: [Int]) {
    setWidgetHub(input: $input, funnelIds: $funnelIds)
  }
`;

export default function setWidgetHub(input: setWidgetHub, funnelIds?: number[]) {
  return window.apolloClient.mutate({
    mutation: UPDATE_WIDGET,
    variables: { input, funnelIds: funnelIds }
  });
}
