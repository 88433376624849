import { Alert, Button, Checkbox, Form, Input, message, Modal, Select, Upload } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { EditOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { compressBase64Image, getImageDimensionsFromFile } from './helper/cropImage';
import { getObjectId } from '../helper/id';
import { ImageUploadV2Messages } from '../config/messages';
import { useMutation } from '@apollo/react-hooks';
import { UPLOAD_IMAGE } from '../Funnel/helper/uploadImages';
import DefaultSmallLoader from '../SharedUI/components/DefaultSmallLoader';
import {
  CropperRef,
  Cropper,
  CircleStencil,
  RectangleStencil,
  ImageRestriction,
  Priority
} from 'react-advanced-cropper';
import { CloseOutlined } from '@ant-design/icons';
import 'react-advanced-cropper/dist/style.css';
import ImageSizeSelectorV3 from './CropSizeSelectors/ImageSizeSelectorV3';
import { defaultFileFieldValidation } from '../UI/utils/formValidation';
import { useAppDispatch } from '../redux/hooks';
import { getAllImagesTagsThunk, getImagesWithPaginationThunk } from '../Library/redux/libraryThunk';
import { useAllTags } from '../Library/redux/librarySlice';
import ImageSelectModal from '../Library/components/ImageSelectModal';
import { GrGallery } from 'react-icons/gr';
import ConfirmationForCropper from './ConfirmationForCropper';
import { pixelImageQuality } from '../constants';

export enum CropperShape {
  RECTANGLE = 'rect',
  ROUND = 'round'
}

export interface CropperSettings {
  aspectRatio?: number;
  minAspectRatio?: number;
  maxAspectRatio?: number;
  imageRestriction?: ImageRestriction;
  stencilType: CropperShape;
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  maxHeight?: number;
  scaleImage?: boolean;
  grid?: boolean;
}

type Props = {
  className?: string;
  onChange: (
    data: string | any,
    imageShape: CropperShape,
    aspectRatio?: { height: number; width: number }
  ) => any;
  maxWidth?: number;
  maxHeight?: number;
  previewImage?: string;
  quality?: number;
  shape?: CropperShape;
  grid?: boolean;
  uploadedImage?: boolean;
  loading?: boolean;
  restrictPosition?: boolean;
  existingImageURL?: string | undefined;
  minZoom?: number;
  onModalCancel?: () => void;
  minHeight: number;
  minWidth: number;
  possibleAspectRatios?: { height: number; width: number }[];
  circleEnabled?: boolean;
  rectangleEnabled?: boolean;
  freeSelectionEnabled?: boolean;
  showRatioSelector?: boolean;
  setImageUploadLoadingForParent?: (loading: boolean) => void;
  cropperIsNotMandatoryAfterSelectionFromLibrary?: boolean;
};
export interface ImageCropperUITypes {
  shape: 'rect' | 'round';
  aspectRatio: { height: number; width: number };
}

const ImageUploadV3 = ({
  className,
  onChange,
  maxWidth: propMaxWidth = 1365,
  maxHeight: propMaxHeight = 2000,
  previewImage,
  quality,
  shape = CropperShape.RECTANGLE,
  grid,
  uploadedImage,
  loading,
  existingImageURL,
  minHeight: propMinHeight,
  minWidth: propMinWidth,
  onModalCancel = () => {},
  circleEnabled = true,
  rectangleEnabled = true,
  freeSelectionEnabled = true,
  possibleAspectRatios = [],
  showRatioSelector = true,
  setImageUploadLoadingForParent,
  cropperIsNotMandatoryAfterSelectionFromLibrary = true
}: Props) => {
  const cropperRef = useRef<CropperRef>(null);
  const [cropLoading, setCropLoading] = useState(false);
  const [uploadImage, { loading: imageUploadLoading }] = useMutation(UPLOAD_IMAGE);
  const [form] = Form.useForm();

  const [uid] = useState(getObjectId());
  const [images, setImages] = useState<{ fileList: any[] }>({
    fileList: []
  });
  const [imageToCrop, setImageToCrop] = useState<string>('');
  const [isImageCropRequiredModalVisible, setIsImageCropRequiredModalVisible] = useState<boolean>(
    false
  );
  const [selectedImageFromLibrary, setSelectedImageFromLibrary] = useState<string>('');
  const [showFieldsNeededToSaveImageInLibrary, setShowFieldsNeededToSaveImageInLibrary] = useState(
    false
  );
  const [cropLoadingImage, setCropLoadingImage] = useState<boolean>(true);
  const [pixelImage, setPixelImage] = useState<string>('');
  const checkIfExistingImage = existingImageURL || pixelImage;
  const [settings, setSettings] = useState<CropperSettings>({
    aspectRatio: propMinWidth / propMinHeight,
    minAspectRatio: undefined,
    maxAspectRatio: undefined,
    imageRestriction: ImageRestriction.fitArea,
    minWidth: undefined,
    maxWidth: undefined,
    minHeight: undefined,
    maxHeight: undefined,
    scaleImage: true,
    grid: true,
    stencilType: shape || CropperShape.RECTANGLE
  });

  const [state, setState] = useState({ arbitraryUrlParameter: '', retriesLeft: 10 });
  const { arbitraryUrlParameter } = state;
  const imageUploadRef = useRef<HTMLDivElement>(null);

  const handleSetArbitraryUrlParameter = () => {
    if (state.retriesLeft < 1) return;

    setState({
      arbitraryUrlParameter: `?arbitraryUrlParameter=${Date.now() +
        Math.floor(Math.random() * 1000)}`,
      retriesLeft: state.retriesLeft - 1
    });
  };

  useEffect(() => {
    if (!imageToCrop) {
      setShowFieldsNeededToSaveImageInLibrary(false);
    }
  }, [imageToCrop]);

  useEffect(() => {
    setCropLoadingImage(true);
    setImageToCrop(existingImageURL || '');
  }, [existingImageURL]);

  useEffect(() => {
    if (setImageUploadLoadingForParent) setImageUploadLoadingForParent(imageUploadLoading);
  }, [imageUploadLoading]);

  useEffect(() => {
    if (previewImage) {
      setImages({
        fileList: [
          {
            uid,
            name: `preview-image__${uid}`,
            status: 'done',
            url: previewImage
          }
        ]
      });
    } else {
      setImages({
        fileList: []
      });
    }
  }, [previewImage]);

  const dispatch = useAppDispatch();

  const availableTags = useAllTags();

  useEffect(() => {
    if (!availableTags.length) dispatch(getAllImagesTagsThunk());
  }, []);

  const handleUpload = async (image: any) => {
    const name = form.getFieldValue('name');
    const tags = form.getFieldValue('tags');
    uploadImage({
      variables: {
        input: [
          {
            imageURL: image,
            ...(showFieldsNeededToSaveImageInLibrary && { name, tags, showInLibrary: true })
          }
        ]
      }
    })
      .then(res => {
        const imageLink = res.data.uploadImages[0].imageLink;
        if (imageLink) onChange(imageLink, stencilType);
        if (showFieldsNeededToSaveImageInLibrary) {
          dispatch(getImagesWithPaginationThunk({}));
          dispatch(getAllImagesTagsThunk());
        }
      })
      .catch(e => {
        message.error('Das Bild konnte nicht hochgeladen werden. Bitte versuche es erneut.');
      });
  };

  const handleReplace = async () => {
    onChange('', CropperShape.RECTANGLE);
  };

  const handleChange = async ({ fileList }: any) => {
    if (!fileList[0]) {
      onChange('', settings.stencilType);
      setImages({ fileList: [] });
      return;
    }
    const file = fileList[0];
    if (file) {
      form.setFieldsValue({ name: file.name });
    }
    const sizeInMb = file.size / 1024 ** 2;
    if (sizeInMb > 8) {
      message.error(ImageUploadV2Messages.max8MbImage);
      return false;
    }
    const dimensions = await getImageDimensionsFromFile(file.originFileObj);
    if (dimensions.width > propMinWidth && dimensions.height < propMinWidth) {
      message.error(ImageUploadV2Messages.imageNotBigEnough(propMinWidth, propMinHeight));
      return false;
    }
    const imageFile = URL.createObjectURL(file.originFileObj);
    setImageToCrop(imageFile);
    return;
  };

  const handleCancel = () => {
    setImageToCrop('');
    onModalCancel();
    form.resetFields();
    setCropLoadingImage(true);
  };
  const handleCropImage = async () => {
    if (cropperRef.current) {
      setCropLoading(true);
      const { height, left, top, width } = cropperRef.current.getCoordinates() || {};
      if (checkIfExistingImage) {
        let imgUrl = `${checkIfExistingImage}&rect=${left},${top},${width},${height}&fit=fill&fill=solid&fill-color=${'transparent'}`;
        if (settings.stencilType === CropperShape.ROUND) {
          imgUrl = imgUrl.replace('fm=jpg', 'fm=webp');
          imgUrl +=
            '&border=1,FFFFFF&border-radius=100000,100000,100000,100000&border-radius-inner=0,0,0,0';
        }
        imgUrl = imgUrl.replace(`q=${pixelImageQuality}`, 'q=80');
        onChange(imgUrl, settings.stencilType);
        setCropLoading(false);
        setImages({
          fileList: []
        });
        setImageToCrop('');
        setPixelImage('');
        return;
      }

      const url = await compressBase64Image(
        cropperRef.current.getCanvas()?.toDataURL() || '',
        quality || 1,
        propMaxWidth,
        propMaxHeight
      );

      setCropLoading(false);
      if (uploadedImage) {
        handleUpload(url);

        form.resetFields();
      } else {
        onChange(url, stencilType);
        form.resetFields();
      }
      handleCancel();
    }
  };

  const handleModalClose = () => {
    setCropLoadingImage(true);
    if (!checkIfExistingImage) {
      return form.submit();
    } else {
      return handleCropImage();
    }
  };

  const handleOkOfConfirmationForCropper = () => {
    setCropLoadingImage(true);
    setImageToCrop(selectedImageFromLibrary);
    setIsImageCropRequiredModalVisible(false);
  };

  const handleCancelOfConfirmationForCropper = () => {
    onChange(selectedImageFromLibrary, stencilType);
    setIsImageCropRequiredModalVisible(false);
  };

  const { fileList } = images;

  const uploadProps = {
    showUploadList: {
      showDownloadIcon: false,
      downloadIcon: <EditOutlined className="replace-icon-style" onClick={handleReplace} />,
      showPreviewIcon: false
    },
    response: false
  };

  const noImages = fileList.length === 0;
  const imageLoading = loading || imageUploadLoading;

  const {
    minHeight,
    minWidth,
    maxWidth,
    maxHeight,
    aspectRatio,
    maxAspectRatio,
    minAspectRatio,
    imageRestriction,
    stencilType,
    scaleImage
    // grid
  } = settings;

  const stencilProps = {
    aspectRatio,
    maxAspectRatio,
    minAspectRatio,
    grid
  };

  //@ts-ignore
  const shouldRenderLibraryButtonLabel = imageUploadRef?.current?.clientWidth >= 100;

  const tempImageToCrop = imageToCrop.includes('blob')
    ? imageToCrop
    : imageToCrop + (!!imageToCrop ? arbitraryUrlParameter : '');

  return (
    <div
      className={
        'image-upload-v3 image-upload__container' +
        (noImages ? ' image-upload__no-images' : '') +
        ' ' +
        className
      }
      ref={imageUploadRef}
    >
      <Upload
        {...uploadProps}
        accept="gif,.jpg,.png,.jpeg"
        listType="picture-card"
        fileList={fileList}
        onChange={handleChange}
        className={'image-upload__upload-field-' + uid}
      >
        {imageLoading ? <DefaultSmallLoader loading={true} /> : noImages && <CloudUploadOutlined />}
      </Upload>
      <ImageSelectModal
        stockImageQuality={pixelImageQuality}
        handleLibraryImage={image => {
          if (cropperIsNotMandatoryAfterSelectionFromLibrary) {
            setSelectedImageFromLibrary(image?.imageLink);
            setIsImageCropRequiredModalVisible(true);
          } else {
            setCropLoadingImage(true);
            setImageToCrop(image?.imageLink);
          }
        }}
        trigger={
          <Button className={`image-upload__open-library overflow-hidden break-word ${shouldRenderLibraryButtonLabel && "text-truncate"}`}>
            <GrGallery /> 
            {shouldRenderLibraryButtonLabel ? 'Archiv / Stockfotos' : null}
          </Button>
        }
        handleStockImage={img => {
          setPixelImage(img);
          setImageToCrop(img);
        }}
      />
      <Modal
        title="Zuschneiden"
        centered
        visible={!!imageToCrop}
        onOk={handleModalClose}
        closeIcon={<CloseOutlined onClick={handleCancel} />}
        width={600}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Abbrechen
          </Button>,
          <Button key="submit" type="primary" loading={cropLoading} onClick={handleModalClose}>
            Speichern
          </Button>
        ]}
      >
        {!cropLoadingImage && showRatioSelector && (
          <ImageSizeSelectorV3
            settings={settings}
            setSettings={setSettings}
            circleEnabled={circleEnabled}
            rectangleEnabled={rectangleEnabled}
            freeSelectionEnabled={freeSelectionEnabled}
            possibleAspectRatios={possibleAspectRatios}
          />
        )}
        <div className="image-cropper-v3">
          {cropLoadingImage && <DefaultSmallLoader loading />}
          <Cropper
            onError={handleSetArbitraryUrlParameter}
            stencilComponent={
              stencilType === CropperShape.RECTANGLE ? RectangleStencil : CircleStencil
            }
            ref={cropperRef}
            src={tempImageToCrop}
            onReady={() => {
              setCropLoadingImage(false);
            }}
            style={{
              maxHeight: '500px'
            }}
            minHeight={minHeight}
            minWidth={minWidth}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            priority={
              imageRestriction === ImageRestriction.fillArea
                ? Priority.visibleArea
                : Priority.coordinates
            }
            stencilProps={stencilProps}
            transformImage={{
              adjustStencil: imageRestriction !== 'stencil' && imageRestriction !== 'none'
            }}
            backgroundWrapperProps={{
              scaleImage
            }}
            imageRestriction={imageRestriction}
          />
        </div>
        {!cropLoadingImage && (
          <Alert
            message="Tipp: Zum Zoomen nutze das Scrollrad an deiner Maus oder zwei finger an deinem Touchpad."
            type="info"
            showIcon
          />
        )}
        {!checkIfExistingImage && (
          <Form
            form={form}
            name="basic"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={data => {
              handleCropImage();
            }}
            autoComplete="off"
          >
            {!cropLoadingImage && (
              <Checkbox
                onChange={() => {
                  setShowFieldsNeededToSaveImageInLibrary(!showFieldsNeededToSaveImageInLibrary);
                }}
                checked={showFieldsNeededToSaveImageInLibrary}
              >
                Möchtest du dieses Bild im Archiv speichern?
              </Checkbox>
            )}
            {showFieldsNeededToSaveImageInLibrary && (
              <>
                <Form.Item
                  label="Name"
                  name="name"
                  className="mb-1"
                  rules={defaultFileFieldValidation}
                >
                  <Input />
                </Form.Item>
                <Form.Item label="Tags" name="tags" className="mb-1">
                  <Select
                    mode="tags"
                    placeholder="z.B. Vorteile, Kunde XYZ ..."
                    options={availableTags.map((tag: any) => ({
                      label: tag.name,
                      value: tag.name
                    }))}
                  />
                </Form.Item>
              </>
            )}
          </Form>
        )}
      </Modal>

      <ConfirmationForCropper
        visible={!!isImageCropRequiredModalVisible}
        onYes={handleOkOfConfirmationForCropper}
        onCancel={handleCancelOfConfirmationForCropper}
      />
    </div>
  );
};

export default ImageUploadV3;
