export const enhanceStockImage = (url: string, height?: string): string => {
  if (
    !url?.startsWith('https://images.pexels.com/') &&
    !url?.startsWith('https://images.unsplash.com')
  ) {
    return url;
  }

  const hasHeight = !!height || url.includes('&h=');
  const hasWidth = url.includes('&max-w=') || url.includes('&width=');
  const heightParam = height ? `&h=${height}` : '';
  const widthParam = hasWidth || hasHeight ? '' : '&width=1000';
  const finalUrl = (url + widthParam + heightParam).replace('&max-w=', '&width=');

  return finalUrl;
};
