import { Tooltip } from 'antd';
import React, { useState } from 'react';
import { tooltipText } from '../../helper/tooltipText';
import { AiFillSave } from 'react-icons/ai';
import { useEditor, useNode } from '@craftjs/core';
import { useAppDispatch } from '../../../redux/hooks';
import { createTextTemplateThunk } from '../../AdJobBuilder/redux/thunk';
import SaveTemplateNameModal from '../../components/elements/RichTextComponent/components/SaveTemplateNameModal';
import { getUniqueId } from '../../helper/craftJs';
import { SAVEABLE_ELEMENTS } from '../../AdJobBuilder/config/craftElements';

const SaveAction = () => {
  const {
    id,
    props,
    actions: { setProp },
    saveableElementsProp
  } = useNode(node => ({
    props: node.data.props,
    saveableElementsProp: node.data.props?.saveableElements || SAVEABLE_ELEMENTS?.FUNNEL_SECTIONS
  }));

  const {
    actions: { history }
  } = useEditor();

  const dispatch = useAppDispatch();

  const [nameModalVisible, setNameModalVisible] = useState(false);

  const handleSaveAsTemplate = () => {
    switch (saveableElementsProp) {
      case SAVEABLE_ELEMENTS.AD_TEXT_TEMPLATES:
        setNameModalVisible(true);
        break;
      case SAVEABLE_ELEMENTS.FUNNEL_SECTIONS:
        history.ignore().setProp(id, (state: any) => (state.showSaveableModal = true));
        break;
    }
  };

  return (
    <>
      <Tooltip title={tooltipText.save}>
        <li className="mr-2 cursor-move action-icon" onClick={handleSaveAsTemplate}>
          <AiFillSave />
        </li>
      </Tooltip>
      <SaveTemplateNameModal
        visible={nameModalVisible}
        setVisible={setNameModalVisible}
        onSubmit={({ name }) => {
          setNameModalVisible(false);
          const id = getUniqueId();
          dispatch(
            createTextTemplateThunk({
              type: props.elementType,
              title: name,
              text: props.editorState.getCurrentContent().getPlainText()
            })
          );
          setProp((state: any) => {
            state.templateId = id;
          });
        }}
      />
    </>
  );
};

export default SaveAction;
