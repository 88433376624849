import React, { useEffect, useState } from 'react';
import { Button, Row, Col } from 'antd';
import { useFunnelAvailableFonts } from '../../redux/builderSlice';
import BuilderSettingSelectFont from './BuilderSettingSelectFont';
import BuilderSettingUploadFontModal from './BuilderSettingUploadFontModal';
import { AvailableFonts } from '../../interfaces/builderSliceTypes';
import mixpanel from 'mixpanel-browser';
import { mixpanelEvents } from '../../../UI/utils/mixpanel';
import { CloudUploadOutlined } from '@ant-design/icons';
import BuilderSettingsTitle from './BuilderSettingsTitle';

export enum AVAILABLE_FONT_TYPE {
  GOOGLE_FONTS = 'GOOGLE',
  CUSTOM_FONTS = 'CUSTOM'
}

export enum CONNECTED_FONT_TYPE {
  HEADING = 'HEADING',
  TEXT = 'TEXT'
}

const BuilderSettingsFont = () => {
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const funnelAvailableFont = useFunnelAvailableFonts();
  const customFontsStyleId = 'custom-fonts-styles-id';

  useEffect(() => {
    loadFonts(funnelAvailableFont);
    return () => {
      const customFontsStyleElement = document.getElementById(customFontsStyleId);
      if (customFontsStyleElement) {
        customFontsStyleElement.remove();
      }
    };
  }, [funnelAvailableFont]);

  const loadFonts = async (fonts: AvailableFonts[]) => {
    const styleElement = document.createElement('style');
    styleElement.id = customFontsStyleId;
    document.head.appendChild(styleElement);

    let cssRules = '';
    const fontPromises = fonts.map((font: AvailableFonts) => {
      if (font.fontLink.includes('fonts.googleapis')) {
        cssRules += `@import url('${font.fontLink}');\n`;
        return Promise.resolve();
      } else {
        const fontFace = new FontFace(font.fontFamily, `url(${font.fontLink})`);
        return fontFace.load().then(loadedFont => {
          document.fonts.add(loadedFont);
        });
      }
    });

    styleElement.textContent = cssRules;

    try {
      await Promise.all(fontPromises);
    } catch (error) {
      console.error('Error loading fonts:', error);
    }
  };

  return (
    <Row className="builder__settings-sidebar__row">
      <Col span={24} className="mt-2 mb-3 px-4">
        <BuilderSettingsTitle title="Überschrift" />
        <BuilderSettingSelectFont
          availableFonts={funnelAvailableFont}
          connectedFontType={CONNECTED_FONT_TYPE.HEADING}
        />
      </Col>
      <Col span={24} className="mt-1 mb-3 px-4">
        <BuilderSettingsTitle title="Text" />
        <BuilderSettingSelectFont
          availableFonts={funnelAvailableFont}
          connectedFontType={CONNECTED_FONT_TYPE.TEXT}
        />
      </Col>
      <Col span={24} className="mt-1 mb-3 px-4">
        <Button
          type="primary"
          icon={<CloudUploadOutlined />}
          onClick={() => {
            mixpanel.track(mixpanelEvents.OPEN_CUSTOM_FONT_UPLOAD_MODAL);
            setUploadModalVisible(true);
          }}
        >
          Schriftart hochladen
        </Button>
      </Col>
      <BuilderSettingUploadFontModal
        uploadModalVisible={uploadModalVisible}
        setUploadModalVisible={setUploadModalVisible}
      />
    </Row>
  );
};

export default BuilderSettingsFont;
