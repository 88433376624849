const teaserTextData = {
  title: 'Teaser',
  data: [
    {
      "category": "Gesundheitswesen",
      "content": [
        {
          "text": "Gesucht: examinierte Pflegefachkräfte (m/w/d) mit Herz ❤️",
          "title": "[Vorteile] Berufsgruppe + Emoji",
          "id": 100000
        },
        {
          "text": "Du kannst gut anpacken und mit Verantwortung umgehen? Dann haben wir etwas für dich!",
          "title": "[Vorteile] Kannst du gut...?",
          "id": 100001
        },
        {
          "text": "Als Pflegefachkraft (m/w/d) liebst du es, mit Menschen zu arbeiten und hast das Herz am rechten Fleck? Super, genau DICH suchen wir!",
          "title": "[Vorteile] Liebst du...?",
          "id": 100002
        },
        {
          "text": "“Das Team der Muster Gmbh ist inzwischen meine zweite Familie, inzwischen freue ich mich auf Montagmorgen!\" - Maxime Musterfrau, Pflegekraft\r\n\r\nDu willst das auch sagen können? Jetzt als Pflegekraft (m/w/d) in Teil- oder Vollzeit in [Stadt] bewerben!\r\n",
          "title": "[Vorteile] Mitarbeiterzitat",
          "id": 100003
        },
        {
          "text": "Pflegekräfte (m/w/d) und Kunden lieben Muster GmbH 🎉 \r\nDavon willst du dich selbst überzeugen? Dann lies jetzt weiter 👇",
          "title": "[Vorteile] ... dann lies jetzt weiter!",
          "id": 100004
        },
        {
          "text": "✋ Pflegekräfte (m/w/d) in [Stadt] und Umgebung aufgepasst! ✋",
          "title": "[Vorteile] Aufgepasst!",
          "id": 100005
        },
        {
          "text": "Harte Arbeit als Pflegekraft (m/w/d)? Kostenlose Massagen warten auf dich nach der Arbeit! [oder anderes unübliches Benefit]",
          "title": "[Vorteile] Dein Benefit wartet",
          "id": 100006
        },
        {
          "text": "Jetzt Wechselprämie von xxxx€ sichern!",
          "title": "[Vorteile] Wechselprämie",
          "id": 100007
        },
        {
          "text": "Wieso Pflegekräfte (m/w/d) zu uns wechseln? 🤔",
          "title": "[Vorteile] Warum wechseln sie zu uns?",
          "id": 100008
        },
        {
          "text": "Du suchst die perfekte Ausbildung als Pflegekraft (m/w/d) in deiner Nähe?",
          "title": "[Quiz] Ausbildung in der Nähe?",
          "id": 100155
        },
        {
          "text": "Du kannst gut anpacken und mit Verantwortung umgehen? Dann starte jetzt als [z.B. Azubi oder Quereinsteiger]",
          "title": "[Quiz] Kannst du gut...?",
          "id": 100156
        },
        {
          "text": "Du liebst es, mit Menschen zu arbeiten und hast das Herz am rechten Fleck? Super, dann hast du die perfekten Voraussetzungen als Pflegekraft (m/w/d)",
          "title": "[Quiz] Liebst du...?",
          "id": 100157
        },
        {
          "text": "[Emotionales 2-Zeiler eines Azubis: z.B. Das Team der Muster Gmbh ist inzwischen meine zweite Familie, inzwischen freue ich mich auf Montagmorgen. Die beste Entscheidung, hier meine Ausbildung gestartet zu haben!\"]",
          "title": "[Quiz] Mitarbeiterzitat",
          "id": 100158
        },
        {
          "text": "Warum Pflegekräfte (m/w/d) den besten Start in das Arbeitsleben haben. Lies jetzt weiter 👇",
          "title": "[Quiz] Bester Start ins Arbeitsleben",
          "id": 100159
        },
        {
          "text": "✋ Finde heraus, ob du Karriere als Pflegekraft (m/w/d) machen kannst! ✋",
          "title": "[Quiz] Finde heraus...",
          "id": 100160
        },
        {
          "text": "[z.B. 5] Gründe, warum Pflegekräfte (m/w/d) den besten Job haben!",
          "title": "[Quiz] Gründe, warum...",
          "id": 100161
        },
        {
          "text": "Du willst als Pflegekraft (m/w/d) arbeiten, aber dir fehlt die Vorstellung, wie das abläuft? ⏫ Du bist Quereinsteiger, Anfänger oder Wiedereinsteiger? Kein Problem!",
          "title": "[Quiz] Fehlende Vorstellung",
          "id": 100162
        },
        {
          "text": "#MeetMaxi - vom [z.B. Quereinsteiger] zum [z.B. Teamleiter als Head of …]",
          "title": "[Story] #MeetName",
          "id": 100275
        },
        {
          "text": "[Kurzer Moment aus der Geschichte, der neugierig macht: z.B. “Und dafür hat er wirklich eine Gehaltserhöhung bekommen! 😂]",
          "title": "[Story] Kurzer Moment",
          "id": 100276
        },
        {
          "text": "Das könntest DU sein! Lies hier 👇",
          "title": "[Story] ... lies hier",
          "id": 100277
        },
        {
          "text": "[Spaßiges Erlebnis, das auch auf dem Bild zu sehen ist, z.B. “Mit dem Rollwagen durch unser Lager düsen. Mach’s wie Max. Habe Spaß bei der Arbeit 😉”]",
          "title": "[Story] Spaßiges Erlebnis",
          "id": 100278
        }
      ]
    },
    {
      "category": "Handwerk",
      "content": [
        {
          "text": "Gesucht: Handwerker (m/w/d) mit Power 💪",
          "title": "[Vorteile] Berufsgruppe + Emoji",
          "id": 100031
        },
        {
          "text": "Du kannst gut anpacken und Staub macht dir nichts aus? Dann haben wir etwas für dich!",
          "title": "[Vorteile] Kannst du gut...?",
          "id": 100032
        },
        {
          "text": "Als Handwerker (m/w/d) liebst du es, dich auszupowern und bist gerne dabei, wenn etwas Großartiges gebaut wird? Top, genau DICH suchen wir!",
          "title": "[Vorteile] Liebst du...?",
          "id": 100033
        },
        {
          "text": "“Das Team der Muster Gmbh ist inzwischen meine zweite Familie, inzwischen freue ich mich auf Montagmorgen!\" - Max Mustermann, Handwerker\r\n\r\nDu willst das auch sagen können? Jetzt als [Berufsgruppe] (m/w/d) in Teil- oder Vollzeit in [Stadt] bewerben!\r\n",
          "title": "[Vorteile] Mitarbeiterzitat",
          "id": 100034
        },
        {
          "text": "Handwerker (m/w/d) und Kunden lieben Muster GmbH 🎉 \r\nDavon willst du dich selbst überzeugen? Dann lies jetzt weiter 👇",
          "title": "[Vorteile] ... dann lies jetzt weiter!",
          "id": 100035
        },
        {
          "text": "✋ Handwerker (m/w/d) in [Stadt] und Umgebung aufgepasst! ✋",
          "title": "[Vorteile] Aufgepasst!",
          "id": 100036
        },
        {
          "text": "Den Tag über körperlich als Handwerker (m/w/d) angestrengt? Kostenlose Massagen warten auf dich nach der Arbeit! [oder anderes unübliches Benefit]",
          "title": "[Vorteile] Dein Benefit wartet",
          "id": 100037
        },
        {
          "text": "Jetzt Wechselprämie von xxxx€ sichern!",
          "title": "[Vorteile] Wechselprämie",
          "id": 100038
        },
        {
          "text": "Wieso Handwerker zu uns wechseln? 🤔",
          "title": "[Vorteile] Warum wechseln sie zu uns?",
          "id": 100039
        },
        {
          "text": "Du suchst die perfekte Ausbildung als Handwerker (m/w/d) in deiner Nähe?",
          "title": "[Quiz] Ausbildung in der Nähe?",
          "id": 100179
        },
        {
          "text": "Du kannst gut anpacken und Staub macht dir nichts aus? Dann starte jetzt als [z.B. Azubi oder Quereinsteiger]",
          "title": "[Quiz] Kannst du gut...?",
          "id": 100180
        },
        {
          "text": "Du liebst es, dich auszupowern und bist gerne dabei, wenn etwas Großartiges gebaut wird? Super, dann hast du die perfekten Voraussetzungen als Handwerker (m/w/d)",
          "title": "[Quiz] Liebst du...?",
          "id": 100181
        },
        {
          "text": "[Emotionales 2-Zeiler eines Azubis: z.B. Das Team der Muster Gmbh ist inzwischen meine zweite Familie, inzwischen freue ich mich auf Montagmorgen. Die beste Entscheidung, hier meine Ausbildung gestartet zu haben!\"]",
          "title": "[Quiz] Mitarbeiterzitat",
          "id": 100182
        },
        {
          "text": "Warum Handwerker (m/w/d) den besten Start in das Arbeitsleben haben. Lies jetzt weiter 👇",
          "title": "[Quiz] Bester Start ins Arbeitsleben",
          "id": 100183
        },
        {
          "text": "✋ Finde heraus, ob du Karriere als Handwerker (m/w/d) machen kannst! ✋",
          "title": "[Quiz] Finde heraus...",
          "id": 100184
        },
        {
          "text": "[z.B. 5] Gründe, warum Handwerker (m/w/d) den besten Job haben!",
          "title": "[Quiz] Gründe, warum...",
          "id": 100185
        },
        {
          "text": "Du willst als Handwerker (m/w/d) arbeiten, aber dir fehlt die Vorstellung, wie das abläuft? ⏫ Du bist Quereinsteiger, Anfänger oder Wiedereinsteiger? Kein Problem!",
          "title": "[Quiz] Fehlende Vorstellung",
          "id": 100186
        },
        {
          "text": "#MeetMaxi - vom [z.B. Quereinsteiger] zum [z.B. Teamleiter als Head of …]",
          "title": "[Story] #MeetName",
          "id": 100299
        },
        {
          "text": "[Kurzer Moment aus der Geschichte, der neugierig macht: z.B. “Und dafür hat er wirklich eine Gehaltserhöhung bekommen! 😂]",
          "title": "[Story] Kurzer Moment",
          "id": 100300
        },
        {
          "text": "Das könntest DU sein! Lies hier 👇",
          "title": "[Story] ... lies hier",
          "id": 100301
        },
        {
          "text": "[Spaßiges Erlebnis, das auch auf dem Bild zu sehen ist, z.B. “Mit dem Rollwagen durch unser Lager düsen. Mach’s wie Max. Habe Spaß bei der Arbeit 😉”]",
          "title": "[Story] Spaßiges Erlebnis",
          "id": 100302
        }
      ]
    },
    {
      "category": "Handel & Vertrieb",
      "content": [
        {
          "text": "Gesucht: Kaufmänner/frauen mit Gespür für Zahlen 🧮",
          "title": "[Vorteile] Berufsgruppe + Emoji",
          "id": 100062
        },
        {
          "text": "Du kannst gut mit Zahlen jonglieren und dir Dinge merken? Dann haben wir etwas für dich!",
          "title": "[Vorteile] Kannst du gut...?",
          "id": 100063
        },
        {
          "text": "Als Kaufmann/frau liebst du es, Preise zu kalkulieren und analytisches Denken fällt dir leicht? Top, genau DICH suchen wir!",
          "title": "[Vorteile] Liebst du...?",
          "id": 100064
        },
        {
          "text": "“Das Team der Muster Gmbh ist inzwischen meine zweite Familie, inzwischen freue ich mich auf Montagmorgen!\" - Max Mustermann, Kaufmann\r\n\r\nDu willst das auch sagen können? Jetzt als Kaufmann/frau in Teil- oder Vollzeit in [Stadt] bewerben!\r\n",
          "title": "[Vorteile] Mitarbeiterzitat",
          "id": 100065
        },
        {
          "text": "Kaufleute und Kunden lieben Muster GmbH 🎉 \r\nDavon willst du dich selbst überzeugen? Dann lies jetzt weiter 👇\r\n",
          "title": "[Vorteile] ... dann lies jetzt weiter!",
          "id": 100066
        },
        {
          "text": "✋ Kaufmann/frau in [Stadt] und Umgebung aufgepasst! ✋",
          "title": "[Vorteile] Aufgepasst!",
          "id": 100067
        },
        {
          "text": "Den ganzen Tag den Kopf angestrengt? Kostenlose Massagen warten auf dich nach der Arbeit! [oder anderes unübliches Benefit]",
          "title": "[Vorteile] Dein Benefit wartet",
          "id": 100068
        },
        {
          "text": "Jetzt Wechselprämie von xxxx€ sichern!",
          "title": "[Vorteile] Wechselprämie",
          "id": 100069
        },
        {
          "text": "Wieso Kaufleute zu uns wechseln? 🤔",
          "title": "[Vorteile] Warum wechseln sie zu uns?",
          "id": 100070
        },
        {
          "text": "Du suchst die perfekte Ausbildung als Kaufmann/frau (m/w/d) in deiner Nähe?",
          "title": "[Quiz] Ausbildung in der Nähe?",
          "id": 100203
        },
        {
          "text": "Du kannst gut mit Zahlen jonglieren und dir Dinge merken? Dann starte jetzt als [z.B. Azubi oder Quereinsteiger]",
          "title": "[Quiz] Kannst du gut...?",
          "id": 100204
        },
        {
          "text": "Du liebst es, Preise zu kalkulieren und analytisches Denken fällt dir leicht? Super, dann hast du die perfekten Voraussetzungen als Handwerker (m/w/d)",
          "title": "[Quiz] Liebst du...?",
          "id": 100205
        },
        {
          "text": "[Emotionales 2-Zeiler eines Azubis: z.B. Das Team der Muster Gmbh ist inzwischen meine zweite Familie, inzwischen freue ich mich auf Montagmorgen. Die beste Entscheidung, hier meine Ausbildung gestartet zu haben!\"]",
          "title": "[Quiz] Mitarbeiterzitat",
          "id": 100206
        },
        {
          "text": "5. Warum Kaufleute (m/w/d) den besten Start in das Arbeitsleben haben. Lies jetzt weiter 👇",
          "title": "[Quiz] Bester Start ins Arbeitsleben",
          "id": 100207
        },
        {
          "text": "✋ Finde heraus, ob du Karriere als Kaufmann/frau (m/w/d) machen kannst! ✋",
          "title": "[Quiz] Finde heraus...",
          "id": 100208
        },
        {
          "text": "[z.B. 5] Gründe, warum Kaufleute (m/w/d) den besten Job haben!",
          "title": "[Quiz] Gründe, warum...",
          "id": 100209
        },
        {
          "text": "Du willst als Kaufleute (m/w/d) arbeiten, aber dir fehlt die Vorstellung, wie das abläuft? ⏫ Du bist Quereinsteiger, Anfänger oder Wiedereinsteiger? Kein Problem!",
          "title": "[Quiz] Fehlende Vorstellung",
          "id": 100210
        },
        {
          "text": "#MeetMaxi - vom [z.B. Quereinsteiger] zum [z.B. Teamleiter als Head of …]",
          "title": "[Story] #MeetName",
          "id": 100323
        },
        {
          "text": "[Kurzer Moment aus der Geschichte, der neugierig macht: z.B. “Und dafür hat er wirklich eine Gehaltserhöhung bekommen! 😂]",
          "title": "[Story] Kurzer Moment",
          "id": 100324
        },
        {
          "text": "Das könntest DU sein! Lies hier 👇",
          "title": "[Story] ... lies hier",
          "id": 100325
        },
        {
          "text": "[Spaßiges Erlebnis, das auch auf dem Bild zu sehen ist, z.B. “Mit dem Rollwagen durch unser Lager düsen. Mach’s wie Max. Habe Spaß bei der Arbeit 😉”]",
          "title": "[Story] Spaßiges Erlebnis",
          "id": 100326
        }
      ]
    },
    {
      "category": "Gastronomie",
      "content": [
        {
          "text": "Gesucht: Köche (m/w/d) mit Geschmack 🍳",
          "title": "[Vorteile] Berufsgruppe + Emoji",
          "id": 100093
        },
        {
          "text": "Guter Geschmack ist dein siebter Sinn und deine Suppen sind nie versalzen? Dann haben wir etwas für dich!",
          "title": "[Vorteile] Kannst du gut...?",
          "id": 100094
        },
        {
          "text": "Als Koch (m/w/d) liebst du es, neue Gerichte zu kreieren und kannst mit exzellenten Zutaten gut umgehen? Top, genau DICH suchen wir!",
          "title": "[Vorteile] Liebst du...?",
          "id": 100095
        },
        {
          "text": "“Das Team der Muster Gmbh ist inzwischen meine zweite Familie, inzwischen freue ich mich auf Montagmorgen!\" - Max Mustermann, Koch\r\n\r\nDu willst das auch sagen können? Jetzt als Koch (m/w/d) in Teil- oder Vollzeit in [Stadt] bewerben!\r\n",
          "title": "[Vorteile] Mitarbeiterzitat",
          "id": 100096
        },
        {
          "text": "Köche (m/w/d) und Kunden lieben Muster GmbH 🎉 \r\nDavon willst du dich selbst überzeugen? Dann lies jetzt weiter 👇\r\n",
          "title": "[Vorteile] ... dann lies jetzt weiter!",
          "id": 100097
        },
        {
          "text": "✋ Köche (m/w/d) in [Stadt] und Umgebung aufgepasst! ✋",
          "title": "[Vorteile] Aufgepasst!",
          "id": 100098
        },
        {
          "text": "Den ganzen Tag gekocht? Kostenlose Massagen warten auf dich nach der Arbeit! [oder anderes unübliches Benefit]",
          "title": "[Vorteile] Dein Benefit wartet",
          "id": 100099
        },
        {
          "text": "Jetzt Wechselprämie von xxxx€ sichern!",
          "title": "[Vorteile] Wechselprämie",
          "id": 100100
        },
        {
          "text": "Wieso Köche (m/w/d) zu uns wechseln? 🤔",
          "title": "[Vorteile] Warum wechseln sie zu uns?",
          "id": 100101
        },
        {
          "text": "Du suchst die perfekte Ausbildung als Koch (m/w/d) in deiner Nähe?",
          "title": "[Quiz] Ausbildung in der Nähe?",
          "id": 100227
        },
        {
          "text": "Guter Geschmack ist dein siebter Sinn und deine Suppen sind nie versalzen? Dann starte jetzt als [z.B. Azubi oder Quereinsteiger]",
          "title": "[Quiz] Kannst du gut...?",
          "id": 100228
        },
        {
          "text": "Du liebst es, neue Gerichte zu kreieren und kannst mit exzellenten Zutaten gut umgehen? Super, dann hast du die perfekten Voraussetzungen als Handwerker (m/w/d)",
          "title": "[Quiz] Liebst du...?",
          "id": 100229
        },
        {
          "text": "[Emotionales 2-Zeiler eines Azubis: z.B. Das Team der Muster Gmbh ist inzwischen meine zweite Familie, inzwischen freue ich mich auf Montagmorgen. Die beste Entscheidung, hier meine Ausbildung gestartet zu haben!\"]",
          "title": "[Quiz] Mitarbeiterzitat",
          "id": 100230
        },
        {
          "text": "Warum Köche (m/w/d) den besten Start in das Arbeitsleben haben. Lies jetzt weiter 👇",
          "title": "[Quiz] Bester Start ins Arbeitsleben",
          "id": 100231
        },
        {
          "text": "✋ Finde heraus, ob du Karriere als Koch (m/w/d) machen kannst! ✋",
          "title": "[Quiz] Finde heraus...",
          "id": 100232
        },
        {
          "text": "[z.B. 5] Gründe, warum Köche (m/w/d) den besten Job haben!",
          "title": "[Quiz] Gründe, warum...",
          "id": 100233
        },
        {
          "text": "Du willst als Köche (m/w/d) arbeiten, aber dir fehlt die Vorstellung, wie das abläuft? ⏫ Du bist Quereinsteiger, Anfänger oder Wiedereinsteiger? Kein Problem!",
          "title": "[Quiz] Fehlende Vorstellung",
          "id": 100234
        },
        {
          "text": "#MeetMaxi - vom [z.B. Quereinsteiger] zum [z.B. Teamleiter als Head of …]",
          "title": "[Story] #MeetName",
          "id": 100347
        },
        {
          "text": "[Kurzer Moment aus der Geschichte, der neugierig macht: z.B. “Und dafür hat er wirklich eine Gehaltserhöhung bekommen! 😂]",
          "title": "[Story] Kurzer Moment",
          "id": 100348
        },
        {
          "text": "Das könntest DU sein! Lies hier 👇",
          "title": "[Story] ... lies hier",
          "id": 100349
        },
        {
          "text": "[Spaßiges Erlebnis, das auch auf dem Bild zu sehen ist, z.B. “Mit dem Rollwagen durch unser Lager düsen. Mach’s wie Max. Habe Spaß bei der Arbeit 😉”]",
          "title": "[Story] Spaßiges Erlebnis",
          "id": 100350
        }
      ]
    },
    {
      "category": "Industrie",
      "content": [
        {
          "text": "Gesucht: Staplerfahrer (m/w/d) mit Durchblick 👷",
          "title": "[Vorteile] Berufsgruppe + Emoji",
          "id": 100124
        },
        {
          "text": "Als Staplerfahrer (m/w/d) liebst du es, mit dem Stapler zu fahren und behältst immer Durchblick? Dann haben wir etwas für dich!",
          "title": "[Vorteile] Kannst du gut...?",
          "id": 100125
        },
        {
          "text": "Als Staplerfahrer (m/w/d) liebst du es, schnell durch die Lager zu düsen und hast Feingefühl? Top, genau DICH suchen wir!",
          "title": "[Vorteile] Liebst du...?",
          "id": 100126
        },
        {
          "text": "“Das Team der Muster Gmbh ist inzwischen meine zweite Familie, inzwischen freue ich mich auf Montagmorgen!\" - Max Mustermann, Staplerfahrer\r\n\r\nDu willst das auch sagen können? Jetzt als Koch (m/w/d) in Teil- oder Vollzeit in [Stadt] bewerben!\r\n",
          "title": "[Vorteile] Mitarbeiterzitat",
          "id": 100127
        },
        {
          "text": "Staplfahrer (m/w/d) und Kunden lieben Muster GmbH 🎉 \r\nDavon willst du dich selbst überzeugen? Dann lies jetzt weiter 👇\r\n",
          "title": "[Vorteile] ... dann lies jetzt weiter!",
          "id": 100128
        },
        {
          "text": "✋ Staplerfahrer (m/w/d) in [Stadt] und Umgebung aufgepasst! ✋",
          "title": "[Vorteile] Aufgepasst!",
          "id": 100129
        },
        {
          "text": "Den ganzen Tag im Stapler? Kostenlose Massagen warten auf dich nach der Arbeit! [oder anderes unübliches Benefit]",
          "title": "[Vorteile] Dein Benefit wartet",
          "id": 100130
        },
        {
          "text": "Jetzt Wechselprämie von xxxx€ sichern!",
          "title": "[Vorteile] Wechselprämie",
          "id": 100131
        },
        {
          "text": "Du suchst die perfekte Ausbildung als Staplerfahrer (m/w/d) in deiner Nähe?",
          "title": "[Quiz] Ausbildung in der Nähe?",
          "id": 100251
        },
        {
          "text": "Du würdest gerne mit dem Stapler fahren und behältst generell immer Durchblick? Dann starte jetzt als [z.B. Azubi oder Quereinsteiger]",
          "title": "[Quiz] Kannst du gut...?",
          "id": 100252
        },
        {
          "text": "Du würdest es lieben, schnell durch die Lager zu düsen und hast Feingefühl? Super, dann hast du die perfekten Voraussetzungen als Handwerker (m/w/d)",
          "title": "[Quiz] Liebst du...?",
          "id": 100253
        },
        {
          "text": "[Emotionales 2-Zeiler eines Azubis: z.B. Das Team der Muster Gmbh ist inzwischen meine zweite Familie, inzwischen freue ich mich auf Montagmorgen. Die beste Entscheidung, hier meine Ausbildung gestartet zu haben!\"]",
          "title": "[Quiz] Mitarbeiterzitat",
          "id": 100254
        },
        {
          "text": "Warum Staplerfahrer (m/w/d) den besten Start in das Arbeitsleben haben. Lies jetzt weiter 👇",
          "title": "[Quiz] Bester Start ins Arbeitsleben",
          "id": 100255
        },
        {
          "text": "✋ Finde heraus, ob du Karriere als Staplerfahrer (m/w/d) machen kannst! ✋",
          "title": "[Quiz] Finde heraus...",
          "id": 100256
        },
        {
          "text": "[z.B. 5] Gründe, warum Staplerfahrer (m/w/d) den besten Job haben!",
          "title": "[Quiz] Gründe, warum...",
          "id": 100257
        },
        {
          "text": "Du willst als Staplerfahrer (m/w/d) arbeiten, aber dir fehlt die Vorstellung, wie das abläuft? ⏫ Du bist Quereinsteiger, Anfänger oder Wiedereinsteiger? Kein Problem!",
          "title": "[Quiz] Fehlende Vorstellung",
          "id": 100258
        },
        {
          "text": "#MeetMaxi - vom [z.B. Quereinsteiger] zum [z.B. Teamleiter als Head of …]",
          "title": "[Story] #MeetName",
          "id": 100371
        },
        {
          "text": "[Kurzer Moment aus der Geschichte, der neugierig macht: z.B. “Und dafür hat er wirklich eine Gehaltserhöhung bekommen! 😂]",
          "title": "[Story] Kurzer Moment",
          "id": 100372
        },
        {
          "text": "Das könntest DU sein! Lies hier 👇",
          "title": "[Story] ... lies hier",
          "id": 100373
        },
        {
          "text": "[Spaßiges Erlebnis, das auch auf dem Bild zu sehen ist, z.B. “Mit dem Rollwagen durch unser Lager düsen. Mach’s wie Max. Habe Spaß bei der Arbeit 😉”]",
          "title": "[Story] Spaßiges Erlebnis",
          "id": 100374
        }
      ]
    }
  ]
};

export default teaserTextData;
