import React from 'react';
import { Role } from './roleTypes';
import BlankUI from '../container/BlankUI';
import EmptyUI from '../container/EmptyUI';
import UI from '../container/UI';

export enum availableLayouts {
  UI = 'UI',
  EmptyUi = 'EmptyUI',
  BlankUi = 'BlankUI'
}

export const layout = {
  [availableLayouts.BlankUi]: BlankUI,
  [availableLayouts.EmptyUi]: EmptyUI,
  [availableLayouts.UI]: UI
};

export interface SingleRouteType {
  path: string;
  component: React.ReactElement;
  title?: string;
  icon?: React.ReactElement;
  topLayer?: boolean;
  layout: availableLayouts;
  allowedRoles: Role[];
  subRoutes?:SingleRouteType[]
}
