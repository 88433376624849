import { Select } from 'antd';
import React, { useState } from 'react';
import { AvailableFonts } from '../../interfaces/builderSliceTypes';
import { useDispatch } from 'react-redux';
import { AVAILABLE_FONT_TYPE, CONNECTED_FONT_TYPE } from './BuilderSettingsFont';
import { getConnectedFonts } from '../../helper/craftJs';
import { setConnectedFonts, useConnectedFonts } from '../../redux/builderSlice';
import { DeleteOutlined } from '@ant-design/icons';
import FontDeleteModal from './FontDeleteModal';
import { useCurrentWidgetId } from '../../WidgetsBuilder/redux/widgetBuilderSlice';
import { useIsWidgetsBuilderPath } from '../../hooks/useIsWidgetsBuilderPath';
import { setConnectedFontThunk } from '../../redux/thunk';
import mixpanel from 'mixpanel-browser';
import { mixpanelEvents } from '../../../UI/utils/mixpanel';

export interface BuilderSettingSelectFontProps {
  availableFonts: AvailableFonts[];
  connectedFontType: CONNECTED_FONT_TYPE;
}

const BuilderSettingSelectFont = ({
  availableFonts,
  connectedFontType
}: BuilderSettingSelectFontProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [deleteModalvisibility, setDeleteModalVisibility] = useState(false);
  const [fontId, setFontId] = useState<number | null>(null);
  const connectedFonts = useConnectedFonts();
  const isWidgetsBuilderPath = useIsWidgetsBuilderPath();
  const widgetId = useCurrentWidgetId();
  const dispatch = useDispatch();
  const handleFontChange = async (
    value: string,
    option: AvailableFonts,
    type: CONNECTED_FONT_TYPE
  ) => {
    type === CONNECTED_FONT_TYPE.HEADING
      ? mixpanel.track(mixpanelEvents.HEADLINE_FONTS)
      : mixpanel.track(mixpanelEvents.TEXT_FONTS);
    dispatch(
      setConnectedFontThunk({
        ...(isWidgetsBuilderPath && { widgetId: widgetId }),
        fontId: option.id,
        type: connectedFontType
      })
    );
    dispatch(
      setConnectedFonts({
        type,
        availableFont: {
          id: option.id,
          fontFamily: option.fontFamily,
          fontLink: option.fontLink,
          type: option.type
        }
      })
    );
  };

  const hasCustomFonts =
    [...availableFonts].filter(font => font.type === AVAILABLE_FONT_TYPE.CUSTOM_FONTS).length > 0;

  const customFontOptions = [...availableFonts]
    .filter(font => font.type === AVAILABLE_FONT_TYPE.CUSTOM_FONTS)
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    .map(font => ({
      label: (
        <span
          style={{ fontFamily: font.fontFamily }}
          className="font-settings__select-element d-flex justify-content-between align-items-center"
        >
          {font.fontFamily}
          <DeleteOutlined
            className="font-settings__delete"
            onClick={e => {
              e.stopPropagation();
              setFontId(font.id);
              setDeleteModalVisibility(true);
            }}
          />
        </span>
      ),
      value: font.fontFamily,
      data: font
    }));

  const googleFontOptions = [...availableFonts]
    .filter(font => font.type === AVAILABLE_FONT_TYPE.GOOGLE_FONTS)
    .sort((a, b) => a.fontFamily.localeCompare(b.fontFamily))
    .map(font => ({
      label: (
        <span style={{ fontFamily: font.fontFamily }} className="d-flex justify-content-between">
          {font.fontFamily}
        </span>
      ),
      value: font.fontFamily,
      data: font
    }));

  const FontOptions = [
    {
      label: hasCustomFonts ? (
        <h4 className="text-info">Eigene Schriftarten</h4>
      ) : (
        <>
          <h4 className="text-info">Eigene Schriftarten</h4>
          <p>Keine eigenen Schriftarten</p>
        </>
      ),
      options: customFontOptions
    },
    {
      label: <h4 className="text-info">Google Fonts</h4>,
      options: googleFontOptions
    }
  ];

  return (
    <div className="font-settings">
      <Select
        className="w-100"
        showSearch
        placeholder="Schriftart auswählen"
        optionLabelProp="value"
        onBlur={() => setDropdownOpen(false)}
        {...(dropdownOpen && { open: true })}
        defaultActiveFirstOption={true}
        onSelect={(value, option) => handleFontChange(value, option.data, connectedFontType)}
        //@ts-ignore
        defaultValue={
          connectedFonts && getConnectedFonts(connectedFonts, connectedFontType)?.fontFamily
        }
        options={FontOptions}
      />
      <FontDeleteModal
        visibility={deleteModalvisibility}
        setVisibility={setDeleteModalVisibility}
        fontId={fontId}
        setFontId={setFontId}
      />
    </div>
  );
};

export default BuilderSettingSelectFont;
