import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { GET_USER_WEBHOOKS } from './useUserWebHooks';

export const UPDATE_WEBHOOK_DOMAIN = gql`
  mutation updateWebhook($input: UpdateWebhookInput!) {
    updateWebhook(input: $input) {
      id
    }
  }
`;

export default function useUpdateWebhook() {
  return useMutation(UPDATE_WEBHOOK_DOMAIN 
    ,{
    refetchQueries: [{ query: GET_USER_WEBHOOKS }]
  }
  );
}
