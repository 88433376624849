import React from 'react';
import Avatar from 'react-avatar';
import { FaPaperPlane } from 'react-icons/fa';
import { useChannelTemplatesData } from '../../redux/builderSlice';
import moment from 'moment';

interface Props {
  template: string;
  className?: string;
  name?: string;
  time?: string;
}

const SMSPreview = ({ template, className = '', name, time = '' }: Props) => {
  const { senderName } = useChannelTemplatesData();
  return (
    <div className={`sms-preview ${className}`}>
      <div className="chat-mock chat-mock__left chat-mock__small"></div>
      <div className="chat-mock chat-mock__left chat-mock__big"></div>
      <div className="chat">
        <div className="chat-title">
          <h2>{(name || senderName) || 'Nachricht'}</h2>
          <figure className="avatar">
            <Avatar name={(name || senderName)?.[0] || 'Nachricht'} size="30" textSizeRatio={2} round={true} />
          </figure>
        </div>
        <div className="messages">
          <div className="messages-content">
            <div className="message">
              {template}
              {time ? <div className="timestamp">{moment(time).format('HH:mm')}</div> : null}
            </div>
          </div>
        </div>
        <div className="action-box">
          <div className="action-box-input">
            <div className="action-box-text-mock"></div>
          </div>
          <div className="action-box-submit">
            <FaPaperPlane />
          </div>
        </div>
      </div>
      <div className="chat-mock chat-mock__right chat-mock__big"></div>
      <div className="chat-mock chat-mock__right chat-mock__small"></div>
    </div>
  );
};

export default SMSPreview;
