import gql from 'graphql-tag';

export const GET_FUNNEL_PRESETS = gql`
  query getFunnelPresets {
    getFunnelPresets {
      id
      preset
      presetType
    }
  }
`;

export default function getFunnelPresets() {
  return window.apolloClient.query({
    query: GET_FUNNEL_PRESETS,
    fetchPolicy: 'no-cache'
  });
}
