import gql from 'graphql-tag';

export interface Input {
  id: number;
  name: string;
  tags: string[];
}

export const UPDATE_IMAGE = gql`
  mutation updateImage($input: UpdateImageInput!) {
    updateImage(input: $input) {
      id
    }
  }
`;

export default function updateImage(input: Input) {
  return window.apolloClient.mutate({
    mutation: UPDATE_IMAGE,
    variables: { input }
  });
}
