import gql from 'graphql-tag';

interface Input {
  id: number;
}

export const DELETE_AVAILABLE_FONT = gql`
mutation DeleteAvailableFont($deleteAvailableFontId: Int!) {
  deleteAvailableFont(id: $deleteAvailableFontId)
}
`;

export default function deleteAvailableFontApi(input: Input) {
  return window.apolloClient.mutate({
    mutation: DELETE_AVAILABLE_FONT,
    variables: {
      deleteAvailableFontId: input.id
    }
  });
}
