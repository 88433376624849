import gql from 'graphql-tag';
import { AgencyOSThemeType } from '../interfaces/agencyOSBuilderSliceTypes';

const SET_AGENCYOS_THEME = gql`
  mutation setAgencyOSBuilderTheme($input: SetAgencyOSBuilderThemeInput!) {
    setAgencyOSBuilderTheme(input: $input) {
      primaryColor
      primaryContrastColor
      accentColor
      headlineColor
      textColor
      fontFamily
      fontLink
    }
  }
`;

export default function setAgencyOSBuilderTheme(input: AgencyOSThemeType) {
  return window.apolloClient.mutate({
    mutation: SET_AGENCYOS_THEME,
    variables: { input }
  });
}
