import { Button, Checkbox, Modal, Result } from 'antd';
import React from 'react';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

const WarningModalWithHideForeverCheckbox = ({
  onCancel,
  visible,
  setVisible,
  onSubmit,
  checkboxVisible,
  localStorageKey,
  title,
  content,
  cancelButtonText,
  submitButtonText,
  iconPath
}: {
  visible: boolean;
  setVisible: (e: boolean) => void;
  onSubmit: () => void;
  onCancel?: () => void;
  checkboxVisible?: boolean;
  localStorageKey: string;
  title: string;
  content: JSX.Element;
  cancelButtonText?: string;
  submitButtonText?: string;
  iconPath?: string;
}) => {
  const toggleModal = () => setVisible(false);
  const currentShowCount = parseInt(
    localStorage.getItem('meetovoContainerCreateWarningShowCount' || '') || '0'
  );

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) localStorage.setItem(localStorageKey, 'true');
    else localStorage.removeItem(localStorageKey);
  };

  return (
    <Modal visible={visible} onOk={onSubmit} onCancel={toggleModal} footer={null}>
      <Result
        icon={iconPath ? <img width={70} src={iconPath} /> : null}
        status="info"
        title={title}
        subTitle={
          <>
            {content}
            {(currentShowCount >= 1 || checkboxVisible) && (
              <Checkbox onChange={handleCheckboxChange}>Hinweis nicht erneut anzeigen</Checkbox>
            )}
          </>
        }
        extra={[
          <Button
            type="primary"
            key="WarningForWithoutContainerElement__1"
            onClick={() => {
              onSubmit();
              toggleModal();
            }}
          >
            {submitButtonText || 'Ja'}
          </Button>,
          <Button
            key="WarningForWithoutContainerElement__2"
            type="link"
            onClick={() => {
              onCancel && onCancel();
              toggleModal();
            }}
          >
            {cancelButtonText || 'Nein'}
          </Button>
        ]}
      />
    </Modal>
  );
};

export default WarningModalWithHideForeverCheckbox;
