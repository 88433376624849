import gql from 'graphql-tag';

export interface AiTextForNodeInput {
  nodeId: string;
}

export const GET_AI_TEXT_RESPONSE_FOR_NODE = gql`
  query getAiTextResponseForNode($input: AiTextForNodeInput!) {
    getAiTextResponseForNode(input: $input) {
      conversationId
      message
      totalTokens
    }
  }
`;

export default function getAiTextResponseForNode(input: AiTextForNodeInput) {
  return window.apolloClient.query({
    query: GET_AI_TEXT_RESPONSE_FOR_NODE,
    fetchPolicy: 'no-cache',
    variables: { input }
  });
}
