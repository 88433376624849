import { Role } from '../../UI/utils/roleTypes';
import { questions } from '../utils/question-config';

interface ShouldShowOnboardingProps {
  questionsAnswered: any;
  isEmployee: boolean;
  isLoggedIn: boolean;
  pathname: string;
  role: Role;
}
export const shouldShowOnboarding = ({
  isLoggedIn,
  isEmployee,
  questionsAnswered,
  role,
  pathname
}: ShouldShowOnboardingProps): boolean => {
  return (
    isLoggedIn &&
    !((questionsAnswered?.length ?? 0) >= 3) &&
    !isEmployee &&
    role != Role.AGENCY_CUSTOMER &&
    pathname !== '/onboarding-einfuehrung' &&
    // @ts-ignore
    !window.showQuestion
  );
};
