import gql from 'graphql-tag';

interface Input {
  id: number;
}

export const DELETE_FUNNEL_FOLDER = gql`
  mutation deleteFunnelFolder($id: Int!) {
    deleteFunnelFolder(id: $id)
  }
`;

export default function deleteFunnelFolder(input: Input) {
  return window.apolloClient.mutate({
    mutation: DELETE_FUNNEL_FOLDER,
    variables: { id: input.id }
  });
}
