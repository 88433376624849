const jobTasksTextData = {
  title: 'Aufgaben',
  data: [
    {
      category: 'Gesundheitswesen',
      content: [
        {
          text: `Eingebettet in unser engagiertes Team in [STANDORT] übernimmst du verantwortungsvoll folgende Tätigkeiten:\n\n1. Gewissenhafte Durchführung von Pflegemaßnahmen in [ARBEITSPLATZMODELL]:\nDu setzt individuelle Pflegepläne mit Sorgfalt um und sicherst so die optimale Betreuung der Patienten.\n\n2. Mitwirkung an der Erstellung von Pflegekonzepten:\nDu gestaltest aktiv Pflegepläne mit und bringst deine Fachkenntnisse in die individuelle Betreuung ein.\n\n3. Unterstützung bei therapeutischen Maßnahmen:\nDurch deine Mitarbeit bei therapeutischen Ansätzen leistest du einen wertvollen Beitrag zum Wohlbefinden der Patienten.\n\n4. Regelmäßige Dokumentation und Berichterstattung:\nDu behältst den Überblick, dokumentierst gewissenhaft und kommunizierst effektiv im Team.`,
          title: 'Nummerierung',
          id: 100000
        },
        {
          text: `Bei [UNTERNEHMENSNAME] wirst du in [STANDORT] als Pflegekraft folgende Aufgaben übernehmen.\n\n- Personalmanagement und Teamführung: Du leitest ein Team von Fachkräften in [ARBEITSPLATZMODELL].\n\n- Koordination von Gesundheitsdiensten: Du organisierst die Abläufe in Gesundheitseinrichtungen.\n\n- Entwicklung von Gesundheitsprogrammen: Du gestaltest Programme zur Förderung von Gesundheitsprävention und -aufklärung.\n\n- Management von Infektionskrankheiten: Du koordinierst Maßnahmen zur Bekämpfung von Infektionskrankheiten.\n\n- Optimierung der Gesundheitsinfrastruktur: Du planst die Modernisierung von Gesundheitseinrichtungen.`,
          title: 'Du perspektive',
          id: 100001
        },
        {
          text: `Folgende Aufgaben werden deinen Alltag in [STANDORT] in [ARBEITSPLATZMODELL] beschreiben:\n\nTeamchef im Pflegezirkus: Mit einem Lächeln und einem Augenzwinkern führst du dein Team von Pflege-Helden durch den Schichtdienst.\n\nDirigent des Gesundheits-Symphonieorchesters: Mit einem Taktstock in der Hand organisierst du die Abläufe in der Gesundheitseinrichtung wie ein Maestro.\n\nKreativer Gesundheits-Entertainer: Mit bunten Ideen gestaltest du Programme zur Gesundheitsprävention und -aufklärung, die sogar die müdesten Geister erfrischen.\n\nKeim-Jäger mit Superkräften: Mit Desinfektionsspray und Schutzmaske bewaffnet, stehst du bereit, die Welt vor fiesen Keimen zu retten.\n\nArchitekt des Gesundheits-Wunderlands: Mit Helm und Bauplan in der Hand verwandelst du Gesundheitseinrichtungen in moderne Oasen des Wohlbefindens.`,
          title: 'Emotional & Humor',
          id: 100002
        },
        {
          text: `Um als Pflegekraft bei [UNTERNEHMENSNAME] in [STANDORT] erfolgreich zu sein, wirst du folgende Aufgaben übernehmen:\n\n- Beratung und Unterstützung von Patienten bei der Umstellung auf gesunde Lebensgewohnheiten und Krankheitsbewältigung.\n- Entwicklung individualisierter Gesundheitspläne und langfristiger Betreuung.\n- Innovationspionier im Gesundheitstechnologiebereich.\n- Evaluierung, Implementierung und Schulung neuer Technologien zur Verbesserung von Diagnose, Behandlung und Pflege.\n- Einsatzleitung bei Notfallsituationen und Zusammenarbeit mit anderen Rettungsdiensten.\n- Gesundheitsbotschafter in der Gemeinschaft.`,
          title: 'Kurz',
          id: 100003
        }
      ]
    },
    {
      "category": "Handwerk",
      "content": [
        {
          "text": `In unserem Team, wirst du für folgende Aufgaben verantwortlich sein:\n\n1. Ausführung von handwerklichen Arbeiten gemäß den Anweisungen und Plänen.\n2. Installation, Wartung und Reparatur von elektrischen, sanitären oder mechanischen Systemen.\n3. Durchführung von Bau- und Renovierungsarbeiten an Gebäuden und anderen Strukturen.\n4. Koordination mit anderen Handwerkern und Teammitgliedern, um Projekte termingerecht abzuschließen.\n5. Kundenkommunikation und -betreuung, um sicherzustellen, dass deren Anforderungen erfüllt werden.\n6. Einhaltung von Qualitätsstandards und Gewährleistung von präziser und hochwertiger Arbeit.\n7. Monatliche Berichterstattung.`,
          "title": "Nummerierung",
          "id": 100049
        },
        {
          "text": `Dies wird dein Aufgabenfeld in [BESCHAEFTIGUNGSART] bei uns sein.\n\nWir suchen einen Handwerks-Held, Anweisungen und Pläne setzt du im Handumdrehen um?\nDein Werkzeugkasten weicht dir niemals von der Seite und ist immer parat, neue Ideen umzusetzen?\nSicherheitsstandards sind deine Superkraft – du hältst sie ein, als wärst du ein Geheimagent auf einer streng geheimen Mission?\nFehler? Welche Fehler? Du analysierst sie schneller als ein Detektiv und behebst sie, bevor sie überhaupt jemand bemerken kann?\n\nDann bist bestehens aufgehoben bei [UNTERNEHMENSNAME].`,
          "title": "Fragend humoristisch",
          "id": 100192
        },
        {
          "text": `Folgende Aufgaben werden Teil deines Alltags sein:\n\nEinrichtung, regelmäßige Inspektion und Behebung von Problemen in diversen Systemen.\nDirekter Kontakt mit Kunden, um ihre Anliegen zu klären, Lösungen anzubieten und ihre Zufriedenheit sicherzustellen.\nGewissenhafte Einhaltung etablierter Qualitätsstandards bei allen durchgeführten Arbeiten.\nRegelmäßige Zusammenstellung und Präsentation detaillierter Berichte über den Fortschritt und die Ergebnisse der durchgeführten Arbeiten.`,
          "title": "Umschrieben",
          "id": 100193
        },
        {
          "text": `Deine Aufgaben bei [UNTERNEHMENSNAME]\n\n- Ausführung von handwerklichen Arbeiten.\n- Installation, Wartung und Reparatur von Systemen.\n- Durchführung von Bau- und Renovierungsarbeiten.\n- Koordination mit Teammitgliedern.\n- Kundenkommunikation und -betreuung.\n- Einhaltung von Qualitätsstandards.\n- Monatliche Berichterstattung.`,
          "title": "Stichpunkte. Kurz & Knapp",
          "id": 100194
        }
      ]
    },
    {
      "category": "Handel & Vertrieb",
      "content": [
        {
          "text": `Dies werden deine Aufgaben bei [UNTERNEHMENSNAME] sein. \n\n1.Kommunikation mit Kunden, um sicherzustellen, dass ihre Bedürfnisse erfüllt werden.\n2.Sicherstellung von Präzision und Qualität in allen Vertriebsaktivitäten.\n3.Regelmäßige Erstellung und Vorlage von Berichten über Vertriebsaktivitäten.\n4.Beobachtung und Analyse des Marktes, um Chancen zu identifizieren.\n5.Durchführung von Verkaufs- und Verhandlungsgesprächen sowie Angebotserstellung.\n6.Organisation von Veranstaltungen zur Förderung des Vertriebs.\n7.Erfassung und Auswertung von Verkaufsdaten zur Ableitung von Strategien.`,
          "title": "Umschrieben",
          "id": 100076
        },
        {
          "text": `Dies wird dein Aufgabenfeld in [BESCHAEFTIGUNGSART] bei uns sein.\n\n- Marktbeobachtung und Analyse\n- Verkaufs- und Verhandlungsgespräche\n- Organisation von Vertriebsveranstaltungen\n- Erfassung und Auswertung von Vertriebsdaten\n- Schulung und Unterstützung von Mitarbeitern`,
          "title": "Stichpunkte",
          "id": 100077
        },
        {
          "text": `In [BESCHAEFTIGUNGSART] bei [UNTERNEHMENSNAME] wirst du dich mit folgenden Aufgaben beschäftigen\n\n- Ausführung von Vertriebs- und Kundenakquiseaktivitäten gemäß den Unternehmensrichtlinien und Verkaufsstrategien.\n- Implementierung, Wartung und Weiterentwicklung von Vertriebssystemen und -prozessen zur Optimierung der Verkaufsleistung.\n- Entwicklung und Umsetzung von Vertriebsstrategien zur Erschließung neuer Märkte und Zielgruppen.\n- Zusammenarbeit mit anderen Vertriebsmitarbeitern und Abteilungen, um gemeinsame Ziele zu erreichen und den Verkaufserfolg zu steigern.\n- Pflege und Ausbau von Kundenbeziehungen durch professionelle Kommunikation und kundenorientierte Dienstleistungen.`,
          "title": "Lange Sätze",
          "id": 100078
        },
        {
          "text": `GESUCHT in [BESCHAEFTIGUNGSART]:  Ein Vertriebs-Zauberer, der...\n\n- Kunden umgarnt wie ein Profi und dabei die Firmenregeln und Verkaufsstrategien befolgt.\n- Vertriebssysteme jongliert und optimiert, als wäre er*sie ein Tech-Magier im Dienste des Verkaufserfolgs.\n- Vertriebsstrategien ausheckt, die neue Märkte erobern wie ein Entdecker auf der Suche nach El Dorado.\n- Mit den anderen Vertriebskollegen zusammenarbeitet wie ein gut geöltes Team in einem Heist-Film, um die gemeinsamen Ziele zu erreichen.\n- Kundenbeziehungen pflegt wie ein charmanter Gastgeber, der jeden mit einem Lächeln und einem Glas Champagner begrüßt.`,
          "title": "Humor, Wir suchen",
          "id": 100079
        }
      ]
    },
    {
      "category": "Gastronomie",
      "content": [
        {
          "text": `Deine Aufgaben bei [UNTERNEHMENSNAME]\n\n- Zubereitung und Präsentation von Speisen gemäß den Rezeptvorgaben.\n- Sicherstellung der Einhaltung von Hygiene- und Sicherheitsstandards in der Küche.\n- Planung und Organisation des Arbeitsablaufs in der Küche.\n- Bestellung und Lagerung von Lebensmitteln und anderen Küchenmaterialien.\n- Zusammenarbeit mit dem Küchenteam bei der Entwicklung neuer Menüs oder Spezialgerichte.\n- Bedienung und Pflege von Küchengeräten und -maschinen.`,
          "title": "Aufgaben - einfach",
          "id": 100107
        },
        {
          "text": `Wenn diese Aufgaben dich begeistern, dann freuen wir uns auf deine Bewerbung:\n\nBedienung und Pflege von Küchengeräten und -maschinen: Fachgerechte Bedienung, Reinigung und Pflege von Küchengeräten und -maschinen, um deren ordnungsgemäße Funktionsweise sicherzustellen.\n\nUnterstützung bei der Vorbereitung von Catering-Events oder besonderen Veranstaltungen: Mitarbeit bei der Vorbereitung und Durchführung von Catering-Events, Banketten oder besonderen Veranstaltungen nach den Vorgaben des Küchenchefs.\n\nBereitstellung von Unterstützung und Anleitung für Küchenhilfen und Auszubildende: Unterstützung und Anleitung von Küchenhilfen und Auszubildenden bei ihren Aufgaben sowie Bereitstellung von Hilfestellung bei Bedarf.\n\nGewährleistung der Qualität und Konsistenz der zubereiteten Speisen: Sicherstellung der gleichbleibend hohen Qualität und Konsistenz aller zubereiteten Speisen gemäß den festgelegten Standards.`,
          "title": "Aufgaben - umschrieben lang",
          "id": 100108
        },
        {
          "text": `Folgende Aufgaben erwarten dich bei [UNTERNEHMENSNAME]\n\nBereite Gerichte vor, die die Gäste zum Staunen bringen – ohne Zauberstab, dafür mit Kochlöffel!\n\nHeldencape anlegen und die Küche blitzblank halten.\n\nJongliere mit Pfannen und Töpfen, um den Küchenzirkus am Laufen zu halten!\n\nBringe deine Ideen in die Speisekarte ein und erschaffe kulinarische Meisterwerke!\n\nVerwandle Catering-Events in wahre Feste und sorge dafür, dass die Gäste immer wieder kommen wollen!`,
          "title": "Humor",
          "id": 100109
        },
        {
          "text": `Einen Einblick, in dein zukünftiges Aufgabenfeld:\n\n- Einhaltung von zeitlichen Vorgaben und Koordination mit dem Serviceteam.\n- Kommunikation mit dem Servicepersonal zur effizienten Übermittlung von Bestellungen.\n- Reinigung und Pflege der Küchenbereiche und -ausrüstung.\n- Mitarbeit bei der Umsetzung von Maßnahmen zur Kostenkontrolle und -optimierung.\n- Teilnahme an Schulungen und Weiterbildungen zur persönlichen und beruflichen Entwicklung.`,
          "title": "Stichpunkte",
          "id": 100110
        }
      ]
    },
    {
      "category": "Industrie",
      "content": [
        {
          "text": `Deine Aufgaben bei [UNTERNEHMENSNAME]\n\nProduktion und Fertigung: Überwachung und Steuerung des Fertigungsprozesses, Einrichten und Bedienen von Maschinen und Anlagen, Qualitätskontrolle der hergestellten Produkte.\n\nInstandhaltung und Wartung: Durchführung von Wartungs- und Reparaturarbeiten an Maschinen und Anlagen, Planung von Wartungsintervallen, Sicherstellung der Betriebsbereitschaft.\n\nLogistik und Lagerhaltung: Organisation und Optimierung des Materialflusses, Lagerverwaltung und -kontrolle, Koordination von Transporten und Lieferungen.\n\nQualitätsmanagement: Entwicklung und Umsetzung von Qualitätsstandards und -richtlinien, Durchführung von Qualitätsprüfungen und Audits, kontinuierliche Verbesserung der Produktionsprozesse.\n\nArbeitssicherheit und Umweltschutz: Gewährleistung der Einhaltung von Sicherheitsvorschriften und -standards, Implementierung von Maßnahmen zum Umweltschutz und zur Abfallentsorgung, Schulung der Mitarbeiter in Sicherheits- und Umweltfragen.`,
          "title": "Vorteile, lang umschrieben",
          "id": 100138
        },
        {
          "text": `Dies wird dein Aufgabenfeld als [BESCHAEFTIGUNGSART] bei uns sein.\n\n- Logistik und Lagerhaltung\n- Organisation und Optimierung des Materialflusses\n- Entwicklung und Umsetzung von Qualitätsstandards und -richtlinien\n- Einhaltung von Sicherheitsvorschriften und -standards\n- Planung, Organisation und Durchführung von Projekten\n- Entwicklung neuer Produkte und Technologien`,
          "title": "Kurz, Stichpunkte",
          "id": 100139
        },
        {
          "text": `Bei [UNTERNEHMENSNAME] wirst du folgende Aufgaben tätigen:\nDu wirst Projekte planen, organisieren und durchführen, um sicherzustellen, dass alles reibungslos läuft.\nDu wirst die Teams koordinieren und sicherstellen, dass alle Ressourcen optimal genutzt werden.\nDu wirst den Fortschritt der Projekte überwachen und sicherstellen, dass Zeit- und Kostenpläne eingehalten werden.\nDu wirst den Fertigungsprozess überwachen und sicherstellen, dass alles nach Plan läuft.\nDu wirst Maschinen und Anlagen einrichten und bedienen, um die Produktion aufrechtzuerhalten.\nDu wirst die hergestellten Produkte auf Qualität überprüfen und sicherstellen, dass sie den Standards entsprechen.\nDu wirst Wartungs- und Reparaturarbeiten an Maschinen und Anlagen durchführen, um ihre Betriebsbereitschaft sicherzustellen.`,
          "title": "Du perspektive - ansprechend",
          "id": 100140
        }
      ]
    }
  ]
};

export default jobTasksTextData;