export const getTimeString = (seconds: number) => {
  let timeString: string;
  if (seconds < 60) {
    timeString = `${seconds}s`;
  } else {
    let minutes = Math.floor(seconds / 60);
    timeString = `${minutes}min ${seconds % 60}s`;
  }

  return timeString;
};
