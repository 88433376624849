import React from 'react';
import usePreDefinedColorThemes from '../../../hooks/usePredefinerFunnelThemes';
import { Col, Row } from 'antd';
import BuilderSettingsTitle from '../../sharedUI/BuilderSettingsTitle';
import { FunnelThemeType } from '../../../interfaces/builderSliceTypes';
import {
  getFunnelIdFromToken,
  getMixPanelEventExistingTrackingData,
  mixpanelEvents,
  saveMixpanelEventOccurrence
} from '../../../../UI/utils/mixpanel';
import mixpanel from 'mixpanel-browser';
import { CheckOutlined } from '@ant-design/icons';
import ColorList from '../../sharedUI/ColorList';
import DefaultSmallLoader from '../../../../SharedUI/components/DefaultSmallLoader';

const PreDefinedColorThemesListing = ({
  applyColorTheme
}: {
  applyColorTheme: (t: FunnelThemeType) => Promise<any>;
}) => {
  const { data: staticThemes, loading } = usePreDefinedColorThemes();
  if (loading) {
    return <DefaultSmallLoader loading />;
  }

  return (
    <>
      {!!staticThemes?.preDefinedColorThemes?.length && (
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BuilderSettingsTitle title="Vorschläge" />
            <div className="d-grid d-grid__col-2">
              {staticThemes?.preDefinedColorThemes?.map(
                (colorTheme: FunnelThemeType, i: number) => (
                  <div
                    className={'color-circles'}
                    key={i}
                    onClick={() => {
                      if (
                        !getMixPanelEventExistingTrackingData(
                          mixpanelEvents.SELECTED_THEME_FOR_FUNNEL
                        )
                      ) {
                        mixpanel.track(mixpanelEvents.SELECTED_THEME_FOR_FUNNEL, {
                          funnelId: getFunnelIdFromToken()
                        });
                        saveMixpanelEventOccurrence(mixpanelEvents.SELECTED_THEME_FOR_FUNNEL);
                      }
                      applyColorTheme(colorTheme);
                    }}
                  >
                    <div className="color-circles__edit-overlay">
                      <CheckOutlined />
                    </div>
                    <div className="color-circles__inner">
                      <ColorList theme={colorTheme} qty={5} />
                    </div>
                  </div>
                )
              )}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default PreDefinedColorThemesListing;
