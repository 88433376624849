import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { useSelectedElementTab } from '../../../hooks/useSelectedElementTab';
import useGeneralSettingsDataSave from '../../../hooks/useGeneralSettingsDataSave';
import { addNewTemplateHandler } from '../../../helper/sharedFunctions';
import { useEditor } from '@craftjs/core';

interface Props {
  staticIcon?: boolean;
}

export const PlusIcon = ({staticIcon}:Props) => {
  const selectedElementTab = useSelectedElementTab();
  const {actions} = useEditor();
  const { handleGeneralSettingsDataSave } = useGeneralSettingsDataSave();
  return (
    <React.Fragment>
      <div
        className={`add-button-green circular-plus-button container__add-wrapper-icon ${staticIcon?"position-static":"append-container-icon"}`}
        onClick={e => addNewTemplateHandler(e, selectedElementTab, handleGeneralSettingsDataSave)}
        onMouseDown={(e)=>{
          e.stopPropagation();
          actions.selectNode("");
        }}
      >
        <FaPlus className="container-plus-icon" />
      </div>
    </React.Fragment>
  );
};
