import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Button, message } from 'antd';
import { LocalStorageKeys } from '../../Share/constants/localstorageKeys';
import { inActiveTimeLimit } from '../../constants';
import LoginValidationRules from '../../Login/utils/LoginValidationRules';
import { ValidatePinMessages } from '../../config/messages';
import mutateValidatePin from '../../hooks/mutateValidatePin';

const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress', 'keydown'];
let timer: any;
const InActiveUserForm = () => {
  const [visibility, setVisibility] = useState<boolean>(false);
  const [incorrectAttempts, setIncorrectAttempts] = useState<number>(0);

  const [form] = Form.useForm();

  const [ValidatesPin, { loading }] = mutateValidatePin();
  const handlePinModalTimer = () => {
    resetTimer();
    timer = setTimeout(() => {
      resetTimer();
      events.forEach(item => {
        window.removeEventListener(item, resetTimer, true);
      });
      setVisibility(true);
      localStorage.setItem(LocalStorageKeys.MEETOVO_INACTIVE_MODAL_VISIBLE, 'true');
    }, inActiveTimeLimit);
  };

  const resetTimer = () => {
    clearTimeout(timer);
  };

  useEffect(() => {
    if (localStorage.getItem(LocalStorageKeys.MEETOVO_INACTIVE_MODAL_VISIBLE) === 'true') {
      setVisibility(true);
      return;
    }
    handlePinModalTimer();
    events.forEach(item => {
      window.addEventListener(item, handlePinModalTimer, true);
    });
    return () => {
      resetTimer();
      events.forEach(item => {
        window.removeEventListener(item, resetTimer, true);
      });
      clearTimeout(timer);
    };
  }, []);

  const handleLogout = async () => {
    localStorage.removeItem(LocalStorageKeys.MEETOVO_INACTIVE_MODAL_VISIBLE);
    window.handleLogout();
  };

  const handleSubmit = (values: any) => {
    const { pin } = values;
    ValidatesPin({
      variables: {
        input: pin
      }
    })
      .then((res: any) => {
        if (res.data.validatePin) {
          message.success(ValidatePinMessages.correctPin);
          closeModal();
          clearTimeout(timer);
        } else {
          setIncorrectAttempts(prevAttempts => prevAttempts + 1);
          form.resetFields();
          message.error(ValidatePinMessages.incorrectPin);
          if (incorrectAttempts >= 2) {
            closeModal();
            handleLogout();
          }
        }
      })
      .catch((err: any) => {
        message.error('Not verified');
      });
  };

  const closeModal = () => {
    form.resetFields();
    setIncorrectAttempts(0);
    localStorage.setItem(LocalStorageKeys.MEETOVO_INACTIVE_MODAL_VISIBLE, 'false');
    setVisibility(false);
  };

  return (
    <Modal
      width={500}
      visible={visibility}
      centered
      closable={false}
      footer={false}
      title="PIN Required"
      wrapClassName="background_blur"
    >
      <h5>
        Looks like you have been inactive for quite some time. Please enter your PIN to proceed.
      </h5>
      <Form form={form} name="basic" layout="vertical" onFinish={handleSubmit}>
        <Form.Item name="pin" label="PIN" rules={LoginValidationRules.pin}>
          <Input
            type="password"
            maxLength={4}
            placeholder="Enter your PIN"
            addonAfter={
              incorrectAttempts > 0 && (
                <span style={{ color: 'red' }}>{`You have remaining (${3 -
                  incorrectAttempts}) attempts left`}</span>
              )
            }
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default InActiveUserForm;
