export const HTMLWidgetDefaultProps = {
  html: `<h1>Hello World..!</h1>
<p>Hinterlege hier deinen eigenen Code.</p>
<script>
    var docReady = function(fn) {
        var stateCheck = setInterval(function() {
            if (document.readyState !== "complete") return;
            clearInterval(stateCheck);
            try {
                fn()
            } catch (e) {}
        }, 1);
    };
    docReady(function() {
        console.log('HELLO WORLD!');
    });
</script>`
};
