import React, { useState } from 'react'
import { useAppDispatch } from '../../redux/hooks';
import { CHANNEL_TYPE } from '../interfaces/builderSliceTypes';
import { updateMessageSendingChannel } from '../redux/builderSlice';

const useChannelChange = () => {
    const dispatch = useAppDispatch();

    const [showModal, setShowModal] = useState(false);
    const showSmsHideWarning = !!localStorage.getItem('MeetovoSmsHideWarning')

    const handleChannelChange = (val: any) => {
        if (val === CHANNEL_TYPE.MESSAGE && !showModal && !showSmsHideWarning) {

            setShowModal(true)
            dispatch(updateMessageSendingChannel(val));
            
        } else {
            dispatch(updateMessageSendingChannel(val));
        }
    };

    const handleModalCancel = () => {
        setShowModal(false);
        dispatch(updateMessageSendingChannel(CHANNEL_TYPE.EMAIL));
    };

    const handleOkSubmit = () => {
        dispatch(updateMessageSendingChannel(CHANNEL_TYPE.MESSAGE));
    }

    return {
        showModal,
        setShowModal,
        handleModalCancel,
        handleChannelChange,
        handleOkSubmit
    }
}

export default useChannelChange