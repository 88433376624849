import React, { ChangeEvent } from 'react';
import { Form, Input } from 'antd';

interface SearchInterface {
  data: any[];
  searchKey: string;
  onSearch(searchedData: any[]): any;
}

const Search = ({ data, searchKey, onSearch }: SearchInterface) => {
  const includesLowercase = (value1: string, value2: string) =>
    value1
      .toString()
      .toUpperCase()
      .includes(value2.toUpperCase());

  const searchChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) return onSearch(data.map(({ id }) => id));

    const searchedIds = data
      .filter(sd => includesLowercase(sd[searchKey], e.target.value))
      .map(({ id }) => id);

    return onSearch(searchedIds);
  };

  return (
    <>
      <Form.Item>
        <Input placeholder="Suche..." onChange={searchChangeHandler} />
      </Form.Item>
    </>
  );
};

export default Search;
