import React from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';

export default ({ field, placeholder, prefix, ...props }: any) => {
  return (
    <InputGroup className="text-input mb-3">
      <InputGroup.Prepend>
        <InputGroup.Text className="bl-0">{prefix}</InputGroup.Text>
      </InputGroup.Prepend>
      <FormControl {...props} placeholder={placeholder} />
    </InputGroup>
  );
};
