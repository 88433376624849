import React, { useState } from 'react';
import { Modal, Checkbox, message, Button, Steps, Alert } from 'antd';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { ContractMessages } from '../../config/messages';
import ContractDocument from '../components/ContractDocument';

export interface PublicContract {
  id: number;
  title?: string;
  url?: string;
  updatedAt?: string;
  newVersionOf?: number;
  reasonForContractChange?: string;
  deprecated?: boolean;
  required?: boolean;
}

export interface Contract {
  id: number;
  agreed: boolean;
  updatedAt: string;
  publicContract: PublicContract;
}

interface ContractModalProperties {
  publicContracts: PublicContract[];
  contracts: Contract[];
}

export const SET_CONTRACT = gql`
  mutation setContract($input: [ContractData!]) {
    setContract(input: $input) {
      id
    }
  }
`;

export function ContractModal({
  publicContracts,
  contracts
}: ContractModalProperties) {
  const unsignedPublicContracts = publicContracts.filter((pc: PublicContract) => {
    const contractIsSigned = contracts.some((c: Contract) => c.publicContract.id === pc.id);

    return !contractIsSigned && !pc.deprecated && pc.required;
  });

  const mergedContractsWithPublic = unsignedPublicContracts.map((pc: PublicContract) => {
    const oldVersionId = pc.newVersionOf || 0;
    const userSignedOldContract = oldVersionId
      ? contracts.some(({ publicContract }) => publicContract.id === oldVersionId)
      : false;
    const reaseonForChange = userSignedOldContract ? pc.reasonForContractChange : '';

    return {
      id: (contracts || []).find((c: Contract) => c.publicContract.id === pc.id)?.id,
      publicContractId: pc.id,
      agreed: false,
      reaseonForChange
    };
  });

  const [setNewContracts, { loading }] = useMutation(SET_CONTRACT);
  const [tempContracts, setTempContracts] = useState(mergedContractsWithPublic);
  const [visible, setVisible] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);

  const updateCheckedContracts = (
    contract: Contract,
    contractIdx: number,
    properties: any,
    publicContractId: number
  ) => {
    let allCheckedContracts = [...tempContracts];

    if (contract) {
      allCheckedContracts[contractIdx] = { ...allCheckedContracts[contractIdx], ...properties };
    } else {
      allCheckedContracts = [...allCheckedContracts, { publicContractId, ...properties }];
    }

    setTempContracts(allCheckedContracts);
  };

  const updateContracts = () => {
    const sendToServer = tempContracts.map(({ publicContractId, agreed, id }) => ({
      publicContractId,
      agreed,
      id
    }));

    setNewContracts({ variables: { input: sendToServer } })
      .then(() => {
        message.success(ContractMessages.setNewContractsSuccess);
        setVisible(false);
      })
      .catch(() => {
        message.error(ContractMessages.setNewContractsError);
      });
  };

  const next = () => {
    document.getElementsByClassName('contract-modal__contract-wrapper')[0].scrollTop = 0;
    const current = currentStep + 1;
    setCurrentStep(current);
  };

  const prev = () => {
    const current = currentStep - 1;
    setCurrentStep(current);
  };

  const { Step } = Steps;

  const steps = unsignedPublicContracts.map((pc: PublicContract, idx: number) => {
    let correspondingCheckedContractIndex = tempContracts.findIndex(
      (cc: any) => cc.publicContractId === pc.id
    );

    let correspondingCheckedContract: any;
    if (correspondingCheckedContractIndex > -1) {
      correspondingCheckedContract = tempContracts[correspondingCheckedContractIndex];
    }

    let checked = !!correspondingCheckedContract?.agreed;

    const reaseonForChange = correspondingCheckedContract.reaseonForChange;
    const showFreeTestMessage = idx === 0 && contracts && contracts.length === 0;

    return {
      title: pc.title,
      content: (
        <div className="contract-modal__inner">
          <ContractDocument
            source={pc.url || ''}
          />

          {showFreeTestMessage ? (
            <Alert
              message="14 Tage kostenfrei"
              description="Die Nutzungsvereinbarungen dienen zur gegenseitigen Absicherung und zur Einhaltung der DSGVO-Vorschriften. Es wird kein Abonnement abgeschlossen."
              type="success"
              showIcon
            />
          ) : null}

          {reaseonForChange && <Alert message={reaseonForChange} type="info" showIcon />}

          <div className="contract-modal__checkbox-wrapper">
            <Checkbox
              key={`contract_key_${idx}`}
              checked={checked}
              onChange={() =>
                updateCheckedContracts(
                  correspondingCheckedContract,
                  correspondingCheckedContractIndex,
                  { agreed: !checked },
                  pc.id
                )
              }
            >
              {`Ich stimme dem Vertrag "${pc.title}" zu.`}
            </Checkbox>
          </div>
        </div>
      )
    };
  });

  return visible && steps.length ? (
    <Modal
      title="Nutzungsvereinbarungen"
      centered
      closable={false}
      visible
      width={700}
      footer={
        <div className="steps__action">
          {currentStep > 0 && <Button onClick={prev}>Zurück</Button>}
          {currentStep < steps.length - 1 && (
            <Button type="primary" onClick={next} disabled={!tempContracts[currentStep].agreed}>
              Weiter
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button
              type="primary"
              loading={loading}
              onClick={updateContracts}
              disabled={!tempContracts[currentStep].agreed || loading}
            >
              Bestätigen
            </Button>
          )}
        </div>
      }
    >
      <div>
        <Steps size="small" current={currentStep}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps__content">{steps[currentStep].content}</div>
      </div>
    </Modal>
  ) : null;
}
