import React from 'react';
import ActionProviderHOCControllable from '../../sharedUI/ActionProviderHOCControllable';
import EditableLabel from '../Carousel/Components/EditableLabel';
import { Card, Col, Collapse, Form, Input, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import IconPickerGrid from '../../../../GeneralComponents/IconPickerGrid/container/IconPickerGrid';
import { MdDragHandle } from 'react-icons/md';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { urlFormValidator } from '../../../../helper/url';
import BuilderColorPickerButton from '../../sharedUI/BuilderColorPickerButton';
import { useEditor } from '@craftjs/core';
import { useCurrentlyEditingBuilderTheme } from '../../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';

const DragHandle = SortableHandle(() => <MdDragHandle className="drag-handler" />);

const LinkCard = SortableElement(
  ({ contactLink, nodeId, idx, iconsLinks, sortStarted, ...props }: any) => {
    const [form] = Form.useForm();
    const theme = useCurrentlyEditingBuilderTheme();
    const { actions } = useEditor();

    return (
      <div
        className="mb-3"
        style={{
          zIndex: 9
        }}
      >
        <ActionProviderHOCControllable
          deletable={true}
          title={
            <div className="d-flex align-items-center">
              <DragHandle />
              <EditableLabel
                className="mx-2"
                value={contactLink?.name}
                setValue={newValue => {
                  actions.setProp(nodeId, (props: any) => {
                    props.contactLinkIcons[idx].name = newValue;
                  });
                }}
              />
            </div>
          }
          onDelete={() => {
            const array = [...iconsLinks];
            array.splice(idx, 1);
            actions.setProp(nodeId, (props: any) => (props.contactLinkIcons = array));
          }}
        >
          <Card className="contact-link-card">
            <Form
              form={form}
              initialValues={{ url: contactLink?.url || '' }}
              onFinish={values => {
                actions.setProp(
                  nodeId,
                  (props: any) => (props.contactLinkIcons[idx].url = values.url)
                );
              }}
              autoComplete="off"
            >
              <Form.Item
                name="url"
                rules={[
                  {
                    required: true,
                    message: 'URL ist erforderlich'
                  },
                  urlFormValidator
                ]}
              >
                <Input
                  className="mt-2"
                  placeholder="https://deine-seite.de/impressum"
                  onChange={form.submit}
                />
              </Form.Item>
            </Form>
            <Row className="builder__settings-sidebar__row color-picker-row">
              <Col span={11}>
                <Text>Farbe des Symbols</Text>
                <BuilderColorPickerButton
                  color={contactLink?.iconColor || theme.accentColor}
                  onChange={color => {
                    actions.setProp(nodeId, (props: any) => {
                      props.contactLinkIcons[idx].iconColor = color;
                    });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Collapse bordered={false} expandIconPosition="right" style={{ width: '100%' }}>
                <Collapse.Panel
                  header="Icons"
                  key="icons"
                  style={{ border: 'none', padding: '0px !important' }}
                >
                  <IconPickerGrid
                    onChange={value => {
                      actions.setProp(nodeId, (props: any) => {
                        props.contactLinkIcons[idx].icon = value;
                      });
                    }}
                  />
                </Collapse.Panel>
              </Collapse>
            </Row>
          </Card>
        </ActionProviderHOCControllable>
      </div>
    );
  }
);

export default LinkCard;
