import React, { useEffect } from 'react';
import { useNode, useEditor, Element } from '@craftjs/core';
import ContainerSettings from './ContainerSettings';
import { CRAFT_ELEMENTS } from '../../../../config/craftElements';
import { ContainerComponentProps, ContainerSettingsTab } from '../config';
import { ContainerComponentProps as ContainerComponentPropsInterface } from '../interfaces';
import ContainerComponentHOC from '../../common/ContainerHOC';
import { Alert } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { FaPlus } from 'react-icons/fa';
import { getParentNodeId, getUniqueId } from '../../../../helper/craftJs';
import ColumnGrid from '../../grid/ColumnGrid';
import Column from '../../grid/Column';
import { useAppDispatch } from '../../../../../redux/hooks';
import { useNavigate } from 'react-router';
import { setSelectedElementTab } from '../../../../redux/builderSlice';
import IconComponent from '../../IconComponent';

const ContainerComponent = (props: ContainerComponentPropsInterface) => {
  const { children } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { actions, query } = useEditor((state, query) => ({
    enabled: state.options.enabled,
    canUndo: state.options.enabled && query.history.canUndo(),
    canRedo: state.options.enabled && query.history.canRedo()
  }));

  const { nodeId, currentNode, SerializedNodes } = useNode(node => {
    const SerializedNodes = query.getSerializedNodes();

    return {
      nodeId: node.id,
      currentNode: node,
      parentId: node.data.parent,
      childNodes: node.data.nodes,
      SerializedNodes: SerializedNodes
    };
  });

  const noChildPresent = !!!currentNode.data.nodes.length;
  useEffect(() => {
    if (!query.node(currentNode.id).get()) return;

    if (props.isFirstRender) {
      // needs to have this the router is not checking if we are already on that page
      if (!location.href.includes('/builder/bearbeiten/elemente'))
        navigate('/builder/bearbeiten/elemente');

      dispatch(
        setSelectedElementTab({
          payload: '2'
        })
      );
      actions.history.ignore().setProp(currentNode.id, (state: any) => {
        state.selectedTab = ContainerSettingsTab.TEMPLATES;
        state.isFirstRender = false;
      });

      actions.selectNode(currentNode.id);
    }
  }, [query.getState()?.nodes]);

  const handleAddNewRows = () => {
    const containerNodeId = getParentNodeId(nodeId, query, CRAFT_ELEMENTS.CONTAINER);
    const allDescendants = query.node(containerNodeId).descendants(true);
    const serializedNodes = query.getSerializedNodes();
    const specialIconNode = allDescendants?.find((nodeId: any) => {
      const node = serializedNodes[nodeId];
      return node.displayName === CRAFT_ELEMENTS.ICON && node.props.isColumnIcon === true;
    });

    const gridColumnNodeId = SerializedNodes[nodeId]?.nodes[0];
    const columnNodes = SerializedNodes[gridColumnNodeId]?.nodes;
    const gridColumnNodes = SerializedNodes[nodeId]?.nodes;
    const gridProps = { gridColumnNodesLength: gridColumnNodes?.length };
    const isSpecialIconVisible = !!SerializedNodes[nodeId].props.displaySpecialIcon;

    let specialIconProps: any = {};
    if (specialIconNode) {
      const props = SerializedNodes[specialIconNode].props;
      specialIconProps = { ...props };
    }
    
    const indexToPlaceAt = query.getState()?.nodes?.[currentNode.id]?.data?.nodes?.length;
    const actionCreator = actions.history.ignore();
    const node = query
      .parseReactElement(
        <Element id={getUniqueId()} is={ColumnGrid} {...gridProps} canvas>
          {columnNodes?.map(element => {
            const { sm, md, lg, columnWidths } = SerializedNodes[element]?.props;
            const colProps = { columnWidths, sm, md, lg };
            return (
              <Element
                id={getUniqueId()}
                is={Column}
                displaySpecialIcon={isSpecialIconVisible}
                {...colProps}
                columnWidths
                canvas
              >
                {isSpecialIconVisible ? (
                  <Element
                    id={getUniqueId()}
                    is={IconComponent}
                    isColumnIcon={true}
                    deleteNotReq={true}
                    showPlusIcon={false}
                    onlySettingsReq={true}
                    copyNotReq={true}
                    fontSize={specialIconProps ? specialIconProps.fontSize : 4}
                    isOutline={specialIconProps ? specialIconProps.isOutline : false}
                    canDrag={false}
                    className="special-icon"
                  ></Element>
                ) : (
                  []
                )}
              </Element>
            );
          })}
        </Element>
      )
      .toNodeTree();
    actionCreator.addNodeTree(node, currentNode.id, indexToPlaceAt);
  };

  return (
    <ContainerComponentHOC {...props} minHeight="auto" elementName={CRAFT_ELEMENTS.CONTAINER}>
      {noChildPresent && (
        <div className="empty-container-layout">
          <Alert
            message="Nutze Vorlagen oder einen leeren Abschnitt"
            description="Auf der linken Seite findest du Vorlagen oder einen leeren Abschnitt. Anschließend kannst du Elemente darin platzieren."
            type="success"
            icon={<PlusCircleOutlined />}
            showIcon
          />
        </div>
      )}

      <div
        className={`position-relative container-md container-row align_${props.align}`}
        style={{
          ...(currentNode.data.nodes.length !== 0 && {
            minHeight: 'inherit',
            height: 'inherit !important'
          })
        }}
      >
        {children}
        {!noChildPresent && currentNode.events.selected && (
          <div className="add-new-row mt-1 mb-1 d-flex">
            <div
              className="add-button-green add-button-green__with-label"
              onClick={handleAddNewRows}
            >
              <FaPlus />
              Zeile hinzufügen
            </div>
          </div>
        )}
      </div>
    </ContainerComponentHOC>
  );
};

ContainerComponent.craft = {
  name: CRAFT_ELEMENTS.CONTAINER,
  rules: {
    canMoveIn: (selectedNode: any) => {
      if (selectedNode[0].data.displayName === CRAFT_ELEMENTS.COLUMN_GRID) {
        return true;
      } else {
        return false;
      }
    }
  },
  props: ContainerComponentProps,
  related: {
    settings: ContainerSettings
  }
};

export default ContainerComponent;
