import React from 'react';
import { Modal, Button, Result, message } from 'antd';
import { useDispatch } from 'react-redux';
import { deleteAvailableFontsThunk } from '../../redux/thunk';
import { getConnectedFonts } from '../../helper/craftJs';
import { useConnectedFonts } from '../../redux/builderSlice';
import { CONNECTED_FONT_TYPE } from './BuilderSettingsFont';
import { CustomFontsMessages } from '../../../config/messages';

function FontDeleteModal({
  visibility,
  setVisibility,
  fontId,
  setFontId
}: {
  visibility: boolean;
  setVisibility: (visible: boolean) => void;
  fontId: number | null;
  setFontId: (fontId: number | null) => void;
}) {
  const dispatch = useDispatch();
  const connectedFonts = useConnectedFonts();
  const handleCancel: () => void = () => {
    setVisibility(false);
  };

  const headlineFontId =
    connectedFonts && getConnectedFonts(connectedFonts, CONNECTED_FONT_TYPE.HEADING)?.id;
  const textFontId =
    connectedFonts && getConnectedFonts(connectedFonts, CONNECTED_FONT_TYPE.TEXT)?.id;

  const handleDeleteAvailableFont = async () => {
    if (fontId) {
      if (fontId === headlineFontId || fontId === textFontId) {
        message.error(CustomFontsMessages.selectedFont);
        setFontId(null);
        setVisibility(false);
      } else {
        await dispatch(deleteAvailableFontsThunk(fontId));
        setFontId(null);
        setVisibility(false);
      }
    }
  };

  return (
    <React.Fragment>
      <Modal
        width={500}
        visible={visibility}
        destroyOnClose
        onCancel={handleCancel}
        className="mb-6 decline-applicant"
        footer={null}
      >
        <Result
          status="warning"
          title="Willst du die Schriftart wirklich löschen?"
          extra={[
            <>
              <Button type="primary" className="" onClick={handleDeleteAvailableFont}>
                Ja
              </Button>
              <Button className="" onClick={handleCancel}>
                Nein
              </Button>
            </>
          ]}
        />
      </Modal>
    </React.Fragment>
  );
}

export default FontDeleteModal;
