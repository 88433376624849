import { Button, Form, Input, Modal } from 'antd';
import { Store } from 'antd/lib/form/interface';
import React from 'react';
import { Rules } from '../../../Funnel/components/subcomponents/Testimonials';
interface PropTypes {
  isOpen: boolean;
  handleClose: Function;
  handleSubmit?: (values: Store) => void;
}
const ActionModal: React.FC<PropTypes> = ({ isOpen, handleClose, handleSubmit }) => {
  return (
    <Modal
      title="Bitte gib eine Begründung an"
      visible={isOpen}
      onOk={() => handleClose()}
      onCancel={() => handleClose()}
      footer={null}
    >
      <Form onFinish={handleSubmit} initialValues={{ note: '' }}>
        <Form.Item rules={Rules.text} name="note">
          <Input.TextArea
            placeholder="Dies wird in den Notizen auf der Bewerbung gespeichert"
            rows={7}
          />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Speichern
        </Button>
      </Form>
    </Modal>
  );
};

export default ActionModal;
