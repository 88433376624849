import React from 'react';
import { Card } from 'antd';
import { Col } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { useIsMobileView } from '../../../../hooks/useIsMobileView';
import { TEMPLATE_KEYS } from '../../Container/helper';

interface Props {
  handleAddClick: () => void;
  templateKey?: string;
}

export default function AddNewChoice({ handleAddClick ,templateKey }: Props) {
  const isMobileView = useIsMobileView();
  const isIconOnCenter = templateKey === TEMPLATE_KEYS.ICON_ON_CENTER;
  return (
    <Col
      sm={12}
      md={isIconOnCenter ? 3 : 6}
      {...(isMobileView && {
        sm: isIconOnCenter ? 6 : 12,
        md: isIconOnCenter ? 6 : 12,
        lg: isIconOnCenter ? 6 : 12
      })}
      className={`pe-2 add-new-choice-container ${isMobileView ?"mobile-add-new-choice-view":""}`} 
    >
      <Card className="add-new-choice-wrapper d-flex align-items-center justify-content-center">
        <div className="add-icon-wrapper cursor-pointer" onClick={handleAddClick}>
          <FaPlus className="add-icon" />
        </div>
      </Card>
    </Col>
  );
}
