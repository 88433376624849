import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { FunnelThemeType } from '../interfaces/builderSliceTypes';

const UPDATE_USER_COLOR_THEME = gql`
  mutation updateUserColorTheme($input: UpdateFunnelColorThemeInput!) {
    updateUserColorTheme(input: $input) {
      id
      headlineColor
      accentColorContrast
      textColor
      accentColor
      backgroundColor
      cardBackgroundColor
      cardIconColor
      cardTextColor
      fontFamily
      fontLink
      isCustom
      name
    }
  }
`;

export default function mutateUserColorTheme() {
  return useMutation(UPDATE_USER_COLOR_THEME);
}

export function mutateUserColorThemeAPI(input: FunnelThemeType) {
  return window.apolloClient.mutate({
    mutation: UPDATE_USER_COLOR_THEME,
    variables: {
      input: { ...input }
    }
  });
}
