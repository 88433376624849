import { CraftElementBaseProps } from '../../helper/craftJs';
import { useNode, useEditor } from '@craftjs/core';
import React, { useEffect, useRef } from 'react';
import { CRAFT_ELEMENTS, nonInteractingComponents } from '../../config/craftElements';
import { PlusIcon } from '../elements/common/PlusIcon';
import { isBuilderInvalid } from '../../container/BuilderValidator';
import { trackInteraction } from '../../DebugTracking/utils/helper';
import { TRACKINGS } from '../../DebugTracking/container/DebugTracker';
import { handleSelectCraftElement } from '../../helper/craftJs';
import { useSetDraggingElement } from '../../reduxCommonMutations/setDraggingElement';
import { useAppDispatch } from '../../../redux/hooks';
import useGeneralSettingsDataSave from '../../hooks/useGeneralSettingsDataSave';
import { ActionControllerProps } from './ActionsControllerProps';
import { AiFillEyeInvisible } from 'react-icons/ai';
import { RICH_TEXT_SETTINGS_VALUE, useElementSubSettings } from '../../helper/richTextHelpers';

export const ActionsController = (props: ActionControllerProps) => {
  const setDraggingElement = useSetDraggingElement();
  const { handleGeneralSettingsDataSave } = useGeneralSettingsDataSave();
  const { goToElementSubSettings } = useElementSubSettings();
  const CurrentNodeRef = useRef();
  const dispatch = useAppDispatch();
  const {
    connectors: { connect, drag },
    nodeId,
    nodeProps,
    currentNode
  } = useNode(node => {
    return {
      nodeId: node.id,
      currentNode: node,
      nodeProps: node.data.props
    };
  });
  const isHovered = currentNode.events.hovered;
  const { query } = useEditor();
  const {
    children,
    className = '',
    canDrag = false,
    ParentTag,
    ParentTagProps,
    innerRef,
    onlySettingsReq,
    copyNotReq,
    deleteNotReq,
    actionIcon,
    elementTitle,
    showPlusIcon,
    activeActionControllerClassNotRequired,
    openChildSettingsOnParentSettings,
    openParentSettingsOnChildSettings,
    label,
    maxHeight,
    minHeight,
    maxWidth,
    minWidth,
    borderRadius,
    backgroundColor,
    isFirstRender,
    isCustomColor,
    isLeadQualifier,
    showArrow,
    rawState,
    isLoading,
    boldText,
    buttonAlign,
    actionLogic,
    customClasses,
    showParentSettingsOnChildHover,
    columnWidths,
    paddingTopClass,
    paddingBottomClass,
    selectedTab,
    backgroundImg,
    containerMinHeightForMobile,
    customBrandingEnabled,
    exporter,
    editorState,
    customBranding,
    imageURL,
    isCoumnIcon,
    elementName,
    customRules,
    showSaveableModal,
    backgroundImgEnabled,
    containerMinHeightDesktop,
    videoSrc,
    showLine,
    dividerColor,
    buttonText,
    innerText,
    choiceType,
    deactivateIconButton,
    templateKey,
    cardIconColor,
    optionLogic,
    childNodes,
    initialValue,
    specialClosures,
    bookingRangeInHours,
    maxBookingsPerDay,
    slotBufferInMinutes,
    busyStatus,
    durationInMinutes,
    Title,
    elementType,
    canHide,
    ...restProps
  }: ActionControllerProps = { ...nodeProps, ...props };

  useEffect(() => {
    return () => {
      setDraggingElement({ name: null });
    };
  }, []);

  useEffect(() => {
    if (
      nonInteractingComponents.includes(currentNode.data.displayName) &&
      showPlusIcon === undefined
    ) {
      actions.history.ignore().setProp(nodeId, (props: any) => {
        props.showPlusIcon = true;
      });
    }
  }, []);

  const { selected, actions } = useEditor((state, query) => {
    const currentNodeId = query.getEvent('selected').last();
    let selected: any;

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        props: state.nodes[currentNodeId].data.props,
        settings: state.nodes[currentNodeId].related && state.nodes[currentNodeId].related.settings,
        isDeletable: query.node(currentNodeId).isDeletable()
      };
    }

    return {
      selected,
      enabled: state.options.enabled
    };
  });

  const handleSwitchSettings = async (e: any) => {
    if (!selected?.props) return;
    if(currentNode.data.displayName === CRAFT_ELEMENTS.RICH_TEXT){    
      goToElementSubSettings(RICH_TEXT_SETTINGS_VALUE.GENERAL)
    }
    const selectedElementProps = Object.keys(selected.props);
    if (
      selectedElementProps.includes('openParentSettingsOnChildSettings') ||
      selectedElementProps.includes('openChildSettingsOnParentSettings')
    ) {
      handleSelectCraftElement({
        actions,
        nodeId,
        query,
        openChildSettingsOnParentSettings,
        openParentSettingsOnChildSettings
      });
    } else {
      handleSelectCraftElement({
        actions,
        nodeId: selected.id,
        query,
        openChildSettingsOnParentSettings: false,
        openParentSettingsOnChildSettings: false
      });
    }

    trackInteraction({
      type: 'FUNCTION_CALL',
      customEventName: TRACKINGS.HANDLE_SWITCH_SETTINGS_IN_ACTIONS_CONTROLLER,
      additionalData: { selected }
    });

    if (isBuilderInvalid()) return;
    e.stopPropagation();
    await handleGeneralSettingsDataSave('2'); // because of which settings are going to each element
    // the first click that opens the container settings are from this file
  };

  // const elementName = currentNode.data.displayName;

  const Tag = ParentTag || 'div';
  return (
    <>
      <Tag
        {...restProps}
        ref={(ref: any) => {
          CurrentNodeRef.current = ref;
          connect(drag(ref));
          if (innerRef) innerRef.current = ref;
        }}
        elementtitle={elementTitle}
        className={`actions-controller__wrapper ${className} ${
          activeActionControllerClassNotRequired
            ? ''
            : selected?.id === nodeId
            ? 'active-actions-controller__wrapper'
            : ''
        } `}
        onDragStart={(e: any) => {
          e.preventDefault();
          return;
        }}
        onClick={handleSwitchSettings}
        {...ParentTagProps}
      >
        {label && isHovered && <div className="actions-controller__label">{label}</div>}
        {canHide?.hideSwich ? (
          <div className="hidden-container">
            <AiFillEyeInvisible size={50} />
          </div>
        ) : (
          children
        )}
        {showPlusIcon && <PlusIcon />}
      </Tag>
    </>
  );
};
