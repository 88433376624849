import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const GET_EMAIL_TEMPLATE = gql`
  query getApplicantNotificationTemplate($input: ApplicantNotificationTemplateInput) {
    getApplicantNotificationTemplate(input: $input) {
      message{
        content
      }
      email{
        html
        subject
      }
      channelType
    }
  }
`;

export default function useGetEmailTemplate(bookingId: number, applicantNotificationType: string) {
  return useQuery(GET_EMAIL_TEMPLATE, {
    variables: { input: { bookingId, applicantNotificationType } },
    fetchPolicy: 'no-cache'
  });
}
