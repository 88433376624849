import React, { useEffect } from 'react';
import DefaultEmptyViewForSettingsPanel from './DefaultEmptyViewForSettingsPanel';
import { useCurrentNodeSettings } from './useCurrentNodeSettings';
import SettingsPanelBase from './SettingsPanelBase';

export const SettingsPanel = () => {
  const { selected } = useCurrentNodeSettings();

  useEffect(() => {
    const builderNode = document?.getElementsByClassName('builder__content')?.[0];
    if (builderNode) {
      const targetNode: any = builderNode.childNodes[0].childNodes[0];
      targetNode.scrollTo(0, 0);
    }
  }, [selected]);

  return <SettingsPanelBase emptyViewComponent={<DefaultEmptyViewForSettingsPanel style={{ marginTop: '45%' }} />} />
};
