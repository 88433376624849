import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { useIsAgencyOSBuilderPath } from './useIsAgencyOSBuilderPath';

export const useIsMobileView = () => {
  const isAgencyOSBuilderPath = useIsAgencyOSBuilderPath();
  
  return useAppSelector((state: RootState) =>
  isAgencyOSBuilderPath ? false : state.builderUI.isMobileView
  );
};
