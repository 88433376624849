import gql from 'graphql-tag';

export interface CreateAvailableFontInput {
  fontFamily: string;
  fontLink: string;
  type: string;
}

const CREATE_AVAILABLE_FONT = gql`
mutation createAvailableFont($input: CreateAvailableFontInput!) {
  createAvailableFont(input: $input) {
    id
    fontFamily
    fontLink
    type
    createdAt
  }
}
`;

export function createAvailableFontApi(data:CreateAvailableFontInput) {
  return window.apolloClient.mutate({
    mutation: CREATE_AVAILABLE_FONT,
    variables: {
      input: data
    }
  });
}
