import { MailOutlined, UserOutlined } from '@ant-design/icons';
import { Element, useEditor, useNode } from '@craftjs/core';
import { Col, Card, Row, Switch, Form as AntForm, Input, Tabs } from 'antd';
import { Form as BootstrapForm } from 'react-bootstrap';
import React, { useEffect } from 'react';
import { ActionsController } from '../../../sharedUI/ActionsController';
import BuilderSettingsTitle from '../../../sharedUI/BuilderSettingsTitle';
import TextInput from '../components/TextInput';
import PhoneInput from '../components/PhoneInput';
import { useBuilderGeneralSettings } from '../../../../redux/builderSlice';
import { CRAFT_ELEMENTS, CRAFT_ELEMENTS_LABEL } from '../../../../config/craftElements';
import { ContentEditableEvent } from 'react-contenteditable';
import { urlFormValidator } from '../../../../../helper/url';
import { useAppDispatch } from '../../../../../redux/hooks';
import BuilderSettingsTogglePro from '../../../sharedUI/BuilderSettingsTogglePro';
import FrequentlyUsedIconPicker from '../../../../../GeneralComponents/FrequentlyUsedIconPicker/container/FrequentlyUsedIconPicker';
import EmojiPicker from '../../../../../GeneralComponents/EmojiPicker';
import IconPickerGrid from '../../../../../GeneralComponents/IconPickerGrid/container/IconPickerGrid';
import {
  IconProvider as Icon
  // @ts-ignore
} from 'meetovo-frontend-booking';
import { CraftElementBaseProps } from '../../../../helper/craftJs';
import { getUniqueId } from '../../../../helper/craftJs';
import { ButtonComponent } from '../../BuilderButtonComponent/ButtonComponent';
import { FUNNEL_THEME_KEYS } from '../../../../interfaces/builderSliceTypes';
import { translations } from '../../../../config/translation';
const { TabPane } = Tabs;

interface Props extends CraftElementBaseProps {
  phoneEnabled?: boolean;
  buttonText?: string;
  displayName?: string;
  legalInformationURL?: string;
  buttonTextColor?: string;
  buttonBackgroundColor?: string;
  isFirstRender?: boolean;
  generalPurposeFieldEnabled?: boolean;
  generalPurposeFieldPlaceholder?: string;
  generalPurposeFieldIcon?: string;
  generalPurposeFieldIconType?: string;
  breakNameFieldIntoFNAndLN?: boolean;
  children?: React.ReactNode;
}

const ContactForm = ({
  phoneEnabled,
  buttonText,
  displayName,
  legalInformationURL,
  isFirstRender,
  buttonTextColor,
  buttonBackgroundColor,
  generalPurposeFieldEnabled,
  generalPurposeFieldPlaceholder,
  generalPurposeFieldIcon,
  breakNameFieldIntoFNAndLN,
  children
}: Props) => {
  const { language } = useBuilderGeneralSettings();

  const { nodeId, currentNode } = useNode(node => {
    return {
      currentNode: node,
      nodeId: node.id
    };
  });

  const { query, actions } = useEditor();

  const handleSetChoiceTitle = (e: ContentEditableEvent) => {
    const newValue = e.target.value.replace(/<\/?[^>]+(>|$)/g, '');
    actions.setProp(nodeId, (props: any) => (props.buttonText = newValue));
  };

  useEffect(() => {
    if (!children && currentNode.data.nodes.length === 0) {
      const nodeTree = query
        .parseReactElement(
          <Element
            id={getUniqueId()}
            title={buttonText}
            color={buttonTextColor}
            backgroundColor={buttonBackgroundColor}
            is={ButtonComponent}
            onlySettingsReq={true}
            settings={{ shouldShowActionSelector: false }}
            showPlusIcon={false}
          ></Element>
        )
        .toNodeTree();
      actions.history.ignore().addNodeTree(nodeTree, nodeId);
    }
  }, []);

  return (
    <ActionsController label={CRAFT_ELEMENTS_LABEL[currentNode.data.displayName]}>
      <div className="contact-form__wrapper d-flex justify-content-center">
        <div className="contact-form__inner-wrapper max-width__700px-centered">
          {breakNameFieldIntoFNAndLN ? (
            <div className="d-flex gap-2">
              <TextInput
                prefix={<UserOutlined className="site-form-item-icon d-flex align-items-center" />}
                placeholder={
                  translations.firstName[language as keyof typeof translations.firstName]
                }
              />
              <TextInput
                prefix={<UserOutlined className="site-form-item-icon d-flex align-items-center" />}
                placeholder={translations.lastName[language as keyof typeof translations.lastName]}
              />
            </div>
          ) : (
            <TextInput
              prefix={<UserOutlined className="site-form-item-icon d-flex align-items-center" />}
              placeholder="Name"
            />
          )}
          <TextInput
            prefix={<MailOutlined className="site-form-item-icon d-flex align-items-center" />}
            placeholder="E-Mail"
          />

          {phoneEnabled && <PhoneInput />}

          {generalPurposeFieldEnabled && (
            <TextInput
              prefix={
                <Icon
                  name={generalPurposeFieldIcon}
                  className="site-form-item-icon d-flex align-items-center"
                />
              }
              placeholder={generalPurposeFieldPlaceholder}
              min={1}
              max={250}
            />
          )}

          <BootstrapForm.Check type="checkbox" className="mb-3">
            <BootstrapForm.Check.Input type="checkbox" />
            <BootstrapForm.Check.Label>
              {language === 'DE' ? (
                <>
                  {`Ich stimme den `}
                  <a href={legalInformationURL} target="_blank" rel="noreferrer">
                    Datenschutzbestimmungen
                  </a>
                  {` von ${displayName} zu und erlaube die Kontaktaufnahme.`}
                </>
              ) : (
                <>
                  {`I accept the `}
                  <a href={legalInformationURL} target="_blank" rel="noreferrer">
                    legal information
                  </a>
                  {` of ${displayName} and I agree to get contacted.`}
                </>
              )}
            </BootstrapForm.Check.Label>
          </BootstrapForm.Check>
          {children}
        </div>
      </div>
    </ActionsController>
  );
};

export default ContactForm;

enum IconType {
  icon = 'icon',
  emoji = 'emoji'
}

export const ContactFormDefaultProps: Props = {
  phoneEnabled: true,
  isFirstRender: true,
  buttonText: 'Bewerbung absenden',
  displayName: 'Vorname Nachname',
  buttonTextColor: FUNNEL_THEME_KEYS.ACCENT_COLOR_CONTRAST,
  buttonBackgroundColor: FUNNEL_THEME_KEYS.ACCENT_COLOR,
  legalInformationURL: '',
  generalPurposeFieldEnabled: false,
  generalPurposeFieldPlaceholder: '',
  generalPurposeFieldIconType: IconType.icon,
  generalPurposeFieldIcon: 'FaSmileWink',
  breakNameFieldIntoFNAndLN: false,
  copyNotReq: true
};

const ContactFormSettings = () => {
  const [form] = AntForm.useForm();

  const {
    actions: { setProp },
    props
  } = useNode(node => ({
    props: node.data.props
  }));

  return (
    <Col span={24} className="builder__settings-sidebar__container">
      <Card className="settings-card" title="Kontaktdaten" bordered={false}>
        <Row className="builder__settings-sidebar__row remove-margin-bottom">
          <Col span={24}>
            <AntForm
              name="lead-qualifier_settings_form"
              layout="vertical"
              form={form}
              scrollToFirstError={true}
              initialValues={{
                displayName: props.displayName || '',
                legalInformationURL: props.legalInformationURL || ''
              }}
              onChange={form.submit}
              onFinish={values => {
                setProp((props: any) => (props.displayName = values.displayName));
                setProp((props: any) => (props.legalInformationURL = values.legalInformationURL));
              }}
            >
              <BuilderSettingsTitle title="Rechtlicher Vertreter" />
              <AntForm.Item name="displayName">
                <Input />
              </AntForm.Item>
              <BuilderSettingsTitle title="Rechtliche Informationen" />
              <AntForm.Item
                name="legalInformationURL"
                rules={[urlFormValidator]}
                className="remove-margin-bottom"
              >
                <Input placeholder="https://deine-seite.de/datenschutz" />
              </AntForm.Item>
            </AntForm>
          </Col>
        </Row>
      </Card>

      <BuilderSettingsTogglePro
        classNames="border-bottom-0"
        title="Vor-und Nachname abfragen"
        rightContent={
          <Switch
            size="small"
            checked={props.breakNameFieldIntoFNAndLN}
            onChange={checked => {
              setProp((props: any) => (props.breakNameFieldIntoFNAndLN = checked));
            }}
          />
        }
      />
      <BuilderSettingsTogglePro
        title="Telefonnummer"
        rightContent={
          <Switch
            size="small"
            checked={props.phoneEnabled}
            onChange={checked => {
              setProp((props: any) => (props.phoneEnabled = checked));
            }}
          />
        }
      />
      <BuilderSettingsTogglePro
        title="Zusatzfeld"
        classNames="border-top-0"
        rightContent={
          <Switch
            size="small"
            checked={props.generalPurposeFieldEnabled}
            onChange={checked => {
              setProp((props: any) => (props.generalPurposeFieldEnabled = checked));
            }}
          />
        }
      />
      {props.generalPurposeFieldEnabled && (
        <Card className="settings-card" bordered={false}>
          <BuilderSettingsTitle title="Platzhalter" />
          <Input
            placeholder="z.B. Deine Postleitzahl"
            defaultValue={props.generalPurposeFieldPlaceholder}
            onChange={e => {
              setProp((props: any) => {
                props.generalPurposeFieldPlaceholder = e?.target?.value || '';
              }, 100);
            }}
          />
          <Tabs className="tabs__reset-left-padding" defaultActiveKey={props.type}>
            <TabPane tab="Icons" key={IconType.icon}>
              <Row className="builder__settings-sidebar__row">
                <Col span={24}>
                  <BuilderSettingsTitle title="Häufig verwendet" />
                  <FrequentlyUsedIconPicker
                    value={props.value}
                    onChange={(value: string) => {
                      setProp((props: any) => {
                        props.generalPurposeFieldIcon = value;
                        props.generalPurposeFieldIconType = IconType.icon;
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row className="builder__settings-sidebar__row">
                <Col span={24}>
                  <IconPickerGrid
                    onChange={value => {
                      setProp((props: any) => {
                        props.generalPurposeFieldIcon = value;
                        props.generalPurposeFieldIconType = IconType.icon;
                      });
                    }}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Emoji's" key={IconType.emoji} style={{ height: 600 }}>
              <EmojiPicker
                emojiSize={40}
                onChange={value => {
                  setProp((props: any) => {
                    props.generalPurposeFieldIcon = value;
                    props.generalPurposeFieldIconType = IconType.emoji;
                  });
                }}
              />
            </TabPane>
          </Tabs>
        </Card>
      )}
    </Col>
  );
};

ContactForm.craft = {
  name: CRAFT_ELEMENTS.CONTACT_FORM,
  props: ContactFormDefaultProps,
  related: {
    settings: ContactFormSettings
  },
  rules: {
    canMoveOut: () => false,
    canMoveIn: () => false
  }
};
