import gql from 'graphql-tag';

interface Input {
  id: number;
}

export const DELETE_AD_TEXT_TEMPLATE = gql`
  mutation deleteAdTextTemplate($id: Int!) {
    deleteAdTextTemplate(id: $id)
  }
`;

export default function deleteAdTextTemplate(input: Input) {
  return window.apolloClient.mutate({
    mutation: DELETE_AD_TEXT_TEMPLATE,
    variables: { id: input.id }
  });
}
