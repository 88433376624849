import { SizeInterface } from './TextTypeInterface';

export const containerMinHeightForMobile: SizeInterface[] = [
  {
    key: 1,
    value: '0px'
  },
  {
    key: 2,
    value: '100px'
  },
  {
    key: 3,
    value: '200px'
  },
  {
    key: 4,
    value: '300px'
  },
  {
    key: 5,
    value: '400px'
  }
];

export const defaultContainerMinHeightForMobile = containerMinHeightForMobile[0];

export const containerMinHeightForDesktop: SizeInterface[] = [
  {
    key: 1,
    value: 'auto'
  },
  {
    key: 2,
    value: '200px'
  },
  {
    key: 3,
    value: '300px'
  },
  {
    key: 4,
    value: '400px'
  },
  {
    key: 5,
    value: '500px'
  },
  {
    key: 6,
    value: '600px'
  }
];

export const defaultContainerMinHeightForDesktop = containerMinHeightForDesktop[0];
