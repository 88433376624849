import React from 'react';
import { AlignLeftOutlined, AlignCenterOutlined, AlignRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';

type Aligns = 'left' | 'center' | 'right';
interface Props {
  align: Aligns;
  onChange(align: Aligns): void;
  disabled?: boolean;
}

const TextAlignmentButtons = ({ align, onChange, disabled }: Props) => {
  return (
    <div className="text-formatting-buttons text-formatting-buttons__alignment">
      <Button
        disabled={disabled}
        size="large"
        type={align === 'left' ? 'primary' : undefined}
        icon={<AlignLeftOutlined />}
        onClick={(e: any) => {
          onChange('left');
        }}
        shape="circle"
        data-testid='text-alignment-left-alignment-button'
      />
      <Button
        disabled={disabled}
        className="m-2 mt-1 mb-1"
        size="large"
        type={align === 'center' ? 'primary' : undefined}
        icon={<AlignCenterOutlined />}
        onClick={(e: any) => {
          onChange('center');
        }}
        shape="circle"
      />
      <Button
        disabled={disabled}
        size="large"
        type={align === 'right' ? 'primary' : undefined}
        icon={<AlignRightOutlined />}
        onClick={(e: any) => {
          onChange('right');
        }}
        shape="circle"
      />
    </div>
  );
};

export default TextAlignmentButtons;
