interface GetIpAndUserAgentCallbackInterface {
  clientIp: string;
  clientUserAgent: string;
}

let savedIpAndUserAgent: GetIpAndUserAgentCallbackInterface;
export const getIpAndUserAgent = async (cb: (data: GetIpAndUserAgentCallbackInterface) => any) => {
  const emptySavedIpAndUserAgent = { clientIp: '', clientUserAgent: '' };

  try {
    if (savedIpAndUserAgent) {
      cb(savedIpAndUserAgent);
      return;
    }

    const { clientIp } =
      (await fetch('https://extreme-ip-lookup.com/json')
        .then(res => res.json())
        .then(data => {
          if (!data?.query) return {};
          return { clientIp: data.query };
        })) || {};

    const clientUserAgent = navigator.userAgent;

    if (!clientIp || !clientUserAgent) {
      cb(emptySavedIpAndUserAgent);
      return;
    }

    savedIpAndUserAgent = {
      ...emptySavedIpAndUserAgent,
      clientIp,
      clientUserAgent
    };
    cb(savedIpAndUserAgent);
  } catch (error) {
    cb(emptySavedIpAndUserAgent);
  }
};
