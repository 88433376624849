import React from 'react';
import { MdSchool } from 'react-icons/md';
import { ExperienceDuration } from '../../../../AdJobBuilder/helper/AdJobHelper';

export enum TARGET_GROUP_ELEMENTS {
  AGE = 'age',
  GOALS = 'goals',
  SKILLS = 'skills',
  EXPERIENCE = 'experience',
  EDUCATION = 'education',
  EXPERIENCE_DURATION = 'experienceDuration'
}

export enum AGE_OPTION_VALUES {
  LESS_THAN_25 = 'lessThan25',
  _26_TO_34 = '26to34',
  _35_TO_44 = '35to44',
  _45_TO_54 = '45to54',
  _55_PLUS = '55plus'
}

export enum GENDER_OPTION_VALUES {
  MASCULINE = 'Masculine',
  FEMININE = 'Feminine',
  DIVERSE = 'Diverse',
  NO_MATTER = 'NoMatter'
}
export enum educationRequirementCategories {
  HIGH_SCHOOL = 'high school',
  ASSOCIATE_DEGREE = 'associate degree',
  BACHELOR_DEGREE = 'bachelor degree',
  PROFESSIONAL_CERTIFICATE = 'professional certificate',
  POSTGRADUATE_DEGREE = 'postgraduate degree'
}

export type TargetGroupOptions =
  | TARGET_GROUP_ELEMENTS.AGE
  | TARGET_GROUP_ELEMENTS.GOALS
  | TARGET_GROUP_ELEMENTS.SKILLS
  | TARGET_GROUP_ELEMENTS.EXPERIENCE
  | TARGET_GROUP_ELEMENTS.EDUCATION
  | TARGET_GROUP_ELEMENTS.EXPERIENCE_DURATION;

export const getTargetGroupOptions = (key: TargetGroupOptions) => {
  const obj = {
    icon: <></>,
    age: {
      options: [
        { label: '< 25', value: AGE_OPTION_VALUES.LESS_THAN_25 },
        { label: '26-34', value: AGE_OPTION_VALUES._26_TO_34 },
        { label: '35-44', value: AGE_OPTION_VALUES._35_TO_44 },
        { label: '45-54', value: AGE_OPTION_VALUES._45_TO_54 },
        { label: '55+', value: AGE_OPTION_VALUES._55_PLUS }
      ]
    },
    goals: {
      icon: <></>,
      options: []
    },
    skills: {
      icon: <></>,
      options: []
    },
    experience: {
      icon: <></>,
      options: []
    },
    experienceDuration: {
      icon: <></>,
      options: [
        { label: 'Monate', value: ExperienceDuration.MONTHS },
        { label: 'Jahre', value: ExperienceDuration.YEARS }
      ]
    },
    education: {
      icon: <MdSchool />,
      options: [
        { label: 'Schule', value: educationRequirementCategories.HIGH_SCHOOL },
        { label: 'Fachschule', value: educationRequirementCategories.ASSOCIATE_DEGREE },
        { label: 'Studium', value: educationRequirementCategories.BACHELOR_DEGREE },
        {
          label: 'Ausbildung',
          value: educationRequirementCategories.PROFESSIONAL_CERTIFICATE
        },
        {
          label: 'Promoviert',
          value: educationRequirementCategories.POSTGRADUATE_DEGREE
        }
      ]
    }
  };

  return obj[key] || obj[TARGET_GROUP_ELEMENTS.AGE];
};

export function getOptionsValuesInListAi(type: any, values: string[], options: any[]) {
  const labels = values.reduce((acc, value) => {
    const label = options.find(({ value: _value }) => _value === value)?.label;

    if (label) return [...acc, label];

    return acc;
  }, [] as string[]);

  return labels.join(', ') || '';
}
