import React from 'react';
import { Alert } from 'antd';
import { uniqueIdentifierMessage } from '../../../config/messages';
import { WarningOutlined } from '@ant-design/icons';

export interface UniqueIdentiferProps {
  oldUniqueIdentifier: string | undefined;
  newUniqueIdentifier: string | undefined;
}

const UniqueIdentifierNoLongerAvailable = ({
  oldUniqueIdentifier,
  newUniqueIdentifier
}: UniqueIdentiferProps) => {
  return (
    <>
      {oldUniqueIdentifier !== newUniqueIdentifier && (
        <Alert
          className="my-2"
          message={uniqueIdentifierMessage.noLongerAccessible}
          type="error"
          icon={<WarningOutlined />}
          showIcon={true}
        />
      )}
    </>
  );
};
export default UniqueIdentifierNoLongerAvailable;
