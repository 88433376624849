import { Store } from 'antd/lib/form/interface';
import { deepCopy } from '../../helper/array';

interface MutateKeysWithIdSuffixInterface {
  data: object[] | Store | Object;
  exclude?: string[];
  keyPrefix?: string;
}

interface MutateObjectKeysWithIdSuffixInterface {
  obj?: any;
  keyPrefix?: string;
  exclude?: string[];
}

export const mutateArrayOfObjectsKeysWithIdSuffix = ({
  data,
  exclude,
  keyPrefix
}: MutateKeysWithIdSuffixInterface) => {
  let mutatedObject = {};

  if (!Array.isArray(data)) {
    return {};
  }

  data.forEach((obj: any, i: number) => {
    mutatedObject = {
      ...mutatedObject,
      ...mutateObjectKeysWithIdSuffix({ obj, exclude, keyPrefix })
    };
  });

  return mutatedObject;
};

export const mutateObjectKeysWithIdSuffix = ({
  obj,
  keyPrefix,
  exclude
}: MutateObjectKeysWithIdSuffixInterface) => {
  if (!obj) return obj;
  if (!obj.id) throw new Error(JSON.stringify(obj) + ' has now id');
  if (!exclude) exclude = ['id'];

  let mutatedObject = {};

  Object.keys(obj)
    // @ts-ignore
    .filter(k => !exclude.includes(k))
    .forEach(k => {
      const value = obj[k];
      const id = obj.id;

      if (Array.isArray(value) && typeof value[0] === 'object') {
        const newKey = (keyPrefix ? keyPrefix + '_' : '') + k + '_' + id;
        mutatedObject = {
          ...mutatedObject,
          ...mutateArrayOfObjectsKeysWithIdSuffix({ data: value, keyPrefix: newKey })
        };
      } else {
        // @ts-ignore
        mutatedObject[(keyPrefix ? keyPrefix + '_' : '') + k + '_' + id] = value;
      }
    });

  return mutatedObject;
};

export const normalizeKeysWithIdSuffix = (object: any): [] => {
  return Object.keys(object).reduce((acc: any, k: string) => {
    const nextDimensionPattern = new RegExp('([a-zA-Z]*)_([0-9]*)_');
    const nextDimensionMatch = k.match(nextDimensionPattern);

    const originalKeyPattern = new RegExp('([A-Za-z]+)');
    const key = k.match(originalKeyPattern)?.[0];

    const idPattern = new RegExp(key + '_([0-9]+)');
    const id = parseInt(k.match(idPattern)?.[1] || '');

    if (!key || !id) return acc;

    let value;
    if (nextDimensionMatch) {
      const nextDimensionKeyPrefix: any = k.match(nextDimensionPattern)?.[0];

      const nextDimensionValues: { [index: string]: any } = {};
      Object.keys(object).forEach((k2: string) => {
        if (k2.includes(nextDimensionKeyPrefix)) {
          const nextDimensionItemKey: string = k2.replace(nextDimensionKeyPrefix, '');
          nextDimensionValues[nextDimensionItemKey] = object[k2];
        }
      });
      value = normalizeKeysWithIdSuffix(nextDimensionValues);
    } else {
      value = object[k];
    }
    mutateArrayOfObjectsKeysWithIdSuffix;
    const index = acc.findIndex((sa: any) => sa.id === id);
    if (index === -1) return [...acc, { id, [key]: value }];
    else acc[index][key] = value;

    return acc;
  }, []);
};

export const mergeFormValuesToNormalData = (
  changedValues: { [index: string]: any },
  data: object[] | Store
) => {
  const currentValues: { [key: string]: string } = mutateArrayOfObjectsKeysWithIdSuffix({
    data
  });

  Object.keys(changedValues).forEach(k => {
    currentValues[k] = changedValues[k];
  });
  return normalizeKeysWithIdSuffix(currentValues);
};

export const getNewId = (items: object[]) => {
  const newId = items.reduce((acc: any, ls: any) => (ls.id > acc ? ls.id : acc), 0) + 1;
  // id multiplizieren damit die künstlichen daten nicht mit der neuen überinstimmen
  return newId * 2;
};

export const getNewIdAndWeight = (items: object[]) => {
  const newId = getNewId(items);
  const newWeight = items.reduce((acc: any, ls: any) => (ls.weight > acc ? ls.weight : acc), 0) + 1;

  return { newId, newWeight };
};

export const getSubmissionDataFromTempData = (items: object[], keysToRemove?: string[]) => {
  const idsToDelete: number[] = [];
  const submissionData = JSON.parse(JSON.stringify(items)).reduce((acc: any, lt: any) => {
    const { toDelete, tempId, id } = lt;

    if (keysToRemove?.length) keysToRemove.forEach(key => delete lt[key]);

    if (tempId && toDelete) return acc;

    if (lt.hasOwnProperty('isPartialMatch')) {
      delete lt.isPartialMatch;
    }

    if (tempId) {
      delete lt.tempId;
      delete lt.id;
      return [...acc, lt];
    }

    if (toDelete && id) {
      idsToDelete.push(id);
      return acc;
    }

    if (id) return [...acc, lt];

    return acc;
  }, []);

  return { submissionData, idsToDelete };
};

export const softRemoveById = (id: number, items: object[]) => {
  const copiedData = deepCopy(items, ['voiceMessageFile']);
  const itemIndex = copiedData.findIndex(({ id: thisId }: any) => thisId === id);

  if (itemIndex < 0) return items;

  copiedData[itemIndex].toDelete = true;

  return copiedData;
};
