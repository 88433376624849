import gql from 'graphql-tag';

export interface Input {
  id: number;
  funnelFolderId: number | null;
}

const SET_FUNNEL_FOLDER = gql`
  mutation setFunnelFolder($input: FunnelFolderInput!) {
    setFunnelFolder(input: $input) {
      id
      funnelFolder {
        id
      }
    }
  }
`;

export default function setFunnelFolder(input: Input) {
  return window.apolloClient.mutate({
    mutation: SET_FUNNEL_FOLDER,
    variables: { input }
  });
}
