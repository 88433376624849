import gql from 'graphql-tag';
import { builderPresetElements } from '../interfaces/builderSliceTypes';

export interface Input {
  preset: string;
  presetType: builderPresetElements;
}

const SET_FUNNEL_PRESETS = gql`
  mutation SetFunnelPresets($input: [SetFunnelPresetsInput]!) {
    setFunnelPresets(input: $input)
  }
`;

export default function setFunnelPresets(input: Input[]) {
  return window.apolloClient.mutate({
    mutation: SET_FUNNEL_PRESETS,
    variables: { input }
  });
}
