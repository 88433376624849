import React, { useEffect, useRef, useState } from 'react';
import { Layout, Tag } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useAppDispatch } from '../../../redux/hooks';
import {
  useBuilderGeneralSettings,
  useShouldSaveFunnel,
  useSelectedBlockNodeIds,
  resetSelectedBlockNodeIds,
  resetToInitialBuilderState,
  useFunnel,
  useMainFunnelLoader,
  setGeneralSettingsTabView,
  useJobInformation,
  useCompanyDetail,
  useTargetAudience
} from '../../redux/builderSlice';
import { useNavigate } from 'react-router';
import { builderInvalidationClass } from '../../config/builderUi';
import Logo from '../../../UI/components/Logo';
import IconSidebar from '../../layout/IconSidebar';
import { AiFillSave } from 'react-icons/ai';
import { returnUrlToMyFunnels } from '../../../Funnel/redux/funnelSlice';
import { Editor, useEditor } from '@craftjs/core';
import AdJobSettings from '../components/sharedUI/AdJobSettings';
import PublishAdModal from '../components/ui/PublishJob';
import debounce from 'debounce';
import { useInternalEditorReturnType } from '@craftjs/core/lib/editor/useInternalEditor';
import { Delete } from 'craftjs-utils-meetovo';
import { useCraftStringifiedState } from '../../helper/craftJs';
import { CRAFT_ELEMENTS } from '../config/craftElements';
import {
  editIsJobSaved,
  resetToInitialJobBuilderState,
  useJob,
  useJobBuilderLoading
} from '../redux/jobBuilderSlice';
import DefaultLoader from '../../../SharedUI/components/DefaultLoader';
import DontUseBrowserButtonsWarning from '../../components/ui/DontUseBrowserButtonsWarning';
import { saveJobBuilderStateThunk } from '../redux/thunk';
import GoogleJobsBanner from '../components/sharedUI/GoogleJobsBanner';
import { CraftOptionPicker } from '../components/sharedUI/CraftOptionPicker';
import { CraftAddressPicker } from '../components/sharedUI/AddressPicker';
import RichTextComponent from '../../components/elements/RichTextComponent/container/RichTextComponent';
import { CraftRangeSelector } from '../components/sharedUI/CraftRangeSelector';
import { RenderNode } from '../../renderNode/RenderNode';
import ImageComponent from '../../components/elements/ImageComponent';
import { BuilderPreviewBase } from '../../components/ui/BuilderPreviewBase';
import { CraftDatePicker } from '../components/sharedUI/CraftDatePicker';
import { BasicAllOptionPicker } from '../components/sharedUI/BasicAllOptionPicker';
import {
  findEnumForFirstKey,
  validateFunnelGoogleJobPresets,
  validateFunnelGoogleJobValues
} from '../helper/validateFunnelJobPresets';
import GeneralSettingsModal from '../components/sharedUI/GeneralSettingsModal';
import { GeneralSettingsTabView } from '../../interfaces/builderSliceTypes';
import JobSaveIndicator from '../components/ui/JobSaveIndicator';
import { JOB_RELATED_DATA_TYPES } from '../helper/AdJobHelper';

const CraftJsUserComponents = {
  [CRAFT_ELEMENTS.IMAGE]: ImageComponent,
  [CRAFT_ELEMENTS.RICH_TEXT]: RichTextComponent,
  [CRAFT_ELEMENTS.CRAFT_OPTION_PICKER]: CraftOptionPicker,
  [CRAFT_ELEMENTS.CRAFT_ADDRESS_PICKER]: CraftAddressPicker,
  [CRAFT_ELEMENTS.Craft_Range_Selector]: CraftRangeSelector,
  [CRAFT_ELEMENTS.Craft_Date_Picker]: CraftDatePicker,
  [CRAFT_ELEMENTS.BASIC_ALL_OPTION_PICKER]: BasicAllOptionPicker
};

const { Header, Sider, Content } = Layout;
const AdJobBuilderLayoutScreen = () => {
  const [openJobValidationModal, setOpenJobValidationModal] = useState(false);
  const [settingsToOpen, setSettingsToOpen] = useState(GeneralSettingsTabView.JOB_INFORMATION);
  const job = useJob();
  const { craftState } = job;
  const craftStateRef = useRef(craftState);
  const dispatch = useAppDispatch();
  const funnel = useFunnel();
  const jobInformation = useJobInformation();
  const companyDetails = useCompanyDetail();
  const funnelLoader = useMainFunnelLoader();
  const targetGroup = useTargetAudience();
  const loading = useJobBuilderLoading();

  useEffect(() => {
    craftStateRef.current = craftState;
  }, [craftState]);

  useEffect(() => {
    const isNotValid = validateFunnelGoogleJobPresets({
      ...funnel,
      ...jobInformation,
      ...companyDetails,
      ...targetGroup
    });
    const invalidFields = validateFunnelGoogleJobValues(funnel);
    const isValid = !invalidFields.length;
    if (isNotValid && !funnelLoader) {
      setOpenJobValidationModal(true);
      const routeDecider = findEnumForFirstKey(invalidFields);
      if (routeDecider === JOB_RELATED_DATA_TYPES.JobAttributesIdentifier) {
        setSettingsToOpen(GeneralSettingsTabView.JOB_INFORMATION);
      } else if (routeDecider === JOB_RELATED_DATA_TYPES.TARGET_GROUP_ELEMENTS) {
        setSettingsToOpen(GeneralSettingsTabView.TARGET_GROUP);
      }
    } else if (!isNotValid && isValid) {
      dispatch(setGeneralSettingsTabView(GeneralSettingsTabView.GENERAL_SETTINGS));
    }
  }, [funnelLoader, funnel]);

  const handleOnNodesChange = debounce(
    (query: Delete<useInternalEditorReturnType<any>['query'], 'deserialize'>) => {
      window.craftJsJobBuilderQuery = query;
      const craftStringifiedState = useCraftStringifiedState(query);
      window.__CRAFT_STATE__ = craftStringifiedState;
      if (JSON.stringify(craftStateRef.current) !== JSON.stringify(craftStringifiedState)) {
        dispatch(editIsJobSaved(false));
      } else {
        dispatch(editIsJobSaved(true));
      }
    },
    1500
  );

  return (
    <>
      <DefaultLoader loading={loading} />
      <DontUseBrowserButtonsWarning />
      <Layout className="meetovo-funnel-builder-layout">
        <Editor
          onRender={RenderNode}
          enabled={true}
          resolver={CraftJsUserComponents}
          onNodesChange={handleOnNodesChange}
        >
          <Header className="builder__header">
            <HeaderContent />
          </Header>
          <Sider theme="light" className="builder__icon-sidebar">
            <IconSidebar />
          </Sider>
          {craftState && (
            <>
              <Sider theme="light" className="builder__settings-sidebar ad-sidebar-style">
                <AdJobSettings />
              </Sider>
              <div className="job-builder-container">
                <GoogleJobsBanner />
                <Content className="builder__content">
                  <BuilderPreviewBase
                    pagesExisting={Boolean(job.craftState)}
                    className="job-builder"
                    selectedPage={job}
                    shouldReplacePlaceHolders
                  />
                </Content>
              </div>
            </>
          )}
        </Editor>
      </Layout>
      <GeneralSettingsModal
        settingsToOpen={settingsToOpen}
        openJobValidationModal={openJobValidationModal}
        setOpenJobValidationModal={setOpenJobValidationModal}
      />
    </>
  );
};

export default AdJobBuilderLayoutScreen;

const HeaderContent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const generalSettings = useBuilderGeneralSettings();
  const selectedBlockNodeIds = useSelectedBlockNodeIds();
  const [openCreateMultiBlockTemplate, setOpenCreateMultiBlockTemplate] = useState(false);
  const shouldSaveFunnel = useShouldSaveFunnel();
  const returnUrlToMyFunnelsLink = returnUrlToMyFunnels();
  const { query } = useEditor();

  const handleSaveBuilder = async () => {
    if (!shouldSaveFunnel) {
      navigate(returnUrlToMyFunnelsLink);
      dispatch(resetToInitialBuilderState());
      dispatch(resetToInitialJobBuilderState());
      return;
    }

    dispatch(
      saveJobBuilderStateThunk({
        query,
        callbackOnSucces: () => {
          navigate(returnUrlToMyFunnelsLink);
          dispatch(resetToInitialBuilderState());
          dispatch(resetToInitialJobBuilderState());
        }
      })
    );
  };

  const toggleVisibilityOfCreateMultiBlockTemplate = () => {
    setOpenCreateMultiBlockTemplate(!openCreateMultiBlockTemplate);
  };

  return (
    <>
      <div className="builder__header__logo-wrapper">
        <span
          className={`builder__header__logo-wrapper__logo ${builderInvalidationClass}`}
          onClick={handleSaveBuilder}
        >
          <Logo iconOnly redirectionNeeded={false} />
        </span>
        <div className="builder__header__logo-wrapper__title">
          {generalSettings?.title || 'Mein Recruiting Funnel'}
        </div>
        <div>
          <JobSaveIndicator />
        </div>
      </div>

      <div className="d-flex align-items-center me-2">
        {!!selectedBlockNodeIds.length && (
          <div>
            <Tag
              className="d-flex align-items-center me-2 py-2"
              closable
              onClose={() => {
                dispatch(resetSelectedBlockNodeIds());
              }}
            >
              <AiFillSave
                className="cursor-pointer me-2"
                size={20}
                onClick={toggleVisibilityOfCreateMultiBlockTemplate}
              />
            </Tag>
          </div>
        )}
        <div className={`builder__header__right-wrapper ${builderInvalidationClass}`}>
          <PublishAdModal />
          <CloseOutlined onClick={handleSaveBuilder} />
        </div>
      </div>
    </>
  );
};
