import { ContentState, EditorState } from 'draft-js';
import { useNavigate } from 'react-router';

export const initializeDraftWithContent = (content = '') => {
  return EditorState.createWithContent(ContentState.createFromText(`${content}`));
};

export enum  BuilderIdentifier {
  WIDGET_BUILDER = 'widgetBuilder',
  JOB_BUILDER = 'jobBuilder',
}

export enum RICH_TEXT_SETTINGS_VALUE {
  AI = 'ai',
  GENERAL = 'general'
}

export const useElementSubSettings = () => {
  const navigate = useNavigate();
  const goToElementSubSettings = (settings: RICH_TEXT_SETTINGS_VALUE) => {
    const params = new URLSearchParams({
      elementSubSettings: settings
    });
    navigate(`?${params.toString()}`);
  };
  return { goToElementSubSettings };
};
