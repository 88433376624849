import React from 'react';
import { Modal, Result } from 'antd';
function GoogleCalendarModal({
  visible,
  setVisibility,
}: {
  visible: boolean;
  setVisibility: (visible: boolean) => void;
}) {
  const handleCancel: () => void = () => {
    setVisibility(false);
  };

  return (
    <React.Fragment>
      <Modal
        width={500}
        visible={visible}
        destroyOnClose
        onCancel={handleCancel}
        className="mb-6"
        footer={null}
      >
        <Result
          status="info"
          title="Google-Account-Verknüpfung aufheben"
          subTitle={
           <>
            <p>
            Um die Verbindung zu deinem Google-Account aufzuheben und auf den E-Mail-Login zu wechseln, gehe bitte unter "Mein Account" zu “E-Mail-Login”. Alternativ kannst du die Zugriffsrechte in deinem Google-Konto entfernen, um die Verbindung zu deinem Account erneut durchzuführen. Weitere Informationen findest du in diesem <a href="https://hilfe.meetovo.de/de/article/google-calendar-richtig-verbinden-auflosen-1cj01tx/#3-2-verbindung-in-meetovo-aufheben"  target="_blank">Hilfeartikel</a>
            </p>
            <p>Für weitere Fragen stehen wir dir gerne im Support-Chat zur Verfügung.</p>
           </>
          }
        />
      </Modal>
    </React.Fragment>
  );
}

export default GoogleCalendarModal;
