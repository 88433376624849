import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export enum ActionEvents {
  LAST_NOT_REACHED_TIME = 'LAST_NOT_REACHED_TIME',
  REACHED_TIME = 'REACHED_TIME'
}

export const GET_BOOKINGS = gql`
  query getBookings($filter: BookingFilterV2!) {
    getBookings(input: $filter) {
      id
      name
      firstName
      lastName
      email
      phone
      generalPurpose
      eventStart
      eventEnd
      createdAt
      funnelId
      utmParameter
      version
      bookingActionEvent{
        id
        eventTime
        actionEventType
      }
      
      choices {
        choiceId
        leadQualifierId
        stringValue
        numberValue
        voiceMessageAWSKey
      }
      bookingStatus {
        color
        status
        value
        id
      }
      file {
        fileLink
        name
        expirationDate
      }
    }
  }
`;

export default function useBookings(variables: any) {
  return useQuery(GET_BOOKINGS, {
    variables: {
      ...variables,
      filter: {
        ...variables.filter,
        includeChoices: false
      }
    },
    fetchPolicy: 'no-cache'
  });
}
