import gql from 'graphql-tag';

interface Input {
  id: number;
}

export const DELETE_AD_TEMPLATE = gql`
  mutation deleteAdTemplate($id: Int!) {
    deleteAdTemplate(id: $id)
  }
`;

export default function deleteAdTemplate(input: Input) {
  return window.apolloClient.mutate({
    mutation: DELETE_AD_TEMPLATE,
    variables: { id: input.id }
  });
}
