import { IconType } from '../components/elements/SingleChoiceComponent/components/Choice';

export const defaultQuestion = 'Was ist dir bei einem neuen Arbeitsplatz wichtig?';

export interface DefaultChoice {
  content: string;
  type: IconType;
  value: string;
}

export const defaultChoices = [
  { content: 'Sicherer & stabiler Arbeitsplatz', type: IconType.icon, value: 'FaShieldAlt' },
  { content: 'Freie Zeiteinteilung', type: IconType.icon, value: 'FiClock' },
  { content: 'Weiterbildung', type: IconType.icon, value: 'FaBook' }
];
