import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { useDebounce } from '../../Builder/helper/sharedFunctions';

export default function DefaultLoader({ loading }: { loading: boolean }) {
  loading = useDebounce(loading, 200);

  if (!loading) return null;

  return (
    <div className="default-loader">
      <LoadingOutlined className="centered" style={{ position: 'fixed', zIndex: 111 }} spin />
      <div className="loading-overlay"></div>
    </div>
  );
}
