import { Tooltip } from 'antd';
import React from 'react';
import { tooltipText } from '../../helper/tooltipText';
import { useEditor, useNode } from '@craftjs/core';
import { AiFillEyeInvisible, AiTwotoneEye } from 'react-icons/ai';

const HideAction = () => {
  const { actions, query } = useEditor();
  const { name, nodeId, hide } = useNode(node => {
    return {
      nodeId: node.id,
      name: node.data.custom.displayName || node.data.displayName,
      hide: node.data.props.canHide.hideSwich
    };
  });

  const handleHide = () => {
    actions.setProp(nodeId, (state: any) => {
      state.canHide.hideSwich = !state.canHide.hideSwich;
    });
  };

  return (
    <li style={{ width: 40 }} onClick={handleHide}>
      {hide ? (
        <Tooltip title={tooltipText.showContent}>
          <AiTwotoneEye />
        </Tooltip>
      ) : (
        <Tooltip title={tooltipText.hideContent}>
          <AiFillEyeInvisible />
        </Tooltip>
      )}
    </li>
  );
};

export default HideAction;
