import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import SpecialClosure from '../../Funnel/components/subcomponents/SpecialClosures';

interface SpecialClosure {
  id: number
  funnelId: number
  start: Date
  end: Date
}
interface Shift {
  id: number
  funnelId: number
  dow: number[]
  start: string
  end: string
}
interface BookingSettings {
  bookingRangeInHours: number[]
  maxBookingsPerDay: number
  slotBufferInMinutes: number
  durationInMinutes: number
}

export interface CalendarSettings {
  bookingRangeInHours: number[]
  maxBookingsPerDay: number
  shifts: Shift[]
  durationInMinutes: number
  specialClosures: SpecialClosure[]
  slotBufferInMinutes: number
  calendarId: string
  busyStatus: string
}

interface SetCalendarSettingsAPI {
  shifts: Shift[]
  specialClosures: SpecialClosure[]
  bookingSettings: {
    bookingRangeInHours: number[]
    maxBookingsPerDay: number
    durationInMinutes: number
    slotBufferInMinutes: number
  }
}

export const SET_CALENDAR_SETTINGS = gql`
  mutation setCalendarSettings(
    $shifts: [ShiftData!]!
    $specialClosures: [SpecialClosureData!]!
    $bookingSettings: BookingSettingsData!
  ) {
    setShifts(input: $shifts) {
      id
    }
    setSpecialClosures(input: $specialClosures) {
      id
    }
    setBookingSettings(input: $bookingSettings) {
      id
    }
  }
`;

export default function setCalendarSettings() {
  return useMutation(SET_CALENDAR_SETTINGS);
}

export function setCalendarSettingsAPI(params: SetCalendarSettingsAPI): Promise<any> {
  return window.apolloClient.mutate({
    mutation: SET_CALENDAR_SETTINGS,
    variables: params,
  });
}

