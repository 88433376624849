import gql from 'graphql-tag';

export const GET_MESSAGE_TEMPLATES = gql`
  query getMessageTemplates {
    getMessageTemplates {
      id
      timeInMinutesTillEvent
      companyName
      contactNumber
      contactPersonCallNumber
      enabled
      content
      scheduledTime
      type
    }
  }
`;

export async function fetchMessageTemplates() {
  try {
    const { data } = await window.apolloClient.query({
      query: GET_MESSAGE_TEMPLATES,
      fetchPolicy: 'no-cache'
    });
    return data.getMessageTemplates;
  } catch (error) {
    return [];
  }
}
