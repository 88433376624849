import gql from 'graphql-tag';
import { BuilderPageDataType, GeneralSettings, MetaData } from '../interfaces/builderSliceTypes';

export interface Input {
  pages: BuilderPageDataType[];
  funnelData: {
    generalSettings: GeneralSettings;
    metaData: MetaData;
    calendarSettings?:
      | {
          calendarId: string;
          calendarBlockMode: string;
        }
      | {};
  };
  aiSettingsUpdatedAt: Date | null;
}

export const SET_FUNNEL_AND_PAGES = gql`
  mutation setCompleteFunnel($pages: [SetFunnelPageInput!], $funnelData: FunnelData!) {
    setFunnelPages(input: $pages) {
      id
      name
      craftState
      funnelId
      type
      redirectURL
      pageOrder
    }
    setFunnel(input: $funnelData) {
      id
      uniqueIdentifier
      description
      title
      metaTitle
      customMetaDataEnabled
      iconURL
      previewImageURL
    }
  }
`;

export default function setFunnelAndPagesAPI(input: Input) {
  return window.apolloClient.mutate({
    mutation: SET_FUNNEL_AND_PAGES,
    variables: {
      pages: input.pages,
      funnelData: {
        ...input.funnelData.generalSettings,
        ...input.funnelData.metaData,
        ...input.funnelData.calendarSettings,
        ...(input?.aiSettingsUpdatedAt ? { aiSettingsUpdatedAt: input?.aiSettingsUpdatedAt } : {})
      }
    }
  });
}
