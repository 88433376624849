import { Node, SerializedNodes } from '@craftjs/core/lib/interfaces/index';
import { Dispatch, SetStateAction } from 'react';
import { CRAFT_ELEMENTS } from '../../../config/craftElements';
import { CraftElementBaseProps } from '../../../helper/craftJs';

export interface ContainerComponentProps extends CraftElementBaseProps {
  backgroundColor?: string;
  paddingTopClass?: string;
  paddingBottomClass?: string;
  minHeight?: number | string;
  children?: any;
  copyNotReq?: boolean;
  isFirstRender?: boolean;
  sectionId?: string;
  align?: string;
  elementName?: string;
  backgroundImg?: string;
  opacity?: string;
  backgroundImgEnabled?: boolean;
  containerMinHeightForMobile?: string;
  containerMinHeightDesktop?: string;
  containerBackgroundAlignment?: string;
}

export interface CreateTemplateButtonProps {
  currentNode: Node;
  setTemplateCraftState: Dispatch<SetStateAction<string>>;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

export enum ContainerPaddingClasses {
  CONTAINER_PADDING_S_TOP = 'container__padding-s-top',
  CONTAINER_PADDING_S_BOTTOM = 'container__padding-s-bottom',
  CONTAINER_PADDING_M_TOP = 'container__padding-m-top',
  CONTAINER_PADDING_M_BOTTOM = 'container__padding-m-bottom',
  CONTAINER_PADDING_L_TOP = 'container__padding-l-top',
  CONTAINER_PADDING_L_BOTTOM = 'container__padding-l-bottom'
}
