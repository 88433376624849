import React, { useEffect } from 'react';
import { Button, Form, Input, Modal, Table } from 'antd';
import {
  urlPlaceholderColumns,
  urlPlaceholderData
} from '../../../Funnel/utils/redirect-url-placeholders';
import { urlFormValidator } from '../../../helper/url';
import { BuilderPageDataType } from '../../interfaces/builderSliceTypes';
import { useAppDispatch } from '../../../redux/hooks';
import { updateFunnelPageThunk } from '../../redux/thunk';

interface Props {
  visible: boolean;
  toggleVisible(data: boolean): any;
  pageData: BuilderPageDataType | undefined;
}

const Forwarding = ({
  visible = false,
  toggleVisible = (data: boolean) => {},
  pageData = undefined
}: Props) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (pageData) {
      if (form.getFieldValue('redirectURL') !== pageData.redirectURL) {
        form.setFieldsValue({
          redirectURL: pageData.redirectURL
        });
      }
    }
  }, [pageData?.redirectURL]);

  const handleOk = async (e: any) => {
    if (pageData) {
      await dispatch(
        updateFunnelPageThunk({
          redirectURL: form.getFieldValue('redirectURL'),
          id: pageData.id
        })
      );
      toggleVisible(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    toggleVisible(false);
  };

  return (
    <>
      <Modal
        title="Weiterleitung"
        className="meetovo-funnel-builder-popup"
        visible={visible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Abbrechen
          </Button>,
          <Button key="submit" type="primary" onClick={form.submit}>
            Speichern
          </Button>
        ]}
      >
        <div className="py-3 px-4">
          <Form
            name="meta_data_form"
            layout="vertical"
            initialValues={{ remember: true }}
            form={form}
            onFinish={handleOk}
            scrollToFirstError={true}
          >
            <Form.Item required name={'redirectURL'} rules={[urlFormValidator]}>
              <Input placeholder="https://deine-seite.de/dankesseite" />
            </Form.Item>
            <Table
              columns={urlPlaceholderColumns}
              dataSource={urlPlaceholderData}
              size="small"
              pagination={false}
            />
          </Form>
        </div>
      </Modal>
    </>
  );
};
export default Forwarding;
