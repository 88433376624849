import { createAsyncThunk } from '@reduxjs/toolkit';
import { getImagesWithPagination } from '../hooks/useImagesWithPagination';
import { getImagesTags } from '../hooks/useImageTags';
import { message } from 'antd';
import { GeneralMessages } from '../../config/messages';
import removeImageFromLibrary from '../graphql/removeImageFromLibrary';
import updateImage from '../graphql/updateImage';

export const getImagesWithPaginationThunk = createAsyncThunk(
  'get-images-with-pagination',
  async (input: { page?: number; limit?: number; searchQuery?: string }) => {
    try {
      const parameters = { page: 1, limit: 30, ...input };
      const { data } = await getImagesWithPagination(parameters);
      return { ...data.getImagesWithPagination, ...parameters };
    } catch (error) {
      message.error(GeneralMessages.error);
      throw error;
    }
  }
);

export const getAllImagesTagsThunk = createAsyncThunk('get-all-images-tags', async () => {
  try {
    const { data: imagesTags } = await getImagesTags();
    return imagesTags?.getImageTags;
  } catch (error) {
    message.error(GeneralMessages.error);
    throw error;
  }
});

export const removeImageFromGalleryThunk = createAsyncThunk(
  'remove-image-from-gallery',
  async (data: { id: number }) => {
    try {
      await removeImageFromLibrary(data.id);
      return data;
    } catch (error) {
      message.error(GeneralMessages.error);
      throw error;
    }
  }
);

export const updateImageFromGalleryThunk = createAsyncThunk(
  'update-image-from-gallery',
  async (data: { id: number; name: string; tags: string[] }) => {
    try {
      await updateImage(data);
      return data;
    } catch (error) {
      message.error(GeneralMessages.error);
      throw error;
    }
  }
);
