import { useRef } from 'react';

function useCustomDebounce<T extends (...args: any[]) => void>(func: T, delay: number): T {
  const debounceDelayRef = useRef<number>(delay);
  const timerRef = useRef<any>(null);

  const customDebounce = (...args: any[]) => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      func(...args);
      if (debounceDelayRef.current < 8000) {
        debounceDelayRef.current += 500;
      }
    }, debounceDelayRef.current);
  };

  return customDebounce as T;
}

export default useCustomDebounce;
