import { ColumnsType } from 'antd/es/table/interface';
import React, { useState } from 'react';
import { Table, Tooltip, message } from 'antd';
import moment from 'moment';
import { ClockCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import dateFormats from '../../../config/dateFormats';
import { useMutation, useQuery } from '@apollo/react-hooks';
import DefaultSmallLoader from '../../../SharedUI/components/DefaultSmallLoader';
import { GET_APPLICATION_SCHEDULE_MESSAGES } from '../../../Builder/hooks/useBookingFiles';
import CommunicationDetailModal from './CommunicationDetailModal';
import { GeneralMessages } from '../../../config/messages';
import gql from 'graphql-tag';
import { getBookingStatusLabelByMessageType } from '../../helper/bookingStatuses';
import { JsonParseMessage } from '../../../helper/jsonParse';

interface PropsInterface {
  selectedEvent: any;
}

export const emailProtocolMessages =  {
  TWILIO_SERVICE_FAILED : 'SMS konnte nicht gesendet werden.',
  PAYMENT_IN_PROGRESS : 'Die Zahlung läuft.',
  CONSECUTIVE_PAYMENT_FAILURES : 'Zahlung fehlgeschlagen.',
  SPAM_SUSPECT : 'Verdächtige Aktivität erkannt.',
  PADDLE_SERVICE_FAILED : 'Zahlung fehlgeschlagen.',
  DOMAIN_NOT_EXIST : 'E-Mail konnte nicht gesendet werden.',
  SEND_MAIL_AFTER_SOME_TIME : 'Erneuter Versuch...',
  COULD_NOT_SEND_EMAIL : 'E-Mail konnte nicht gesendet werden.',
  TWILIO_PHONE_VALIDATION_ERROR : 'SMS konnte nicht gesendet werden.'
}

const UPDATE_APPLICATION_MESSAGES = gql`
  mutation updateApplicationMessages($input: UpdateApplicationScheduleInput!) {
    updateApplicationMessages(input: $input)
  }
`;

const getIconDetails = (processName: string) => {
  const isEmailProcess = processName === 'EMAIL' || processName === 'sendEmailWithRetry';
  const iconPath = isEmailProcess
    ? '/dashboard/funnel-builder/message-channel-icon__email.png'
    : '/dashboard/funnel-builder/message-channel-icon__sms.png';
  const label = isEmailProcess ? 'E-Mail' : 'SMS';
  return { iconPath, label };
};

const CommunicationTable = ({ selectedEvent }: PropsInterface) => {
  const [selectedItem, setSelectedItem] = useState<any>({});

  const getMessageColumnReason = (reason: keyof typeof emailProtocolMessages) => {
    return emailProtocolMessages[reason];
  };

  const messageColumns: ColumnsType<{
    channel: string;
    type: string;
    message: string;
    sentDate: Date;
  }> = [
      {
        title: 'Kanal',
        dataIndex: 'processName',
        key: 'processName',
        render: (processName: string) => {
          const { iconPath, label } = getIconDetails(processName);
          return (
            <span>
              {iconPath && (
                <div className="custom-logo-size">
                  <Tooltip title={label}>
                    <span role="img">
                      <img src={iconPath} alt={processName} />
                    </span>
                  </Tooltip>
                </div>
              )}
            </span>
          );
        }
      },
      {
        title: 'Nachricht',
        dataIndex: 'payload',
        width: '200px',
        key: 'payload',
        render: (payload: string, { processName }: any) => {
          const parsedPayload = JSON.parse(payload);
          let displayContent;
          switch (processName) {
            case 'sendEmailWithRetry':
            case 'EMAIL':
              displayContent = parsedPayload?.subject;
              break;
            case 'sendMessageWithRetry':
              displayContent = parsedPayload?.content;
              break;
            case 'SMS':
              displayContent = parsedPayload?.html;
              break;
            default:
              displayContent = payload; // Displays the raw payload if none of the specified process names match
              break;
          }
          return <div className="truncate">{displayContent}</div>;
        }
      },
      {
        title: 'Status',
        dataIndex: 'executionTime',
        key: 'executionTime',
        width: '150px',
        render: (executionTime: string, item: any) => {
          if (!executionTime) {
            return <span>Fehler, neuer Versuch</span>;
          }

          if (executionTime && Number(item?.retryCount) >= 3) {
            return <span>Fehler</span>;
          }
          const isSent = moment(executionTime).isBefore(moment());
          return (
            <span>
              {isSent ? 'Gesendet' : 'Geplant'}
            </span>
          );
        }
      },
      {
        title: 'Typ',
        dataIndex: 'payload',
        key: 'payload',
        width: '100px',
        render: (payload: string, item: any) => {
          const parsedPayload = JSON.parse(payload);
          return (
            <span>
              {getBookingStatusLabelByMessageType(parsedPayload?.protocolData?.type)}
            </span>
          );
        }
      },
      {
        title: 'Datum',
        dataIndex: 'executionTime',
        key: 'executionTime',
        render: executionTime => (
          <span>{executionTime ? moment.utc(executionTime).format(dateFormats?.dateTime) : ''}</span>
        )
      },
      {
        title: 'Versuche',
        dataIndex: 'retryCount',
        key: 'retryCount',
        render: retryCount => <span>{retryCount ? retryCount : 0}</span>
      },
      {
        title: 'Fehler',
        dataIndex: 'reason',
        key: 'reason',
        render: reason => <span>{reason ? getMessageColumnReason(reason) : null}</span>
      }
    ];

  //Use the get message hook here
  const { data, loading, refetch } = useQuery(GET_APPLICATION_SCHEDULE_MESSAGES, {
    variables: {
      input: {
        bookingId: selectedEvent?.event?.id
      }
    }
  });

  const [updateBookingSchedule, {}] = useMutation(UPDATE_APPLICATION_MESSAGES);

  const handleUpdateMessage = (data: {
    processName: string;
    id: number;
    isAlreadyScheduled?: boolean;
    executionTime?: string;
  }): void => {
    //useMutation to cancel message should be called here
    updateBookingSchedule({
      variables: {
        input: {
          id: data?.id,
          isAlreadyScheduled: data?.isAlreadyScheduled,
          processName: data?.processName,
          executionTime: data?.executionTime
        }
      }
    })
      .then(() => {
        message.success(GeneralMessages.success);
        refetch();
      })
      .catch(e => {
        message.error(GeneralMessages.error);
      });
  };

  const onCancelMessage = (item: any) => {
    handleUpdateMessage({
      processName: item?.processName,
      isAlreadyScheduled: true,
      id: item?.id,
      executionTime: item?.executionTime
    });
    setSelectedItem({});
  };

  const onUpdateMessage = (item: any, date: string) => {
    handleUpdateMessage({
      processName: item?.processName,
      id: item?.id,
      isAlreadyScheduled: item?.isAlreadyScheduled,
      executionTime: date
    });
    setSelectedItem({});
  };

  return (
    <div>
      {loading ? (
        <div className="d-flex justify-content-center">
          <DefaultSmallLoader loading={loading} />
        </div>
      ) : (
        <>
          {
            <>
              <Table
                pagination={false}
                columns={messageColumns}
                dataSource={data?.getApplicationMessages}
                scroll={{ x: 800 }}
                onRow={record => {
                  return {
                    onClick: () => {
                      setSelectedItem(record);
                    } 
                  };
                }}
                rowKey={(record: any) => record.id}
              />
              <CommunicationDetailModal
                isOpen={!!selectedItem?.id}
                item={selectedItem}
                handleClose={() => {
                  setSelectedItem({});
                }}
                onCancelMessage={(item: any) => {
                  onCancelMessage(item);
                }}
                onRescheduleMessage={(item: any, date: string) => {
                  onUpdateMessage(item, date);
                }}
              />
            </>
          }
        </>
      )}
    </div>
  );
};

export default CommunicationTable;
