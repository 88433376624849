import React from 'react';
import { CRAFT_ELEMENTS } from '../../../../config/craftElements';
import ChoiceContainerHoc, { CHOICE_TYPES } from '../../common/ChoiceContainerHOC';

export interface Props {
  children?: any;
}

const SingleChoiceComponent = (props: Props) => {
  return <ChoiceContainerHoc {...props} type={CHOICE_TYPES.SINGLE}></ChoiceContainerHoc>;
};

export const SingleChoiceComponentProps = {};

export default SingleChoiceComponent;

SingleChoiceComponent.craft = {
  name: CRAFT_ELEMENTS.SINGLE_CHOICE,
  props: SingleChoiceComponentProps,
  rules: {
    canMoveIn: (selectedNode: any) => {
      return selectedNode[0].data.displayName === CRAFT_ELEMENTS.CHOICE;
    },
    canMoveOut: () => false
  }
};
