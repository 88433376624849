import React, { useEffect } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { selectGlobalSettingsFlags } from '../../../UI/redux/uiSlice';
import { getValueFromKeyValueArrayOfObjects } from '../../../helper/array';
import { selectUserData } from '../../../UI/redux/userSlice';
import { hotjar } from 'react-hotjar';

declare global {
  interface Window {
    mouseFlowInitialized?: boolean;
  }
}

function HotjarWrapper() {
  const globalSettingsFlags = useAppSelector(selectGlobalSettingsFlags);
  const userData = useAppSelector(selectUserData);

  useEffect(() => {
    if (!globalSettingsFlags || !userData || window.mouseFlowInitialized) return;

    const enabledCoachIds = getValueFromKeyValueArrayOfObjects(
      globalSettingsFlags,
      'hotjarEnabledCoachIds'
    );

    if (!enabledCoachIds?.includes(userData.id) && enabledCoachIds !== 'ALL') return;
    window.mouseFlowInitialized = true;

    hotjar.initialize(3481475, 6);
  }, [globalSettingsFlags, userData]);

  useEffect(() => {
    const isAvailableInterval = setInterval(() => {
      if (hotjar.initialized()) {
        hotjar.identify(userData.id, {});
        clearInterval(isAvailableInterval);
      }
    }, 2000);

    return () => {
      clearInterval(isAvailableInterval);
    };
  }, []);

  return null;
}

export default HotjarWrapper;
