import React, { useState } from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { Button, Typography } from 'antd';
import CreateNewPageModal from './CreateNewPageModal';
import { PlusOutlined } from '@ant-design/icons';

const EmptyPageList = () => {
  const [visible, setVisible] = useState(false);

  return (
    <div className="empty-page-list--container">
      <FaRegEdit color="#e0e0e0" size={60} className="mb-3" />
      <Typography.Title level={4}>Keine Seiten vorhanden</Typography.Title>
      <p>Über den Button kannst du Seiten hinzufügen und deinen Funnel gestalten.</p>
      <Button
        className="default-opacity-hover"
        onClick={() => setVisible(true)}
        type="primary"
        shape="round"
        icon={<PlusOutlined />}
      >
        Seite hinzufügen
      </Button>
      <CreateNewPageModal visible={visible} toggleVisible={setVisible} />
    </div>
  );
};

export default EmptyPageList;
