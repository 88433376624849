import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { DesktopOutlined, MobileOutlined } from '@ant-design/icons';

interface IFrameModalProps {
  openModal?: boolean;
  onModalCancel?: () => void;
  domain: string
}

const IFrameModal: React.FC<IFrameModalProps> = ({ openModal, domain, onModalCancel }: IFrameModalProps) => {
  const [isMobileView, setIsMobileView] = useState(false);

  const closeModal = () => {
    onModalCancel && onModalCancel()
    setTimeout(() => {
      setIsMobileView(false);
    }, 1000);
  };

  return (
    <Modal
      destroyOnClose
      visible={openModal}
      onCancel={closeModal}
      footer={[]}
      width={isMobileView ? 414 : 1366}
      centered={true}
      wrapClassName='iframe-modal-wrapper'
      title={
        <div className="view-changer">
          <DesktopOutlined
            onClick={() => setIsMobileView(false)}
            className={`preview-size-icon ${!isMobileView ? 'active' : ''}`}
          />
          <div style={{ width: '20px' }}></div>
          <MobileOutlined
            onClick={() => setIsMobileView(true)}
            className={`preview-size-icon ${isMobileView ? 'active' : ''}`}
          />
        </div>
      }
    >
      <iframe height={window.innerHeight - 55} width={isMobileView ? 414 : 1366} src={domain} />
    </Modal>
  );
};

export default IFrameModal;
