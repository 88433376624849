export function validateEmail(email: string) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
  return re.test(email);
}

export function validateSemicolonSeperatedEmails(str: string | undefined) {
  const invalidEmails = [];
  const validEmails = [];

  if (str && typeof str === 'string') {
    const emails = str.split(';');

    for (var i = 0; i < emails.length; i++) {
      const trimmedEmail = emails[i].trim();
      if (!validateEmail(trimmedEmail) && trimmedEmail) invalidEmails.push(trimmedEmail);
      else if (trimmedEmail) validEmails.push(trimmedEmail);
    }
  }

  return {
    invalidEmails,
    validEmails
  };
}

export const semicolonSeperatedEmailsValidator = ({ getFieldValue }: { getFieldValue: Function }) => ({
  validator(rule: any, value: string) {
    const emailsValidity = validateSemicolonSeperatedEmails(value)
    if (value && emailsValidity.invalidEmails.length) {
      return Promise.reject(
        'Eingabe nicht Valide. Beispiel: abc@beispiel.de;cba@beispiel.de'
      );
    }
    return Promise.resolve();
  }
});