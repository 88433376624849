import gql from 'graphql-tag';

export enum FunnelJobPostStatus {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED'
}

export interface SetFunnelJobPostInput {
  craftState: string;
  validThrough?: Date;
  status?: FunnelJobPostStatus;
}

export const SET_FUNNEL_JOB_POST = gql`
  mutation setFunnelJobPost($input: SetFunnelJobPostInput!) {
    setFunnelJobPost(input: $input) {
      id
      craftState
      status
      platform
    }
  }
`;

export default function setFunnelJobPost(input: SetFunnelJobPostInput) {
  return window.apolloClient.mutate({
    mutation: SET_FUNNEL_JOB_POST,
    variables: { input }
  });
}
