import { Col, Row, Select } from 'antd';
import React from 'react';
import BuilderSettingsTitle from './BuilderSettingsTitle';
import { JOB_ATTRIBUTES_IDENTIFIER } from '../../AdJobBuilder/helper/AdJobHelper';
import { TARGET_GROUP_ELEMENTS } from '../generalSettings/TargetGroup/components/TargetGroupHelper';

interface ChoicePickerProps {
  title: string;
  handleChange: (value: any, identifier: JOB_ATTRIBUTES_IDENTIFIER | TARGET_GROUP_ELEMENTS) => void;
  value: any;
  options: string[] | { label: string; value: string }[];
  identifier: JOB_ATTRIBUTES_IDENTIFIER | TARGET_GROUP_ELEMENTS;
}

export default function ChoicePicker({
  title,
  handleChange,
  value,
  options,
  identifier
}: ChoicePickerProps) {
  return (
    <Row className="builder__settings-sidebar__row">
      <Col span={24}>
        <BuilderSettingsTitle title={title} />
        <Select
          className="w-100"
          onChange={value => {
            handleChange(value, identifier);
          }}
          value={value}
        >
          {options.map(option => (
            <Select.Option value={typeof option === 'object' ? option.value : option}>
              {typeof option === 'object' ? option.label : option}
            </Select.Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
}
