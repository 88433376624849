import React from 'react';
import { copyStringToClipboard } from '../../helper/browserActions';
import { Form, Alert, Input, Button } from 'antd';
import { CopyOutlined, EyeOutlined } from '@ant-design/icons';
import { BookingLinkMessages } from '../../config/messages';

function CopyInput({
  link,
  description,
  label,
  setOpenModal
}: {
  link: string;
  description?: string;
  label?: string;
  setOpenModal?: (value: boolean) => void;
}) {
  if (!link)
    return <Alert message={BookingLinkMessages.noLinkAvailableError} type="error" showIcon />;

  return (
    <Form.Item label={label} name={link} extra={description} className="copy-input">
      <Input
        value={link}
        defaultValue={link}
        disabled
        addonAfter={
          <div
            className="cursor-pointer default-opacity-hover"
            onClick={() => copyStringToClipboard(link)}
          >
            <CopyOutlined />
          </div>
        }
      />
      {setOpenModal && <Button onClick={() => setOpenModal?.(true)}>Vorschau</Button>}
    </Form.Item>
  );
}

export default CopyInput;
