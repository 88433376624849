import React, { useEffect, useRef, useState } from 'react';
import { Button, Popover, message } from 'antd';
import { FunnelMessages, SaveIndicatorMessages } from '../../../config/messages';
import { builderInvalidationClass } from '../../config/builderUi';
import DefaultSmallLoader from '../../../SharedUI/components/DefaultSmallLoader';
import { SaveOutlined } from '@ant-design/icons';
import SaveTemplateModal from './SaveTemplateModal';
import { useNavigate } from 'react-router';
import { useBuilderValidatorErrors, useMainFunnelLoader } from '../../redux/builderSlice';
import { useAdBuilderLoading } from '../../AdBuilder/redux/adBuilderSlice';
import { useDebounce } from '../../helper/sharedFunctions';
import { returnUrlToMyFunnels } from '../../../Funnel/redux/funnelSlice';
import { useMainAgencyOSBuilderLoader } from '../../AgencyOSBuilder/redux/agencyOSBuilderSlice';
import { useIsAgencyOSBuilderPath } from '../../hooks/useIsAgencyOSBuilderPath';
import ManageCurrentlyOpenedFunnels from './ManageCurrentlyOpenedFunnels';
import { useWidgetBuilderLoading } from '../../WidgetsBuilder/redux/widgetBuilderSlice';
import { useIsWidgetsBuilderPath } from '../../hooks/useIsWidgetsBuilderPath';
import {
  BuilderValidationErrorMessages,
  disabledBuilderSaveOnInvalidation
} from '../../container/BuilderValidator';

interface SaveIndicatorBaseProps {
  isLoading?: boolean;
  shouldSave?: boolean;
  isSaved: boolean;
  showDownloadTemplate?: boolean;
  onClickSave(): any;
  onPageOpenedInAnotherTab(): any;
}

const SaveIndicatorBase = ({
  isLoading,
  isSaved,
  showDownloadTemplate,
  onClickSave,
  onPageOpenedInAnotherTab
}: SaveIndicatorBaseProps) => {
  const [shouldSave, setShouldSave] = useState(true);
  const navigate = useNavigate();
  const mainFunnelLoader = useMainFunnelLoader();
  const adBuilderLoader = useAdBuilderLoading();
  const isWidgetBuilderPath = useIsWidgetsBuilderPath();
  const widgetBuilderLoading = useWidgetBuilderLoading();
  const mainAgencyOSBuilderLoader = useMainAgencyOSBuilderLoader();
  const isAgencyOSBuilderPath = useIsAgencyOSBuilderPath();
  const getBuilderValidatorErrors = useBuilderValidatorErrors();
  isLoading = isAgencyOSBuilderPath
    ? useDebounce(isLoading || mainAgencyOSBuilderLoader, 200)
    : isWidgetBuilderPath
    ? useDebounce(isLoading || widgetBuilderLoading, 200)
    : useDebounce(isLoading || mainFunnelLoader || adBuilderLoader, 200);
  const returnUrlToMyFunnelsLink = returnUrlToMyFunnels();

  const builderValidatorErrorsRef = useRef(getBuilderValidatorErrors);

  const showErrorAlert = (event: MouseEvent) => {
    const targetEvents = [
      'INPUT',
      'disable-builder',
      'public-DraftStyleDefault-block',
      'editor',
      'rdw-editor-main'
    ];
    const Invalid = builderValidatorErrorsRef.current.length > 0;
    if (!Invalid) return;
    const errorMessage = BuilderValidationErrorMessages[builderValidatorErrorsRef.current[0]];
    let target = event.target as HTMLElement;
    const isInputField =
      targetEvents.includes(target?.tagName) ||
      targetEvents.some(className => target.classList.contains(className));
    if (!isInputField) {
      message.error(errorMessage);
    }
  };
  useEffect(() => {
    getBuilderValidatorErrors.length > 0
      ? disabledBuilderSaveOnInvalidation(true)
      : disabledBuilderSaveOnInvalidation(false);
    builderValidatorErrorsRef.current = getBuilderValidatorErrors;
  }, [getBuilderValidatorErrors.length]);

  useEffect(() => {
    document.addEventListener('click', showErrorAlert);
    return () => {
      document.removeEventListener('click', showErrorAlert);
    };
  }, []);

  return (
    <>
      <ManageCurrentlyOpenedFunnels
        onPageOpenedInAnotherTab={() => {
          setShouldSave(false);
          onPageOpenedInAnotherTab();
        }}
      />
      <DefaultSmallLoader loading={!!isLoading} />
      {!isLoading && (
        <Popover
          trigger="click"
          content={
            <div className="default-popover__content led__popover-content">
              <p>
                {!shouldSave
                  ? FunnelMessages.twoTabsOpen
                  : isSaved
                  ? SaveIndicatorMessages.popoverAllSavedMessage
                  : SaveIndicatorMessages.popoverUnsavedChanges}
              </p>

              {shouldSave && !isSaved && (
                <Button
                  type="primary"
                  onClick={() => !isLoading && onClickSave()}
                  loading={isLoading}
                >
                  Jetzt speichern
                </Button>
              )}

              {!shouldSave && (
                <Button type="primary" onClick={() => navigate(returnUrlToMyFunnelsLink)}>
                  Zurück zur Übersicht
                </Button>
              )}

              {shouldSave && isSaved && showDownloadTemplate && (
                <SaveTemplateModal>
                  <Button>Download</Button>
                </SaveTemplateModal>
              )}
            </div>
          }
          title={() => (
            <span className="default-popover__title led__popover-title">
              <SaveOutlined /> {SaveIndicatorMessages.popoverTitle}
            </span>
          )}
        >
          {!shouldSave ? (
            <div className={`led led__red ${builderInvalidationClass}`}></div>
          ) : isSaved ? (
            <div className={`led led__green ${builderInvalidationClass}`}></div>
          ) : (
            <div className={`led led__gray ${builderInvalidationClass}`}></div>
          )}
        </Popover>
      )}
    </>
  );
};

export default SaveIndicatorBase;
