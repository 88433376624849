import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';
import { setConfettiMessage } from '../../UI/redux/uiSlice';

interface ProductFruitState {
  props: { [key: string]: string };
  milestones: ProductFruitMilestones;
  reloadCount: number
}

type ProductFruitMilestones = Array<{
  done: boolean;
  message: string;
  trialDays: number;
  pfId: number;
}>;

const INITIAL_STATE: ProductFruitState = {
  props: {},
  milestones: [],
  reloadCount: 0
};

export const updateMilestoneDiff = createAsyncThunk(
  'productFruits/updateMilestoneDiff',
  async (milestones: ProductFruitMilestones, thunkApi) => {
    const state = (thunkApi.getState() as RootState).productFruits;
    state.milestones
      .filter(oldMilestone => {
        const newML = milestones.find(nm => nm.pfId === oldMilestone.pfId);

        return oldMilestone.done === false && newML!.done;
      })
      .forEach(oldMilestone => {
        thunkApi.dispatch(setConfettiMessage(oldMilestone.message));
      });

    return milestones;
  }
);

export const productFruitsSlice = createSlice({
  name: 'productFruits',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: INITIAL_STATE,
  reducers: {
    resetToInitialProductFruitsState: () => {
      return INITIAL_STATE;
    },
    setProductFruitsProps: (state, action: PayloadAction<any>) => {
      state.props = { ...state.props, ...action.payload };
    },
    incrementRelaodIndicator: (state, action: PayloadAction<any>) => {
      state.reloadCount = state.reloadCount + 1
    }
  },
  extraReducers: builder => {
    builder.addCase(updateMilestoneDiff.fulfilled, (state, action) => {
      state.milestones = [...action.payload];
    });
  }
});

export const { resetToInitialProductFruitsState, setProductFruitsProps, incrementRelaodIndicator } = productFruitsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectProductFruitsProps = (state: RootState) => state.productFruits.props;
export const selectReload = (state: RootState) => state.productFruits.reloadCount;
export const selectTotalTrialDays = (state: RootState) => 
  state.productFruits.milestones.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.trialDays;
  }, 0);

export default productFruitsSlice.reducer;
