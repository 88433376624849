import React, { useContext, useEffect, useState } from 'react';
import { Typography, Button, Progress } from 'antd';
import { TourContext } from '../util/TourProvider';

import { LoadingOutlined } from '@ant-design/icons';
import mixpanel from 'mixpanel-browser';
import { mixpanelEvents } from '../../UI/utils/mixpanel';
import sidebar_acedemy_trophy from '../resources/sidebar__acedemy-trophy.png';
import sidebar_collapsed from '../resources/sidebar-collapsed__acedemy-trophy.png';
import { FaChevronRight } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { challengeModulesCompleted, challengeProgressPercent } from '../../UI/redux/uiSlice';

const { Text, Title, Paragraph } = Typography;

declare global {
  interface Window {
    showTourProgressPopUp(): any;
  }
}
interface SidebarTourProgressProps {
  collapsed?: boolean;
}

export const SidebarTourProgress = ({ collapsed }: SidebarTourProgressProps) => {
  let trophyPulseInterval: any;
  const navigate = useNavigate();
  const tourContext = useContext(TourContext);
  const progress = useSelector(challengeProgressPercent);
  const modulesCompleted = useSelector(challengeModulesCompleted);
  const completedTours = tourContext.tours.filter(tour => {
    return tour.isDoneChecker(tourContext.tourInfo);
  }).length;

  window.showTourProgressPopUp = () => handleNavigation();

  const handleTrophyPulse = () => {
    clearInterval(trophyPulseInterval);

    if (completedTours < 3) {
      trophyPulseInterval = setInterval(() => {
        const trophy = document.querySelector('.mini-trophy-wrapper');
        trophy?.classList.add('mini-trophy-pulse');

        setTimeout(() => {
          trophy?.classList.remove('mini-trophy-pulse');
        }, 4000);
      }, 6000);

      return () => clearInterval(trophyPulseInterval);
    }
  };

  useEffect(handleTrophyPulse, [completedTours]);

  const handleNavigation = () => {
    navigate('/academy');
  };

  return (
    <>
      {collapsed ? (
        <div>
          <img
            onClick={handleNavigation}
            src={sidebar_collapsed}
            alt="Trophy"
            className="collapsed-trophy"
          />
        </div>
      ) : (
        <div className="sidebar-container">
          <>
            <img src={sidebar_acedemy_trophy} alt="Trophy" className="expanded-trophy" />
            <Title level={3} className="sidebar-container-heading">
              {modulesCompleted > 0 ? (
                <Progress
                  trailColor="#4C5464"
                  strokeWidth={10}
                  percent={+progress.toFixed(0)}
                  type="circle"
                  width={64}
                  strokeColor="#FFFFFF"
                />
              ) : (
                <>Recruiting Academy</>
              )}
            </Title>
            {modulesCompleted > 0 && modulesCompleted < 6 && (
              <Paragraph className="d-flex sidebar-container-progress-paragraph">
                Dein Fortschritt{' '}
                <span>
                  ({modulesCompleted}/{6})
                </span>
              </Paragraph>
            )}
            {modulesCompleted === 6 && (
              <Paragraph className="d-flex sidebar-container-progress-paragraph">
                Alles geschafft
              </Paragraph>
            )}
            <Paragraph className="sidebar-container-paragraph">
              {modulesCompleted > 0 ? (
                modulesCompleted === 6 ? (
                  <>Du befinest dich auf dem besten Weg zum Top-Recruiter-Award.</>
                ) : (
                  <>Du schlägst dich gut. Es warten noch weitere Insights auf dich!</>
                )
              ) : (
                <>In 6 Video-Modulen zum Top-Recruiter.</>
              )}
            </Paragraph>
            <Button type="link" className="sidebar-container-button" onClick={handleNavigation}>
              Videos ansehen
              <FaChevronRight />
            </Button>
          </>
        </div>
      )}
    </>
  );
};
