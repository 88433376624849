import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectConfettiMessage, setConfettiMessage } from '../redux/uiSlice';

const Confetti = () => {
  const confettiMessage = useAppSelector(selectConfettiMessage);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (confettiMessage !== '') {
      setTimeout(() => {
        dispatch(setConfettiMessage(""));
      }, 5000);
    }
  }, [confettiMessage]);

  if (confettiMessage == '') return <></>;

  return (
    <div className="confetti-wrapper">
      {Array.from(Array(150).keys()).map((r, i) => (
        <div key={i} className={`confetto-${i}`}></div>
      ))}
    </div>
  );
};

export default Confetti;
