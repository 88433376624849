import gql from 'graphql-tag';

export interface Input {
  title: string;
  public: boolean;
  craftState: string;
}

export const CREATE_FUNNEL_TEMPLATE = gql`
  mutation createFunnelTemplate($input: CreateFunnelTemplateInput!) {
    createFunnelTemplate(createFunnelTemplateInput: $input) {
      id
      title
      tags
      image
      public
      craftState
      userId
    }
  }
`;

export default function addFunnelTemplate(input: Input) {
  return window.apolloClient.mutate({
    mutation: CREATE_FUNNEL_TEMPLATE,
    variables: { input }
  });
}
