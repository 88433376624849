import gql from 'graphql-tag';

export interface UpdateAdInput {
  id: number;
  craftState: string;
}

export const UPDATE_FUNNEL_AD = gql`
  mutation updateAd($input: UpdateAdInput!) {
    updateAd(input: $input) {
      id
      craftState
    }
  }
`;

export default function updateAd(input: UpdateAdInput) {
  return window.apolloClient.mutate({
    mutation: UPDATE_FUNNEL_AD,
    variables: { input }
  });
}
