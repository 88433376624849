import { SizeInterface } from "./TextTypeInterface";

export const ImageAvailableOpacity: SizeInterface[] = [
  {
    key: 1,
    value: 0.10
  },
  {
    key: 2,
    value: 0.20
  },
  {
    key: 3,
    value: 0.30
  },
  {
    key: 4,
    value: 0.40
  },
  {
    key: 5,
    value: 0.50
  },
  {
    key: 6,
    value: 0.60
  },
  {
    key: 7,
    value: 0.70
  },
  {
    key: 8,
    value: 0.80
  },
  {
    key: 9,
    value: 0.90
  }
];

export const defaultImageOpacity = ImageAvailableOpacity[0];
