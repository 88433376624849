import React, { useState } from 'react';
import { Typography, Input, Table, Form, message } from 'antd';
import { Editor } from 'react-draft-wysiwyg';
import { customDraftToHtml } from '../../../helper/draftHelper';
import { convertFromRaw, EditorState } from 'draft-js';
import sendApplicantNotification from '../../../Events/graphql/sendApplicantNotification';
import { DeclineApplicantEmailMessages } from '../../../config/messages';
import { filteredTokensAsPerEmailType } from '../../../Builder/helper/filterTokens';
import { applicantNotificationTypeEnum, columns } from '../../../Builder/components/emailTemplate/utils';
import { NotificationChannelType } from '../../../Events/components/Event/ApplicantNotificationModal';
import useGetEmailTemplate from '../../../hooks/useGetEmailTemplate';

const EmailTemplateBlock = ({
  eventId,
  applicantNotificationType,
  setIsSending,
  setVisibility,
  form
}: {
  eventId: number;
  setIsSending: (newState: boolean) => void;
  setVisibility: (newState: boolean) => void;
  form: any;
  applicantNotificationType: applicantNotificationTypeEnum;
}) => {
  const [isEditorEmpty, setIsEditorEmpty] = useState(false);
  const { data: templateData, loading: templateLoading } = useGetEmailTemplate(
    eventId,
    applicantNotificationType
  );
  const handleSubmit: (values: any) => void = values => {
    const emailData = {
      bookingId: eventId,
      subject: values.subject,
      html: customDraftToHtml(values.body.getCurrentContent()),
      applicantNotificationType
    };

    setIsSending(true);
    sendApplicantNotification(emailData)
      .then((res: any) => {
        if (res.data?.sendApplicantNotification?.status) {
          message.success(DeclineApplicantEmailMessages.success);
          setVisibility(false);
        }
      })
      .catch((error: any) => {
        message.error(DeclineApplicantEmailMessages.error);
      })
      .finally(() => setIsSending(false));
  };

  const filteredTokens = filteredTokensAsPerEmailType(applicantNotificationType);
  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      initialValues={{
        body: EditorState.createEmpty(),
        subject: ''
      }}
    >
      <div className={`template-form disable-layer`}>
        <div className="mb-3">
          <Typography.Text strong>Betreff</Typography.Text>
          <Form.Item
            name="subject"
            rules={[{ required: true, message: 'Betreff ist erforderlich.' }]}
          >
            <Input
              className="mt-2"
              placeholder="Bestätigung"
              {...(templateData?.getApplicantNotificationTemplate?.type ===
                NotificationChannelType.MESSAGE && { disabled: true })}
            />
          </Form.Item>
        </div>

        <Form.Item
          name="body"
          rules={[
            {
              required: true,
              validator: () => {
                if (isEditorEmpty) {
                  return Promise.reject(new Error('E-Mail-Text ist erforderlich.'));
                }
                return Promise.resolve();
              }
            }
          ]}
          getValueFromEvent={e => {
            return EditorState.createWithContent(convertFromRaw(e));
          }}
        >
          <Editor
            defaultEditorState={form.getFieldValue('body')}
            toolbarClassName="decline-applicant__toolbar"
            toolbar={{
              link: {
                popupClassName: 'email-link-popup',
                link: { className: 'email-link-btn' }
              },
              options: ['inline', 'emoji', 'link'],
              inline: { inDropdown: false, options: ['bold', 'italic', 'underline'] }
            }}
            editorClassName="decline-applicant__editor"
            onEditorStateChange={editorState => {
              if (
                editorState
                  ?.getCurrentContent()
                  .getPlainText()
                  .trim() !== ''
              ) {
                setIsEditorEmpty(false);
              } else {
                setIsEditorEmpty(true);
              }
            }}
            stripPastedStyles
          />
        </Form.Item>
        <Table pagination={false} columns={columns} dataSource={filteredTokens} size="middle" />
      </div>
    </Form>
  );
};

export default EmailTemplateBlock;
