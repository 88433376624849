import { Alert, Col, Row } from 'antd';
import React from 'react';
import { AiTextMessages } from '../../../../config/messages';

const DuplicateAiSettingsAlert = () => {
  return (
    <Row className="builder__settings-sidebar__row">
      <Col span={24}>
        <Alert message={AiTextMessages.funnelDuplicated} type="info" showIcon />
      </Col>
    </Row>
  );
};

export default DuplicateAiSettingsAlert;
