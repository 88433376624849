import React, { useState } from 'react';
import { Switch, Slider, Row, Col } from 'antd';

interface SpecialClosurePropsInterface {
  onChange(value: any): any;
  maxBookingsPerDay?: number;
  slotBufferInMinutes?: number;
}

interface StateInterface {
  maxBookingsPerDayEnabled: boolean;
  slotBufferInMinutesEnabled: boolean;
}

function ScarcitySettings({
  onChange,
  maxBookingsPerDay,
  slotBufferInMinutes
}: SpecialClosurePropsInterface) {
  const [state, setState] = useState<StateInterface>({
    maxBookingsPerDayEnabled: maxBookingsPerDay ? maxBookingsPerDay > 0 : false,
    slotBufferInMinutesEnabled: slotBufferInMinutes ? slotBufferInMinutes > 0 : false
  });

  const { maxBookingsPerDayEnabled, slotBufferInMinutesEnabled } = state;

  return (
    <div className="scarcity-settings">
      <Row className="builder__settings-sidebar__row">
        <Col span={24}>
          <div className="d-flex justify-content-between align-items-center">
            <label className="switch-right">
              Maximale Termine am Tag {maxBookingsPerDay ? `(${maxBookingsPerDay})` : null}
            </label>
            <Switch
              size="small"
              checked={maxBookingsPerDayEnabled}
              className="ms-2"
              onChange={value => {
                setState({ ...state, maxBookingsPerDayEnabled: value });
                if (!value) onChange({ maxBookingsPerDay: 0 });
                else onChange({ maxBookingsPerDay: 5 });
              }}
            />
          </div>
          {maxBookingsPerDayEnabled && (
            <Slider
              min={1}
              max={35}
              value={maxBookingsPerDay}
              onChange={(value: any) => onChange({ maxBookingsPerDay: value })}
            />
          )}
        </Col>
      </Row>
      <Row className="builder__settings-sidebar__row">
        <Col span={24}>
          <div className="d-flex justify-content-between align-items-center">
            <label className="switch-right">
              Puffer am Ende von Terminen{' '}
              {slotBufferInMinutes ? `(${slotBufferInMinutes} Minuten)` : null}
            </label>
            <Switch
              size="small"
              checked={slotBufferInMinutesEnabled}
              className="ms-2"
              onChange={value => {
                setState({ ...state, slotBufferInMinutesEnabled: value });
                if (!value) onChange({ slotBufferInMinutes: 0 });
                else onChange({ slotBufferInMinutes: 15 });
              }}
            />
          </div>
          {slotBufferInMinutesEnabled && (
            <Slider
              min={1}
              max={180}
              value={slotBufferInMinutes}
              onChange={(value: any) => onChange({ slotBufferInMinutes: value })}
            />
          )}
        </Col>
      </Row>
    </div>
  );
}

export default ScarcitySettings;
