import { columnsOneByTwoWidth, columnsWidths } from '../grid/ColumnsComposition';
import { SliderValue } from 'antd/lib/slider';
import { containerPaddingsWithSliderValue } from './config';
import { ContainerPaddingClasses } from './interfaces';

export const getPaddingClassBySliderValue = (
  value: SliderValue,
  suffix: 'top' | 'bottom'
): string => {
  const match = containerPaddingsWithSliderValue.find(({ sliderValue }) => value === sliderValue);

  if (!match) return '';

  return match.className + '-' + suffix;
};

export const getSliderValueByPaddingClass = (
  _className: ContainerPaddingClasses,
  suffix: 'top' | 'bottom'
): number => {
  const match = containerPaddingsWithSliderValue.find(
    ({ className }) => className + '-' + suffix === _className
  );

  if (!match) return 0;

  return match.sliderValue;
};

export const getColumnProps = (key: number, index: number) => {
  if (key === 5 && index === 1) {
    return columnsOneByTwoWidth[0];
  } else if (key === 6 && index === 0) {
    return columnsOneByTwoWidth[1];
  } else {
    return columnsWidths[key - 1];
  }
};
export enum TEMPLATE_KEYS {
  ICON_ON_LEFT = 'iconOnLeft',
  ICON_ON_RIGHT = 'iconOnRight',
  ICON_ON_TOP = 'iconOnTop',
  ICON_ON_CENTER = 'iconOnCenter'
}

export const getChoiceTemplateClassName = (templateKey?: TEMPLATE_KEYS) => {
  switch (templateKey) {
    case TEMPLATE_KEYS.ICON_ON_RIGHT:
      return 'choice-icon-on-right';
    case TEMPLATE_KEYS.ICON_ON_CENTER:
      return 'choice-icon-on-center';
    case TEMPLATE_KEYS.ICON_ON_LEFT:
      return 'choice-icon-on-left';
    case TEMPLATE_KEYS.ICON_ON_TOP:
      return 'choice-icon-on-top';
    default:
      return 'choice-icon-on-right';
  }
};

export const doesPageExist = (actionLogic: number, pages: any[]) => {
  const foundPage = pages.find(page => page.id === actionLogic);
  return Boolean(foundPage);
};

export const checkType = (value: string, type: any) => {
  return typeof value === type;
};
