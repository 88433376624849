import { LeadQualifierInterface } from '../../Events/container/Events';
import { PageHitDataInterface } from '../components/PageHitComponent';
import { checkIfStepIsCraftPage } from './setpNameHelpers';


export interface WelcomePageDataInterfaceSimple {
    id: number;
    serviceName: string;
  }

export const readableStepNames: { [key: string]: {} } = {
  calendar: 'Kalender',
  'submit-form': 'Formular',
  Konversion: 'Konversion',
  'thank-you-page': 'Dankesseite'
};

export const getReadableStepNameByPhd = ({
  phd,
  welcomePageData,
  leadQualifierData,
  funnelPages,
  maxLength
}: {
  phd: PageHitDataInterface;
  welcomePageData:  WelcomePageDataInterfaceSimple[];
  leadQualifierData:  LeadQualifierInterface[];
  maxLength: number;
  funnelPages: { id: number; name: string }[];
}) => {
  let readableStepName = readableStepNames[phd.stepName] + '' || 'Nicht verfügbar';

  if (phd.stepName.includes('pre-page')) {
    const prePageId = parseInt(phd.stepName.replace('pre-page_', ''));
    const currentWelcomePage = welcomePageData.find(({ id }) => id === prePageId);

    readableStepName = currentWelcomePage?.serviceName || 'Pre-Page ' + (phd.stepIndex + 1);
  }

  if (phd.stepName.includes('leadQualifier')) {
    const leadQualifierId = parseInt(phd.stepName.replace('leadQualifier_', ''));
    const currentLeadQualifier = leadQualifierData.find(({ id }) => id === leadQualifierId);

    readableStepName = currentLeadQualifier?.question || phd.stepName;
  }
  if (checkIfStepIsCraftPage(phd.stepName)) {
    const craftPageId = parseInt(phd.stepName.replace('craft-page_', ''));
    const currentFunnelPage = funnelPages.find(({ id }) => id === craftPageId);
    const currentLeadQualifier = leadQualifierData.find(({ id }) => id === phd.leadQualifierId);
    readableStepName = currentLeadQualifier?.question || currentFunnelPage?.name || 'Gelöscht';
  }

  if (!readableStepName) {
    readableStepName = phd.stepName;
  }

  if (readableStepName.length > maxLength) {
    // @ts-ignore
    readableStepName = readableStepName.slice(0, maxLength) + '...';
  }

  return readableStepName;
};
