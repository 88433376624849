import { SizeInterface } from '../config/builderUi';

export const ButtonAvailableBorderRadius: SizeInterface[] = [
  {
    key: 1,
    value: 'Eckig',
    pixelValue: '0px'
  },
  {
    key: 2,
    value: 'Leicht gerundet',
    pixelValue: '10px'
  },
  {
    key: 3,
    value: 'Rund',
    pixelValue: '30px'
  }
];

export const defaultButtonRadius = ButtonAvailableBorderRadius[2];
