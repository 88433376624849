import React, { useEffect, useRef } from 'react';
import { ActionsController } from '../sharedUI/ActionsController';
import { FileDrop } from 'react-file-drop';
import { Alert, Card, Col, Form, Row, Select, Switch } from 'antd';
import { Element, useEditor, useNode } from '@craftjs/core';
import BuilderSettingsTitle from '../sharedUI/BuilderSettingsTitle';
import { CRAFT_ELEMENTS, CRAFT_ELEMENTS_LABEL } from '../../config/craftElements';

import {
  useBuilderGeneralSettings,
  useBuilderSelectedPageId,
  useThankYouPage
} from '../../redux/builderSlice';
import {
  IconProvider as Icon
  // @ts-ignore
} from 'meetovo-frontend-booking';
import BuilderSettingsTogglePro from '../sharedUI/BuilderSettingsTogglePro';
import { CraftElementBaseProps } from '../../helper/craftJs';
import { ButtonComponent } from './BuilderButtonComponent/ButtonComponent';
import { getRandomId } from '../../helper/craftJs';
import { useCurrentlyEditingBuilderTheme } from '../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';
import { FunnelThemeType } from '../../interfaces/builderSliceTypes';

interface Props extends CraftElementBaseProps {
  buttonText?: string;
  accept: string[];
  innerText?: string;
  isRequired?: boolean;
  multiple?: boolean;
  copyNotReq?: boolean;
  children?: React.ReactNode;
}

const UploadFileComponent = ({ buttonText, innerText, accept, isRequired, children }: Props) => {
  const { language } = useBuilderGeneralSettings();
  const fileInputRef = useRef(null);
  const theme = useCurrentlyEditingBuilderTheme() as FunnelThemeType;
  const { nodeId, currentNode } = useNode(node => {
    return {
      currentNode: node,
      nodeId: node.id
    };
  });
  const { query, actions } = useEditor();

  useEffect(() => {
    if (!children && currentNode.data.nodes.length === 0) {
      const nodeTree = query
        .parseReactElement(
          <Element
            customClasses="mt-3"
            id={getRandomId(10)}
            title={buttonText}
            color={theme.accentColorContrast}
            backgroundColor={theme.accentColor}
            is={ButtonComponent}
            onlySettingsReq={true}
            settings={{ shouldShowActionSelector: false }}
          ></Element>
        )
        .toNodeTree();
      actions.history.ignore().addNodeTree(nodeTree, nodeId);
    }
  }, []);

  useEffect(() => {
    if (!query.node(nodeId).get()) return;

    const labels = accept.map(mimeType => {
      const type = options.find(t => t.value === mimeType);
      return type ? type.label : 'unknown';
    });
    actions.history.ignore().setProp(nodeId, state => (state.innerText = labels.join(', ')));
  }, [accept]);

  const onFileInputChange = (event: any) => {
    const { files } = event.target;
    // do something with your files...
  };

  const handleSetChoiceTitle = (e: any) => {
    const newValue = e.target.value.replace(/<\/?[^>]+(>|$)/g, '');
    actions.history.throttle().setProp(nodeId, (props: any) => (props.buttonText = newValue));
  };

  const uploadMessage = (language: string): string =>
    language === 'EN' ? 'Click here to upload a file' : 'Hier klicken und eine Datei hochladen';

  const skipButtonText = (language: string): string =>
    language === 'EN' ? 'Skip' : 'Überspringen';

  const { id } = useThankYouPage() || {};
  const selectedPage = useBuilderSelectedPageId();
  const isThankyouPage = id === selectedPage;

  return (
    <ActionsController
      className="upload-file__wrapper"
      label={CRAFT_ELEMENTS_LABEL[currentNode.data.displayName]}
    >
      <div className="max-width__700px-centered d-flex flex-column align-items-center">
        <FileDrop>
          <div className="upload-container" style={{ borderColor: theme.accentColor }}>
            <div
              className="uploader"
              style={{ borderColor: theme.accentColor, color: theme.textColor }}
            >
              <Icon
                name={'FaCloudUploadAlt'}
                color={theme.accentColor}
                style={{ fontSize: `3em`, color: theme.accentColor }}
              />
              <span>{uploadMessage(language || '')}</span>
              <span>max. 5 MB, {innerText}</span>
            </div>
          </div>
          <input
            disabled
            onChange={onFileInputChange}
            ref={fileInputRef}
            type="file"
            accept={accept.join(', ')}
            className="d-none"
            max={5242880}
          />
        </FileDrop>
        <div>{children}</div>
        {!isRequired && !isThankyouPage && (
          <p className="mb-2" style={{ color: theme.accentColor }}>
            {skipButtonText(language || '')}
          </p>
        )}
        {isThankyouPage && (
          <Alert
            type="warning"
            showIcon
            message={
              'Aufgrund des sofortigen Versands von E-Mails nach der Bewerbung können Dateien, die auf der Dankesseite hochgeladen werden, nicht als Links in E-Mails eingefügt werden. Sie werden nur im Dashboard angezeigt. Wenn du die Datei als Link in der E-Mail einfügen möchtest, füge das Upload-Element auf einer anderen Seite hinzu.'
            }
          />
        )}
      </div>
    </ActionsController>
  );
};

const options = [
  { label: '.pdf', value: 'application/pdf' },
  { label: '.ppt', value: 'application/vnd.ms-powerpoint' },
  {
    label: '.pptx',
    value: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  },
  { label: '.xls', value: 'application/vnd.ms-excel' },
  { label: '.xlsx', value: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
  { label: '.doc', value: 'application/msword' },
  { label: '.zip', value: 'application/zip' },
  {
    label: '.docx',
    value: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  },
  { label: '.ods', value: 'application/vnd.oasis.opendocument.spreadsheet' },
  { label: '.odt', value: 'application/vnd.oasis.opendocument.text' },
  { label: '.png', value: 'image/png' },
  { label: '.jpg', value: 'image/jpeg' },
  { label: '.gif', value: 'image/gif' },
  { label: '.mov', value: 'video/quicktime' },
  { label: '.mp4', value: 'video/mp4' },
  { label: '.mp3', value: 'audio/mp3' }
];

export const UploadFileSettings = () => {
  const [form] = Form.useForm();
  const {
    actions: { setProp },
    props
  } = useNode(node => ({
    props: node.data.props
  }));

  const handleChange = (e: any) => {
    setProp((state: any) => (state.accept = e));
  };

  const { id } = useThankYouPage() || {};
  const selectedPage = useBuilderSelectedPageId();
  const isThankyouPage = id === selectedPage;

  return (
    <Col span={24} className="builder__settings-sidebar__container">
      <Card className="settings-card" title="Datei hochladen" bordered={false}>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BuilderSettingsTitle title="Dateityp" />
            <Form
              form={form}
              initialValues={{ url: props.videoSrc }}
              onFinish={values => {
                setProp((props: any) => (props.videoSrc = values.url));
              }}
              autoComplete="off"
            >
              <Form.Item
                className="remove-margin-bottom"
                name="fileType"
                rules={[
                  {
                    required: true,
                    message: 'Dateityp ist erforderlich'
                  }
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  filterOption={(inputValue, option) =>
                    !!option?.label?.toString().includes(inputValue)
                  }
                  style={{ width: '100%' }}
                  placeholder="Wähle erlaubte Dateitypen..."
                  defaultValue={props.accept || []}
                  onChange={handleChange}
                  options={options}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
      <Row className="builder__settings-sidebar__row">
        {!isThankyouPage && (
          <Col span={24}>
            <BuilderSettingsTogglePro
              title="Erforderlich"
              style={{
                borderBottom: 0
              }}
              rightContent={
                <Switch
                  size="small"
                  checked={props.isRequired}
                  onChange={checked => setProp((state: any) => (state.isRequired = checked))}
                />
              }
            />
          </Col>
        )}

        <Col span={24}>
          <BuilderSettingsTogglePro
            title="Auswahl mehrerer Dateien zulassen"
            rightContent={
              <Switch
                size="small"
                checked={props.multiple}
                onChange={checked => setProp((state: any) => (state.multiple = checked))}
              />
            }
          />
        </Col>
      </Row>
    </Col>
  );
};

export const fileInputAcceptType = ['image/png'];
const UploadFileComponentProps = {
  accept: fileInputAcceptType,
  isFirstRender: true,
  buttonText: 'Datei absenden',
  copyNotReq: true
};

UploadFileComponent.craft = {
  name: CRAFT_ELEMENTS.UPLOAD_FILE,
  props: UploadFileComponentProps,
  rules: {
    canMoveIn: () => {
      return false;
    }
  },
  related: {
    settings: UploadFileSettings
  }
};

export default UploadFileComponent;
