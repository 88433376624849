import React from 'react';
import { MeetovoProductFruitsInfo } from './TourConfig';

export const TourContext = React.createContext({
  tours: new Array<MeetovoProductFruitsInfo>(),
  shouldShowDays: false,
  shouldShowSidebar: false,
  loading: true,
  tourInfo: {
    bookingsCount: 0,
    funnelCount: 0,
    domainCount: 0,
    trackingCount: 0,
    thirdPartyTrackingSettingsCount: 0
  },
  reloadTours: () => {}
});
