import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Modal,
  Row,
  Space,
  Typography,
  Steps,
  Alert,
  Divider,
  message
} from 'antd';
import { builderInvalidationClass } from '../../../config/builderUi';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { saveJobBuilderStateThunk, unPublishJobThunk } from '../../redux/thunk';
import { FunnelJobPostStatus } from '../../../graphql/setFunnelJobPost';
import {
  useJob,
  useSaveJobBuilderLoading,
  useUnPublishJobBuilderLoading
} from '../../redux/jobBuilderSlice';
import moment from 'moment';
import dateFormats from '../../../../config/dateFormats';
import { dateIsFuture, defaultDateValidation } from '../../../../UI/utils/formValidation';
import { SendOutlined, CheckCircleOutlined, UndoOutlined } from '@ant-design/icons';
import { selectAddOnPermission } from '../../../../Marketplace/redux/slice';
import { ADD_ON_KEY } from '../../../../Marketplace/config/types';
import { GoogleJobsMessages } from '../../../../config/messages';
import { JobNotificationType } from '../../../graphql/setFunnelJobPostForIndexing';
import { useSubscriptionInfo } from '../../../../UI/redux/userSlice';

const PublishAdModal = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const loading = useSaveJobBuilderLoading();
  const unPublishJobLoading = useUnPublishJobBuilderLoading();
  const [form] = Form.useForm();
  const job = useJob();
  const isPublished = job.status === FunnelJobPostStatus.PUBLISHED;
  const hasPermission = useAppSelector(state =>
    selectAddOnPermission(state, ADD_ON_KEY.GOOGLE_JOBS)
  );
  const subscriptionInfo = useSubscriptionInfo();
  const { remainingGoogleJobs, maxGoogleJobs } = subscriptionInfo || {};

  useEffect(() => {
    if (open && job.validThrough) {
      form.setFieldsValue({
        validThrough: moment.utc(job.validThrough)
      });
    }
  }, [open, job.validThrough]);

  const handleOpen = async () => {
    setOpen(true);
  };

  const submitJob = async (data: any) => {
    if (!hasPermission) return message.info(GoogleJobsMessages.noAddOnPermission);
    if (remainingGoogleJobs < 1)
      return message.info(GoogleJobsMessages.noRemainingContingent(maxGoogleJobs));

    await dispatch(
      saveJobBuilderStateThunk({
        data: { status: FunnelJobPostStatus.PUBLISHED, validThrough: data.validThrough.toDate() }
      })
    );
    setOpen(false);
  };

  const unPublishJob = async () => {
    await dispatch(
      unPublishJobThunk({
        data: { type: JobNotificationType.URL_DELETED, status: FunnelJobPostStatus.UNPUBLISHED }
      })
    );
    setOpen(false);
  };

  return (
    <>
      <Button
        icon={isPublished ? <CheckCircleOutlined /> : <SendOutlined />}
        type="primary"
        onClick={handleOpen}
        shape="round"
        className={
          isPublished ? `button-green ${builderInvalidationClass}` : builderInvalidationClass
        }
      >
        {isPublished ? 'Veröffentlicht' : 'Veröffentlichen'}
      </Button>
      <Modal
        className="publish-modal"
        footer={null}
        centered
        visible={open}
        onCancel={() => setOpen(false)}
        width="800px"
      >
        <div className="max-width__600px-centered">
          <div className="text-center mb-4">
            <img
              src={process.env.PUBLIC_URL + '/google-jobs/meetovo-to-google.jpg'}
              className="max-width__250px-centered"
            />
          </div>
          <div className="text-center">
            <Typography.Title level={2}>
              Dein Funnel jetzt auf Google Jobs veröffentlichen!
            </Typography.Title>
            <Typography.Paragraph>
              Erreiche qualifizierte Bewerber direkt über Google Jobs. Mit nur wenigen Klicks ist
              dein Jobangebot online und sichtbar für tausende von Jobsuchenden. Starte jetzt und
              finde die idealen Kandidaten für dein Team.
            </Typography.Paragraph>
          </div>
          <Divider />
          <div>
            <Steps
              className="steps-full-width-content"
              direction="vertical"
              progressDot
              current={10}
            >
              <Steps.Step
                title="1. Anzeige fertigstellen"
                description="Gib alle relevanten Informationen zu deinem Jobangebot ein."
              />
              <Steps.Step
                title="2. Veröffentlichung"
                description="Nach der Einreichung wird dein Jobangebot zur Überprüfung gesendet."
              />
              <Steps.Step
                title="3. Indexierung"
                description="Sobald dein Angebot genehmigt ist, beginnt Google mit der Indexierung, um es Jobsuchenden zugänglich zu machen."
              />
              <Steps.Step
                title="4. Sichtbarkeit"
                description="Dein Jobangebot erscheint auf Google Jobs, abhängig von der Relevanz und dem Matching mit den Suchanfragen der Bewerber."
              />
            </Steps>
          </div>
          <Alert
            showIcon
            type="info"
            className="remove-margin"
            message="Hinweis zur Sichtbarkeit"
            description="Die Platzierung deines Jobs hängt vom Google-Algorithmus ab, der Angebote nach Relevanz und Qualität auswählt. Präzise Jobdetails und Geduld verbessern deine Erfolgschancen."
          ></Alert>
          <Divider />
          <div className="text-center">
            {!isPublished ? (
              <Form form={form} layout="vertical" onFinish={submitJob}>
                <Form.Item
                  name={'validThrough'}
                  label="Ablaufdatum der Anzeige"
                  rules={[...defaultDateValidation, ...dateIsFuture]}
                >
                  <DatePicker
                    data-testid="datePickerInput"
                    className="w-100"
                    placeholder="Datum"
                    format={dateFormats.dateTime}
                    allowClear={false}
                    disabledDate={current => {
                      return current && current < moment().startOf('day');
                    }}
                  />
                </Form.Item>
                <Button
                  loading={loading}
                  className="meetovo-style"
                  type="primary"
                  shape="round"
                  htmlType="submit"
                  icon={<SendOutlined />}
                >
                  Veröffentlichen
                </Button>
              </Form>
            ) : (
              <Button
                loading={unPublishJobLoading}
                className="meetovo-style"
                danger
                type="primary"
                shape="round"
                onClick={unPublishJob}
                icon={<UndoOutlined />}
              >
                Veröffentlichung aufheben
              </Button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PublishAdModal;
