import { Col, Row } from 'antd';
import React from 'react';

export default () => (
  <Row className="builder__settings-sidebar__row m-0">
    <Col span={24}>
      <>
        <p className="px-4 my-3">
          Diese Daten werden im Hintergrund für dich gespeichert und werden bei deinen neuen
          Text-Befehlen stets berücksichtigt, um passgenaue Texte ermöglichen zu können
        </p>
        <div className="px-4 mt-2 mb-4">
          <img
            alt=""
            src="/dashboard/funnel-builder/job-attributes-mockup.jpg"
            className="decent-border w-100"
          />
        </div>
      </>
    </Col>
  </Row>
);
