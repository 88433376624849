import React from 'react';
import { Alert } from 'antd';

export const DefaultPixelText = (
  <React.Fragment>
    <p>
      <strong>1. PageView = Funnel aufgerufen</strong>
      <br />
      "PageView" stellt die Basis des von Facebook-Pixel dar und wird immer ausgelöst. „PageView“
      zeigt an, dass eine bestimmte Seite aufgerufen wurde. Beispielsweise die allererste Seite –
      also die Willkommensseite – deines Funnels.
    </p>

    <p>
      <strong>2. ViewContent = Erster Schritt</strong>
      <br />
      "ViewContent" zählt zu den Standard-Events und erkennt, dass eine für dich relevante Seite
      aufgerufen wurde. Im Falle deines Funnels der Aufruf deines Formulars (die erste
      Seite). Also die aktive Entscheidung des Interessenten auf „Start“ zu klicken und mit dem
      Ausfüllen zu beginnen
    </p>

    <p>
      <strong>3. AddToCart = Termin ausgewählt</strong>
      <br />
      „AddToCart“ zählt zu den Standard-Events und erkennt, dass etwas in den Einkaufswagen gelegt
      wurde. Im Falle deines Funnels eben die Auswahl eines Datums und einer bestimmten
      Uhrzeit, die in den „Einkaufswagen“ gelegt wurde.
    </p>

    <p>
      <strong>4. Schedule = Terminierung</strong>
      <br />
      „Schedule“ zählt zu den neuen Standard-Events und erkennt, dass die Buchung eines Termins
      stattgefunden hat. Er ist mit den älteren Standard-Events „CompleteRegistration“ und „Lead“
      gleichzusetzen, nur gibt er Facebook bessere Erkenntnisse, da es sich um eine
      Terminvereinbarung handelt und nicht um eine „normale“ Conversion.
    </p>
  </React.Fragment>
);

interface CustomPixelTextProps {
  viewFunnel?: string;
  firstStep?: string;
  chooseAppointment?: string;
  schedule?: string;
  viewVideo?: string;
}

export const CustomPixelText = ({
  viewFunnel,
  firstStep,
  chooseAppointment,
  schedule,
  viewVideo
}: CustomPixelTextProps) => (
  <React.Fragment>
    {viewFunnel && (
      <p>
        <strong>{viewFunnel} = Funnel aufgerufen</strong>
        <br />
        {viewFunnel} wird anstelle von “PageView” eingesetzt, wenn dieser Pixel bereits verwendet
        wird. „{viewFunnel}“ zeigt an, dass die Willkommensseite deines Funnels aufgerufen wurde. Es
        ist ein Custom-Event mit selbiger Funktionsweise von „PageView“, um falsches Tracking bei
        Doppelverwendung zu vermeiden.
      </p>
    )}

    {viewVideo && (
      <p>
        <strong>{viewVideo} = Video abgespielt</strong>
        <br />„{viewVideo}“ zeigt an, dass dein Video auf der Welcome-Page aufgerufen wurde. Das
        Event wird gefeuert, sobald das Video mindestens 30 Sekunden angesehen wurde.
      </p>
    )}

    {firstStep && (
      <p>
        <strong>{firstStep} = Erster Schritt</strong>
        <br />„{firstStep}“ wird anstelle von „ViewContent“ eingesetzt, wenn dieser Pixel bereits
        verwendet wird. „{firstStep}“ zeigt an, dass das Formular aufgerufen wurde. Also die aktive
        Entscheidung des Interessenten auf „Start“ zu klicken und mit dem Ausfüllen zu beginnen. Es
        ist ein Custom-Event mit selbiger Funktionsweise von „ViewContent“, um falsches Tracking bei
        Doppelverwendung zu vermeiden.
      </p>
    )}

    {chooseAppointment && (
      <p>
        <strong>{chooseAppointment} = Termin ausgewählt</strong>
        <br />
        {chooseAppointment} wird anstelle von „AddToCart“ eingesetzt, wenn dieser Pixel bereits
        verwendet wird. „{chooseAppointment}“ erkennt, dass ein Datum und eine bestimmte Uhrzeit in
        den „Einkaufswagen“ gelegt wurde. Es ist ein Custom-Event mit selbiger Funktionsweise von
        „AddToCart“, um falsches Tracking bei Doppelverwendung zu vermeiden.
      </p>
    )}

    {schedule && (
      <p>
        <strong>{schedule} = Terminierung</strong>
        <br />„{schedule}“ zählt zu den neuen Standard-Events und erkennt, dass die Anfrage eines
        Termins stattgefunden hat. Er ist mit den älteren Standard-Events „CompleteRegistration“ und
        „Lead“ gleichzusetzen, nur gibt er Facebook bessere Erkenntnisse, da es sich um eine
        Terminvereinbarung handelt und nicht um eine „normale“ Conversion.
      </p>
    )}

    <p>
      <strong>Wichtig:</strong> Verwendest du bereits die drei Standard-Events „PageView“,
      „ViewContent“ oder „AddToCart“, dann kommt dein Tracking nicht durcheinander. Somit machen wir
      es dir möglich, dass du Pixel-Events auch außerhalb von Meetovo verwenden und ohne Probleme an
      deine bestehende Landingpage anbinden kannst. Bei Unklarheiten melde dich gerne bei uns.
    </p>
  </React.Fragment>
);

export const ZapierText = (
  <React.Fragment>
    <p>
      Verbinde MEETOVO mit über 2.000 Apps. Erstelle mit wenigen Klicks deinen eigenen
      automatisierten Prozess. Das geht vom CRM bis hin zu E-Mail-Marketing-Tools. Hier mal ein paar
      Beispiele: HubSpot, ActiveCampaign, Mailchimp, Slack, Trello und viele mehr.
    </p>
    <p>
      Je nachdem welches Paket du bei Zapier hast, dauert es zwischen 1-15 Min. bis eine neue
      Anfrage verarbeitet wird.
    </p>
    <ol>
      <li>Erstelle einen neuen Zap</li>
      <li>Tippe MEETOVO in die Suche ein</li>
      <li>Fülle das Formular aus</li>
      <li>Klicke auf "Sign in to MEETOVO"</li>
      <li>Gib deinen Access token ein</li>
      <li>Binde eine App deiner Wahl an</li>
    </ol>
    <Alert
      showIcon
      type="warning"
      message={
        <React.Fragment>
          <b>Wichtig:</b> Behandele deinen Access token vertraulich. Er ermöglicht den Zugriff auf
          all deine Bewerbungen.
        </React.Fragment>
      }
    />
  </React.Fragment>
);

export const IntegromatText = (
  <React.Fragment>
    <p>
      Verbinde MEETOVO DSGVO-Konform mit hunderten Apps über Make (früher Integromat). Erstelle mit wenigen Klicks deinen eigenen
      automatisierten Prozess. Das geht vom CRM bis hin zu E-Mail-Marketing-Tools. Hier mal ein paar
      Beispiele: Google Sheets, HubSpot, ActiveCampaign, Mailchimp, Slack, Trello und viele mehr.
    </p>
    <p>
      Je nachdem welches Paket du bei Integromat hast, dauert es zwischen 1-15 Min. bis eine neue
      Anfrage verarbeitet wird.
    </p>
    <ol>
      <li>Erstelle ein neues "Scenario"</li>
      <li>Tippe MEETOVO in die Suche ein</li>
      <li>Wähle deinen "Trigger"</li>
      <li>Gib deinen "Access token" ein</li>
      <li>Binde eine App deiner Wahl an</li>
    </ol>
    <Alert
      showIcon
      type="warning"
      message={
        <React.Fragment>
          <b>Wichtig:</b> Behandele deinen Access token vertraulich. Er ermöglicht den Zugriff auf
          all deine Bewerbungen.
        </React.Fragment>
      }
    />
  </React.Fragment>
);


export const RatingsProviderTexts: { [key: string]: JSX.Element } = {
  ProvenExpert: (
    <React.Fragment>
      <p>
        Steigere das Vertrauen bei deinen potenziellen Kunden, indem du ProvenExpert in deinen
        Funnel integrierst. Die Integration ermöglicht es dir, deinen ProvenExpert Badge und
        deine Bewertungen in regelmäßigen Abständen in den Social-Proof-Bubbles anzuzeigen. Diese
        werden alle 12 Stunden aktualisiert.
      </p>
    </React.Fragment>
  ),

  TrustPilot: (
    <React.Fragment>
      <p>
        Steigere das Vertrauen bei deinen potenziellen Kunden, indem du Trustpilot in deinen
        Funnel integrierst. Die Integration ermöglicht es dir, deinen TrustPilot Badge und
        deine Bewertungen in regelmäßigen Abständen in den Social-Proof-Bubbles anzuzeigen. Diese
        werden alle 12 Stunden aktualisiert.
      </p>
    </React.Fragment>
  ),

  ekomi: (
    <React.Fragment>
      <p>
        Steigere das Vertrauen bei deinen potenziellen Kunden, indem du eKomi in deinen
        Funnel integrierst. Die Integration ermöglicht es dir, dein eKomi Badge im Funnel
        anzuzeigen.
      </p>
    </React.Fragment>
  )
};
