import { getEnvironment } from "./environment";

export function factorsAiTrackEvent(event: string) {
    return;
    try {
        const { isDev } = getEnvironment()

        if (isDev) {
            console.log("Not fired factorsAiTrackEvent in dev", event);
            return;
        }

        const checkInterval = 100;

        const intervalId = setInterval(() => {
            if (window.faitracker) {
                clearInterval(intervalId);
                window.faitracker.call("track", event);
            }
        }, checkInterval);
    } catch (error) {
        console.log("Error firing factorsAiTrackEvent", error);
    }
}