import gql from 'graphql-tag';

interface Input {
  id: number;
  customJs: string;
}

export const SET_PAGE_CUSTOM_JS = gql`
  mutation setPageCustomJs($input: SetPageCustomJs!) {
    setPageCustomJs(input: $input) {
      id
      customJs
    }
  }
`;

export default function setPageCustomJsAPI(input: Input) {
  return window.apolloClient.mutate({
    mutation: SET_PAGE_CUSTOM_JS,
    variables: { input }
  });
}
