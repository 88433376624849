import { useEffect, useState } from 'react';

export function useScrollPosition(domId: string) {
  const [position, setPosition] = useState(0);
  useEffect(() => {
    let animationFrameId: any;
    const updatePosition = () => {
      const element = document.getElementById(domId);
      if (element) {
        setPosition(element.getBoundingClientRect().top);
      }
      animationFrameId = requestAnimationFrame(updatePosition);
    };
    updatePosition();

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [domId]);

  return position;
}
