import { Col, Collapse, Form, Row } from 'antd';
import React from 'react';
import { useAppDispatch } from '../../../../redux/hooks';
import { hashFormItemsJobAttributes } from '../../../AdJobBuilder/components/sharedUI/FormItemsCustom';
import {
  getOptionsValuesInList,
  JOB_ATTRIBUTES_IDENTIFIER
} from '../../../AdJobBuilder/helper/AdJobHelper';
import { formatNumber } from '../../../AdJobBuilder/helper/validateFunnelJobPresets';
import BuilderValidator from '../../../container/BuilderValidator';
import { JobInformation } from '../../../interfaces/builderSliceTypes';
import {
  setJobInformation,
  useIsAiSettingDuplicated,
  useJobInformation
} from '../../../redux/builderSlice';
import DuplicateAiSettingsAlert from '../utils/DuplicateAiSettingsAlert';

const { Panel } = Collapse;

interface BuilderJobGeneralSettingsProps {
  showBottomImage?: boolean;
}

export default function BuilderJobGeneralSettings({}: BuilderJobGeneralSettingsProps) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const {
    address,
    salary,
    title,
    workplaceModel,
    category,
    salaryPlan,
    employmentType
  } = useJobInformation();

  const isAiSettingsDuplicated = useIsAiSettingDuplicated();

  const handleChange = (value: any, identifier: keyof JobInformation) => {
    const values = form.getFieldsValue();
    form.setFieldsValue({ ...values, [identifier]: value });
    dispatch(setJobInformation({ value, identifier }));
  };

  return (
    <Col span={24} className="builder__settings-sidebar__container">
      <Row className="builder__settings-sidebar__row">
        <Col span={24} className="px-4">
          <Form
            name="general_settings_form"
            layout="vertical"
            form={form}
            scrollToFirstError={true}
            initialValues={{
              title,
              category,
              workplaceModel,
              employmentType,
              address,
              salary,
              salaryPlan
            }}
          >
            {hashFormItemsJobAttributes[JOB_ATTRIBUTES_IDENTIFIER.JOB_TITLE](title, handleChange)}
            <Collapse className="mt-4 w-100" accordion expandIconPosition="right" bordered={false}>
              <Panel
                header={
                  <div>
                    <h6 className="settings-title__required">Kategorie</h6>
                    <p className="m-0">
                      {getOptionsValuesInList(JOB_ATTRIBUTES_IDENTIFIER.CATEGORY, category)}
                    </p>
                  </div>
                }
                key={1}
              >
                {hashFormItemsJobAttributes[JOB_ATTRIBUTES_IDENTIFIER.CATEGORY](
                  category,
                  handleChange
                )}
              </Panel>

              <Panel
                header={
                  <div>
                    <h6 className="settings-title__required">Beschäftigungsart</h6>
                    <p className="m-0">
                      {getOptionsValuesInList(
                        JOB_ATTRIBUTES_IDENTIFIER.EMPLOYMENT_TYPE,
                        employmentType
                      )}
                    </p>
                  </div>
                }
                key={2}
              >
                {hashFormItemsJobAttributes[JOB_ATTRIBUTES_IDENTIFIER.EMPLOYMENT_TYPE](
                  employmentType,
                  handleChange
                )}
              </Panel>

              <Panel
                header={
                  <div>
                    <h6 className="settings-title__required">Gehalt</h6>
                    <p className="m-0">
                      {`Gehalt ${formatNumber(salary?.[0] || 0)}€ - ${formatNumber(
                        salary?.[1] || 0
                      )}€ ${getOptionsValuesInList(JOB_ATTRIBUTES_IDENTIFIER.SALARY_PLAN, [
                        salaryPlan
                      ])}`}
                    </p>
                  </div>
                }
                key={4}
              >
                {hashFormItemsJobAttributes[JOB_ATTRIBUTES_IDENTIFIER.SALARY](
                  salary,
                  handleChange,
                  salaryPlan,
                  form
                )}
              </Panel>

              <Panel
                header={
                  <div>
                    <h6 className="settings-title__required">Arbeitsplatzmodell</h6>
                    <p className="m-0">
                      {getOptionsValuesInList(JOB_ATTRIBUTES_IDENTIFIER.WORKPLACE_MODEL, [
                        workplaceModel
                      ])}
                    </p>
                  </div>
                }
                key={6}
              >
                {hashFormItemsJobAttributes[JOB_ATTRIBUTES_IDENTIFIER.WORKPLACE_MODEL](
                  workplaceModel,
                  handleChange
                )}
              </Panel>

              <Panel
                header={
                  <div>
                    <h6 className="settings-title__required">Ort</h6>
                    <p className="m-0">{address?.place || 'Wähle einen Standort'}</p>
                  </div>
                }
                key={7}
              >
                {hashFormItemsJobAttributes[JOB_ATTRIBUTES_IDENTIFIER.JOB_ADDRESS](
                  address,
                  handleChange
                )}
              </Panel>
            </Collapse>
          </Form>

          {isAiSettingsDuplicated && <DuplicateAiSettingsAlert />}
        </Col>
      </Row>
    </Col>
  );
}
