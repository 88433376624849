import React, { useEffect, useState } from 'react';
import { useNode } from '@craftjs/core';
import Slider from 'react-rangeslider';
import { Card, Col, Row, Input, Form, InputNumber } from 'antd';
import { ActionsController } from '../sharedUI/ActionsController';
import 'react-rangeslider/lib/index.css';
import { stepsByMax, defaultProps } from '../../config/rangeSliderDefaultValues';
import { Store } from 'antd/lib/form/interface';
import { CRAFT_ELEMENTS, CRAFT_ELEMENTS_LABEL } from '../../config/craftElements';
import { CraftElementBaseProps } from '../../helper/craftJs';
import { useCurrentlyEditingBuilderTheme } from '../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';
interface Props extends CraftElementBaseProps {
  initialValue: number;
  min: number;
  max: number;
  unit: string;
}
const Rules = {
  minLength: [{ required: true, message: 'Es muss ein Mindestwert angeben werden.' }],
  maxLength: [{ required: true, message: 'Es muss ein Maximalwert angeben werden.' }]
};

const RangeSliderComponent = (props: Props) => {
  const { unit, min, max, initialValue } = props;
  let [currentValue, setCurrentValue] = useState<number>(initialValue);

  const { currentNode } = useNode(node => ({
    currentNode: node
  }));

  useEffect(() => {
    if (initialValue !== currentValue) {
      setCurrentValue(initialValue);
    }
  }, [initialValue]);

  const formattedCurrentValue = new Intl.NumberFormat('de-DE', {
    maximumSignificantDigits: 3
  }).format(currentValue);

  const stepSize = stepsByMax.find(s => max <= s.max)?.step || 25000;
  const { textColor } = useCurrentlyEditingBuilderTheme();
  return (
    <ActionsController label={CRAFT_ELEMENTS_LABEL[currentNode.data.displayName]}>
      <div className="rangeslider-wrapper mt-4 max-width__700px-centered">
        <div className="d-flex justify-content-center align-items-center rangeslider-wrapper__value">
          <p style={{ color: textColor }} className="fs-4 fw-light mx-2 ps-3">
            {formattedCurrentValue || ''}
          </p>
          <p style={{ color: textColor }} className="fs-4 fw-light pe-3">
            {unit || ''}
          </p>
        </div>
        <Slider
          tooltip={false}
          min={min}
          max={max}
          step={stepSize}
          value={currentValue}
          onChange={val => setCurrentValue(val)}
        />
      </div>
    </ActionsController>
  );
};

export const RangeSliderComponentSettings = () => {
  const [form] = Form.useForm();

  const {
    actions: { setProp },
    props
  } = useNode(node => ({
    props: node.data.props,
    nodeId: node.id
  }));

  const handleSetFormValues = (values: Store) => {
    setProp((props: any) => {
      props.unit = values.unit;
      props.min = values.min;
      props.max = values.max;
      props.initialValue = values.start;
    });
  };

  return (
    <Col span={24} className="builder__settings-sidebar__container">
      <Card className="settings-card" title="Schieberegler" bordered={false}>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <Form
              name="lead-qualifier_settings_form"
              layout="vertical"
              form={form}
              scrollToFirstError={true}
              initialValues={{
                unit: props.unit || '',
                min: props.min || 1,
                max: props.max || 500,
                start: props.initialValue || 250
              }}
              onChange={form.submit}
              onFinish={handleSetFormValues}
            >
              <Form.Item name="unit" label="Einheit">
                <Input placeholder="z.B. Jahre, Stunden, €, Kilometer ..." />
              </Form.Item>
              <div className="form-group-inline">
                <Form.Item rules={Rules.minLength} name={`min`} label="Minimum">
                  <InputNumber name="min" min={0} />
                </Form.Item>
                <Form.Item rules={Rules.maxLength} name={`max`} label="Maximum">
                  <InputNumber name="max" min={1} />
                </Form.Item>
                <Form.Item name={`start`} label="Startwert">
                  <InputNumber name="start" />
                </Form.Item>
              </div>
            </Form>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

RangeSliderComponent.craft = {
  name: CRAFT_ELEMENTS.RANGE_SLIDER,
  props: defaultProps,
  rules: {
    canMoveIn: () => {
      return false;
    },
    canMoveOut: () => {
      return false;
    }
  },
  related: {
    settings: RangeSliderComponentSettings
  }
};

export default RangeSliderComponent;
