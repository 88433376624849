import { Tooltip } from 'antd';
import React, { LegacyRef } from 'react';
import { tooltipText } from '../../helper/tooltipText';
import { useEditor, useNode } from '@craftjs/core';
import { BsArrowsMove } from 'react-icons/bs';
import { useSetDraggingElement } from '../../reduxCommonMutations/setDraggingElement';
import { DraggingFrom } from '../../interfaces/builderSliceTypes';

const DragAction = () => {
  const { query, actions } = useEditor();
  const setDraggingElement = useSetDraggingElement();

  const {
    connectors: { drag },
    currentNode
  } = useNode(node => {
    return {
      currentNode: node
    };
  });

  const handleDragStart = (e: any) => {
    const img = new Image();
    img.src = `${process.env.PUBLIC_URL}/icons/transparent.png`;
    e.dataTransfer.setDragImage(img, 0, 0);
    e.stopPropagation();
    setTimeout(() => {
      setDraggingElement({
        name: currentNode.data.name,
        nodeId: currentNode.id,
        draggingFrom: DraggingFrom.ACTION_CONTROLLER,
        parentNodeId: currentNode.data.parent,
        nodeIndex: query
          .node(currentNode.data.parent)
          .descendants(false)
          .indexOf(currentNode.id),
        displayName: currentNode.data.name
      });
    }, 0);
  };
  return (
    <Tooltip title={tooltipText.drag}>
      <li
        className="mr-2 drag-icon"
        ref={drag as LegacyRef<HTMLLIElement> | undefined}
        onMouseDown={() => {
          actions.selectNode(currentNode.id);
        }}
        onDragStart={handleDragStart}
        onDragEnd={(e: any) => {
          setDraggingElement({ name: null });
        }}
      >
        <BsArrowsMove />
      </li>
    </Tooltip>
  );
};

export default DragAction;
