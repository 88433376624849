import React, { useEffect } from 'react';
import { Button, Popover, Select, Spin, Tooltip } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router';
import { useEventsContext } from '../../context/EventsContext';
import { useSelector } from 'react-redux';
import { isFunnelRefreshLoading, setFunnelRefreshLoading } from '../../../UI/redux/uiSlice';
import { useAppDispatch } from '../../../redux/hooks';
import { FiFilter } from 'react-icons/fi';

const KanbanFunnelSelect = ({ showFunnelButtonText = false }: { showFunnelButtonText?: boolean }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isFunnelReloading = useSelector(isFunnelRefreshLoading);
  const { funnels, bookingLoading, refetchBookingInfo } = useEventsContext();
  const location = useLocation() as any;
  const locationFunnelIds =
    location?.state?.funnelIds?.length > 0 ? location?.state?.funnelIds : [];

  useEffect(() => {
    if (isFunnelReloading) refetchBookingInfo();
  }, [isFunnelReloading])

  useEffect(() => {
    if (isFunnelReloading && !bookingLoading) dispatch(setFunnelRefreshLoading(false));
  }, [bookingLoading])

  return (
    <div className={`d-flex ${showFunnelButtonText ? 'justify-content-center' : 'align-items-center'}`}>
      {locationFunnelIds.length !== 0 && (
        <Tooltip title='Aktualisieren'>
          <Spin className='d-flex align-items-center'
            indicator={
              <SyncOutlined
                className="agency-dashboard-brand-color"
                spin={bookingLoading}
                onClick={(e: any) => {
                  if (bookingLoading) return;
                  e.preventDefault();
                  e.stopPropagation();
                  refetchBookingInfo();
                }}
              />
            }
          />
        </Tooltip>
      )}
      <div className="d-flex">
        <Tooltip title='Filter'>

          <Popover
            className="default-popover"
            placement="top"
            content={
              <div className="default-popover__content" style={{ width: 400, maxWidth: 400 }}>
                <Select
            loading={bookingLoading}
            value={locationFunnelIds}
            placeholder="Funnel auswählen..."
            style={{ minWidth: '200px' }}
            options={funnels.map((single: any, id: number) => ({
              key: id,
              value: single.id,
              label: single.title
            }))}
            filterOption={(input, option) => {
              return !!option?.label
                ?.toString()
                .toLocaleLowerCase()
                .includes(input.toLowerCase());
            }}
            mode="multiple"
            maxTagCount={3}
            onChange={e => {
              navigate('/kontakte', {
                state: {
                  funnelIds: e
                }
              });
            }}
          />
              </div>
            }
            trigger="click"
          >
            <Button
              className={showFunnelButtonText ? `ant-btn__primary` : `ant-btn__without-background`}
              style={!showFunnelButtonText ? { margin: 0, padding: 0 } : {}}
              type={showFunnelButtonText ? `primary` : `ghost`}
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              icon={<FiFilter className={showFunnelButtonText ? 'funnel-icon-button' : 'agency-dashboard-brand-color'} size={22} />}
            >
              {showFunnelButtonText && <span>Funnel auswählen</span>}
            </Button>
          </Popover>
        </Tooltip>
      </div>
    </div>
  );
};

export default KanbanFunnelSelect;
