import React, { useEffect, useState } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { Card, Col, Row } from 'antd';
import BuilderSettingsTitle from '../../../components/sharedUI/BuilderSettingsTitle';
import { ActionsController } from '../../../components/sharedUI/ActionsController';
import { CraftElementBaseProps } from '../../../helper/craftJs';
import Autocomplete from 'react-google-autocomplete';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { MdMyLocation } from 'react-icons/md';
import { CRAFT_ELEMENTS } from '../../config/craftElements';

const GoogleMapComponent = withScriptjs(
  withGoogleMap((props: any) => {
    const [markerPosition, setMarkerPosition] = useState({
      lat: 31.4715049,
      lng: 74.45842449999999
    });

    useEffect(() => {
      if (props.mapSet) {
        const { lat, lng } = props.mapSet;
        setMarkerPosition({ lat: lat, lng: lng });
      }
    }, [props.mapSet]);

    return (
      <GoogleMap
        defaultZoom={8}
        defaultCenter={{ lat: markerPosition.lat, lng: markerPosition.lng }}
        center={{ lat: markerPosition.lat, lng: markerPosition.lng }}
        zoom={14}
      >
        <Marker position={markerPosition} />
      </GoogleMap>
    );
  })
);

interface Props extends CraftElementBaseProps {
  location?: string;
}

export const CraftAddressPicker = ({ location }: Props) => {
  return (
    <ActionsController>
      <div className="address-container" data-testid="addressPicker">{location}</div>
    </ActionsController>
  );
};

export const AddressPickerDefaultProps = {
  location: 'Cambridge, Ma, USA'
};

const AddressPickerSettings = () => {
  const [mapState, setMapState] = useState<
    | {
        lat: any;
        lng: any;
        zoom: any;
      }
    | undefined
  >(undefined);

  const { nodeId, props } = useNode(node => ({
    nodeId: node.id,
    props: node.data.props
  }));

  const { actions, query } = useEditor();

  return (
    <Col span={24} className="builder__settings-sidebar__container">
      <Card className="settings-card" title="Location" bordered={false}>
        <Row className="builder__settings-sidebar__row">
          <Col span={24} data-testid="craftjs-button-component-size-slider">
            <BuilderSettingsTitle title="Location" />
            <Row className="builder__settings-sidebar__row">
              <Col span={23}>
                <Autocomplete
                  apiKey="AIzaSyBs9iJzThW-gVXM1m9RwYD328Mb34HVL4c"
                  onPlaceSelected={place => {
                    actions.setProp(
                      nodeId,
                      (state: any) => (state.location = place.formatted_address)
                    );
                    const location = {
                      latitude: place?.geometry?.location?.lat(),
                      longitude: place?.geometry?.location?.lng()
                    };

                    setMapState({
                      zoom: 12,
                      lat: location.latitude,
                      lng: location.longitude
                    });
                  }}
                  className="address-picker-input"
                  data-testid="addressInput"
                  options={{
                    fields: ['formatted_address', 'geometry', 'name'],
                    types: ['geocode']
                  }}
                />
              </Col>
              <Col>
                <MdMyLocation className="address-picker-icon" />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            {/* <GoogleMapComponent
              {...{ mapSet: mapState }}
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBs9iJzThW-gVXM1m9RwYD328Mb34HVL4c&v=3.exp&libraries=geometry,drawing,places"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `400px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            /> */}
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

CraftAddressPicker.craft = {
  name: CRAFT_ELEMENTS.CRAFT_ADDRESS_PICKER,
  props: AddressPickerDefaultProps,
  related: {
    settings: AddressPickerSettings
  }
};
