import gql from 'graphql-tag';

export enum AI_PROMPT_TEXT_TYPES {
  HEADLINE = 'Headline',
  ADVANTAGES = 'Advantages',
  TASK = 'Task',
  ABOUT_US = 'AboutUs',
  ASK = 'Ask',
  FREE = 'Free'
}

interface AiTextInput {
  conversationId?: number;
  promptMessage: string;
  type: AI_PROMPT_TEXT_TYPES;
  selectedText: Boolean;
  maxTokens: number;
  nodeId: string;
}

export const GET_AI_TEXT_RESPONSE = gql`
  query GetAiTextResponse($input: AiTextInput!) {
    getAiTextResponse(input: $input) {
      conversationId
      message
      totalTokens
    }
  }
`;

export default function getAiTextResponse(input: AiTextInput) {
  return window.apolloClient.query({
    query: GET_AI_TEXT_RESPONSE,
    fetchPolicy: 'no-cache',
    variables: { input }
  });
}
