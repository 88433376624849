function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const root = '/agency-os-builder';

export const AGENCY_OS_BUILDER_PATH_NAME = {
  root,
  loginSettings: '/anmeldung-einstellungen',
  designSettings: '/design-einstellungen',
  hubSettings: '/nabe-einstellungen'
};

export const AGENCY_OS_BUILDER_ROUTES = {
  root: 'agency-os-builder',
  loginSettings: 'anmeldung-einstellungen',
  designSettings: 'design-einstellungen',
  hubSettings: 'nabe-einstellungen'
};

export const AGENCY_OS_BUILDER_PATH = {
  loginSettings: path(root, AGENCY_OS_BUILDER_PATH_NAME.loginSettings),
  designSettings: path(root, AGENCY_OS_BUILDER_PATH_NAME.designSettings),
  hubSettings: path(root, AGENCY_OS_BUILDER_PATH_NAME.hubSettings)
};
