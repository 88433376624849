import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { useIsAgencyOSBuilderPath } from './useIsAgencyOSBuilderPath';

export const useSelectedElementTab = () => {
  const isAgencyOSBuilderPath = useIsAgencyOSBuilderPath();
  
  return useAppSelector((state: RootState) =>
  isAgencyOSBuilderPath
      ? state.agencyOSBuilderUI.selectedElementTab
      : state.builderUI.selectedElementTab
  );
};
