import React, { useEffect, useRef } from 'react';
import { useEditor, useNode, UserComponent } from '@craftjs/core';
import { Col } from 'react-bootstrap';
import { containerSelectionOnColumn, getParentNodeId, getRandomId } from '../../../helper/craftJs';
import { CRAFT_ELEMENTS } from '../../../config/craftElements';
import DropElementArea, { Level } from '../common/DropElementArea';
import { useOnScreen } from '../../../../hooks/useOnScreen';
import IconComponent from '../IconComponent';
import { Element } from '@craftjs/core';
import { useIsMobileView } from '../../../hooks/useIsMobileView';
import { useDraggingElement } from '../../../hooks/useDraggingElement';
import { PlusIcon } from '../common/PlusIcon';
interface Props {
  columnWidths: {
    sm: number;
    md: number;
    lg: number;
  };
  displaySpecialIcon: boolean;
  deletedSpecialIconProps: string;
  children?: any;
  backgroundColor?: string;
}

const Column: UserComponent = ({
  children,
  columnWidths,
  displaySpecialIcon,
  backgroundColor,
  deletedSpecialIconProps,
  ...props
}: Props) => {
  const isMobileView = useIsMobileView();
  const divRef = useRef<HTMLDivElement | null>(null);
  const draggingElement = useDraggingElement();
  const columnRef = useRef<HTMLDivElement | null>(null);
  const isVisible = useOnScreen(columnRef);
  const {
    query,
    actions: { selectNode }
  } = useEditor();

  const isIconVisible = displaySpecialIcon;
  const deletedSpecialIconPropsData = deletedSpecialIconProps
    ? JSON.parse(deletedSpecialIconProps)
    : {};
  const {
    connectors: { connect, drag },
    nodeId,
    currentNode,
    parentId,
    childNodes,
    SerializedNodes
  } = useNode(node => {
    const SerializedNodes = query.getSerializedNodes();

    return {
      nodeId: node.id,
      currentNode: node,
      parentId: node.data.parent,
      childNodes: node.data.nodes,
      SerializedNodes: SerializedNodes
    };
  });

  const { actions } = useEditor();

  const currentlyNodes = currentNode?.data?.nodes?.map((nodeId: any) => ({
    ...SerializedNodes[nodeId],
    id: nodeId
  }));

  const iconNode = currentlyNodes?.find((node: any) => {
    return node.displayName === CRAFT_ELEMENTS.ICON && node.props.isColumnIcon === true;
  });

  const specialIcon = children?.props?.children?.filter((item: any) => {
    return item.key === iconNode?.id;
  });
  const newChildren = children?.props?.children?.filter((item: any) => {
    return item.key !== iconNode?.id;
  });

  useEffect(() => {
    if (!iconNode && isIconVisible) {
      const containerNodeId = getParentNodeId(nodeId, query, CRAFT_ELEMENTS.CONTAINER);
      const allDescendants = query.node(containerNodeId).descendants(true);
      const serializedNodes = query.getSerializedNodes();
      const specialIconNode = allDescendants?.find((nodeId: any) => {
        const node = serializedNodes[nodeId];
        return node.displayName === CRAFT_ELEMENTS.ICON && node.props.isColumnIcon === true;
      });

      let specialIconProps: any = {};
      if (specialIconNode) {
        const props = SerializedNodes[specialIconNode].props;
        specialIconProps = { ...props };
      }
      const actionCreator = actions.history.ignore();
      const node = query
        .parseReactElement(
          <Element
            id={getRandomId(10)}
            is={IconComponent}
            isColumnIcon={true}
            deleteNotReq={true}
            showPlusIcon={false}
            onlySettingsReq={true}
            copyNotReq={true}
            fontSize={specialIconProps?.fontSize || 4}
            isOutline={specialIconProps?.isOutline || false}
            canDrag={false}
            className="special-icon"
            {...deletedSpecialIconPropsData}
          />
        )
        .toNodeTree();
      actionCreator.addNodeTree(node, currentNode.id);
    }
  }, [isIconVisible, childNodes.length]);

  return (
    <Col
      className="column-parent-wrapper position-relative"
      {...columnWidths}
      data-node-info={CRAFT_ELEMENTS.COLUMN}
      {...props}
      {...(isMobileView && {
        sm: 12,
        md: 12,
        lg: 12
      })}
    >
      <DropElementArea level={Level.COLUMN} />
      <div
        onClick={(e: any) => {
          divRef.current && containerSelectionOnColumn(divRef.current, nodeId, query, selectNode);
        }}
        className="column-wrapper"
        ref={columnRef}
      >
        <div className="column-wrapper__inner">
          {isIconVisible && specialIcon}
          <div
            ref={ref => {
              divRef.current = ref;
              connect(drag(ref as HTMLDivElement));
            }}
            className={`column-content ${isIconVisible ? 'ms-1' : ''} ${
              newChildren ? '' : 'd-flex justify-content-center align-items-center'
            }`}
            style={{
              ...(isVisible &&
                draggingElement && {
                  paddingBlock: 10
                })
            }}
          >
            {newChildren || <PlusIcon staticIcon={true} />}
          </div>
        </div>
      </div>
    </Col>
  );
};

export const ColumnComponentProps = {
  columnWidths: {
    sm: 12,
    md: 6,
    lg: 3
  },
  displaySpecialIcon: false
};

Column.craft = {
  name: CRAFT_ELEMENTS.COLUMN,
  props: ColumnComponentProps,
  rules: {
    canMoveIn: (selectedNode: any) => {
      if (
        [CRAFT_ELEMENTS.COLUMN_GRID, CRAFT_ELEMENTS.CRAFT_STATE_RENDERER].includes(
          selectedNode[0].data.displayName
        )
      ) {
        return false;
      }
      return (
        selectedNode[0].data.displayName !== CRAFT_ELEMENTS.COLUMN &&
        selectedNode[0].data.displayName !== CRAFT_ELEMENTS.COLUMN_GRID &&
        selectedNode[0].data.displayName !== CRAFT_ELEMENTS.CONTAINER &&
        selectedNode[0].data.displayName !== CRAFT_ELEMENTS.ELEMENT_APPENDER
      );
    }
  }
};

export default Column;
