import { Card, message } from 'antd';
import { useForm } from 'antd/lib/form/util';
import React from 'react';
import useUpdateWebhook from '../../../hooks/useUpdateWebhook';
import { UserWebHookType } from '../../../hooks/useUserWebHooks';
import WebhookForm from './WebhookForm';
import { FunnelMessagesApiErrors } from '../../../config/messages';

interface Props extends UserWebHookType {
  handleClose: () => void;
}

const EditWebhook = ({ url, funnelId, id, handleClose }: Props) => {
  const [form] = useForm();
  const [updateWebhook, { loading }] = useUpdateWebhook();

  const handleSubmit = async (data: any) => {
    try {
      await updateWebhook({
        variables: {
          input: {
            id,
            ...data
          }
        }
      });
      handleClose();
    } catch (error) {
      message.error(FunnelMessagesApiErrors['Identifier is not unique.']);
    }
  };

  return (
    <Card>
      <WebhookForm
        initialValues={{
          url,
          funnelId
        }}
        form={form}
        loading={loading}
        handleSubmit={handleSubmit}
        handleCancel={handleClose}
      />
    </Card>
  );
};

export default EditWebhook;
