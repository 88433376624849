import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useNode } from '@craftjs/core';
import { Card, Col, Row, Input, Form } from 'antd';
import BuilderSettingsTitle from '../sharedUI/BuilderSettingsTitle';
import { ActionsController } from '../sharedUI/ActionsController';
import { youtubeOrVimeoUrlFormValidator } from '../../../helper/url';
import { CRAFT_ELEMENTS, CRAFT_ELEMENTS_LABEL } from '../../config/craftElements';
import { ImageLoadingSpinner } from '../../../SharedUI/components/ImageLoadingSpinner';
import { videoPlaceholder } from '../../config/builderUi';
import { useDraggingElement } from '../../hooks/useDraggingElement';
import { CraftElementBaseProps } from '../../helper/craftJs';

interface Props extends CraftElementBaseProps {
  videoSrc?: string;
}

const VideoComponent = (props: Props) => {
  const { videoSrc } = props;
  const [loading, setLoading] = useState(true);
  const draggingElement = useDraggingElement();

  const { currentNode } = useNode(node => ({
    currentNode: node
  }));

  useEffect(() => {
    setLoading(true);
  }, [props.videoSrc]);

  return (
    <ActionsController
      className="video-wrapper"
      label={CRAFT_ELEMENTS_LABEL[currentNode.data.displayName]}
    >
      <div
        className={`video-wrapper__inner w-100 h-100 p-0 ${
          draggingElement ? 'disable-video-player' : ''
        }`}
        style={{
          backgroundImage: `url(${videoPlaceholder})`,
          backgroundSize: `100%`
        }}
      >
        <ReactPlayer
          id="react-player-wrapper"
          url={videoSrc || ''}
          controls={true}
          onReady={() => {
            setLoading(false);
          }}
          onError={() => {
            setLoading(false);
          }}
        />
        {loading && <ImageLoadingSpinner />}
      </div>
    </ActionsController>
  );
};

export const VideoDefaultProps = {
  videoSrc: 'https://vimeo.com/732025226'
};

export const VideoSettings = () => {
  const [form] = Form.useForm();
  const {
    actions: { setProp },
    props
  } = useNode(node => ({
    props: node.data.props
  }));
  return (
    <Col span={24} className="builder__settings-sidebar__container">
      <Card className="settings-card" title="Video" bordered={false}>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BuilderSettingsTitle title="Video-URL" />
            <Form
              form={form}
              initialValues={{ url: props.videoSrc }}
              onFinish={values => {
                setProp((props: any) => (props.videoSrc = values.url));
              }}
              autoComplete="off"
            >
              <Form.Item
                name="url"
                rules={[
                  {
                    required: true,
                    message: 'URL ist erforderlich'
                  },
                  youtubeOrVimeoUrlFormValidator
                ]}
              >
                <Input
                  className="mt-2"
                  placeholder="YouTube, Vimeo, Wistia oder MP4-Url"
                  onChange={form.submit}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

VideoComponent.craft = {
  name: CRAFT_ELEMENTS.VIDEO,
  props: VideoDefaultProps,
  rules: {
    canMoveIn: () => {
      return false;
    }
  },
  related: {
    settings: VideoSettings
  }
};

export default VideoComponent;
