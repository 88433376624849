import gql from 'graphql-tag';
import { AgencyDashboardBase } from '../interfaces/agencyOSBuilderSliceTypes';

const SET_AGENCY_DASHBOARD = gql`
  mutation setAgencyDashboard($input: SetAgencyDashboardInput!) {
    setAgencyDashboard(input: $input) {
      id
    }
  }
`;

interface SetAgencyDashboardInput {
  title?: string;
  logoURL?: string;
  faviconURL?: string;
  primaryColor?: string;
  secondaryColor?: string;
  description?: string;
  previewImageURL?: string;
  customMetaDataEnabled?: boolean;
}

export default function setAgencyDashboard(input: SetAgencyDashboardInput) {
  return window.apolloClient.mutate({
    mutation: SET_AGENCY_DASHBOARD,
    variables: { input }
  });
}
