import { Tooltip } from 'antd';
import React from 'react';
import { tooltipText } from '../../helper/tooltipText';
import { IoIosCopy } from 'react-icons/io';
import { useEditor, useNode } from '@craftjs/core';
import { getClonedTree, updateElementsPropsBeforeCloning } from '../../helper/craftJs';
import { trackInteraction } from '../../DebugTracking/utils/helper';
import { TRACKINGS } from '../../DebugTracking/container/DebugTracker';

const CopyAction = () => {
  const { actions, query } = useEditor();
  const {
    name,
    nodeId
  } = useNode(node => {
    return {
      nodeId: node.id,
      name: node.data.name
    };
  });
  
  const handleClone = () => {
    const theNode = query.node(nodeId).get();
    const parentNode = query.node(theNode.data.parent).get();
    const indexToAdd = parentNode.data.nodes.indexOf(nodeId);

    const oldTree = query.node(nodeId).toNodeTree();
    const tree = getClonedTree(oldTree, query);

    const treeWithUpdatedProps = updateElementsPropsBeforeCloning(tree);
    actions.addNodeTree(treeWithUpdatedProps, parentNode.id, indexToAdd + 1);

    trackInteraction({
      type: 'FUNCTION_CALL',
      customEventName: TRACKINGS.HANDLE_CLONE_IN_RENDER_NODE,
      additionalData: { name, nodeId }
    });
  };
  return (
    <Tooltip title={tooltipText.copy}>
      <li className="mr-2 cursor-pointer action-icon" onClick={handleClone}>
        <IoIosCopy />
      </li>
    </Tooltip>
  );
};

export default CopyAction;
