import React from 'react';
import { getEnvironment } from '../../helper/environment';
import * as Sentry from '@sentry/browser';
import { GoogleLogin, GoogleLoginResponseOffline } from 'react-google-login';
import { message } from 'antd';
import { LoginMessages, GoogleConnectorMessages } from '../../config/messages';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { factorsAiTrackEvent } from '../../helper/factors-ai';

const prodClientId = '179731791369-6mo24im6ah0lp6ibevjevod6f0hh0m6s.apps.googleusercontent.com';
const devClientId = '275797623984-aphrfm7jqgt234ma9ut9gdo733pc5sva.apps.googleusercontent.com';

const CONNECT_GOOGLE_ACCOUNT = gql`
  mutation connectGoogleAccount($code: String!) {
    connectGoogleAccount(code: $code) {
      success
    }
  }
`;

interface GoogleConnectorInterface {
  onSuccess(authData: any): any;
  onFailure?(error: any): any;
  trigger?: JSX.Element;
  renderTrigger?(data: any): any;
  validator?(): any;
  validationFailedMessage?: string;
  connectExistingAccount?: boolean;
}

const GoogleConnector = ({
  onSuccess,
  onFailure,
  trigger,
  renderTrigger,
  connectExistingAccount,
  validator = () => true,
  validationFailedMessage = 'Die Angaben sind nicht vollständig.'
}: GoogleConnectorInterface) => {
  const [connectGoogleAccount, { loading }] = useMutation(CONNECT_GOOGLE_ACCOUNT);
  const { isDev } = getEnvironment();
  const isRegistrationAllowed =
    !isDev ||
    !!localStorage.getItem('meetovoRegistrationAllowed') ||
    window.location.host.includes('localhost');

  return (
    <GoogleLogin
      clientId={isDev ? devClientId : prodClientId}
      render={renderProps =>
        renderTrigger ? (
          renderTrigger({ loading, ...renderProps })
        ) : (
          <div
            className={'google-connector__trigger' + loading ? ' loading' : ''}
            onClick={() =>
              validator()
                ? !loading && isRegistrationAllowed
                  ? renderProps.onClick()
                  : () => {}
                : message.error(validationFailedMessage)
            }
          >
            {trigger}
          </div>
        )
      }
      scope="https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.readonly"
      responseType="code"
      accessType="offline"
      onSuccess={authData => {
        authData = authData as GoogleLoginResponseOffline;

        if (connectExistingAccount) {
          factorsAiTrackEvent('REGISTRATION');
          connectGoogleAccount({ variables: { code: authData.code } })
            .then(() => {
              onSuccess(authData);
            })
            .catch(e => {
              if (e.message.includes('refreshToken is not available'))
                message.error(GoogleConnectorMessages.refreshTokenNotAvailable, 10);
              else message.error(GoogleConnectorMessages.generalError);
            });
        } else {
          factorsAiTrackEvent('LOGIN');
          onSuccess(authData);
        }
        factorsAiTrackEvent('GOOGLE_ACCOUNT_CONNECTED');
      }}
      onFailure={e => {
        Sentry.captureMessage(JSON.stringify(e));

        if (e?.error == 'idpiframe_initialization_failed') {
          message.error(LoginMessages.noCookiesAllowed, 10);
          return;
        }

        if (e?.error == 'popup_closed_by_user') return;

        if (!onFailure) {
          message.error(GoogleConnectorMessages.generalError);
          return;
        }

        onFailure(e);
      }}
      cookiePolicy={'single_host_origin'}
    />
  );
};

export default GoogleConnector;
