import gql from 'graphql-tag';

export interface Input {
  id: number;
  title: string;
  tags: string[];
}

export const UPDATE_FUNNEL_TEMPLATE = gql`
  mutation updateFunnelTemplate($input: UpdateFunnelTemplateInput!) {
    updateFunnelTemplate(updateFunnelTemplateInput: $input) {
      id
      title
      tags
      image
      public
      craftState
      userId
    }
  }
`;

export default function updateFunnelTemplate(input: Input) {
  return window.apolloClient.mutate({
    mutation: UPDATE_FUNNEL_TEMPLATE,
    variables: { input }
  });
}
