import gql from 'graphql-tag';
import { SetAgencyOSBuilderPageInput } from '../interfaces/agencyOSBuilderSliceTypes';

export interface Input {
  pages: SetAgencyOSBuilderPageInput[];
}

export enum AgencyOSBuilderPagesTypes {
  LOGIN = "LOGIN",
  HUB = "HUB"
}

// Using this name here because we are gonna save agency dashboard from here as well.
export const SET_AGENCY_DASHBOARD_PAGES = gql`
  mutation setAgencyDashboardAndPages($pages: [SetAgencyOSBuilderPageInput!]!) {
    setAgencyOSBuilderPages(input: $pages) {
      id
      craftState
      type
    }
  }
`;

export default function setAgencyDashboardPages(input: Input) {
  return window.apolloClient.mutate({
    mutation: SET_AGENCY_DASHBOARD_PAGES,
    variables: {
      pages: input.pages
    }
  });
}
