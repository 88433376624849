import { tokens } from '../components/emailTemplate/utils';

export const filteredTokensAsPerEmailType = (emailType: string) =>
  tokens.filter(({ hideOn, mustShowOn }) => {
    if (mustShowOn?.includes(emailType)) return true;
    else {
      return !hideOn?.some((r: string | Function) =>
        typeof r === 'string' ? r === emailType : r()
      );
    }
  });
