import React, { useEffect } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { ActionsController } from '../sharedUI/ActionsController';
import { CRAFT_ELEMENTS } from '../../config/craftElements';
import { CraftElementBaseProps, getElementColor, getRandomId } from '../../helper/craftJs';
import { LoginComponent } from './LoginComponent';
import { convertColorToRGBA } from '../../helper/sharedFunctions';
import { AGENCY_OS_THEME_KEYS } from '../../AgencyOSBuilder/interfaces/agencyOSBuilderSliceTypes';
import { useAgencyOSCurrentlyEditingTheme } from '../../AgencyOSBuilder/redux/agencyOSBuilderSlice';

interface Props extends CraftElementBaseProps {
  children?: React.ReactNode;
}

export const LoginContainer = ({ children }: Props) => {
  const { nodeId, currentNode } = useNode(node => {
    return {
      currentNode: node,
      nodeId: node.id
    };
  });

  const { query, actions } = useEditor();
  const theme = useAgencyOSCurrentlyEditingTheme();

  useEffect(() => {
    if (!children && currentNode.data.nodes.length === 0) {
      const nodeTree = query
        .parseReactElement(
          <Element
            id={getRandomId(10)}
            is={LoginComponent}
            onlySettingsReq={true}
            showPlusIcon={false}
            openChildSettingsOnParentSettings
          ></Element>
        )
        .toNodeTree();
      actions.history.ignore().addNodeTree(nodeTree, nodeId);
    }
  }, []);

  return (
    <ActionsController
      className=" actions-controller__wrapper__login_container "
      style={{
        background: `linear-gradient(to right, ${convertColorToRGBA(
          getElementColor(AGENCY_OS_THEME_KEYS.PRIMARY_COLOR, theme),
          1
        )}, ${convertColorToRGBA(
          getElementColor(AGENCY_OS_THEME_KEYS.PRIMARY_CONTRAST_COLOR, theme),
          1
        )})`
      }}
    >
      <div className="login_form_container ">{children}</div>
    </ActionsController>
  );
};

export const LoginContainerDefaultProps = {};

const LoginContainerSettings = () => {
  const {
    actions: { setProp },
    props
  } = useNode(node => ({
    props: node.data.props
  }));
  return null;
};

LoginContainer.craft = {
  name: CRAFT_ELEMENTS.LOGIN_CONTAINER,
  props: LoginContainerDefaultProps,
  related: {
    settings: LoginContainerSettings
  }
};
