import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export const GET_CALENDAR = gql`
  query getCalendars {
    getCalendars {
      id
      primary
      summary
      summaryOverride
      backgroundColor
      foregroundColor
    }
  }
`;

export default function useCalendars() {
  return useQuery(GET_CALENDAR);
}
