import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export interface BookingFilterV2 {
  start: Date;
  end: Date;
  statusId: number;
  funnelIds: number[];
  searchQuery: string;
  type: string;
  offset: number;
  limit: number;
  includeChoices: Boolean;
}

export const GET_FUNNEL_BOOKING_COUNT = gql`
  query getBookingCount($funnelIds: [Int]!) {
    getBookingCountByFunnelsId(funnelIds: $funnelIds) {
      funnelId
      bookingCount
    }
  }
`;

export const GET_ALL_BOOKINGS_COUNT = gql`
  query GetBookingsCount($input: BookingFilterV2) {
    getBookingsCount(input: $input)
  }
`;

export default function useGetFunnelBookingCount() {
  return useQuery(GET_FUNNEL_BOOKING_COUNT, {
    fetchPolicy: 'no-cache',
    context: {
      headers: {
        'meetovo-cache-disabled': 'true'
      }
    }
  });
}

export function useGetAllBookingCount(filters?: BookingFilterV2) {
  return useQuery(GET_ALL_BOOKINGS_COUNT, {
    fetchPolicy: 'no-cache',
    variables: filters,
    context: {
      headers: {
        'meetovo-cache-disabled': 'true'
      }
    }
  });
}

export function getFunnelsBookingCount(funnelIds: number[]) {
  return window.apolloClient.query({
    query: GET_FUNNEL_BOOKING_COUNT,
    fetchPolicy: 'no-cache',
    context: {
      headers: {
        'meetovo-cache-disabled': 'true'
      }
    },
    variables: { funnelIds }
  });
}
