import React from 'react';
import { useNode } from '@craftjs/core';
import { CRAFT_ELEMENTS } from '../../config/craftElements';

const BodyComponent = ({ children, backgroundColor, ...props }: any) => {
  const {
    connectors: { connect, drag }
  } = useNode();

  return (
    <div {...props} ref={(ref: any) => connect(drag(ref))}>
      {children}
    </div>
  );
};

BodyComponent.craft = {
  name: CRAFT_ELEMENTS.BODY,
  rules: {
    canMoveIn: () => {
      return false;
    }
  }
};

export default BodyComponent;
