export interface QuestionConfigChoice {
  icon: string;
  title: string;
  weight: number;
  activeCampaignField: {
    fieldTitle: string;
    fieldValue: string;
  };
}
export interface QuestionConfig {
  id: number;
  question: string;
  type: 'radio' | 'multiple' | 'range' | 'text' | 'message';
  choices: QuestionConfigChoice[];
}

export const questions: Array<QuestionConfig> = [
  {
    id: 1,
    question: 'Was ist dein Ziel mit MEETOVO?',
    type: 'radio',
    choices: [
      {
        icon: 'FaUserTie',
        title: 'Mitarbeiter gewinnen',
        weight: 0,
        activeCampaignField: {
          fieldTitle: 'Kundenkategorie',
          fieldValue: 'Bewerbungen'
        }
      },
      {
        icon: 'FaEllipsisH',
        title: 'Etwas anderes',
        weight: 1,
        activeCampaignField: {
          fieldTitle: 'Kundenkategorie',
          fieldValue: 'Leads'
        }
      }
    ]
  },
  {
    id: 2,
    question: 'Wie viel Erfahrung hast du im Online-Marketing?',
    type: 'radio',
    choices: [
      {
        icon: 'MdNotInterested',
        title: 'Keine',
        weight: 0,
        activeCampaignField: {
          fieldTitle: 'Fähigkeitslevel',
          fieldValue: 'Keine'
        }
      },
      {
        icon: 'IoIosRocket',
        title: 'Professionell',
        weight: 3,
        activeCampaignField: {
          fieldTitle: 'Fähigkeitslevel',
          fieldValue: 'Professionell'
        }
      },
      {
        icon: 'FaAngleDoubleUp',
        title: 'Fortgeschritten',
        weight: 2,
        activeCampaignField: {
          fieldTitle: 'Fähigkeitslevel',
          fieldValue: 'Fortgeschritten'
        }
      },
      {
        icon: 'FaAngleUp',
        title: 'Grundlagen',
        weight: 1,
        activeCampaignField: {
          fieldTitle: 'Fähigkeitslevel',
          fieldValue: 'Grundlagen'
        }
      }
    ]
  },
  {
    id: 3,
    question: 'Wie hast du zum ersten Mal von uns erfahren?',
    type: 'radio',
    choices: [
      {
        icon: 'FaFacebook',
        title: 'Facebook/Instagram',
        weight: 0,
        activeCampaignField: {
          fieldTitle: 'Kanal',
          fieldValue: 'Facebook/Instagram'
        }
      },
      {
        icon: 'FaLinkedin',
        title: 'LinkedIn',
        weight: 10,
        activeCampaignField: {
          fieldTitle: 'Kanal',
          fieldValue: 'LinkedIn'
        }
      },
      {
        icon: 'FaGoogle',
        title: 'Google',
        weight: 20,
        activeCampaignField: {
          fieldTitle: 'Kanal',
          fieldValue: 'Google'
        }
      },
      {
        icon: 'FaThumbsUp',
        title: 'Empfehlung',
        weight: 30,
        activeCampaignField: {
          fieldTitle: 'Kanal',
          fieldValue: 'Empfehlung'
        }
      },
      {
        icon: 'FiMoreHorizontal',
        title: 'Sonstiges',
        weight: 40,
        activeCampaignField: {
          fieldTitle: 'Kanal',
          fieldValue: 'Sonstiges'
        }
      }
    ]
  },
  {
    id: 4,
    question: 'Wie viele Mitarbeiter beschäftigt dein Unternehmen?',
    type: 'radio',
    choices: [
      {
        icon: 'FaUser',
        title: '1',
        weight: 0,
        activeCampaignField: {
          fieldTitle: 'Anzahl der Mitarbeiter',
          fieldValue: '1'
        }
      },
      {
        icon: 'FaUserFriends',
        title: '2-5',
        weight: 10,
        activeCampaignField: {
          fieldTitle: 'Anzahl der Mitarbeiter',
          fieldValue: '2-5'
        }
      },
      {
        icon: 'FaUsers',
        title: '5-15',
        weight: 20,
        activeCampaignField: {
          fieldTitle: 'Anzahl der Mitarbeiter',
          fieldValue: '5-15'
        }
      },
      {
        icon: 'FaBuilding',
        title: '> 15',
        weight: 30,
        activeCampaignField: {
          fieldTitle: 'Anzahl der Mitarbeiter',
          fieldValue: '> 15'
        }
      }
    ]
  },
  {
    id: 5,
    question:
      'Für wen möchtest du Mitarbeiter gewinnen?',
    type: 'radio',
    choices: [
      {
        icon: 'FaUserTie',
        title: 'Eigenes Unternehmen',
        weight: 0,
        activeCampaignField: {
          fieldTitle: 'Typ',
          fieldValue: 'Unternehmer'
        }
      },
      {
        icon: 'FaBuilding',
        title: 'Arbeitgeber',
        weight: 10,
        activeCampaignField: {
          fieldTitle: 'Typ',
          fieldValue: 'Recruiter'
        }
      },
      {
        icon: 'FaLaptopHouse', 
        title: 'Agentur',
        weight: 20,
        activeCampaignField: {
          fieldTitle: 'Typ',
          fieldValue: 'Agentur'
        }
      }
    ]
  }
];
