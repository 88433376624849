import React, { useState } from 'react';
//@ts-ignore
import moment from 'moment-timezone';
import { DatePicker, message, Button } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import dateFormats from '../../../config/dateFormats';
import { SpecialClosuresMessages } from '../../../config/messages';
import BuilderSettingsTitle from '../../../Builder/components/sharedUI/BuilderSettingsTitle';
const { RangePicker } = DatePicker;

interface SpecialClosurePropsInterface {
  onChange(value: any): any;
  specialClosures: SpecialClosureInterface[];
}

interface StateInterface {
  addOpen: boolean;
  start: moment.Moment;
  end: moment.Moment;
}

export interface SpecialClosureInterface {
  id?: number;
  start: string;
  end: string;
}

function SpecialClosure({ onChange, specialClosures }: SpecialClosurePropsInterface) {
  const [state, setState] = useState<StateInterface>({
    addOpen: false,
    start: moment().startOf('day'),
    end: moment().endOf('day')
  });

  function deleteSpecialClosure(key: number) {
    onChange(specialClosures.filter((s, _key) => _key !== key));
  }

  function prepareAdd() {
    setState({
      ...state,
      addOpen: true
    });
  }

  function createSpecialClosure(dates: any) {
    let [start, end] = dates;
    start = moment.isMoment(start) && moment(start).startOf('day');
    end = moment.isMoment(end) && moment(end).endOf('day');

    if (start && end && end.isAfter(start)) {
      setState({
        ...state,
        addOpen: false
      });
      onChange([...specialClosures, { start: start.toISOString(), end: end.toISOString() }]);
    } else {
      message.error(SpecialClosuresMessages.startBeforeEnd);
    }
  }

  const { addOpen } = state;

  return (
    <div className="business-hours special-closures">
      <BuilderSettingsTitle title="Abwesenheiten" />
      {specialClosures.map((specialClosure, key) => (
        <div key={key} className="day">
          <div className="time-unit">
            <span className="time start">
              {moment.tz(specialClosure.start, 'Europe/Berlin').format(dateFormats.day)}
            </span>
            <span className="divider"> - </span>
            <span className="time start">
              {moment.tz(specialClosure.end, 'Europe/Berlin').format(dateFormats.day)}
            </span>
            <DeleteOutlined onClick={() => deleteSpecialClosure(key)} />
          </div>
        </div>
      ))}

      {addOpen && <RangePicker onChange={createSpecialClosure} />}

      <Button type="dashed" onClick={prepareAdd} block disabled={addOpen}>
        <PlusOutlined /> Hinzufügen
      </Button>
    </div>
  );
}

export default SpecialClosure;
