import React, { useState, useEffect } from 'react';
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import { detectSafari, detectEdge } from '../helper/deviceHelper';
import ReactAudioPlayer from 'react-audio-player';
import { Button, Upload, message } from 'antd';
import { AudioOutlined, DeleteOutlined, CloudUploadOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import { EventsMessages } from '../../config/messages';

interface VoiceRecorderInterface {
  onChange: Function;
  onClickUpload?: Function;
  onDelete: Function;
  uploadLoading?: boolean;
  voiceMessageAWSKey?: string;
  voiceMessageSignedUrl?: string;
  id?: number;
}

const allowedAudioTypes = ['audio/mp3', 'audio/mpeg'];
let recorder: any, microphone: any;

const fileList: [] = [];

function VoiceRecorder({
  id = 0,
  onChange,
  onDelete,
  voiceMessageAWSKey,
  voiceMessageSignedUrl,
  onClickUpload,
  uploadLoading
}: VoiceRecorderInterface) {
  const [audioFiles, setAudioFiles] = useState<{ [key: number]: string | undefined }>({
    [id]: voiceMessageSignedUrl
  });
  const [isRecording, setRecordingState] = useState<boolean>(false);
  // @ts-ignore
  const audioFile: string | undefined = audioFiles[id];
  const [fileError, setFileError] = useState('');

  const isEdge = detectEdge(),
    isSafari = detectSafari();

  useEffect(() => {
    const currentIds = Object.keys(audioFiles);

    if (!currentIds.includes(id + ''))
      setAudioFiles({ ...audioFiles, [id]: voiceMessageSignedUrl });
  }, [id]);

  useEffect(() => {
    if (!audioFile && voiceMessageSignedUrl)
      setAudioFiles({ ...audioFiles, [id]: voiceMessageSignedUrl });
  }, [voiceMessageSignedUrl]);

  const captureMicrophone = (callback: Function) => {
    if (microphone) {
      callback(microphone);
      return;
    }

    if (typeof navigator.mediaDevices === 'undefined' || !navigator.mediaDevices.getUserMedia) {
      alert('Dein Gerät unterstützt keine Sprachaufnahme.');

      //@ts-ignore
      if (!!navigator.getUserMedia) {
        alert('Dein Browser ist veraltet und unterstützt die Sprachaufnahme nicht vollständig.');
      }
    }

    navigator.mediaDevices
      .getUserMedia({
        audio: isEdge
          ? true
          : {
              echoCancellation: false
            }
      })
      .then(function(mic) {
        callback(mic);
      })
      .catch(function(error) {
        console.error(error);
      });
  };

  const releasMicrophone = () => {
    if (microphone) {
      microphone.stop();
      microphone = null;
    }
  };

  const stopRecording = () => {
    setRecordingState(false);

    if (!recorder) return;

    recorder.stopRecording(() => {
      const blob = recorder.getBlob();
      setAudioFiles({ ...audioFiles, [id]: URL.createObjectURL(blob) });
      onChange(blob);

      if (isSafari) {
        releasMicrophone();
      }
    });

    releasMicrophone();
  };

  const startRecording = () => {
    setRecordingState(true);

    if (!microphone) {
      captureMicrophone(function(mic: any) {
        microphone = mic;

        if (isSafari) {
          setAudioFiles({ ...audioFiles, [id]: undefined });

          alert(
            'Bitte starte die Aufnahme erneut, nachdem du den Zugriff auf das Mikrofon erlaubt hast.'
          );
          return;
        }

        startRecording();
      });
      return;
    }

    setAudioFiles({ ...audioFiles, [id]: undefined });

    const options: any = {
      type: 'audio',
      numberOfAudioChannels: isEdge ? 1 : 2,
      checkForInactiveTracks: true,
      bufferSize: 16384
    };

    if (isSafari || isEdge) {
      options.recorderType = StereoAudioRecorder;
    }

    if (
      navigator.platform &&
      navigator.platform
        .toString()
        .toLowerCase()
        .indexOf('win') === -1
    ) {
      options.sampleRate = 48000; // or 44100 or remove this line for default
    }

    if (isSafari) {
      options.sampleRate = 44100;
      options.bufferSize = 4096;
      options.numberOfAudioChannels = 2;
    }

    if (recorder) {
      recorder.destroy();
      recorder = null;
    }

    recorder = new RecordRTC(microphone, options);

    recorder.startRecording();
  };

  const handleDelete = (voiceMessageAWSKey?: string) => {
    setAudioFiles({ ...audioFiles, [id]: undefined });
    // if (voiceMessageAWSKey)
    onDelete(voiceMessageAWSKey);
  };

  const props = {
    name: 'file',
    accept: '.mp3',
    headers: {
      authorization: 'authorization-text'
    },
    onChange(info: any) {
      const blob = info.file.originFileObj;
      if (info.event) {
        info.event.preventDefault();
      } else {
        return;
      }

      if (!allowedAudioTypes.includes(blob.type)) {
        message.error(EventsMessages.audioFileAllowed);
        return;
      }
      setFileError('');
      setAudioFiles({ ...audioFiles, [id]: URL.createObjectURL(blob) });
      onChange(blob);
    },
    showUploadList: false
  };

  return (
    <div className="voice-recorder">
      <div className="flex-group">
        {audioFile && <ReactAudioPlayer src={audioFiles[0]} controls />}
        {!isRecording && audioFile ? (
          <>
            <Button
              type="primary"
              danger
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => handleDelete(voiceMessageAWSKey)}
            />

            {voiceMessageSignedUrl?.includes('blob:') && (
              <Button
                loading={uploadLoading}
                type="primary"
                className="green mx-1"
                shape="circle"
                icon={<CloudUploadOutlined />}
                onClick={() => !uploadLoading && onClickUpload && onClickUpload()}
              />
            )}
          </>
        ) : null}
      </div>

      {!audioFile ? (
        <Button
          id="record-btn"
          type="primary"
          danger={isRecording}
          className={isRecording ? 'is-recording' : ''}
          onClick={() => (isRecording ? stopRecording() : startRecording())}
          icon={<AudioOutlined />}
        >
          {isRecording ? 'Fertig' : 'Aufnehmen'}
        </Button>
      ) : null}

      {!isRecording && !audioFile && (
        <Upload {...props}>
          <Button className="recorded-audio-upload-btn" icon={<CloudUploadOutlined />}>
            Hochladen
          </Button>
        </Upload>
      )}
      {fileError && <Text type="danger">{fileError}</Text>}
    </div>
  );
}

export default VoiceRecorder;
