import React from 'react';
import { Card, Col, Row } from 'antd';
import ConnectWebhookDomainModal from '../components/Webhook/ConnectWebhookDomainModal';
import { connectCardTitlesAndCategories } from '../utils/types';
import { EDIT_CONTENT_TYPE } from '../context/ResourceEditContext';

const ConnectWebhooks = () => {
  return (
    <>
      <ConnectWebhookDomainModal
        trigger={
          <div className="card-with-hover card-with-hover__logo-not-rounded">
            <Card
              hoverable
              className="card-container"
              cover={<img src={process.env.PUBLIC_URL + '/connect/webhook.svg'} />}
            >
              <Card.Meta title={connectCardTitlesAndCategories[EDIT_CONTENT_TYPE.WEBHOOK].title} className="card-title" />
              <div>{connectCardTitlesAndCategories[EDIT_CONTENT_TYPE.WEBHOOK].category}</div>
            </Card>
          </div>
        }
      />
    </>
  );
};

export default ConnectWebhooks;
