import React, { ReactElement, useState } from 'react';
import { Col, message, Row, Tag, Typography } from 'antd';
import lz from 'lzutf8';
import { Template } from './Template';
import useGetAllFunnelTemplate from '../../hooks/useGetAllFunnelTemplate';
import DefaultLoader from '../../../SharedUI/components/DefaultLoader';
import { defaultTemplate } from './defaultTemplate';
import { TemplatePanelMessages } from '../../../config/messages';
import { Template as TemplateType, SelectedTemplate } from './interfaces';
import Search from 'antd/lib/input/Search';
import CreateAndUpdateBlockTemplate from './CreateAndUpdateBlockTemplate';
import { debounce } from 'debounce';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import DefaultEmptyViewForSettingsPanel from '../sharedUI/DefaultEmptyViewForSettingsPanel';

interface Props {
  handleAddTemplate: (serializedNode: SelectedTemplate) => void;
}

function TemplatesPanel({ handleAddTemplate }: Props): ReactElement {
  const { data, loading = true } = useGetAllFunnelTemplate();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const [editTemplateDate, setEditTemplateDate] = useState<{
    id: number;
    title: string;
    tags: string[];
  } | null>(null);

  const handleFilteredResult = (templates: TemplateType[]) => {
    if (searchQuery) {
      const lowerCasedSearchQuery = searchQuery.toLowerCase();
      return templates.filter(
        template =>
          template.title.toLowerCase().includes(lowerCasedSearchQuery) ||
          (template.tags
            ? template.tags.find((t: string) => t.toLowerCase().includes(lowerCasedSearchQuery))
            : false)
      );
    }
    return templates;
  };

  const handleOnChange = debounce((value: string) => {
    setSearchQuery(value);
  }, 1000);

  const tags: string[] = Array.from(
    new Set(data?.funnelTemplates.map((template: { tags: string[] }) => template.tags).flat())
  );

  const filteredResult = handleFilteredResult([
    defaultTemplate,
    ...(data?.funnelTemplates ? [...data.funnelTemplates] : [])
  ]);

  return (
    <Row>
      <Search
        placeholder="z.B. Vorteile, Kunde XYZ ..."
        className="w-100"
        value={searchValue}
        onSearch={value => setSearchQuery(value)}
        onChange={e => {
          const value = e.target.value;
          setSearchValue(value);
          handleOnChange(value);
        }}
      />

      <div className="mb-2 builder__settings-sidebar__container__template-tags">
        {!!tags.length &&
          tags.map(tag => (
            <CheckableTag
              key={tag}
              checked={searchQuery === tag}
              onChange={() => {
                setSearchValue(tag);
                setSearchQuery(tag);
              }}
            >
              {tag}
            </CheckableTag>
          ))}
      </div>

      <DefaultLoader loading={loading} />
      {!loading &&
        filteredResult.map((template: TemplateType, index: number) => (
          <Col
            span={24}
            className={'my-3' + (index === 0 ? ' mt-0' : '')}
            key={index}
            onDragStart={e => {
              message.info(TemplatePanelMessages.youNeedToClick);
            }}
          >
            <div>
              <Template
                id={template.id}
                serializedNode={JSON.parse(lz.decompress(lz.decodeBase64(template.craftState)))}
                name={template.title}
                tags={template.tags}
                previewImageURL={template.image}
                handleAddTemplate={handleAddTemplate}
                deletable={!template.public && template.id !== 0}
                setEditTemplateDate={setEditTemplateDate}
              />
            </div>
          </Col>
        ))}

      {!filteredResult.length && (
        <DefaultEmptyViewForSettingsPanel
          title="Keine Ergebnisse"
          description="Versuche es mit einem anderen Suchbegriff oder überprüfe deine Eingabe auf Fehler."
          style={{ marginTop: '45%' }}
        />
      )}

      <CreateAndUpdateBlockTemplate
        visible={!!editTemplateDate}
        toggleVisible={() => {
          setEditTemplateDate(null);
        }}
        {...(editTemplateDate && { editTemplateDate })}
      />
    </Row>
  );
}

export default TemplatesPanel;
