import React, { useEffect, useState } from 'react';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import DefaultSmallLoader from './DefaultSmallLoader';
import { Button } from 'antd';

interface Props {
  loading: boolean;
  onDelete(): void;
}

const DeleteButtonWithConfirmation = ({ loading, onDelete }: Props) => {
  const [confirmVisible, setConfirmVisibility] = useState(false);

  useEffect(() => {
    if (!loading && confirmVisible) setConfirmVisibility(false);
  }, [loading]);

  return confirmVisible ? (
    <Button type="link" className="d-flex align-items-center">
      <div className="d-flex align-items-center cursor-pointer">
        <DefaultSmallLoader loading={loading} />
      </div>
      {!loading && (
        <>
          <CloseOutlined
            className="cursor-pointer me-1"
            onClick={() => {
              setConfirmVisibility(false);
            }}
          />
          <div
            data-testid="deleteConfirmButton"
            className="d-flex align-items-center cursor-pointer default-opacity-hover text-red"
            onClick={() => {
              setConfirmVisibility(false);
              onDelete();
            }}
          >
            <DeleteOutlined className="text-red px-1" />
            Bestätigen
          </div>
        </>
      )}
    </Button>
  ) : (
    <Button
      data-testid="deleteButton"
      className="icon-link d-flex align-items-center cursor-pointer default-opacity-hover text-red "
      type="link"
      onClick={() => {
        setConfirmVisibility(true);
      }}
    >
      <DeleteOutlined className="default-opacity-hover text-red" />
      Löschen
    </Button>
  );
};

export default DeleteButtonWithConfirmation;
