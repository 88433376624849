export const tooltipText={
    show:"Bild anzeigen",
    hide:"Bild verbergen",
    save:"Speichern",
    previous:"Zurück",
    next:"Vor",
    drag:"Verschieben",
    copy:"Kopieren",
    delete:"Löschen",
    settings:"Einstellungen",
    emoji:"Emoji",
    showContent:"Inhalt anzeigen",
    hideContent:"Inhalt verbergen",
    textAi:"Text KI"
}
