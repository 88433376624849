import gql from 'graphql-tag';

interface Input {
  name: string;
  craftState: string;
  type: string;
  pageOrder: number;
}

export const CREATE_FUNNEL_PAGE = gql`
  mutation createFunnelPage($input: CreateFunnelPageInput!) {
    createFunnelPage(input: $input) {
      id
      name
      craftState
      type
      facebookPixelEvent
      tikTokPixelEvent
      linkedInEvent
      googleTagTrigger
      customJs
      redirectURL
      pageOrder
    }
  }
`;

export default function addFunnelPage(input: Input) {
  return window.apolloClient.mutate({
    mutation: CREATE_FUNNEL_PAGE,
    variables: { input }
  });
}
