import { Button, Modal, Result } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import React from 'react';

const CompatibilityPopup = ({ visible, onClose }: { visible: boolean; onClose: () => void }) => {
  return (
    <Modal visible={visible} onOk={onClose} onCancel={onClose} footer={null}>
      <Result
        icon={<img width={200} src={`${process.env.PUBLIC_URL}/icons/use-chrome-or-edge.jpg`} />}
        title="Diese Software ist für den Chrome- & Edge-Browser optimiert"
        subTitle={
          <>
            <p>
              Wir empfehlen dir Chrome oder Edge zu nutzen. Unter Safari und Firefox kann es beim
              Erstellen deines Funnels zu Einschränkungen kommen.{' '}
              <b>Deine Funnels funktionieren auf allen Geräten.</b>
            </p>
          </>
        }
        extra={[
          <>
            <Button
              type="primary"
              href="https://www.google.com/intl/de_de/chrome"
              target="_blank"
              icon={<DownloadOutlined />}
            >
              Chrome herunterladen
            </Button>
            <Button onClick={onClose} block type="link">
              Trotzdem weiter
            </Button>
          </>
        ]}
      />
    </Modal>
  );
};

export default CompatibilityPopup;
