import gql from 'graphql-tag';
export interface CreateWidgetAndTheme {
  title: string;
  uniqueIdentifier: string;
  folderId?: string
}

export const SET_CREATE_WIDGET = gql`
  mutation createWidget($input: CreateWidgetAndTheme!) {
    createWidget(input: $input) {
      id
      craftState
      coachId
      uniqueIdentifier
      title
    }
  }
`;

export const GET_ALL_WIDGETS = gql`
  query getAllWidgets {
    getAllWidgets {
      id
      craftState
      coachId
      uniqueIdentifier
      title
      folderId
      enabled
      updatedAt
      createdAt
      previewImageURL
    }
  }
`;

export const DELETE_WIDGET = gql`
  mutation deleteWidgetHub($input: Int!) {
    deleteWidgetHub(input: $input)
  }
`;

export const DUPLICATE_WIDGET = gql`
  mutation duplicateWidget($widgetId: Int!) {
    duplicateWidget(id: $widgetId) {
      id
      coachId
      craftState
      uniqueIdentifier
      previewImageURL
      title
      enabled
      folderId
      createdAt
      updatedAt
    }
  }
`;

export default function setCreateWidget(input: CreateWidgetAndTheme) {
  return window.apolloClient.mutate({
    mutation: SET_CREATE_WIDGET,
    variables: { input }
  });
}

export function getAllWidgets() {
  try {
    return window.apolloClient.query({
      query: GET_ALL_WIDGETS,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          'meetovo-cache-disabled': 'true'
        }
      }
    });
  } catch (e) {
    console.log(e);
  }
}
