import { Popover, Switch, Typography } from 'antd';
import React from 'react';
import { FooterComponentMessages } from '../../../config/messages';
import { useAppDispatch } from '../../../redux/hooks';
import { updateChannelGeneralSettings, useChannelTemplatesData } from '../../redux/builderSlice';
import BuilderSettingsTogglePro from '../sharedUI/BuilderSettingsTogglePro';
import { CHANNEL_DATA_INPUTS } from '../../interfaces/builderSliceTypes';
import { useSubscriptionInfo } from '../../../UI/redux/userSlice';

const EmailBrandingSwitch = () => {
  const dispatch = useAppDispatch();
  const { brandingEnabled } = useChannelTemplatesData();
  const subscriptionInfo = useSubscriptionInfo();
  const { brandingEnabled: forceBrandingEnabled } = subscriptionInfo;

  const BrandingSwitch = ({ disabled }: { disabled?: boolean }) => (
    <Switch
      size="small"
      disabled={disabled}
      defaultChecked
      checked={brandingEnabled}
      onChange={checked => {
        dispatch(
          updateChannelGeneralSettings({
            type: CHANNEL_DATA_INPUTS.BRANDING_ENABLED,
            value: checked
          })
        );
      }}
    />
  );

  return (
    <>
      <BuilderSettingsTogglePro
        title="Branding"
        classNames="py-3"
        rightContent={
          forceBrandingEnabled ? (
            <Popover
              className="default-popover"
              content={
                <div className="default-popover__content">
                  {FooterComponentMessages.brandingEnabled}
                </div>
              }
            >
              <div>
                <BrandingSwitch disabled />
              </div>
            </Popover>
          ) : (
            <BrandingSwitch />
          )
        }
      />
      <p className="px-4 my-3">
        Wenn du das 'Branding' deaktivierst, werden E-Mail-Benachrichtungen von einer neutralen
        Adresse aus an deine Kunden gesendet, so dass diese nicht mehr auf MEETOVO zurück zu führen
        sind.
      </p>
      <div className="px-4 mb-4 mt-2">
        <img
          src="/dashboard/funnel-builder/minimal-email-mockup.jpg"
          className="decent-border w-100"
        />
      </div>
    </>
  );
};

export default EmailBrandingSwitch;
