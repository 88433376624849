import gql from 'graphql-tag';

export interface Input {
  id: number;
  name: string;
}

export const UPDATE_FUNNEL_FOLDER = gql`
  mutation updateFunnelFolder($input: UpdateFunnelFolderInput!) {
    updateFunnelFolder(input: $input) {
      id
      name
    }
  }
`;

export default function updateFunnelFolder(input: Input) {
  return window.apolloClient.mutate({
    mutation: UPDATE_FUNNEL_FOLDER,
    variables: { input }
  });
}
