import React from 'react';
import { CRAFT_ELEMENTS } from '../../../../config/craftElements';
import ChoiceContainerHoc, { CHOICE_TYPES } from '../../common/ChoiceContainerHOC';

export interface Props {}

const MultipleChoiceComponent = (props: Props) => {
  return <ChoiceContainerHoc {...props} type={CHOICE_TYPES.MULTIPLE}></ChoiceContainerHoc>;
};

export const MultipleChoiceComponentProps = {};

export default MultipleChoiceComponent;

MultipleChoiceComponent.craft = {
  name: CRAFT_ELEMENTS.MULTIPLE_CHOICE,
  props: MultipleChoiceComponentProps,
  rules: {
    canMoveIn: (selectedNode: any) => {
      return selectedNode[0].data.displayName === CRAFT_ELEMENTS.CHOICE;
    },
    canMoveOut: () => false
  }
};
