import React from 'react';
import { Tooltip, BarChart, CartesianGrid, XAxis, YAxis, Bar, ResponsiveContainer } from 'recharts';
import { Empty, Card } from 'antd';
import { AnalyticsMessages } from '../../config/messages';
import {
  WelcomePageDataInterfaceSimple,
  getReadableStepNameByPhd
} from '../utils/readableStepNames';
import { formatPercent } from '../../helper/numberFormat';
import PageHitComponentMobile from './PageHitComponentMobile';
import {
  LeadQualifierInterface,
  LeadQualifierChoiceInterface
} from '../../Events/container/Events';

interface ChoiceCountInterface {
  choiceId: number;
  count: number;
}

export interface PageHitDataInterface {
  artificial?: boolean;
  stepIndex: number;
  stepName: string;
  hits: number;
  averageTimeInSeconds: number;
  choiceArray: ChoiceCountInterface[];
  leadQualifierId: number | null;
}

interface PageHitComponentProps {
  pageHitData: PageHitDataInterface[];
  leadQualifierData: LeadQualifierInterface[];
  welcomePageData: WelcomePageDataInterfaceSimple[];
  leads?: number;
  funnelPages: { id: number; name: string }[];
}

interface BarLabelInterface {
  x: number;
  y: number;
  value: string;
}

export interface ChartData {
  name: string;
  value: number;
  phd: PageHitDataInterface;
  lqd: LeadQualifierInterface | undefined;
  readableStepName?: string;
}

export function PageHitComponent({
  leads,
  pageHitData,
  leadQualifierData,
  welcomePageData,
  funnelPages
}: PageHitComponentProps) {
  pageHitData = pageHitData || [];
  leadQualifierData = leadQualifierData || [];
  welcomePageData = welcomePageData || [];

  // filter deleted lq trackings
  pageHitData = pageHitData.reduce((acc: PageHitDataInterface[], phd: PageHitDataInterface) => {
    const isLeadQualifier = phd.stepName.includes('leadQualifier_');

    if (!isLeadQualifier) return [...acc, phd];

    const lqId = parseInt(phd.stepName.replace('leadQualifier_', ''));
    const isAvailable = leadQualifierData.some(lqd => lqd.id === lqId);

    if (isAvailable) return [...acc, phd];
    return acc;
  }, []);

  pageHitData = pageHitData.filter(phd => phd.stepName !== 'thank-you-page');

  const data: ChartData[] = pageHitData.map((phd: PageHitDataInterface) => {
    return {
      name: phd.stepName,
      readableStepName: getReadableStepNameByPhd({
        phd,
        maxLength: 25,
        funnelPages,
        welcomePageData,
        leadQualifierData
      }),
      value: phd.hits,
      phd,
      lqd: phd.leadQualifierId
        ? leadQualifierData.find((lqd: LeadQualifierInterface) => lqd.id === phd.leadQualifierId)
        : undefined
    };
  });

  const highestValue = data[0]?.value;

  const BarLabel = ({ x, y, value }: BarLabelInterface) => {
    return (
      <text x={x} y={y} dy={-10} fontSize="16" fontFamily="sans-serif" fill="#999">
        {formatPercent({ value: +value, basevalue: highestValue })}
      </text>
    );
  };

  const CustomizedTooltip = ({ active, label }: any) => {
    const dataObject = data.find((d: any) => d.name === label);

    let additionalInformation = null;
    if (dataObject?.lqd) {
      const typesToSplit = ['multiple', 'radio'];
      const type = dataObject?.lqd?.type;

      if (typesToSplit.includes(type)) {
        const totalChoices = dataObject?.phd?.choiceArray?.reduce(
          (acc: number, cc: ChoiceCountInterface) => acc + cc.count,
          0
        );
        const choices = dataObject?.phd?.choiceArray;

        additionalInformation = (
          <React.Fragment>
            <p>
              <b>Frage</b>
              <br /> {dataObject?.lqd?.question}
            </p>

            {choices.length > 0 && (
              <p>
                <b>Antwortenaufteilung</b>
                <br />
                {choices?.map((cc: ChoiceCountInterface, index: number) => {
                  let choiceObject = dataObject?.lqd?.choices?.find(
                    (cd: LeadQualifierChoiceInterface) => cd.id === cc.choiceId
                  );
                  return (
                    choiceObject && (
                      <span key={index}>
                        {`${choiceObject.title}: ${parseFloat(
                          `${(cc.count / totalChoices) * 100}`
                        ).toFixed(2)}%`}{' '}
                        <br />
                      </span>
                    )
                  );
                })}
              </p>
            )}
          </React.Fragment>
        );
      } else {
        additionalInformation = (
          <p>
            <b>Frage</b>
            <br /> {dataObject?.lqd?.question}
          </p>
        );
      }
    }

    if (active) {
      return (
        <div className="page-hit-component__tooltip custom-tooltip">
          <p>
            <b>Seite</b>
            <br /> {dataObject?.readableStepName}
          </p>
          {additionalInformation}
          <p>
            <b>Besucher</b>
            <br /> {dataObject?.phd?.hits}
          </p>
          <p>
            <b>Hinweis</b>
            <br />
            Wenn du einen laufenden Funnel bearbeitest, kann die Reihenfolge oder der Wert
            abweichen.
          </p>
        </div>
      );
    }

    return null;
  };

  const CustomizedAxisTick = ({ x, y, payload }: any) => {
    const phd = pageHitData.find((phd: PageHitDataInterface) => phd.stepName === payload.value)!;
    const readableStepName = getReadableStepNameByPhd({
      phd,
      maxLength: 15,
      funnelPages,
      welcomePageData,
      leadQualifierData
    });

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={10} fill="#666">
          <tspan textAnchor="middle" x="0">
            {readableStepName}
          </tspan>
          {/* <tspan textAnchor="middle" x="0" dy="15">{`Besucher: ${phd.hits}`}</tspan> */}
        </text>
      </g>
    );
  };

  const isEmpty = pageHitData.length;

  return window.innerWidth >= 768 ? (
    <Card title="Besucherverhalten (Diagramm)">
      {isEmpty ? (
        <ResponsiveContainer width="100%" minHeight="300px">
          <BarChart data={data}>
            <defs>
              <linearGradient id="OrangeGardient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={'#23d3d3'} />
                <stop offset="100%" stopColor={'#1e266d'} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tick={<CustomizedAxisTick />} />
            <YAxis
              tickFormatter={(value: number) =>
                formatPercent({ value, basevalue: highestValue, roundToInt: true })
              }
              domain={[0, 'dataMax']}
            />
            <Tooltip content={<CustomizedTooltip />} />
            <Bar
              barSize={100}
              radius={[5, 5, 0, 0]}
              dataKey="value"
              fill="#OrangeGardient"
              fontFamily="sans-serif"
              label={BarLabel}
            />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <Empty description={AnalyticsMessages.noPageHitData} />
      )}
    </Card>
  ) : null;
}
