export enum PLAN_INTERVAL_TYPES {
  YEARLY = 'YEARLY',
  MONTHLY = 'MONTHLY',
  INVALID = 'INVALID'
}

const PLAN_INTERVAL_LABEL = {
  [PLAN_INTERVAL_TYPES.MONTHLY]: 'mtl.',
  [PLAN_INTERVAL_TYPES.YEARLY]: 'jährl.',
  [PLAN_INTERVAL_TYPES.INVALID]: ''
};

const monthlySubscriptionPlanIds = [754252, 754250, 754248, 638219, 638217, 637972, 597746];
const yearlySubscriptionPlanIds = [754251, 754249, 754247, 638220, 638218, 638214, 602947];

export const getPlanIntervalById = (planId?: string): PLAN_INTERVAL_TYPES => {
  if (!planId) return PLAN_INTERVAL_TYPES.MONTHLY;

  const isYearly = yearlySubscriptionPlanIds.includes(parseInt(planId));
  const isMonthly = monthlySubscriptionPlanIds.includes(parseInt(planId));

  if (!isMonthly && !isYearly) return PLAN_INTERVAL_TYPES.INVALID;

  if (isMonthly) return PLAN_INTERVAL_TYPES.MONTHLY;

  return PLAN_INTERVAL_TYPES.YEARLY;
};

export const getPlanIntervalLabelById = (planId: string) => {
  const interval = getPlanIntervalById(planId);
  return PLAN_INTERVAL_LABEL[interval];
};

export function formatLocalPrice(price?: number, locale = 'de-DE', currency = 'EUR') {
  if (!price) return '0,00€';

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency
  }).format(price);
}
