import { funnelBaseUrl } from '../../config/url';
import { useFunnel } from '../redux/builderSlice';

export default function useFunnelPublishUrl() {
  const { uniqueIdentifier, domain } = useFunnel();

  return domain && domain?.status === 'CONNECTED'
    ? domain.fullLink
    : funnelBaseUrl + uniqueIdentifier;
}
