import gql from 'graphql-tag';

export interface Input {
  bookingId: number;
  subject: string;
  html: string;
}

const SEND_APPLICANT_Notification = gql`
  mutation sendApplicantNotification($input: SendApplicantNotificationInput!) {
    sendApplicantNotification(input: $input) {
      status
    }
  }
`;

export default function sendApplicantNotification(input: Input) {
  return window.apolloClient.mutate({
    mutation: SEND_APPLICANT_Notification,
    variables: { input }
  });
}
