import React from 'react';
import { editIsJobSaved, useIsJobSaved } from '../../redux/jobBuilderSlice';
import { useDebounce } from '../../../helper/sharedFunctions';
import { useAppDispatch } from '../../../../redux/hooks';
import SaveIndicatorBase from '../../../components/ui/SaveIndicatorBase';
import { saveJobBuilderStateThunk } from '../../redux/thunk';

const JobSaveIndicator = () => {
  const isJobSaved = useIsJobSaved();
  const debouncedIsFunnelSaved = useDebounce(isJobSaved, 1000);

  const dispatch = useAppDispatch();

  const handleSave = () => {
    dispatch(saveJobBuilderStateThunk())
  };

  return (
    <SaveIndicatorBase
      onClickSave={() => handleSave()}
      isSaved={debouncedIsFunnelSaved}
      showDownloadTemplate={false}
      onPageOpenedInAnotherTab={() => dispatch(editIsJobSaved(false))}
    />
  );
};

export default JobSaveIndicator;
