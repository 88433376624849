import React, { useState } from 'react';
import { Button, Card, Tooltip, message } from 'antd';
import { CustomDomainsMessages } from '../../../config/messages';
import DeleteButtonWithConfirmation from '../../../SharedUI/components/DeleteButtonWithConfirmation';
import useRemoveWebhook from '../../../hooks/useRemoveWebhook';
import EditWebhook from './EditWebhook';
import { UserWebHookType } from '../../../hooks/useUserWebHooks';
import { AiOutlineEdit } from 'react-icons/ai';

const SingleWebhook = (props: UserWebHookType) => {
  const { id, url, partialFunnel } = props;
  const [deleteDomain, { loading }] = useRemoveWebhook();
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      {isEditing ? (
        <EditWebhook
          {...props}
          handleClose={() => {
            setIsEditing(false);
          }}
        />
      ) : (
        <Card
          className="ui__white-round-wrapper parent-hover-child-visible"
          style={{ marginBottom: '1em' }}
        >
          <div className="d-flex justify-content-between">
            <div className="webhook-link">
              <h3>{url}</h3>
              <p>{partialFunnel?.title}</p>
            </div>
            <div className="parent-hover-child-visible__child ">
              <div className="d-flex flex-column">
                <Button
                data-testid="set-edit-from-active"
                  className="m-0 p-0"
                  type="link"
                  onClick={() => {
                    setIsEditing(true);
                  }}
                >
                  <AiOutlineEdit /> Bearbeiten
                </Button>

                <DeleteButtonWithConfirmation
                  loading={loading}
                  onDelete={() =>
                    deleteDomain({ variables: { id } }).catch(() =>
                      message.error(CustomDomainsMessages.couldNotDeleteDomain)
                    )
                  }
                />
              </div>
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

export default SingleWebhook;
