import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppSelector } from '../../../redux/hooks';
import { getCompleteJobBuilder, saveJobBuilderStateThunk, unPublishJobThunk } from './thunk';
import { FunnelJobPostStatus } from '../../graphql/setFunnelJobPost';
import { JobNotificationType } from '../../graphql/setFunnelJobPostForIndexing';

export interface Job {
  id: number;
  craftState: string;
  platform: 'GOOGLE_JOBS';
  validThrough: string;
  status?: FunnelJobPostStatus;
}
export interface JobTemplate {
  id: number;
  name: string;
  craftState: string;
  imageUrl: string;
}

export interface JobBuilderStateType {
  job: Job;
  isJobSaved: boolean;
  shouldSaveJob: boolean;
  shouldReplaceJobPlaceHolders: boolean;
  loaders: {
    jobBuilderStateFetch: boolean;
    jobBuilderStateSave: boolean;
    jobTemplateFetch: boolean;
    jobUnPublishStateSave: boolean;
    creatingTextMediaTemplate: boolean;
    deletingTextMediaTemplate: boolean;
    deletingJobTemplate: boolean;
  };
}

const defaultCraftState =
  'eyJST09UIjp7InR5cGXECHJlc29sdmVkTmFtZSI6Ik1haW5Db250YWluZXIifSwiaXNDYW52YXMiOnRydWUsInByb3BzxDlkYXRhLWN5Ijoicm9vdC1jzDVkaXNwbGF51VQsImN1c3RvbSI6e30sImhpZGRlbiI6ZmFsc2UsIm5vZGVzIjpbIjdmZGcyYzZaTjQiXSwibGlua2VkTsYde319LMwg+gDNQWRk9wDMx2/pAM1pZCI6IjE2NjQxMDY5NzExMDLyAMfNTu4AxnBhcmVudCI65gFe+QDW8wDKfQ==';

const INITIAL_STATE: JobBuilderStateType = {
  job: {
    id: 0,
    craftState: '',
    validThrough: '',
    platform: 'GOOGLE_JOBS'
  },
  isJobSaved: true,
  shouldSaveJob: true,
  shouldReplaceJobPlaceHolders: false,
  loaders: {
    jobBuilderStateFetch: true,
    jobBuilderStateSave: false,
    jobTemplateFetch: false,
    jobUnPublishStateSave: false,
    creatingTextMediaTemplate: false,
    deletingTextMediaTemplate: false,
    deletingJobTemplate: false
  }
};

export const jobBuilderUiSlice = createSlice({
  name: 'jobBuilderUI',
  initialState: INITIAL_STATE,
  reducers: {
    resetToInitialJobBuilderState: state => {
      return INITIAL_STATE;
    },
    setCraftState: (state, { payload }: PayloadAction<{ craftState: string }>) => {
      state.job.craftState = payload.craftState;
    },
    editIsJobSaved: (state, { payload }: PayloadAction<boolean>) => {
      state.isJobSaved = payload;
    },
    setShouldSaveJob: (state, { payload }: PayloadAction<boolean>) => {
      state.shouldSaveJob = payload;
    },
    setShouldReplaceJobPlaceHolders: (state, { payload }: PayloadAction<boolean>) => {
      state.shouldReplaceJobPlaceHolders = payload;
    }
  },
  extraReducers: {
    // @ts-ignore
    [getCompleteJobBuilder.fulfilled]: (
      state: JobBuilderStateType,
      action: PayloadAction<{
        getFunnelJobPost: Job;
      }>
    ) => {
      state.job = action.payload.getFunnelJobPost;
      state.loaders.jobBuilderStateFetch = false;
    },
    //@ts-ignore
    [getCompleteJobBuilder.pending]: (state: JobBuilderStateType) => {
      state.loaders.jobBuilderStateFetch = true;
    },
    // @ts-ignore
    [getCompleteJobBuilder.rejected]: (state: JobBuilderStateType) => {
      state.loaders.jobBuilderStateFetch = false;
    },
    // @ts-ignore
    [saveJobBuilderStateThunk.fulfilled]: (
      state: JobBuilderStateType,
      action: PayloadAction<Job>
    ) => {
      if (!state.shouldSaveJob) return;

      state.job.craftState = action.payload.craftState;
      state.job.validThrough = action.payload.validThrough;
      state.job.status = action.payload.status;
      state.loaders.jobBuilderStateSave = false;
      state.isJobSaved = true;
    },
    //@ts-ignore
    [saveJobBuilderStateThunk.pending]: (state: JobBuilderStateType) => {
      if (!state.shouldSaveJob) return;

      state.loaders.jobBuilderStateSave = true;
    },
    // @ts-ignore
    [saveJobBuilderStateThunk.rejected]: (state: JobBuilderStateType) => {
      state.loaders.jobBuilderStateSave = false;
    },
    // @ts-ignore
    [unPublishJobThunk.fulfilled]: (
      state: JobBuilderStateType,
      action: PayloadAction<{ type: JobNotificationType; status: FunnelJobPostStatus }>
    ) => {
      state.job.status = action.payload.status;
      state.loaders.jobUnPublishStateSave = false;
    },
    //@ts-ignore
    [unPublishJobThunk.pending]: (state: JobBuilderStateType) => {
      state.loaders.jobUnPublishStateSave = true;
    },
    // @ts-ignore
    [unPublishJobThunk.rejected]: (state: JobBuilderStateType) => {
      state.loaders.jobUnPublishStateSave = false;
    }
  }
});
export const {
  setCraftState,
  editIsJobSaved,
  setShouldSaveJob,
  resetToInitialJobBuilderState,
  setShouldReplaceJobPlaceHolders
} = jobBuilderUiSlice.actions;

export const useJob = (): Job => {
  return useAppSelector(state => state.jobBuilderUI.job);
};

export const useJobBuilderLoading = (): boolean => {
  return useAppSelector(state => state.jobBuilderUI.loaders.jobBuilderStateFetch);
};

export const useSaveJobBuilderLoading = (): boolean => {
  return useAppSelector(state => state.jobBuilderUI.loaders.jobBuilderStateSave);
};

export const useUnPublishJobBuilderLoading = (): boolean => {
  return useAppSelector(state => state.jobBuilderUI.loaders.jobUnPublishStateSave);
};

export const useIsJobSaved = (): boolean => {
  return useAppSelector(state => state.jobBuilderUI.isJobSaved);
};

export const useShouldSaveJob = (): boolean => {
  return useAppSelector(state => state.jobBuilderUI.shouldSaveJob);
};
export const useShouldReplaceJobPlaceHolders = (): boolean => {
  return useAppSelector(state => state.jobBuilderUI.shouldReplaceJobPlaceHolders);
};

export default jobBuilderUiSlice.reducer;
