export enum CRAFT_ELEMENTS {
  BODY = 'Body',
  IMAGE = 'Image',
  COLORIZED_BANNER = 'ColorizedBanner',
  CONTAINER = 'Container',
  MAIN_CONTAINER = 'MainContainer',
  RICH_TEXT = 'RichText',
  PLAIN_TEXT = 'PlainText',
  ADD_CONTAINER = 'AddContainer',
  ELEMENT_APPENDER = 'ElementAppender',
  MEDIA_CONTENT = 'MediaContent',
  FOOTER = 'Footer'
}
