import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { ImageType } from '../utils/LibraryModels';
import {
  getAllImagesTagsThunk,
  getImagesWithPaginationThunk,
  removeImageFromGalleryThunk,
  updateImageFromGalleryThunk
} from './libraryThunk';

type Images = { images: ImageType[]; total: number; page: number };

export interface FunnelsState {
  allImages: Images;
  allTags: Array<{ id: number; name: string }>;
  loaders: {
    images: boolean;
    tags: boolean;
  };
  errors: {
    images: string;
    tags: string;
  };
  imageSearchValue: string;
}

const INITIAL_STATE: FunnelsState = {
  allImages: { images: [], total: 0, page: 0 },
  allTags: [],
  loaders: {
    images: false,
    tags: false
  },
  errors: {
    images: '',
    tags: ''
  },
  imageSearchValue: ''
};

export const librarySlice = createSlice({
  name: 'library',
  initialState: INITIAL_STATE,
  reducers: {
    resetToInitialLibraryState: () => {
      return INITIAL_STATE;
    },
    setImageSearchValue: (state, action: PayloadAction<string>) => {
      state.imageSearchValue = action.payload;
    },
    handleEmptyLibraryRedux: state => {
      state.allImages = {} as Images;
      state.allTags = [];
    },
    handleAddFolder: (state, action: PayloadAction<{ folder: any }>) => {
      state.allTags.push(action.payload.folder);
    }
  },
  extraReducers: {
    // @ts-ignore
    [getImagesWithPaginationThunk.pending]: (state: FunnelsState) => {
      state.loaders.images = true;
    },
    // @ts-ignore
    [getImagesWithPaginationThunk.fulfilled]: (state: FunnelsState, action: any) => {
      if (action.payload.page === 1) {
        state.allImages.images = action.payload.images;
      } else {
        state.allImages.images = [...state.allImages.images, ...action.payload.images];
      }
      state.allImages.total = action.payload.total;
      state.allImages.page = action.payload.page;
      state.loaders.images = false;
    },
    // @ts-ignore
    [getImagesWithPaginationThunk.rejected]: (state: FunnelsState) => {
      state.errors.images = 'Etwas ist schief gelaufen. Bitte versuche es später noch einmal.';
      state.loaders.images = false;
    },
    // @ts-ignore
    [getAllImagesTagsThunk.pending]: (state: FunnelsState) => {
      state.loaders.tags = true;
    },
    // @ts-ignore
    [getAllImagesTagsThunk.fulfilled]: (state: FunnelsState, action: any) => {
      state.allTags = action.payload;
      state.loaders.tags = false;
    },
    // @ts-ignore
    [getAllImagesTagsThunk.rejected]: (state: FunnelsState) => {
      state.errors.tags = 'Could not get all images tags';
      state.loaders.tags = false;
    },
    // @ts-ignore
    [removeImageFromGalleryThunk.fulfilled]: (
      state: FunnelsState,
      action: PayloadAction<{ id: number }>
    ) => {
      state.allImages.images = state.allImages.images.filter(ele => ele.id !== action.payload.id);
      state.allImages.total = state.allImages.total - 1;
    },
    // @ts-ignore
    [updateImageFromGalleryThunk.fulfilled]: (
      state: FunnelsState,
      action: PayloadAction<{ id: number; name: string; tags: string[] }>
    ) => {
      const currentImage = state.allImages.images.find(ele => ele.id === action.payload.id);
      if (currentImage) {
        currentImage.name = action.payload.name;
        currentImage.tags = action.payload.tags;
      }
    }
  }
});

export const {
  resetToInitialLibraryState,
  setImageSearchValue,
  handleEmptyLibraryRedux,
  handleAddFolder
} = librarySlice.actions;

export const useImageSearchValue = () =>
  useAppSelector((state: RootState) => state.library.imageSearchValue);

export const useAllLibraryImages = () =>
  useAppSelector((state: RootState) => state.library.allImages);
export const useAllLibraryImagesLoading = () =>
  useAppSelector((state: RootState) => state.library.loaders.images);

export const useAllTags = () => useAppSelector((state: RootState) => state.library.allTags);
export const useAllTagsLoading = () =>
  useAppSelector((state: RootState) => state.library.loaders.tags);

export const useAllLibraryImagesError = () =>
  useAppSelector((state: RootState) => state.library.errors.images);
export const useAllTagsError = () =>
  useAppSelector((state: RootState) => state.library.errors.tags);

export default librarySlice.reducer;
