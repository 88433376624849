import React, { useEffect, useState } from 'react';
import { Modal, Button, Result, Typography, Popover, Table } from 'antd';
import ApplicantNotificationModal from './ApplicantNotificationModal';
import DefaultLoader from '../../../SharedUI/components/DefaultLoader';
import dateFormats from '../../../config/dateFormats';
import {
  BookingActionEventInterface,
  BookingInterface,
  TrackingInterface
} from '../../container/Events';
import moment from 'moment';
import {
  GET_BOOKINGS_ACTIONS_EVENTS,
  setCreateBookingActionEvents
} from '../../graphql/bookingQueries';
import { InfoCircleOutlined } from '@ant-design/icons';
import { NotReachedMessages } from '../../../config/messages';
import NotReachedTable from './NotReachedTable';
import { useQuery } from '@apollo/react-hooks';
import { ActionEvents } from '../../../Builder/hooks/useBookings';
import { FaPhone, FaPhoneSlash } from 'react-icons/fa';
import { applicantNotificationTypeEnum } from '../../../Builder/components/emailTemplate/utils';

function NotReachedModal({
  visible,
  setVisibility,
  eventId,
  selectedEvent,
  setSelectedEvent,
  handleUpdateBookingEvents
}: {
  visible: boolean;
  setVisibility: (visible: boolean) => void;
  eventId: number;
  selectedEvent: { index: any; event: BookingInterface | TrackingInterface } | undefined;
  setSelectedEvent: Function;
  handleUpdateBookingEvents?: (eventId: number, bookingEvents: BookingActionEventInterface) => void;
}) {
  const [
    setCreateBookingActionEvent,
    { loading: notReachedTimeLoading }
  ] = setCreateBookingActionEvents();
  const [bookingEvents, setBookingEvent] = useState<BookingActionEventInterface[]>([]);
  const { bookingActionEvent } = selectedEvent?.event as BookingInterface;
  const { data, loading: getBookkingLoading, error } = useQuery(GET_BOOKINGS_ACTIONS_EVENTS, {
    variables: {
      bookingId: selectedEvent?.event.id
    },
    fetchPolicy: 'cache-and-network'
  });

  const showReachedTimeButton =
    bookingEvents?.[(bookingEvents?.length || 1) - 1]?.actionEventType ===
    ActionEvents.LAST_NOT_REACHED_TIME;

  useEffect(() => {
    setBookingEvent(data?.getBookingActionEvents);
  }, [data]);

  const handleCancel: () => void = () => {
    setVisibility(false);
  };
  const handleReachedStatusTime = async (actionEvent: ActionEvents) => {
    const createBookingActionEvents = await setCreateBookingActionEvent({
      variables: { input: { bookingId: selectedEvent?.event.id, actionEventType: actionEvent } }
    });
    const createBookingEvents = createBookingActionEvents?.data?.createBookingActionEvent;

    setBookingEvent([...bookingEvents, createBookingEvents]);
    handleUpdateBookingEvents?.(selectedEvent?.event?.id as number, createBookingEvents);

    await setSelectedEvent({
      event: {
        ...selectedEvent?.event,
        [`${bookingActionEvent?.eventTime}`]: createBookingEvents?.eventTime
      }
    });
  };

  return (
    <React.Fragment>
      <div>
        <div className="d-flex flex-row alignitems-center align-items-center">
          <Typography.Title level={4}>Telefonate</Typography.Title>
          <Popover
            className="default-popover"
            content={
              <div className="default-popover__content" style={{ maxWidth: 400 }}>
                {NotReachedMessages.info}
              </div>
            }
            trigger="hover"
          >
            <InfoCircleOutlined style={{ marginTop: '-6px' }} />
          </Popover>
        </div>
        {/* {showReachedTimeButton && ( */}
        <Button
          className="button-green"
          icon={<FaPhone />}
          loading={getBookkingLoading}
          onClick={() => handleReachedStatusTime(ActionEvents.REACHED_TIME)}
        >
          Erreicht
        </Button>
        {/* )} */}
        <Button
          className="mx-2"
          icon={<FaPhoneSlash />}
          loading={false}
          onClick={() => setVisibility(true)}
          danger
        >
          Nicht erreicht
        </Button>
        <DefaultLoader loading={notReachedTimeLoading} />
        <NotReachedTable bookingEvents={bookingEvents} loading={getBookkingLoading} />
      </div>
      <Modal
        width={500}
        visible={visible}
        destroyOnClose
        onCancel={handleCancel}
        className="mb-6 decline-applicant"
        footer={null}
      >
        <Result
          icon={<FaPhoneSlash className="red-text" style={{ fontSize: '60px' }} />}
          title="Möchtest du eine Followup-Nachricht versenden?"
          subTitle={
            <p>
              Anschließend öffnet sich eine Vorschau dort kannst du die im Funnel hinterlege Vorlage
              ansehen und absenden. Im Falle einer E-Mail lässt sich diese zusätzlich anpassen.
            </p>
          }
          extra={[
            <>
              <ApplicantNotificationModal
                eventId={eventId}
                trigger={<Button type="primary">Ja, Nachricht senden</Button>}
                setConfirmModalVisibility={setVisibility}
                applicantNotificationType={applicantNotificationTypeEnum.NOT_REACHED}
                handleNotReachedTime={handleReachedStatusTime}
              />
              <Button
                type="link"
                className="d-block w-100"
                onClick={() => {
                  handleReachedStatusTime(ActionEvents.LAST_NOT_REACHED_TIME);
                  handleCancel();
                }}
              >
                Nein, nur als 'nicht erreicht' markieren
              </Button>
            </>
          ]}
        />
      </Modal>
    </React.Fragment>
  );
}

export default NotReachedModal;
