import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { InputGroup } from 'react-bootstrap';
import gql from 'graphql-tag';
import { getIpAndUserAgent } from '../../../../helper/contactForm';

const GET_LOCATION = gql`
  query getClientLocation($clientIp: String!) {
    getClientLocation(clientIp: $clientIp) {
      countryCode
    }
  }
`;

export default ({ field, form, wrapperClassName = 'mb-3', ...props }: any) => {
  const [countryCode, setCountryCode] = useState('');
  useEffect(() => {
    if (!props.value) {
      getIpAndUserAgent(data => {
        if (!data?.clientIp) return;
        window.apolloClient
          .query({
            query: GET_LOCATION,
            variables: { clientIp: data.clientIp }
          })
          .then((response: any) => {
            const { getClientLocation } = response?.data || {};
            setCountryCode(getClientLocation?.countryCode || '');
          })
          .catch(() => {});
      });
    }
  }, []);

  return (
    <InputGroup className={`${wrapperClassName}`}>
      <PhoneInput
        {...props}
        enableLongNumbers
        countryCodeEditable={false}
        country={(countryCode || 'de')?.toLocaleLowerCase()}
        preferredCountries={['de', 'ch', 'at']}
        onChange={value => {
          if (typeof props.onChange === 'function') {
            const phoneVal = value.startsWith('+') ? value : `+${value}`;
            props.onChange(phoneVal);
          }
        }}
      />
    </InputGroup>
  );
};
