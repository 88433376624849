import React from 'react';
import { SingleSettingRendererProps } from '../Settings/SingleSettingRenderer';
import { getElementColor } from '../../helper/craftJs';
import { setDesignSettingsTabView, useCurrentlyEditingTheme } from '../../redux/builderSlice';
import { useAppDispatch } from '../../../redux/hooks';
import { DesignSettingsTabView } from '../../interfaces/builderSliceTypes';
import { message } from 'antd';

export const RouteToGradient = ({ element, onChange, values }: SingleSettingRendererProps) => {
  const theme = useCurrentlyEditingTheme();
  const dispatch = useAppDispatch();
  return (
    <div
      className="route-to-gradient"
      style={{
        background: getElementColor(values?.[element.key], theme)
      }}
      onClick={() => {
        message.warn('Bitte bearbeite deinen Verlauf in deinem Farbschema.');
        dispatch(setDesignSettingsTabView(DesignSettingsTabView.COLORS));
      }}
    />
  );
};
