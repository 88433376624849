import React from 'react';
import { Select, Alert } from 'antd';
import { BorderOutlined } from '@ant-design/icons';
import BuilderSettingsTitle from '../../../Builder/components/sharedUI/BuilderSettingsTitle';

export interface CalendarInterface {
  id: string;
  primary: string;
  summary: string;
  summaryOverride: string;
  backgroundColor: string;
  foregroundColor: string;
}

interface CalendarSelectorProps {
  calendarId: string;
  calendars: CalendarInterface[];
  handleChangeCalendarId: Function;
  classes?: string;
}

export function CalendarSelector({
  calendarId,
  calendars,
  handleChangeCalendarId,
  classes
}: CalendarSelectorProps) {
  if (calendars && !calendars.length) {
    return (
      <Alert
        className="mt-0"
        message={
          <span>
            Kein Kalender verfügbar. Nachdem du deinen Funnel erstellt hast, kannst du deinen
            Kalender unter Connect hinzufügen. Wenn du dies bereits getan hast, aktiviere bitte
            deinen{' '}
            <a href="https://calendar.google.com/calendar" target="_blank">
              Google-Calender
            </a>{' '}
            indem du ihn einmal aufrufst.
          </span>
        }
        type="warning"
        showIcon
      />
    );
  } else {
    return (
      <>
        <BuilderSettingsTitle title="Google-Kalender" />
        <Select
          value={calendarId || "Standard"}
          onChange={calendarId => handleChangeCalendarId(calendarId)}
          className={classes}
        >
          {(calendars || []).map((calendar: CalendarInterface) => (
            <Select.Option value={calendar.id} key={`menu_item_id_${calendar.id}`}>
              <BorderOutlined style={{ backgroundColor: calendar.backgroundColor }} />
              {` ${calendar.summaryOverride || calendar.summary}`}
            </Select.Option>
          ))}
        </Select>
      </>
    );
  }
}
