import { message } from "antd";
import { BrowserActions } from "../config/messages";

export const copyStringToClipboard = (str: string) => {
    var el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = "absolute"
    el.style.left = "-9999px"
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    message.success(BrowserActions.copySuccess)
}