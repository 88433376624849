import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export const GET_FUNNEL_FOLDERS = gql`
  query getFunnelFolders {
    getFunnelFolders {
      id
      name
    }
  }
`;

export default function useGetAllFunnelFolders() {
  return useQuery(GET_FUNNEL_FOLDERS, {
    fetchPolicy: 'no-cache'
  });
}

export function getAllFunnelFolders() {
  return window.apolloClient.query({
    query: GET_FUNNEL_FOLDERS,
    fetchPolicy: 'no-cache'
  });
}
