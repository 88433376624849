export interface RawVideoInterface {
  video_id: number;
  title: string;
  description: string;
  url: string;
}

export interface FullVideoInterface {
  module_id: number;
  video_id: number;
  title: string;
  description: string;
  url: string;
  duration: number;
  points: number;
  playedSeconds?: number;
  completed: boolean;
}

export interface Module {
  id: number;
  badge: string;
  title: string;
  tag: TAG_TYPES;
  description: string;
  videos: FullVideoInterface[];
}

export enum TAG_TYPES {
  NEWBIE = 'Newbie',
  INTERMEDIATE = 'Intermediate',
  ENTHUSIAST = 'Enthusiast',
  EXPERT = 'Expert',
  PRO = 'Pro',
  TOP_NOTCH = 'Top-Notch',
  LINKEDIN_PRO = 'LinkedIn Pro'
}

export const videos: FullVideoInterface[] = [
  {
    module_id: 1,
    video_id: 1,
    title: '1. Begrüßung & Überblick',
    description: `<p>Hier startet deine Reise in die Welt des Social-Recruiting - beginnend mit: “Was ist überhaupt ‘Social-Recruiting’?”. Wir freuen uns, dich begrüßen zu dürfen, und möchten dir in diesem Video einen Überblick über alle kommenden Inhalte dieses Moduls geben.</p>
    <p>Deine Ziel für dieses Modul: “Social Recruiting” in der Theorie vollständig verstehen.</p>`,
    url: 'https://vimeo.com/924556412',
    duration: 112,
    points: 1,
    completed: false
  },
  {
    module_id: 1,
    video_id: 2,
    title: '2. in 2 Minuten Social-Recruiting verstehen',
    description: `<p>Verstehe, wie Social-Recruiting funktioniert. In nur 2 Minuten. Mit diesem Video startet alles. Du wirst jedes einzelne Detail in allen darauffolgenden Modulen erlernen, sodass du in wenigen Stunden bereits bestens ausgestattet bist, um Mitarbeiter zu gewinnen - unabhängig von herkömmlichen Recruiting-Maßnahmen.</p>`,
    url: 'https://vimeo.com/924546922/0a372ced40',
    duration: 91,
    points: 1,
    completed: false
  },
  {
    module_id: 1,
    video_id: 3,
    title: '3. Sofort Unterstützung erhalten. So geht’s',
    description: `<p>Auf dem Weg dahin, dass du der nächste Social-Recruiting-Experte wirst, sind wir fest an deiner Seite. Du erhältst von uns direkt 3 Möglichkeiten, wie du sofort Unterstützung erhältst - egal welche Fragen du hast. Genauso zeigen wir dir die Möglichkeit, die Software MEETOVO weiterzuentwickeln mit deinen Ideen und Wünschen.</p>`,
    url: 'https://vimeo.com/924555726',
    duration: 204,
    points: 1,
    completed: false
  },
  {
    module_id: 1,
    video_id: 4,
    title: '4. Social-Recruiting: Mit diesen Ausgaben hast du zu rechnen',
    description: `<p>Social-Recruiting ist in der Regel günstiger als herkömmliche Recruiting-Maßnahmen, wenn man weiß wie. Dafür ist es wichtig zu verstehen, mit welchen Ausgaben man zu rechnen hat. Dies wird in diesem Video exakt aufgeschlüsselt.</p>`,
    url: 'https://vimeo.com/924555709',
    duration: 420,
    points: 1,
    completed: false
  },
  {
    module_id: 1,
    video_id: 5,
    title: '5. In welchen Branchen funktioniert Social-Recruiting?',
    description: `<p>Spoiler: In jeder Branche funktioniert Social-Recruiting. Erfahre in diesem Video, warum und unter welchen Bedingungen dies passiert</p>`,
    url: 'https://vimeo.com/924555693',
    duration: 265,
    points: 1,
    completed: false
  },
  {
    module_id: 1,
    video_id: 6,
    title: '6. Das Recruiting-Flywheel - so aktivierst du die Aufwärtsspirale',
    description: `<p>Denke nicht linear, sondern in einem selbstbefeuernden Prozess - dem Recruiting-Flywheel. Lerne dies in einem Video kennen und verstehe, wie du den Kreislauf: Anziehen, Qualifizieren, Einstellen befeuern kannst.</p>`,
    url: 'https://vimeo.com/928747156',
    duration: 112,
    points: 1,
    completed: false
  },
  {
    module_id: 1,
    video_id: 7,
    title: '7. Deinen MEETOVO-Account mit Mitarbeitern teilen (oder Kunden mit dir)',
    description: `<p>Erfahre, wie du deinen MEETOVO-Account deinen Mitarbeitern freigeben kannst, sodass diese Funnels erstellen und Bewerbungen verwalten können. Als Agentur kannst du dies deinen Kunden mitgeben, falls du für die Umsetzung der Kunden-Accounts zuständig bist</p>`,
    url: 'https://vimeo.com/924555738',
    duration: 366,
    points: 1,
    completed: false
  },
  {
    module_id: 2,
    video_id: 8,
    title: '1. Recruiting-Funnel: Einführung & Überblick',
    description: `<p>Es geht an die Erstellung deines ersten Recruiting-Funnels, inkl. Qualifizierung und Nurturing. In diesem Video möchten wir dir einen Überblick über alle kommenden Inhalte dieses Moduls geben.Dein Ziel für dieses Modul: Dein eigener fertiggestellter Recruiting-Funnel</p>`,
    url: 'https://vimeo.com/924561163',
    duration: 160,
    points: 1,
    completed: false
  },
  {
    module_id: 2,
    video_id: 9,
    title: '2. Recruiting-Flywheel: Qualifizieren',
    description: `<p>In diesem Video lernst du, wie der Teil “Qualifizieren” im Recruiting-Flywheel zu verstehen ist und wie du es richtig aufsetzt in den folgenden Videos</p>`,
    url: 'https://vimeo.com/928747381',
    duration: 138,
    points: 1,
    completed: false
  },
  {
    module_id: 2,
    video_id: 10,
    title: '3. Funnel-Vorlagen verstehen (und ersten Funnel laden)',
    description: `<p>MEETOVO bietet in verschiedenen Branchen unterschiedliche Vorlagen an, die du sofort laden und anpassen kannst. Verstehe, wie das funktioniert und für welche Zwecke die unterschiedlichen Vorlagen eingesetzt werden könnten</p>`,
    url: 'https://vimeo.com/924561236',
    duration: 295,
    points: 1,
    completed: false
  },
  {
    module_id: 2,
    video_id: 11,
    title: '4. Funnel-Builder und Funktionen in der Praxis (inkl. Tipps & Tricks)',
    description: `<p>Mit dem MEETOVO-Funnel-Builder hast du ein einfaches, aber umfangreiches Tool, das es dir ermöglicht, innerhalb von Minuten Recruiting-Funnels zu bauen, ohne Technikkenntnisse. Erfahre, wie du mit dem Builder und diversen Funktionen effizient arbeiten kannst.</p>`,
    url: 'https://vimeo.com/924561103',
    duration: 1274,
    points: 2,
    completed: false
  },
  {
    module_id: 2,
    video_id: 12,
    title: '5. Bewerber Persona: Definition, Zielgruppenrecherche und Umsetzung',
    description: `<p>Die Basis jeder guten Kampagne ist gutes Zielgruppenverständnis. Dabei hilft dir eine Bewerber Persona. Mithilfe des Onboarding-Funnels kannst du direkt Informationen zum Unternehmen und gewünschten Mitarbeiter einsammeln, abgerundet von eigener Zielgruppenrecherche ergibt sich deine Bewerber Persona. Erfahre wie du dies für dich umsetzen kannst.</p>`,
    url: 'https://vimeo.com/924561054',
    duration: 1590,
    points: 3,
    completed: false
  },
  {
    module_id: 2,
    video_id: 13,
    title: '6. Recruiting-Funnel erstellen: Job & Unternehmen präsentieren (1/3)',
    description: `<p>Wenn du weißt, wie du den Job darstellen kannst, dass sich mehr potenzielle Mitarbeiter angesprochen fühlen, werden deine Kampagnen erfolgreicher. Erfahre Tipps & Tricks, sowie die ersten gemeinsamen Klicks für unseren Recruiting-Funnel.</p>`,
    url: 'https://vimeo.com/924561216',
    duration: 1323,
    points: 2,
    completed: false
  },
  {
    module_id: 2,
    video_id: 14,
    title: '7. Recruiting-Funnel erstellen: Richtig qualifizieren (2/3)',
    description: `<p>Nur mit gezielter Qualifizierung erhältst du die Kontaktinformationen der Bewerber, die du auch wirklich haben willst. Erfahre wie du interaktiv qualifizieren kannst</p>`,
    url: 'https://vimeo.com/924561193',
    duration: 1789,
    points: 2,
    completed: false
  },
  {
    module_id: 2,
    video_id: 15,
    title: '8. Recruiting-Funnel erstellen: (automatische) Nachrichten versenden (3/3)',
    description: `<p>Nurturing sorgt für weitere Informationen, die potenzielle Mitarbeiter während des Recruiting-Prozesses benötigen. Das können völlig automatische Nachrichten (E-Mail oder SMS) sein, die nach einen bestimmten Ereignis versendet werden müssen oder teilautomatisierte Nachrichten, die du selbst auslösen kannst. Erfahre wie du deine Nachrichten-Kommunikation einrichtest.</p>`,
    url: 'https://vimeo.com/924561127',
    duration: 958,
    points: 2,
    completed: false
  },
  {
    module_id: 3,
    video_id: 16,
    title: '1. Anzeigen: Einführung & Überblick',
    description: `<p>Es geht an die Umsetzung deiner ersten Werbeanzeigen. In diesem Video möchten wir dir einen Überblick über alle kommenden Inhalte dieses Moduls geben.</p>
    <p>Dein Ziel für dieses Modul: Fertige Werbeanzeigen einsatzbereit</p>`,
    url: 'https://vimeo.com/924563758',
    duration: 124,
    points: 1,
    completed: false
  },
  {
    module_id: 3,
    video_id: 17,
    title: '2.  Recruiting-Flywheel: “Anziehen”',
    description: `<p>In diesem Video lernst du, wie der Teil “Anziehen” im Recruiting-Flywheel zu verstehen ist und wie du es richtig aufsetzt in den folgenden Videos</p>`,
    url: 'https://vimeo.com/928748450',
    duration: 277,
    points: 1,
    completed: false
  },
  {
    module_id: 3,
    video_id: 18,
    title: '3.  Werbetexte (= Copies) verstehen',
    description: `<p>Bevor du deinen ersten Werbetext schreibst, musst du einmal die Theorie hinter guten Werbetexten verstehen lernen. Erfahre, worauf es ankommt, wenn du deine ersten Werbetexte (= Copies) schreibst, um Mitarbeiter zu gewinnen</p>`,
    url: 'https://vimeo.com/924563809',
    duration: 511,
    points: 2,
    completed: false
  },
  {
    module_id: 3,
    video_id: 19,
    title: '4.  Erste Copies schreiben in der Praxis',
    description: `<p>Schau uns über die Schulter, wie wir mithilfe des MEETOVO Ad-Builders Copies schreiben. Nutze dieses Wissen, um danach deine eigenen ersten Copies zu schreiben</p>`,
    url: 'https://vimeo.com/924563796',
    duration: 720,
    points: 1,
    completed: false
  },
  {
    module_id: 3,
    video_id: 20,
    title: '5.  Werbegrafiken (= Creatives) verstehen',
    description: `<p>Bevor deine erste Werbegrafik entsteht, musst du einmal die Theorie hinter guten Creatives verstehen lernen und wie diese erstellt werden können. Erfahre, worauf du achten musst und wie du am besten arbeiten kannst.</p>
    <p>Lade dir dein Auswertungsdokument herunter, das dir für die spätere “Optimierung” hilft :</p>
    <p>Bitte drücke auf “Datei” und “Kopie erstellen”, um es abzuspeichern</p>
    <p>
    <label>Relevante Links:</label><br />
    <ul class="light-list">
    <a target="_blank" href="https://docs.google.com/spreadsheets/d/1A1dDnu5s5Qv4oGhyB6GQWO2JcgBQujTK-I81NwlfDgw/edit"><li>Testprotokoll</li></a>
    </ul>
    </p>
    `,
    url: 'https://vimeo.com/924563715',
    duration: 604,
    points: 2,
    completed: false
  },
  {
    module_id: 3,
    video_id: 21,
    title: '6. 	Erste Creatives in der Praxis erstellen, ohne Designkenntnisse',
    description: `<p>Schau uns über die Schulter, wie wir mithilfe des MEETOVO Ad-Builders Creatives erstellen. Nutze dieses Wissen, um innerhalb weniger Minuten eigene Creatives zu erstellen, ohne Photoshop oder Designkenntnisse</p>`,
    url: 'https://vimeo.com/924563743',
    duration: 563,
    points: 1,
    completed: false
  },
  {
    module_id: 3,
    video_id: 22,
    title: '7. 	Google Jobs Anzeigen: So werden sie erstellt',
    description: `<p>Erfahre wie du Google Jobs Anzeigen vorbereitest und schreibst, um alle Vorbereitungen gemacht zu haben, um diese im nächsten Schritt zu veröffentlichen.</p>`,
    url: 'https://vimeo.com/928748660',
    duration: 447,
    points: 1,
    completed: false
  },
  {
    module_id: 4,
    video_id: 23,
    title: '1.  Veröffentlichung: Einführung & Überblick',
    description: `<p>Es geht an die Veröffentlichung deiner ersten Kampagne. In diesem Video möchten wir dir einen Überblick über alle kommenden Inhalte dieses Moduls geben.</p>
    <p>Dein Ziel für dieses Modul: Erledigte Vorbereitung und folglich veröffentlichte Anzeigen</p>`,
    url: 'https://vimeo.com/924580460',
    duration: 107,
    points: 1,
    completed: false
  },
  {
    module_id: 4,
    video_id: 24,
    title: '2.  Google Jobs Anzeigen veröffentlichen',
    description: `<p>Erfahre, wie du die Google Jobs Anzeigen veröffentlichst und was dabei zu beachten ist. Genauso wie die Indexierung realisiert und die Anzeigen auffindbar sind.</p>`,
    url: 'https://vimeo.com/928748837',
    duration: 210,
    points: 1,
    completed: false
  },
  {
    module_id: 4,
    video_id: 25,
    title: '3.  Vorhandene Karriereseite als Besucherstrom: Karriere-Widgets',
    description: `<p>Erfahre, wie du deine vorhandene Karriereseite nutzt, um “Karriere-Widgets” einzufügen, die wiederum zurück in die jeweiligen Recruiting-Funnels zu leiten. Genauso können Agenturinhaber diese Widgets bei den Karriereseiten ihrer Kunden einbauen, daraus einen Retainer schaffen und die Verwaltung der offenen Positionen übernehmen.</p>`,
    url: 'https://vimeo.com/928749101',
    duration: 543,
    points: 2,
    completed: false
  },
  {
    module_id: 4,
    video_id: 26,
    title: '4.  Letzte Vorbereitung vor Live-Schaltung',
    description: `<p>Auch wenn die Domainverifizierung optional ist, empfehlen wir dringend, diesen Schutzschritt zu setzen. Warum? Um Missbrauch und betrügerische Informationen zu verhindern, sowie um Facebook Echtheit zu signalisieren. Sobald deine Hauptdomain verifiziert ist, sind alle darunterliegenden Subdomains automatisch mit verifiziert. Erfahre in diesem Video, wie das geht.</p>`,
    url: 'https://vimeo.com/776121370',
    duration: 98,
    points: 2,
    completed: false
  },
  {
    module_id: 4,
    video_id: 27,
    title: '5.  Werbekonto und Unternehmensseite einrichten',
    description: `<p>Das Herzstück ist das Werbekonto, über das die Werbeanzeigen zeitgleich auf Facebook und Instagram ausgestrahlt werden. Gefolgt von der Facebook-Unternehmensseite, ohne die eine Ausstrahlung nicht stattfinden kann. Erfahre, wie du dies aufsetzt.</p>`,
    url: 'https://vimeo.com/924580487',
    duration: 1332,
    points: 2,
    completed: false
  },
  {
    module_id: 4,
    video_id: 28,
    title: '6.  So verifizierst du deine Domain bei Facebook',
    description: `<p>Auch wenn die Domainverifizierung optional ist, empfehlen wir dringend, diesen Schutzschritt zu setzen. Warum? Um Missbrauch und betrügerische Informationen zu verhindern, sowie um Facebook Echtheit zu signalisieren. Sobald deine Hauptdomain verifiziert ist, sind alle darunterliegenden Subdomains automatisch mit verifiziert. Erfahre in diesem Video, wie das geht.</p>`,
    url: 'https://vimeo.com/786711481',
    duration: 67,
    points: 1,
    completed: false
  },
  {
    module_id: 4,
    video_id: 29,
    title: '7.  Eigene Subdomain mit Recruiting-Funnel verknüpfen',
    description: `<p>Statt der MEETOVO-URL kannst du deine eigene Subdomain (z.B. karriere.maxmustermann.de) mit erstellten Recruiting-Funnel verbinden. Nun erstrahlt dein Funnel vollständig in deinem Branding. Erfahre, wie das funktioniert.</p>
    <p>CNAME-Record: cname.meetovo-dns.de.</p>
    <p>Für weitere Informationen, lies dir diesen Hilfeartikel durch</p>
    <p>
    <label>Relevante Links:</label><br />
    <ul class="light-list">
    <a target="_blank" href="https://hilfe.meetovo.de/de/article/eigene-subdomain-domain-verknupfen-woykz1/"><li>Eigene Domain verknüpfen</li></a>
    </ul>
    </p>`,
    url: 'https://vimeo.com/776134435',
    duration: 709,
    points: 1,
    completed: false
  },
  {
    module_id: 4,
    video_id: 30,
    title: '8.  Facebook-Tracking einrichten (Pixel, Events & Conversions API)',
    description: `<p>Ohne deinen Facebook-Pixel und die Conversions API befindet sich dein Recruiting-Funnel im Blindflug. Über den Facebook-Pixel und hinterlegte Events senden wir Facebook Signale, dass gewünschte Ziele ausgelöst werden. Dadurch werden die Anzeigen treffsicherer an die richtigen Personen ausgestrahlt - und spart damit langfristig viel Werbebudget. Erfahre, wie du dies umsetzt</p>`,
    url: 'https://vimeo.com/644348525',
    duration: 1051,
    points: 3,
    completed: false
  },
  {
    module_id: 4,
    video_id: 31,
    title: '9.  Veröffentlichung: Kampagne über Werbeanzeigenmanager live schalten (1:1 Anleitung)',
    description: `<p>Der wichtigste Schritt im Social-Recruiting: Die Veröffentlichung deiner Kampagne auf Facebook (und gleichzeitig auf Instagram). Folge Schritt für Schritt unseren Anweisungen und bewirb deine erstellten Werbeanzeigen und Recruiting-Funnel. Erfahre, wie du dies machst.</p>
    <p>Ziel dieses Moduls: Bessere Kampagne-Ergebnisse (falls nötig)</p>`,
    url: 'https://vimeo.com/776227759',
    duration: 1611,
    points: 3,
    completed: false
  },
  {
    module_id: 5,
    video_id: 32,
    title: '1.  Optimierung: Einführung & Überblick',
    description: `<p>Die wenigstens landen “One-Hit-Wonder”. Durchdachte Optimierung hilft dabei, Kosten zu reduzieren und noch mehr qualifizierte Bewerbungen zu generieren. Erhalte einen Überblick über die kommenden Inhalte.</p>`,
    url: 'https://vimeo.com/924580752',
    duration: 106,
    points: 1,
    completed: false
  },
  {
    module_id: 5,
    video_id: 33,
    title: '2.  Kennzahlen & Optimierung in der Theorie',
    description: `<p>Lerne alle wichtigen Kennzahlen kennen und was diese aussagen, um in der Praxis die richtigen Entscheidungen treffen zu können. Anhand eines Beispiels erlebst du die Wirkung, die gezielte Optimierung haben kann.</p>`,
    url: 'https://vimeo.com/924580702',
    duration: 990,
    points: 2,
    completed: false
  },
  {
    module_id: 5,
    video_id: 34,
    title: '3.  Praxisanwendung: Optimierung (anhand einer echten Kampagne)',
    description: `<p>Mithilfe deines vorhandenen Wissens bezüglich Optimierung, kannst du uns nun über die Schultern gucken. Verstehe die Optimierung für dich in der Praxis aussehen kann, um die Kosten pro Bewerbung zu senken.</p>
    <p>Lade dir dein Auswertungsdokument herunter, das dir für die spätere “Optimierung” hilft:</p>
    <p>
    <label>Relevante Links:</label><br />
    <ul class="light-list">
    <a target="_blank" href="https://docs.google.com/spreadsheets/d/1A1dDnu5s5Qv4oGhyB6GQWO2JcgBQujTK-I81NwlfDgw/edit"><li>Testprotokoll</li></a>
    </ul>
    </p>`,
    url: 'https://vimeo.com/924580785',
    duration: 1885,
    points: 3,
    completed: false
  },
  {
    module_id: 6,
    video_id: 35,
    title: '1.  Verwaltung: Einführung & Überblick',
    description: `<p>In diesem Modul geht es um die Verwaltung der gewonnenen Bewerbungen. In diesem Video geben wir dir einen Überblick über alle kommenden Inhalte.</p>
    <p>Dein Ziel für dieses Modul: Einstellungen aus deiner erste Kampagne vorzunehmen.</p>`,
    url: 'https://vimeo.com/924581089',
    duration: 89,
    points: 1,
    completed: false
  },
  {
    module_id: 6,
    video_id: 36,
    title: '2.  Recruiting Fly-Wheel: "Einstellen"',
    description: `<p>In diesem Video lernst du, wie der Teil “Einstellen” im Recruiting-Flywheel zu verstehen ist und wie du es richtig aufsetzt in den folgenden Videos.</p>`,
    url: 'https://vimeo.com/928749305',
    duration: 185,
    points: 1,
    completed: false
  },
  {
    module_id: 6,
    video_id: 37,
    title: '3.  Bewerbermanagement: Einrichtung und die wichtigsten Punkte auf einen Blick',
    description: `<p>Verstehe, wie du das MEETOVO Bewerbermanagement aufbauen und nutzen kannst. Erfahre, wie du damit arbeiten kannst, um Kandidaten von der Bewerbungsannahme bis zur Einstellung innerhalb einer Benutzeroberfläche zu führen.</p>`,
    url: 'https://vimeo.com/924581049',
    duration: 1085,
    points: 1,
    completed: false
  },
  {
    module_id: 6,
    video_id: 38,
    title:
      '4.  Eigenes BMS anbinden, statt das MEETOVO-BMS (Achtung: Überprüfe vorher die Notwendigkeit)',
    description: `<p>Wenn bereits ein vorhandenes Bewerbermanagementsystem existiert und du alle Bewerbungen automatisch in das eigene übertragen möchtest, dann ist dies auch möglich. Erfahre, wie du die automatische Verbindung herstellst.</p>`,
    url: 'https://vimeo.com/928749515',
    duration: 412,
    points: 3,
    completed: false
  },
  {
    module_id: 6,
    video_id: 39,
    title: '5.  Agency-Dashboard: Einrichten (und Umsatz als Agentur steigern)',
    description: `<p>Erfahre, wie du mit Kunden oder Kollegen das Bewerbermanagement teilen kannst. Und das in deinem eigenen Branding. Zeitgleich bietet es dir als Agentur die Möglichkeit, deinen Umsatz zu steigern durch eine professionellere Zusammenarbeit und Wahrnehmung deiner Dienstleistung</p>`,
    url: 'https://vimeo.com/924581023',
    duration: 813,
    points: 2,
    completed: false
  },
  {
    module_id: 6,
    video_id: 40,
    title: '6.  Kontaktaufnahmen mit neuen Bewerber (inkl. Telefonleitfaden)',
    description: `<p>Verstehe, wie deine Mitarbeiter oder du mit neuen Bewerbungen umgehen kannst, wenn eine Kontaktaufnahme aussteht. Spare dir Zeit und Geld, indem du vor dem Vorstellungsgespräch ein kurzes Kennlerngespräch schaltest, um alle Rahmenbedingungen abzuklären. Alles beleuchtet anhand dieses <b>Telefonleitfadens</b></p>
    <p>Bitte drücke auf “Datei” und “Kopie erstellen”, um es abzuspeichern</p>
    <p>
        <label>Relevante Links:</label><br />
        <ul class="light-list">
        <a target="_blank" href="https://docs.google.com/document/d/1tHFk7ubxgfutEnWzBRR-p9W9DiHWpwhHXM6IarmdJQA/edit"><li>Telefonleitfaden</li></a>
        </ul>
    </p>
    `,
    url: 'https://vimeo.com/924581070',
    duration: 1202,
    points: 2,
    completed: false
  },
  {
    module_id: 6,
    video_id: 41,
    title: '7.  Vorstellungsgespräche vereinbaren und führen: Tipps & Tricks',
    description: `<p>Vereinbare noch einfacher Vorstellungsgespräche und sorge für einen durchdachten Prozess, der auf beiden Seiten Zeit einspart.</p>`,
    url: 'https://vimeo.com/924581111',
    duration: 774,
    points: 2,
    completed: false
  },
  {
    module_id: 7,
    video_id: 42,
    title: '1.  Einführung LinkedIn',
    description: `<p>Der Start deiner Reise auf LinkedIn und ein kurzer Überblick</p>`,
    url: 'https://vimeo.com/928726995',
    duration: 223,
    points: 1,
    completed: false
  },
  {
    module_id: 7,
    video_id: 43,
    title: '2.  Organische Kundengewinnung über LinkedIn',
    description: `<p>In diesem Modul lernst du, wie du organisch auf LinkedIn vorgehen musst, um Leads zu generieren und deine Marke aufzubauen.</p>`,
    url: 'https://vimeo.com/928730738',
    duration: 1477,
    points: 2,
    completed: false
  },
  {
    module_id: 7,
    video_id: 44,
    title: '3.  Content Tipps',
    description: `<p>Erfahre, wie du Content auf LinkedIn erstellst, der Leads generiert</p>`,
    url: 'https://vimeo.com/928731732',
    duration: 248,
    points: 2,
    completed: false
  },
  {
    module_id: 7,
    video_id: 45,
    title: '4.  Go-to-market Strategie',
    description: `<p>Das wichtigste Video, bevor du mit Anzeigen startest. Hier lernst du, wieso es so wichtig ist vorab ein ICP Profil zu erstellen und deine Go-to-market Strategie auszubauen.</p>
    <p>
      <label>Relevante Links:</label><br />
      <ul class="light-list">
      <a target="_blank" href="https://docs.google.com/document/d/1EP3CUPafdN2RpdGFe-mtyREi3NEzF6W2jjwnWFDIDkA/edit#heading=h.6wizyj3j2lxj"><li>Go-to-market-Strategie-Vorlage</li></a>
      </ul>
    </p>`,
    url: 'https://vimeo.com/928733859',
    duration: 233,
    points: 1,
    completed: false
  },
  {
    module_id: 7,
    video_id: 46,
    title: '5.  Einführung LinkedIn Ads',
    description: `<p>Der Start deiner ersten Anzeigen auf LinkedIn</p>`,
    url: 'https://vimeo.com/928727018',
    duration: 126,
    points: 1,
    completed: false
  },
  {
    module_id: 7,
    video_id: 47,
    title: '6.  Grundlagen Ads',
    description: `<p>Wie du richtig Anzeigen schaltest und dich dafür vorbereitest</p>`,
    url: 'https://vimeo.com/928739040',
    duration: 1487,
    points: 2,
    completed: false
  },
  {
    module_id: 7,
    video_id: 48,
    title: '7.  Werbetexte für Anzeigen',
    description: `<p>Copywriting ist eines der wichtigsten Punkte bei einer Anzeige. Lerne wie du systematisiert Werbetexte schreiben kannst.t</p>`,
    url: 'https://vimeo.com/928733265',
    duration: 508,
    points: 2,
    completed: false
  },
  {
    module_id: 7,
    video_id: 49,
    title: '8.  Leadmagnet Aufbau für bessere Leads',
    description: `<p>Wie du einen Leadmagneten aubaust, den deine Zielgruppe unbedingt lesen will.</p>`,
    url: 'https://vimeo.com/928728796',
    duration: 412,
    points: 3,
    completed: false
  },
  {
    module_id: 7,
    video_id: 50,
    title: '9.  Leadmagneten mit ChatGPT erstellen',
    description: `<p>Du lernst, wie du einen Leadmagneten in unter 1 Stunde erstellen kannst.</p>`,
    url: 'https://vimeo.com/928728812',
    duration: 294,
    points: 3,
    completed: false
  }
];

export const modules: Module[] = [
  {
    id: 1,
    title: 'Basics: Was ist “Social Recruiting” und wie funktioniert’s',
    badge: process.env.PUBLIC_URL + '/challenge/pawn.svg',
    tag: TAG_TYPES.NEWBIE,
    description:
      'Bereite dich ideal vor, um einen vollen Überblick über “Social Recruiting” zu erhalten, zu erwartende Ausgaben und wie du es einsetzen kannst',
    videos: videos.filter(video => video.module_id === 1)
  },
  {
    id: 2,
    title: 'Funnel-Erstellung: Persona und Aufsetzung',
    badge: process.env.PUBLIC_URL + '/challenge/knight.svg',
    tag: TAG_TYPES.INTERMEDIATE,
    description:
      'Definiere deine Bewerber Persona, lege sie an und erstelle zielgruppengerecht deinen ersten Recruiting-Funnel, inkl. Tipps & Tricks',
    videos: videos.filter(video => video.module_id === 2)
  },
  {
    id: 3,
    title: 'Anzeigen-Erstellung: Copies & Creatives',
    badge: process.env.PUBLIC_URL + '/challenge/bishop.svg',
    tag: TAG_TYPES.ENTHUSIAST,
    description:
      'Erstelle die perfekte Recruiting-Kampagne, indem du alle Grundlagen rund um Copywriting und Anzeigen-Erstellung lernst',
    videos: videos.filter(video => video.module_id === 3)
  },
  {
    id: 4,
    title: 'Veröffentlichung: Vorbereitung und Live-Schaltung',
    badge: process.env.PUBLIC_URL + '/challenge/castle.svg',
    tag: TAG_TYPES.EXPERT,
    description:
      'Verbinde deine eigene Domain, richte Werbekonto und dein Pixel-Tracking ein, um dann Klick für Klick die erste Kampagne zu schalten',
    videos: videos.filter(video => video.module_id === 4)
  },
  {
    id: 5,
    title: 'Optimierung: Günstigere Preise, höhere Qualität',
    badge: process.env.PUBLIC_URL + '/challenge/king.svg',
    tag: TAG_TYPES.PRO,
    description:
    'Lerne aus vergangenen Kampagnen, setze auf Gewinner und reduziere systematisch deine Kosten',
    videos: videos.filter(video => video.module_id === 5)
  },
  {
    id: 6,
    title: 'Verwaltung: Bewerbermanagement und Zusammenarbeit',
    badge: process.env.PUBLIC_URL + '/challenge/queen.svg',
    tag: TAG_TYPES.TOP_NOTCH,
    description:
    'Verstehe, wie du effektiv mit deinen Kunden oder Kollegen die Bewerbungen abarbeiten kannst',
    videos: videos.filter(video => video.module_id === 6)
  },
  {
    id: 7,
    title: 'LinkedIn Marketing Kurs (für Kundengewinnung)',
    badge: process.env.PUBLIC_URL + '/challenge/linkedin-badge.svg',
    tag: TAG_TYPES.LINKEDIN_PRO,
    description:
      'Erfahre, wie du für deine Agentur oder dein Unternehmen Neukunden über LinkedIn gewinnst. Weitere Kunden führen zu mehr Wachstum und gleichzeitig mehr Bedarf an Mitarbeitern.',
    videos: videos.filter(video => video.module_id === 7)
  }
];
