import { Col, Collapse, Form, Row } from 'antd';
import React from 'react';
import { useAppDispatch } from '../../../../redux/hooks';
import { CompanyDetail } from '../../../interfaces/builderSliceTypes';
import {
  setCompanyDetail,
  useCompanyDetail,
  useIsAiSettingDuplicated
} from '../../../redux/builderSlice';
import DuplicateAiSettingsAlert from '../utils/DuplicateAiSettingsAlert';
import { COMPANY_INFORMATION_ELEMENTS } from './components/AiDataHelper';
import {
  hashFormItemsCompany,
  hashTitlesCompanyInformation
} from '../../../AdJobBuilder/components/sharedUI/FormItemsCustom';

const { Panel } = Collapse;

export default function CompanyInformationTab({}) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { name, benefits, uniqueSellingPoint, description } = useCompanyDetail();

  const handleChange = (value: any, identifier: keyof CompanyDetail) => {
    const values = form.getFieldsValue();
    form.setFieldsValue({ ...values, [identifier]: value });
    if (!identifier || value === undefined) return;
    dispatch(setCompanyDetail({ identifier, value }));
  };

  const isFunnelDuplicated = useIsAiSettingDuplicated();

  return (
    <>
      <Col span={24} className="builder__settings-sidebar__container">
        <Form
          name="general_settings_form"
          layout="vertical"
          form={form}
          scrollToFirstError={true}
          initialValues={{ benefits, uniqueSellingPoint, name, description }}
        >
          <Row className="builder__settings-sidebar__row">
            <Col span={24} className="px-4">
              {hashTitlesCompanyInformation[COMPANY_INFORMATION_ELEMENTS.NAME]()}
              {hashFormItemsCompany[COMPANY_INFORMATION_ELEMENTS.NAME](name, handleChange)}
              <Collapse className="w-100" accordion expandIconPosition="right" bordered={false}>
                <Panel
                  header={hashTitlesCompanyInformation[COMPANY_INFORMATION_ELEMENTS.USP](
                    uniqueSellingPoint
                  )}
                  key={COMPANY_INFORMATION_ELEMENTS.USP}
                >
                  {hashFormItemsCompany[COMPANY_INFORMATION_ELEMENTS.USP](
                    uniqueSellingPoint,
                    handleChange
                  )}
                </Panel>
                <Panel
                  header={hashTitlesCompanyInformation[COMPANY_INFORMATION_ELEMENTS.BENEFITS](
                    benefits
                  )}
                  key={COMPANY_INFORMATION_ELEMENTS.BENEFITS}
                >
                  {hashFormItemsCompany[COMPANY_INFORMATION_ELEMENTS.BENEFITS](
                    benefits,
                    handleChange
                  )}
                </Panel>
                <Panel
                  header={hashTitlesCompanyInformation[COMPANY_INFORMATION_ELEMENTS.PRODUCT](
                    description
                  )}
                  key={COMPANY_INFORMATION_ELEMENTS.PRODUCT}
                >
                  {hashFormItemsCompany[COMPANY_INFORMATION_ELEMENTS.PRODUCT](
                    description,
                    handleChange
                  )}
                </Panel>
              </Collapse>
              {isFunnelDuplicated && <DuplicateAiSettingsAlert />}
            </Col>
          </Row>
        </Form>
      </Col>
    </>
  );
}
