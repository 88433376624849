import React, { Suspense } from 'react'
import { LoadingOutlined } from '@ant-design/icons';

const SuspenseLoadingWrapper = ({ children }: any) =>  {
  return (
    <Suspense
      fallback={
        <div className="loading-overlay">
          <LoadingOutlined className="centered" spin />
        </div>
      }
    >
      {children}
    </Suspense>
  );
}

export default SuspenseLoadingWrapper;