import React from 'react';
import { Col, Form, Row, Tabs } from 'antd';
import BuilderMetaDataSettings from '../components/generalSettings/BuilderMetaDataSettings';
import BuilderGeneralSettings from '../components/generalSettings/BuilderGeneralSettings';
import BuilderJobGeneralSettings from '../components/generalSettings/builderJobSettings/BuilderJobGeneralSettings';
import {
  setGeneralSettingsTabView,
  useCompanyDetail,
  useGeneralSettingsTabView
} from '../redux/builderSlice';
import { GeneralSettingsTabView } from '../interfaces/builderSliceTypes';
import TargetGroupTab from '../components/generalSettings/TargetGroup/TargetGroupTab';
import CompanyInformationTab from '../components/generalSettings/AiDataTab/CompanyInformationTab';
import CommunicationTab from '../components/generalSettings/Communications/CommunicationTab';
import { useAppDispatch } from '../../redux/hooks';
import BackroundDataSaveBanner from './BackroundDataSaveBanner';

const { TabPane } = Tabs;

const GeneralSettings = () => {
  const tabView = useGeneralSettingsTabView();
  const dispatch = useAppDispatch();

  return (
    <Tabs
      onChange={key => dispatch(setGeneralSettingsTabView(key as GeneralSettingsTabView))}
      className="general-settings-tabs overflow-auto"
      defaultActiveKey={tabView}
    >
      <TabPane tab="Allgemein" key={GeneralSettingsTabView.GENERAL_SETTINGS}>
        {GeneralSettingsTabView.GENERAL_SETTINGS && <BuilderGeneralSettings />}
      </TabPane>
      <TabPane tab="Meta-Daten" key={GeneralSettingsTabView.META_DATA}>
        {GeneralSettingsTabView.META_DATA && <BuilderMetaDataSettings />}
      </TabPane>
      <TabPane tab="Unternehmensinformationen" key={GeneralSettingsTabView.COMPANY_INFORMATION}>
        {GeneralSettingsTabView.COMPANY_INFORMATION == tabView && <CompanyInformationTab />}
        <BackroundDataSaveBanner />
      </TabPane>
      <TabPane tab="Job-Informationen" key={GeneralSettingsTabView.JOB_INFORMATION}>
        {GeneralSettingsTabView.JOB_INFORMATION && <BuilderJobGeneralSettings />}
        <BackroundDataSaveBanner />
      </TabPane>
      <TabPane tab="Zielgruppe" key={GeneralSettingsTabView.TARGET_GROUP}>
        {GeneralSettingsTabView.TARGET_GROUP && <TargetGroupTab />}
        <BackroundDataSaveBanner />
      </TabPane>
      <TabPane tab="Kommunikation" key={GeneralSettingsTabView.COMMUNICATION}>
        {GeneralSettingsTabView.COMMUNICATION && <CommunicationTab />}
        <BackroundDataSaveBanner />
      </TabPane>
    </Tabs>
  );
};

export default GeneralSettings;
