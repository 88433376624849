import React, { useEffect } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { ActionsController } from '../sharedUI/ActionsController';
import { CraftElementBaseProps, getRandomId } from '../../helper/craftJs';

import EmailLoginAndRegistration from '../../../Login/components/EmailLoginAndRegistration';
import { IoMdMail } from 'react-icons/io';
import { AiFillLock } from 'react-icons/ai';
import ImageComponent from './ImageComponent';

interface Props extends CraftElementBaseProps {
  maxWidth?: string;
  src?: string;
  align?: string;
  children?: React.ReactNode;
}

export const LoginComponent = (props: Props) => {
  const { nodeId, currentNode } = useNode(node => {
    return {
      currentNode: node,
      nodeId: node.id
    };
  });

  const { query, actions } = useEditor();

  const handleAfterLogin = () => {};

  useEffect(() => {
    if (!props.children && currentNode.data.nodes.length === 0) {
      const nodeTree = query
        .parseReactElement(
          <Element
            id={getRandomId(10)}
            is={ImageComponent}
            src="/dashboard/funnel-builder/default-logo.png"
            align="center"
            onlySettingsReq={true}
            showPlusIcon={false}
          ></Element>
        )
        .toNodeTree();
      actions.history.ignore().addNodeTree(nodeTree, nodeId);
    }
  }, []);

  return (
    <>
      <ActionsController className="actions-controller__wrapper__login_component p-4">
        <div className="logo_container">{props.children}</div>
        <div className="login_form">
          <EmailLoginAndRegistration
            mode={'email-login'}
            afterLogin={handleAfterLogin}
            disableModeSwitch={true}
            emailIcon={<IoMdMail />}
            passwordIcon={<AiFillLock />}
            disabledLoginButton={true}
          />
        </div>
      </ActionsController>
    </>
  );
};
