import { ColumnsType } from 'antd/es/table/interface';
import React, { useEffect, useMemo } from 'react';
import FileIcon from './FileIcon';
import { Table, Tooltip } from 'antd';
import moment from 'moment';
import { HourglassOutlined } from '@ant-design/icons';
import dateFormats from '../../../config/dateFormats';
import { useQuery } from '@apollo/react-hooks';
import UploadFile from './UploadFile';
import DefaultSmallLoader from '../../../SharedUI/components/DefaultSmallLoader';
import { GET_SINGLE_BOOKING_FILES } from '../../../Builder/hooks/useBookingFiles';
import { acceptedFormats } from '../../helper/uploadFile';

interface PropsInterface {
  handleDownloadFile: (url: string, name: string) => void;
  selectedEvent: any;
}

const FilesTable = ({ handleDownloadFile, selectedEvent }: PropsInterface) => {
  const filesColumns: ColumnsType<{
    fileLink: string;
    name: string;
    expirationDate: Date;
  }> = [
    {
      title: 'Dateiname',
      dataIndex: 'name',
      key: 'name',
      render: (_, { fileLink, name }) => (
        <div className="d-flex align-items-center" style={{ columnGap: 8 }}>
          <FileIcon fileName={name} />
          <div
            className="file-name-button"
            onClick={() => {
              handleDownloadFile(fileLink, name || fileLink);
            }}
          >
            {name}
          </div>
        </div>
      )
    },
    {
      title: 'Löschdatum',
      dataIndex: 'expirationDate',
      key: 'expirationDate',
      render: (_, { expirationDate }) => (
        <Tooltip title={`Wird in ${moment(expirationDate).diff(moment(), 'days')} Tagen gelöscht`}>
          <span>
            <HourglassOutlined className="text-red" />{' '}
            {moment(expirationDate).format(dateFormats.dateTime)}
          </span>
        </Tooltip>
      )
    }
  ];

  const { data, loading, refetch } = useQuery(GET_SINGLE_BOOKING_FILES, {
    variables: {
      filter: { id: selectedEvent.event.id }
    },
    fetchPolicy: 'cache-and-network'
  });

  const {
    getSingleBooking: { file }
  } = data || {
    getSingleBooking: {
      file: []
    }
  };
  const sortedFiles = useMemo(() => {
    return file.sort(
      (a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
  }, [file?.length]);

  return (
    <div>
      {loading ? (
        <div className="d-flex justify-content-center">
          <DefaultSmallLoader loading={loading} />
        </div>
      ) : (
        <>
          {
            <>
              <UploadFile
                multiple={true}
                accept={acceptedFormats}
                selectedEvent={selectedEvent}
                handleSubmit={() => {
                  refetch();
                }}
              />
              <Table columns={filesColumns} dataSource={sortedFiles} />
            </>
          }
        </>
      )}
    </div>
  );
};

export default FilesTable;
