import React, { useState, useEffect } from 'react';

import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';

const translations = {
  search: 'Suche...',
  categories: {
    search: 'Suchergebnisse',
    recent: 'Kürzlich verwendet',
    smileys: 'Smileys',
    people: 'Menschen',
    nature: 'Natur & Tiere',
    foods: 'Essen & Trinken',
    activity: 'Aktivität',
    places: 'Reisen',
    objects: 'Objekte',
    symbols: 'Symbole',
    flags: 'Flaggen',
    custom: 'Andere'
  }
};

export default React.memo(
  function EmojiPicker({
    height = '100%',
    onChange,
    emojiSize = 30
  }: {
    onChange(value: string): any;
    emojiSize?: number;
    height?: string;
  }) {
    return (
      <Picker
        title={''}
        color="#1890ff"
        style={{ width: '100%', height: height }}
        showPreview={false}
        emojiSize={emojiSize}
        i18n={translations}
        onSelect={({ native }: any) => onChange(native)}
      />
    );
  },
  () => {
    // this blocks all rerenderings because of performance issues
    return true;
  }
);
