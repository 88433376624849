import { useEditor, useNode } from '@craftjs/core';
import React, { useEffect } from 'react';
import { ActionsController } from '../../../sharedUI/ActionsController';
import { CRAFT_ELEMENTS, CRAFT_ELEMENTS_LABEL } from '../../../../config/craftElements';
import { DividerComponentProps } from '../config';
import { DividerSettings } from './DividerSettings';
import { DividerComponentProps as DividerComponentPropsInterface } from '../interfaces';
import DropElementArea, { Level, Positions } from '../../common/DropElementArea';
import { getElementColor } from '../../../../helper/craftJs';
import { useCurrentlyEditingBuilderTheme } from '../../../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';

export const DividerComponent = ({
  paddingTopClass,
  paddingBottomClass,
  dividerColor,
  children,
  backgroundColor,
  showLine,
  ...props
}: DividerComponentPropsInterface) => {
  const theme = useCurrentlyEditingBuilderTheme();
  const { nodeId, currentNode} = useNode(node => ({
    nodeId: node.id,
    currentNode: node
  }));
  const { query } = useEditor();

  return (
    <>
      <DropElementArea position={Positions.TOP} level={Level.CONTAINER} />
      <ActionsController
        {...props}
        className={`flex justify-content-center container__add-wrapper ${paddingTopClass} ${paddingBottomClass}`}
        style={{
          backgroundColor: getElementColor(backgroundColor, theme)
        }}
        label={CRAFT_ELEMENTS_LABEL[currentNode.data.displayName]}
      >
        <div
          style={{
            height: 1,
            width: '100%',
            background: showLine
              ? getElementColor(dividerColor, theme)
              : getElementColor(backgroundColor, theme)
          }}
        ></div>
      </ActionsController>
      <DropElementArea position={Positions.BOTTOM} level={Level.CONTAINER} />
    </>
  );
};

export default DividerComponent;

DividerComponent.craft = {
  name: CRAFT_ELEMENTS.DIVIDER,
  props: DividerComponentProps,
  related: {
    settings: DividerSettings
  }
};
