import gql from 'graphql-tag';

export interface Input {
  id: number;
  html: string;
  brandingEnabled?: boolean;
  enabled?: boolean;
  emailSenderName?: string;
  subject?: string;
}

export const UPDATE_EMAIL_TEMPLATE = gql`
  mutation updateEmailTemplate($input: EmailTemplatesData!) {
    setEmailTemplate(input: $input) {
      id
    }
  }
`;

export default function updateEmailTemplate(input: Input) {
  return window.apolloClient.mutate({
    mutation: UPDATE_EMAIL_TEMPLATE,
    variables: { input }
  });
}
