import gql from 'graphql-tag';

export const GET_COMPLETE_AD_BUILDER = gql`
  query getCompleteAdBuilder {
    funnelAd {
      id
      craftState
    }
    adTemplates {
      id
      craftState
      name
      imageUrl
    }
    adMediaTemplates {
      id
      craftState
      name
      imageUrl
    }
    adTextTemplates {
      id
      title
      text
      type
    }
  }
`;

export function fetchCompleteAdBuilder() {
  return window.apolloClient.query({
    query: GET_COMPLETE_AD_BUILDER,
    fetchPolicy: 'no-cache'
  });
}
