import React from 'react';
import { RxSpaceBetweenVertically } from 'react-icons/rx';
import { BsAlignCenter } from 'react-icons/bs';
import {
  MdOutlineAlignVerticalBottom,
  MdOutlineAlignVerticalTop,
  MdVerticalAlignCenter
} from 'react-icons/md';

export enum ALIGNMENT_OPTIONS {
  START = 'start',
  CENTER = 'center',
  END = 'end',
  SPACE_BETWEEN = 'space-between',
  SPACE_AROUND = 'space-around'
}

export const Aligns = {
  START: { label: 'Anfang', value: ALIGNMENT_OPTIONS.START },
  CENTER: { label: 'Mitte', value: ALIGNMENT_OPTIONS.CENTER },
  END: { label: 'Ende', value: ALIGNMENT_OPTIONS.END },
  SPACE_BETWEEN: { label: 'Platz dazwischen', value: ALIGNMENT_OPTIONS.SPACE_BETWEEN },
  SPACE_AROUND: { label: 'Platz drumherum', value: ALIGNMENT_OPTIONS.SPACE_AROUND }
};

export const alignmentOptions = [
  {
    align: Aligns.START.value,
    icon: <MdOutlineAlignVerticalTop />,
    text: Aligns.START.label
  },
  {
    align: Aligns.CENTER.value,
    icon: <MdVerticalAlignCenter />,
    text: Aligns.CENTER.label
  },
  {
    align: Aligns.END.value,
    icon: <MdOutlineAlignVerticalBottom />,
    text: Aligns.END.label
  },
  {
    align: Aligns.SPACE_BETWEEN.value,
    icon: <RxSpaceBetweenVertically />,
    text: Aligns.SPACE_BETWEEN.label
  },
  {
    align: Aligns.SPACE_AROUND.value,
    icon: <BsAlignCenter />,
    text: Aligns.SPACE_AROUND.label
  }
];

export enum CONTAINER_ALIGNMENT_OPTIONS {
  TOP = 'top',
  CENTER = 'center',
  BOTTOM = 'bottom'
}

export const ContainerBackgroundAligns = {
  TOP: { label: 'Anfang', value: CONTAINER_ALIGNMENT_OPTIONS.TOP },
  CENTER: { label: 'Mitte', value: CONTAINER_ALIGNMENT_OPTIONS.CENTER },
  BOTTOM: { label: 'Ende', value: CONTAINER_ALIGNMENT_OPTIONS.BOTTOM }
};

export const backgroundAlignmentOptions = [
  {
    align: ContainerBackgroundAligns.TOP.value,
    icon: <MdOutlineAlignVerticalTop />,
    text: ContainerBackgroundAligns.TOP.label
  },
  {
    align: ContainerBackgroundAligns.CENTER.value,
    icon: <MdVerticalAlignCenter />,
    text: ContainerBackgroundAligns.CENTER.label
  },
  {
    align: ContainerBackgroundAligns.BOTTOM.value,
    icon: <MdOutlineAlignVerticalBottom />,
    text: ContainerBackgroundAligns.BOTTOM.label
  }
];
