import { Modal, message, Form, Button, Upload, Input, Alert, Checkbox } from 'antd';
import React, { useRef, useState } from 'react';
import { acceptedFontFormats } from '../../../Events/helper/uploadFile';
import { CustomFontsMessages, uploadFileMessage } from '../../../config/messages';
import { useDispatch } from 'react-redux';
import { createAvailableFontsThunk } from '../../redux/thunk';
import { AVAILABLE_FONT_TYPE } from './BuilderSettingsFont';
import { useMutation } from '@apollo/react-hooks';
import UploadFile, { UPLOAD_FILE } from '../../../Events/components/Event/UploadFile';
import { CloseOutlined } from '@ant-design/icons';
import { useFunnelAvailableFonts } from '../../redux/builderSlice';
import { AvailableFonts } from '../../interfaces/builderSliceTypes';
import { formRules } from '../../DebugTracking/utils/formValidationRules';
import mixpanel from 'mixpanel-browser';
import { mixpanelEvents } from '../../../UI/utils/mixpanel';
import { FaSpinner } from 'react-icons/fa';
export interface BuilderSettingUploadFontModalProps {
  uploadModalVisible: boolean;
  setUploadModalVisible: (visible: boolean) => void;
}

const BuilderSettingUploadFontModal = ({
  uploadModalVisible,
  setUploadModalVisible
}: BuilderSettingUploadFontModalProps) => {
  const funnelAvailableFont = useFunnelAvailableFonts();
  const [isInputFieldError, setIsInputFieldError] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [uploadFileMutation] = useMutation(UPLOAD_FILE);
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (fontFile: any) => {
    setLoading(true);
    const fontFamily = form.getFieldValue('fontFamily');

    if (fontFamily) {
      const isFontAlreadyExist = funnelAvailableFont.some((font: AvailableFonts) => {
        return font.fontFamily === fontFamily;
      });

      if (isFontAlreadyExist) {
        message.error(CustomFontsMessages.fontAlreadyExist);
        setLoading(false);
        return;
      }
    }
    const res = await uploadFont(fontFile as File);
    if (res?.data?.uploadFile[0].fileLink) {
      const availableFontsApiRes: { [key: string]: any } = await dispatch(
        createAvailableFontsThunk({
          fontFamily,
          fontLink: res?.data?.uploadFile[0].fileLink,
          type: AVAILABLE_FONT_TYPE.CUSTOM_FONTS
        })
      );
      if (availableFontsApiRes?.payload) {
        mixpanel.track(mixpanelEvents.UPLOAD_SUBMIT_BUTTON);
        handleClose();
      }
    } else {
      message.error(uploadFileMessage.fileNotUploaded);
    }
    setLoading(false);
  };

  const uploadFont = async (font: File) => {
    try {
      const filesData = {
        file: font,
        name: font.name,
        isPermanent: true
      };
      const res = await uploadFileMutation({
        variables: {
          files: [filesData],
          areSignedUrlsRequired: true,
          homeDirectory: 'font'
        }
      });
      return res;
    } catch (error) {
      console.log('error', error);
      message.error(uploadFileMessage.fileNotUploaded);
    }
  };

  const onSelectFile = (fontName: string) => {
    const lastDotIndex = fontName.lastIndexOf('.');
    const fileName = fontName.substring(0, lastDotIndex);
    form.setFieldsValue({ fontFamily: fileName });
    setIsInputFieldError(true);
    mixpanel.track(mixpanelEvents.UPLOAD_FILE);
    return false;
  };

  const handleClose = async () => {
    mixpanel.track(mixpanelEvents.CLOSE_CUSTOM_FONT_UPLOAD_MODAL);
    await form.resetFields();
    setUploadModalVisible(false);
  };

  return (
    <Modal
      title="Schriftart hochladen"
      width={500}
      visible={uploadModalVisible}
      destroyOnClose
      onCancel={handleClose}
      footer={null}
    >
      <div className="m-2">
        <Form layout="vertical" form={form} onFinish={handleFormSubmit} autoComplete="off">
          <Form.Item label="Name" name="fontFamily" rules={formRules.fontNameRules}>
            <Input
              placeholder="z.B. Arial"
              onChange={e => {
                e.target.value.length < 4
                  ? setIsInputFieldError(false)
                  : setIsInputFieldError(true);
              }}
            />
          </Form.Item>
          <UploadFile
            multiple={false}
            accept={acceptedFontFormats}
            handleSubmit={handleFormSubmit}
            onSelectFile={onSelectFile}
            disabled={!isInputFieldError}
            shouldUpload={true}
            loading={loading}
          />

          <Alert message={CustomFontsMessages.infoUploadFont} type="info" showIcon />
        </Form>
      </div>
    </Modal>
  );
};

export default BuilderSettingUploadFontModal;
