export const isAnyColorSameAsBackgroundColor = (colorMap: any) => {
  const backgroundColor = convertToUniformColorHash(
    (colorMap['backgroundColor'] as string).toUpperCase()
  );
  const cardBackgroundColor = convertToUniformColorHash(
    (colorMap['cardBackgroundColor'] as string).toUpperCase()
  );


  for (const [key, value] of Object.entries(colorMap)) {
    if (key === 'backgroundColor' || key === 'cardBackgroundColor') {
      continue;
    }

    const colorValue = convertToUniformColorHash((value as string)).toUpperCase();

    if (key === 'cardTextColor' || key === 'cardIconColor') {
      if (colorValue === cardBackgroundColor) {
        return {
          error: true,
          key: key
        };
      }
    } else if (['headlineColor', 'textColor', 'accentColor'].includes(key)) {
      if (colorValue === backgroundColor) {
        return {
          error: true,
          key: key
        };
      }
    }
  }

  return {
    error: false,
    key: null
  };
};

const convertToUniformColorHash = (colorHash: string) => {
  colorHash = '' + colorHash;
  if (colorHash.length > 4) {
    return colorHash;
  }

  const codePartOfColorHash = colorHash.substring(1, 4);
  let uniformHash = `#`;

  for (const code of codePartOfColorHash) {
    uniformHash += `${code}${code}`;
  }
  return uniformHash;
};
