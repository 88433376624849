import { useLocation } from 'react-router';
import { useAppDispatch } from '../../redux/hooks';
import { useSetSelectedElementTab } from '../reduxCommonMutations/setSelectedElementTab';
import { saveCompleteBuilderDataThunk } from '../redux/thunk';
import { saveWidgetBuilderStateThunk } from '../WidgetsBuilder/redux/thunk';

export default function useGeneralSettingsDataSave() {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const setSelectedElementTab = useSetSelectedElementTab();

  const handleGeneralSettingsDataSave = async (payload: string) => {
    const successCallback = () => {
      setSelectedElementTab(
        {
          payload
        },
        {
          navigationReq: true
        }
      );
    };

    if (pathname.includes('grundeinstellungen')) {
      if (pathname.includes('/widget-builder/')) {
        await dispatch(saveWidgetBuilderStateThunk());
        return successCallback();
      }
      await dispatch(saveCompleteBuilderDataThunk({ callbackOnSucces: successCallback }));
    } else {
      successCallback();
    }
  };
  return {
    handleGeneralSettingsDataSave
  };
}
