import React from 'react';
import { Button, Col, Row } from 'antd';
import BuilderSettingsTitle from '../../sharedUI/BuilderSettingsTitle';
import { BuilderSettingsColorMessages } from '../../../../config/messages';
import { FaPlus } from 'react-icons/fa';
import { FunnelThemeType } from '../../../interfaces/builderSliceTypes';
import { PlusOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons';
import {
  setCurrentlyEditingTheme,
  setIsEditingColorTheme,
  useFunnelTheme,
  useUserColorThemes
} from '../../../redux/builderSlice';
import { useDispatch } from 'react-redux';
import ColorList from '../../sharedUI/ColorList';
import { useIsWidgetsBuilderPath } from '../../../hooks/useIsWidgetsBuilderPath';

const UserColorThemeListing = ({
  applyColorTheme
}: {
  applyColorTheme: (t: FunnelThemeType) => Promise<any>;
}) => {
  const colorThemes = useUserColorThemes();
  const currentFunnelTheme = useFunnelTheme();
  const dispatch = useDispatch();
  const isWidgetBuilderPath = useIsWidgetsBuilderPath();

  const editUserCustomColorTheme = (colorTheme: FunnelThemeType) => {
    dispatch(setCurrentlyEditingTheme(colorTheme));
    dispatch(setIsEditingColorTheme(true));
  };

  const handleAddNewColorTheme = () => {
    const { id, funnelId, ...rest } = {
      ...currentFunnelTheme,
      isCustom: true
    } as FunnelThemeType;

    //@ts-ignore
    dispatch(setCurrentlyEditingTheme(rest));
    dispatch(setIsEditingColorTheme(true));
  };

  return (
    <Row className="builder__settings-sidebar__row">
      <Col span={24}>
        <BuilderSettingsTitle
          title="Meine Farbbibliothek"
          infoPopoverText={BuilderSettingsColorMessages.colorLibraryDescription}
          rightAlignedElement={
            !isWidgetBuilderPath && colorThemes?.length > 0 ? (
              <FaPlus className="green-text" onClick={handleAddNewColorTheme} />
            ) : null
          }
        />

        <div className="d-grid d-grid__col-2">
          {colorThemes?.map((colorTheme: FunnelThemeType, i: number) => (
            <div
              key={i}
              className={
                'color-circles ' + (currentFunnelTheme?.id === colorTheme.id ? 'selected' : '')
              }
            >
              <label className="small-label">{colorTheme.name}</label>
              <div className="color-circles__edit-overlay">
                <CheckOutlined onClick={() => applyColorTheme(colorTheme)} />
                {!isWidgetBuilderPath && (
                  <EditOutlined onClick={() => editUserCustomColorTheme(colorTheme)} />
                )}
              </div>
              <div className="color-circles__inner">
                <ColorList theme={colorTheme} qty={5} />
              </div>
            </div>
          ))}

          {!isWidgetBuilderPath && !colorThemes?.length && (
            <Button type="dashed" onClick={handleAddNewColorTheme} icon={<PlusOutlined />}>
              Hinzufügen
            </Button>
          )}
          {isWidgetBuilderPath && !colorThemes?.length && (
            <p>Erstellung von nur im Funnel-Builder möglich.</p>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default UserColorThemeListing;
