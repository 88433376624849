import React, { useState } from 'react';
import { Card, Popover, Progress, message } from 'antd';
import { CustomDomainsMessages } from '../../../config/messages';
import DeleteButtonWithConfirmation from '../../../SharedUI/components/DeleteButtonWithConfirmation';
import { useMutation } from '@apollo/react-hooks';
import { DELETE_DOMAIN, GET_ALL_DOMAINS } from '../../utils/domainQueries';
import { SlugInterface } from './Slug';
import SlugListModal from './SlugListModal';
import DomainStatusIndicator from './DomainStatusIndicator';
import { LinkOutlined } from '@ant-design/icons';
import { GET_SUBSCRIPTION_INFO } from '../../../hooks/useSubscriptionInfo';
import { useAppDispatch } from '../../../redux/hooks';
import { setFunnelDomainInSlice } from '../../../Funnel/redux/funnelSlice';
import { InfoCircleOutlined } from '@ant-design/icons';

export interface DomainInterface {
  id: number;
  name: string;
  agencyDashboardId?: number;
  status?: string;
  type?: string;
  slugs: SlugInterface[];
  maximumSlugs: number;
  cName?: string;
}

interface DomainProps {
  id: number;
  name: string;
  status: string;
  slugs: SlugInterface[];
  maximumSlugs: number;
  refetchSubscriptionInfo: () => void;
  setNewlyCreatedDomainId: Function;
  newlyCreatedDomainId?: number;
  cName?: string;
}

export enum DomainStatus {
  DELETED = 'deleted',
  UPDATED = 'updated'
}

const Domain = ({
  id,
  name,
  status,
  slugs,
  maximumSlugs,
  refetchSubscriptionInfo,
  setNewlyCreatedDomainId,
  newlyCreatedDomainId,
  cName
}: DomainProps) => {
  const [deleteDomain, { loading }] = useMutation(DELETE_DOMAIN, {
    refetchQueries: [{ query: GET_ALL_DOMAINS }, { query: GET_SUBSCRIPTION_INFO }]
  });
  const dispatch = useAppDispatch();

  const handleDeleteDomain = () => {
    const domainData = slugs.map(item => {
      return {
        domianStatus: status,
        domain: name,
        slug: item.slug,
        funnelId: item.funnelId,
        status: DomainStatus.DELETED
      };
    });
    deleteDomain({ variables: { id } })
      .then(() => {
        dispatch(setFunnelDomainInSlice(domainData));
        refetchSubscriptionInfo();
      })
      .catch(() => {
        message.error(CustomDomainsMessages.couldNotDeleteDomain);
      });
  };

  return (
    <Card
      className="ui__white-round-wrapper parent-hover-child-visible"
      style={{ marginBottom: '1em' }}
    >
      {/* temporary disabled */}
      {false && (
        <div className="d-flex align-items-center justify-content-between mb-1">
          <div className="d-flex align-items-center">
            <h4 className="m-0">Verwendete Slugs</h4>
            <Popover
              className="default-popover"
              content={
                <div className="default-popover__content" style={{ maxWidth: 400 }}>
                  {CustomDomainsMessages.slugsLimitExplanation}
                </div>
              }
              trigger="hover"
            >
              <InfoCircleOutlined />
            </Popover>
          </div>

          <Progress
            size="small"
            percent={(slugs.length * 100) / maximumSlugs}
            format={() => slugs.length + '/' + maximumSlugs}
            style={{ width: 150 }}
          />
        </div>
      )}
      <div className="d-flex justify-content-between">
        <div>
          <h3>{name}</h3>
        </div>
        <div>
          <SlugListModal
            newlyCreatedDomainId={newlyCreatedDomainId}
            onClose={() => setNewlyCreatedDomainId(undefined)}
            domain={{
              id,
              name,
              slugs,
              maximumSlugs,
              status,
              cName
            }}
            trigger={
              <a data-testid="editDomain" className="icon-link parent-hover-child-visible__child">
                <LinkOutlined />
                Funnel verbinden
              </a>
            }
          />
        </div>
      </div>

      <div className="d-flex justify-content-between">
        <div>
          <DomainStatusIndicator status={status} />
        </div>
        <div className="parent-hover-child-visible__child">
          <DeleteButtonWithConfirmation loading={loading} onDelete={() => handleDeleteDomain()} />
        </div>
      </div>
    </Card>
  );
};

export default Domain;
