import React, { useState } from 'react';
import { useNode } from '@craftjs/core';
import { Card, Col, message, Row, Slider } from 'antd';
import { ActionsController } from '../sharedUI/ActionsController';
import BuilderSettingsTitle from '../sharedUI/BuilderSettingsTitle';
import VoiceRecorder from '../../../VoiceRecorder/container/VoiceRecorder';
import VoiceMessagePreview from '../voiceMessage/VoiceMessagePreview';
import { CRAFT_ELEMENTS, CRAFT_ELEMENTS_LABEL } from '../../config/craftElements';
import TextAlignmentButtons from '../../../SharedUI/components/TextAlignmentButtons';
import { CraftElementBaseProps, getKeyByValue, getValueByKey } from '../../helper/craftJs';
import { defaultVoiceMessageSize, VoiceMessageSizes } from '../../interfaces/VoiceMessageSizes';
import { useMutation } from '@apollo/react-hooks';
import { UPLOAD_VOICE_MESSAGE } from '../../graphql/uploadVoiceMessage';
import * as Sentry from '@sentry/browser';
import { VoiceMessageComponentMessages } from '../../../config/messages';
import ImageUploadV3, { CropperShape } from '../../../GeneralComponents/ImageUploadV3';
import { enhanceStockImage } from '../../helper/images';

interface Props extends CraftElementBaseProps {
  imageURL?: string;
  align?: string;
  value?: any;
  width?: string;
}

const VoiceMessageComponent = (props: Props) => {
  const { currentNode } = useNode(node => ({
    currentNode: node
  }));

  return (
    <ActionsController
      className="voice-message-preview"
      style={{ display: 'flex', justifyContent: props.align }}
      label={CRAFT_ELEMENTS_LABEL[currentNode.data.displayName]}
    >
      <VoiceMessagePreview
        width={props.width}
        imageUrl={enhanceStockImage(props.imageURL as string, '200')}
        voiceMessageSignedUrl={props.value}
      />
    </ActionsController>
  );
};

const VoiceMessageComponentDefaultProps = {
  width: '90px',
  form: 'square',
  align: 'left',
  value: '',
  imageURL: `${process.env.PUBLIC_URL}/profile-image-placeholder.png`
};

const marks = {
  1: 'S',
  2: 'M',
  3: 'L'
};

const defaultVoiceMessageStateValue = {
  url: '',
  blob: new Blob()
};
export const VoiceMessageComponentSettings = () => {
  const [uploadVoiceMessage, { loading }] = useMutation(UPLOAD_VOICE_MESSAGE);
  const [voiceMessageBlob, setVoiceMessageBlob] = useState(defaultVoiceMessageStateValue);

  const {
    actions: { setProp },
    props
  } = useNode(node => ({
    props: node.data.props
  }));

  const handleUploadVoiceMessage = () => {
    uploadVoiceMessage({
      variables: { input: { voiceMessageFile: voiceMessageBlob.blob, isPublic: true } }
    })
      .then(res => {
        const { voiceMessageSignedUrl } = res.data.uploadVoiceMessage;
        setProp((props: any) => {
          props.value = voiceMessageSignedUrl;
        });
        setVoiceMessageBlob(defaultVoiceMessageStateValue);
      })
      .catch(e => {
        Sentry.captureException(e);
        message.error(VoiceMessageComponentMessages.uploadFailed);
      });
  };

  const handleChangeVoiceMessage = (blob: Blob) => {
    var blobUrl = URL.createObjectURL(blob);
    setVoiceMessageBlob({
      url: blobUrl,
      blob: blob
    });
  };

  const src = enhanceStockImage(props.imageURL);
  return (
    <div className="builder__settings-sidebar__container">
      <Card className="settings-card" title="Sprachnachricht" bordered={false}>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <VoiceRecorder
              uploadLoading={loading}
              onClickUpload={handleUploadVoiceMessage}
              voiceMessageSignedUrl={voiceMessageBlob.url || props.value}
              onChange={handleChangeVoiceMessage}
              onDelete={(key: any) => {
                setVoiceMessageBlob(defaultVoiceMessageStateValue);
                setProp((props: any) => {
                  props.value = '';
                });
              }}
            />
          </Col>
        </Row>

        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BuilderSettingsTitle title="Größe" />
          </Col>
          <Col span={24}>
            <Slider
              className="builder-slider-style"
              marks={marks}
              min={1}
              max={3}
              tooltipVisible={false}
              defaultValue={defaultVoiceMessageSize.key}
              value={getKeyByValue(props.width, VoiceMessageSizes).key}
              onChange={value => {
                setProp(
                  // @ts-ignore
                  (props: any) => (props.width = getValueByKey(value, VoiceMessageSizes).value)
                );
              }}
            />
          </Col>
        </Row>

        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BuilderSettingsTitle title="Ausrichtung" />
            <TextAlignmentButtons
              align={props.align}
              onChange={align => {
                setProp((props: any) => (props.align = align));
              }}
            />
          </Col>
        </Row>
        <Row className="builder__settings-sidebar__row voice-img__wrapper">
          <Col span={24}>
            <BuilderSettingsTitle title="Bild" />
            <ImageUploadV3
              previewImage={enhanceStockImage(src, '200')}
              onChange={(url: string) => setProp((prop: any) => (prop.imageURL = url))}
              maxHeight={300}
              minWidth={100}
              minHeight={100}
              minZoom={1}
              showRatioSelector={false}
              uploadedImage
              grid
              shape={CropperShape.ROUND}
              restrictPosition={true}
              cropperIsNotMandatoryAfterSelectionFromLibrary={false}
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
};

VoiceMessageComponent.craft = {
  name: CRAFT_ELEMENTS.VOICE_MESSAGE,
  props: VoiceMessageComponentDefaultProps,
  related: {
    settings: VoiceMessageComponentSettings
  }
};

export default VoiceMessageComponent;
