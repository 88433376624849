export const detectEdge = () =>
  navigator.userAgent.indexOf('Edge') !== -1 &&
  //@ts-ignore
  (!!navigator.msSaveOrOpenBlob || !!navigator.msSaveBlob);

export const detectSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const detectFirefox = () => navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

export function detectMacintosh() {
  return navigator.platform.indexOf('Mac') > -1;
}

export const isFacebookInApp = () => {
  //@ts-ignore
  var ua = navigator.userAgent || navigator.vendor || window.opera || '';
  return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1 || ua.indexOf('Instagram') > -1;
};
