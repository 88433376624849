import React, { useEffect, useRef } from 'react';
import { useEditor, useNode } from '@craftjs/core';

import { ActionsController } from '../../sharedUI/ActionsController';
import { useIsMobileView, useCurrentlyEditingTheme } from '../../../redux/builderSlice';

import { ContainerComponentProps as ContainerComponentPropsInterface } from '../Container/interfaces';
import CreateAndUpdateBlockTemplate from '../../TemplatesPanel/CreateAndUpdateBlockTemplate';
import DropElementArea, { Level, Positions } from './DropElementArea';
import { useCurrentlyEditingBuilderTheme } from '../../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';
import { defaultImage } from '../../../config/builderUi';
import { addOpacityToLinearGradient, convertColorToRGBA } from '../../../helper/sharedFunctions';
import { getElementColor } from '../../../helper/craftJs';
import { CRAFT_ELEMENTS_LABEL } from '../../../config/craftElements';
import {
  defaultContainerMinHeightForDesktop,
  defaultContainerMinHeightForMobile
} from '../../../AdBuilder/interfaces/containerHeightInterface';

const ContainerComponentHOC = ({
  backgroundColor,
  paddingTopClass,
  paddingBottomClass,
  minHeight,
  children,
  isFirstRender,
  backgroundImg,
  opacity,
  backgroundImgEnabled,
  containerMinHeightForMobile,
  containerMinHeightDesktop,
  containerBackgroundAlignment,
  ...props
}: ContainerComponentPropsInterface) => {
  const theme = useCurrentlyEditingBuilderTheme();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { currentNode } = useNode(node => ({
    currentNode: node
  }));
  const isMobileView = useIsMobileView();

  const getContainerMinHeight = isMobileView
    ? backgroundImgEnabled
      ? containerMinHeightForMobile
      : defaultContainerMinHeightForMobile.value
    : backgroundImgEnabled
    ? containerMinHeightDesktop
    : defaultContainerMinHeightForDesktop.value;
  const { actions } = useEditor();

  // defining the function here because have to use getElementColor from craftjs
  const makeGradientForBackgroundImage = (clr: string): string => {
    if (clr.toLowerCase().includes('gradient')) {
      return addOpacityToLinearGradient(getElementColor(clr, theme), parseFloat(opacity as string));
    } else {
      const newColor = convertColorToRGBA(getElementColor(clr, theme), opacity);
      return `linear-gradient(to right, ${newColor}, ${newColor})`;
    }
  };

  const newBackground = makeGradientForBackgroundImage(backgroundColor as string);

  const backgroundImgStyle = backgroundImgEnabled
    ? {
        backgroundImage: `${newBackground}, url(${backgroundImg || defaultImage})`,
        backgroundSize: 'cover',
        backgroundPosition: `${containerBackgroundAlignment || 'center'} center`,
        backgroundRepeat: 'no-repeat',
        minHeight: getContainerMinHeight
      }
    : { background: getElementColor(backgroundColor, theme), minHeight: getContainerMinHeight };
  return (
    <>
      <DropElementArea position={Positions.TOP} level={Level.CONTAINER} />
      <ActionsController
        {...props}
        className={`container__wrapper ${paddingTopClass} ${paddingBottomClass}`}
        style={backgroundImgStyle}
        innerRef={containerRef}
        label={CRAFT_ELEMENTS_LABEL[currentNode.data.displayName]}
      >
        {children}
      </ActionsController>
      <CreateAndUpdateBlockTemplate
        visible={!!currentNode.data.props.showSaveableModal}
        toggleVisible={value => {
          actions.history
            .ignore()
            .setProp(currentNode.id, (state: any) => (state.showSaveableModal = value));
        }}
        containerRef={containerRef.current}
        currentNode={currentNode}
      />
      <DropElementArea position={Positions.BOTTOM} level={Level.CONTAINER} />
    </>
  );
};

export default ContainerComponentHOC;
