import { Col } from 'react-bootstrap';
import React from 'react';

export const ColumnsLayoutUI = (length: number, sizePerCol: number) => {
  return [...Array.from({ length })].map((_, index) => (
    <Col xs={sizePerCol} key={index}>
      <div className="block-col-style"></div>
    </Col>
  ));
};
export const columnsWidths = [
  {
    sm: 12,
    md: 12,
    lg: 12
  },
  {
    sm: 12,
    md: 6,
    lg: 6
  },
  {
    sm: 12,
    md: 6,
    lg: 4
  },
  {
    sm: 12,
    md: 6,
    lg: 3
  },
  {
    sm: 12,
    md: 5,
    lg: 5
  },
  {
    sm: 12,
    md: 5,
    lg: 5
  }
];

export const columnsOneByTwoWidth = [
  {
    sm: 12,
    md: 7,
    lg: 7
  },
  {
    sm: 12,
    md: 7,
    lg: 7
  }
];
