import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';

export const MenuItemContainer = (props: {
  menuItems: Array<{
    title: string;
    moreLink: string;
  }>;
}) => {
  return (
    <>
      {props.menuItems.map(item => {
        return (
          <Link to={`../${item.moreLink}`}>
            <div className="menu-item">
              <div>{`${item.title}`}</div>

              <RightOutlined />
            </div>
          </Link>
        );
      })}
    </>
  );
};
