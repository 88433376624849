import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export type Admin_User = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  imageURL: string;
  activated: boolean;
  createdAt: string;
  billingDetails?: {
    id: number;
    freeForever: boolean;
    maxVisits: number;
    maxWidgets: number;
    maxFunnels: number;
    maxDomains: number;
    maxAgencyCustomers: number;
    remainingSmsCredits: number;
    brandingEnabled: boolean;
    customBrandingEnabled: boolean;
    agencyDashboardEnabled: boolean;
    smsCreditEnabled: boolean;
    paddle_status: string;
    maxGoogleJobs: number;
  };
};

export const GET_USERS = gql`
  query getAdminUsers($input: UsersFilter!) {
    admin_getUsersV2(input: $input) {
      data {
        id
        firstName
        lastName
        email
        imageURL
        activated
        createdAt
        iban
        companyName
        tax
        vat
        billingAddress
        partner {
          partnerId
          status
        }
        billingDetails {
          id
          freeForever
          maxVisits
          maxWidgets
          maxFunnels
          maxDomains
          maxAgencyCustomers
          remainingSmsCredits
          brandingEnabled
          agencyDashboardEnabled
          paddle_status
          paddle_subscription_plan_id
          paddle_next_bill_date
          customBrandingEnabled
          maxGoogleJobs
        }
      }
      meta {
        count
      }
    }
  }
`;

export default function useAdminUsers(variables: any) {
  return useQuery(GET_USERS, {
    variables,
    fetchPolicy: 'no-cache',
    context: {
      headers: {
        'meetovo-cache-disabled': 'true'
      }
    }
  });
}
