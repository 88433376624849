const STORAGE_KEY = 'meetovoRecentUserIcons';

export const setFrequentlyUsedIcons = (name: string) => {
  const icons = JSON.parse(localStorage.getItem(STORAGE_KEY) || '{}');

  if (icons[name]) {
    icons[name] = icons[name] + 1;
  } else {
    icons[name] = 1;
  }

  let sorted = Object.entries(icons)
    // @ts-ignore
    .sort(([, a], [, b]) => b - a)
    .slice(0, 20)
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

  localStorage.setItem(STORAGE_KEY, JSON.stringify(sorted));
};

export const getFrequentlyUsedIcons = () => {
  return Object.keys(JSON.parse(localStorage.getItem(STORAGE_KEY) || '[]'));
};
