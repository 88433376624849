import React, { useState } from 'react';
import { Modal, Space, Switch, Typography } from 'antd';
import QRCode from 'react-qr-code';
import CopyInput from '../../../Share/components/CopyInput';
import { CheckOutlined, CloseOutlined, DesktopOutlined, MobileOutlined } from '@ant-design/icons';
import IFrameModal from '../../../UI/components/IframeModal';

const LinkSharing = ({
  url,
  showTrackingSwitch
}: {
  url: string;
  showTrackingSwitch?: boolean;
}) => {
  const [isFunnelPreviewModalOpen, setIsFunnelPreviewModalOpen] = useState(false);
  const [trackingEnabled, setTrackingEnabled] = useState(true);
  const [isMobileView, setIsMobileView] = useState(false);
  url = !trackingEnabled ? url + '?tracking-deaktiviert=1' : url;

  return (
    <Space direction="vertical" size={20} className="max-width__600px-centered">
      <div
        onClick={() => setIsFunnelPreviewModalOpen(true)}
        className="default-opacity-hover cursor-pointer"
      >
        <div className="qr-code-wrapper meetovo-border">
          <div className="meetovo-border__inner">
            <QRCode
              size={256}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={url}
              viewBox={`0 0 256 256`}
            />
          </div>
        </div>
      </div>
      <div>
        <Typography.Title level={2}>1. Funnel aufrufen</Typography.Title>
        <Typography.Paragraph>
          Über den QR-Code kannst du per Klick oder durch Scannen den Funnel aufrufen. Das Tracking
          kannst für Tests deaktivieren.
        </Typography.Paragraph>
        <CopyInput link={url} setOpenModal={setIsFunnelPreviewModalOpen} />
        {showTrackingSwitch && (
          <div className={`d-flex px-4 justify-content-center`}>
            <div className={`d-flex align-items-center mx-2`}>
              <label className="mb-0">Tracking aktiviert</label>
            </div>
            <div className={`d-flex align-items-center`}>
              <Switch
                defaultChecked
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={checked => setTrackingEnabled(checked)}
              />
            </div>
          </div>
        )}
      </div>
      

      <IFrameModal
        openModal={isFunnelPreviewModalOpen && url.length > 0}
        onModalCancel={() => {
          setIsFunnelPreviewModalOpen(false);
        }}
        domain={url}
      />
    </Space>
  );
};

export default LinkSharing;
