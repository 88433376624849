import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

export default function DefaultSmallLoader({
  loading,
  fontSize,
  makeItCenter
}: {
  loading: boolean;
  fontSize?: number;
  makeItCenter?: boolean;
}) {
  if (!loading) return null;
  const spinner = (
    <Spin indicator={<LoadingOutlined style={{ fontSize: fontSize || 14}} spin />} />
  );
  return makeItCenter ? (
    <div className="d-flex justify-content-center align-items-center">{spinner}</div>
  ) : (
    spinner
  );
}
