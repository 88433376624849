import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { message, Button, Input, Checkbox } from 'antd';
import { LoginMessages } from '../../config/messages';
import { validateEmail } from '../../helper/email';

const SET_USER_INFORMATION = gql`
  mutation setUserInformation($input: UserInformationData!) {
    setUserInformation(input: $input) {
      id
    }
  }
`;

function AlternativeEmailInput({ onSave }: { onSave: Function }) {
  const [setAlternativeEmail, { loading }] = useMutation(SET_USER_INFORMATION);

  const handleSaveEmail = (email: string | null, alternativeEmailEnabled: boolean) => {
    if (email && !validateEmail(email) && alternativeEmailEnabled) {
      message.error(LoginMessages.invalidEmail);
      return;
    }

    setAlternativeEmail({
      variables: {
        input: { alternativeEmail: email, alternativeEmailEnabled, alternativeEmailAdvertisingConsent: false }
      }
    })
      .then(() => {
        onSave();
      })
      .catch(() => {
        message.error(LoginMessages.couldntSetAlternativeEmail);
      });
  };

  return (
    <React.Fragment>
      <h3>Wo sollen wir deine Benachrichtigungen hinsenden?</h3>

      <p>
        Diese E-Mail-Adresse wird genutzt, um dich über neue Bewerbungen zu benachrichtigen.
        Gelegentlich kontaktieren wir dich auch zum Austausch oder bei besonderen Angeboten. Von
        Spam halten wir nichts 😉
      </p>

      <Input.Search
        placeholder="z.B. meine-email@meine-seite.de"
        enterButton="Speichern"
        onSearch={email => handleSaveEmail(email, true)}
        loading={loading}
      />

      <Button
        type="link"
        size="small"
        className="gmail-is-my-main-button medium-text text-secondary text-light"
        onClick={() => handleSaveEmail(null, false)}
      >
        Meine Gmail-Adresse ist meine Hauptadresse
      </Button>
    </React.Fragment>
  );
}

export default AlternativeEmailInput;
