import gql from 'graphql-tag';

export const GET_ALL_DOMAINS = gql`
  query getAllDomains {
    getAllDomains {
      id
      name
      status
      cName
      type
      agencyDashboardId
      slugs {
        id
        slug
        funnelId
      }
      maximumSlugs
    }
  }
`;

export const DELETE_DOMAIN = gql`
  mutation deleteDomain($id: Int!) {
    deleteDomain(id: $id)
  }
`;

export const SET_FUNNEL_DOMAIN = gql`
  mutation setFunnelDomain($domain: FunnelDomainData!) {
    setFunnelDomain(domain: $domain) {
      id
      name
      status
      slugs {
        id
        slug
        funnelId
      }
    }
  }
`;

export const SET_AGENCY_DASHBOARD_DOMAIN = gql`
  mutation setAgencyDashboardDomain($domain: AgencyDashboardDomainData!) {
    setAgencyDashboardDomain(domain: $domain) {
      id
      name
      status
    }
  }
`;