import React, { FC, useEffect, useState } from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import BuilderSettingsTitleWithDelete from '../../../sharedUI/BuilderSettingsTitleWithDelete';
import { useDeleteAdTextTemplateLoading } from '../../../../AdBuilder/redux/adBuilderSlice';

const SelectTemplateCard: FC<{
  template: { text: string; id: number; title?: string };
  currentTemplateId: number;
  handelSelect: (e: any) => void;
  handleDelete?: () => void;
  deleteLoading?: boolean;
}> = ({ template, handelSelect, currentTemplateId, handleDelete, deleteLoading = false }) => {
  const [deleteLocalLoading, setDeleteLocalLoading] = useState(false);
  const deleteAdTextTemplateLoading = useDeleteAdTextTemplateLoading();
  const isActive = currentTemplateId === template.id;
  const text = template.text.length < 400 ? template.text : `${template.text.substring(0, 400)}...`;

  useEffect(() => {
    !deleteAdTextTemplateLoading && deleteLocalLoading && setDeleteLocalLoading(false);
  }, [deleteAdTextTemplateLoading]);

  return (
    <div className={`template-block ${isActive && 'template-block__active'} my-4 px-4`}>
      <BuilderSettingsTitleWithDelete
        title={template.title || ''}
        classNames="template-block__name small-label"
        handleDelete={() => {
          setDeleteLocalLoading(true);
          handleDelete && handleDelete();
        }}
        loading={deleteLocalLoading}
        disabled={template.id >= 100000}
      />

      <div className="template-block__inner default-opacity-hover p-3" onClick={handelSelect}>
        {isActive && (
          <div className="template-block__inner__active-overlay">
            <CheckCircleOutlined />
          </div>
        )}
        <p>{text}</p>
      </div>
    </div>
  );
};

export default SelectTemplateCard;
