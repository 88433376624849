import { Card, Col, Row } from 'antd';
import React from 'react';
import { JOB_ATTRIBUTES_IDENTIFIER } from '../../AdJobBuilder/helper/AdJobHelper';
import { TARGET_GROUP_ELEMENTS } from '../generalSettings/TargetGroup/components/TargetGroupHelper';
import { COMMUNICATION_ELEMENTS, COMPANY_INFORMATION_ELEMENTS } from '../generalSettings/AiDataTab/components/AiDataHelper';
import BuilderCardThumb from './BuilderCardThumb';

type IdentifierType =
  | JOB_ATTRIBUTES_IDENTIFIER
  | TARGET_GROUP_ELEMENTS
  | COMPANY_INFORMATION_ELEMENTS
  | COMMUNICATION_ELEMENTS;

interface OptionsPickerProps<T extends IdentifierType> {
  handleChange: (value: any, identifier?: T) => void;
  value: any;
  options: any[];
  identifier?: T;
  className?: string;
}

export default function OptionsPicker<T extends IdentifierType>({
  options,
  handleChange,
  identifier,
  value,
  className
}: OptionsPickerProps<T>) {
  const handleClick = (itemValue: string) => {
    let newValue;
    if (Array.isArray(value)) {
      if (value.includes(itemValue)) {
        if (value.length <= 1) return;
        newValue = value.filter(val => val !== itemValue);
      } else {
        newValue = [...value, itemValue];
      }
    } else {
      newValue = itemValue;
    }
    handleChange(newValue, identifier);
  };

  return (
    <Row className={`options-picker ${className || ''}`}>
      {options?.map((item: any) => {
        const isActive = value?.includes(item.value);

        return (
          <Col key={item.value} span={8}>
            <Card
              onClick={() => handleClick(item.value)}
              className={`element-card small ${item.disabled &&
                'disable-layer__disabled element-card__disabled'} ${isActive ? 'checked' : ''}`}
            >
              <div className="element-card__check-circle"></div>
              {item.icon && React.cloneElement(item.icon, { className: 'element-card__icon' })}
              <p
                className="element-card__text"
                style={!item.icon ? { marginTop: '0px !important' } : {}}
              >
                {item.label}
              </p>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
}
