import { useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { useIsAgencyOSBuilderPath } from '../useIsAgencyOSBuilderPath';

export const useBuilderMetaData = () => {
  const isAgencyOSBuilderPath = useIsAgencyOSBuilderPath();

  return useAppSelector((state: RootState) =>
    isAgencyOSBuilderPath ? state.agencyOSBuilderUI.metaData : state.builderUI.metaData
  );
};
