import React, { useRef } from 'react';
import { useEditor, useNode } from '@craftjs/core';
// import { useDraggingElement } from '../../../redux/builderSlice';
import { useOnScreen } from '../../../../hooks/useOnScreen';
import {
  containerLevelElements,
  CRAFT_ELEMENTS,
  elementsNeededToShowDropableAreaInsideColumn
} from '../../../config/craftElements';
import { useDraggingElement } from '../../../hooks/useDraggingElement';

export enum Positions {
  TOP = 'top',
  BOTTOM = 'bottom'
}

export enum Level {
  CONTAINER = 'container',
  COLUMN = 'column',
  COLUMN_GRID = 'COLUMN_GRID'
}

const DropElementArea = ({ position, level }: { position?: Positions; level: Level }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const isVisible = useOnScreen(containerRef);
  const draggingElement = useDraggingElement();

  const { query } = useEditor();
  const { currentNode } = useNode(node => ({
    currentNode: node
  }));

  const isValidElementDraggingForDropableArea = (() => {
    if (draggingElement) {
      switch (level) {
        case Level.CONTAINER:
          if (
            query.node(currentNode.data.parent).get().data.displayName !==
            CRAFT_ELEMENTS.MAIN_CONTAINER
          )
            return false;
          return containerLevelElements.includes(draggingElement);
        case Level.COLUMN:
          return elementsNeededToShowDropableAreaInsideColumn.includes(draggingElement);
        case Level.COLUMN_GRID:
          if (draggingElement === CRAFT_ELEMENTS.COLUMN_GRID) return true;
      }
    } else return false;
  })();

  const mainContainerNodes = query.node(currentNode.data.parent).get().data.nodes;

  const getAddContainerNodeId = () => {
    return mainContainerNodes.find(element => {
      if (query.node(element).get()?.data.name === CRAFT_ELEMENTS.ADD_CONTAINER) {
        return element;
      }
    });
  };

  const elementIndexInsideMainContainer = mainContainerNodes
    .filter(nodeId => nodeId !== (getAddContainerNodeId() as string))
    .indexOf(currentNode.id);

  const parentHeight = 15;

  const getStyledObject = () => {
    const childExtraStyling: React.CSSProperties = {
      height: 0
    };

    const parentExtraStyling: React.CSSProperties = {
      paddingTop: 0,
      height: 0
    };

    if (Level.COLUMN_GRID === level) {
      parentExtraStyling.height = '10px';
      childExtraStyling.height = '10px';
    } else if (Level.CONTAINER === level) {
      switch (true) {
        case elementIndexInsideMainContainer === 0 && position === Positions.TOP:
        case elementIndexInsideMainContainer === 0 &&
          mainContainerNodes.length === 1 &&
          position === Positions.BOTTOM:
        case elementIndexInsideMainContainer === 0 &&
          mainContainerNodes.length > 1 &&
          position === Positions.BOTTOM:
        case elementIndexInsideMainContainer === mainContainerNodes.length - 1 &&
          position === Positions.BOTTOM:
        case elementIndexInsideMainContainer > 0 && position === Positions.BOTTOM:
          parentExtraStyling.height = parentHeight;
          childExtraStyling.height = parentHeight;
          break;
      }
    } else if (level === Level.COLUMN) {
      parentExtraStyling.height = '100%';
      childExtraStyling.height = '100%';
    }
    return { parentExtraStyling, childExtraStyling };
  };

  const { parentExtraStyling, childExtraStyling } = getStyledObject();

  const shouldMountArea =
    // isVisible &&
    isValidElementDraggingForDropableArea && parentExtraStyling.height;

  return (
    <div
      ref={containerRef}
      className={`element-dropable-area ${
        level === Level.COLUMN ? 'element-dropable-area__fullsize' : ''
      }`}
    >
      {shouldMountArea ? (
        <div
          style={{
            ...parentExtraStyling
          }}
          className="element-dropable-area__wrapper"
        >
          <div
            className="element-dropable-area__wrapper__inner"
            style={{
              ...childExtraStyling
            }}
          ></div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default DropElementArea;
