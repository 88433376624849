import { FUNNEL_THEME_KEYS } from '../../../interfaces/builderSliceTypes';
import {
  ALIGNMENT_OPTIONS,
  CONTAINER_ALIGNMENT_OPTIONS
} from '../../../../SharedUI/components/alignmentHelpers';
import { ContainerPaddingClasses } from './interfaces';

export enum ContainerSettingsTab {
  SETTINGS = 'Einstellungen',
  TEMPLATES = 'Vorlagen'
}

export const distanceAboveMarks = {
  0: 'S',
  50: 'M',
  100: 'L'
};

export const distanceBelowMarks = distanceAboveMarks;

export const minHeightMobileMarks = {
  1: {
    style: {
      transform: 'translateX(0%)'
    },
    label: '0px'
  },
  2: '100px',
  3: '200px',
  4: '300px',
  5: {
    style: {
      transform: 'translateX(-100%)'
    },
    label: '400px'
  }
};

export const minHeightDesktopMarks = {
  1: {
    style: {
      transform: 'translateX(0%)'
    },
    label: 'auto'
  },
  2: '200px',
  3: '300px',
  4: '400px',
  5: '500px',
  6: '600px'
};

export const containerPaddingsWithSliderValue = [
  { sliderValue: 0, className: 'container__padding-s' },
  { sliderValue: 50, className: 'container__padding-m' },
  { sliderValue: 100, className: 'container__padding-l' }
];

export const ContainerComponentProps = {
  backgroundColor: FUNNEL_THEME_KEYS.BACKGROUND_COLOR,
  paddingTopClass: ContainerPaddingClasses.CONTAINER_PADDING_M_TOP,
  paddingBottomClass: ContainerPaddingClasses.CONTAINER_PADDING_M_BOTTOM,
  minHeight: 260,
  selectedTab: ContainerSettingsTab.SETTINGS,
  isFirstRender: true,
  backgroundImg: '',
  opacity: '0',
  containerMinHeightForMobile: 'auto',
  containerMinHeightDesktop: 'auto',
  backgroundImgEnabled: false,
  align: ALIGNMENT_OPTIONS.START,
  containerBackgroundAlignment: CONTAINER_ALIGNMENT_OPTIONS.CENTER,
};
