import { textTypesEnum } from "../AdBuilder/interfaces/TextTypeInterface";

export enum CRAFT_ELEMENTS {
  BODY = 'Body',
  BUTTON = 'Button',
  LOGIN_COMPONENT = 'LoginComponent',
  LOGIN_CONTAINER = 'LoginContainer',
  IMAGE = 'Image',
  CONTAINER = 'Container',
  ICON = 'Icon',
  MAIN_CONTAINER = 'MainContainer',
  COLUMN_GRID = 'ColumnGrid',
  COLUMN = 'Column',
  VIDEO = 'Video',
  RICH_TEXT = 'RichText',
  ADD_CONTAINER = 'AddContainer',
  DIVIDER = 'Divider',
  LOGO = 'Logo',
  FOOTER = 'Footer',
  CONTACT_FORM = 'ContactForm',
  ELEMENT_APPENDER = 'ElementAppender',
  CHOICE = 'Choice',
  SINGLE_CHOICE = 'SingleChoice',
  MULTIPLE_CHOICE = 'MultipleChoice',
  VOICE_MESSAGE = 'VoiceMessage',
  LONG_ANSWER = 'LongAnswer',
  RANGE_SLIDER = 'RangeSlider',
  CALENDAR = 'Calendar',
  MEDIA_WRAPPER = 'MediaWrapper',
  HTML_WIDGET = 'HTMLWidget',
  CONTAINER_COMPONENT_HOC = 'ContainerComponentHOC',
  UPLOAD_FILE = 'UploadFile',
  CAROUSEL = 'Carousel',
  CAROUSEL_CONTAINER = 'CarouselContainer',
  CAROUSEL_SLIDE = 'CarouselSlide',
  BANNER = 'Banner',
  LINK_BOX = 'LINK_BOX',
  CONTACT = 'Contact',
  SUPPORT_CARD = 'SupportCard',
  CRAFT_CTA = 'CraftCTA',
  JOB_OPTION_PICKER = 'JobOptionPicker',
  CRAFT_ADDRESS_PICKER = 'CraftAddressPicker',
  SALARY = 'Salary',
  DIV = 'div',
  HR = 'hr',
  WIDGET = 'Widget',
  WIDGET_FOOTER = 'WidgetFooter',
  WIDGET_TILES = 'WidgetTiles',
  WIDGET_TILE_WRAPPER = 'WidgetTileWrapper',
  WIDGET_EMPTY_VIEW = 'WidgetEmptyView',
  CRAFT_STATE_RENDERER = 'CraftStateRenderer',
  WIDGET_BODY = 'WidgetBody',
  CRAFT_OPTION_PICKER = 'CraftOptionPicker'
}

export const CRAFT_ELEMENTS_LABEL : any= {
  [CRAFT_ELEMENTS.BODY]: "Body",
  [CRAFT_ELEMENTS.BUTTON]: "Button",
  [CRAFT_ELEMENTS.IMAGE]: "Bild",
  [CRAFT_ELEMENTS.CONTAINER]: "Abschnitt",
  [CRAFT_ELEMENTS.ICON]: "Icon",
  [CRAFT_ELEMENTS.COLUMN_GRID]: "Spalten",
  [CRAFT_ELEMENTS.COLUMN]: "Spalte",
  [CRAFT_ELEMENTS.VIDEO]: "Video",
  [CRAFT_ELEMENTS.RICH_TEXT]: "Text",
  [CRAFT_ELEMENTS.DIVIDER]: "Abstandhalter",
  [CRAFT_ELEMENTS.LOGO]: "Logo",
  [CRAFT_ELEMENTS.FOOTER]: "Footer",
  [CRAFT_ELEMENTS.CONTACT_FORM]: "Kontaktdaten",
  [CRAFT_ELEMENTS.ELEMENT_APPENDER]: "Abschnitt",
  [CRAFT_ELEMENTS.CHOICE]: "Auswahl",
  [CRAFT_ELEMENTS.VOICE_MESSAGE]: "Sprachnachricht",
  [CRAFT_ELEMENTS.LONG_ANSWER]: "Textantwort",
  [CRAFT_ELEMENTS.RANGE_SLIDER]: "Schieberegler",
  [CRAFT_ELEMENTS.CALENDAR]: "Kalender",
  [CRAFT_ELEMENTS.UPLOAD_FILE]: "Datei-Upload",
  [CRAFT_ELEMENTS.HTML_WIDGET]: "HTML",
  [CRAFT_ELEMENTS.CAROUSEL_CONTAINER]: "Bild-Slider",
  [textTypesEnum.JOB_TITLE]: 'Stellenbezeichnung',
  [textTypesEnum.TEASER]: 'Teaser',
  [textTypesEnum.SKILLS]: 'Fähigkeiten',
  [textTypesEnum.RESPONSIBILITIES]: 'Verantwortlichkeiten',
  [textTypesEnum.JOB_MIDDLE_PART]: 'Mittlerer Teil des Jobs',
  [textTypesEnum.JOB_TEASER]: 'Teaser',
  [textTypesEnum.JOB_CTA]: 'Call-To-Action',
  [textTypesEnum.QUALIFICATION]: 'Qualifikation',
  [textTypesEnum.ADVANTAGES]: 'Vorteile',
  [textTypesEnum.TASKS]: 'Aufgaben',
  [textTypesEnum.REQUIREMENTS]: 'Voraussetzungen',
};

export const nonInteractingComponents: string[] = [
  CRAFT_ELEMENTS.BUTTON,
  CRAFT_ELEMENTS.IMAGE,
  CRAFT_ELEMENTS.ICON,
  CRAFT_ELEMENTS.VIDEO,
  CRAFT_ELEMENTS.RICH_TEXT,
  CRAFT_ELEMENTS.DIVIDER,
  CRAFT_ELEMENTS.VOICE_MESSAGE,
  CRAFT_ELEMENTS.CAROUSEL_CONTAINER,
  CRAFT_ELEMENTS.CONTAINER,
  CRAFT_ELEMENTS.ELEMENT_APPENDER,
  CRAFT_ELEMENTS.LOGO,
  CRAFT_ELEMENTS.CRAFT_STATE_RENDERER
];

export const elementsNeededToShowActionIconsOnHover: string[] = [CRAFT_ELEMENTS.VIDEO];

export const disabledElementsOnThankYouPage: string[] = [
  CRAFT_ELEMENTS.ELEMENT_APPENDER,
  CRAFT_ELEMENTS.CALENDAR,
  CRAFT_ELEMENTS.CONTACT_FORM
];

export const containerDependingComponent = [
  CRAFT_ELEMENTS.CONTACT_FORM,
  CRAFT_ELEMENTS.RICH_TEXT,
  CRAFT_ELEMENTS.IMAGE,
  CRAFT_ELEMENTS.VIDEO,
  CRAFT_ELEMENTS.BUTTON,
  CRAFT_ELEMENTS.ICON,
  CRAFT_ELEMENTS.VOICE_MESSAGE,
  CRAFT_ELEMENTS.UPLOAD_FILE,
  CRAFT_ELEMENTS.HTML_WIDGET,
  CRAFT_ELEMENTS.CAROUSEL_CONTAINER
];

export const containerLevelElements: string[] = [
  CRAFT_ELEMENTS.ELEMENT_APPENDER,
  CRAFT_ELEMENTS.CONTAINER,
  CRAFT_ELEMENTS.DIVIDER,
  CRAFT_ELEMENTS.CALENDAR,
  CRAFT_ELEMENTS.CRAFT_STATE_RENDERER,
  ...containerDependingComponent
];

export const elementsNeededToShowDropableAreaInsideColumn: string[] = [
  ...containerDependingComponent,
  CRAFT_ELEMENTS.DIVIDER
];

export const elementsNotReqActionIcons: string[] = [
  CRAFT_ELEMENTS.ADD_CONTAINER,
  CRAFT_ELEMENTS.MAIN_CONTAINER,
  CRAFT_ELEMENTS.MULTIPLE_CHOICE,
  CRAFT_ELEMENTS.SINGLE_CHOICE,
  CRAFT_ELEMENTS.COLUMN,
  CRAFT_ELEMENTS.CAROUSEL,
  CRAFT_ELEMENTS.CAROUSEL_SLIDE,
  CRAFT_ELEMENTS.HR,
  CRAFT_ELEMENTS.DIV
];

export enum BUILDER_IDENTIFIER {
  WIDGET_BUILDER = 'widgetBuilder',
  JOB_BUILDER = 'jobBuilder',
}

export const LeadsQualifiers = [
  CRAFT_ELEMENTS.LONG_ANSWER,
  CRAFT_ELEMENTS.RANGE_SLIDER,
  CRAFT_ELEMENTS.SINGLE_CHOICE,
  CRAFT_ELEMENTS.MULTIPLE_CHOICE,
]
