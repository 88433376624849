import { message } from 'antd';
import { useEffect } from 'react';
import { DontUseBrowserButtonsWarningMessages } from '../../../config/messages';

const DontUseBrowserButtonsWarning = () => {
  useEffect(() => {
    const handlePopState = () => {
      message.warn(DontUseBrowserButtonsWarningMessages.doNotUseTheBrowserButtons, 8);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return null;
};

export default DontUseBrowserButtonsWarning;
