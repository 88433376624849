import React from 'react';
import { Outlet, Route, Routes } from 'react-router';
import { BuilderBase, allRouterRouteDefs } from '../config/builderUi';

const MenuComponentContainer = ({ builderConfig }: { builderConfig: Array<BuilderBase> }) => {
  return (
    <>
      <Routes>
        {allRouterRouteDefs(builderConfig).map((routeDef, index) => {
          if (Array.isArray(routeDef)) {
            routeDef = routeDef[0];
          }
          return <Route key={index} path={routeDef.path} element={routeDef.component}></Route>;
        })}
      </Routes>
      <Outlet />
    </>
  );
};

export default MenuComponentContainer;
