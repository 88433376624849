import { isBase64Image } from '../../GeneralComponents/helper/cropImage';
import gql from 'graphql-tag';

export const UPLOAD_IMAGE = gql`
  mutation uploadImages($input: [ImageData!]!) {
    uploadImages(input: $input) {
      name
      tags
      imageLink
      temporaryKey
    }
  }
`;

export const uploadImages = async (
  objectWithImages: { [key: string]: string },
  keysOfImages: string[]
) => {
  const imagesToUpload = keysOfImages.reduce((acc: any, key) => {
    if (isBase64Image(objectWithImages[key]))
      return [...acc, { imageURL: objectWithImages[key], temporaryKey: key }];
    return acc;
  }, []);

  if (imagesToUpload.length) {
    //@ts-ignore
    const response = await window.apolloClient.mutate({
      mutation: UPLOAD_IMAGE,
      variables: { input: imagesToUpload }
    });

    const { uploadImages } = response.data;

    keysOfImages.forEach((key, i) => {
      const image = uploadImages.find(({ temporaryKey }: any) => {

        return temporaryKey === key;
      });

      if (image?.imageLink) objectWithImages[key] = image?.imageLink;
    });
  }

  return objectWithImages;
};
