import React, { useState } from 'react';
import { useEditor, useNode } from '@craftjs/core';

import { ActionsController } from '../sharedUI/ActionsController';
import { CraftElementBaseProps, getKeyByValue, getValueByKey } from '../../helper/craftJs';
import {
  ImageAvailableBorderRadius,
  ImageAvailableSizes
} from '../../interfaces/ImageSizeInterface';
import { defaultImage } from '../../config/builderUi';
import { ImageLoadingSpinner } from '../../../SharedUI/components/ImageLoadingSpinner';
import { CRAFT_ELEMENTS, CRAFT_ELEMENTS_LABEL } from '../../config/craftElements';
import ImageWithoutCacheOnError from '../sharedUI/ImageWithoutCacheOnError';
import { enhanceStockImage } from '../../helper/images';
import SettingsGB from '../Settings/SettingsGB';
import { ImageSettingsItems } from '../Settings/SettingsTemplates';
import { useElementsPresets } from '../../redux/builderSlice';
import { builderPresetElements } from '../../interfaces/builderSliceTypes';
import { SettingsElement } from '../Settings/settingsGbTypes';

interface Props extends CraftElementBaseProps {
  maxWidth?: string;
  src?: string;
  form?: string;
  align?: string;
  isLoading?: boolean;
  borderRadius?: string;
  settings?: {
    shouldShowImageSizeSlider?: boolean;
    shouldShowImageBorderRadiusSlider?: boolean;
    shouldShowImageAlignment?: boolean;
    showRatioSelector?: boolean;
    showPresetSwitch?: boolean;
    customAspectRatio?: { height: number; width: number };
    freeSelectionEnabled?: boolean;
  };
  isPresetOn?: boolean;
}

const ImageComponent = (props: Props) => {
  const {
    query,
    actions: { history }
  } = useEditor();
  const { id,node } = useNode(node => {
    return { id: node.id, node: node }});

  const [img, setImg] = useState<undefined | string>();

  const presets = useElementsPresets(builderPresetElements.IMAGE);

  const currentStyles = props?.isPresetOn ? { ...props, ...presets } : props;

  const src = enhanceStockImage(props.src as string);

  if (src !== img) {
    if (query.node(id).get()) {
      history.ignore().setProp(id, state => (state.isLoading = true));
      setImg(src);
    }
  }

  const toggleLoading = () => {
    if (!query.node(id).get()) return;

    history.ignore().setProp(id, state => (state.isLoading = false));
  };

  return (
    <ActionsController style={{ display: 'flex', justifyContent: currentStyles.align }} label={CRAFT_ELEMENTS_LABEL[node.data.displayName]}>
      <div
        className="img-element__wrapper"
        style={{ maxWidth: currentStyles.maxWidth, width: '100%' }}
      >
        <ImageWithoutCacheOnError
          width={'100%'}
          style={{
            borderRadius: currentStyles.form === 'round' ? '50%' : currentStyles.borderRadius
          }}
          src={src || defaultImage}
          onLoadCapture={toggleLoading}
          onError={toggleLoading}
          onLoad={toggleLoading}
        />
        {currentStyles.isLoading && <ImageLoadingSpinner />}
      </div>
    </ActionsController>
  );
};

const ImageComponentDefaultProps = {
  maxWidth: '100%',
  form: 'square',
  align: 'left',
  borderRadius: '0px',
  settings: {
    shouldShowImageSizeSlider: true,
    shouldShowImageBorderRadiusSlider: true,
    shouldShowImageAlignment: true,
    showRatioSelector: true,
    showPresetSwitch: true
  },
  isPresetOn: true,
};

export const BuilderImageSettings = () => {
  const {
    actions: { setProp },
    props
  } = useNode(node => ({
    props: node.data.props
  }));

  const presets = useElementsPresets(builderPresetElements.IMAGE);

  const handlePropsChange = (key: string, value: any) => {
    setProp((props: any) => {
      switch (key) {
        case 'maxWidth':
          props.maxWidth = getValueByKey(value, ImageAvailableSizes).value;
          break;
        case 'borderRadius':
          props.borderRadius = getValueByKey(value, ImageAvailableBorderRadius).pixelValue;
          break;
        default:
          props[key] = value;
      }
    });
  };

  const finalProps = props.isPresetOn ? { ...props, ...presets } : props;

  const values = {
    ...finalProps,
    maxWidth: getKeyByValue(props.maxWidth, ImageAvailableSizes).key,
    borderRadius: getKeyByValue(props.borderRadius, ImageAvailableBorderRadius, 'pixelValue').key
  };

  const filteredImageSettingsItems = ImageSettingsItems.map(item => {
    switch (item.key) {
      case 'maxWidth':
        return props.settings.shouldShowImageSizeSlider ? item : null;
      case 'borderRadius':
        return props.settings.shouldShowImageBorderRadiusSlider ? item : null;
      case 'align':
        return props.settings.shouldShowImageAlignment ? item : null;
      case 'isPresetOn':
        return props.settings.showPresetSwitch ? item : null;
      default:
        return item;
    }
  }).filter(Boolean);

  return (
    <SettingsGB
      elements={filteredImageSettingsItems as SettingsElement[]}
      onChange={handlePropsChange}
      values={values}
      settingTitle="Bild"
    />
  );
};

ImageComponent.craft = {
  name: CRAFT_ELEMENTS.IMAGE,
  props: ImageComponentDefaultProps,
  related: {
    settings: BuilderImageSettings
  }
};

export default ImageComponent;
