const JobCtaTextData = {
  title: 'Call to action',
  data: [
    {
      category: 'Gesundheitswesen',
      content: [
        {
          text:`… dann bewirb dich jetzt bei uns als [z.B. Pflegekraft (m/w/d)]. Klicke dafür unten einfach nur auf Button.\n\nDauert nicht länger als zwei Minuten. Kein Lebenslauf nötig. Versprochen!\n\nUnser Bewerbungsschluss ist am ...`,
          title: 'Kein Lebenslauf nötig',
          id: 100014
        },
        {
          text:`Klingt gut? Klicke jetzt auf "Mehr dazu" und schau, ob wir zueinander passen.\n\nBewirb dich in unter 2 Minuten bei uns - ganz ohne Lebenslauf.\n\nUnser Bewerbungsschluss ist am ...`,
          title: `Passen wir zueinander`,
          id: 100015
        },
        {
          text:`Interesse? Deine Bewerbung kannst du ohne Anschreiben und Lebenslauf in unter 2 Minuten zukommen lassen. Jetzt auf den Button klicken.\n\nLass uns gemeinsam…  [Unternehmensvision in 1-2 Sätzen. Höherer Sinn. Die Bedeutung, die die Arbeit hat]\n\nUnser Bewerbungsschluss ist am ...`,
          title: 'Vision',
          id: 100016
        },
        {
          text:`Wir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig.\n\nKlicke jetzt auf den Button und beantworte ein paar kurze Fragen (Dauer: maximal 2 Minuten).\n\nDer Ablauf:\n1. Klicke auf den Button und beantworte die kurzen Fragen\n2. Du erhältst Termine für ein Kennenlerngespräch\n3. Wenn wir zueinander passen, machen wir Dir ein attraktives Jobangebot\n\nUnser Bewerbungsschluss ist am ...`,
          title: 'Nächste Schritte',
          id: 100017
        },
        {
          text:`Unglücklich im jetzigen Job? Wenn du keine Lust mehr hast, jeden Tag bedeutungslose Arbeit zu machen, dann melde dich bei uns. \n\nWir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig. Jetzt auf den Button klicken und in 2 Minuten bewerben.\n\nUnser Bewerbungsschluss ist am ...`,
          title: 'Unglücklich im jetzigen Job',
          id: 100018
        },
        {
          text:`Hast du das Zeug zur [z.B. Pflegekraft (m(w/d)]? Finde es in nur 53 Sekunden heraus.\n\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\n\nUnser Bewerbungsschluss ist am ...`,
          title: 'Hast du das Zeug?',
          id: 100168
        },
        {
          text:`Jetzt klicken und finde in nur 53 Sekunden heraus, ob dieser Job zu dir passt.\n\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\n\nUnser Bewerbungsschluss ist am ...`,
          title: 'Passt dieser Job?',
          id: 100169
        },
        {
          text:`Klingt gut?\n\nDann finde jetzt in 53 Sekunden heraus, ob du Karriere in der Pflege machen kannst.\n\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\n\nUnser Bewerbungsschluss ist am ...`,
          title: 'Klingt gut?',
          id: 100170
        },
        {
          text:`Hast du auch Lust auf eine Karriere in der Pflege?\n\nKlicke jetzt auf den Button und finde in 53 Sekunden heraus, ob du als Pflegekraft (m/w/d) glücklich wirst.\n\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\n\nWir freuen uns auf dich!\n\nUnser Bewerbungsschluss ist am ...`,
          title: 'Lust auf Karriere?',
          id: 100171
        },
        {
          text:`Ready für den Ausbildungsstart [z.B. 2023]? Schau es dir jetzt unser #Abiprogramm an und finde in 53 Sekunden heraus, ob du Karriere in der [KATEGORIE] machen kannst.\n\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\n\nUnser Bewerbungsschluss ist am ...`,
          title: 'Ausbildungsstart',
          id: 100172
        }
      ]
    },
    {
      "category": "Handwerk",
      "content": [
        {
          "text": `… dann bewirb dich jetzt bei uns als [z.B. Handwerker (m/w/d)]. Klicke dafür unten einfach nur auf Button.\n\nDauert nicht länger als zwei Minuten. Kein Lebenslauf nötig. Versprochen!\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Kein Lebenslauf nötig",
          "id": 100045
        },
        {
          "text": `Klingt gut? Klicke jetzt auf "Mehr dazu" und schau, ob wir zueinander passen.\nBewirb dich in unter 2 Minuten bei uns - ganz ohne Lebenslauf.\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Passen wir zueinander",
          "id": 100046
        },
        {
          "text": `Interesse? Deine Bewerbung kannst du ohne Anschreiben und Lebenslauf in unter 2 Minuten zukommen lassen. Jetzt auf den Button klicken.\n\nLass uns gemeinsam…  [Unternehmensvision in 1-2 Sätzen. Höherer Sinn. Die Bedeutung, die die Arbeit hat]\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Vision",
          "id": 100047
        },
        {
          "text": `Wir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig.\n\nKlicke jetzt auf den Button und beantworte ein paar kurze Fragen (Dauer: maximal 2 Minuten).\n\nDer Ablauf:\n1. Klicke auf den Button und beantworte die kurzen Fragen\n2. Du erhältst Termine für ein Kennenlerngespräch\n3. Wenn wir zueinander passen, machen wir Dir ein attraktives Jobangebot\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Nächste Schritte",
          "id": 100048
        },
        {
          "text": `Unglücklich im jetzigen Job? Wenn du keine Lust mehr hast, jeden Tag bedeutungslose Arbeit zu machen, dann melde dich bei uns. \n\nWir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig. Jetzt auf den Button klicken und in 2 Minuten bewerben.\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Unglücklich im jetzigen Job",
          "id": 100049
        },
        {
          "text": `Hast du das Zeug zum [z.B. Handwerker (m/w/d)?] Finde es in nur 53 Sekunden heraus.\n\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Hast du das Zeug?",
          "id": 100192
        },
        {
          "text": `Jetzt klicken und finde in nur 53 Sekunden heraus, ob dieser Job zu dir passt.\n\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Passt dieser Job?",
          "id": 100193
        },
        {
          "text": `Klingt gut?\n\nDann finde jetzt in 53 Sekunden heraus, ob du Karriere im Handwerk machen kannst.\n\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Klingt gut?",
          "id": 100194
        },
        {
          "text": `Hast du auch Lust auf eine Karriere im Handwerk?\n\nKlicke jetzt auf den Button und finde in 53 Sekunden heraus, ob du in der [KATEGORIE] glücklich wirst.\n\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\n\nWir freuen uns auf dich!\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Lust auf Karriere?",
          "id": 100195
        },
        {
          "text": `Ready für den Ausbildungsstart [z.B. 2023]? Schau es dir jetzt unser #Abiprogramm an und finde in 53 Sekunden heraus, ob du Karriere in der [KATEGORIE] machen kannst.\n\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Ausbildungsstart",
          "id": 100196
        }
      ]
    },
    {
      "category": "Handel & Vertrieb",
      "content": [
        {
          "text": `… dann bewirb dich jetzt bei uns als [z.B. Kaufmann/frau (m/w/d)]. Klicke dafür unten einfach nur auf Button.\n\nDauert nicht länger als zwei Minuten. Kein Lebenslauf nötig. Versprochen!\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Kein Lebenslauf nötig",
          "id": 100076
        },
        {
          "text": `Klingt gut? Klicke jetzt auf "Mehr dazu" und schau, ob wir zueinander passen.\nBewirb dich in unter 2 Minuten bei uns - ganz ohne Lebenslauf.\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Passen wir zueinander",
          "id": 100077
        },
        {
          "text": `Interesse? Deine Bewerbung kannst du ohne Anschreiben und Lebenslauf in unter 2 Minuten zukommen lassen. Jetzt auf den Button klicken.\n\nLass uns gemeinsam…  [Unternehmensvision in 1-2 Sätzen. Höherer Sinn. Die Bedeutung, die die Arbeit hat]\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Vision",
          "id": 100078
        },
        {
          "text": `Wir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig.\n\nKlicke jetzt auf den Button und beantworte ein paar kurze Fragen (Dauer: maximal 2 Minuten).\n\nDer Ablauf:\n1. Klicke auf den Button und beantworte die kurzen Fragen\n2. Du erhältst Termine für ein Kennenlerngespräch\n3. Wenn wir zueinander passen, machen wir Dir ein attraktives Jobangebot\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Nächste Schritte",
          "id": 100079
        },
        {
          "text": `Unglücklich im jetzigen Job? Wenn du keine Lust mehr hast, jeden Tag bedeutungslose Arbeit zu machen, dann melde dich bei uns. \n\nWir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig. Jetzt auf den Button klicken und in 2 Minuten bewerben.\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Unglücklich im jetzigen Job",
          "id": 100080
        },
        {
          "text": `Hast du das Zeug zum Kaufmann/frau (m/w/d)? Finde es in nur 53 Sekunden heraus.\n\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Hast du das Zeug?",
          "id": 100216
        },
        {
          "text": `Jetzt klicken und finde in nur 53 Sekunden heraus, ob dieser Job zu dir passt.\n\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Passt dieser Job?",
          "id": 100217
        },
        {
          "text": `Klingt gut?\n\nDann finde jetzt in 53 Sekunden heraus, ob du Karriere im Handel machen kannst.\n\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Klingt gut?",
          "id": 100218
        },
        {
          "text": `Hast du auch Lust auf eine Karriere im Handel?\n\nKlicke jetzt auf den Button und finde in 53 Sekunden heraus, ob du in [KATEGORIE] glücklich wirst.\n\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\n\nWir freuen uns auf dich!\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Lust auf Karriere?",
          "id": 100219
        },
        {
          "text": `Ready für den Ausbildungsstart [z.B. 2023]? Schau es dir jetzt unser #Abiprogramm an und finde in 53 Sekunden heraus, ob du Karriere in der [KATEGORIE] machen kannst.\n\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Ausbildungsstart",
          "id": 100220
        },
      ]
    },
    {
      "category": "Gastronomie",
      "content": [
        {
          "text": `… dann bewirb dich jetzt bei uns als [z.B. Kaufmann/frau (m/w/d)]. Klicke dafür unten einfach nur auf Button.\n\nDauert nicht länger als zwei Minuten. Kein Lebenslauf nötig. Versprochen!\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Kein Lebenslauf nötig",
          "id": 100107
        },
        {
          "text": `Klingt gut? Klicke jetzt auf "Mehr dazu" und schau, ob wir zueinander passen.\nBewirb dich in unter 2 Minuten bei uns - ganz ohne Lebenslauf.\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Passen wir zueinander",
          "id": 100108
        },
        {
          "text": `Interesse? Deine Bewerbung kannst du ohne Anschreiben und Lebenslauf in unter 2 Minuten zukommen lassen. Jetzt auf den Button klicken.\n\nLass uns gemeinsam…  [Unternehmensvision in 1-2 Sätzen. Höherer Sinn. Die Bedeutung, die die Arbeit hat]\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Vision",
          "id": 100109
        },
        {
          "text": `Wir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig.\n\nKlicke jetzt auf den Button und beantworte ein paar kurze Fragen (Dauer: maximal 2 Minuten).\n\nDer Ablauf:\n1. Klicke auf den Button und beantworte die kurzen Fragen\n2. Du erhältst Termine für ein Kennenlerngespräch\n3. Wenn wir zueinander passen, machen wir Dir ein attraktives Jobangebot\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Nächste Schritte",
          "id": 100110
        },
        {
          "text": `Unglücklich im jetzigen Job? Wenn du keine Lust mehr hast, jeden Tag bedeutungslose Arbeit zu machen, dann melde dich bei uns.\n\nWir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig. Jetzt auf den Button klicken und in 2 Minuten bewerben.\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Unglücklich im jetzigen Job",
          "id": 100111
        },
        {
          "text": `Hast du das Zeug zum Koch (m/w/d)? Finde es in nur 53 Sekunden heraus.\n\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Hast du das Zeug?",
          "id": 100240
        },
        {
          "text": `Jetzt klicken und finde in nur 53 Sekunden heraus, ob dieser Job zu dir passt.\n\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Passt dieser Job?",
          "id": 100241
        },
        {
          "text": `Klingt gut?\n\nDann finde jetzt in 53 Sekunden heraus, ob du Karriere in der Gastronomie machen kannst.\n\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Klingt gut?",
          "id": 100242
        },
        {
          "text": `Hast du auch Lust auf eine Karriere in der Gastronomie?\n\nKlicke jetzt auf den Button und finde in 53 Sekunden heraus, ob du in [KATEGORIE] glücklich wirst.\n\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\n\nWir freuen uns auf dich!\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Lust auf Karriere?",
          "id": 100243
        },
        {
          "text": `Ready für den Ausbildungsstart [z.B. 2023]? Schau es dir jetzt unser #Abiprogramm an und finde in 53 Sekunden heraus, ob du Karriere in der [KATEGORIE] machen kannst.\n\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Ausbildungsstart",
          "id": 100244
        }
      ]
    },
    {
      "category": "Industrie",
      "content": [
        {
          "text": `… dann bewirb dich jetzt bei uns als [z.B. Kaufmann/frau (m/w/d)]. Klicke dafür unten einfach nur auf Button.\n\nDauert nicht länger als zwei Minuten. Kein Lebenslauf nötig. Versprochen!\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Kein Lebenslauf nötig",
          "id": 100138
        },
        {
          "text": `Klingt gut? Klicke jetzt auf "Mehr dazu" und schau, ob wir zueinander passen.\nBewirb dich in unter 2 Minuten bei uns - ganz ohne Lebenslauf.\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Passen wir zueinander",
          "id": 100139
        },
        {
          "text": `Interesse? Deine Bewerbung kannst du ohne Anschreiben und Lebenslauf in unter 2 Minuten zukommen lassen. Jetzt auf den Button klicken.\n\nLass uns gemeinsam…  [Unternehmensvision in 1-2 Sätzen. Höherer Sinn. Die Bedeutung, die die Arbeit hat]\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Vision",
          "id": 100140
        },
        {
          "text": `Wir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig.\n\nKlicke jetzt auf den Button und beantworte ein paar kurze Fragen (Dauer: maximal 2 Minuten).\n\nDer Ablauf:\n1. Klicke auf den Button und beantworte die kurzen Fragen\n2. Du erhältst Termine für ein Kennenlerngespräch\n3. Wenn wir zueinander passen, machen wir Dir ein attraktives Jobangebot\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Nächste Schritte",
          "id": 100141
        },
        {
          "text": `Unglücklich im jetzigen Job? Wenn du keine Lust mehr hast, jeden Tag bedeutungslose Arbeit zu machen, dann melde dich bei uns.\n\nWir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig. Jetzt auf den Button klicken und in 2 Minuten bewerben.\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Unglücklich im jetzigen Job",
          "id": 100142
        },
        {
          "text": `Hast du das Zeug zum [z.B. Staplerfahrer (m/w/d)]? Finde es in nur 53 Sekunden heraus.\n\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Hast du das Zeug?",
          "id": 100264
        },
        {
          "text": `Jetzt klicken und finde in nur 53 Sekunden heraus, ob dieser Job zu dir passt.\n\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Passt dieser Job?",
          "id": 100265
        },
        {
          "text": `Klingt gut?\n\nDann finde jetzt in 53 Sekunden heraus, ob du Karriere in der Industrie machen kannst.\n\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Klingt gut?",
          "id": 100266
        },
        {
          "text": `Hast du auch Lust auf eine Karriere in der Industrie?\n\nKlicke jetzt auf den Button und finde in 53 Sekunden heraus, ob du in [KATEGORIE] glücklich wirst.\n\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\n\nWir freuen uns auf dich!\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Lust auf Karriere?",
          "id": 100267
        },
        {
          "text": `Ready für den Ausbildungsstart [z.B. 2023]? Schau es dir jetzt unser #Abiprogramm an und finde in 53 Sekunden heraus, ob du Karriere in der [KATEGORIE] machen kannst.\n\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\n\nUnser Bewerbungsschluss ist am ...`,
          "title": "Ausbildungsstart",
          "id": 100268
        }
      ]
    }
  ]
};

export default JobCtaTextData;
