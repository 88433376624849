import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const CREATE_BOOKING_ACTION_EVENTS = gql`
mutation CreateBookingActionEvent($input: CreateBookingActionEventInput!) {
  createBookingActionEvent(input: $input) {
    id
    eventTime
    actionEventType
  }
}
`;

export function setCreateBookingActionEvents() {
  return useMutation(CREATE_BOOKING_ACTION_EVENTS);
}

export const GET_BOOKINGS_ACTIONS_EVENTS = gql`
query getBookingActionEvents($bookingId:Int!){
  getBookingActionEvents(bookingId:$bookingId){
    id
    eventTime
    actionEventType
  }
}
`;