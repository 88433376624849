import { Row, Switch, Col } from 'antd';
import React from 'react';
import BuilderSettingsTitle from './BuilderSettingsTitle';

type BuilderSettingWithSwitchProps = {
  title: string;
  icon?: React.ReactNode;
  checked: boolean;
  onChange: (checked: boolean) => void;
  description?: string;
};

const BuilderSettingsTitleWithSwitch = ({
  title,
  checked,
  onChange,
  icon,
  description
}: BuilderSettingWithSwitchProps) => {
  return (
    <>
      <Row>
        <Col span={22}>
          <BuilderSettingsTitle title={title} icon={icon} />
        </Col>
        <Col span={2}>
          <Switch size="small" checked={checked} onChange={onChange} />
        </Col>
      </Row>
      {description && <Row>{description}</Row>}
    </>
  );
};

export default BuilderSettingsTitleWithSwitch;
