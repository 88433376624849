import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export const GET_FUNNEL_TEMPLATES = gql`
  query funnelTemplates {
    funnelTemplates {
      id
      title
      image
      craftState
      public
      userId
      tags
    }
  }
`;

export default function useGetAllFunnelTemplate() {
  return useQuery(GET_FUNNEL_TEMPLATES);
}
