import React, { useState } from 'react';
// import { Document, Page } from 'react-pdf/dist/entry.webpack';
import { Document, Page, pdfjs } from 'react-pdf'
import { FullscreenOutlined } from '@ant-design/icons';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const ContractDocument = ({ source }: { source: string }) => {
    const [numPages, setNumPages] = useState(0)

    const onDocumentLoadSuccess = ({ numPages }: any) => {
        setNumPages(numPages);
    }

    if (!source && numPages > 0) {
        return <div>Loading...</div>;
    }

    const src = process.env.NODE_ENV === "development" ? process.env.PUBLIC_URL + "/meetovo-vertrag-uber-software-as-a-service-leistungen-saas.pdf" : source;

    return (
        <div className="contract-modal__document">
            <div className="contract-modal__controlls-wrapper">
                <a
                    className="contract-modal__open-fullscreen"
                    target="_blank"
                    href={src}
                >
                    <FullscreenOutlined />
                    Im Vollbild öffnen oder drucken
                </a>
            </div>

            <div
                className="contract-modal__contract-wrapper"
                style={{ overflowY: 'scroll', height: 300, width: "100%" }}
            >
                <Document
                    loading="Wird geladen..."
                    file={src}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    {
                        Array.from(
                            new Array(numPages),
                            (el, index) => (
                                <div
                                    style={{ margin: 10 }}
                                    key={`page_${index + 1}`}
                                >
                                    <Page
                                        width={document.getElementsByClassName("contract-modal__contract-wrapper")[0].clientWidth - 10}
                                        pageNumber={index + 1}
                                    />
                                </div>
                            ),
                        )
                    }
                </Document>
            </div>
        </div>
    );
};

export default ContractDocument;