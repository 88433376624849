import React from 'react';
import * as Sentry from '@sentry/browser';
import { Alert, Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import DefaultLoader from '../SharedUI/components/DefaultLoader';
import store from '../redux/store';

interface ErrorBoundaryState {
  hasError?: boolean;
  errorMessage: { title?: string; message: string; error: string; button?: React.ReactElement };
}
declare global {
  interface Window {
    meetovoCapturedMessages: any[];
  }
}

const KnownErrors = [
  {
    error: 'Invalid token',
    title: 'Zugangscode abgelaufen',
    message: 'Bitte logge dich erneut ein, um deinen Zugangscode zu erneuern.',
    button: (
      <Button
        onClick={() => window.handleLogout()}
        style={{ display: 'block', marginTop: '1em' }}
      >
        <ReloadOutlined />
        Erneut einloggen
      </Button>
    )
  },
  {
    error: `Failed to execute 'removeChild' on 'Node'`,
    message:
      'Bitte verwende keine Übersetzungs-Plugins oder ähnliches, da dies möglicherweise Fehler in Textfeldern verursacht. Bitte klicke auf "Neu laden". Sollte dies nicht helfen, melde dich über den Support-Chat. Wir lösen das Problem für dich.'
  }
];

class ErrorBoundary extends React.Component<any, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, errorMessage: { error: '', title: '', message: '' } };
  }
  componentDidMount(): void {
    // Create an empty array to store the captured messages
    window.meetovoCapturedMessages = [];
    // Override console methods to capture messages
    (function() {
      // Save a reference to the original console object
      var originalConsole = Object.assign({}, console);

      // Override console.log method
      console.log = function() {
        window.meetovoCapturedMessages.push({
          level: 'log',
          message: Array.from(arguments)
        });
        // @ts-ignore
        originalConsole.log.apply(originalConsole, arguments);
      };

      // Override console.error method
      console.error = function() {
        window.meetovoCapturedMessages.push({
          level: 'error',
          message: Array.from(arguments)
        });
        // @ts-ignore
        originalConsole.error.apply(originalConsole, arguments);
      };

      // Override console.warn method
      console.warn = function() {
        window.meetovoCapturedMessages.push({
          level: 'warn',
          message: Array.from(arguments)
        });
        // @ts-ignore
        originalConsole.warn.apply(originalConsole, arguments);
      };

      // Add more overrides for other console methods if desired
    })();
  }

  componentDidCatch(error: any, errorInfo: any) {
    const knownError = KnownErrors.find(kerror => {
      if (error.message.includes(kerror.error)) {
        return true;
      }
    });

    if (knownError) {
      this.setState({ errorMessage: knownError, hasError: true });
      return;
    }

    Sentry.withScope(scope => {
      window.location.href.includes('builder')
        ? scope.setExtras({
            currentPageCraftState: window.__CRAFT_STATE__,
            currentStepForSentryDebugging: window.currentStepForSentryDebugging
          })
        : scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
    if (window.letExistingExceptionThrough) {
      setTimeout(() => {
        const selectedPageId = store.getState().builderUI.selectedPageId;
        window.location.href = `${window.location.href}?selectedPageId=${selectedPageId}`;
      }, 500);
    }
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return window.letExistingExceptionThrough ? (
        <DefaultLoader loading />
      ) : (
        <Alert
          message={this.state.errorMessage.title || 'Ooops, da ist etwas schief gelaufen 😬'}
          description={
            <>
              {this.state.errorMessage.message ||
                'Es gab einen Fehler beim Laden. Bitte klicke auf "Neu laden". Sollte dies nicht helfen, melde dich über den Support-Chat. Wir lösen das Problem für dich.'}
              <br />
              {this.state.errorMessage.button || (
                <Button
                  onClick={() => location.reload()}
                  style={{ display: 'block', marginTop: '1em' }}
                >
                  <ReloadOutlined />
                  Neu laden
                </Button>
              )}
            </>
          }
          type="warning"
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
