import React, { useEffect } from 'react';
import { Button, Card, Form, Input, message } from 'antd';
import {
  ConnectSettingsMessages,
  CustomDomainsMessages,
  GeneralMessages
} from '../../../config/messages';
import { useMutation } from '@apollo/react-hooks';
import {
  DELETE_DOMAIN,
  GET_ALL_DOMAINS,
  SET_AGENCY_DASHBOARD_DOMAIN
} from '../../utils/domainQueries';
import { DomainValidatiorV2 } from '../../utils/domainAndSlugValidation';
import DomainStatusIndicator from './DomainStatusIndicator';
import { forceLowerCase } from '../../utils/domainInput';
import mixpanel from 'mixpanel-browser';
import { mixpanelEvents } from '../../../UI/utils/mixpanel';
import DeleteButtonWithConfirmation from '../../../SharedUI/components/DeleteButtonWithConfirmation';

const EditAgencyDashboardDomain = ({
  domain,
  domainCount,
  subdomainCount,
  maxDomains,
  refetchSubscriptionInfo
}: any) => {
  const [form] = Form.useForm();
  const [setDomain, { loading }] = useMutation(SET_AGENCY_DASHBOARD_DOMAIN, {
    refetchQueries: [{ query: GET_ALL_DOMAINS }]
  });
  const [deleteDomain, { loading: deleteDomainLoading }] = useMutation(DELETE_DOMAIN, {
    refetchQueries: [{ query: GET_ALL_DOMAINS }]
  });

  useEffect(() => {
    form.setFieldsValue({
      name: domain.name
    });
  }, [domain]);

  const handleSubmit = (values: any) => {
    const newDomain = {
      ...(domain.id ? { id: domain.id } : {}),
      name: values.name
    };
    setDomain({ variables: { domain: newDomain } })
      .then(() => {
        refetchSubscriptionInfo();
        mixpanel.track(mixpanelEvents.AGENCY_DASHBOARD_DOMAIN_CONNECTED);
        message.success(GeneralMessages.success);
      })
      .catch(err => {
        if ((err?.message as string)?.toLowerCase()?.includes('already existing')) {
          message.error(ConnectSettingsMessages.domainAlreadyConnected);
          return;
        }
        message.error(GeneralMessages.error);
      });
  };

  return (
    <Card className="ui__white-round-wrapper" style={{ marginBottom: '1em' }}>
      <Form
        name="add-domain-form"
        form={form}
        layout="vertical"
        scrollToFirstError={true}
        onFinish={handleSubmit}
      >
        <Form.Item
          required
          rules={DomainValidatiorV2(domainCount, subdomainCount, maxDomains as number)}
          label="Domain"
          name="name"
          style={{ marginBottom: '0.5em' }}
        >
          <Input placeholder="z.B. deine-domain.de" onInput={forceLowerCase} />
        </Form.Item>
        {domain.name && (
          <div className="d-flex justify-content-between">
            <DomainStatusIndicator status={domain.status} />
            <DeleteButtonWithConfirmation
              loading={deleteDomainLoading}
              onDelete={() =>
                deleteDomain({ variables: { id: domain.id } })
                  .then(() => {
                    refetchSubscriptionInfo();
                  })
                  .catch(() => message.error(CustomDomainsMessages.couldNotDeleteDomain))
              }
            />
          </div>
        )}
        <Button
          style={domain.name ? { marginTop: '0.75em' } : {}}
          type="primary"
          onClick={() => form.submit()}
          loading={loading}
        >
          Speichern
        </Button>
      </Form>
    </Card>
  );
};

export default EditAgencyDashboardDomain;
