import React from 'react';
import ProductFruits from 'react-product-fruits';
import { getEnvironment } from '../../helper/environment';
import {
  QuestionConfig,
  questions
} from '../../OnBoarding/utils/question-config';
import { useAppSelector } from '../../redux/hooks';
import { selectUserData } from '../../UI/redux/userSlice';
import { selectProductFruitsProps } from '../redux/productFruitsSlice';

function ProductFruitsWrapper() {
  const userData = useAppSelector(selectUserData);
  const props = useAppSelector(selectProductFruitsProps);
  const { id, firstName, createdAt, questionsAnswered } = userData || { questionsAnswered: [] };
  const propObject: { [key: string]: string } = { ...props };
  const isDev = getEnvironment().isDev;

  if (questionsAnswered.length)
    questions.forEach((elem, index) => {
      let thisAnswer = questionsAnswered.find(({ question }: QuestionConfig) => {
        return question == elem.question;
      })?.answer;

      // to rewrite the previews value and make it compatible
      if (thisAnswer === 'Bewerbungen') thisAnswer = 'Mitarbeiter gewinnen';

      const activeCampaignFieldValue = elem.choices.find(({ title }) => title === thisAnswer)
        ?.activeCampaignField.fieldValue;

      propObject[`question_${elem.id}`] = `${activeCampaignFieldValue || 'Leads'}`;
    });

  if (!userData || !Object.keys(props).length) return null;

  return (
    <ProductFruits
      projectCode="L6x6kxrzshWcCFXz"
      language="de"
      username={`${isDev ? 'IS_DEV_' : ''}${id}`}
      email={''}
      firstname={firstName}
      lastname={''}
      signUpAt={createdAt}
      role={''}
      props={propObject}
    ></ProductFruits>
  );
}

export default ProductFruitsWrapper;
