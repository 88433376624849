import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';
import { EVENT_VIEWS } from '../../Events/utils/eventViewsTypes';
import moment from 'moment';
import { defaultPage, StateInterface } from '../../Events/container/Events';
import { modules } from '../../LearningCenter/utils/videos';
interface UIState {
  confettiMessage: string;
  globalSettingsFlags: { key: string, value: string }[] | null;
  funnelViewSelection: EVENT_VIEWS;
  isFunnelRefreshLoading: boolean;
  isEventRefreshLoading: boolean;
  eventsDownloading: boolean;
  availableBookingStatuses: {
    color: string;
    status: string;
    value: string;
    id: number;
  }[];
  eventFilterState: StateInterface;
  challengeModuleName: string | null;
  challengeProgressPercent: number;
  challengeModulesCompleted: number;
}

const INITIAL_STATE: UIState = {
  confettiMessage: "",
  globalSettingsFlags: null,
  funnelViewSelection: EVENT_VIEWS.KANBAN,
  isFunnelRefreshLoading: false,
  isEventRefreshLoading: false,
  eventsDownloading: false,
  availableBookingStatuses: [],
  eventFilterState: {
    bookingFilter: {
      start: moment().startOf('day').subtract(2, 'weeks'),
      end: moment().startOf('day').add(2, 'weeks'),
      type: 'ALL',
      pagination: {
        currentPageNum: 1,
        booking: {
          offset: defaultPage.offset,
          limit: defaultPage.limit
        },
        tracking: {
          offset: defaultPage.offset,
          limit: defaultPage.limit
        }
      },
      funnelIds: []
    },
    expandedEvent: undefined,
    needlessEvent: undefined,
    initiated: false
  },
  challengeModuleName: null,
  challengeProgressPercent: 0,
  challengeModulesCompleted: 0
};

export const uiSlice = createSlice({
  name: 'ui',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: INITIAL_STATE,
  reducers: {
    resetToInitialUIState: () => {
      return INITIAL_STATE;
    },
    setConfettiMessage: (state, action: PayloadAction<any>) => {
      state.confettiMessage = action.payload;
    },
    setGlobalSettingsFlags: (state, action: PayloadAction<any>) => {
      state.globalSettingsFlags = action.payload;
    },
    setFunnelViewSelection: (state, action: PayloadAction<any>) => {
      state.funnelViewSelection = action.payload;
    },
    setFunnelRefreshLoading: (state, action: PayloadAction<any>) => {
      state.isFunnelRefreshLoading = action.payload;
    },
    setEventFilters: (state, action: PayloadAction<any>) => {
      state.eventFilterState = action.payload;
    },
    setEventsDownloading: (state, action: PayloadAction<any>) => {
      state.eventsDownloading = action.payload;
    },
    setEventRefreshLoading: (state, action: PayloadAction<any>) => {
      state.isEventRefreshLoading = action.payload;
    },
    setAvailableBookingStatuses: (state, action: PayloadAction<any>) => {
      state.availableBookingStatuses = action.payload;
    },
    setChallengeModuleName: (state, action: PayloadAction<any>) => {
      state.challengeModuleName = action.payload;
    },
    setChallengeProgressPercent: (state, action: PayloadAction<any>) => {
      state.challengeProgressPercent = action.payload;
    },
    setChallengeModulesCompleted: (state, action: PayloadAction<any>) => {
      state.challengeModulesCompleted = action.payload;
    }
  }
});

export const { 
  resetToInitialUIState, 
  setConfettiMessage, 
  setGlobalSettingsFlags, 
  setFunnelViewSelection, 
  setFunnelRefreshLoading,
  setEventFilters,
  setEventsDownloading,
  setEventRefreshLoading,
  setAvailableBookingStatuses,
  setChallengeModuleName,
  setChallengeProgressPercent,
  setChallengeModulesCompleted
} = uiSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectConfettiMessage = (state: RootState) => state.ui.confettiMessage;
export const selectGlobalSettingsFlags = (state: RootState) => state.ui.globalSettingsFlags;
export const selectedFunnelViewSelection = (state: RootState) => state.ui.funnelViewSelection;
export const isFunnelRefreshLoading = (state: RootState) => state.ui.isFunnelRefreshLoading;
export const isEventRefreshLoading = (state: RootState) => state.ui.isEventRefreshLoading;
export const eventsDownloading = (state: RootState) => state.ui.eventsDownloading;
export const eventFilterState = (state: RootState) => state.ui.eventFilterState;
export const availableBookingStatuses = (state: RootState) => state.ui.availableBookingStatuses;
export const selectChallengeModuleName = (state: RootState) => state.ui.challengeModuleName;
export const challengeProgressPercent = (state: RootState) => state.ui.challengeProgressPercent;
export const challengeModulesCompleted = (state: RootState) => state.ui.challengeModulesCompleted;

export default uiSlice.reducer;
