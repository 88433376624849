import React from 'react';
import { Route, Routes } from 'react-router-dom';
import routes from '../routes';
import { getAllRoutes } from '../utils/routeUtils';
import SuspenseLoadingWrapper from '../../Share/container/SuspenseLoadingWrapper';

const BlankUI = () => {
  const allRoutes = getAllRoutes(routes);
  return (
    <>
      <SuspenseLoadingWrapper>
        <Routes>
          {allRoutes.map(({ path, component }, i) => (
            <Route key={i} path={path} element={component} />
          ))}
        </Routes>
      </SuspenseLoadingWrapper>
    </>
  );
};

export default BlankUI;
