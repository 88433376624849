import { GET_FUNNEL_DATA } from "../utils/queries";

export const getFunnelDataMock = {
  request: {
    query: GET_FUNNEL_DATA,
    variables: {}
  },
  result: {
    "data": {
      "getUser": {
        "firstName": "Lukas",
        "lastName": "Bludau",
        "email": "mail@lukasbludau.de",
        "quote": null,
        "calendarId": "mail@lukasbludau.de",
        "imageURL": "https://lh3.googleusercontent.com/-5gXbCOrhc8Q/AAAAAAAAAAI/AAAAAAAAAAA/AKF05nDpDQGDNe5lMygmtP97l56iVKHasw/photo.jpg",
      },
      "getShifts": [
        {
          "id": 36,
          "start": "13:00",
          "end": "16:00",
          "dow": [
            1,
            2,
            6,
            7
          ]
        }
      ],
      "getSpecialClosures": [
        { id: 1, end: "2020-03-25T16:33:22+00:00", start: "2020-03-16T16:33:22+00:00" },
        { id: 2, end: "2020-03-14T16:33:47+00:00", start: "2020-03-01T16:33:47+00:00" },
        { id: 3, end: "2020-03-14T16:33:47+00:00", start: "2020-03-01T16:33:47+00:00" }
      ],
      "getCalendars": [
        {
          id: "mail@lukasbludau.de",
          primary: true,
          summary: "Mein Kalender",
          summaryOverride: null,
          backgroundColor: "#42f548",
          foregroundColor: null
        },
        {
          id: "mail@lukasbludau2.de",
          primary: false,
          summary: "Mein zweiter Kalender",
          summaryOverride: null,
          backgroundColor: "#42f584",
          foregroundColor: null
        }
      ]
    }
  },
}

export default [getFunnelDataMock, getFunnelDataMock]