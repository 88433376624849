import { Tooltip } from 'antd';
import React from 'react';
import { tooltipText } from '../../helper/tooltipText';
import { BsArrowLeft, BsArrowRight, BsFillTrashFill } from 'react-icons/bs';
import SwitchTemplate from '../../components/elements/RichTextComponent/components/SwitchTemplate';
import { TemplateContent, useTextTemplateData } from '../../AdBuilder/data';
import { textTypesEnum } from '../../AdBuilder/interfaces/TextTypeInterface';

export interface ArrowActionsTypes {
  elementType: textTypesEnum;
}
const ArrowAction = ({ elementType }: ArrowActionsTypes) => {
  const userTemplate: TemplateContent[] = [];
  const content = useTextTemplateData(elementType);

  return (
    <>
      <Tooltip title={tooltipText.previous}>
        <li>
          <SwitchTemplate direction="PREV" userTemplate={userTemplate} content={content}>
            <BsArrowLeft />
          </SwitchTemplate>
        </li>
      </Tooltip>
      <Tooltip title={tooltipText.next}>
        <li>
          <SwitchTemplate direction="NEXT" userTemplate={userTemplate} content={content}>
            <BsArrowRight />
          </SwitchTemplate>
        </li>
      </Tooltip>
    </>
  );
};

export default ArrowAction;
