const getVimeoThumbnail = async (url: string) => {
  const id = url.split('/')[3];

  try {
    const response = await fetch('https://vimeo.com/api/v2/video/' + id + '.json');
    const data = await response.json();
    const { thumbnail_large } = data[0];

    return thumbnail_large;
  } catch (error) {
    return '';
  }
};

const getYouTubeThumbnail = (url: string) => {
  const id = url.split('v=')[1];
  return 'https://img.youtube.com/vi/' + id + '/hqdefault.jpg';
};

const getWisitaThumbnail = async (url: string) => {
  const id = url.split('/')[4].replace('.bin', '');
  const thumbnail = 'https://embed.wistia.com/deliveries/' + id + '.jpg?video_still_time=10';

  try {
    await fetch(thumbnail);
    return thumbnail;
  } catch (error) {
    return '';
  }
};

export const getVideoThumbnail = async (url: string) => {
  if (!url) return '';
  if (url.includes('vimeo')) return await getVimeoThumbnail(url);
  if (url.includes('youtube')) return getYouTubeThumbnail(url);
  if (url.includes('wisitia')) return await getWisitaThumbnail(url);

  return '';
};

// export const testVideoThumbnauil = async () => {
//   const thumbnail1 = await getVimeoThumbnail('https://vimeo.com/278844455');
//   const thumbnail2 = await getYouTubeThumbnail('https://www.youtube.com/watch?v=Qne7Jdtr7qA');
//   const thumbnail3 = await getWisitaThumbnail(
//     'https://embed.wistia.com/deliveries/5413caeac5fdf4064a2f9eab5c10a0848e42f19f.bin'
//   );

//   console.log(thumbnail1,thumbnail2,thumbnail3);
// };
