import gql from 'graphql-tag';

export interface Input {
  name: string;
  craftState: string;
  imageUrl: string;
}

const CREATE_AD_MEDIA_TEMPLATE = gql`
  mutation createAdMediaTemplate($input: CreateAdMediaTemplateInput!) {
    createAdMediaTemplate(input: $input) {
      id
      name
      craftState
      imageUrl
    }
  }
`;

export default function createAdMediaTemplate(input: Input) {
  return window.apolloClient.mutate({
    mutation: CREATE_AD_MEDIA_TEMPLATE,
    variables: { input }
  });
}
