import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { getMeetovoAuthData } from '../../Login/container/Login';

export enum mixpanelEvents {
  LOGIN = 'Login',
  DELETE_ACCOUNT = 'Delete Account',
  CREATE_FUNNEL = 'Create Funnel',
  AD_DOWNLOAD = 'Ad Download',
  FUNNEL_DOMAIN_CONNECTED = 'Funnel Domain Connected',
  AGENCY_DASHBOARD_DOMAIN_CONNECTED = 'Agency Dashboard Domain Connected',
  SUBSCRIPTION_UPGRADE = 'Subscription Upgrade',
  CREATE_AD = 'Create Ad',
  SEND_MESSAGE_TO_SUPPORT = 'Send Message to Support',
  ONBOARDING_CHECKLIST_VIEWED = 'Onboarding Checklist Viewed',
  ADDED_LOGO_INSIDE_FUNNEL = 'Added Logo Inside Funnel',
  ADDED_SENDER_INSIDE_EMAIL_SETTINGS = 'Added Sender Inside Email Settings',
  ADDED_SENDER_INSIDE_MESSAGING_SETTINGS = 'Added Sender Inside Message Settings',
  ADDED_MESSAGING_NUMBER_INSIDE_MESSAGING_SETTINGS = 'Added Message Number Inside Message Settings',
  ADDED_PHONE_NUMBER_INSIDE_MESSAGING_SETTINGS = 'Added Phone Number Inside Message Settings',
  SELECTED_THEME_FOR_FUNNEL = 'Selected Theme For Funnel',
  TIME_SPEND_INSIDE_BUILDER = 'Time Spend Inside Builder',
  CREATED_AGENCY_DASHBOARD_USER = 'Created Agency Dashboard User',
  ADDED_USER_TO_SHARE_ACCOUNT = 'Added User To Share Account',
  THIRD_PARTY_TRACKING = 'Third Party Tracking',
  WEBHOOK_CONNECTED = 'Connected WebHook',
  REDIRECT_VIDEO_EDITING = "Redirect Video Editing",
  HEADLINE_FONTS = "Headline Fonts",
  TEXT_FONTS = "Text Fonts",
  OPEN_CUSTOM_FONT_UPLOAD_MODAL = "Open Custom Font Upload Modal",
  CLOSE_CUSTOM_FONT_UPLOAD_MODAL = "Close Custom Font Upload Modal",
  UPLOAD_FILE = 'Upload File',
  UPLOAD_SUBMIT_BUTTON = "Upload Submit Button" ,
  CANCEL_UPLOADED_FILE = "Cancel Uploaded File" ,
  INPUT_USP = "Input USP In AI",
  COMPANY_ADVANTAGES = "Company Advantages",
  COMPANY_DESCRIPTION  = "Company Description",
  INPUT_SPEECH = 'Input Speech',
  WRITING_STYLE = 'Write Style' ,
  EMPLOYEE_AGE = 'Employee Age',
  EMPLOYEE_GENDER = 'Employee Gender',
  EMPLOYEE_GOALS = 'Employee Goals',
  EMPLOYEE_SKILLS = 'Employee Skills',
  EMPLOYEE_EXPERIENCE = "Employee Experience",
  EMPLOYEE_EXPERIENCE_DURATION = "Employee Experience Duration",
  EMPLOYEE_EDUCATION = "Employee Education",
  JOB_CATEGORY="Job Category",
  JOB_EMPLOYEMENT_TYPE = "Job Employement Type",
  SALARY_PLAN = "Salary Plan",
  WORKPLACE_MODEL ="Workplace Modal",
  JOB_ADDRESS="Job Address",
  TEXT_TYPE = "TEXT_TYPE",
  GO_TO_AI_SETTING = 'Go To Ai Setting',
  TEXT_GENERATION_BUTTON = "Text Generation Button",
  CLICK_ON_GENERATED_TEXT_CARD = "Click_On_Generated_Text_Card "
}

export const getFunnelIdFromToken = ():number => {
  const decodedToken: {
    funnelId: number;
  } = jwt_decode(JSON.parse(getMeetovoAuthData() || '{}')?.token);
  return decodedToken?.funnelId || 0;
};

export const getMixPanelEventExistingTrackingData = (
  eventName: string,
  isFunnelIdCheckReq: boolean = true
) => {
  const eventData = JSON.parse(localStorage.getItem('mixpanelEventsTrack') || '{}')[eventName];
  if (eventData && moment().diff(moment(eventData.trackTime), 'hours') <= 24) {
    if (isFunnelIdCheckReq) {
      const decodedToken: {
        funnelId: string;
      } = jwt_decode(JSON.parse(getMeetovoAuthData() || '{}')?.token);
      if (eventData.funnelId === decodedToken.funnelId) return eventData;
      else return undefined;
    }
    return eventData;
  }
  return undefined;
};

export const saveMixpanelEventOccurrence = (
  eventName: string,
  isFunnelIdReq: boolean = true,
  trackTime: Date = new Date()
) => {
  const options = { funnelId: '' };

  if (isFunnelIdReq) {
    const decodedToken: {
      funnelId: string;
    } = jwt_decode(JSON.parse(getMeetovoAuthData() || '{}')?.token);
    options.funnelId = decodedToken.funnelId;
  }
  return localStorage.setItem(
    'mixpanelEventsTrack',
    JSON.stringify({
      ...JSON.parse(localStorage.getItem('mixpanelEventsTrack') || '{}'),
      [eventName]: {
        ...options,
        trackTime
      }
    })
  );
};
