export const colorPickerConfig = [
  {
    label: 'Überschriften',
    description: 'Überschriften und Fragen',
    colorKey: 'headlineColor',
    errorMessage:
      'Überschriften werden nicht lesbar sein. Du solltest entweder die Hintergrund- oder die Überschriftenfarbe ändern.'
  },
  {
    label: 'Text',
    description: 'Haupttexte und Hinweise',
    colorKey: 'textColor',
    errorMessage:
      'Der Text wird nicht lesbar sein. Du solltest entweder die Hintergrund- oder die Textfarbe ändern.'
  },
  {
    label: 'Akzente',
    description: 'Buttons, Icons und Fortschrittsanzeige',
    colorKey: 'accentColor',
    errorMessage:
      'Buttons, Icons und Fortschrittsanzeige wird nicht lesbar sein. Du solltest entweder die Hintergrund- oder die Akzentfarbe ändern.'
  },
  {
    label: 'Akzentkontrast',
    description: 'Wird auf der Akzentfarbe verwendet',
    colorKey: 'accentColorContrast'
  },
  {
    label: 'Auswahlkartenhintergrund',
    description: 'Auswahlkarten und Testimonials',
    colorKey: 'cardBackgroundColor'
  },
  {
    label: 'Auswahlkarten-Icons',
    description: 'Einfach- und Mehrfachauswahl',
    colorKey: 'cardIconColor',
    errorMessage:
      'Icons werden nicht sichtbar sein. Du solltest entweder die Auswahlkartenhintergrundfarbe oder die Auswahlkarten-Icon-Farbe ändern.'
  },
  {
    label: 'Auswahlkartentext',
    description: 'Antwortmöglichkeiten',
    colorKey: 'cardTextColor',
    errorMessage:
      'Die Texte werden nicht lesbar sein. Du solltest entweder die Auswahlkartenhintergrundfarbe oder die Auswahlkartentextfarbe ändern.'
  },
  {
    label: 'Hintergrund',
    description: 'Gesamter Hintergrund',
    colorKey: 'backgroundColor'
  },
  {
    label: 'Verlauf',
    description: 'Vorauswahl für verlaufsfähige Elemente',
    colorKey: 'defaultGradient',
  }
];

export const defaultColorTheme = {
  headlineColor: '#333333',
  textColor: '#555555',
  accentColor: '#23d3d3',
  backgroundColor: '#f3f3f3',
  cardBackgroundColor: '#0d265d',
  cardIconColor: '#ffffff',
  cardTextColor: '#ffffff',
  accentColorContrast: '#ffffff',
  defaultGradient:
    'linear-gradient(90deg, RGBA(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)',
  isCustom: true
};
