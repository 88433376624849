import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { message, Card } from 'antd';
import { EventsMessages } from '../../config/messages';
import moment from 'moment';
import { EventTable } from '../components/EventTable';
import { Role } from '../../UI/utils/roleTypes';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { selectUserData } from '../../UI/redux/userSlice';
import getDownloadFunnelData from '../helper/getDownloadFunnelData';
import { csvMaker } from '../helper/csvMaker';
import download from 'downloadjs';
import useBookings, { ActionEvents } from '../../Builder/hooks/useBookings';
import { useAllFunnels } from '../../Funnel/redux/funnelSlice';
import { useDispatch, useSelector } from 'react-redux';
import { eventFilterState, eventsDownloading, isEventRefreshLoading, setAvailableBookingStatuses, setEventFilters, setEventRefreshLoading, setEventsDownloading } from '../../UI/redux/uiSlice';
import { useEventsContext } from '../context/EventsContext';

export interface BookingStatus {
  color: string;
  status: string;
  value: string;
  id: number;
}

export const DELETE_BOOKING = gql`
  mutation deleteBooking($bookingId: Int!) {
    deleteBooking(id: $bookingId)
  }
`;

export const DELETE_APPLICATION = gql`
  mutation deleteApplication($bookingId: Int!) {
    deleteApplication(id: $bookingId)
  }
`;

export const DELETE_TRACKING = gql`
  mutation deleteTracking($trackingId: Int!) {
    deleteTracking(id: $trackingId)
  }
`;

export const GET_BOOKING_COUNTS = gql`
  query getCounts($filter1: BookingFilterV2!, $filter2: TrackingFilter!) {
    getBookingsCount(input: $filter1)
    getTrackingCount(filter: $filter2)
  }
`;

export const GET_BOOKING_INFO = gql`
  query getBookingInfo($filter2: TrackingFilter!) {
    getTrackings(filter: $filter2) {
      id
      completed
      createdAt
      source
      totalDurationInSeconds
      funnelId
      progressCollectors {
        key
        firstActivity
        leadQualifierId
        durationInSeconds
        choices {
          choiceId
          stringValue
          numberValue
        }
      }
    }
  }
`;

export interface BookingActionEventInterface {
  id: number;
  eventTime: Date;
  actionEventType: ActionEvents;
}
export interface BookingInterface {
  id: number;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  eventStart: string;
  eventEnd: string;
  choices: BookingChoiceInterface[];
  createdAt: string;
  updatedAt: string;
  version: string;
  sortOrder: number;
  funnelId?: number;
  funnelName?: string;
  bookingActionEvent: BookingActionEventInterface;
  bookingStatus?: {
    status: string;
    value: string;
    color: string;
  };
  file: {
    fileLink: string;
    name: string;
    expirationDate: Date;
  }[];
}

export interface TrackingInterface {
  id: number;
  funnelId?:number;
  createdAt: string;
  source: string;
  completed: boolean;
  totalDurationInSeconds: number;
  progressCollectors: ProgressCollectorInterface[];
  funnelName?: string;
}

export interface ProgressCollectorInterface {
  key: string;
  firstActivity: string;
  leadQualifierId?: number;
  durationInSeconds: number;
  choices: ProgressCollectorChoiceInterface[];
}

export interface BookingChoiceInterface extends ProgressCollectorChoiceInterface {
  leadQualifierId: number;
}

export interface ProgressCollectorChoiceInterface {
  choiceId: number;
  stringValue: string;
  numberValue: number;
  voiceMessageAWSKey?: string;
}

export interface LeadQualifierInterface {
  id?: number;
  question: string;
  messageTitle: string;
  type: string;
  choices: LeadQualifierChoiceInterface[];
}

export interface LeadQualifierChoiceInterface {
  id?: number;
  title?: string;
  unit?: string;
}

export interface StateInterface {
  bookingFilter: BookingFilterInterface;
  expandedEvent: number | undefined;
  initiated: boolean;
  needlessEvent: any | undefined;
}

export interface StatusInterface {
  color: string;
  id: number;
  sortOrder: number;
  status: string;
  value: string;
}

interface BookingFilterInterface {
  start: moment.Moment;
  end: moment.Moment;
  funnelIds?: number[];
  type: string;
  searchQuery?: string;
  statusId?: number;
  pagination: {
    currentPageNum: number;
    booking: {
      offset: number;
      limit: number;
    };
    tracking: {
      offset: number;
      limit: number;
    };
  };
}

export const defaultPage = {
  offset: 0,
  limit: 12
};

function Events() {
  const [downloadLoading, setDownloadLoading] = useState(false);
  const location = useLocation() as any;
  const state = useSelector(eventFilterState);
  const isEventsReloading = useSelector(isEventRefreshLoading);
  const _eventsDownloading = useSelector(eventsDownloading);
  const dispatch = useDispatch();
  const funnels = useAllFunnels();
  const userData = useAppSelector(selectUserData);

  useEffect(() => {
    if (location?.state) {
      const tempState: StateInterface = JSON.parse(JSON.stringify(state));

      if (location?.state?.start) tempState.bookingFilter.start = moment(location.state.start);
      if (location?.state?.end) tempState.bookingFilter.end = moment(location.state.end);
      if (location?.state?.funnelIds?.length > 0) tempState.bookingFilter.funnelIds = location?.state?.funnelIds

      dispatch(setEventFilters(tempState));
    }
  }, [location])

  const filter = {
    start: moment(state.bookingFilter.start).toISOString(),
    end: moment(state.bookingFilter.end).toISOString(),
    funnelIds: state.bookingFilter.funnelIds,
    type: state.bookingFilter.type,
    ...state.bookingFilter.pagination.booking
  };

  const { type: dummyType, ...filterForTracking } = filter;
  const variables = {
    filter1: {
      ...filter,
      searchQuery: state.bookingFilter.searchQuery,
      statusId: state.bookingFilter.statusId
    },
    filter2: {
      ...filterForTracking,
      ...state.bookingFilter.pagination.tracking,
      minProgressCollectorCount: 3,
      funnelIds: state.bookingFilter.type !== 'TRACKINGS' ? [0] : state.bookingFilter.funnelIds
    },
    filter3: {
      showAll: !state?.bookingFilter?.funnelIds?.length,
      funnelIds:
        state.bookingFilter.type === 'TRACKINGS' ? undefined : state.bookingFilter.funnelIds
    }
  };

  const {
    offset: dummyBookingOffset,
    limit: dummyBookingLimit,
    ...filter1ForCount
  } = variables.filter1;
  const {
    offset: dummyTrackingOffset,
    limit: dummyTrackingLimit,
    ...filter2ForCount
  } = variables.filter2;

  const variablesForCount = { filter1: filter1ForCount, filter2: filter2ForCount };

  const {
    loading: countLoading,
    error: countError,
    data: countData,
    refetch: countRefetch
  } = useQuery(GET_BOOKING_COUNTS, { variables: variablesForCount });

  const { data: bookingsData, loading, refetch } = useBookings({
    filter: variables.filter1
  });

  const bookings: BookingInterface[] = bookingsData?.getBookings || [];

  let { loading: bookingLoading, error, data } = useQuery(GET_BOOKING_INFO, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  const [deleteBooking, { loading: deleteBookingLoading }] = useMutation(DELETE_BOOKING, {
    refetchQueries: [{ query: GET_BOOKING_INFO, variables }],
    awaitRefetchQueries: true
  });

  const [deleteApplication, { loading: deleteApplicationLoading }] = useMutation(
    DELETE_APPLICATION,
    {
      refetchQueries: [{ query: GET_BOOKING_INFO, variables }],
      awaitRefetchQueries: true
    }
  );

  const [deleteTracking, { loading: deleteTrackingLoading }] = useMutation(DELETE_TRACKING, {
    refetchQueries: [{ query: GET_BOOKING_INFO, variables }],
    awaitRefetchQueries: true
  });

  const handleModal = (value: number | undefined) => {
    dispatch(setEventFilters({ ...state, expandedEvent: value }));
  };

  const handleDelete = async (id: number, version: string, type: string, callBack?: Function) => {
    if (type === 'booking') {
      if (version === 'V1')
        return deleteBooking({ variables: { bookingId: id } })
          .then((res: any) => {
            if (res.data.deleteBooking) {
              message.success(EventsMessages.deleteBookingSuccess);
              dispatch(setEventFilters({ ...state, needlessEvent: undefined, expandedEvent: undefined }));
              refetch();
              if (callBack) {
                callBack(id);
              }
            } else {
              throw new Error();
            }
          })
          .catch(() => {
            message.error(
              userData.role === Role.AGENCY_CUSTOMER
                ? EventsMessages.deleteBookingNotPermitted
                : EventsMessages.deleteBookingError
            );
            dispatch(setEventFilters({ ...state, needlessEvent: undefined, expandedEvent: undefined }));
          });
      else
        return deleteApplication({ variables: { bookingId: id } })
          .then((res: any) => {
            if (res.data.deleteApplication) {
              message.success(EventsMessages.deleteBookingSuccess);
              dispatch(setEventFilters({ ...state, needlessEvent: undefined, expandedEvent: undefined }));
              refetch();
              if (callBack) {
                callBack(id);
              }
            } else {
              throw new Error();
            }
          })
          .catch(() => {
            message.error(
              userData.role === Role.AGENCY_CUSTOMER
                ? EventsMessages.deleteBookingNotPermitted
                : EventsMessages.deleteBookingError
            );
            dispatch(setEventFilters({ ...state, needlessEvent: undefined, expandedEvent: undefined }));
          });
    } else {
      return deleteTracking({ variables: { trackingId: id } })
        .then((res: any) => {
          if (res.data.deleteTracking) {
            message.success(EventsMessages.deleteTrackingSuccess);
            dispatch(setEventFilters({ ...state, needlessEvent: undefined, expandedEvent: undefined }));
          } else {
            throw new Error();
          }
        })
        .catch(() => {
          message.error(
            userData.role === Role.AGENCY_CUSTOMER
              ? EventsMessages.deleteBookingNotPermitted
              : EventsMessages.deleteTrackingError
          );
          dispatch(setEventFilters({ ...state, needlessEvent: undefined, expandedEvent: undefined }));
        });
    }
    dispatch(setEventFilters({ ...state, needlessEvent: { id, type }, expandedEvent: undefined }));
  };

  const { getTrackings: trackings } = data || {
    getBookings: [],
    getTrackings: [],
    getAllBookingStatus: []
  };

  const { getBookingsCount: totalBookings, getTrackingCount: totalTrackings } = countData || {
    getBookingsCount: defaultPage.offset,
    getTrackingCount: defaultPage.offset
  };
  const isTrackingTypeSelected = state.bookingFilter.type == 'TRACKINGS';
  const shouldShowOnlyBookings =
    state.bookingFilter.type == 'WITH_APPOINTMENT' ||
    state.bookingFilter.type == 'WITHOUT_APPOINTMENT';

  const totalEvents =
    (isTrackingTypeSelected ? 0 : totalBookings) + (shouldShowOnlyBookings ? 0 : totalTrackings);

  const { expandedEvent, needlessEvent } = state;
  const selectionValue = state.bookingFilter.type;

  const allEvents: any[] = isTrackingTypeSelected ? [] : [...bookings];

  if (allEvents.length < defaultPage.limit && !shouldShowOnlyBookings) {
    const leftoverCount = defaultPage.limit - allEvents.length;

    if (trackings?.length > 0) {
      const leftoverEvents = trackings.slice(0, leftoverCount);
      allEvents.push(...leftoverEvents);
    }
  }

  const handleChangePage = (page: number) => {
    const offset = (page - 1) * defaultPage.limit;
    const shouldShowTrackings = offset >= totalBookings;

    if ((shouldShowTrackings || isTrackingTypeSelected) && !shouldShowOnlyBookings) {
      const trackingOffset = isTrackingTypeSelected
        ? offset
        : Math.floor((offset - totalBookings) / 6) * 6;
      dispatch(setEventFilters({
        ...state,
        bookingFilter: {
          ...state.bookingFilter,
          pagination: {
            ...state.bookingFilter.pagination,
            currentPageNum: page,
            booking: {
              limit: defaultPage.limit,
              offset: Math.ceil(totalBookings / defaultPage.limit) * 6
            },
            tracking: {
              limit: defaultPage.limit,
              offset: trackingOffset
            }
          }
        }
      }));
      return;
    }

    dispatch(setEventFilters({
      ...state,
      bookingFilter: {
        ...state.bookingFilter,
        pagination: {
          ...state.bookingFilter.pagination,
          currentPageNum: page,
          booking: {
            limit: defaultPage.limit,
            offset
          },
          tracking: {
            limit: defaultPage.limit,
            offset: defaultPage.offset
          }
        }
      }
    }));
  };

  const handleDownload = async () => {
    if (!state.bookingFilter.funnelIds?.length)
      return message.info(EventsMessages.pleaseChooseAFunnelBeforeExport);

    setDownloadLoading(true);
    const { data } = await getDownloadFunnelData({
      filter: filter1ForCount
    });
    setDownloadLoading(false);
    const { getBookings, getLeadQualifier } = data;
    if (getBookings.length === 0) return message.info(EventsMessages.noDataToExport);

    const csvData = csvMaker({
      bookings: getBookings,
      leadQualifiers: getLeadQualifier,
      funnels
    });

    const file = new Blob([csvData], { type: 'text/csv' });

    download(file, `Export_${moment().format('DD.MM.YYYY_HH.mm')}.csv`);
  };

  useEffect(() => {
    if (isEventsReloading) refetch().then(() => dispatch(setEventRefreshLoading(false)));
  }, [isEventsReloading])

  useEffect(() => {
    if (_eventsDownloading) handleDownload();
  }, [_eventsDownloading])

  useEffect(() => {
    if (_eventsDownloading && !downloadLoading) dispatch(setEventsDownloading(false));
  }, [downloadLoading])

  useEffect(() => {
    if (countLoading || bookingLoading || loading) dispatch(setEventRefreshLoading(true));
    else if (!countLoading && !bookingLoading && !loading) dispatch(setEventRefreshLoading(false));
  }, [countLoading, bookingLoading, loading])

  if (error) {
    message.error(EventsMessages.loadingError);
    return null;
  }

  return (
    <div className="events">
      <Card
        className="events-container"
      >
        <EventTable
          loading={countLoading || bookingLoading || loading || isEventsReloading}
          data={allEvents}
          needlessEvent={needlessEvent}
          funnels={funnels}
          deleteBookingLoading={deleteBookingLoading || deleteApplicationLoading}
          deleteTrackingLoading={deleteTrackingLoading}
          handleDelete={handleDelete}
          handleModal={handleModal}
          expandedEvent={expandedEvent}
          pagination={{
            defaultCurrent: 1,
            current: state.bookingFilter.pagination.currentPageNum,
            total: totalEvents,
            showSizeChanger: false,
            pageSize: defaultPage.limit,
            onChange: handleChangePage
          }}
        />
      </Card>
    </div>
  );
}

export default Events;
