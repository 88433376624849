import React from 'react';
import { Badge } from 'antd';

interface DomainStatusPropsInterface {
  status?: string;
}

function DomainStatusIndicator({ status }: DomainStatusPropsInterface) {
  let thisBadge;

  if (status === 'CONNECTED') {
    thisBadge = <Badge status="success" text="Verbunden" />;
  } else if (status === 'WAITING_FOR_DNS_CONFIGURATION' || status === 'DNS_CONFIGURATION_DONE') {
    thisBadge = <Badge status="warning" text="Warten auf Verbindung" />;
  } else {
    thisBadge = <Badge status="error" text="Falsch konfiguriert" />;
  }

  return <div>{thisBadge}</div>;
}

export default DomainStatusIndicator;
