import { Button, Modal, Result } from 'antd';
import React from 'react';

const ConfirmationForCropper = ({
  visible,
  onYes,
  onCancel
}: {
  visible: boolean;
  onYes?: () => void;
  onCancel?: () => void;
}) => {
  return (
    <Modal visible={visible} footer={null} onCancel={onCancel} onOk={onYes}>
      <Result
        status="info"
        title="Möchtest du dieses Bild erneut zuschneiden?"
        subTitle={
          <p>
            Es ist einfacher, Bilder mit den benötigten Seitenverhältnissen zu speichern, damit du
            sie schnell aus dem Archiv verwenden kannst, anstatt sie enreut hochzuladen und
            zuzuschneiden.
          </p>
        }
        extra={[
          <>
            <Button type="primary" onClick={onCancel}>
              Vorherigen Auschnitt verwenden
            </Button>
            <Button type="link" className="d-block w-100" onClick={onYes}>
              Zuschneiden
            </Button>
          </>
        ]}
      />
    </Modal>
  );
};

export default ConfirmationForCropper;
