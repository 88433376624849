import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { useIsAgencyOSBuilderPath } from './useIsAgencyOSBuilderPath';
export const useDraggingElement = (): string | null => {
  const isAgencyOSBuilderPath = useIsAgencyOSBuilderPath();
  
  return useAppSelector((state: RootState) =>
  isAgencyOSBuilderPath
      ? state.agencyOSBuilderUI.draggingElement.name
      : state.builderUI.draggingElement.name
  );
};
