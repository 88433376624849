import { message } from 'antd';
import { GeneralComponentsMessages } from '../../config/messages';
import Compressor from 'compressorjs';

const removeTransparencyFromCanvas = (ctx: any, c: any) => {
  var pixels = ctx.getImageData(0, 0, c.width, c.height),
    l = pixels.data.length,
    i,
    bound = {
      top: null,
      left: null,
      right: null,
      bottom: null
    } as any,
    x,
    y;

  // Iterate over every pixel to find the highest
  // and where it ends on every axis ()
  for (i = 0; i < l; i += 4) {
    if (pixels.data[i + 3] !== 0) {
      x = (i / 4) % c.width;
      y = ~~(i / 4 / c.width);

      if (bound.top === null) {
        bound.top = y;
      }

      if (bound.left === null) {
        bound.left = x;
      } else if (x < bound.left) {
        bound.left = x;
      }

      if (bound.right === null) {
        bound.right = x;
      } else if (bound.right < x) {
        bound.right = x;
      }

      if (bound.bottom === null) {
        bound.bottom = y;
      } else if (bound.bottom < y) {
        bound.bottom = y;
      }
    }
  }

  // Calculate the height and width of the content
  var trimHeight = bound.bottom - bound.top,
    trimWidth = bound.right - bound.left,
    trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);

  c.width = trimWidth;
  c.height = trimHeight;
  ctx.putImageData(trimmed, 0, 0);

  // Return trimmed canvas
  return c;
};

const imageHasTransparency = (src: string) =>
  new Promise((resolve, reject) => {
    var hasTransparency = false;
    try {
      var tempCanvasForCheck = document.createElement('canvas') as any;

      if (!tempCanvasForCheck) return;

      var ctx1 = tempCanvasForCheck.getContext('2d');
      var img1 = new Image();
      img1.crossOrigin = 'anonymous';
      img1.src = src;
      img1.onload = function() {
        tempCanvasForCheck.width = img1.width;
        tempCanvasForCheck.height = img1.height;

        ctx1.drawImage(img1, 0, 0);

        var imgData = ctx1.getImageData(0, 0, tempCanvasForCheck.width, tempCanvasForCheck.height);
        var data = imgData.data;
        for (var i = 0; i < data.length; i += 4) {
          if (data[i + 3] < 255) {
            hasTransparency = true;
            resolve(true);
          }
        }

        resolve(false);
      };

      return hasTransparency;
    } catch (error) {
      console.log(error);
      resolve(false);
    }
  });

const getImageSizeInKbFromBase64 = (base64: string) => {
  const mimetype = parseBase64MimeType(base64);
  var stringLength = base64.length - ('data:' + mimetype + ';base64,').length;

  var sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
  var sizeInKb = sizeInBytes / 1000;

  return sizeInKb;
};

export const isWrongUsageOfPng = async (base64: string) => {
  if (!(await imageHasTransparency(base64))) {
    if (getImageSizeInKbFromBase64(base64) > 230) {
      return true;
    }
  }

  return false;
};

export const parseBase64MimeType = (encoded: string) => {
  var result = null;

  if (typeof encoded !== 'string') {
    return result;
  }

  var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

  if (mime && mime.length) {
    result = mime[1];
  }

  return result;
};

const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => {
      const imgWidth = image.naturalWidth;
      const imgHeight = image.naturalHeight;

      resolve({ image, imageSize: { width: imgWidth, height: imgHeight } });
    });
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export default async function getCroppedImg(imageSrc: string, pixelCrop: any) {
  const { image, imageSize } = (await createImage(imageSrc)) as any;
  let canvas = document.createElement('canvas');
  const heightSmallerThanArea = imageSize.height < pixelCrop.height;
  const widthSmallerThanArea = imageSize.width < pixelCrop.width;
  const choosenWidth = widthSmallerThanArea ? imageSize.width : pixelCrop.width;
  const choosenHeight = heightSmallerThanArea ? imageSize.height : pixelCrop.height;
  const choosenX = widthSmallerThanArea ? 0 : pixelCrop.x;
  const choosenY = heightSmallerThanArea ? 0 : pixelCrop.y;

  const customSize = heightSmallerThanArea || widthSmallerThanArea;

  canvas.width = choosenWidth;
  canvas.height = choosenHeight;
  const ctx = canvas.getContext('2d');

  if (!ctx || !imageSize) return;

  ctx.fillStyle = '#f7f7f7';
  ctx.fillRect(0, 0, choosenWidth, choosenHeight);

  ctx.drawImage(
    image,
    choosenX,
    choosenY,
    choosenWidth,
    choosenHeight,
    0,
    0,
    choosenWidth,
    choosenHeight
  );

  // As a blob
  return new Promise<{ croppedImage: Blob; customSize: boolean }>(async (resolve, reject) => {
    const mimetype = (await isWrongUsageOfPng(imageSrc))
      ? 'image/jpeg'
      : parseBase64MimeType(imageSrc);

    canvas.toBlob(file => {
      if (!file) {
        message.error(GeneralComponentsMessages.noBlobSupport);
        return;
      }
      resolve({ croppedImage: file, customSize });
    }, mimetype || 'image/jpeg');
  });
}

export const base64toBlob = ({
  b64Data,
  sliceSize = 512,
  forcedMimeType
}: {
  b64Data: string;
  sliceSize?: number;
  forcedMimeType?: string;
}) => {
  const mimeType = forcedMimeType || b64Data.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)![0];
  b64Data = b64Data.replace(/^data:.*,/, '');

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: mimeType });
  return blob;
};

export async function blobToBase64(file: any) {
  return new Promise((resolve: any, reject: any) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function() {
      var base64data = reader.result;
      resolve(base64data);
    };
    reader.onerror = function(err) {
      reject(err);
    };
  });
}

export const isValidImage = (type: string) => {
  const valid = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'].includes(type);

  if (valid) return true;

  message.error('Es werden nur jpg, png oder gif akzeptiert.');

  return false;
};

export const compressBlobImage = ({
  blob,
  quality = 0.95,
  maxWidth = 1366,
  maxHeight = Infinity,
  returnBase64 = false
}: {
  blob: Blob;
  quality?: number;
  maxWidth?: number;
  maxHeight?: number;
  returnBase64?: boolean;
}): Promise<string> => {
  return new Promise(async (resolve: any, reject: any) => {
    const base64 = (await blobToBase64(blob)) as string;
    const mimeType = (await isWrongUsageOfPng(base64)) ? 'image/jpeg' : parseBase64MimeType(base64);

    new Compressor(blob, {
      ...(mimeType ? { mimeType } : {}),
      quality: 1,
      maxWidth,
      maxHeight,
      drew(context, canvas) {
        // temporary enabled for develop will be deleted as soon as custom image crop is availale
        canvas = removeTransparencyFromCanvas(context, canvas);
      },
      success(result) {
        if (returnBase64)
          blobToBase64(result)
            .then(base64 => {
              resolve(base64);
            })
            .catch(err => reject(err));
        else resolve(result);
      },
      error(err) {
        console.log(err.message);
        reject(err);
      }
    });
  });
};

export const compressBase64Image = (
  base64: string,
  quality: number = 0.95,
  maxWidth: number = 1366,
  maxHeight: number = Infinity
): Promise<string> => {
  const blob = base64toBlob({ b64Data: base64 });
  return compressBlobImage({ blob, quality, maxWidth, maxHeight, returnBase64: true });
};

export function resizeImage(fileBlob: any, width: number = 850, height: number = 450) {
  return new Promise((resolve: any) => {
    if (!window.File || !window.FileReader || !window.FileList || !window.Blob) {
      message.error(GeneralComponentsMessages.noBlobSupport);
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    reader.onload = (event: any) => {
      const img = new Image() as any;
      img.src = event.target.result;
      (img.onload = () => {
        const elem = document.createElement('canvas');
        elem.width = width;
        elem.height = height;
        const ctx = elem.getContext('2d');
        if (!ctx) return;

        const mimeType = fileBlob.type;

        // img.width and img.height will contain the original dimensions
        ctx.drawImage(img, 0, 0, width, height);
        ctx.canvas.toBlob(
          blob => {
            if (!blob) {
              message.error(GeneralComponentsMessages.noBlobSupport);
              return;
            }

            resolve(blob);
          },
          mimeType,
          1
        );
      }),
        (reader.onerror = error => {
          message.error(GeneralComponentsMessages.noBlobSupport);
        });
    };
  });
}

export function isImage(imageURL: string) {
  const re = new RegExp('jpg|png|jpeg|gif|tif|tiff');
  return imageURL.search(re) !== -1;
}

export function isBase64Image(base64: string) {
  return (
    base64?.includes('data:image/png;base64,') ||
    base64?.includes('data:image/jpeg;base64,') ||
    base64?.includes('data:image/jpg;base64,')
  );
}

export function getImageDimensionsFromFile(file: File): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    var fileReader = new FileReader();
    var image = new Image() as any;
    fileReader.onload = function(event) {
      if (!event?.target?.result) return;

      var uri = event.target.result;
      image.src = uri;
      image.onload = function() {
        const { width, height } = this as any;
        resolve({ width, height });
      };
    };
    fileReader.readAsDataURL(file);
  });
}

export const commonAspectRatios = [
  { height: 1, width: 1 },
  { height: 9, width: 16 },
  { height: 3, width: 4 },
  { height: 4, width: 3 },
];

export const containerBackgroundAspectRatios = [
  { height: 1, width: 1 },
  { height: 9, width: 16 },
  { height: 3, width: 4 },
];
