import React from 'react';
import { Tabs } from 'antd';

import BuilderSettingsFont from '../components/sharedUI/BuilderSettingsFont';
import BuilderSettingsColor from '../components/sharedUI/BuilderSettingsColor';
import {
  setDesignSettingsTabView,
  useDesignSettingsTabView,
  useIsEditingColorTheme
} from '../redux/builderSlice';
import ElementsPresets from '../components/ElementsPresets/ElementsPresets';
import { DesignSettingsTabView } from '../interfaces/builderSliceTypes';
import { useAppDispatch } from '../../redux/hooks';

const { TabPane } = Tabs;

const DesignSettings = ({ showPresetsTab }: { showPresetsTab: boolean }) => {
  const isEditingColorTheme = useIsEditingColorTheme();
  const activeTab = useDesignSettingsTabView();
  const dispatch = useAppDispatch();

  return (
    <Tabs
      onChange={(key: any) => dispatch(setDesignSettingsTabView(key))}
      className="design-settings tab-container-style"
      activeKey={activeTab}
    >
      <TabPane tab="Farben" key={DesignSettingsTabView.COLORS}>
        <BuilderSettingsColor />
      </TabPane>
      {!isEditingColorTheme && (
        <TabPane tab="Schrift" key={DesignSettingsTabView.WRITING}>
          <BuilderSettingsFont />
        </TabPane>
      )}
      {showPresetsTab && (
        <TabPane tab="Voreinstellungen" key={DesignSettingsTabView.PRESETS}>
          <ElementsPresets />
        </TabPane>
      )}
    </Tabs>
  );
};

export default DesignSettings;
