import React from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { ActionsController } from '../../../components/sharedUI/ActionsController';
import { CRAFT_ELEMENTS, CRAFT_IDENTIFIER } from '../../config/craftElements';
import { CraftElementBaseProps } from '../../../helper/craftJs';
import { Card, Col, Row, Select, Slider } from 'antd';
import BuilderSettingsTitle from '../../../components/sharedUI/BuilderSettingsTitle';

interface CraftRangeSelectorProps extends CraftElementBaseProps {
  value: number | number[];
  Title?: string;
  range?: boolean;
  selectOption?: string;
}

export const CraftRangeSelector = ({ value, range, selectOption }: CraftRangeSelectorProps) => {
  return (
    <ActionsController className="cursor-pointer" data-testid="rangeSelector">
      {range ? `${(value as number[])[0]}$-${(value as number[])[1]}$` : value} {selectOption}
    </ActionsController>
  );
};

const CraftRangeSelectorSettings = () => {
  const { nodeId, props } = useNode(node => ({
    nodeId: node.id,
    props: node.data.props
  }));
  const { actions } = useEditor();

  const onChange = (value: number | number[]) => {
    actions.setProp(nodeId, (state: any) => (state.value = value));
  };

  const handleSalaryType = (value: string) => {
    actions.setProp(nodeId, (state: any) => (state.selectOption = value));
  };

  const { sliderProps, selectProps } = getDefaultPropsForSlider(props.identifier);
  return (
    <Col span={24} className="builder__settings-sidebar__container">
      <Card className="settings-card" title={props.Title} bordered={false}>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BuilderSettingsTitle title={props.Title} />
            <Slider {...sliderProps} range={props.range} value={props.value} onChange={onChange} />
            <p>
              {props.range
                ? `${props.Title} ${props.value[0]}$ - ${props.value[1]}$`
                : `${props.Title} ${props.value} ${props.selectOption}`}
            </p>
          </Col>
        </Row>
        {selectProps && (
          <Row className="builder__settings-sidebar__row">
            <Col span={24}>
              <BuilderSettingsTitle title={props.Title} />
              <Select
                {...selectProps}
                className="w-100"
                onChange={handleSalaryType}
                value={props.selectOption}
              />
            </Col>
          </Row>
        )}
      </Card>
    </Col>
  );
};

const getDefaultPropsForSlider = (identifier: CRAFT_IDENTIFIER) => {
  switch (identifier) {
    case CRAFT_IDENTIFIER.SALARY:
      return {
        sliderProps: { min: 0, max: 1000 },
        selectProps: {
          defaultValue: 'Monatlich',
          options: [
            { value: 'monthly', label: 'Monatlich' },
            { value: 'yearly', label: 'Jährlich' }
          ]
        }
      };
    case CRAFT_IDENTIFIER.EXPERIENCE:
      return {
        sliderProps: { min: 0, max: 12 },
        selectProps: {
          defaultValue: 'Monate',
          options: [
            { value: 'months', label: 'Monate' },
            { value: 'years', label: 'Jahre' }
          ]
        }
      };
    default:
      return {};
  }
};

export const CraftRangeSelectorDefaultProps = {
  selectOption: 'Monate'
};

CraftRangeSelector.craft = {
  name: CRAFT_ELEMENTS.Craft_Range_Selector,
  props: CraftRangeSelectorDefaultProps,
  related: {
    settings: CraftRangeSelectorSettings
  }
};
