export const formRules = {
  required: [{ required: true, message: 'Bitte mache eine Eingabe.' }],
  notRequired: [{ required: false }],
  fontNameRules: [
    { required: true, message: 'Bitte gib den Namen der Schriftart ein.' },
    { min: 4, message: 'Schriftname mit mindestens 4 Zeichen erforderlich.' }
  ],
  uploadFile: [{ required: true, message: 'Bitte lade eine Datei hoch.' }],
  atLeastOneRequired: [
    {
      validator: (_: any, value: any) => {
        if (Array.isArray(value) && value.length > 0) {
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('Bitte wählen Sie mindestens ein Element aus'));
        }
      }
    }
  ]
};
