import React, { useEffect } from 'react';
import { Row, Col, Button, Card } from 'antd';
import { Element, useEditor, useNode } from '@craftjs/core';
import {
  getKeyByValue,
  getUniqueId,
  getValueByKey,
  handleBringSlideToFront,
  sleep
} from '../../../../helper/craftJs';
import CarouselSlide, { defaultCarouselSlideImage } from '../Container/CarouselSlide';
import SlidesListing from './SlidesListing';
import { FaPlus } from 'react-icons/fa';
import SettingsGB from '../../../Settings/SettingsGB';
import { ImageAvailableBorderRadius } from '../../../../interfaces/ImageSizeInterface';
import { ImageCarouselSettingsItems } from '../../../Settings/SettingsTemplates';
import { useElementsPresets } from '../../../../redux/builderSlice';
import { builderPresetElements } from '../../../../interfaces/builderSliceTypes';

const scrollToBottom = async () => {
  await sleep(0);
  var container = document.querySelector('.builder__settings-sidebar__container');
  if (container) {
    container.scrollTop = container.scrollHeight;
  }
};

const CarouselComponentSettings = () => {
  const {
    slideNodes,
    id,
    props,
    actions: { setProp }
  } = useNode(node => {
    return { slideNodes: node.data.nodes, props: node.data.props };
  });
  
  const { actions, query, firstNodeProps } = useEditor((_, query) => {
    const firstNode = query.getSerializedNodes()?.[slideNodes[0]];
    return { firstNodeProps: firstNode.props };
  });

  const handleDelete = (id: string) => {
    actions.delete(id);
    setTimeout(() => {
      handleBringSlideToFront(id, slideNodes.filter(_ => _ != id)[0]);
    }, 0);
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    actions.move(slideNodes[oldIndex], id, oldIndex < newIndex ? newIndex + 1 : newIndex);
    handleBringSlideToFront(id, slideNodes[oldIndex]);
  };

  const imageCarousalValues = useElementsPresets(builderPresetElements.CAROUSEL);

  const finalProps = props.isPresetOn
    ? { ...props, ...{ borderRadius: firstNodeProps.borderRadius }, ...imageCarousalValues }
    : { ...props, ...{ borderRadius: firstNodeProps.borderRadius } };

  const values = {
    ...finalProps,
    borderRadius: getKeyByValue(finalProps?.borderRadius, ImageAvailableBorderRadius, 'pixelValue')
      .key
  };

  const appendSlide = async () => {
    const testId = getUniqueId();
    const nodeTree = query
      .parseReactElement(
        <Element
          id={testId}
          is={CarouselSlide}
          canDrag={false}
          activeActionControllerClassNotRequired={true}
          opacity={5}
          label={`Slide # ${slideNodes.length + 1}`}
          image={defaultCarouselSlideImage}
        />
      )
      .toNodeTree();
    actions.addNodeTree(nodeTree, id);
    handleBringSlideToFront(id, nodeTree.rootNodeId);
    scrollToBottom();
  };

  const handlePropsChange = (key: string, value: any) => {
    if (key === 'borderRadius') {
      actions.setProp(
        slideNodes,
        (props: any) =>
          (props.borderRadius = getValueByKey(value, ImageAvailableBorderRadius).pixelValue)
      );
      return;
    }
    actions.setProp(slideNodes, (props: any) => (props[key] = value));
    setProp((props: any) => (props[key] = value));
  };

  return (
    <div className="carousel-settings">
      <SettingsGB
        elements={ImageCarouselSettingsItems}
        onChange={handlePropsChange}
        values={values}
        settingTitle="Bild"
      />
      <Card className="settings-card" title="Bild-Slider" bordered={false}>
        <SlidesListing slideNodes={slideNodes} onSortEnd={onSortEnd} handleDelete={handleDelete} />
        <Row align="middle" justify="center" className="mt-4">
          <Col sm={24}>
            <div
              className="add-button-green circular-plus-button container__add-wrapper-icon append-container-icon"
              onClick={() => {
                appendSlide();
              }}
            >
              <FaPlus className="container-plus-icon" />
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default CarouselComponentSettings;
