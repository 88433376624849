import { useAgencyOSTheme } from '../../../AgencyOSBuilder/redux/agencyOSBuilderSlice';
import { useFunnelTheme } from '../../../redux/builderSlice';
import { useIsAgencyOSBuilderPath } from '../../useIsAgencyOSBuilderPath';

export const useBuilderTheme = () => {
  const isAgencyOSBuilderPath = useIsAgencyOSBuilderPath();
  if (isAgencyOSBuilderPath) {
    return useAgencyOSTheme();
  } else {
    return useFunnelTheme();
  }
};
