import { Button, Modal, Result } from 'antd';
import React from 'react';

const SaveWarningModal = ({
  onCancel,
  onMoveToNext,
  onSave,
  visible,
  setVisible
}: {
  visible: boolean;
  setVisible: (e: boolean) => void;
  onCancel?: () => void;
  onMoveToNext: () => void;
  onSave: () => void;
}) => {
  const toggleModal = () => setVisible(false);
  return (
    <Modal visible={visible} onOk={() => {}} onCancel={toggleModal} footer={null}>
      <Result
        status="warning"
        title="Willst du die Änderungen überschreiben?"
        subTitle='Du kannst deine aktuelle Copy/Creative entweder speichern und über "Eigene Templates" erneut verwenden oder durch die nächste Vorlage ersetzen.'
        extra={[
          <Button
            type="primary"
            key="console"
            onClick={() => {
              onSave();
              toggleModal();
            }}
          >
            Speichern
          </Button>,
          <Button
            onClick={() => {
              onMoveToNext();
              toggleModal();
            }}
          >
            Zur nächsten wechseln
          </Button>
        ]}
      />
    </Modal>
  );
};

export default SaveWarningModal;
