import React from 'react';
import { NotReachedTableColumns } from '../../utils/eventTypes';
import { Table } from 'antd';

const NotReachedTable = ({ loading, bookingEvents }: { loading: boolean; bookingEvents: any }) => {
  return (
    <>
      {bookingEvents?.length > 0 && (
        <Table
          className="mt-4"
          pagination={false}
          loading={loading}
          columns={NotReachedTableColumns}
          dataSource={bookingEvents}
          scroll={{ y: 300 }}
          size="middle"
        />
      )}
    </>
  );
};

export default NotReachedTable;
