import { Select } from 'antd';
import React, { PropsWithChildren, useState } from 'react';
import { SelectProps } from 'antd/lib/select';

interface CategoryPickerProps extends PropsWithChildren<SelectProps<string[]>> {
  placeholder?: string;
  value: any;
  options: any[];
}

export default function CategoryPicker({
  placeholder,
  value,
  options,
  ...other
}: CategoryPickerProps) {
  const [searchText, setSearchText] = useState('');
  return (
    <Select
      placeholder={placeholder || ''}
      className="w-100"
      mode="multiple"
      value={value || []}
      autoClearSearchValue
      onSearch={input => setSearchText(input)}
      filterOption={(input, option) => {
        if (!input) {
          return true;
        }
        return option?.children?.toLowerCase().includes(input.toLowerCase());
      }}
      {...other}
    >
      {(options || []).map(item => (
        <Select.Option key={item.value} value={item?.label || item.value}>
          {item.label}
        </Select.Option>
      ))}
    </Select>
  );
}
