import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useAppDispatch } from '../../../redux/hooks';
import { setPageCustomJs } from '../../redux/thunk';
import { BuilderPageDataType } from '../../interfaces/builderSliceTypes';
import { useMainFunnelLoader } from '../../redux/builderSlice';
import Editor from 'react-simple-code-editor';
// @ts-ignore
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';

interface Props {
  visible: boolean;
  toggleVisible: (data: boolean) => void;
  pageData: BuilderPageDataType | undefined;
}
const PageCustomJs = ({ visible, toggleVisible, pageData }: Props) => {
  const dispatch = useAppDispatch();
  const loading = useMainFunnelLoader();

  const [customCode, setCustomCode] = useState(pageData?.customJs);

  useEffect(() => {
    setCustomCode(pageData?.customJs);
  }, [pageData?.customJs]);

  const handleOk = (e: any) => {
    if (pageData && pageData?.customJs !== customCode) {
      dispatch(
        setPageCustomJs({
          customJs: customCode,
          id: pageData.id
        })
      );
    }
    toggleVisible(false);
  };

  const handleCancel = () => {
    toggleVisible(false);
  };

  return (
    <Modal
      title="Eigener Code"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={loading}
      width={800}
      className="code-editor--wrapper"
      okText="Speichern"
    >
      <div className="py-3 px-4">
        <Editor
          value={customCode || ''}
          onValueChange={value => {
            setCustomCode(value);
          }}
          highlight={code => highlight(code, languages.js)}
          padding={10}
          style={{
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 12
          }}
          className="code-editor"
        />
      </div>
    </Modal>
  );
};
export default React.memo(
  PageCustomJs,
  ({ toggleVisible }, { toggleVisible: newToggleVisible }) => {
    return newToggleVisible === toggleVisible;
  }
);
