import React from 'react';
import { ActionEvents } from '../../../Builder/hooks/useBookings';
import { Tooltip } from 'antd';
import { FaPhone, FaPhoneSlash } from 'react-icons/fa';
import moment from 'moment';
import dateFormats from '../../../config/dateFormats';
import { BookingActionEventInterface } from '../../container/Events';

const ActionEventIcon = ({ record }: { record: BookingActionEventInterface }) => {
  
  const eventTime = `${moment(record?.eventTime).format(dateFormats.dateReadableMonth)} - ${moment(
    record?.eventTime
  ).format(dateFormats.time)} `;

  return (
    <>
      {record?.actionEventType === ActionEvents.LAST_NOT_REACHED_TIME ? (
        <Tooltip placement="topLeft" title={`Letzte nicht erreichte Zeit , ${eventTime}`}>
          <FaPhoneSlash color="red" />
        </Tooltip>
      ) : record?.actionEventType === ActionEvents.REACHED_TIME ? (
        <Tooltip placement="topLeft" title={`Zeit erreicht , ${eventTime} `}>
          <FaPhone color="green" />
        </Tooltip>
      ) : null}
    </>
  );
};

export default ActionEventIcon;
