import gql from 'graphql-tag';

export const GET_FUNNEL_JOB_POST = gql`
  query getFunnelJobPost {
    getFunnelJobPost {
      id
      craftState
      validThrough
      status
      platform
    }
  }
`;

export function getFunnelJobPost() {
  return window.apolloClient.query({
    query: GET_FUNNEL_JOB_POST,
    fetchPolicy: 'no-cache'
  });
}
