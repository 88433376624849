import { useEditor } from '@craftjs/core';
import { useEffect } from 'react';
import { handleKeyBoardShortcut } from './craftJs.ts';
import { useIsAgencyOSBuilderPath } from '../hooks/useIsAgencyOSBuilderPath.ts';
import{useIsWidgetsBuilderPath} from '../hooks/useIsWidgetsBuilderPath.ts' 

const useFunnelUndoRedo = () => {
  const isAgencyOSBuilderPath = useIsAgencyOSBuilderPath()
  const isWidgetsBuilderPath = useIsWidgetsBuilderPath()

  const canUndoAndRedo =
    window.location.pathname.includes('ad-builder/bearbeiten') ||
    window.location.pathname.includes('builder/bearbeiten') ||
    isAgencyOSBuilderPath || isWidgetsBuilderPath;

  const { canUndo, canRedo, actions } = useEditor((state, query) => ({
    canUndo: query.history.canUndo(),
    canRedo: query.history.canRedo()
  }));

  useEffect(() => {
    document.addEventListener('keydown', e => handleKeyBoardShortcut(e, canUndo, canRedo, actions));
    return () => {
      document.removeEventListener('keydown', () => handleKeyBoardShortcut);
    };
  }, [canUndo, canRedo, actions, canUndoAndRedo]);

  return {
    canUndo: canUndo && canUndoAndRedo,
    canRedo: canRedo && canUndoAndRedo,
    actions,
    canUndoAndRedo
  };
};

export default useFunnelUndoRedo;
