import { useMutation } from "@apollo/react-hooks";
import gql from 'graphql-tag';

export const DELETE_APPLICATION = gql`
  mutation deleteApplication($bookingId: Int!) {
    deleteApplication(id: $bookingId)
  }
`;


export default ()=> useMutation(
  DELETE_APPLICATION
);