import { SizeInterface } from '../config/builderUi';

export const LogoSizes: SizeInterface[] = [
  {
    key: 1,
    value: '35px'
  },
  {
    key: 2,
    value: '50px'
  },
  {
    key: 3,
    value: '75px'
  },
];

export const defaultLogoSize = LogoSizes[0];
