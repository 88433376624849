const jobRequirementsTextData = {
  title: 'Voraussetzungen',
  data: [
    {
      category: 'Gesundheitswesen',
      content: [
        {
          text: `Damit du als [z.B. Pflegekraft] in [STANDORT] unserem Team optimal wirken kannst, sollten folgende Qualifikationen und Eigenschaften Teil deines Profils sein:\n\n1. Abgeschlossene Berufsausbildung als examinierte Pflegefachkraft:\nDu bringst eine solide Ausbildung und ein [BILDUNGSGRAD] mit, die die Basis für deine qualifizierte Pflegeleistung bildet. Vorteilhaft, aber nicht notwendig, wenn du bereits min. [ERFAHRUNG] mitbringst.\n\n2. Einfühlungsvermögen und respektvoller Umgang:\nDein empathischer Umgang mit den individuellen Bedürfnissen der Patienten ist der Schlüssel zu einer erfolgreichen Betreuung.\n\n3. Teamorientierte Arbeitsweise und gute Kommunikationsfähigkeiten:\nDie Zusammenarbeit im Team sowie eine klare und offene Kommunikation sind für uns essenziell.\n\n4. Bereitschaft zur kontinuierlichen Fort- und Weiterbildung:\nWir legen Wert auf deine persönliche und berufliche Weiterentwicklung, um gemeinsam erfolgreich zu sein.`,
          title: 'Auflistung',
          id: 100000
        },
        {
          text: `Folgende Fähigkeiten und Qualifikationen solltest du mitbringen, um bei [UNTERNEHMENSNAME] erfolgreich zu sein.\n\n- Fähigkeit, einfühlsam auf die Bedürfnisse und Anliegen der Patienten einzugehen.\n- Vertrautheit mit pflegerischen Standards und Protokollen.\n- Gute Kommunikationsfähigkeiten und Teamarbeit.\n- Flexibilität und Anpassungsfähigkeit an wechselnde Arbeitsbedingungen.\n- Vorherige Berufserfahrung in [KATEGORIE] mit. [ERFAHRUNG]\n- Nachweisbare Qualifikation und Abschluss. Min. [BILDUNGSGRAD] in diesem Berufsfeld.\n- Du bist in [STANDORT] wohnhaft und tätig.`,
          title: 'Kurz & Knackig',
          id: 100001
        },
        {
          text: `Wir freuen uns auf deine Bewerbung, wenn du folgende Fähigkeiten beherrschst:\n\n- Hervorragende Fähigkeiten im Umgang mit allem, was das Pflegealltag so hergibt – sei es Bettpfannenakrobatik oder Bandagen-Zauberei.\n- Fähigkeit, Pflegepläne zu jonglieren und Dokumentationen zu jonglieren, als wären es Zirkusrequisiten.\n- Ein einfühlsames Gemüt und eine große Portion Humor, um selbst den müdesten Patienten ein Lächeln ins Gesicht zu zaubern.\n- Ein wahrer Zauberer in der Kunst des Schmerz-Managements, der mit einem Augenzwinkern und einem Zauberstab die Schmerzen der Patienten verschwinden lässt.\n- Min. [ERFAHRUNG] in [KATEGORIE] und den Bildungsgrad: [BILDUNGSGRAD].\n- Du bist in [STANDORT] wohnhaft und tätig.`,
          title: 'Humor',
          id: 100002
        }
      ]
    },
    {
      "category": "Handwerk",
      "content": [
        {
          "text": `Um in [BESCHAEFTIGUNGSART] erfolgreich zu sein, erwarten wir, dass du folgende Qualifikationen und Eigenschaften mitbringst:\n\n1.Handwerkliches Geschick und technisches Verständnis.\n2. Es ist erforderlich, dass du min. [ERFAHRUNG] in dem Bereich [KATEGORIE] mitbringst\n3. Eine abgeschlossene Ausbildung\n4. Zuverlässigkeit und Teamfähigkeit \n5. Selbstständiges Arbeiten\n6. Bereitschaft zur Weiterbildung und Entwicklung\n7. Arbeiten vor Ort in [STANDORT]`,
          "title": "Kurze Stichpunkte",
          "id": 100045
        },
        {
          "text": `Erforderliche Qualifikationen und Eigenschaften für diese Rolle sind:\n\n- Handwerkliches Geschick und technisches Verständnis erforderlich:\n  Du bist handwerklich begabt und hast das technische Know-How.\n\n- Erfahrung in handwerklichen Tätigkeiten:\n  Du bringst mindestens [ERFAHRUNG] im [KATEGORIE] mit.\n\n- Abgeschlossene Ausbildung im Handwerk von Vorteil:\n  Eine abgeschlossene Ausbildung im Handwerk ist von Vorteil, aber nicht zwingend erforderlich.\n\n- Zuverlässigkeit und Teamfähigkeit notwendig:\n  Du solltest zuverlässig sein und gut im Team arbeiten können, direkt bei uns [ARBEITSPLATZMODELL] in [STANDORT].\n\n- Selbstständiges Arbeiten und Problemlösungsfähigkeiten wichtig:\n  Du arbeitest selbstständig ung löst Probleme einfach.`,
          "title": "Du perspektive",
          "id": 100046
        },
        {
          "text": `Erforderliche Qualifikationen und Eigenschaften für diese Rolle sind: \n\n- Die Fähigkeit, präzise und effektiv mit Werkzeugen und Materialien umzugehen.\n- Vorherige praktische Erfahrung in einem handwerklichen Bereich.\n- Eine abgeschlossene Berufsausbildung mit [BILDUNGSGRAD].\n- Min. [ERFAHRUNG] in [KATEGORIE].\n- In [STANDORT] wohnhaft und tätig. Arbeitend in [ARBEITSPLATZMODELL].\n- Die Fähigkeit, Verantwortung zu übernehmen und Aufgaben termingerecht und zuverlässig auszuführen.\n- Die Fähigkeit, eigenständig zu arbeiten und Lösungen für auftretende Probleme zu finden.\n- Die Bereitschaft, sich kontinuierlich weiterzubilden und neue Techniken oder Verfahren im Handwerk zu erlernen.\n- Ein Verständnis für geltende Sicherheitsvorschriften und -verfahren im handwerklichen Umfeld ist unerlässlich.\n- Die Fähigkeit, flexibel auf sich ändernde Anforderungen und Arbeitsbedingungen zu reagieren, sowie die Bereitschaft, neue Aufgaben oder Herausforderungen anzunehmen.\n- Der Besitz eines Führerscheins der Klasse B oder höher kann von Vorteil sein.`,
          "title": "Beschriebener Text",
          "id": 100047
        }
      ]
    },
    {
      "category": "Handel & Vertrieb",
      "content": [
        {
          "text": `Wir suchen dich in [[BESCHAEFTIGUNGSART], wenn du folgende Fähigkeiten beherrschst:\n\n- Kommunikationsfähigkeiten: Du kommunizierst klar und kannst Kunden überzeugen\n- Abgeschlossene Berufsausbildung: Idealerweise mit [ERFAHRUNG] und [BILDUNGSGRAD]\n- Hybrid-Modell: Bevorzugt in [STANDORT] arbeitend, aber Homeoffice möglich\n- Verhandlungsgeschick: Du verhandelst genau und kannst erfolgreich Verträge abschließen\n- Verkaufstalent: Du kannst überzeugend Präsentieren und Abschlüsse erzielen.\n- Analytische Fähigkeiten: Du analysiert Daten und erkennst Trends rechtzeitig\n- Kundenorientierung: Du kannst gut auf die Bedürfnisse deiner Kunden eingehen.\n- Organisationsgeschick: Organisation ist für dich kein Problem.`,
          "title": "Du perspektive, Fähigkeiten",
          "id": 100076
        },
        {
          "text": `Wenn du folgenden Anforderungen entsprichst, dann bewerbe dich bei uns in [BESCHAEFTIGUNGSART]:\n\n- Branchenkenntnisse: Fundierte Kenntnisse über Vertrieb Strategien\n- Vertriebserfahrung: [ERFAHRUNG] im Verkauf oder Vertrieb ist von Vorteil.\n- Ausbildung im Bereich Marketing oder Vertrieb: Eine Ausbildung mit [BILDUNGSGRAD] in [KATEGORIE] oder einem verwandten Bereich ist wünschenswert.\n- Erfahrung mit Vertriebssoftware: Kenntnisse in der Nutzung von Vertriebssoftware und CRM-Systemen sind hilfreich.`,
          "title": "Umschrieben",
          "id": 100077
        },
        {
          "text": `Erforderliche Qualifikationen und Eigenschaften für diese Rolle sind:\n\n- Abgeschlossene Ausbildung oder Studium im Bereich Wirtschaft, Vertrieb, Marketing oder ähnliches.\n- Nachweisbare Erfahrung im Vertrieb oder verwandten Bereichen.\n- Mindestens X Jahre Erfahrung im Vertrieb, idealerweise in der Branche XYZ.\n- Erfolgreiche Kundeninteraktionen und Verhandlungen in vergangenen Positionen.\n- Nachweisbare Erfahrung in der Anwendung von Vertriebssoftware wie Salesforce oder ähnlichen Tools.\n- Fähigkeit zur Zusammenarbeit mit verschiedenen Abteilungen und Teammitgliedern.`,
          "title": "Stichpunkte",
          "id": 100078
        },
        {
          "text": "Wir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig.\r\n\r\nKlicke jetzt auf den Button und beantworte ein paar kurze Fragen (Dauer: maximal 2 Minuten).\r\n\r\nDer Ablauf:\r\n1. Klicke auf den Button und beantworte die kurzen Fragen\r\n2. Du erhältst Termine für ein Kennenlerngespräch\r\n3. Wenn wir zueinander passen, machen wir Dir ein attraktives Jobangebot",
          "title": "[Vorteile] Nächste Schritte",
          "id": 100079
        },
        {
          "text": "Unglücklich im jetzigen Job? Wenn du keine Lust mehr hast, jeden Tag bedeutungslose Arbeit zu machen, dann melde dich bei uns. \r\n\r\nWir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig. Jetzt auf den Button klicken und in 2 Minuten bewerben.",
          "title": "[Vorteile] Unglücklich im jetzigen Job",
          "id": 100080
        },
        {
          "text": "Hast du das Zeug zum Kaufmann/frau (m/w/d)? Finde es in nur 53 Sekunden heraus.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!",
          "title": "[Quiz] Hast du das Zeug?",
          "id": 100216
        },
        {
          "text": "🚀 Jetzt klicken und finde in nur 53 Sekunden heraus, ob dieser Job zu dir passt.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!",
          "title": "[Quiz] Passt dieser Job?",
          "id": 100217
        },
        {
          "text": "Klingt gut?  \r\n\r\nDann finde jetzt in 53 Sekunden heraus, ob du Karriere im Handel machen kannst.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!",
          "title": "[Quiz] Klingt gut?",
          "id": 100218
        },
        {
          "text": "🔝 Hast du auch Lust auf eine Karriere im Handel?\r\n\r\nKlicke jetzt auf den Button und finde in 53 Sekunden heraus, ob du als [Berufsgruppe] glücklich wirst.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!\r\n\r\nWir freuen uns auf dich! ❤️",
          "title": "[Quiz] Lust auf Karriere?",
          "id": 100219
        },
        {
          "text": "Ready für den Ausbildungsstart [z.B. 2023]? Schau es dir jetzt unser #Abiprogramm an und finde in 53 Sekunden heraus, ob du Karriere in der [Branche] machen kannst.\r\n\r\nUnd erhalte die Chance auf ein unverbindliches Ausbildungsangebot. Mit persönlichem Ansprechpartner!",
          "title": "[Quiz] Ausbildungsstart",
          "id": 100220
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\n… dann bewirb dich jetzt bei uns als [Berufsgruppe]. Klicke dafür unten einfach nur auf Button.\r\n\r\nDauert nicht länger als zwei Minuten. Kein Lebenslauf nötig. Versprochen! 🤝\r\n",
          "title": "[Story] Kein Lebenslauf nötig",
          "id": 100330
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\nKlingt gut? Klicke jetzt auf \"Mehr dazu\" und schau, ob wir zueinander passen.\r\nBewirb dich in unter 2 Minuten bei uns - ganz ohne Lebenslauf.\r\n",
          "title": "[Story] Passen wir zueinander",
          "id": 100331
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\nInteresse? Deine Bewerbung kannst du ohne Anschreiben und Lebenslauf in unter 2 Minuten zukommen lassen. Jetzt auf den Button klicken.\r\n\r\nLass uns gemeinsam…  [Unternehmensvision in 1-2 Sätzen. Höherer Sinn. Die Bedeutung, die die Arbeit hat]\r\n",
          "title": "[Story] Vision",
          "id": 100332
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\nWir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig.\r\n\r\nKlicke jetzt auf den Button und beantworte ein paar kurze Fragen (Dauer: maximal 2 Minuten).\r\n\r\nDer Ablauf:\r\n1. Klicke auf den Button und beantworte die kurzen Fragen\r\n2. Du erhältst Termine für ein Kennenlerngespräch\r\n3. Wenn wir zueinander passen, machen wir Dir ein attraktives Jobangebot\r\n",
          "title": "[Story] Nächste Schritte",
          "id": 100333
        },
        {
          "text": "Willst du bei der Muster GmbH deine eigene Geschichte schreiben? Hast du auch eine hohe Affinität im Bereich [....] und [....]?\r\n\r\nUnglücklich im jetzigen Job? Wenn du keine Lust mehr hast, jeden Tag bedeutungslose Arbeit zu machen, dann melde dich bei uns. \r\n\r\nWir wollen dich einmal persönlich kennenlernen. Lebenslauf nicht nötig. Jetzt auf den Button klicken und in 2 Minuten bewerben.\r\n",
          "title": "[Story] Unglücklich im jetzigen Job",
          "id": 100334
        }
      ]
    },
    {
      "category": "Gastronomie",
      "content": [
        {
          "text": `Du solltest folgende Anforderungen mitbringen, um bei [UNTERNEHMENSNAME] in [STANDORT] im Team mitzuwirken.\nKochausbildung oder Erfahrung: Eine abgeschlossene Kochausbildung mit [BILDUNGSGRAD] oder nachweisbare Erfahrung min. [ERFAHRUNG]  in der Gastronomie sind unerlässlich, um die erforderlichen Fähigkeiten in der Zubereitung von Speisen zu besitzen.\n\nKenntnisse über Lebensmittelhygiene und Sicherheitsstandards: Ein fundiertes Verständnis der Lebensmittelhygiene- und Sicherheitsstandards ist entscheidend, um sicherzustellen, dass alle gesetzlichen Vorschriften eingehalten werden und die Gäste geschützt sind.\n\nOrganisations- und Zeitmanagementfähigkeiten: In der Gastronomie ist es wichtig, effizient zu arbeiten und den Überblick über viele Aufgaben gleichzeitig zu behalten. Daher sind starke Organisations- und Zeitmanagementfähigkeiten unerlässlich.\n\nTeamfähigkeit und Kommunikationsfähigkeiten: Da die Arbeit in der Gastronomie oft im Team erfolgt, ist es wichtig, gut mit anderen zusammenarbeiten zu können und klar und effektiv zu kommunizieren, um einen reibungslosen Ablauf zu gewährleisten. `,
          "title": "Lang und umschrieben",
          "id": 100107
        },
        {
          "text": `Dies sind die Anforderungen denen du als [z.B. Koch] im Bereich [KATEGORIE] in [STANDORT] entsprechen solltest:\n\n- Kochausbildung min. [BILDUNGSGRAD] oder [ERFAHRUNG] in der Gastronomie.\n- Kenntnisse über Lebensmittelhygiene und Sicherheitsstandards.\n- Starke Organisations- und Zeitmanagementfähigkeiten.\n-Teamfähigkeit und effektive Kommunikationsfähigkeiten.\n- Belastbarkeit und Flexibilität, um mit den Anforderungen des gastronomischen Umfelds umzugehen.`,
          "title": "Kurz - Stichpunkte",
          "id": 100108
        },
        {
          "text": `Wenn du folgenden Anforderungen entsprichst in [KATEGORIE], freuen wir uns über deine Bewerbung:\n\nDu bist ein Meisterkoch oder zumindest bereit, mit einem Lächeln Toast zuzubereiten.\n\nDu jonglierst mit Lebensmitteln wie ein Profi und reinige wie ein geheimer Ninja.\n\nDu bist in [STANDORT] wohnhaft.\n\nDu beherrschst die Kunst, viele Aufgaben gleichzeitig zu jonglieren, ohne den Verstand zu verlieren.\n\nDu bist ein Meister im Multitasking, ob beim Servieren von Kaffee oder dem Führen von Smalltalk. \n\nDu bist ein belastbarer Anpassungskünstler wie ein Chamäleon: bereit für alles, ob plötzliche Bestellfluten oder vergessene Zutaten – und behältst trotzdem den Überblick!`,
          "title": "Humor - Du perspektive",
          "id": 100109
        }
      ]
    },
    {
      "category": "Industrie",
      "content": [
        {
          "text": `Wenn du folgenden Anforderungen entsprichst, freuen wir uns auf deine Bewerbung bei [UNTERNEHMENSNAME] in [STANDORT] :\n\n-Technisches Verständnis: Du solltest technische Prozesse verstehen.\n- Problemlösungsfähigkeiten: Du musst Probleme schnell lösen können.\n- Teamfähigkeit: Du solltest gut im Team arbeiten können.\n- Organisationstalent: Du musst Abläufe organisieren können.\n- Kommunikationsfähigkeiten: Du solltest klar kommunizieren können.\n- Belastbarkeit und Flexibilität: Du musst belastbar und flexibel sein.\n- Ausbildung: Du solltest eine Ausbildung mit [BILDUNGSGRAD] haben\n- Erfahrung : Du solltest idealerweise [ERFAHRUNG] mitbringen`,
          "title": "Kurz und Knackig",
          "id": 100138
        },
        {
          "text": `Wenn folgende Anforderungen auf dich zutreffen, dann suchen wir dich als [z.B. BERUF] in [STANDORT] !\n\nDu arbeitest selbstständig und übernimmst Verantwortung für deine Aufgaben.\nDu analysierst komplexe Probleme und triffst fundierte Entscheidungen.\nDu arbeitest effizient und optimierst Prozesse, um Zeit und Ressourcen zu sparen.\nDu hast Erfahrung im Umgang mit Produktionsmaschinen.\nDu verfügst über Kenntnisse in Arbeitssicherheit und Umweltschutz und trägst zur Sicherheit und Nachhaltigkeit am Arbeitsplatz bei.\nDu bringst mindestens [ERFAHRUNG] in [KATEGORIE] mit.\nDu hast den Bildungsgrad: [BILDUNGSGRAD]`,
          "title": "Du perspektive - Ansprechend",
          "id": 100139
        },
        {
          "text": `Diese Anforderungen solltest du mitbringen um bei [UNTERNEHMENSNAME] erfolgreich zu sein.\nWohnhaft in [STANDORT].\nFlexibilität: Die Fähigkeit, sich schnell an neue Anforderungen anzupassen und verschiedene Aufgabenbereiche zu übernehmen.\nZeitmanagement: Effizientes Organisieren von Arbeit, Priorisierung von Aufgaben und Einhaltung von Terminen.\nTeamfähigkeit: Gute Zusammenarbeit im Team, Ideenaustausch und Unterstützung anderer zur Erreichung gemeinsamer Ziele.\nKonfliktlösung: Erkennen und diplomatisch lösen von Konflikten, Finden von Kompromissen für eine positive Arbeitsumgebung.\nKundenorientierung: Verständnis für Kundenbedürfnisse, schnelle Reaktion auf Anfragen und Gewährleistung ihrer Zufriedenheit.\nAusbildung: Eine abgeschlossene Ausbildung in [KATEGORIE] mit min. dem [BILDUNGSGRAD]\nErfahrung: Mindestens [Erfahrung] in dem Bereich [BESCHAEFTIGUNGSART]`,
          "title": "Umschrieben",
          "id": 100140
        }
      ]
    }
  ]
};

export default jobRequirementsTextData;
