import React, { useState } from 'react';
import { Empty, Modal, message } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import DefaultSmallLoader from '../../../SharedUI/components/DefaultSmallLoader';
import AddWebhookDomain from './AddWebhookDomain';
import useUserWebHooks from '../../../hooks/useUserWebHooks';
import SingleWebhook from './SingleWebhook';
import { EDIT_CONTENT_TYPE, useEditResourceContext } from '../../context/ResourceEditContext';
import { ConnectSettingsMessages } from '../../../config/messages';

interface ConnectWebhookDomainModalProps {
  trigger: JSX.Element;
}

const ConnectWebhookDomainModal = ({ trigger }: ConnectWebhookDomainModalProps) => {
  const { data, loading: webHooksLoading, refetch: refetchUserWebHooks } = useUserWebHooks({
    disableCache: true
  });
  const {
    toggleResourceUsage,
    resourceEditState,
    resourceEditSocketLoading
  } = useEditResourceContext();
  const { getWebhooks: domains } = data || { getWebhooks: [] };
  const [open, setOpen] = useState(false);

  const loading = webHooksLoading;

  return (
    <>
      <Modal
        className="connect-settings-changer"
        width={750}
        closeIcon
        closable
        title="Webhooks"
        visible={open}
        onCancel={() => {
          toggleResourceUsage(EDIT_CONTENT_TYPE.WEBHOOK, false);
          setOpen(false);
        }}
        footer={null}
        destroyOnClose
      >
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <DefaultSmallLoader loading />
          </div>
        ) : (
          <>
            <div className="connect-settings-changer__modal-inner" id="domain-list-modal">
              {data?.getWebhooks.map(({ id, ...rest }) => {
                return <SingleWebhook key={id} id={id} {...rest} />;
              })}

              {!domains.length && (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Keine Domains gefunden" />
              )}
            </div>
            <AddWebhookDomain addable={true} />
          </>
        )}
      </Modal>
      {resourceEditSocketLoading ? (
        <DefaultSmallLoader loading />
      ) : (
        <span
          data-testid="webHookButton"
          className="cursor-pointer"
          onClick={() => {
            if (resourceEditState[EDIT_CONTENT_TYPE.WEBHOOK] === true) {
              message.info(ConnectSettingsMessages.duplicateResources);
              return;
            }
            refetchUserWebHooks();
            toggleResourceUsage(EDIT_CONTENT_TYPE.WEBHOOK, true);
            setOpen(true);
          }}
        >
          {trigger}
        </span>
      )}
    </>
  );
};

export default ConnectWebhookDomainModal;
