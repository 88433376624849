export const urlPlaceholderColumns = [
  {
    title: 'Platzhalter',
    dataIndex: 'placeholder'
  },
  {
    title: 'Beschreibung',
    dataIndex: 'description'
  }
];

export const urlPlaceholderData = [
  {
    key: '1',
    placeholder: '[[NAME]]',
    description: 'Eingegebener Name'
  },
  {
    key: '2',
    placeholder: '[[EMAIL]]',
    description: 'Eingegebene E-Mail'
  },
  {
    key: '3',
    placeholder: '[[PHONE]]',
    description: 'Eingegebene Nummer'
  }
];
