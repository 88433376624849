import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export interface UserWebHookType {
  id: number;
  url: string;
  type: string;
  funnelId: number;
  partialFunnel: {
    title: string;
  };
}

export enum WEBHOOK_TYPE {
  NEW_CONTACT = 'NEW_CONTACT'
}

export const GET_USER_WEBHOOKS = gql`
  query getWebhooks {
    getWebhooks {
      id
      url
      type
      funnelId
      partialFunnel {
        title
      }
    }
  }
`;

const useUserWebHooks = (props?: { disableCache?: boolean }) => {
  const { disableCache } = props || {};

  const subscriptionQeury = useQuery<{
    getWebhooks: UserWebHookType[];
  }>(
    GET_USER_WEBHOOKS,
    disableCache
      ? {
          fetchPolicy: 'cache-and-network'
        }
      : {}
  );
  return subscriptionQeury;
};

export default useUserWebHooks;
