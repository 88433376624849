import React, { useState, useEffect, useRef, useMemo } from 'react';

export function usePrevious(value: any) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export const useDidMountEffect = (func: any, deps: any) => {
  const didMount = useRef(false);

  useEffect(() => {
    let unmount: any;
    if (didMount.current) unmount = func();
    else didMount.current = true;

    return () => {
      didMount.current = false;
      unmount && unmount();
    };
  }, deps);
};

export interface Shortcut {
  ctrlKey?: boolean;
  metaKey?: boolean;
  shiftKey?: boolean;
  key: string;
  handler: string;
}

const useKeyboardShortcut = (shortcuts: Shortcut[], callback: (handler: string) => void) => {
  const sortedShortcuts = useMemo(() => {
    return [...shortcuts].sort((a, b) => {
      const aSpecificity = (a.ctrlKey ? 1 : 0) + (a.metaKey ? 1 : 0) + (a.shiftKey ? 1 : 0);
      const bSpecificity = (b.ctrlKey ? 1 : 0) + (b.metaKey ? 1 : 0) + (b.shiftKey ? 1 : 0);
      return bSpecificity - aSpecificity;
    });
  }, [shortcuts]);

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      for (const { ctrlKey, metaKey, shiftKey, key, handler } of sortedShortcuts) {
        if (
          (ctrlKey === undefined || event.ctrlKey === ctrlKey) &&
          (metaKey === undefined || event.metaKey === metaKey) &&
          (shiftKey === undefined || event.shiftKey === shiftKey) &&
          event.key.toLowerCase() === key.toLowerCase()
        ) {
          event.preventDefault();
          event.stopPropagation();
          try {
            callback(handler);
          } catch (error) {
            console.error('Error handling shortcut:', error, event);
          }
          break;
        }
      }
    };

    window.addEventListener('keydown', handleKeydown);

    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [sortedShortcuts, callback]);
};

export default useKeyboardShortcut;
