import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, message, Button, Alert } from 'antd';
import {
  mutateArrayOfObjectsKeysWithIdSuffix,
  normalizeKeysWithIdSuffix
} from '../../helper/dynamicFormHelper';
import { SortableList } from '../../../SharedUI/components/SortableList';
import CircularImageUpload from '../../../SharedUI/components/CircularImageUpload';
import { getVideoThumbnail } from '../../../helper/getThumbnailFromVideoLink';
import { TestimonialMessages } from '../../../config/messages';

export const Rules = {
  name: [{ required: true, message: 'Der Name darf nicht leer sein.' }],
  text: [{ required: true, message: 'Der Text darf nicht leer sein.' }]
};

interface TestimonialsPropsInterface {
  onChange(value: any): any;
  testimonials: TestimonialsInterface[];
  trigger: JSX.Element;
}

export interface TestimonialsInterface {
  id?: number;
  name: string;
  text: string;
  imageURL?: string | null;
  videoURL?: string | null;
  toDelete?: boolean;
  tempId?: boolean;
  weight: number;
}

function Testimonials({ onChange, testimonials = [], trigger }: TestimonialsPropsInterface) {
  const [form] = Form.useForm();
  const [visible, setVisibility] = useState(false);
  const [alteredTestimonialData, setAlteredTestimonialData] = useState(testimonials);
  const mutatedFormValues = mutateArrayOfObjectsKeysWithIdSuffix({ data: alteredTestimonialData });

  const refreshFormValues = (newTestimonials: TestimonialsInterface[]) => {
    form.resetFields();
    setAlteredTestimonialData(newTestimonials);
    const mutatedTestimonials = mutateArrayOfObjectsKeysWithIdSuffix({ data: newTestimonials });
    form.setFieldsValue(mutatedTestimonials);
  };

  useEffect(() => {
    refreshFormValues(testimonials);
  }, [testimonials]);

  useEffect(() => {
    if (!testimonials.length && !alteredTestimonialData.length)
      refreshFormValues([
        { id: Math.floor(Math.random() * 100000), name: '', tempId: true, text: '', weight: 1 }
      ]);
  }, [visible]);

  const handleValuesChange = (changedValues: any, allValues: any = mutatedFormValues) => {
    const newData = { ...allValues, ...changedValues };
    const newTestimonials = normalizeKeysWithIdSuffix(newData);
    form.setFieldsValue(newData);
    setAlteredTestimonialData(newTestimonials);
  };

  const handleSubmit = () => {
    onChange(alteredTestimonialData);
    setVisibility(false);
  };

  return (
    <>
      <div className="testimonials__trigger" onClick={() => setVisibility(true)}>
        {trigger}
      </div>
      <Modal
        width={1100}
        zIndex={500}
        closeIcon
        closable
        title="Testimonials"
        visible={visible}
        onCancel={() => setVisibility(false)}
        destroyOnClose
        footer={[
          <Button key="submit" type="primary" onClick={() => form.submit()}>
            Bestätigen
          </Button>
        ]}
      >
        <div className="testimonials">
          <Form
            form={form}
            name="testimonials_form"
            layout="vertical"
            scrollToFirstError={true}
            onValuesChange={handleValuesChange}
            onFinish={handleSubmit}
            // wichtig keine inital values verwenden da die hooks die values kontrollieren
          >
            <SortableList
              addButtonText="Testimonial hinzufügen"
              emptyObject={{ id: '', name: '', text: '' }}
              items={alteredTestimonialData || []}
              renderItem={(ns: any) => {
                const videoURL = form.getFieldValue('videoURL_' + ns.id);
                let imageURL = form.getFieldValue('imageURL_' + ns.id);

                return (
                  <React.Fragment>
                    <div className="form-group-inline">
                      <div>
                        <CircularImageUpload
                          headline={'Lade ein Bild deines Testimonials hoch'}
                          imageURL={imageURL}
                          onChange={value => {
                            handleValuesChange({ ['imageURL_' + ns.id]: value });
                          }}
                          placeholderImage={getVideoThumbnail(videoURL)}
                        />
                        <Form.Item rules={Rules.name} name={`name_${ns.id}`} label={'Name'}>
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name={`videoURL_${ns.id}`}
                          label="Video"
                          extra="Unterstützt werden: YouTube, Vimeo, Wistia, Dailymotion"
                        >
                          <Input placeholder="z.B. https://www.youtube.com/watch?v=y1yFiotx3qk" />
                        </Form.Item>
                      </div>
                      <Form.Item rules={Rules.text} name={`text_${ns.id}`} label="Text">
                        <Input.TextArea rows={9} />
                      </Form.Item>
                    </div>
                  </React.Fragment>
                );
              }}
              onChangeEnd={(items: any) => {
                refreshFormValues(items);
                // onChange(items);
              }}
            />
          </Form>
          {!!alteredTestimonialData.length && (
            <Alert message={TestimonialMessages.placeholderDescription} type="info" showIcon />
          )}
        </div>
      </Modal>
    </>
  );
}

export default Testimonials;
