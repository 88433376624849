import { GET_DASHBOARD_INFO } from "../utils/graphqlQueries"

export default {
  request: {
    query: GET_DASHBOARD_INFO,
    variables: { } 
  },
  result: {
    data: {
      getUser: {
        firstName: "Lukas",
        lastName: "Bludau",
        quote: "Jede falsche Entscheidung bringt dich einen großen Schritt näher an dein Ziel.",
        email: "mail@lukasbludau.de",
        imageURL: "",
        uniqueIdentifier: "lukas.bludau",
        primaryBrandColor: "#333",
        secondaryBrandColor: "#999",
      },
      getPublicContract: [],
      getContract: [],
      getAllFunnels: []
    },
  },
}