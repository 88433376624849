import React from 'react';
import { Tabs } from 'antd';
import BuilderSettingsFont from '../../components/sharedUI/BuilderSettingsFont';
import AgencyOSBuilderColorPicker from './AgencyOSBuilderColorPicker';
import MetaDataSettings from '../../components/generalSettings/BuilderMetaDataSettings';
import { saveCompleteAgencyOSBuilderDataThunk } from '../redux/thunk';
import { useAppDispatch } from '../../../redux/hooks';

const { TabPane } = Tabs;

const DesignSettings = () => {
  const dispatch = useAppDispatch();

  const handleTabChange = () => {
    dispatch(saveCompleteAgencyOSBuilderDataThunk());
  };
  return (
    <Tabs
      className="design-settings tab-container-style"
      defaultActiveKey="1"
      onChange={handleTabChange}
    >
      <TabPane tab="Schrift" key="1">
        <BuilderSettingsFont />
      </TabPane>
      <TabPane tab="Farben" key="2">
        <AgencyOSBuilderColorPicker />
      </TabPane>
      <TabPane tab="Metadaten" key="3">
        <MetaDataSettings />
      </TabPane>
    </Tabs>
  );
};

export default DesignSettings;
