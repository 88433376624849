import React, { useEffect } from 'react';
import { endSession, startSession, autoTrackInteraction } from '../utils/helper';
import { debounce } from 'debounce';
import { useBuilderSelectedPageId } from '../../redux/builderSlice';
import { useAppSelector } from '../../../redux/hooks';
import { selectGlobalSettingsFlags } from '../../../UI/redux/uiSlice';
import { getValueFromKeyValueArrayOfObjects } from '../../../helper/array';
import { selectUserData } from '../../../UI/redux/userSlice';
import { trackInteraction } from '../utils/helper';

declare global {
  interface Window {
    selectedPageId?: number;
    debugTrackingEnabled?: boolean;
    debugTrackingAutoTrackEnabled?: boolean;
  }
}

export enum TRACKINGS {
  AUTO_TRACK_CLICK = 'AUTO_TRACK_CLICK',
  AUTO_TRACK_DRAG = 'AUTO_TRACK_DRAG',
  RICHTEXT_CONTENT_MISSMATCH = 'RICHTEXT_CONTENT_MISSMATCH',
  ON_CHANGE_IN_RICH_TEXT_COMPONENT = 'ON_CHANGE_IN_RICH_TEXT_COMPONENT',
  HANDLE_CLONE_IN_RENDER_NODE = 'HANDLE_CLONE_IN_RENDER_NODE',
  HANDLE_DELETE_CLICK_IN_RENDER_NODE = 'HANDLE_DELETE_CLICK_IN_RENDER_NODE',
  HANDLE_SWITCH_SETTINGS_IN_RENDER_NODE = 'HANDLE_SWITCH_SETTINGS_IN_RENDER_NODE',
  HANDLE_UNDO_IN_UNDO_REDO_BUTTONS = 'HANDLE_UNDO_IN_UNDO_REDO_BUTTONS',
  HANDLE_REDO_IN_UNDO_REDO_BUTTONS = 'HANDLE_REDO_IN_UNDO_REDO_BUTTONS',
  HANDLE_SWITCH_SETTINGS_IN_ACTIONS_CONTROLLER = 'HANDLE_SWITCH_SETTINGS_IN_ACTIONS_CONTROLLER',
  ON_SORT_END_IN_PAGES_LIST = 'ON_SORT_END_IN_PAGES_LIST',
  HANDLE_OPEN_CREATE_PAGE_IN_PAGES_LIST = 'HANDLE_OPEN_CREATE_PAGE_IN_PAGES_LIST',
  HANDLE_DELETE_PAGE_IN_PAGES_LIST = 'HANDLE_OPEN_CREATE_PAGE_IN_PAGES_LIST',
  HANDLE_CHANGE_PAGE_NAME_IN_PAGES_LIST = 'HANDLE_OPEN_CREATE_PAGE_IN_PAGES_LIST',
  AUTO_TRACK_KEYBOARD_UNDO = 'AUTO_TRACK_KEYBOARD_UNDO',
  AUTO_TRACK_KEYBOARD_REDO = 'AUTO_TRACK_KEYBOARD_REDO',
  AUTO_TRACK_KEYBOARD_COPY = 'AUTO_TRACK_KEYBOARD_COPY',
  AUTO_TRACK_KEYBOARD_PASTE = 'AUTO_TRACK_KEYBOARD_PASTE'
}

function DebugTracking() {
  const globalSettingsFlags = useAppSelector(selectGlobalSettingsFlags);
  const userData = useAppSelector(selectUserData);
  const selectedPageId = useBuilderSelectedPageId();
  const trackKeyboardInteractions = getValueFromKeyValueArrayOfObjects(
    globalSettingsFlags,
    'trackKeyboardInteractions'
  );
  window.selectedPageId = (selectedPageId || 0) as number;
       

  useEffect(() => {
    if (!globalSettingsFlags || !userData || location.href.includes('localhost')) return;

    const debugTrackingEnabledCoachIds = getValueFromKeyValueArrayOfObjects(
      globalSettingsFlags,
      'debugTrackingEnabledCoachIds'
    );

    const debugTrackingAutoTrackEnabledCoachIds = getValueFromKeyValueArrayOfObjects(
      globalSettingsFlags,
      'debugTrackingAutoTrackEnabledCoachIds'
    );

    if (
      !debugTrackingEnabledCoachIds?.includes(userData.id) &&
      debugTrackingEnabledCoachIds !== 'ALL'
    )
      return;

    window.debugTrackingEnabled = true;

    if (
      debugTrackingAutoTrackEnabledCoachIds?.includes(userData.id) ||
      debugTrackingAutoTrackEnabledCoachIds === 'ALL'
    )
      window.debugTrackingAutoTrackEnabled = true;

    startSession();

    const handleClick = debounce(
      (event: MouseEvent) => autoTrackInteraction(event, TRACKINGS.AUTO_TRACK_CLICK),
      500
    );
    const handleDrag = debounce(
      (event: MouseEvent) => autoTrackInteraction(event, TRACKINGS.AUTO_TRACK_DRAG),
      500
    );

    document.body.addEventListener('click', handleClick, true);
    document.body.addEventListener('drag', handleDrag, true);

    return () => {
      endSession();
      document.body.removeEventListener('click', handleClick, true);
      document.body.removeEventListener('drag', handleDrag, true);
    };
  }, []);

  useEffect(() => {
    if (!trackKeyboardInteractions) return;
    const handleKeyboardShortcut = (event: KeyboardEvent) => {
      if (event.ctrlKey || event.metaKey) {
        if (event.key === 'z' && !event.shiftKey) {
          // Track undo shortcut
          tractKeyboardInteractions(TRACKINGS.AUTO_TRACK_KEYBOARD_UNDO);
        } else if ((event.key === 'z' && event.shiftKey) || event.key === 'y') {
          // Track redo shortcut
          tractKeyboardInteractions(TRACKINGS.AUTO_TRACK_KEYBOARD_REDO);
        }
      }
    };

    const tractKeyboardInteractions = (type: TRACKINGS) => {
      trackInteraction({
        type
      });
    };

    const handleCopy = () => {
      tractKeyboardInteractions(TRACKINGS.AUTO_TRACK_KEYBOARD_COPY);
    };

    const handlePaste = () => tractKeyboardInteractions(TRACKINGS.AUTO_TRACK_KEYBOARD_PASTE);

    document.addEventListener('copy', handleCopy);
    document.addEventListener('paste', handlePaste);
    document.addEventListener('keydown', handleKeyboardShortcut);
    return () => {
      document.removeEventListener('keydown', handleKeyboardShortcut);
      document.removeEventListener('copy', handleCopy);
      document.removeEventListener('paste', handlePaste);
    };
  }, []);

  return null;
}

export default DebugTracking;
