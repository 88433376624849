import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

type InputType = {
  page: number;
  limit: number;
  searchQuery?: string;
};

export const GET_IMAGES_WITH_PAGINATION = gql`
  query getImagesWithPagination($input: PaginatedImagesFilter!) {
    getImagesWithPagination(input: $input) {
      images {
        id
        name
        imageLink
        tags
      }
      total
    }
  }
`;

export const useImagesWithPagination = (input: InputType) => {
  return useQuery(GET_IMAGES_WITH_PAGINATION, {
    variables: { input }
  });
};

export const getImagesWithPagination = (input: InputType) => {
  return window.apolloClient.query({
    query: GET_IMAGES_WITH_PAGINATION,
    variables: { input },
    fetchPolicy: 'no-cache'
  });
};
