import React, { useRef, useState } from 'react';
import useOutsideAlerter from '../../../../hooks/useOutsideAlerter';

const EditableLabel = ({
  value,
  setValue,
  className
}: {
  value: string;
  setValue(value: string): void;
  className?: string;
}) => {
  const [editable, setEditable] = useState(false);
  const ref = useRef<HTMLInputElement | null>(null);

  useOutsideAlerter(ref, () => {
    setEditable(false);
  });

  return editable ? (
    <input
    className={className || ''}
      ref={ref}
      value={value}
      onChange={e => {
        setValue(e.target.value);
      }}
    />
  ) : (
    <label
      className={className || ''}
      style={{ cursor: 'text' }}
      onClick={() => {
        setEditable(true);
      }}
    >
      {value}
    </label>
  );
};

export default EditableLabel;
