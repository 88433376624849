import {
  BuilderState,
  Communication,
  CompanyDetail,
  DesignSettingsTabView,
  EmailType,
  FunnelThemeType,
  GeneralSettingsTabView,
  JobInformation
} from '../../Builder/interfaces/builderSliceTypes';
import { ExperienceDuration, SalaryPlan } from '../AdJobBuilder/helper/AdJobHelper';

export const defaultFunnel = {
  id: 0,
  uniqueIdentifier: '',
  builderVersion: 'V2',
  domain: {
    fullLink: '',
    status: '',
    name: ''
  },
  isEditing: false,
  connectedFonts: []
};

export const defaultTargetAudience = {
  id: 0,
  age: '',
  goals: [],
  experience: '0',
  experienceDuration: ExperienceDuration.YEARS,
  education: [],
  skills: []
};
export const defaultCompanyDetail: CompanyDetail = {
  name: '',
  description: '',
  benefits: [],
  uniqueSellingPoint: ''
};

export const defaultCommunication: Communication = {
  speech: '',
  writingStyle: ''
};

export const defaultJobInformation: JobInformation = {
  title: '',
  category: [],
  employmentType: [],
  salary: [0, 1000],
  salaryPlan: SalaryPlan.MONTHLY,
  workplaceModel: '',
  address: { place: '', zoom: 12, lat: 39.1731621, lng: -77.2716502 }
};

export const defaultCraftState =
  'ewogICJST09UIjogxAwgICJ0eXBlxA4gInJlc29sdmVkTmFtxBIiQm9keSIgfSzGKGlzQ2FudmFzIjogdHJ1ZccWcHJvcMQTe8gnZGlzcGxhec1ExxtjdXN0b23MLWhpZGRlbiI6IGZhbHPIU25vZGXEU1siY0lqMDdPVE9YdiIsICJXRkJZYkdQQXd0xA5mTlZyeHZCUmh3Il3HOWxpbmtlZE7HP3t9CiDmAL3MSP8A9+QAs0xvZ2/1APfsAKXpAPjFDyAgImJhY2tncm91bmRDb2xvcsRJ0BPGOyAgImFsaWfkAPYiY2VudGXLGXVybMQXL2Rhc2hib2FyZC9mdW5uZWwtYnVpbGRlci9kZWZhdWx0LWxvZ28ucG5nyjtpc0PlAV/IfuUArcUd9wGa5QDn+QGacGFyZW50xCjlAi7/AbDmAbD/AYjtAcL/AYjEeU1haW5Db250YWluZXL/AojqAZAgImRhdGEtY3nEQ3Jvb3QtY9JE7wELzmr/ART/ARTwAsR2MlVVeUxsaGJE/wKoIO0C1P8BIOQAhUZvb+QCPf8CquwBGv8CpMQT+AEjx23/ARz/ARz/AjDxARDrATz/ARDEeUFkZP4CL/cBFmlkxD4xNjYyOTY0OTgyMzgx+QEHzWT/AQ3EMOwEtP8BE/8BEyB9Cn0=';
export const builderPages = [
  {
    name: 'Leere Seite',
    craftState: defaultCraftState,
    id: -1,
    pageOrder: 0,
    facebookPixelEvent: '',
    tikTokPixelEvent: '',
    linkedInEvent: '',
    googleTagTrigger: '',
    redirectURL: '',
    customJs: ''
  },
  {
    name: 'Dankesseite',
    craftState: defaultCraftState,
    id: -2,
    pageOrder: -1,
    type: 'THANK_YOU',
    facebookPixelEvent: '',
    tikTokPixelEvent: '',
    linkedInEvent: '',
    googleTagTrigger: '',
    customJs: '',
    redirectURL: ''
  }
];
export const funnelTheme: FunnelThemeType = {
  id: 2877,
  headlineColor: '#333333',
  textColor: '#555555',
  accentColor: '#23d3d3',
  backgroundColor: '#f3f3f3',
  cardBackgroundColor: 'rgba(251, 254, 254, 1)',
  cardIconColor: '#ffffff',
  cardTextColor: 'rgba(85, 85, 85, 1)',
  accentColorContrast: '#ffffff',
  fontFamily: 'Open Sans',
  fontLink:
    'https://fonts.googleapis.com/css?family=Open Sans%3Aregular%2Citalic%2C300%2C700&subset=latin&font-display=swap',
  isCustom: false,
  name: 'defautl-Theme',
  defaultGradient:
    'linear-gradient(90deg, RGBA(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)'
};

export const defaultFunnelTheme: FunnelThemeType = {
  headlineColor: '#333333',
  textColor: '#555555',
  accentColor: '#23d3d3',
  backgroundColor: '#f3f3f3',
  cardBackgroundColor: '#0d265d',
  cardIconColor: '#ffffff',
  cardTextColor: '#ffffff',
  accentColorContrast: '#ffffff',
  fontFamily: 'Open Sans',
  name: '',
  id: 1,
  isCustom: false,
  defaultGradient: 'linear-gradient(90deg, #23d3d3 0%, #23d3d3 100%)'
};
export const calendarSettings = {
  bookingRangeInHours: [1, 360],
  busyStatus: true,
  durationInMinutes: 15,
  id: '37a956dbdd',
  maxBookingsPerDay: 5,
  onlySettingsReq: true,
  shifts: [
    {
      dow: [1, 2, 3, 4, 5],
      end: '16:00',
      start: '08:00'
    }
  ],
  slotBufferInMinutes: 15,
  specialClosures: []
};

export const generalSettings = {
  brandingEnabled: true,
  cookieBanner: true,
  language: 'DE',
  title: 'title1',
  uniqueIdentifier: 'uniqueIdentifier'
};
export const metaData = {
  customMetaDataEnabled: false,
  description: '',
  iconURL: '',
  metaTitle: '',
  previewImageURL: ''
};

export const templates = [
  {
    brandingEnabled: true,
    emailSenderName: '',
    enabled: true,
    html: '',
    id: 7574,
    otherEmailAddressesToSend: '',
    subject: 'Deine Bewerbung ist eingegangen 🎉',
    type: EmailType.CONFIRMATION
  },
  {
    brandingEnabled: true,
    emailSenderName: '',
    enabled: true,
    html: '',
    id: 7573,
    otherEmailAddressesToSend: '',
    subject: 'Neue Bewerbung 🎉',
    type: EmailType.INTERNAL_CONFIRMATION
  },
  {
    brandingEnabled: true,
    emailSenderName: '',
    enabled: true,
    html: '',
    id: 7575,
    otherEmailAddressesToSend: '',
    subject: 'Absage ❌',
    type: EmailType.CANCELATION
  },
  {
    brandingEnabled: true,
    emailSenderName: '',
    enabled: true,
    html: '',
    id: 7576,
    otherEmailAddressesToSend: '',
    subject: 'Terminerinnerung 🕑',
    type: EmailType.REMINDER
  }
];

export const INITIAL_STATE: BuilderState = {
  builderPages,
  currentlyEditingTheme: defaultFunnelTheme,
  isMobileView: true,
  isEditingColorTheme: false,
  selectedCard: null,
  selectedElementTab: '1',
  selectedPageId: null,
  checksums: {
    funnelTheme: null,
    funnel: null,
    otherEmailAddressesToSend: null,
    emailTemplatesGeneralSettings: null,
    emailTemplateActiveTabContent: null,
    calendarSettings: null,
    builderPages: null,
    generalSettings: null,
    metaData: null,
    funnelPresets: null,
    funnelConnectedFont: null,
    communication: null,
    companyDetail: null,
    jobInformation: null,
    targetAudience: null
  },
  ui: {
    funnelIsEditingModalVisibility: false,
    completeFunnelSavingByUserInteractionDisabled: true,
    funnelSuggestionModalVisibility: false
  },
  generalSettings,
  funnel: defaultFunnel,
  communication: defaultCommunication,
  companyDetail: defaultCompanyDetail,
  jobInformation: defaultJobInformation,
  targetAudience: defaultTargetAudience,
  aiSettingsUpdatedAt: null,
  loaders: {
    mainFunnelLoader: false,
    isFunnelSaved: true
  },
  shouldSaveFunnel: true,
  lastSaveSuccessful: true,
  funnelTheme,
  metaData,
  commonElements: {
    header: '',
    footer: ''
  },
  draggingElement: {
    name: null,
    nodeId: undefined,
    draggingFrom: undefined,
    parentNodeId: undefined,
    nodeIndex: undefined,
    displayName: undefined
  },
  oldLeadQualifier: {
    leadQualifier: [],
    leadQualifierIdsToDelete: [],
    choiceIdsToDelete: []
  },
  selectedBlockNodeIds: [],
  userColorThemes: [],
  funnelSuggestion: '',
  channelTemplates: {
    sender: '',
    brandingEnabled: false,
    activeTab: '',
    templates: []
  },
  funnelPresets: [],
  generalSettingsTabView: GeneralSettingsTabView.GENERAL_SETTINGS,
  designSettingsTabView: DesignSettingsTabView.COLORS,
  builderValidationErrors: [],
  availableFonts: []
};

export function apolloClientMock() {
  const mockApolloClient = {
    mutate: jest.fn()
  };
  window.apolloClient = mockApolloClient;
}

export function stringifyObjectFields(
  objectToStringfyFields: any,
  fields?: string[]
): { [key: string]: string | any[] } {
  const result: { [key: string]: string | any[] } = { ...objectToStringfyFields };

  const fieldsToProcess =
    fields && fields.length > 0 ? fields : Object.keys(objectToStringfyFields);

  fieldsToProcess.forEach(field => {
    if (
      objectToStringfyFields.hasOwnProperty(field)
      // commment left as a reminder
      // && Array.isArray(objectToStringfyFields[field])
    ) {
      result[field] = JSON.stringify(objectToStringfyFields[field]);
    }
  });

  return result;
}

// This function can be used at any place for the purpose of checking any object
export function hasFalsyValue(obj: { [key: string]: any }): boolean {
  for (const key in obj) {
    if (key === 'id') {
      return false;
    }

    const value = obj[key];

    if (value == null) {
      return true;
    }

    if (typeof value === 'string' && value.trim() === '') {
      return true;
    }

    if (typeof value === 'object' && !Array.isArray(value)) {
      if (Object.keys(value).length === 0) {
        return true;
      }

      if (hasFalsyValue(value)) {
        return true;
      }
    }

    if (Array.isArray(value)) {
      if (value.length === 0) {
        return true;
      }

      for (const item of value) {
        if (hasFalsyValue({ item })) {
          return true;
        }
      }
    }
  }

  return false;
}

export function hasTruthyValue(obj: { [key: string]: any }): boolean {
  function checkValue(value: any): boolean {
    if (value && !Array.isArray(value) && typeof value !== 'object') {
      return true;
    }

    if (Array.isArray(value) && value.length > 0) {
      return value.some(checkValue);
    }

    if (typeof value === 'object' && value !== null && Object.keys(value).length > 0) {
      return Object.values(value).some(checkValue);
    }

    return false;
  }

  return checkValue(obj);
}

export function extractKeysFromAnyObject(obj: Record<string, any>, keys: string[]): any {
  let result: Record<string, any> = {};

  function recursiveHelper(
    currentObj: Record<string, any>,
    currentKeys: string[],
    resultObj: Record<string, any>
  ): void {
    for (let key of currentKeys) {
      if (key in currentObj) {
        resultObj[key] = currentObj[key];

        if (Array.isArray(currentObj[key])) {
          resultObj[key] = currentObj[key];
        } else if (typeof currentObj[key] === 'object' && currentObj[key] !== null) {
          resultObj[key] = {};
          recursiveHelper(currentObj[key], currentKeys, resultObj[key]);
        }
      } else {
        resultObj[key] = undefined;
      }
    }
  }

  recursiveHelper(obj, keys, result);

  return result;
}

export function pickKeys<T extends object>(obj: T, keys: (keyof T)[]): Partial<T> {
  const result: Partial<T> = {};
  keys.forEach(key => {
    if (key in obj) {
      result[key] = obj[key];
    }
  });
  return result;
}

export function removeFalsyValues<T extends object>(obj: T): Partial<T> {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => {
      if (!value) return false;

      if (typeof value === 'object' && value !== null) {
        if (Array.isArray(value)) {
          return value.length > 0;
        } else {
          return Object.keys(value).length > 0;
        }
      }

      return true;
    })
  ) as Partial<T>;
}
