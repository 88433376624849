import React, { useEffect, useState } from 'react';
import useLatestCommitHash from '../../hooks/useLatestCommitHash';
import WarningModal from './WarningModal';
import { RocketOutlined } from '@ant-design/icons';

const LatestVersionValidator = ({ testCommitHash }: { testCommitHash?: string }) => {
  const { data } = useLatestCommitHash();
  const [commitHash, setCommitHash] = useState(testCommitHash || '');
  const [hashStateChanged, setHashStateChanged] = useState(false);

  useEffect(() => {
    if (data?.getLatestCommitHash || testCommitHash) {
      if (!commitHash) {
        setCommitHash(data?.getLatestCommitHash);
      } else if (commitHash !== data?.getLatestCommitHash) setHashStateChanged(true);
    }
  }, [data?.getLatestCommitHash, testCommitHash]);

  return (
    <WarningModal
      visible={hashStateChanged}
      toggleModal={() => setHashStateChanged(false)}
      icon={<RocketOutlined style={{color: '#1e266d'}} />}
      title={'Neue Version verfügbar!'}
      subTitle={
        <>
          Wir haben ein neues Update veröffentlicht, welches Verbesserungen und Fehlerbehebungen
          enthält. Um Fehler zu vermeiden, lade bitte die Seite neu, um die neueste Version zu
          erhalten. <b>Wichtig: </b>Wenn du im Builder bist, speichere bevor du neu lädst.
        </>
      }
      yesButtonText="Jetzt neu laden"
      onYes={() => window.location.reload()}
      noButtonText="Ich mache es später"
      yesButtonProps={{ type: 'primary' }}
      onNo={() => setHashStateChanged(false)}
      noButtonProps={{ danger: true }}
    />
  );
};

export default LatestVersionValidator;
