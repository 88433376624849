import React, { useState } from 'react';
import { DeleteOutlined, CloseOutlined } from '@ant-design/icons';
import DefaultSmallLoader from '../../../SharedUI/components/DefaultSmallLoader';
import BuilderSettingsTitle from './BuilderSettingsTitle';

const BuilderSettingsTitleWithDelete = ({
  classNames = '',
  title,
  loading,
  handleDelete,
  disabled
}: {
  classNames?: string;
  title: string;
  loading: boolean;
  handleDelete?: () => void;
  disabled?: boolean;
}) => {
  const [toBeDeleted, setToBeDeleted] = useState(false);
  return (
    <div className="d-flex builder-settings-title__with-delete">
      <BuilderSettingsTitle title={title} classNames="template-block__name small-label" />

      {handleDelete && !disabled && (
        <div className="settings-title__right px-1 default-opacity-hover pe-auto">
          {toBeDeleted ? (
            <div className="d-flex align-items-center">
              <CloseOutlined
                className="cursor-pointer me-1"
                onMouseDown={() => {
                  setToBeDeleted(false);
                }}
              />
              <div className="d-flex align-items-center cursor-pointer">
                <DefaultSmallLoader loading={loading} />
              </div>
              {!loading && (
                <div
                  className="d-flex align-items-center cursor-pointer default-opacity-hover text-red"
                  onClick={() => {
                    handleDelete();
                  }}
                >
                  <DeleteOutlined className="text-red px-1" />
                  Bestätigen
                </div>
              )}
            </div>
          ) : (
            <DeleteOutlined
              className="default-opacity-hover text-red"
              onClick={() => {
                setToBeDeleted(true);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default BuilderSettingsTitleWithDelete;
