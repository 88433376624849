import React from 'react';
import SingleSettingRenderer, { SingleSettingRendererProps } from './SingleSettingRenderer';
import { SettingsComponents } from './settingsGbTypes';
import { useNode } from '@craftjs/core';

export const CustomAlignmentOptionsForImage = ({
  element,
  onChange,
  values
}: SingleSettingRendererProps) => {
  const { props } = useNode(node => ({
    props: node.data.props
  }));
  return (
    <SingleSettingRenderer
      element={{
        ...element,
        component: SettingsComponents.ALIGNMENT_OPTIONS,
        disabled: props.maxWidth === '100%'
      }}
      onChange={onChange}
      values={values}
    />
  );
};
