import gql from 'graphql-tag';

export const GET_SUBSCRIPTION_INFO = gql`
  query getSubscriptionInfo {
    getSubscriptionInfo {
      status
      brandingEnabled
    }
  }
`;

export const GET_FUNNEL_DATA = gql`
  query getFunnelData {
    getEmailSettings {
      id
      subject
      introduction
      type
      timeInMinutesTillEvent
      otherEmailAdressesToSend
      emailSenderName
      enabled
    }
    getFunnel {
      id
      title
      description
      calendarId
      serviceId
      uniqueIdentifier
      primaryBrandColor
      secondaryBrandColor
      calendarBlockMode
      logoURL
      templateId
      builderVersion
      type
      domain {
        name
        status
        fullLink
      }
      themeStyles {
        fontFamily
        fontLink
        headlineColor
        textColor
        accentColor
        backgroundColor
        cardBackgroundColor
        cardIconColor
        cardTextColor
      }
      metaTitle
      customMetaDataEnabled
      iconURL
      previewImageURL
    }
    getBookingSettings {
      id
      cookieHint
      socialBubblesActivated
      calendarHeadline
      calendarPreHeadline
      bookingRangeInHours
      redirectURL
      pixelName
      gTag
      maxBookingsPerDay
      slotBufferInMinutes
      calendarEnabled
      incomingVoiceMessagesEnabled
    }
    getPublicTrustBadge {
      id
      title
      url
    }
    getTrustBadge {
      id
      enabled
      publicTrustBadge {
        id
        url
      }
    }
    getUser {
      firstName
      lastName
      imageURL
    }
    getShifts {
      id
      start
      end
      dow
    }
    getSpecialClosures {
      id
      start
      end
    }
    getCalendars {
      id
      primary
      summary
      summaryOverride
      backgroundColor
      foregroundColor
    }
    getSubmitForm {
      id
      imageURL
      name
      quote
      buttonText
      buttonHint
      pixelName
      gTag
      voiceMessageAWSKey
      voiceMessageSignedUrl
      legalRepresentativeName
      phoneEnabled
    }
    getThankyouPage {
      id
      pixelName
      gTag
      imageURL
      videoURL
      voiceMessageAWSKey
      voiceMessageSignedUrl
      headline
      ctaLink {
        id
        type
        title
        link
      }
      nextSteps {
        id
        description
        title
        weight
        icon
      }
    }
    getWelcomePage {
      id
      name
      serviceName
      imageURL
      desktopImageURL
      videoURL
      voiceMessageAWSKey
      voiceMessageSignedUrl
      buttonText
      headline
      pixelName
      gTag
      text
      activated
      showButtonByVideoTimeEnabled
      showButtonWhenScrolledDown
      showButtonVideoSeconds
      weight
      type
      html
    }
    getLeadQualifier {
      id
      question
      messageTitle
      messageText
      messageSkippable
      messageCtaLink
      messageCtaTitle
      pixelName
      gTag
      voiceMessageAWSKey
      voiceMessageSignedUrl
      weight
      type
      choices {
        id
        title
        weight
        icon
        imageURL
        placeholder
        length
        min
        max
        unit
        initialStringValue
        initialNumberValue
      }
      logic {
        id
        fallback
        leftSideValue
        operator
        rightSideValue
        thenLeadQualifierId
        thenStepKey
      }
    }
    getLinkTree {
      title
      url
      id
      weight
    }
    getRatingProviderSettings {
      provider
      profileURL
      activated
    }
    getTestimonials {
      id
      name
      text
      imageURL
      videoURL
      weight
    }
  }
`;
export const SET_EMAIL_SETTINGS = gql`
  mutation setEmailSettings($input: [EmailSettingsData!]!) {
    setEmailSettings(input: $input) {
      id
    }
  }
`;

export const SET_GENERAL_SETTINGS = gql`
  mutation setGeneralSettings(
    $funnelData: FunnelData!
    $trustBadgeData: [TrustBadgeData!]
    $bookingSettingsData: BookingSettingsData!
    $submitFormData: SubmitFormData!
  ) {
    setFunnel(input: $funnelData) {
      id
      domain {
        name
        status
        fullLink
      }
    }
    setBookingSettings(input: $bookingSettingsData) {
      id
    }
    setTrustBadge(input: $trustBadgeData) {
      id
      enabled
      publicTrustBadge {
        id
        title
        url
      }
    }
    setSubmitForm(input: $submitFormData) {
      id
      imageURL
    }
  }
`;

export const SET_SUBMIT_FORM_SETTINGS = gql`
  mutation setSubmitFormSettings($input: SubmitFormData!, $voiceMessagesToDelete: [String!]) {
    setSubmitForm(input: $input, voiceMessagesToDelete: $voiceMessagesToDelete) {
      id
    }
  }
`;

export const SET_CALENDAR_SETTINGS = gql`
  mutation setCalendarSettings(
    $shiftData: [ShiftData!]!
    $specialClosureData: [SpecialClosureData!]!
    $bookingSettingsData: BookingSettingsData!
    $funnelData: FunnelData!
  ) {
    setShifts(input: $shiftData) {
      id
    }
    setSpecialClosures(input: $specialClosureData) {
      id
    }
    setBookingSettings(input: $bookingSettingsData) {
      id
    }
    setFunnel(input: $funnelData) {
      id
    }
  }
`;

export const SET_THANKYOU_PAGE_SETTINGS = gql`
  mutation setThankyouPageSettings(
    $input: ThankyouPageData!
    $nextStepIdsToDelete: [Int!]
    $bookingSettingsData: BookingSettingsData!
    $voiceMessagesToDelete: [String!]
  ) {
    setThankyouPage(
      input: $input
      nextStepIdsToDelete: $nextStepIdsToDelete
      voiceMessagesToDelete: $voiceMessagesToDelete
    ) {
      id
    }
    setBookingSettings(input: $bookingSettingsData) {
      id
    }
  }
`;

export const SET_WELCOME_PAGES = gql`
  mutation setWelcomePage(
    $input: [WelcomePageData!]!
    $idsToDelete: [Int!]
    $voiceMessagesToDelete: [String!]
    $testimonialDataToSubmit: [TestimonialData!]
    $testimonialIdsToDelete: [Int!]
  ) {
    setWelcomePage(
      input: $input
      idsToDelete: $idsToDelete
      voiceMessagesToDelete: $voiceMessagesToDelete
    ) {
      id
    }
    setTestimonials(input: $testimonialDataToSubmit, idsToDelete: $testimonialIdsToDelete) {
      id
    }
  }
`;

export const SET_LEAD_QUALIFIER = gql`
  mutation setLeadQualifier(
    $input: [LeadQualifierData!]
    $idsToDelete: [Int!]
    $choiceIdsToDelete: [Int!]
    $voiceMessagesToDelete: [String!]
    $logicIdsToDelete: [Int!]
  ) {
    setLeadQualifier(
      input: $input
      idsToDelete: $idsToDelete
      choiceIdsToDelete: $choiceIdsToDelete
      voiceMessagesToDelete: $voiceMessagesToDelete
      logicIdsToDelete: $logicIdsToDelete
    ) {
      id
    }
  }
`;

export const SET_LEGAL_SETTINGS = gql`
  mutation setLegalSettings(
    $linkTree: [LinkData!]
    $bookingSettingsData: BookingSettingsData!
    $idsToDelete: [Int!]
  ) {
    setLinkTree(input: $linkTree, idsToDelete: $idsToDelete) {
      id
    }
    setBookingSettings(input: $bookingSettingsData) {
      id
    }
  }
`;
