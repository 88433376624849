import React from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { ActionsController } from '../../../components/sharedUI/ActionsController';
import { CRAFT_ELEMENTS } from '../../config/craftElements';
import { CraftElementBaseProps } from '../../../helper/craftJs';
import { Card, Col, DatePicker, Row } from 'antd';
import BuilderSettingsTitle from '../../../components/sharedUI/BuilderSettingsTitle';
import moment from 'moment';
import { BsCalendar2DateFill } from 'react-icons/bs';
import dateFormats from '../../../../config/dateFormats';
import { useCurrentlyEditingTheme } from '../../../redux/builderSlice';

interface CraftDatePickerProps extends CraftElementBaseProps {
  Title: string;
  date: string;
  settings?: {
    showTime?: boolean;
  };
}

export const CraftDatePicker = ({ date }: CraftDatePickerProps) => {
  const { accentColor } = useCurrentlyEditingTheme();
  return (
    <ActionsController className="cursor-pointer" data-testid="craftDatePicker">
      <div className="job-attribute m-1">
        <BsCalendar2DateFill style={{ color: accentColor }} />{' '}
        {moment(date).format(dateFormats.dateTime)}
      </div>
    </ActionsController>
  );
};

const CraftDatePickerSettings = () => {
  const { nodeId, props } = useNode(node => ({
    nodeId: node.id,
    props: node.data.props
  }));
  const { actions } = useEditor();

  const handleDate = (value: any) => {
    const selectedDateTime = moment(value).toISOString();
    actions.setProp(nodeId, (state: any) => {
      state.date = selectedDateTime;
    });
  };

  return (
    <Col span={24} className="builder__settings-sidebar__container">
      <Card className="settings-card" title={props.Title} bordered={false}>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BuilderSettingsTitle title={props.Title} />
            <DatePicker
              {...(process.env.NODE_ENV === 'test' && { open: true })}
              data-testid="datePickerInput"
              className="w-100"
              placeholder="Select Date"
              showTime={props.settings.showTime}
              format={dateFormats.dateTime}
              allowClear={false}
              onChange={handleDate}
            />
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

export const CraftDatePickerDefaultProps = {
  date: new Date().toISOString(),
  settings: {
    showTime: false
  }
};
CraftDatePicker.craft = {
  name: CRAFT_ELEMENTS.Craft_Date_Picker,
  props: CraftDatePickerDefaultProps,
  related: {
    settings: CraftDatePickerSettings
  }
};
