import gql from 'graphql-tag';

export interface CreateConnectedFontInput {
  fontId:number;
  type: string;
}

const CREATE_CONNECTED_FONTS = gql`
mutation setConnectedFonts($input: [SetConnectedFontInput]!) {
  setConnectedFonts(input: $input) {
    type
    availableFont {
      id
      fontFamily
      fontLink
      type
    }
  }
}
`;

export function setConnectedFontApi(data:CreateConnectedFontInput[]) {
  return window.apolloClient.mutate({
    mutation: CREATE_CONNECTED_FONTS,
    variables: {
      input: data
    }
  });
}
