import React from 'react';
import { useIsMobileView } from '../../../hooks/useIsMobileView';
import SingleSettingRenderer, { SingleSettingRendererProps } from '../../Settings/SingleSettingRenderer';
import { SettingsComponents } from '../../Settings/settingsGbTypes';

export const CustomAlignmentOptionsForButton = ({
  element,
  onChange,
  values
}: SingleSettingRendererProps) => {
  const isMobileView = useIsMobileView();
  return (
    <SingleSettingRenderer
      element={{
        ...element,
        component: SettingsComponents.ALIGNMENT_OPTIONS,
        disabled: isMobileView
      }}
      onChange={onChange}
      values={values}
    />
  );
};
