import React from 'react';
import { FaRegFileWord, FaRegFileExcel, FaRegFileImage, FaRegFileAlt, FaRegFilePdf } from 'react-icons/fa';

const icons: { [key: string]: JSX.Element } = {
  'pdf': <FaRegFilePdf size={20} color='#E65B33' />,
  'docx': <FaRegFileWord size={20} color='#1F97FB' />,
  'doc': <FaRegFileWord size={20} color='#1F97FB' />,
  'xlsx': <FaRegFileExcel size={20} color='#8CC263' />,
  'xls': <FaRegFileExcel size={20} color='#8CC263' />,
  'jpg': <FaRegFileImage size={20} color='#6B94B9' />,
  'jpeg': <FaRegFileImage size={20} color='#6B94B9' />,
  'png': <FaRegFileImage size={20} color='#6B94B9' />,
  'gif': <FaRegFileImage size={20} color='#6B94B9' />,
  'webp': <FaRegFileImage size={20} color='#6B94B9' />,
  'svg': <FaRegFileImage size={20} color='#6B94B9' />,
};

interface Props {
  fileName: string;
}

const FileIcon: React.FC<Props> = ({ fileName }) => {
  const fileExtension = fileName.split('.').pop()?.toLowerCase();
  const icon = fileExtension ? icons[fileExtension] || <FaRegFileAlt size={20} /> : <FaRegFileAlt size={20} />;
  return icon;
};

export default FileIcon;
