export const groupArrayByKey = (items: any, key: string) =>
  items.reduce(
    (result: any, item: any) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item]
    }),
    {}
  );

export const deepComparisson = (value1: any, value2: any) => {
  return JSON.stringify(value1) === JSON.stringify(value2);
};

export const deepCopy = (data: any, excludeKeys?: string[]) => {
  let dataToExclude: any = {};
  const shouldExcludeData = Array.isArray(data) && excludeKeys?.length;

  if (shouldExcludeData) {
    //@ts-ignore
    dataToExclude = data.reduce((acc, row, i) => {
      acc[i] = {};
      //@ts-ignore
      excludeKeys.forEach(key => {
        acc[i][key] = row[key];
      });
      return acc;
    }, {});
  }

  let duplicatedData = JSON.parse(JSON.stringify(data));

  if (shouldExcludeData) {
    duplicatedData = duplicatedData.map((row: any, i: number) => {
      const thisDataToExclude = dataToExclude[i];
      if (thisDataToExclude) {
        Object.keys(thisDataToExclude).map(key => {
          row[key] = thisDataToExclude[key];
        });
      }
      return row;
    });
  }

  return duplicatedData;
};

export const searchInObject = (object: any, property: string, value: string) =>
  (object[property] || '').toLowerCase().includes((value || '').toLowerCase());

export const searchInArrayOfObjects = (array: any[], property: string, value: string) =>
  array.filter(object => searchInObject(object, property, value));


export function getValueFromKeyValueArrayOfObjects(array: { key: string, value: string }[] | null, key: string) {
  if (!array) return undefined;

  const found = array.find(obj => obj.key === key);
  if (found) {
    const value = found.value;
    if (typeof value === 'string') {
      try {
        return JSON.parse(value);
      } catch (e) {
        if (/^\d+$/.test(value)) {
          return parseInt(value);
        }
      }
    }
    return value;
  }
  
  return undefined;
}