import { Col, Row } from 'antd';
import React from 'react';
import BuilderSettingsTitle from '../../sharedUI/BuilderSettingsTitle';
import { setCurrentlyEditingTheme, setIsEditingColorTheme } from '../../../redux/builderSlice';
import ColorList from '../../sharedUI/ColorList';
import { EditOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { FunnelThemeType } from '../../../interfaces/builderSliceTypes';
import { useCurrentlyEditingBuilderTheme } from '../../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';

const AppliedColorThemeActions = () => {
  const funnelTheme = useCurrentlyEditingBuilderTheme() as FunnelThemeType;
  const dispatch = useDispatch();

  const editCurrentColorTheme = (colorTheme: FunnelThemeType) => {
    dispatch(setCurrentlyEditingTheme(colorTheme));
    dispatch(setIsEditingColorTheme(true));
  };
  return (
    <Row className="builder__settings-sidebar__row pt-2">
      <Col span={24}>
        <BuilderSettingsTitle title="Aktuelle Farben" />
        <div className={'color-circles current-theme d-flex justify-content-center'}>
          <div
            className="color-circles__edit-overlay"
            onClick={() => editCurrentColorTheme(funnelTheme as FunnelThemeType)}
          >
          <EditOutlined />
          </div>
          <div className="color-circles__inner">
            <ColorList theme={funnelTheme} qty={8} />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default AppliedColorThemeActions;
