import gql from 'graphql-tag';

export const UPLOAD_VOICE_MESSAGE = gql`
  mutation($input: UploadVoiceMessage!) {
    uploadVoiceMessage(input: $input){
      voiceMessageSignedUrl
      voiceMessageAWSKey
    }
  }
`;
