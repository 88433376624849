import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { GET_USER_WEBHOOKS } from './useUserWebHooks';

export const CREATE_WEB_HOOK = gql`
  mutation($data: CreateWebhookInput!) {
    createWebhook(input: $data) {
      url
      type
      funnelId
    }
  }
`;

const mutateCreateWebhook = () => {
  const mutation = useMutation(CREATE_WEB_HOOK,
     {
    refetchQueries: [{ query: GET_USER_WEBHOOKS }]
  }
  );
  return mutation;
};

export default mutateCreateWebhook;
