import React, { useState } from 'react';
import { Button, Col, Divider, Modal, Row, Space, Typography } from 'antd';
import { RocketOutlined, LinkOutlined, EyeOutlined } from '@ant-design/icons';
import { builderInvalidationClass } from '../../config/builderUi';
import FunnelLinkSharing from './FunnelLinkSharing';

interface PublishModalProps {
  handleOpen: () => Promise<void>;
  shouldSaveOnClick?: boolean;
  showTrackingSwitch?: boolean;
  url: string;
}

const PublishModal = ({
  shouldSaveOnClick,
  handleOpen,
  url,
  showTrackingSwitch = true
}: PublishModalProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        type="primary"
        onClick={async () => {
          await handleOpen();
          setOpen(true);
        }}
        shape="round"
        icon={<EyeOutlined />}
        className={builderInvalidationClass}
      >
        Funnel ansehen
      </Button>
      <Modal
        className="publish-modal text-center"
        footer={null}
        centered
        visible={open}
        onCancel={() => setOpen(false)}
        width="800px"
      >
        <Row gutter={[0, 48]}>
          <Col span={24}>
            <FunnelLinkSharing url={url} showTrackingSwitch={showTrackingSwitch} />
          </Col>
          <Divider />
          <Col span={24}>
            <Space direction="vertical" size={20} className="max-width__600px-centered">
              <img
                src="/dashboard/funnel-builder/bewerber-in-den-funnel-visualisierung.jpg"
                className="max-width__450px-centered"
              />
              <div>
                <Typography.Title level={2}>2. Bewerber in den Funnel leiten</Typography.Title>
                <Typography.Paragraph>
                  Wir haben dir eine Checkliste mit Best-Practices und Videos inkl. Vorlagen
                  zusammengestellt die deinen Funnel nach vorne katapultieren.
                </Typography.Paragraph>
                <Button
                  className="meetovo-style"
                  type="primary"
                  shape="round"
                  icon={<RocketOutlined />}
                  href="https://hilfe.meetovo.de/de/category/launch-checkliste-7j3dxw/"
                  target="_blank"
                >
                  Jetzt zur Launch-Checkliste
                </Button>
              </div>
            </Space>
          </Col>
          <Divider />
          <Col span={24}>
            <Space direction="vertical" size={20} className="max-width__600px-centered">
              <img
                src="/dashboard/funnel-builder/dsgvo+badges.jpg"
                className="max-width__450px-centered"
              />
              <div>
                <Typography.Title level={2}>3. Datenschutzbaustein einfügen</Typography.Title>
                <Typography.Paragraph>
                  Damit du deinen Recruiting-Funnel DSGVO-Konform nutzen kannst, musst du den
                  vorgefertigten Datenschutzbaustein in deine Datenschutzerklärung einfügen.
                  Außerdem solltest du den gesetzlich vorgeschreibenen ADV-Vertrag von uns
                  anfordern.
                </Typography.Paragraph>
                <Button
                  className="meetovo-style"
                  type="primary"
                  shape="round"
                  icon={<LinkOutlined />}
                  href="https://hilfe.meetovo.de/de/article/dsgvo-checkliste-4cunb6"
                  target="_blank"
                >
                  Jetzt zur DSGVO-Checkliste
                </Button>
              </div>
            </Space>
          </Col>
          <Divider />
          <Col span={24}>
            <Space direction="vertical" size={20} className="max-width__600px-centered">
              <img
                src="/dashboard/funnel-builder/connect-publish-popup.jpg"
                className="max-width__450px-centered"
              />
              <div>
                <Typography.Title level={2}>
                  4. Integriere Tools, die du bereits nutzt.
                </Typography.Title>
                <Typography.Paragraph>
                  Verbinde MEETOVO mit Zapier oder Make (früher: Integromat) mit über 4,000 Apps.
                  Erstelle deinen eigenen automatisierten Prozess mit nur ein paar Klicks. Vom CRM
                  zum E-Mail-Marketing.
                </Typography.Paragraph>
                <Button
                  className="meetovo-style"
                  type="primary"
                  shape="round"
                  icon={<LinkOutlined />}
                  href="/dashboard/connect"
                  target="_blank"
                >
                  Jetzt verbinden
                </Button>
              </div>
            </Space>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default PublishModal;
